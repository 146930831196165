import React, { useEffect, useState } from "react";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { CommunityApi } from "../../apis/CommunityApi";
import config from "../../config";
import moment from "moment";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import MobileHeader from "../../components/Layout/Mobile/MobileHeader";
import { withTranslation } from "react-i18next";
import RightAdvertiseBanner from "../Components/RightAdvertiseBanner";
import { HomeApi } from "../../apis/HomeApi";
import constants from "../../constants";
import { getRoleID } from "../../Token";
import InfiniteScroll from "react-infinite-scroll-component";
import OwlCarousel from "react-owl-carousel";
import i18n from "../../i18n";

const options = {
  responsiveClass: true,
  dots: false,
  autoplay: false,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 6,
    },
    700: {
      items: 6,
    },
    1000: {
      items: 6,
    },
  },
};
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Community(props) {
  const params = useParams();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product = urlParams.get("search");

  const query = useQuery();
  const type = query.get("type") || "NEWS";
  const [communitypostData, setCommunitypostData] = useState({
    page: 1,
    limit: config.LIMIT,
    type,
    search: "",
    filterBy: {
      company_id: "All",
    },
    category_id: "All",
    institute_user_id: "All",
  });

  const [communitypost, setCommunitypost] = useState([]);
  const [communityTopicData, setCommunityData] = useState({
    parent_type: "community",
    type,
  });
  const [communityTopic, setCommunityTopic] = useState([]);
  const [autoSearch, setAutoSearchData] = useState([]);
  const [keywordActive, setKeywordActive] = useState("");


  const fetchScrollPostData = {};
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
    search: urlParams.get("search") == null ? "" : urlParams.get("search"),
    type: type,
    filterBy: { company_id: "All" },
    institute_user_id:
      urlParams.get("institute_user_id") == null
        ? "All"
        : urlParams.get("institute_user_id"),
    category_id:
      urlParams.get("category_id") == null
        ? "All"
        : urlParams.get("category_id"),
  });
  const [categorytActive, setCategoryActive] = useState("All");
  const [searchActive, setSearchActive] = useState("");
  const [instituteActive, setInstituteActive] = useState("All");
  const [topicPosition, setTopicPosition] = useState(0);

  const getCommunityPosts = (data, is_push_data) => {
    CommunityApi.getCommunityPosts(data)
      .then((res) => {
        if (res.data.success) {
          if (is_push_data) {
            setCommunitypost([...communitypost, ...res.data.data]);
          } else {
            setCommunitypost(res.data.data);
          }
        } else {
          setCommunitypost([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCommunityTopic = (data) => {
    CommunityApi.getNewsTopic(data)
      .then((res) => {
        if (res.data.success) {
          setCommunityTopic(res.data.institutes);
        } else {
          setCommunityTopic([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [banners, setBanners] = useState([]);
  const [topBanner, setTopBanner] = useState({
    page_name: "Community-News",
    type: "Banner",
    position: "Top",
  });

  const getBanners = (data) => {
    HomeApi.getBanners(data)
      .then((res) => {
        if (res.data.success) {
          setBanners(res.data.data.banners);
        } else {
          setBanners([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMoreData = () => {
    fetchScrollPostData({
      page: activeData.page + 1,
      limit: config.LIMIT,
      search: activeData.search,
      type: type,
      filterBy: { company_id: activeData.filterBy.company_id },
      institute_user_id: activeData.institute_user_id,
      category_id: activeData.category_id,
    });
  };

  window.onscroll = async function (ev) {
    var pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    if (window.innerHeight + window.scrollY >= pageHeight) {
      const data = {
        page: activeData.page + 1,
        limit: config.LIMIT,
        search: activeData.search,
        type: type,
        filterBy: { company_id: activeData.filterBy.company_id },
        institute_user_id: instituteActive,
        category_id: categorytActive,
      };
      setActiveData(data);
      getCommunityPosts(data, true);
    }
  };

  useEffect(() => {
    setCategoryActive(activeData.category_id);
    //setSearchActive(activeData.search);
    setInstituteActive(activeData.institute_user_id);
  }, []);

  useEffect(() => {
    getBanners(topBanner);
    getCommunityPosts(communitypostData);
  }, [communitypostData.institute_user_id, communitypostData.search]);

  useEffect(() => {
    getCommunityTopic(communityTopicData);
  }, []);

  function handleChangeSearch(event) {
    console.log(handleChangeSearch, "xyz");
    // alert (1)

    if (event.key === "Enter") {
      event.preventDefault();
      // console.log(event,"xyz");

      setCommunitypostData({
        ...communitypostData,
        search: event.target.value,
      });
    }
  }
  function handleChangeTopic(topicId,position) {
    // console.log(handleChangeSearch, "xyz");
    // alert (1)
    setTopicPosition(position);

    setCommunitypostData({
      ...communitypostData,
      institute_user_id: topicId,
    });
  }

  function handleChangeLikeUnlikePost(isLike, communityPostId) {
    const data = {
      // post_comment_id: commentid,
      post_community_id: communityPostId,
      is_liked: isLike,
    };
    CommunityApi.CommunityPostsLikes(data)
      .then((res) => {
        getCommunityPosts(communitypostData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const OnSearchKeyUp = (e) => {
    setKeywordActive(e.target.value);
    if(e.key == "Enter") {
      activeData.search = e.target.value;
      setActiveData({
        page: 1,
        limit: config.LIMIT,
        search: keywordActive,
        type: type,
        filterBy: { company_id: activeData.filterBy.company_id },
        institute_user_id: instituteActive,
        category_id: categorytActive,
      });
      getCommunityPosts(activeData);
      setAutoSearchData([]);
      return false;
    }
  }

  const OnSearch = (e) => {
    let data = { keyword: e.target.value, type: type};
    setKeywordActive(e.target.value);
    if(e.target.value != '') {
      CommunityApi.getCommunityNameNameByKeyword(data)
        .then((res) => {
          if (res.data.success) {
            setAutoSearchData(res.data.data);
          } else {
            setAutoSearchData([]);
          }
        })
        .catch((err) => {
          console.log(err);
      });
    } else {
      setAutoSearchData([]);
    }
    // setActiveData({ ...activeData, search: e.target.value })
  }

  const selectRecord = (value) => {
    setKeywordActive(value);
    setCommunitypost([]);
    activeData.search = value;
   
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      search: value,
      type: type,
      filterBy: { company_id: activeData.filterBy.company_id },
      institute_user_id: instituteActive,
      category_id: categorytActive,
    });
    getCommunityPosts(activeData,false);
    setAutoSearchData([]);

  }

  const searchRecord = () => {
    setKeywordActive(keywordActive);
    setCommunitypost([]);
    activeData.search = (keywordActive !== undefined) ? keywordActive : "";
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      search: keywordActive,
      type: type,
      filterBy: { company_id: activeData.filterBy.company_id },
      institute_user_id: instituteActive,
      category_id: categorytActive,
    });
    getCommunityPosts(activeData,false);
    setAutoSearchData([]);
  }

  return (
    <>
      <Navbar />
      <ScrollToTopOnMount />

      {/* <!-- Mobile View Top Start --> */}
      {/* <MobileHeader title="Community" /> */}
      <MobileHeader title={props.t("Community")} />

      {/* <!-- Mobile View Top End --> */}

      {/* <!-- Mobile Bottom Menu Start --> */}
      <MobileBottomMenu />
      {/* <!-- Mobile Bottom Menu End --> */}

      <section className="pt-3 top-curve">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12">
              <h1 className="mt-5 mb-4 main-title d-m-none">
                {props.t("Community")}
              </h1>
            </div>
            <div className="col-md-12">
              <div className="top-links d-none list-style-none">
                <ul>
                  <li>
                    <Link to={`/community`} className="">
                      {props.t("In-Focus")}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/community/news`} className="active">
                      {props.t("News")}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/community/blog`} className="">
                      {props.t("Dr-Connect")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Carousel Start --> */}
      {/* <section className="pt-2 d-none">
                <div className="container p-0 carousel-p">
                    <div className="row">
                        <div id="demo" className="carousel slide" data-bs-ride="carousel">
                            <!-- Indicators/dots -->
                            <div className="carousel-indicators">
                                <button
                                    type="button"
                                    data-bs-target="#demo"
                                    data-bs-slide-to="0"
                                    className="active"
                                ></button>
                                <button
                                    type="button"
                                    data-bs-target="#demo"
                                    data-bs-slide-to="1"
                                ></button>
                                <button
                                    type="button"
                                    data-bs-target="#demo"
                                    data-bs-slide-to="2"
                                ></button>
                                <button
                                    type="button"
                                    data-bs-target="#demo"
                                    data-bs-slide-to="3"
                                ></button>
                            </div>

                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img
                                        className="w-100"
                                        src="/assets/images/community-banner.png"
                                        alt="Image"
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img
                                        className="w-100"
                                        src="/assets/images/community-banner.png"
                                        alt="Image"
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img
                                        className="w-100"
                                        src="/assets/images/community-banner.png"
                                        alt="Image"
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img
                                        className="w-100"
                                        src="/assets/images/community-banner.png"
                                        alt="Image"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* <!-- Carousel End --> */}

      {/* <!-- In focus Start --> */}
      <section className="community pb-3 mob-pt-1 mob-mt-m-35 mob-pb-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-9 px-30 mob-px-30">
              <ul className="nav nav-tabs d-m-none" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Link to={`/community`} className="nav-link ">
                    {props.t("In-Focus")}
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community/news`}
                    className="nav-link active"
                    id="news-tab"
                  >
                    {props.t("News")}
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community/blog`}
                    className="nav-link"
                    id="blog-tab"
                  >
                    {props.t("Dr-Connect")}
                  </Link>
                </li>
              </ul>

              <div className="tab-content mob-mb-30 clearfix" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="focus"
                  role="tabpanel"
                  aria-labelledby="focus-tab"
                >
                  <div className="row">
                    <div className="col-md-12 col-mob-46">
                      <div className="search-box  mb-2">
                        
                          <div className="row">
                            <div className="col-md-12">

                            <div className="wrapper w-40">
                              <div className="search-input">
                                <input type="text" value={keywordActive}  defaultValue={keywordActive}  onChange={(e) => OnSearch(e) }  onKeyUp = {OnSearchKeyUp}   placeholder={props.t("Search")}/>
                                <div className="autocom-box">
                                {autoSearch?.map((searchdata, index) => {
                                  return (<li key={index}  onClick={() => selectRecord(searchdata.name_en)}  >{searchdata.name_en}</li>)
                                })}
                                </div>
                                <div className="icon"  onClick={() => searchRecord()}>
                                  <img
                                    src={config.BASE_URL + "/assets/images/search.png"}
                                    alt="search-icon"
                                    width={15}
                                    height={15}
                                  />
                                </div>
                              </div>
                              </div>


                              {/* <div className="input-group">
                                <span className="input-group-text">
                                  <img
                                    src="/assets/images/search.png"
                                    alt="search-icon"
                                    width="15"
                                    height="15"
                                  />
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={props.t("Search")}
                                  onKeyDown={handleChangeSearch}
                                />
                              </div> */}
                            </div>
                          </div>
                       
                      </div>
                      <div className="mob-pr-0 specialiry my-4 mob-mb-0 mob-mt-70 mob-pl-8 list-style-none">
                        {/* <h5 className="title mb-4">
                          {" "}
                          {props.t("Medical-Institute")}
                        </h5> */}
                        <ul>
                        <OwlCarousel
                          autoWidth={true}
                          {...options}
                          nav={true}
                          startPosition={topicPosition}
                          navText={[
                            '<span class="interest-arrow interest-prev">‹</span>',
                            '<span class="interest-arrow interest-next">›</span>',
                          ]}
                        >
                          <li key={'allspecialties'}>
                            <a
                              href="javascript:void();"
                              onMouseUp={() => handleChangeTopic("All",0)}
                              className={`${communitypostData.institute_user_id == "All" ? "active" : ""}`}
                            >
                              {props.t("All")}
                            </a>
                          </li>
                          {communityTopic
                            ? communityTopic.map((titem, tindex) => {
                                return (
                                  <div key={tindex} className="mx-1">
                                    <li>
                                      <a
                                        role="button"
                                        onClick={() =>
                                          handleChangeTopic(titem.user_id,tindex)
                                        }
                                        className={
                                          communitypostData.institute_user_id ==
                                          titem.user_id
                                            ? "active "
                                            : "cursor pointer"
                                        }
                                      >
                                        {titem?.institution_name}
                                      </a>
                                    </li>
                                  </div>
                                );
                              })
                            : ""}
                        </OwlCarousel>
                        </ul>
                       
                      </div>
                    </div>
                    <div className="col-md-12 bg-m-white">
                      {communitypost.length > 0 && (
                        <InfiniteScroll
                          dataLength={communitypost.length}
                          next={fetchMoreData}
                          loader={
                            <h4 className="w-50 h-40 text-[18px]">
                              Loading...
                            </h4>
                          }
                          inverse={true}
                          hasMore={true}
                          endMessage={
                            <p
                              className="text-[16px]"
                              style={{ textAlign: "center", marginTop: "10px" }}
                            >
                              {" "}
                              <b>No more post</b>{" "}
                            </p>
                          }
                        >
                          {communitypost.length > 0 ? (
                            communitypost.map((item, i) => {
                              if (i === 2) {
                                return (
                                  <>
                                    <RightAdvertiseBanner
                                      banner={banners}
                                      className="mb-3 p-20 d-none"
                                    />
                                    <div className="bg-gray mb-3" key={i}>
                                      {/* <h3>{item.name_en}</h3> */}
                                      <Link
                                        to={`/community/details/${item.type}/${item.id}`}
                                      >
                                        <h3>
                                          {
                                            i18n.language == 'en' && item.name_en ? (
                                              item.name_en
                                            ) : ( item.name_th )
                                          }
                                        </h3>
                                        <div className="d-flex align-items-center">
                                          {/* <div>
                                                                                <img
                                                                                    src="/assets/images/profile.png"
                                                                                    className="rounded"
                                                                                    alt=""
                                                                                    width="40"
                                                                                    height="40"
                                                                                />
                                                                            </div> */}
                                          <div className="ml-1">
                                            <h5 className="mb-0">
                                              {item.User.first_name}&nbsp;
                                              {item.User.last_name}
                                            </h5>
                                            <span>
                                              {moment(item.post_date).fromNow()}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="slide-gallery my-3">
                                          {item.CommunityPostMedia ? (
                                            item?.CommunityPostMedia[0]
                                              ?.image_url ? (
                                              <img
                                                src={
                                                  item?.CommunityPostMedia[0]
                                                    ?.image_url
                                                }
                                                className="w-100 img-rouded-5"
                                              />
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div className="slide-gallery my-3">
                                          <div className="d-flex  column-list col-md-3 row-cols-4 w-100">
                                            {item.CommunityPostMedia
                                              ? item.CommunityPostMedia.map(
                                                  (comitem, comindx) => {
                                                    return comindx !== 0 ? (
                                                      comindx <= 3 ? (
                                                        <div
                                                          className="column"
                                                          key={comindx}
                                                        >
                                                          <img
                                                            className="demo cursor w-100 h-75 img-fluid rounded-5"
                                                            src={
                                                              comitem?.image_url
                                                            }
                                                            alt=""
                                                          />
                                                        </div>
                                                      ) : comindx === 4 ? (
                                                        <div className="column ">
                                                          <Link
                                                            to={`/community/details/${item.type}/${item.id}`}
                                                          >
                                                            <img
                                                              className="demo cursor w-100 h-75 img-fluid "
                                                              src={
                                                                comitem?.image_url
                                                              }
                                                              alt=""
                                                            />
                                                            <div className="overlay  h-75">
                                                              <span className="image-count h-25 ">
                                                                +
                                                                {item
                                                                  .CommunityPostMedia
                                                                  .length - 3}
                                                              </span>
                                                            </div>
                                                          </Link>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )
                                                    ) : (
                                                      ""
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </div>
                                        </div>
                                      </Link>
                                      <div className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between">
                                        <ul className="d-flex justify-content-between m-0 p-0 image-list w-100">
                                          <li className="d-flex">
                                            {constants.ROLE.COMPANY !==
                                              getRoleID() && (
                                              <div>
                                                {item.isLike == 1 ? (
                                                  <button
                                                    type="button"
                                                    className="btn-dl like-btn"
                                                    onClick={
                                                      () =>
                                                        handleChangeLikeUnlikePost(
                                                          false,
                                                          item.id
                                                        )
                                                      // setCommunityPostId(item.id)
                                                    }
                                                  >
                                                    <img
                                                      src="/assets/images/like.png"
                                                      alt=""
                                                      width=""
                                                      height=""
                                                      className="m-1"
                                                    />
                                                    {props.t("Like")}
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    className="btn-dl dislike-btn"
                                                    onClick={() =>
                                                      handleChangeLikeUnlikePost(
                                                        true,
                                                        item.id
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src="/assets/images/dislike.png"
                                                      alt=""
                                                      width=""
                                                      height=""
                                                      className="m-1"
                                                    />
                                                    {props.t("Like")}
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                            <div className="ml-1">
                                              <ul className="d-flex m-0 p-0 image-list">
                                                {item.Likes.map(
                                                  (litem, linedx) => {
                                                    if (linedx <= 3) {
                                                      return (
                                                        <li>
                                                          <a href="">
                                                            <img
                                                              src={
                                                                litem.User
                                                                  .profile_image
                                                                  ? `${config.IMAGE_URL}${litem.User.profile_image}`
                                                                  : require("assets/images/profile.png")
                                                              }
                                                              className="rounded"
                                                              alt=""
                                                              width="35"
                                                              height="35"
                                                            />
                                                            {linedx === 3 && (
                                                              <span className="image-count">
                                                                {item.totalLikeCount -
                                                                  4 >
                                                                0
                                                                  ? `+${
                                                                      item.totalLikeCount -
                                                                      4
                                                                    }`
                                                                  : ""}
                                                              </span>
                                                            )}
                                                          </a>
                                                        </li>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                            {/* <li className="ml-1">
                                                                                        {item.Likes.map((litem, linedx) => {
                                                                                            return (
                                                                                                <a>
                                                                                                    {" "}
                                                                                                    <img
                                                                                                        src={litem.User.profile_image}
                                                                                                    />{" "}
                                                                                                </a>
                                                                                            );
                                                                                        })}
                                                                                    </li> */}
                                          </li>

                                          <Link
                                            to={`/community/details/${item.type}/${item.id}`}
                                            className="view-text2 pt-1"
                                          >
                                            {props.t("Comments")} (
                                            {item.totalCommentCount})
                                          </Link>
                                        </ul>
                                      </div>
                                    </div>
                                  </>
                                );
                              } else {
                                return (
                                  <div className="bg-gray mb-3" key={i}>
                                    {/* <h3>{item.name_en}</h3> */}
                                    <Link
                                      to={`/community/details/${item.type}/${item.id}`}
                                    >
                                      <h3>
                                        {
                                            i18n.language == 'en' && item.name_en ? (
                                              item.name_en
                                            ) : ( item.name_th )
                                        }
                                        </h3>
                                      <div className="d-flex align-items-center">
                                        {/* <div>
                                                                                <img
                                                                                    src="/assets/images/profile.png"
                                                                                    className="rounded"
                                                                                    alt=""
                                                                                    width="40"
                                                                                    height="40"
                                                                                />
                                                                            </div> */}
                                        <div className="ml-1">
                                          <h5 className="mb-0">
                                            {item.User.first_name}&nbsp;
                                            {item.User.last_name}
                                          </h5>
                                          <span>
                                            {moment(item.post_date).fromNow()}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="slide-gallery my-3">
                                        {item.CommunityPostMedia ? (
                                          item?.CommunityPostMedia[0]
                                            ?.image_url ? (
                                            <div>
                                            <img className="center-cropped"  src={item?.CommunityPostMedia[0]
                                            ?.image_url} />
                                            </div>

                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="slide-gallery my-3">
                                        <div className="d-flex  column-list col-md-3 row-cols-4 w-100">
                                          {item.CommunityPostMedia
                                            ? item.CommunityPostMedia.map(
                                                (comitem, comindx) => {
                                                  return comindx !== 0 ? (
                                                    comindx <= 3 ? (
                                                      <div
                                                        className="column"
                                                        key={comindx}
                                                      >
                                                        <img
                                                          className="demo cursor w-100 h-75 img-fluid rounded-5"
                                                          src={
                                                            comitem?.image_url
                                                          }
                                                          alt=""
                                                        />
                                                      </div>
                                                    ) : comindx === 4 ? (
                                                      <div className="column ">
                                                        <Link
                                                          to={`/community/details/${item.type}/${item.id}`}
                                                        >
                                                          <img
                                                            className="demo cursor w-100 h-75 img-fluid "
                                                            src={
                                                              comitem?.image_url
                                                            }
                                                            alt=""
                                                          />
                                                          <div className="overlay  h-75">
                                                            <span className="image-count h-25 ">
                                                              +
                                                              {item
                                                                .CommunityPostMedia
                                                                .length - 3}
                                                            </span>
                                                          </div>
                                                        </Link>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )
                                                  ) : (
                                                    ""
                                                  );
                                                }
                                              )
                                            : ""}
                                        </div>
                                      </div>
                                    </Link>
                                    <div className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between">
                                      <ul className="d-flex justify-content-between m-0 p-0 image-list w-100">
                                        <li className="d-flex">
                                          {constants.ROLE.COMPANY !==
                                            getRoleID() && (
                                            <div>
                                              {item.isLike == 1 ? (
                                                <button
                                                  type="button"
                                                  className="btn-dl like-btn"
                                                  onClick={
                                                    () =>
                                                      handleChangeLikeUnlikePost(
                                                        false,
                                                        item.id
                                                      )
                                                    // setCommunityPostId(item.id)
                                                  }
                                                >
                                                  <img
                                                    src="/assets/images/like.png"
                                                    alt=""
                                                    width=""
                                                    height=""
                                                    className="m-1"
                                                  />
                                                  {props.t("Like")}
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn-dl dislike-btn"
                                                  onClick={() =>
                                                    handleChangeLikeUnlikePost(
                                                      true,
                                                      item.id
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src="/assets/images/dislike.png"
                                                    alt=""
                                                    width=""
                                                    height=""
                                                    className="m-1"
                                                  />
                                                  {props.t("Like")}
                                                </button>
                                              )}
                                            </div>
                                          )}
                                          <div className="ml-1">
                                            <ul className="d-flex m-0 p-0 image-list">
                                              {item.Likes.map(
                                                (litem, linedx) => {
                                                  if (linedx <= 3) {
                                                    return (
                                                      <li>
                                                        <a href="">
                                                          <img
                                                            src={
                                                              litem.User
                                                                .profile_image
                                                                ? `${config.IMAGE_URL}${litem.User.profile_image}`
                                                                : require("assets/images/profile.png")
                                                            }
                                                            className="rounded"
                                                            alt=""
                                                            width="35"
                                                            height="35"
                                                          />
                                                          {linedx === 3 && (
                                                            <span className="image-count">
                                                              {item.totalLikeCount -
                                                                4 >
                                                              0
                                                                ? `+${
                                                                    item.totalLikeCount -
                                                                    4
                                                                  }`
                                                                : ""}
                                                            </span>
                                                          )}
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                          {/* <li className="ml-1">
                                              {item.Likes.map((litem, linedx) => {
                                                  return (
                                                      <a>
                                                          {" "}
                                                          <img
                                                              src={litem.User.profile_image}
                                                          />{" "}
                                                      </a>
                                                  );
                                              })}
                                          </li> */}
                                        </li>

                                        <Link
                                          to={`/community/details/${item.type}/${item.id}`}
                                          className="view-text2 pt-1"
                                        >
                                          {props.t("Comments")} (
                                          {item.totalCommentCount})
                                        </Link>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <h1>{props.t("No-News-Found")}</h1>
                          )}
                        </InfiniteScroll>
                      )}
                    </div>

                    {/* <div className="col-md-2 d-m-none">
                        <div className="img-block mb-3">
                            <img
                                src="/assets/images/image004.png"
                                className="img-fluid"
                                alt=""
                                width=""
                                height=""
                            />
                        </div>
                        <div className="img-block mb-3">
                            <img
                                src="/assets/images/image004.png"
                                className="img-fluid"
                                alt=""
                                width=""
                                height=""
                            />
                        </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightAdvertiseBanner
              banner={banners}
              className="col-md-3 pt-3 communityBanner"
            />
          </div>
        </div>
      </section>
      {/* <!-- In focus End --> */}
      <Footer />
    </>
  );
}

export default withTranslation()(Community);
