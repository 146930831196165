import React, { useEffect, useState } from "react";
import { NavLink, Link, useParams } from "react-router-dom";
import { EducationApi } from "../../apis/EducationApi";
import config from "../../config";
import * as url from "../../helpers/common_helper";
import { getUser } from "../../Token";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import MobileHeaderArrowBanner from "../../components/Layout/Mobile/MobileHeaderArrowBanner";
import { withTranslation } from "react-i18next";
import constants from "../../constants";
import i18n from "../../i18n";


const ReviewBooking = (props) => {
  const user = getUser();
  const params = useParams();
  const activity_id = atob(params.activity_id);

  const [activeData, setactiveData] = useState({ activity_id: activity_id });
  const [activity, setActivityData] = useState([]);
  const [image, setActivityImage] = useState("");
  const [files, setActivityFile] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    getActivityDetails(activeData);
  }, []);

  const getActivityDetails = (data) => {
    EducationApi.getActivityDetails(data)
      .then((res) => {
        if (res.data.success) {
          setActivityData(res.data.data);
          setActivityImage(res.data.data.ActivityMediaImage[0].file_url);
          setActivityFile(res.data.data.ActivityMediaFile);
        } else {
          setActivityData([]);
          setActivityImage("");
          setActivityFile([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmBooking = () => {
    const activityData = { activity_id: parseInt(activity_id) };
    EducationApi.confirmBooking(activityData)
      .then((res) => {
        if (res.data.success) {
          window.location.href = "/booking";
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          //toastr.error(error?.response?.data?.message, '');
        } else {
          // setError(error?.response?.data?.error?.errors[0].msg);
        }
      });
  };

  return (
    <div>
      <Navbar />
      <MobileBottomMenu />

      <section className="mt-5 d-m-none">
        <div className="container">
          <div className="row">
            <div className="col-md-12 list-style-none">
              <ul className="breadcrumb mt-3">
                <li>
                  <a href="javascript:void(0);">{props.t("Activity")}</a>
                </li>
                <li>/</li>
                <li className="active">{
                          i18n.language == 'en' && activity.title_en ? (
                          activity.title_en
                          ) : ( activity.title_th)
                        }</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <>
              <MobileHeaderArrowBanner  back_page_url="education" />
            </> */}

      {/* <!-- Desktop Booking Start --> */}
      <section className="pt-3 pb-5 d-m-none">
        <div className="container">
          <div className="row p-20">
            <div className="review-bg">
              <div className="booking mt-4 mob-mt-0">
                <h3 className="title my-3 mob-mt-0">
                  {props.t("Review-Booking-Information")}
                </h3>
                <div className="row mb-4">
                  <div className="col-md-6 coupon">
                    <div className="d-flex">
                      <div
                        className={`block1 ${
                          activity.type == "Online" ? "bg-green" : ""
                        }`}
                      >
                        <img
                          src="assets/images/qr-code.png"
                          className=""
                          alt=""
                          width=""
                          height=""
                        />
                        <span>{props.t("Date-And-Time")}</span>
                        <h4>{url.dateFormat(activity.activity_date)}</h4>
                        <h5>
                          {url.TimeFormat(activity.start_time)} -{" "}
                          {url.TimeFormat(activity.end_time)}
                        </h5>
                        <ul>
                          {/* <li>
                              <span>Seal</span>
                              <strong>23A</strong>
                          </li> */}
                          <li>
                            <span>{props.t("Price")}</span>
                            <strong>
                              {activity.fee_type == "FREE"
                                ? "Free"
                                : "฿ " + activity.fees}
                            </strong>
                          </li>
                        </ul>
                      </div>
                      <div className="block2">
                        <a href="">
                          <img
                            src={image ? image : require("assets/images/no-image.png")}
                            className=""
                            alt=""
                            width=""
                            height=""
                          />
                        </a>
                        <a href="">
                          <h4>{
                          i18n.language == 'en' && activity.title_en ? (
                          activity.title_en
                          ) : ( activity.title_th)
                        }</h4>
                        </a>
                        <span className="text-center">
                        {activity?.type == constants.TYPE.Hybrid
                            ? "Online/Onsite"
                            : activity?.type}
                        </span>
                      </div>
                    </div> 
                  </div>
                  <div className="col-md-6 c-details list-style-none">
                    <div className="d-flex">
                      <div>
                        <ul>
                          <li>
                            <span>{props.t("First-Name")}</span>
                            <strong>{user?.first_name}</strong>
                          </li>
                          <li>
                            <span>{props.t("Email")}</span>
                            <strong>{user?.email}</strong>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <ul>
                          <li>
                            <span>{props.t("Last-Name")}</span>
                            <strong>{user?.last_name}</strong>
                          </li>
                          <li>
                            <span>{props.t("Mobile-Phone-Number")}</span>
                            <strong>{user?.mobile}</strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {/* <h3 className="title my-3">Payment Method</h3>
                  <div className="row">
                      <div className="col-md-5">
                          <div className="pblock">
                              <img
                                  src="assets/images/image 57.png"
                                  alt=""
                                  className=""
                                  width=""
                                  height=""
                              />
                              <div>
                                  <strong>Kittiya Yuthasastrkosol</strong>
                                  <span>Promptpay 0901234567</span>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-5">
                          <div className="pblock">
                              <img
                                  src="assets/images/image 58.png"
                                  alt=""
                                  className=""
                                  width=""
                                  height=""
                              />
                              <div>
                                  <strong>Kittiya Yuthasastrkosol</strong>
                                  <span>SCB 5052565979</span>
                              </div>
                          </div>
                      </div>
                  </div> */}
                {error != "" && (
                  <div className="alert alert-danger alert-dismissible">
                    {error}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="clearfix" />
              <div className="mob-bg-white">
                <button
                  onClick={() => confirmBooking()}
                  className="btn btn-join mt-4"
                >
                  {props.t("Confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Booking End --> */}

      {/* Mobile View Top Start */}
      {/* <section className="bg-red mob-booking d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 bg-red" style="display: none">
                            <div className="d-flex align-items-center justify-content-between mob-mt-39">
                                <div>
                                    <Link to="/booking"><img src="assets/images/768/arrow-left-w.png" className="" alt=""
                                        width="" height="" /></Link>
                                </div>
                                <div>
                                    <h4 className="mb-0 text-white">Booking Detail</h4>
                                </div>
                                <div>

                                </div>
                            </div>
                            <div className="m-coupon red">
                                <div className="text-center mh-300">
                                    <div className="qr">
                                        <img src="assets/images/768/qrcode.png" className="opacity-2 mb-2" alt="" width=""
                                            height="" />
                                        <div className="overlay-text text-red">
                                            Cancelled
                                        </div>
                                    </div>
                                    <p className="opacity-2">Please show this QR code to enter the event and don’t share this QR to
                                        other people.</p>
                                </div>
                                <div className="m-coupon-data">
                                    <div>
                                        <a href="">
                                            <img src="assets/images/768/Ellipse 98.png" className="rounded" width="97" height=""
                                                alt="" />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="">
                                            <h4 className="mb-0">{activity.title_en}</h4>
                                        </a>
                                        <span className="text-center">
                                            <img src="assets/images/location-marker.png" className="" width="" height="" alt="" />
                                            {activity.type}
                                        </span>
                                    </div>
                                </div>

                                <div className="mt-3 mb-2 m-data">
                                    <div className="d-flex">
                                        <div className="w-130">
                                            <span>Date</span>
                                            <h4>{url.dateFormat(activity.activity_date)}</h4>
                                        </div>
                                        <div>
                                            <span>Time</span>
                                            <h4>{url.TimeFormat(activity.start_time)} - {url.TimeFormat(activity.end_time)}</h4>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="w-130">
                                            <span>Seal</span>
                                            <h4>23A</h4>
                                        </div>
                                        <div>
                                            <span>Price</span>
                                            <h4>{activity.fee_type == 'FREE' ? 'Free' : '฿ ' + activity.fees}</h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* Mobile View Top End */}

      {/* Mobile View Top Start */}
      <section className="bg-blue2 mob-booking d-none">
        <div className="container">
          <div className="row">
            <div className={`col-md-12 bg-green2 green`}>
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                  <Link to="/booking">
                    <img
                      src="/assets/images/768/arrow-left-w.png"
                      className=""
                      alt=""
                      width=""
                      height=""
                    />
                  </Link>
                </div>
                <div>
                  <h4 className="mb-0 text-white">{props.t("Booking-Detail")}</h4>
                </div>
                <div></div>
              </div>
              <div className={`m-coupon mobBookingDetailHeight green`}>
                <div className="m-coupon-data">
                  <div>
                    <a href="#">
                      <img
                        src={
                          image
                            ? image
                            : require("assets/images/no-image.png")
                        }
                        className="rounded"
                        width="97"
                        height=""
                        alt=""
                      />
                    </a>
                  </div>
                  <div>
                    <a href="#">
                      <h4 className="mb-0">{
                          i18n.language == 'en' && activity.title_en ? (
                          activity.title_en
                          ) : ( activity.title_th)
                        }</h4>
                    </a>
                    <span className="text-center">
                      <img
                        src="/assets/images/location-marker.png"
                        className=""
                        width=""
                        height=""
                        alt=""
                      />
                      {activity?.type == constants.TYPE.Hybrid
                        ? 'Online/Onsite' 
                        : activity?.type
                        }
                    </span>
                  </div>
                </div>
                <div className="mt-3 mb-2 m-data">
                  <div className="d-flex">
                    <div className="w-130">
                      <span>{props.t("Date")}</span>
                      <h4>{url.dateFormat(activity?.activity_date)}</h4>
                    </div>
                    <div>
                      <span>{props.t("Time")}</span>
                      <h4>
                        {url.TimeFormat(activity?.start_time)} -{" "}
                        {url.TimeFormat(activity?.end_time)}
                      </h4>
                    </div>
                  </div>
                  <div className="d-flex">
                    {/* <div className="w-130">
                      <span>Seal</span>
                      <h4>23A</h4>
                    </div> */}
                    <div>
                      <span>{props.t("Price")}</span>
                      <h4>
                        {activity?.fee_type == "FREE"
                          ? "Free"
                          : "฿ " + activity?.fees}
                      </h4>
                    </div>
                  </div>
                  <div className="mb-3 m-btn">
                          <button
                          type="button"
                          onClick={() => confirmBooking()}
                          target="_blank"
                          className="btn btn-join2 bg-primary"
                        >
                          {props.t("Confirm")}
                        </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Mobile View Top End */}

      {/* <!-- Footer Start --> */}
      <Footer />
      {/* <!-- Footer End --> */}
    </div>
  );
}

export default withTranslation()(ReviewBooking)
