import { combineReducers } from "redux";
import { homeReducers } from "./home.reducer";
import { userReducers } from "./user.reducer";

const reducers = combineReducers({
    banner: homeReducers,
    upactivity: homeReducers,
    news: homeReducers,
    profile_image: userReducers
});
export default reducers;
