import React from "react";
import { withTranslation } from "react-i18next";
import config from "../../config";


function Footer(props) {
    return (
        <div>
            <footer className="d-m-nonee">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center list-style-none">
                                <p>
                                ศูนย์การศึกษาต่อเนื่องของแพทย์<br />
                                กระทรวงสาธารณสุข สำนักงานเลขาธิการแพทยสภา <br />
                                อาคารมหิตลาธิเบศร ชั้น 12 เลขที่ 88/19<br />
                                ซอยสาธารณสุข 8 อำเภอเมือง นนทบุรี 11000

                                </p>
                                <ul className="my-4" style={{paddingLeft: "unset"}}>
                                    <li>
                                        <img src={config.BASE_URL + '/assets/images/call.png'} alt="" width="" height="" className="img-fluid" />
                                        09 8741 6587
                                    </li>
                                    <li>
                                        <img src={config.BASE_URL + '/assets/images/Message.png'} alt="" width="" height="" className="img-fluid" />
                                        ccme.or.th@gmail.com
                                    </li>
                                </ul>
                                <ul style={{paddingLeft: "unset"}}>
                                    <li>
                                        <span>{props.t("The-Medical-Council-Of-Thailand")}</span>
                                    </li>
                                    <li>
                                        <a href="/privacy-policy" target="_blank" className="text-blue">{props.t("Privacy-Policy")}</a>
                                    </li>
                                    <li>
                                        <a href="/terms-and-condition" target="_blank" className="text-blue">{props.t("Terms-Conditions")}</a>
                                    </li>
                                </ul>
                                <ul>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
export default withTranslation()(Footer)