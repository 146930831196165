import { withTranslation } from "react-i18next";

const EvaluationFormDetails = (props) => (
  <div className="dashboard-inner mb-3" style={{ minHeight: "auto" }}>
    <div className="row">
      <div className="col-md-6">
        <h2 className="mb-5 d-m-none">{props.title}</h2>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="position-relative ">
          {Array.isArray(props.evaluationFormDetails) &&
            props.evaluationFormDetails.length > 0 && (
              <div className="row">
                <div className="col-md-12 mb-2 d-none">
                  <h6> {props.t("Evaluation-Details")}</h6>
                </div>
                {props.evaluationFormDetails.map((item, index) => {
                  return (
                    <>
                      <div className="row mb-3">
                        <div className="col-md-3 col-4">
                          <h6> {item?.UserEvalutionFormField?.label_en}</h6>
                        </div>
                        <div className="col-md-9 col-8">
                          <h6>
                            {": "} {item.field_value}
                          </h6>
                        </div>
                      </div>
                      {item.remark !== null ? (
                        <div className="row mb-3">
                          <div className="col-md-3 col-4">
                            <h6> {props.t("Remark")}</h6>
                          </div>
                          <div className="col-md-9 col-8">
                            <h6>
                              {": "} {item.remark}
                            </h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
                {props.internSubmittedResult && (
                  <>
                    <div className="col-sm-12">
                      <span className="text-green">
                        {props.internSubmittedResult?.type === "Accepted"
                          ? props.t("internAcceptedResult")
                          : props.t("internSubmittedConcern")}
                      </span>
                      {props.internSubmittedResult?.type !== "Accepted" && (
                        <p>{props.internSubmittedResult.concern_comment}</p>
                      )}
                    </div>
                    {props.type === "evaluation" &&
                    !props.checkTaskSubmittedToHod ? (
                      <div className="col-sm-12">
                        <div className="justify-content-between align-items-center">
                          <div className="float-right">
                            <button
                              type="button"
                              className="btn btn-join"
                              onClick={props.onSendEvaluationToHOD}
                            >
                              {props.t("sendToHod")}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      props.type === "evaluation" && (
                        <div className="col-sm-12">
                          <div className="justify-content-between align-items-center">
                            <span className="text-green">
                              {props.t("youHaveSubmittedToHod")}
                            </span>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  </div>
);

export default withTranslation()(EvaluationFormDetails);
