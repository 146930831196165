import constants from "constants";
import { withTranslation } from "react-i18next";
import Select from "react-select";

const EvaluationFormFields = (props) => {
  return (
    <div className="row application-form">
      {props.evaluationFormFields?.map((field, index) => {
        index = index + 1;

        if (field.formType === "TEXT") {
          return (
            <div className="col-md-6" key={`evaluationFormFiels${index}`}>
              <label htmlFor="" className="form-label">
                {index}. {field.fieldLabel}{" "}
                {field.isRequired ? <span className="text-danger">*</span> : ""}
              </label>
              <input
                type="text"
                className="form-control"
                id=""
                name={`field_${field.fieldId}`}
                placeholder={field.fieldLabel}
                onChange={(e) =>
                  props.onHandleChange(
                    field.fieldId,
                    field.formType,
                    e.target.value,
                    "",
                    index - 1
                  )
                }
              />
              {props.validationErr[`field_${field.fieldId}`] ? (
                <p className="text-danger text-small">
                  {props.validationErr[`field_${field.fieldId}`]}
                </p>
              ) : (
                ""
              )}
            </div>
          );
        } else if (field.formType === "MULTITEXT") {
          return (
            <div className="col-md-12" key={`evaluationFormFiels${index}`}>
              <label htmlFor="" className="form-label">
                {index}. {field.fieldLabel}{" "}
                {field.isRequired ? <span className="text-danger">*</span> : ""}
              </label>
              <textarea
                rows={5}
                className="form-control"
                id=""
                name={`field_${field.fieldId}`}
                placeholder={field.fieldLabel}
                style={{ height: "unset" }}
                onChange={(e) =>
                  props.onHandleChange(
                    field.fieldId,
                    field.formType,
                    e.target.value,
                    "",
                    index - 1
                  )
                }
              />
              {props.validationErr[`field_${field.fieldId}`] ? (
                <p className="text-danger text-small">
                  {props.validationErr[`field_${field.fieldId}`]}
                </p>
              ) : (
                ""
              )}
            </div>
          );
        } else if (field.formType === "QUESTION") {
          return (
            <div className="col-md-6" key={`evaluationFormFiels${index}`}>
              <label className="form-label">
                {index}. {field.fieldLabel}{" "}
                {field.isRequired ? <span className="text-danger">*</span> : ""}
              </label>
              <Select
                styles={{
                  control: (styles) => ({
                    ...styles,
                    borderRadius: "50px",
                    marginBottom: "15px",
                  }),
                }}
                name={`field_${field.fieldId}`}
                options={field?.EvaluationFormFieldOption.map((option, i) => ({
                  value: option.id,
                  label: `${option.option_en}`,
                }))}
                onChange={(e) =>
                  props.onHandleChange(
                    field.fieldId,
                    field.formType,
                    e.value,
                    e.label,
                    index - 1
                  )
                }
              />
              {props.validationErr[`field_${field.fieldId}`] ? (
                <p className="text-danger text-small mb-3">
                  {props.validationErr[`field_${field.fieldId}`]}
                </p>
              ) : (
                ""
              )}

              {field.isRemark ? (
                <textarea
                  rows={5}
                  className="form-control"
                  id=""
                  name={`field_remark_${field.fieldId}`}
                  placeholder={props.t("Remark")}
                  style={{ height: "unset" }}
                  onChange={(e) =>
                    props.onHandleChange(
                      field.fieldId,
                      field.formType,
                      e.target.value,
                      "",
                      index - 1,
                      field.isRemark
                    )
                  }
                />
              ) : (
                ""
              )}
            </div>
          );
        }
      })}

      {props?.forPage === "internship" && (
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">
              {props.t("Approval-Status")}{" "}
              <span className="text-danger">*</span>
            </label>
            <Select
              onChange={(e) => {
                props.setStatus(`${e?.value}`);
              }}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderRadius: "50px",
                  marginBottom: "15px",
                }),
              }}
              name="teacherTaskStatus"
              options={Object.keys(constants.APPROVALS).map((key) => ({
                value: `${key}`,
                label: `${key}`,
              }))}
            />
            {props.validationErr.teacherTaskStatus && (
              <p className="text-danger text-small mt-3">
                {props.validationErr.teacherTaskStatus}
              </p>
            )}
          </div>
        </div>
      )}

      <div className="flex-wrap gap-2 mt-3">
        <button
          type="button"
          onClick={props.onSubmitEvaluationForm}
          className="btn btn-join2"
        >
          {props.t("Submit")}
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(EvaluationFormFields);
