import VideoArea from "pages/Components/ui/VideoArea";
import { withTranslation } from "react-i18next";
import config from "../../../config";
import * as url from "../../../helpers/common_helper";

const MobileActivityCardArea = (props) => {
  const activity = props.activity;
  return (
    <>
      <div className="position-relative main-img mb-2">
        {activity?.ActivityMedia.length === 0 ? (
          <img
            className="img-fluid mx-auto"
            src={config.BASE_URL + "/assets/images/default-activity.jpg"}
            alt=""
          />
        ) : activity?.ActivityMedia[0]?.type === "IMAGE" ? (
          <img
            style={{ height: "148px" }}
            className="img-fluid mx-auto"
            src={activity?.ActivityMedia[0]?.file_url}
            alt=""
          />
        ) : activity?.activityVideoThumbnail?.file_url ? (
          <img
            style={{ height: "150px" }}
            className="img-fluid mx-auto"
            src={activity?.activityVideoThumbnail?.file_url}
            alt=""
          />
        ) : (
          <img
            style={{ height: "150px" }}
            className="img-fluid mx-auto"
            src={require("../../../assets/images/videothumbnail.png")}
            alt=""
          />
        )}
        {activity?.is_credit &&
        props?.currentTab !== "ARTICLE" &&
        props?.currentTab !== "Multimedia" ? (
          <span>
            {props?.credits} {props.t("Credit")}
          </span>
        ) : (
          ""
        )}
      </div>
      <h4>{activity.title_en}</h4>
      <div className="list-style-none">
        <ul className="event-list">
          <li>
            <div>
              <img
                className="img-fluid "
                src={config.BASE_URL + "/assets/images/user.png"}
                alt=""
                width={15}
                height=""
              />
            </div>
            <div>
              {activity?.Institute?.institution_name.length >= 16
                ? activity?.Institute?.institution_name.substring(0, 16) + "..."
                : activity?.Institute?.institution_name}
            </div>
          </li>
          {activity.activity_date && (
            <li>
              <div>
                <img
                  className="img-fluid"
                  src={config.BASE_URL + "/assets/images/clock.png"}
                  alt=""
                  width={12}
                  height={12}
                />
              </div>
              <div>{url.dateFormat(activity.activity_date)}</div>
            </li>
          )}
          {activity.type && (
            <li>
              <div>
                <img
                  className="img-fluid "
                  src={config.BASE_URL + "/assets/images/location-marker.png"}
                  alt=""
                  width={12}
                  height={12}
                />
              </div>
              <div>
                {activity.type == "Hybrid" ? "Online/Onsite" : activity.type}
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default withTranslation()(MobileActivityCardArea);
