module.exports = {
  /*dev*/
  // API_URL: "https://api.medxdev.online/",
  // ADMIN_URL: "https://admin.medxdev.online",
  // BASE_URL: "https:///medxdev.online/",
  // IMAGE_URL: "https://api.medxdev.online/storage/images/",

  /*UAT*/
  // API_URL: "https://medxdev.online:8003/",
  // ADMIN_URL: 'http://adminuat.medxdev.online'
  // BASE_URL: "https://uat.medxdev.online/",
  // IMAGE_URL:"https://medxdev.online:8003/storage/images/",

  /*Production*/
  API_URL: "https://api.medxcorporation.com/",
  ADMIN_URL: 'https://admin.medxcorporation.com',
  BASE_URL: "https://medxcorporation.com/",
  IMAGE_URL:"https://api.medxcorporation.com/storage/images/",

  /*Localhost*/
  // IMAGE_URL:"http://localhost:8002/storage/images/",
  // ADMIN_URL: "https://admin.medxdev.online",
  // API_URL: "http://localhost:8002/",
  // BASE_URL: "http://localhost:3000/",

  LIMIT: 10,
  CLIENT_ID: "4Sp7q01ds42i4oHgjwgmv2nZ",
  google_key: "AIzaSyAnT1QYVcj2rsVI4A54LJoU0QNh1w33DPw",
}; 