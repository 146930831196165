import { React, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import FileCard from "../FileCard";
import constants from "../../../constants";
import * as url from "../../../helpers/common_helper";
import config from "../../../config";
import QuizQuestionList from "pages/ActivityJson/QuizQuestionList";

const ActivityDetailsmodal = (props) => {
  const activity = props.data;
  let taskAttachments = activity?.UserTaskActivity?.TaskAttachment;
  let activityMediaFiles = activity?.ActivityMediaFile;
  return (
    <div className="modal fade in" id={`activityDetailsmodal_${activity.id}`}>
      <div className="modal-dialog scrollDialog modal-lg">
        <div className="modal-content mb-5">
          <div className="modal-header">
            <h4 className="modal-title">{props.t("Activity-Detail")}</h4>
          </div>
          <div className="modal-body scrollAndFixedHeight">
            <div className="row">
              <div className="col-md-4 col-4">
                <h6>
                  {props.t("Activity")} {props.t("Name")}
                </h6>
              </div>
              <div className="col-md-8 col-8">
                <p>: {activity?.title_en}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-4">
                <h6>{props.t("Activity-Type")}</h6>
              </div>
              <div className="col-md-8 col-8">
                <p>: {activity?.ActivityType?.activity_group}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-4">
                <h6>{props.t("Activity-Sub-Type")}</h6>
              </div>
              <div className="col-md-8 col-8">
                <p>: {activity?.ActivityType?.code}</p>
              </div>
            </div>
            {activity?.Institute?.id && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("Institution")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {activity?.Institute?.institution_name}</p>
                </div>
              </div>
            )}
            {activity?.ActivitySubDetail?.ActivityDisplayType && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("Activity-Display-Type")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>
                    : {activity?.ActivitySubDetail?.ActivityDisplayType.name_en}
                  </p>
                </div>
              </div>
            )}
            {activity?.SpeakerUser?.id && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>
                    {props.t("Speaker")} {props.t("Name")}
                  </h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>
                    : {activity?.SpeakerUser?.first_name}{" "}
                    {activity?.SpeakerUser?.last_name}
                  </p>
                </div>
              </div>
            )}
            {activity?.Doctor?.id && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>
                    {props.t("Doctor")} {props.t("Name")}
                  </h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>
                    : {activity?.Doctor?.first_name}{" "}
                    {activity?.Doctor?.last_name}
                  </p>
                </div>
              </div>
            )}
            {activity?.type && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("Type")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {activity?.type === constants.TYPE.Hybrid
                        ? 'Online/Onsite' 
                        : activity?.type
                        }
                  </p>
                </div>
              </div>
            )}
            {activity?.online_meeting_url && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("online-meeting-url")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {activity?.online_meeting_url}</p>
                </div>
              </div>
            )}
            {activity?.address && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("Address")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {activity?.address}</p>
                </div>
              </div>
            )}
            {activity?.latitude && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("Latitude")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {activity?.latitude}</p>
                </div>
              </div>
            )}
            {activity?.longitude && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("Longitude")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {activity?.longitude}</p>
                </div>
              </div>
            )}
            {activity?.activity_date && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("Activity-Date")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {url.dateFormat(activity.activity_date)}</p>
                </div>
              </div>
            )}
            {activity?.start_time && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("Start-Time")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {url.TimeFormat(activity.start_time)}</p>
                </div>
              </div>
            )}
            {activity?.end_time && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("End-Time")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {url.TimeFormat(activity.end_time)}</p>
                </div>
              </div>
            )}
            {activity?.ActivitySubDetail?.ActivityContentType && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("Activity-Content-Type")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>
                    :{" "}
                    {activity?.ActivitySubDetail?.ActivityContentType?.name_en}
                  </p>
                </div>
              </div>
            )}
            {activity?.CoAuthor && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>
                    {props.t("Co-Author")} {props.t("Name")}
                  </h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>
                    : {activity?.CoAuthor?.first_name}{" "}
                    {activity?.CoAuthor?.last_name}
                  </p>
                </div>
              </div>
            )}
            {/* {activity?.CoAuthor?.id && (
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <h6>{props.t("Book-Name")}</h6>
                                </div>
                                <div className="col-md-8 col-8">
                                    <p>: {activity?.CoAuthor?.book_name} {" "} {activity?.CoAuthor?.book_name}</p>
                                </div>
                            </div>
                        )}
                        {activity?.CoAuthor?.id && (
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <h6>{props.t("Chapter-Name")}</h6>
                                </div>
                                <div className="col-md-8 col-8">
                                    <p>: {activity?.CoAuthor?.first_name} {" "} {activity?.CoAuthor?.last_name}</p>
                                </div>
                            </div>
                        )} */}
            {activity.ActivityMediaFile[0]?.AttachmentType && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("Attachment-Type")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>
                    : {activity?.ActivityMediaFile[0]?.AttachmentType?.name_en}
                  </p>
                </div>
              </div>
            )}

            {activity?.ActivitySubDetail?.TaskType && (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>{props.t("task")}</h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {activity?.ActivitySubDetail?.TaskType?.name_en}</p>
                </div>
              </div>
            )}

            {(activity?.ActivitySubDetail?.task_type_id ===
              constants.TASKTYPE.INTERVIEWTEST ||
              activity?.ActivitySubDetail?.task_type_id ===
                constants.TASKTYPE.MEQSHORTASSAY) &&
              activity?.ActivitySubDetail.amount_of_examinee && (
                <div className="row">
                  <div className="col-md-4 col-4">
                    <h6>{props.t("amountOfExaminee")}</h6>
                  </div>
                  <div className="col-md-8 col-8">
                    <p>: {activity?.ActivitySubDetail.amount_of_examinee}</p>
                  </div>
                </div>
              )}

            {activityMediaFiles && (
              <>
                {Array.isArray(activityMediaFiles) &&
                  activityMediaFiles.length > 0 && (
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <h6> {props.t("Attachments")}</h6>
                      </div>
                      {activityMediaFiles.map((item, index) => {
                        return (
                          <div className="col-md-12">
                            <div className="pdf-block">
                              {/* <FileCard key={`activityM_${index}`} item={item}/> */}
                              <a
                                href={`${config.IMAGE_URL}${item.file_url}`}
                                target="_blank"
                              >
                                <div className="pblock3 d-flex align-items-center mb-3">
                                  <div className="image">
                                    <img
                                      src="/assets/images/link.png"
                                      alt="link"
                                    />
                                  </div>
                                  <div className="text">
                                    <strong>{item.name}</strong>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
              </>
            )}

            <div className="row">
              <div className="col-md-12">
                <h6>{props.t("Description")}</h6>
              </div>
              <div className="col-md-12">
                <p>{activity?.description_en}</p>
              </div>
              {/* <div className="col-md-8 col-6">
                                <p>: {activity?.UserTaskActivity?.submited_details}</p>
                            </div> */}
            </div>

            {activity?.Question.length > 0 && (
              <div className="row">
                <div className="col-md-12">
                  <h6>{props.t("Quiz")} :</h6>
                </div>
                <QuizQuestionList
                  from="view"
                  onEditQuizQuestionHanlder=""
                  onDeletetQuizQuestionHanlder=""
                  isInstituteFiledEnable={false}
                  questions={activity?.Question}
                />
              </div>
            )}

            {activity?.UserTaskActivity &&
              activity.UserTaskActivity.status ==
                constants.APPROVAL_STATUS.Submited && (
                <>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <h6>{props.t("Submited-Details")}</h6>
                    </div>
                    <div className="col-md-12 col-12">
                      <p>{activity?.UserTaskActivity?.submited_details}</p>
                    </div>
                  </div>
                  {Array.isArray(taskAttachments) &&
                    taskAttachments.length > 0 && (
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <h6> {props.t("referenceFile")}</h6>
                        </div>
                        {taskAttachments.map((item, index) => {
                          return (
                            <div className="col-md-12">
                              <div className="pdf-block">
                                {/* <FileCard key={`activityM_${index}`} item={item}/> */}
                                <a
                                  href={`${config.IMAGE_URL}${item.file_url}`}
                                  target="_blank"
                                >
                                  <div className="pblock3 d-flex align-items-center mb-3">
                                    <div className="image">
                                      <img
                                        src="/assets/images/link.png"
                                        alt="link"
                                      />
                                    </div>
                                    <div className="text">
                                      <strong>{item.title_en}</strong>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                </>
              )}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ActivityDetailsmodal);
