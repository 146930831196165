import React, { useEffect, useState } from "react";
import { NavLink, Link, useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import upevent1 from "../../assets/images/right-arrow.png";
import { EducationApi } from "../../apis/EducationApi";
import { BookmarkApi } from "../../apis/BookmarkApi";
import config from "../../config";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import { withTranslation } from "react-i18next";


function EducationLibraryDetails(props) {
  const params = useParams();
  const library_id = params.library_id;
  const [library, setLibraryData] = useState({ library_id: library_id });
  const [isBookmark, setBookmark] = useState(false);

  useEffect(() => {
    getLibraryDetails(library);
  }, []);

  const getLibraryDetails = (data) => {
    EducationApi.getLibraryDetails(data)
      .then((res) => {
        if (res.data.success) {
          setLibraryData(res.data.data);
          setBookmark(res.data.data.isBookmark);

        } else {
          setLibraryData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleLibraryclick(libid) {
    //add bookmark
    const postLibraryBookmark = (data) => {
      BookmarkApi.postLibraryBookmark(data)
        .then((res) => {
          setBookmark(true);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const adddata = {
      "library_id": libid
    }

    postLibraryBookmark(adddata);

  }


  return (
    <div>
      <Navbar />
      <MobileBottomMenu />
      <>

        {/* Mobile View Top Start */}
        <div className="mobile-detail-bg d-none">
          <img className="img-fluid" src={config.BASE_URL + '/assets/images/768/image 15.png'} alt="" />
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                  <a href="education-cme.html">
                    <img
                      src={config.BASE_URL + '/assets/images/768/arrow-left.png'}
                      className="p-fixed"
                      alt=""
                      width=""
                      height=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View Top End */}


        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row">
              <div className="col-md-12 list-style-none">
                <ul className="breadcrumb mt-3">
                  <li>
                    <a href="">Education</a>
                  </li>
                  <li>/</li>
                  <li className="active">{library.education_type} : Library</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* Library Detail Start */}
        <section className="pt-3 pb-5 details top-curve">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="event-item d-flex justify-content-between">
                  <div className="news-info w-90">
                    <div>
                      <span className="credits">5 {props.t("Credit")}</span>
                      <h4 className="mt-3 mb-5">
                        Distribution of HLA-B*13:01 allele related with
                        dapsone-induced severe cutaneous adverse reaction in Thai{" "}
                      </h4>
                    </div>
                    <div className="list-style-none">
                      <ul className="event-list">
                        <li>
                          <div>
                            <img
                              className="img-fluid"
                              src={config.BASE_URL + '/assets/images/clock.png'}
                              alt=""
                              width={15}
                              height=""
                            />
                          </div>
                          <div>16 Sep 2022 09:01 am</div>
                        </li>
                        <li>
                          <div>
                            <img
                              className="img-fluid "
                              src={config.BASE_URL + '/assets/images/location-marker.png'}
                              alt=""
                              width={15}
                              height=""
                            />
                          </div>
                          <div>
                            Bangkok Marriott Hotel Sukhumvit, Pool Side, Bangkok,
                            Thailand
                          </div>
                        </li>
                        <li>
                          <div>
                            <img
                              className="img-fluid "
                              src={config.BASE_URL + '/assets/images/user.png'}
                              alt=""
                              width={15}
                              height=""
                            />
                          </div>
                          <div>SICres</div>
                        </li>
                      </ul>
                      <div className="specialiry mt-4">
                        <ul>
                          {library?.ActivityInterest?.map((interest, i) => (
                            <li key={i}>
                              <a href="" className="">
                                {interest?.Interest?.name_en}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {!isBookmark ?
                    <>
                      <Link onClick={() => handleLibraryclick(library.id)}
                        to=""
                        data-bs-dismiss="modal"
                        data-bs-target="#bookmarkto"
                        data-bs-toggle="modal"
                      >
                        <div className="bookmark bookmark-show">
                          <div>
                            <img
                              className="img-fluid"
                              src={config.BASE_URL + '/assets/images/bookmark-outline.png'}
                              alt=""
                              width={25}
                              height=""
                            />
                          </div>
                        </div>
                      </Link></> :  <>
                      <div className="bookmark bookmark-show">
                        <div>
                          <img
                            className="img-fluid"
                            src={config.BASE_URL + '/assets/images/bookmark.png'}
                            alt=""
                            width={25}
                            height=""
                          />
                        </div>
                      </div>
                    </>
                  }

                  <div className="bookmark-hide bookmark">
                    <div>
                      <img
                        className="img-fluid"
                        src={config.BASE_URL + '/assets/images/bookmark.png'}
                        alt=""
                        width={25}
                        height=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 event-content my-4 plr-80">
                <br />
                <div dangerouslySetInnerHTML={{ __html: library.description_en }} />
              </div>
              <div className="library-list mob-detail-list plr-80 list-style-none">
                <ul>
                  <li>
                    <a href="">
                      <div className="d-flex align-items-center mb-3">
                        <div className="book">
                          <img
                            src={config.BASE_URL + '/assets/images/link.png'}
                            alt="link"
                            className=""
                            width={30}
                            height=""
                          />
                        </div>
                        <div className="link-block">
                          <span>Doctors Without Borders.pdf</span>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* Library Detail End */}
      </>
      <Footer />
    </div>
  );
}

export default withTranslation()(EducationLibraryDetails)