import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";

function MobileTabs(props) {
  return (
    <div className="col-md-12">
    <div className="top-links d-none list-style-none">
        <ul>
            <li>
            <NavLink to="#" className="active" >{props.t("Offer")}</NavLink>
            </li>
            {/* <li>
                <a href="service.html" className="">Service</a>
            </li>
            <li>
                <a href="explore.html" className="">Explore</a>
            </li> */}
        </ul>
    </div>
</div>
  )
}

export default withTranslation()(MobileTabs)