import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const DoctorApi = {
  requestDoctor: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.REQUEST_DOCTOR_SIGNUP}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  requestUpdateDoctor: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.UPDATE_REQUEST_DOCTOR_SIGNUP}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getRequestDoctorByid: async (request_doctor_id) => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.FETCH_REQUEST_DOCTOR_BY_ID}/${request_doctor_id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(axiosConfig);
  },
};
