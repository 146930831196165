import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import { Link, NavLink, useLocation } from "react-router-dom";
import { CommunityApi } from "../../apis/CommunityApi";
import config from "../../config";
import moment from "moment";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import MobileHeader from "../../components/Layout/Mobile/MobileHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form, Input, Label } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RightAdvertiseBanner from "../Components/RightAdvertiseBanner";
import { HomeApi } from "../../apis/HomeApi";
import constants from "../../constants";
import { getRoleID } from "../../Token";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function CommunityBlogPost(props) {
  const navigate = useNavigate();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [delTitle, setDelTitle] = useState(null);

  const toggleAdd = () => setModalAdd(!modalAdd);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const toggleCancel = () => setModalCancel(!modalCancel);

  const query = useQuery();
  const modalref = useRef(null);
  const modaledit = useRef(null);
  const modaldelete = useRef(null);
  const type = query.get("type") || "D-CONNECT";

  //   console.log(config.LIMIT, "dekhle");
  const [communitypostData, setCommunitypostData] = useState({
    page: 1,
    limit: config.LIMIT,
    type,
    search: "",
    filterBy: {
      company_id: "",
    },
    // category_id: "All",
    // institute_user_id: "All",
  });
  const [communitypost, setCommunitypost] = useState([]);
  const [communitypostDetails, setCommunitypostDetails] = useState([]);
  const userReduxState = useSelector((state) => state.profile_image);

  const [banners, setBanners] = useState([]);
  const [topBanner, setTopBanner] = useState({
    page_name: "Community-Blog",
    type: "Banner",
    position: "Top",
  });

  const getBanners = (data) => {
    HomeApi.getBanners(data)
      .then((res) => {
        if (res.data.success) {
          setBanners(res.data.data.banners);
        } else {
          setBanners([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBanners(topBanner);
  }, []);

  const getCommunityPostsByUser = (data, edit) => {
    CommunityApi.getCommunityPostsByUser(data)
      .then((res) => {
        //   console.log(res.data, "kya huw");
        if (res.data.success) {
          setCommunitypost(res.data.data);
          if (edit) {
            const data2 = res.data.data?.find(
              (e) => e.id == edit.community_post_id
            );
            setCommunitypostDetails(data2);
          }
        } else {
          setCommunitypost([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCommunityPostsByUser(communitypostData);
  }, [communitypostData.search]);

  const getDconnectCommunityPostDetails = (data) => {
    const data2 = communitypost?.find((e) => e.id == data.community_post_id);
    setCommunitypostDetails(data2);
    toggleEdit();
  };

  const removeCommunity = () => {
    CommunityApi.removeCommunity(deleteId)
      .then((res) => {
        //   console.log(res.data, "kya huw");
        if (res.data.success) {
          getCommunityPostsByUser(communitypostData);
          toggleDelete();
        } else {
          setCommunitypost([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formClear = () => {
    formik.resetForm({ values: { community_files: null } });
  };

  const formik = useFormik({
    validateOnBlur: false,
    initialValues: {
      topic: "",
      desciption: "",
      community_files: null,
    },
    validationSchema: Yup.object({
      topic: Yup.string().required(props.t("Required-Message")),
      desciption: Yup.string()
        .trim(props.t("Cannot-Include-Spaces-Message"))
        .required(props.t("Required-Message")),
      community_files: Yup.mixed().required(props.t("File-Required-Message")),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("type", type);
      data.append("topic", values.topic);
      data.append("desciption", values.desciption);
      if (values?.community_files && values?.community_files?.length > 0) {
        for (let i = 0; i < values.community_files?.length; i++) {
          data.append("community_files", values.community_files[i]);
        }
      }

      CommunityApi.getCreateCommunityPost(data)
        .then((res) => {
          if (res.data.success == true) {
            getCommunityPostsByUser(communitypostData);
            alert(props.t("Post-Submitted-Message"));
            toggleAdd();
          }
        })
        .catch((err) => {
          // alert(err.value.msg)
          console.log(err);
        });
    },
  });

  // console.log(communitypostDetails, 'yahi' );

  const formikEdit = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    initialValues: {
      topic: communitypostDetails?.name_en ?? "",
      desciption: communitypostDetails?.description_en ?? "",
      community_files: null,
    },
    validationSchema: Yup.object({
      topic: Yup.string().required(props.t("Required-Message")),
      desciption: Yup.string()
        .trim(props.t("Cannot-Include-Spaces-Message"))
        .required(props.t("Required-Message")),
      community_files:
        communitypostDetails?.CommunityPostMedia?.length > 0
          ? null
          : Yup.mixed().required(props.t("File-Required-Message")),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("community_post_id", communitypostDetails.id);
      data.append("type", type);
      data.append("topic", values.topic);
      data.append("desciption", values.desciption);
      if (values?.community_files && values?.community_files?.length > 0) {
        for (let i = 0; i < values.community_files.length; i++) {
          data.append("community_files", values.community_files[i]);
        }
      }
      data.append("post_date", new Date());
      CommunityApi.getUpdateCommunityPost(data)
        .then((res) => {
          if (res.data.success == true) {
            alert(props.t("Request-Submitted-Message"));
            getCommunityPostsByUser(communitypostData);
            toggleEdit();
          }
        })
        .catch((err) => {
          // alert(err.value.msg)
          console.log(err);
        });
    },
  });

  function handleChangeSearch(event) {
    console.log(handleChangeSearch, "xyz");
    // alert (1)

    if (event.key === "Enter") {
      event.preventDefault();
      // console.log(event,"xyz");

      setCommunitypostData({
        ...communitypostData,
        search: event.target.value,
      });
    }
  }
  function handleChangeLikeUnlikePost(isLike, communityPostId) {
    const data = {
      // post_comment_id: commentid,
      post_community_id: communityPostId,
      is_liked: isLike,
    };
    console.log(data);
    CommunityApi.CommunityPostsLikes(data)
      .then((res) => {
        getCommunityPostsByUser(communitypostData);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const removeImage = (item) => {
    //console.log(item)
    const data = {
      community_post_id: communitypostDetails?.id,
      id: item?.id,
    };
    CommunityApi.deleteFile(data)
      .then((res) => {
        console.log(res.data);
        getCommunityPostsByUser(communitypostData, data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Navbar />
      {/* <!-- Mobile View Top Start --> */}
      {/* <MobileHeader title="Community" /> */}
      <MobileHeader title={props.t("Community")} />

      {/* <!-- Mobile View Top End --> */}

      {/* <!-- Mobile Bottom Menu Start --> */}
      <MobileBottomMenu />
      {/* <!-- Mobile Bottom Menu End --> */}

      <section className="pt-3 top-curve">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12">
              <h1 className="mt-5 mb-4 main-title d-m-none">
                {props.t("Community")}
              </h1>
            </div>
            <div className="col-md-12">
              <div className="top-links d-none list-style-none">
                <ul>
                  <li>
                    <Link to={`/community`} className="">
                      {props.t("In-Focus")}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/community/news`} className="">
                      {props.t("News")}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/community/blog`} className="active">
                      {props.t("Dr-Connect")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Carousel Start --> */}
      {/* <section className="pt-2 d-none">
        <div className="container p-0 carousel-p">
          <div className="row">
            <div id="demo" className="carousel slide" data-bs-ride="carousel">
              <!-- Indicators/dots -->
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide-to="0"
                  className="active"
                ></button>
                <button
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide-to="1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide-to="2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide-to="3"
                ></button>
              </div>

              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="w-100"
                    src="/assets/images/community-banner.png"
                    alt="Image"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="w-100"
                    src="/assets/images/community-banner.png"
                    alt="Image"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="w-100"
                    src="/assets/images/community-banner.png"
                    alt="Image"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="w-100"
                    src="/assets/images/community-banner.png"
                    alt="Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Carousel End --> */}

      {/* <!-- In focus Start --> */}
      <section className="community pb-3 mob-pt-1 mob-mt-m-35 mob-pb-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-9 px-30 mob-px-30">
              <ul className="nav nav-tabs d-m-none" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Link to={`/community`} className="nav-link ">
                    {props.t("In-Focus")}
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community/news`}
                    className="nav-link "
                    id="news-tab"
                  >
                    {props.t("News")}
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community/blog`}
                    className="nav-link active"
                    id="blog-tab"
                  >
                    {props.t("Dr-Connect")}
                  </Link>
                </li>
              </ul>

              <div className="tab-content mob-mb-30 clearfix" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="focus"
                  role="tabpanel"
                  aria-labelledby="focus-tab"
                >
                  <div className="row">
                    <div className="col-md-12 col-mob-46">
                      <div className="search-box  mb-2">
                        <form action="">
                          <div className="row">
                            <div className="w-100 d-flex justify-content-center mb-4 rounded-4">
                              <Link
                                to="/community/blog"
                                type="button"
                                className="border-0  w-50 rounded-5 text-gray-black  d-flex align-items-center justify-content-center "
                                style={{
                                  borderTopLeftRadius: 5,
                                  borderBottomLeftRadius: 5,
                                  backgroundColor: "#eee",
                                }}
                              >
                                <svg
                                  width="18"
                                  height="16"
                                  viewBox="0 0 18 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.58333 4.66927H12.9167M4.58333 8.0026H7.91667M8.75 14.6693L5.41667 11.3359H2.91667C2.47464 11.3359 2.05072 11.1603 1.73816 10.8478C1.42559 10.5352 1.25 10.1113 1.25 9.66927V3.0026C1.25 2.56058 1.42559 2.13665 1.73816 1.82409C2.05072 1.51153 2.47464 1.33594 2.91667 1.33594H14.5833C15.0254 1.33594 15.4493 1.51153 15.7618 1.82409C16.0744 2.13665 16.25 2.56058 16.25 3.0026V9.66927C16.25 10.1113 16.0744 10.5352 15.7618 10.8478C15.4493 11.1603 15.0254 11.3359 14.5833 11.3359H12.0833L8.75 14.6693Z"
                                    stroke="#A9A9A9"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                &ensp;{props.t("Feed")}
                              </Link>
                              <button
                                type="button"
                                className="btn btn-primary w-50 rounded-5 text-white d-flex align-items-center justify-content-center"
                                style={{
                                  borderTopRightRadius: 5,
                                  borderBottomRightRadius: 5,
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="#A9A9A9"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                &ensp;{props.t("Posts")}
                              </button>
                            </div>
                            {constants.ROLE.COMPANY !== getRoleID() && (
                              <div className="bg-grayy">
                                <a onClick={toggleAdd}>
                                  <h4>{props.t("Your-Posts")}</h4>
                                  <div className="d-flex col-md-12 ">
                                    <div>
                                      <img
                                        src={
                                          userReduxState.profile_image
                                          ? userReduxState.profile_image
                                          : require("assets/images/profile.png")
                                        }
                                        className="rounded"
                                        alt=""
                                        width="40"
                                        height="40"
                                      />
                                    </div>
                                    <div className="input-group mx-2">
                                      <span className="input-group-text">
                                        <img
                                          src="/assets/images/search.png"
                                          alt="search-icon"
                                          width="15"
                                          height="15"
                                        />
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder={props.t("Write-Topic")}

                                        // onKeyDown={handleChangeSearch}
                                      />
                                    </div>
                                    <img
                                      src="/assets/images/upload-img.png"
                                      width="38"
                                      height="38"
                                      alt=""
                                    />
                                  </div>
                                </a>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                    <Modal
                      style={{
                        background: "transparent",
                        borderRadius: 0,
                        borderWidth: 0,
                        boxShadow: "none",
                      }}
                      centered
                      backdrop="static"
                      isOpen={modalAdd}
                      toggle={toggleAdd}
                    >
                      <ModalBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="c-content">
                            <h5 className="mb-2">{props.t("Create-Post")}</h5>

                            <div className="mb-2">
                              <Label htmlFor="" className="form-label">
                                {props.t("Topic")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className={
                                  formik.touched.topic && formik.errors.topic
                                    ? "form-control bor w-100"
                                    : "form-control text-dark w-100"
                                }
                                name="topic"
                                placeholder={props.t("Topic")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.topic}
                              />
                              {formik.touched.topic && formik.errors.topic ? (
                                <p className="text-danger text-small ">
                                  {formik.errors.topic}
                                </p>
                              ) : null}
                            </div>

                            <div>
                              <Label htmlFor="" className="form-label">
                                {props.t("Description")}
                                <span className="text-danger">*</span>
                              </Label>
                              <textarea
                                rows="12"
                                type="text"
                                className={
                                  formik.touched.desciption &&
                                  formik.errors.desciption
                                    ? "form-control bor w-100"
                                    : "form-control bor-15 text-dark w-100"
                                }
                                id=""
                                name="desciption"
                                placeholder={props.t("Description")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.desciption}
                              />
                              {formik.touched.desciption &&
                              formik.errors.desciption ? (
                                <p className="text-danger text-small ">
                                  {formik.errors.desciption}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="d-flex column-list row-cols-5 w-100 flex-wrap py-2">
                            {formik.values.community_files?.length > 0
                              ? formik.values.community_files?.map(
                                  (item, index) => {
                                    return index <= 3 ? (
                                      <div className="column p-1" key={index}>
                                        <img
                                          className="img-fluid rounded-5"
                                          style={{
                                            width: 80,
                                            height: 80,
                                            borderRadius: 10,
                                          }}
                                          src={URL.createObjectURL(item)}
                                          //   src={item.image_url}
                                          alt=""
                                        />
                                      </div>
                                    ) : index === 4 ? (
                                      <div className="column mr-2">
                                        <div style={{ position: "relative" }}>
                                          <img
                                            className="img-fluid"
                                            style={{
                                              width: 80,
                                              height: 80,
                                              borderRadius: 10,
                                              position: "relative",
                                            }}
                                            // src={item.image_url}
                                            src={URL.createObjectURL(item)}
                                            alt=""
                                          />
                                          <div
                                            className="overlay "
                                            style={{
                                              width: 80,
                                              height: 80,
                                            }}
                                          >
                                            <span
                                              className="image-count "
                                              style={{
                                                width: 80,
                                                height: 80,
                                                color: "#fff",
                                                fontSize: 25,
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                              }}
                                            >
                                              +
                                              {formik.values.community_files
                                                ?.length - 4}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    );
                                  }
                                )
                              : null}
                          </div>
                          <div className="upload__box">
                            <div className="upload__img-wrap mt-3"></div>

                            <div className="upload__btn-box">
                              <Label className="upload__btn">
                                <img
                                  src="/assets/images/upload-img.png"
                                  width="38"
                                  height="38"
                                  alt=""
                                />
                                <Input
                                  name="community_files"
                                  placeholder={"Enter Community Image Url"}
                                  type="file"
                                  multiple
                                  className="form-control upload__inputfile"
                                  onBlur={(e) => {
                                    if (
                                      e.target.files.length == 0 &&
                                      formik.values.community_files &&
                                      formik.values.community_files.length == 0
                                    ) {
                                      formik.setFieldValue(
                                        "community_files",
                                        null
                                      );
                                    }
                                  }}
                                  onChange={(e) => {
                                    const filesArray2 = [];
                                    for (
                                      let i = 0;
                                      i < e.target.files.length;
                                      i++
                                    ) {
                                      filesArray2.push(e.target.files[i]);
                                    }

                                    formik.setFieldValue(
                                      "community_files",
                                      formik.values.community_files
                                        ? [
                                            ...formik.values.community_files,
                                            ...filesArray2,
                                          ]
                                        : filesArray2
                                    );
                                  }}
                                  invalid={
                                    formik.touched.community_files &&
                                    formik.errors.community_files
                                      ? true
                                      : false
                                  }
                                />

                                {formik.touched.community_files &&
                                formik.errors.community_files ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {formik.errors.community_files}
                                  </div>
                                ) : null}
                              </Label>
                            </div>
                          </div>
                          <div className="d-flex collection-btn-list">
                            <div className="">
                              <Button
                                onClick={(e) => formClear()}
                                className="float-right btn btn-cancel-d"
                              >
                                {props.t("Clear")}
                              </Button>
                              <Button
                                onClick={toggleCancel}
                                className="float-right btn btn-cancel-d mx-2"
                                role="button"
                                type="button"
                              >
                                {props.t("Cancel")}
                              </Button>
                              <Button
                                className="float-right btn btn-create"
                                type="submit"
                              >
                                {props.t("Post")}
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </ModalBody>
                    </Modal>

                    <Modal
                      style={{
                        background: "transparent",
                        borderRadius: 0,
                        borderWidth: 0,
                        boxShadow: "none",
                      }}
                      centered
                      backdrop="static"
                      isOpen={modalEdit}
                      toggle={toggleEdit}
                    >
                      <ModalBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            formikEdit.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="c-content">
                            <h5 className="mb-2">
                              {props.t("Edit")} {props.t("Post")}
                            </h5>

                            <div className="mb-2">
                              <Label htmlFor="" className="form-label">
                                {props.t("Topic")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className={
                                  formikEdit.touched.topic &&
                                  formikEdit.errors.topic
                                    ? "form-control bor w-100"
                                    : "form-control text-dark w-100"
                                }
                                name="topic"
                                placeholder={props.t("Topic")}
                                onChange={formikEdit.handleChange}
                                onBlur={formikEdit.handleBlur}
                                value={formikEdit.values.topic}
                              />
                              {formikEdit.touched.topic &&
                              formikEdit.errors.topic ? (
                                <p className="text-danger text-small ">
                                  {formikEdit.errors.topic}
                                </p>
                              ) : null}
                            </div>

                            <div>
                              <Label htmlFor="" className="form-label">
                                {props.t("Description")}
                                <span className="text-danger">*</span>
                              </Label>
                              <textarea
                                type="text"
                                rows={12}
                                className={
                                  formikEdit.touched.desciption &&
                                  formikEdit.errors.desciption
                                    ? "form-control bor w-100"
                                    : "form-control bor-15  text-dark w-100"
                                }
                                id=""
                                name="desciption"
                                placeholder={props.t("Description")}
                                onChange={formikEdit.handleChange}
                                onBlur={formikEdit.handleBlur}
                                value={formikEdit.values.desciption}
                              />
                              {formikEdit.touched.desciption &&
                              formikEdit.errors.desciption ? (
                                <p className="text-danger text-small ">
                                  {formikEdit.errors.desciption}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="d-flex flex-column  column-list  row-cols-5  py-2">
                            <div
                              className="d-flex row-cols-5 w-100"
                              style={{ position: "relative" }}
                            >
                              {communitypostDetails?.CommunityPostMedia
                                ?.length > 0
                                ? communitypostDetails?.CommunityPostMedia?.map(
                                    (item, index) => {
                                      return index <= 3 ? (
                                        <div
                                          className="column mr-2"
                                          style={{
                                            marginRight: 4,
                                            position: "relative",
                                          }}
                                          key={index}
                                        >
                                          <img
                                            className="col-md-12 img-fluid rounded-5"
                                            style={{
                                              width: 80,
                                              height: 80,
                                              borderRadius: 10,
                                            }}
                                            src={item["image_url"]}
                                            alt=""
                                          />
                                          <a
                                            onClick={() => {
                                              removeImage(item);
                                            }}
                                            className="text-danger"
                                            type="button"
                                            style={{
                                              fontSize: 12,
                                              marginBottom: 5,
                                              position: "absolute",
                                              right: 5,
                                              background: "#fff",
                                              padding: 2,
                                              top: 5,
                                              paddingLeft: 5,
                                              paddingRight: 5,
                                              borderRadius: 5,
                                            }}
                                          >
                                            {" "}
                                            <svg
                                              width="12"
                                              height="14"
                                              viewBox="0 0 12 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M3.625 1.87305H3.5C3.56875 1.87305 3.625 1.8168 3.625 1.74805V1.87305H8.375V1.74805C8.375 1.8168 8.43125 1.87305 8.5 1.87305H8.375V2.99805H9.5V1.74805C9.5 1.19648 9.05156 0.748047 8.5 0.748047H3.5C2.94844 0.748047 2.5 1.19648 2.5 1.74805V2.99805H3.625V1.87305ZM11.5 2.99805H0.5C0.223438 2.99805 0 3.22148 0 3.49805V3.99805C0 4.0668 0.05625 4.12305 0.125 4.12305H1.06875L1.45469 12.2949C1.47969 12.8277 1.92031 13.248 2.45313 13.248H9.54688C10.0813 13.248 10.5203 12.8293 10.5453 12.2949L10.9313 4.12305H11.875C11.9438 4.12305 12 4.0668 12 3.99805V3.49805C12 3.22148 11.7766 2.99805 11.5 2.99805ZM9.42656 12.123H2.57344L2.19531 4.12305H9.80469L9.42656 12.123Z"
                                                fill="black"
                                                fill-opacity="0.45"
                                              />
                                            </svg>
                                          </a>
                                        </div>
                                      ) : index === 4 ? (
                                        <div className="column ">
                                          <div style={{ position: "relative" }}>
                                            <img
                                              className="img-fluid "
                                              style={{
                                                width: 80,
                                                height: 80,
                                                margin: 1,
                                                borderRadius: 20,
                                                position: "relative",
                                              }}
                                              src={item["image_url"]}
                                              alt=""
                                            />
                                            <div
                                              className="overlay "
                                              style={{
                                                width: 80,
                                                height: 80,
                                                // borderRadius: 20,
                                              }}
                                            >
                                              <span
                                                className="image-count"
                                                style={{
                                                  width: 80,
                                                  height: 80,
                                                  color: "#fff",
                                                  fontSize: 25,
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  display: "flex",
                                                }}
                                              >
                                                +{" "}
                                                {communitypostDetails
                                                  .CommunityPostMedia.length -
                                                  4}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      );
                                    }
                                  )
                                : null}
                            </div>
                            <div className="d-flex row-cols-5 w-100 mt-2">
                              {formikEdit.values.community_files?.length > 0
                                ? formikEdit.values.community_files?.map(
                                    (item, index) => {
                                      return index <= 3 ? (
                                        <div
                                          className="column mr-2"
                                          style={{ marginRight: 4 }}
                                          key={index}
                                        >
                                          <img
                                            className="img-fluid rounded-5"
                                            style={{
                                              width: 80,
                                              height: 80,
                                              borderRadius: 10,
                                            }}
                                            src={URL.createObjectURL(item)}
                                            //   src={item.image_url}
                                            alt=""
                                          />
                                        </div>
                                      ) : index === 4 ? (
                                        <div className="column mr-2">
                                          <div style={{ position: "relative" }}>
                                            <img
                                              className="img-fluid"
                                              style={{
                                                width: 80,
                                                height: 80,
                                                borderRadius: 20,
                                                position: "relative",
                                              }}
                                              // src={item.image_url}
                                              src={URL.createObjectURL(item)}
                                              alt=""
                                            />
                                            <div
                                              className="overlay "
                                              style={{
                                                width: 80,
                                                height: 80,
                                              }}
                                            >
                                              <span
                                                className="image-count "
                                                style={{
                                                  width: 80,
                                                  height: 80,
                                                  color: "#fff",
                                                  fontSize: 25,
                                                  background: "#000",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  display: "flex",
                                                  borderRadius: 10,
                                                }}
                                              >
                                                +
                                                {formikEdit.values
                                                  .community_files?.length - 4}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          </div>
                          <div className="upload__box">
                            <div className="upload__img-wrap mt-3"></div>

                            <div className="upload__btn-box">
                              <Label className="upload__btn">
                                <img
                                  src="/assets/images/upload-img.png"
                                  width="38"
                                  height="38"
                                  alt=""
                                />
                                <Input
                                  name="community_files"
                                  placeholder={"Enter Community Image Url"}
                                  type="file"
                                  multiple
                                  className="form-control upload__inputfile"
                                  onBlur={(e) => {
                                    if (
                                      e.target.files.length == 0 &&
                                      formikEdit.values.community_files &&
                                      formikEdit.values.community_files
                                        .length == 0
                                    ) {
                                      formikEdit.setFieldValue(
                                        "community_files",
                                        null
                                      );
                                    }
                                  }}
                                  onChange={(e) => {
                                    console.log(e.target.files);
                                    const filesArray2 = [];
                                    for (
                                      let i = 0;
                                      i < e.target.files.length;
                                      i++
                                    ) {
                                      // console.log()
                                      filesArray2.push(e.target.files[i]);
                                    }

                                    formikEdit.setFieldValue(
                                      "community_files",
                                      formikEdit.values.community_files
                                        ? [
                                            ...formikEdit.values
                                              .community_files,
                                            ...filesArray2,
                                          ]
                                        : filesArray2
                                    );
                                  }}
                                  invalid={
                                    formikEdit.touched.community_files &&
                                    formikEdit.errors.community_files
                                      ? true
                                      : false
                                  }
                                />

                                {formikEdit.touched.community_files &&
                                formikEdit.errors.community_files ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {formikEdit.errors.community_files}
                                  </div>
                                ) : null}
                              </Label>
                            </div>
                          </div>
                          <div className="d-flex collection-btn-list">
                            <div className="">
                              <Button
                                data-bs-dismiss="modal"
                                className="float-right btn btn-cancel-d mx-2"
                                onClick={toggleEdit}
                              >
                                {props.t("Cancel")}
                              </Button>
                              <Button
                                className="float-right btn btn-create"
                                type="submit"
                              >
                                {props.t("Post")}
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </ModalBody>
                    </Modal>
                    {/* <!-- Remove Post Modal --> */}
                    <Modal
                      style={{
                        background: "transparent",
                        borderRadius: 0,
                        borderWidth: 0,
                        boxShadow: "none",
                      }}
                      centered
                      isOpen={modalDelete}
                      toggle={toggleDelete}
                    >
                      <ModalBody>
                        <div className="d-flex collection-btn-list">
                          <img
                            src="/assets/images/cancel.png"
                            width="38"
                            height="38"
                            alt=""
                            className="m-3"
                          />

                          <div>
                            <h5>{props.t("Delete Post")}</h5>
                            {/* {communitypost.name_en} */}

                            <p>
                              Are you sure want to delete '{delTitle}' ? This
                              action cannot be undone
                            </p>
                          </div>
                        </div>
                        <div className="bg-gray d-flex justify-content-end">
                          <Button
                            className="float-right btn btn-cancel-d mx-2"
                            onClick={() => {
                              setDeleteId(null);
                              toggleDelete();
                            }}
                          >
                            {props.t("Cancel")}
                          </Button>
                          <Button
                            className="float-right btn-cancel"
                            onClick={() => removeCommunity()}
                          >
                            {props.t(" Yes, delete post")}
                          </Button>
                        </div>
                      </ModalBody>
                    </Modal>
                    <Modal
                      style={{
                        background: "transparent",
                        borderRadius: 0,
                        borderWidth: 0,
                        boxShadow: "none",
                      }}
                      centered
                      isOpen={modalCancel}
                      toggle={toggleCancel}
                    >
                      <ModalBody className=" p-3">
                        <div className="d-flex p-0">
                          <img
                            src="/assets/images/cancel.png"
                            width="38"
                            height="38"
                            alt=""
                            className="m-3"
                          />

                          <div>
                            <h5>{props.t("Discard-Post")}</h5>
                            {/* {communitypost.name_en} */}

                            <p>{props.t("Discard-Post-Msg")}</p>
                          </div>
                        </div>
                        <div className="bg-gray d-flex justify-content-end">
                          <Button
                            className="float-right btn btn-cancel-d mx-2"
                            onClick={() => {
                              toggleCancel();
                            }}
                          >
                            {props.t("Cancel")}
                          </Button>
                          <Button
                            className="float-right btn-cancel"
                            onClick={() => {
                              toggleCancel();
                              toggleAdd();
                            }}
                          >
                            {props.t("Delete-Post")}
                          </Button>
                        </div>
                      </ModalBody>
                    </Modal>

                    {/* <!-- Remove Post Modal --> */}

                    <div className="col-md-12 bg-m-white">
                      {communitypost
                        ? communitypost.map((item, i) => {
                            if (i === 2) {
                              return (
                                <>
                                  <RightAdvertiseBanner
                                    banner={banners}
                                    className="mb-3 p-20 d-none"
                                  />
                                  <div className="bg-gray mb-3" key={i}>
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex ml-4">
                                        <img
                                          src={
                                            userReduxState.profile_image
                                            ? userReduxState.profile_image
                                            : require("assets/images/profile.png")
                                          }
                                          className="rounded"
                                          alt=""
                                          width="40"
                                          height="40"
                                        />
                                      </div>
                                      <div className="p-3 d-flex align-items-center justify-content-between w-100">
                                        <h5 className=" d-flex flex-column">
                                          {item.User.first_name}&nbsp;
                                          {item.User.last_name}
                                          <span>
                                            {moment(item.post_date).fromNow()}
                                          </span>
                                        </h5>

                                        <div className="nav-item dropdown">
                                          <a
                                            href="#"
                                            className="nav-link dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            role="button"
                                          >
                                            <svg
                                              className=" "
                                              style={{ height: 30, width: 40 }}
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              fill="currentColor"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M4.5 12a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </a>
                                          <div className="dropdown-menu bg-gray m-0 p-2">
                                            <a
                                              className="text-drak dropdown-item p-1"
                                              role="button"
                                              onClick={(e) =>
                                                getDconnectCommunityPostDetails(
                                                  {
                                                    community_post_id: item.id,
                                                  }
                                                )
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                style={{ width: 20 }}
                                              >
                                                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                                <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                              </svg>
                                              &ensp;{props.t("Edit")}{" "}
                                              {props.t("Post")}
                                            </a>

                                            <a
                                              className=" text-drak dropdown-item p-1"
                                              onClick={() => {
                                                setDeleteId(item.id);
                                                setDelTitle(item.name_en);
                                                toggleDelete();
                                              }}
                                              role="button"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                style={{ width: 20 }}
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                              &ensp;{props.t("Remove")}{" "}
                                              {props.t("Post")}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="pl-55">
                                      <h3>{item.name_en}</h3>
                                      <p className="desc  ">
                                        {item?.description_en?.substring(
                                          0,
                                          200
                                        )}
                                      </p>
                                      <Link
                                        to={`/community/details/${item.type}/${item.id}`}
                                        className=""
                                      >
                                        {props.t("Read-More")}
                                      </Link>

                                      {item?.CommunityPostMedia?.length > 0 ? (
                                        item.CommunityPostMedia[0].image_url ? (
                                          <img
                                            src={
                                              item.CommunityPostMedia[0]
                                                .image_url
                                            }
                                            className="w-100 img-rouded-5"
                                          />
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="slide-gallery my-3 pl-55">
                                      <div className="d-flex  column-list col-md-3 row-cols-6 feedwidth">
                                        {item.CommunityPostMedia?.length > 0
                                          ? item.CommunityPostMedia.map(
                                              (comitem, comindx) => {
                                                return comindx !== 0 ? (
                                                  comindx <= 5 ? (
                                                    <div
                                                      className="column"
                                                      key={comindx}
                                                    >
                                                      <Link
                                                        to={`/community/details/${item.type}/${item.id}`}
                                                      >
                                                        <img
                                                          className="demo cursor w-100 h-100 img-fluid rounded-5"
                                                          src={
                                                            comitem.image_url
                                                          }
                                                          alt=""
                                                        />
                                                      </Link>
                                                    </div>
                                                  ) : comindx === 6 ? (
                                                    <div className="column ">
                                                      <Link
                                                        to={`/community/details/${item.type}/${item.id}`}
                                                      >
                                                        <img
                                                          className="demo cursor w-100 h-100 img-fluid "
                                                          src={
                                                            comitem.image_url
                                                          }
                                                          alt=""
                                                        />
                                                        <div className="overlay  h-100">
                                                          <span className="image-count h-25 ">
                                                            +
                                                            {item
                                                              ?.CommunityPostMedia
                                                              ?.length - 3}
                                                          </span>
                                                        </div>
                                                      </Link>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )
                                                ) : (
                                                  ""
                                                );
                                              }
                                            )
                                          : ""}
                                      </div>
                                    </div>
                                    <div className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between">
                                      <ul className="d-flex justify-content-between m-0 p-0 image-list w-100">
                                        <li className="d-flex">
                                          <div>
                                            {item.isLike == 1 ? (
                                              <button
                                                type="button"
                                                className="btn-dl like-btn"
                                                // onClick={
                                                //   () =>
                                                //     handleChangeLikeUnlikePost(
                                                //       false,
                                                //       item.id
                                                //     )
                                                // }
                                              >
                                                <img
                                                  src="/assets/images/like.png"
                                                  alt=""
                                                  width=""
                                                  height=""
                                                  className="m-1"
                                                />
                                                {props.t("Like")}
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                className="btn-dl dislike-btn"
                                                // onClick={() =>
                                                //   handleChangeLikeUnlikePost(
                                                //     true,
                                                //     item.id
                                                //   )
                                                // }
                                              >
                                                <img
                                                  src="/assets/images/dislike.png"
                                                  alt=""
                                                  width=""
                                                  height=""
                                                  className="m-1"
                                                />
                                                {props.t("Like")}
                                              </button>
                                            )}
                                          </div>
                                          <div className="ml-1 list-style-none">
                                            <ul className="d-flex m-0 p-0 image-list">
                                              {item.Likes.map(
                                                (litem, linedx) => {
                                                  if (linedx <= 3) {
                                                    return (
                                                      <li>
                                                        <a href="">
                                                          <img
                                                            src={
                                                              litem.User
                                                                .profile_image
                                                                ? `${config.IMAGE_URL}${litem.User.profile_image}`
                                                                : require("assets/images/profile.png")
                                                            }
                                                            className="rounded"
                                                            alt=""
                                                            width="35"
                                                            height="35"
                                                          />
                                                          {linedx === 3 && (
                                                            <span className="image-count">
                                                              {item.totalLikeCount -
                                                                4 >
                                                              0
                                                                ? `+${
                                                                    item.totalLikeCount -
                                                                    4
                                                                  }`
                                                                : ""}
                                                            </span>
                                                          )}
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                          {/* <li className="ml-1">
                                        {item.Likes.map((litem, linedx) => {
                                          return (
                                            <a>
                                              {" "}
                                              <img
                                                src={litem.User.profile_image}
                                              />{" "}
                                            </a>
                                          );
                                        })}
                                      </li> */}
                                        </li>

                                        <Link
                                          to={`/community/details/${item.type}/${item.id}`}
                                          className="view-text2 pt-1"
                                        >
                                          {props.t("Comments")} (
                                          {item.totalCommentCount})
                                        </Link>
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              );
                            } else {
                              return (
                                <div className="bg-gray mb-3" key={i}>
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex ml-4">
                                      <img
                                        src={
                                          userReduxState.profile_image
                                          ? userReduxState.profile_image
                                          : require("assets/images/profile.png")
                                        }
                                        className="rounded"
                                        alt=""
                                        width="40"
                                        height="40"
                                      />
                                    </div>
                                    <div className="p-3 d-flex align-items-center justify-content-between w-100">
                                      <h5 className=" d-flex flex-column">
                                        {item.User.first_name}&nbsp;
                                        {item.User.last_name}
                                        <span>
                                          {moment(item.post_date).fromNow()}
                                        </span>
                                      </h5>

                                      <div className="nav-item dropdown">
                                        <a
                                          href="#"
                                          className="nav-link dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          role="button"
                                        >
                                          <svg
                                            className=" "
                                            style={{ height: 30, width: 40 }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M4.5 12a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
                                              clipRule="evenodd"
                                            />
                                          </svg>
                                        </a>
                                        <div className="dropdown-menu bg-gray m-0 p-2">
                                          <a
                                            className="text-drak dropdown-item p-1"
                                            role="button"
                                            onClick={(e) =>
                                              getDconnectCommunityPostDetails({
                                                community_post_id: item.id,
                                              })
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              fill="currentColor"
                                              style={{ width: 20 }}
                                            >
                                              <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                              <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                            </svg>
                                            &ensp;{props.t("Edit")}{" "}
                                            {props.t("Post")}
                                          </a>

                                          <a
                                            className=" text-drak dropdown-item p-1"
                                            onClick={() => {
                                              setDeleteId(item.id);
                                              setDelTitle(item.name_en);
                                              toggleDelete();
                                            }}
                                            role="button"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              fill="currentColor"
                                              style={{ width: 20 }}
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                            &ensp;{props.t("Remove")}{" "}
                                            {props.t("Post")}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="pl-55">
                                    <h3>{item.name_en}</h3>
                                    <p className="desc  ">
                                      {item?.description_en?.substring(0, 200)}
                                    </p>
                                    <Link
                                      to={`/community/details/${item.type}/${item.id}`}
                                      className=""
                                    >
                                      {props.t("Read-More")}
                                    </Link>

                                    {item?.CommunityPostMedia?.length > 0 ? (
                                      item.CommunityPostMedia[0].image_url ? (
                                        <img
                                          src={
                                            item.CommunityPostMedia[0].image_url
                                          }
                                          className="w-100 img-rouded-5"
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="slide-gallery my-3 pl-55">
                                    <div className="d-flex  column-list col-md-3 row-cols-6 feedwidth">
                                      {item.CommunityPostMedia?.length > 0
                                        ? item.CommunityPostMedia.map(
                                            (comitem, comindx) => {
                                              return comindx !== 0 ? (
                                                comindx <= 5 ? (
                                                  <div
                                                    className="column"
                                                    key={comindx}
                                                  >
                                                    <Link
                                                      to={`/community/details/${item.type}/${item.id}`}
                                                    >
                                                      <img
                                                        className="demo cursor w-100 h-100 img-fluid rounded-5"
                                                        src={comitem.image_url}
                                                        alt=""
                                                      />
                                                    </Link>
                                                  </div>
                                                ) : comindx === 6 ? (
                                                  <div className="column ">
                                                    <Link
                                                      to={`/community/details/${item.type}/${item.id}`}
                                                    >
                                                      <img
                                                        className="demo cursor w-100 h-100 img-fluid "
                                                        src={comitem.image_url}
                                                        alt=""
                                                      />
                                                      <div className="overlay  h-100">
                                                        <span className="image-count h-25 ">
                                                          +
                                                          {item
                                                            ?.CommunityPostMedia
                                                            ?.length - 3}
                                                        </span>
                                                      </div>
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              );
                                            }
                                          )
                                        : ""}
                                    </div>
                                  </div>
                                  <div className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between">
                                    <ul className="d-flex justify-content-between m-0 p-0 image-list w-100">
                                      <li className="d-flex">
                                        <div>
                                          {item.isLike == 1 ? (
                                            <button
                                              type="button"
                                              className="btn-dl like-btn"
                                              // onClick={
                                              //   () =>
                                              //     handleChangeLikeUnlikePost(
                                              //       false,
                                              //       item.id
                                              //     )
                                              // }
                                            >
                                              <img
                                                src="/assets/images/like.png"
                                                alt=""
                                                width=""
                                                height=""
                                                className="m-1"
                                              />
                                              {props.t("Like")}
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="btn-dl dislike-btn"
                                              // onClick={() =>
                                              //   handleChangeLikeUnlikePost(
                                              //     true,
                                              //     item.id
                                              //   )
                                              // }
                                            >
                                              <img
                                                src="/assets/images/dislike.png"
                                                alt=""
                                                width=""
                                                height=""
                                                className="m-1"
                                              />
                                              {props.t("Like")}
                                            </button>
                                          )}
                                        </div>
                                        <div className="ml-1 list-style-none">
                                          <ul className="d-flex m-0 p-0 image-list">
                                            {item.Likes.map((litem, linedx) => {
                                              if (linedx <= 3) {
                                                return (
                                                  <li>
                                                    <a href="">
                                                      <img
                                                        src={
                                                          litem.User
                                                            .profile_image
                                                            ? `${config.IMAGE_URL}${litem.User.profile_image}`
                                                            : require("assets/images/profile.png")
                                                        }
                                                        className="rounded"
                                                        alt=""
                                                        width="35"
                                                        height="35"
                                                      />
                                                      {linedx === 3 && (
                                                        <span className="image-count">
                                                          {item.totalLikeCount -
                                                            4 >
                                                          0
                                                            ? `+${
                                                                item.totalLikeCount -
                                                                4
                                                              }`
                                                            : ""}
                                                        </span>
                                                      )}
                                                    </a>
                                                  </li>
                                                );
                                              }
                                            })}
                                          </ul>
                                        </div>
                                        {/* <li className="ml-1">
                                        {item.Likes.map((litem, linedx) => {
                                          return (
                                            <a>
                                              {" "}
                                              <img
                                                src={litem.User.profile_image}
                                              />{" "}
                                            </a>
                                          );
                                        })}
                                      </li> */}
                                      </li>

                                      <Link
                                        to={`/community/details/${item.type}/${item.id}`}
                                        className="view-text2 pt-1"
                                      >
                                        {props.t("Comments")} (
                                        {item.totalCommentCount})
                                      </Link>
                                    </ul>
                                  </div>
                                </div>
                              );
                            }
                          })
                        : ""}
                      {communitypost.length > 0 ? null : (
                        <div className="subtitle text-center d-none">{props.t("No Post Found")}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <RightAdvertiseBanner
              banner={banners}
              className="col-md-3 pt-3 communityBanner"
            />
          </div>
        </div>
      </section>

      {/* <!-- In focus End --> */}
      <Footer />
    </>
  );
}
export default withTranslation()(CommunityBlogPost);
