export const Token = {
  saveToken: (user, token) => {
    setToken(token);
    setUserInfo(user);
    // setUserID(user.id);
    // storeName(user.name);
    // storeEmail(user.email);
    // storeProfilePhoto(user.profile_photo);
  },
  getToken: () => {
    return getToken();
  },
  getUserID: () => {
    return getUserID();
  },
  getName: () => {
    return getName();
  },
  getRoleID: () => {
    return getRoleID()
  },
  isRestricted: () => {
    return isRestricted()
  },
  isFda: () => {
    return isFda()
  },
  // storeName: (name: any) => {
  //     return storeName(name);
  // },
  getEmail: () => {
    return getEmail();
  },
  // storeEmail: (email: any) => {
  //     return storeEmail(email);
  // },
  getProfilePhoto: () => {
    return getProfilePhoto();
  },
  isLoggedIn: () => {
    if (getToken()) {
      return true;
    }
    return false;
  },
  clearStorage: () => {
    return localStorage.clear();
  },
  removeStorageItem: () => {
    removeItem();
  },
};

export const setToken = (token) => {
  localStorage.setItem("userToken", token);
};

export const setUserInfo = (user) => {
  localStorage.setItem("userInfo", JSON.stringify(user));
};

export const removeItem = () => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("userInfo");
};

export const getToken = () => {
  return localStorage.getItem("userToken");
};

export const isLoggedIn = () => {
  if (getToken()) {
    return true;
  }
  return false;
};

// const setUserID = (user_id: any) => {
//     localStorage.setItem('user_id', user_id);
// }

export const getUserID = () => {
  let userInfo = localStorage.getItem("userInfo");
  userInfo = JSON.parse(userInfo);
  return userInfo ? userInfo.id : userInfo;
};

// export const storeName = (name: any) => {
//     localStorage.setItem('name', name);
// }

export const getName = () => {
  let userInfo = localStorage.getItem("userInfo");
  userInfo = JSON.parse(userInfo);
  return userInfo ? ` ${userInfo.first_name} ${userInfo.last_name}` : userInfo;
  // return localStorage.getItem('name');
};

export const isRestricted = () => {
  let userInfo = localStorage.getItem('userInfo')
  userInfo = JSON.parse(userInfo);
  return userInfo ? userInfo.is_restricted : 0;
}
export const isFda = () => {
  let userInfo = localStorage.getItem('userInfo')
  userInfo = JSON.parse(userInfo);
  return userInfo ? userInfo.is_fda : 0;
}

export const getRoleID = () => {
  let userInfo = localStorage.getItem('userInfo')
  userInfo = JSON.parse(userInfo)
  return userInfo.role_id;
}

// export const storeEmail = (email: any) => {
//     localStorage.setItem('email', email);
// }

export const getEmail = () => {
  let userInfo = localStorage.getItem("userInfo");
  userInfo = JSON.parse(userInfo);
  return userInfo ? userInfo.email : userInfo;
};

export const getLicenseNumber = () => {
  let userInfo = localStorage.getItem("userInfo");
  userInfo = JSON.parse(userInfo);
  return userInfo ? userInfo.license_number : userInfo;
};

export const getUser = () => {
  let userInfo = localStorage.getItem("userInfo");
  userInfo = JSON.parse(userInfo);
  return userInfo;
  // return localStorage.getItem('email');
};

export const getProfilePhoto = () => {
  let userInfo = localStorage.getItem("userInfo");
  userInfo = JSON.parse(userInfo);
  return userInfo ? userInfo.avatar_image : userInfo;
  // return localStorage.getItem('profile_photo');
};
