import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import config from "config";
import * as Yup from "yup";
import Navbar from "components/Layout/Navbar";
import Footer from "components/Layout/Footer";
import Select from "react-select";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import { withTranslation } from "react-i18next";
import { Input } from "reactstrap";
import constants from "../../constants";
import Swal from "sweetalert2"; 
import { getRoleID, getToken } from "../../Token";
import { CustomActivityApi } from "../../apis/CustomActivityApi";
import MobPageTitleBar from "pages/Components/MobPageTitleBar";
import ProfileTitle from "pages/Components/ProfileTitle";
import AddEditQuizModal from "pages/Components/Modal/AddEditQuizModal";
import _ from "lodash";
import moment from "moment";
// import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";

import {
  LoadScript,
  GoogleMap,
  DrawingManager,
  Polygon,
  Marker,
  MarkerF,
  OverlayView,
  StandaloneSearchBox,
} from "@react-google-maps/api";

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};

const ActivityFormNew = (props) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  let roleID;
  const instituteID = new URLSearchParams(search).get("institute_id");


  const [selectedActivityImage, setSelectedActivityImage] = useState();
  const [activityTypes, setActivityTypes] = useState([]);
  const [selectedActivityType, setSelectedActivityType] = useState(null);
  const [evaluationForms, setEvaluationForms] = useState([]);
  const [activityContentTypes, setActivityContentTypes] = useState([]);
  const [activityDisplayTypes, setActivityDisplayTypes] = useState([]);
  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const [quizTypes, setQuizTypes] = useState([]);
  const [activitySubTypes, setActivitySubTypes] = useState([]);
  const [activityTypeJson, setActivityTypeJson] = useState([]);
  const [quizTypeDetail, setQuizTypeDetail] = useState([]);
  const [activitySubTypeDetail, setActivitySubTypeDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInstituteFiledEnable, setIsInstituteFiledEnable] = useState(false);
  const [isAddQuizBtnEnable, setIsAddQuizBtnEnable] = useState(false);
  const [fromUser, setFromUser] = useState("Doctor");
  const [addedQuizCount, setAddedQuizCout] = useState(0);
  const [subActivityTask, setSubActivityTask] = useState([]);
  const [doctorsData, setDoctorsData] = useState([]);
  const [doctorPaginateData, setDoctorPaginateData] = useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  });
  const [isMoreDoctors, setIsMoreDoctors] = useState(true);
  const [isDoctorLoading, setIsDoctorLoading] = useState(true);

  const [institutesData, setinstitutesData] = useState([]);
  const [institutesPaginateData, setinstitutesPaginateData] = useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  });
  const [isMoreInstitutes, setIsMoreInstitutes] = useState(true);
  const [isInstituteLoading, setIsInstituteLoading] = useState(true);
  const [internshipSessions, setInternshipSessions] = useState([]);
  const [sessionTasks, setSessionTasks] = useState([]);
  const [internshipSessionId, setInternShipSessionId] = useState();
  const [sessionTaskDetail, setSessionTaskDetail] = useState(null);
  const [selectedOptionSpeaker, setSelectedOptionSpeaker] = useState(null);
  const [selectedQuizType, setSelectedQuizType] = useState("");
  const [quizQuetions, setQuizQuestions] = useState([]);

  if (instituteID === null) {
    roleID = getRoleID();
  } else {
    roleID = new URLSearchParams(search).get("roleID");
  }

  const [customValidation, setCustomValidation] = useState({
    activity_name: Yup.string().required(props.t("Required-Message")),
    activity_group: Yup.string().required(props.t("Required-Message")),
    activity_sub_type: Yup.string().required(props.t("Required-Message")),
    description: Yup.string().required(props.t("Required-Message")),
  });

  const activityFormValidation = (contentJson, activity_sub_type) => {
    let valSchema = {
      activity_name: Yup.string().required(props.t("Required-Message")),
      activity_group: Yup.string().required(props.t("Required-Message")),
      activity_sub_type: Yup.string().required(props.t("Required-Message")),
      description: Yup.string().required(props.t("Required-Message")),
    };
    if (
      contentJson.approval_rquired &&
      contentJson.approver_roles.includes(constants.ROLE.INSTITUTE) &&
      // contentJson.activity_creators.includes(constants.ROLE.DOCTOR) &&
      // contentJson.activity_creators.includes(constants.ROLE.SUBINSTITUTE)
      roleID != constants.ROLE.INSTITUTE
    ) {
      setIsInstituteFiledEnable(true);
      valSchema.institute_id = Yup.string().required(
        props.t("Required-Message")
      );
    }

    if (contentJson.can_other_join_activity) {
      valSchema.type = Yup.string().required(props.t("Required-Message"));
      valSchema.activity_date = Yup.string().required(
        props.t("Required-Message")
      );
      valSchema.start_time = Yup.string().required(props.t("Required-Message"));
      valSchema.end_time = Yup.string().required(props.t("Required-Message"));
      valSchema.speakers = Yup.array()
        .min(1, props.t("Select Speaker"))
        .required(props.t("Required-Message"));
    }

    if (
      contentJson.can_attach_file &&
      contentJson.score_type_id == constants.SCORE_TYPE.FILE_ATTACHMENT
    ) {
      if (activity_sub_type !== "4.3") {
        valSchema.attachments = Yup.mixed().required(
          props.t("File-Required-Message")
        );
      }
    }
    if (
      (contentJson.assign_as_task && constants.ROLE.INSTITUTE == roleID) ||
      (constants.ROLE.DOCTOR == roleID &&
        contentJson.score_type_id === constants.SCORE_TYPE.GIVING_ADVISE &&
        contentJson.approval_rquired === false)
    ) {
      valSchema.doctor_id = Yup.string().required(props.t("Required-Message"));
    }
    if (contentJson.can_add_quiz) {
      // valSchema.quiz_type_id = Yup.string().required(
      //   props.t("Required-Message")
      // );
    }
    if (
      contentJson.score_type_id ==
      constants.SCORE_TYPE.DISPLAY_CONTENT_PROVIDING
    ) {
      valSchema.display_type_id = Yup.string().required(
        props.t("Required-Message")
      );
    }
    if (contentJson.score_type_id == constants.SCORE_TYPE.CONTENT_TYPE) {
      valSchema.content_type_id = Yup.string().required(
        props.t("Required-Message")
      );
    }
    if (contentJson.score_type_id == constants.SCORE_TYPE.FILE_ATTACHMENT) {
      valSchema.attachment_type_id = Yup.string().required(
        props.t("Required-Message")
      );
    }

    if (activity_sub_type == "3.1") {
      valSchema.doctor_id = Yup.string().required(
        props.t("File-Required-Message")
      );
    }

    if (contentJson.score_type_id == constants.SCORE_TYPE.EXAM_WITH_QUIZ) {
      valSchema.task_type_id = Yup.string().required(
        props.t("Required-Message")
      );
    }

    if (!contentJson?.can_add_quiz && contentJson?.visible_to_other) {
      valSchema.educationType = Yup.string().required(
        props.t("Required-Message")
      );
    }

    setCustomValidation(valSchema);
  };

  const onValidationHandler = (fieldName, value) => {
    const customValidationCopy = _.cloneDeep(customValidation);
    if (fieldName === "type") {
      if (value === constants.TYPE.Online || value === constants.TYPE.Hybrid) {
        customValidationCopy.online_meeting_url = Yup.string().required(
          props.t("Required-Message")
        );
      } else {
        customValidationCopy.online_meeting_url = undefined;
      }

      if (value === constants.TYPE.Onsite || value === constants.TYPE.Hybrid) {
        customValidationCopy.address = Yup.string().required(
          props.t("Required-Message")
        );
        customValidationCopy.latitude = Yup.string().required(
          props.t("Required-Message")
        );
        customValidationCopy.longitude = Yup.string().required(
          props.t("Required-Message")
        );
      } else {
        customValidationCopy.address = undefined;
        customValidationCopy.latitude = undefined;
        customValidationCopy.longitude = undefined;
      }
    } else if (fieldName === "task_type_id") {
      if (value === constants.TASKTYPE.INTERVIEWTEST) {
        if (
          parseInt(roleID) === constants.ROLE.INSTITUTE ||
          parseInt(roleID) === constants.ROLE.SUBINSTITUTE
        ) {
          customValidationCopy.doctor_id = Yup.string().required(
            props.t("Required-Message")
          );
          customValidationCopy.intern_id = Yup.string().required(
            props.t("Required-Message")
          );
        }

        if (
          value === constants.TASKTYPE.INTERVIEWTEST &&
          parseInt(roleID) === constants.ROLE.DOCTOR
        ) {
          customValidationCopy.amountOfExaminee = Yup.string().required(
            props.t("Required-Message")
          );
        }
      } else {
        customValidationCopy.doctor_id = undefined;
        customValidationCopy.intern_id = undefined;
        customValidationCopy.amountOfExaminee = undefined;
      }

      if (
        value === constants.TASKTYPE.MEQSHORTASSAY &&
        parseInt(roleID) === constants.ROLE.DOCTOR
      ) {
        customValidationCopy.amountOfExaminee = Yup.string().required(
          props.t("Required-Message")
        );
      }

      if (
        value === constants.TASKTYPE.MEQSHORTASSAY &&
        (parseInt(roleID) === constants.ROLE.INSTITUTE ||
          parseInt(roleID) === constants.ROLE.SUBINSTITUTE)
      ) {
        customValidationCopy.doctor_id = Yup.string().required(
          props.t("Required-Message")
        );
      } else if (
        value === constants.TASKTYPE.CREATIONTEACHINGMATERIAL &&
        (parseInt(roleID) === constants.ROLE.INSTITUTE ||
          parseInt(roleID) === constants.ROLE.SUBINSTITUTE)
      ) {
        customValidationCopy.doctor_id = Yup.string().required(
          props.t("Required-Message")
        );
      } else {
        // customValidationCopy.amountOfExaminee = undefined;
      }
    } else if (fieldName === "taskTypeForInternship") {
      if (value === "quiz") {
        customValidationCopy.quiz_type_id = Yup.string().required(
          props.t("Required-Message")
        );
        customValidationCopy.amountOfExaminee = Yup.string().required(
          props.t("Required-Message")
        );
        customValidationCopy.task_type_id = undefined;
      } else {
        customValidationCopy.task_type_id = Yup.string().required(
          props.t("Required-Message")
        );
        customValidationCopy.amountOfExaminee = undefined;
        customValidationCopy.quiz_type_id = undefined;
      }
    } else if (fieldName === "attachment_type_id") {
      if (selectedActivityType != "4.3") {
        if (value == constants.ATTACHMENT_TYPES.CERTIFICATE) {
          customValidationCopy.attachments = Yup.mixed().test(
            "fileSize",
            props.t("Please-Select-Only-One-File"),
            (value) => {
              if (value && value?.length === 1) {
                return true;
              }
              return false;
            }
          );
        } else {
          customValidationCopy.attachments = Yup.mixed().required(
            props.t("File-Required-Message")
          );
        }
      }
    }
    setCustomValidation(customValidationCopy);
  };

  const getinstituteSessions = () => {
    CustomActivityApi.getMySessionsApi({ institute_id: instituteID })
      .then((res) => {
        if (res.data.success) {
          setInternshipSessions(res.data.data.rows);
        } else {
          setInternshipSessions([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInternshipSessionAllTasks = (sessionId) => {
    setInternShipSessionId(sessionId);
    CustomActivityApi.getInternshipSessionsAllTasksApi({
      session_id: sessionId,
    })
      .then((res) => {
        if (res.data.success) {
          setSessionTasks(res.data.data.rows);
        } else {
          setSessionTasks([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInternshipSessionTaskDetails = (taskTypeId) => {
    const payload = {
      session_id: internshipSessionId,
      task_type_id: taskTypeId,
    };
    CustomActivityApi.getInternshipSessionsTaskDetailApi(payload)
      .then((res) => {
        if (res.data.success) {
          setSessionTaskDetail(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getActivityTypeJson = (activity_sub_type) => {
    let data = { activity_sub_type: activity_sub_type };
    CustomActivityApi.getActivityTypeJson(data)
      .then((res) => {
        if (res.data.success) {
          setActivitySubTypeDetail(res.data.data);
          const formFieldJson = JSON.parse(res.data.data.form_content_json);
          setActivityTypeJson(formFieldJson);
          getsubActivityTask();
          activityFormValidation(formFieldJson, activity_sub_type);
        } else {
          setActivitySubTypeDetail([]);
          setActivityTypeJson([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuizTypeDetail = (quiz_type_id) => {
    let data = { quiz_type_id: quiz_type_id };
    CustomActivityApi.getQuizTypeDetail(data)
      .then((res) => {
        if (res.data.success) {
          setIsAddQuizBtnEnable(true);
          setQuizTypeDetail(res.data.data);
        } else {
          setQuizTypeDetail([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getsubActivityTask = () => {
    CustomActivityApi.getTaskTypesApi()
      .then((res) => {
        if (res.data.success) {
          setSubActivityTask(res.data.data);
        } else {
          setSubActivityTask([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchActivityTypes = () => {
    CustomActivityApi.fetchActivityTypes()
      .then((res) => {
        if (res.data.success) {
          setActivityTypes(res.data.data);
        } else {
          setActivityTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchActivityDisplayTypes = () => {
    CustomActivityApi.fetchActivityDisplayTypes()
      .then((res) => {
        if (res.data.success) {
          setActivityDisplayTypes(res.data.data);
        } else {
          setActivityDisplayTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchActivityContentTypes = () => {
    CustomActivityApi.fetchActivityContentTypes()
      .then((res) => {
        if (res.data.success) {
          setActivityContentTypes(res.data.data);
        } else {
          setActivityContentTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAttachmentTypes = () => {
    CustomActivityApi.fetchAttachmentTypes()
      .then((res) => {
        if (res.data.success) {
          setAttachmentTypes(res.data.data);
        } else {
          setAttachmentTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchActivitySubTypes = (activity_type) => {
    let data = { activity_type: activity_type };
    CustomActivityApi.fetchActivitySubTypes(data)
      .then((res) => {
        if (res.data.success) {
          setActivitySubTypes(res.data.data);
        } else {
          setActivitySubTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchEvaluationForms = () => {
    let data = { institute_id: instituteID };
    CustomActivityApi.fetchEvaluationForms(data)
      .then((res) => {
        if (res.data.success) {
          setEvaluationForms(res.data.data);
        } else {
          setEvaluationForms([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllDoctorsPaginate = (searchKeyword = "") => {
    if (searchKeyword !== "") {
      doctorPaginateData.search = searchKeyword;
    }
    CustomActivityApi.getAllDoctorsApi(doctorPaginateData)
      .then((res) => {
        if (res.data.success) {
          const pageNo = doctorPaginateData.page;
          setDoctorPaginateData({
            ...doctorPaginateData,
            totalRecords: res.data.data.count,
            page: res.data.data.metadata.page,
            limit: res.data.data.metadata.limit,
          });
          let doctorArr = [];
          res.data.data.rows.length > 0 &&
            res.data.data.rows.map((doctor, i) => {
              doctorArr[i] = {
                value: doctor.id,
                label:
                  doctor.first_name +
                  " " +
                  (doctor.last_name ? doctor.last_name : ""),
              };
            });
          if (pageNo === 1) {
            setDoctorsData([...doctorArr]);
          } else {
            if (doctorArr.length === 0) {
              setIsMoreDoctors(false);
              setIsDoctorLoading(false);
            }
            setDoctorsData([...doctorsData, ...doctorArr]);
          }
        } else {
          setDoctorsData([]);
        }
      })
      .catch((err) => {
        setDoctorsData([]);
      });
  };

  const getAllInstitutesPaginate = (searchKeyword = "") => {
    if (searchKeyword !== "") {
      institutesPaginateData.search = searchKeyword;
    }
    CustomActivityApi.getAllInstitutesApi(institutesPaginateData)
      .then((res) => {
        if (res.data.success) {
          const pageNo = institutesPaginateData.page;
          setinstitutesPaginateData({
            ...institutesPaginateData,
            totalRecords: res.data.data.count,
            page: res.data.data.metadata.page,
            limit: res.data.data.metadata.limit,
          });
          if (pageNo === 1) {
            setinstitutesData([...res.data.data.rows]);
          } else {
            if (res.data.data.rows.length === 0) {
              setIsMoreInstitutes(false);
              setIsInstituteLoading(false);
            }
            setinstitutesData([...institutesData, ...res.data.data.rows]);
          }
        } else {
          setinstitutesData([]);
        }
      })
      .catch((err) => {
        setinstitutesData([]);
      });
  };

  const getQuizTypes = () => {
    setLoading(true);
    CustomActivityApi.getQuizTypes()
      .then((res) => {
        setQuizTypes(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setQuizTypes([]);
        console.log(err);
      });
  };

  useEffect(() => {
    if (instituteID !== null) getinstituteSessions();
  }, [instituteID]);

  useEffect(() => {
    fetchActivityTypes();
    getQuizTypes();
    fetchActivityDisplayTypes();
    fetchActivityContentTypes();
    fetchAttachmentTypes();
    fetchEvaluationForms();
  }, []);
  const formik = useFormik({
    initialValues: {
      activity_image: null,
      attachments: null,
      activity_name: "",
      activity_group: "",
      activity_sub_type: "",
      description: "",
      institute_id: instituteID ? atob(instituteID) : "",
      quiz_type_id: "",
      type: "",
      address: "",
      latitude: "",
      longitude: "",
      online_meeting_url: "",
      activity_date: "",
      start_time: "",
      end_time: "",
      speakers: "",
      doctor_id: "",
      intern_id: "",
      doctor_hod_id: "",
      examiner_id: "",
      evaluation_form_ids: "",
      co_author_id: "",
      display_type_id: "",
      content_type_id: "",
      attachment_type_id: "",
      book_name: "",
      chapter_name: "",
      fromUser: fromUser,
      task_type_id: "",
      taskTypeForInternship: "subtask",
      amountOfExaminee: "",
      internship_session_id: "",
      session_task_id: "",
      frequency: "",
      performActivityDay: "",
      educationType: "",
      content_type:""
    },
    validationSchema: Yup.object().shape({
      ...customValidation,
    }),
    onSubmit: (values) => {
      const data = new FormData();
      if (values.attachments != null) {
        for (let i = 0; i < values.attachments.length; i++) {
          data.append("attachments", values.attachments[i]);
        }
      }

      data.append("quizQuestions", JSON.stringify(quizQuetions));
      data.append("activity_image", selectedActivityImage);
      data.append("activity_name", values.activity_name);
      data.append("activity_group", values.activity_group);
      data.append("activity_sub_type", values.activity_sub_type);
      data.append("description", values.description);
      data.append("institute_id", values.institute_id);
      data.append("attachments", values.attachments);
      data.append("quiz_type_id", values.quiz_type_id);
      data.append("type", values.type);
      data.append("address", values.address);
      data.append("latitude", values.latitude);
      data.append("longitude", values.longitude);
      data.append("online_meeting_url", values.online_meeting_url);
      data.append("activity_date", values.activity_date);
      data.append("start_time", values.start_time);
      data.append("end_time", values.end_time);
      data.append("speakers", values.speakers);
      data.append("doctor_id", values.doctor_id);
      data.append("intern_id", values.intern_id);
      data.append("doctor_hod_id", values.doctor_hod_id);
      data.append("examiner_id", values.examiner_id);
      data.append("evaluation_form_ids", values.evaluation_form_ids);
      data.append("co_author_id", values.co_author_id);
      data.append("display_type_id", values.display_type_id);
      data.append("content_type_id", values.content_type_id);
      data.append("attachment_type_id", values.attachment_type_id);
      data.append("book_name", values.book_name);
      data.append("chapter_name", values.chapter_name);
      data.append("fromUser", instituteID ? "Institute" : "Doctor");
      data.append("task_type_id", values.task_type_id);
      data.append("amountOfExaminee", values.amountOfExaminee);
      data.append("taskTypeForInternship", values.taskTypeForInternship);
      data.append("internship_session_id", values.internship_session_id);
      data.append("session_task_id", values.session_task_id);
      data.append("frequency", values.frequency);
      data.append("performActivityDay", values.performActivityDay);
      data.append("educationType", values.educationType);
      data.append("content_type", values.content_type);
      
      CustomActivityApi.createActivity(data)
        .then((res) => {
          if (res.data.success) {
            formClear();
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then((result) => {
              if (instituteID !== null) {
                window.close();
                window.location.reload(config.ADMIN_URL);
              }
            });
            if (instituteID === null) {
              window.location.href = "/my-activity";
            }
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
          } else {
            err.response.data.error.errors?.map((erritem, errindex) => {});
          }
        });
    },
  });

  useEffect(() => {}, [formik.values.attachments]);

  const removeAttachment = (lastModified) => {
    const updatedList = [
      ...formik.values.attachments.filter(
        (e) => e.lastModified !== lastModified
      ),
    ];
    formik.setFieldValue(
      "attachments",
      updatedList.length > 0 ? updatedList : null
    );
  };

  const formClear = () => {
    formik.resetForm({ values: { attachments: null, activity_image: null } });
  };

  const onAddViewWuizHandler = () => {
    window.$("#addEditQuizModal").modal("show");
  };

  const onScrollDoctorListHandler = _.debounce(async function () {
    if (isMoreDoctors) {
      setIsDoctorLoading(true);
      getAllDoctorsPaginate();
    }
  }, 500);

  const onScrollInstituesDataHandler = _.debounce(async function () {
    if (isMoreInstitutes) {
      setIsInstituteLoading(true);
      getAllInstitutesPaginate();
    }
  }, 500);

  const onSearchDoctorHandler = _.debounce(async function (searchInput) {
    setDoctorPaginateData({
      ...doctorPaginateData,
      page: 1,
      search: searchInput,
    });
    if (searchInput.length >= 2) getAllDoctorsPaginate(searchInput);
  }, 500);

  const onSearchInstitutesHandler = _.debounce(async function (searchInput) {
    setinstitutesPaginateData({
      ...institutesPaginateData,
      page: 1,
      search: searchInput,
    });
    if (searchInput.length >= 3) getAllInstitutesPaginate(searchInput);
  }, 500);

  const onOpenInstituteMenuHandler = () => {
    setinstitutesPaginateData({
      ...institutesPaginateData,
      page: 1,
    });
    getAllInstitutesPaginate();
  };

  const onOpenDoctorMenuHandler = () => {
    setDoctorPaginateData({
      ...doctorPaginateData,
      page: 1,
    });
    getAllDoctorsPaginate();
  };

  const [latitude, setLatitude] = useState(15.87);
  const [longitude, setLongitude] = useState(100.5086199930891);
  const [address, setAddress] = useState(
    "Ministry of Public Health The Secretariat of the Medical Council"
  );
  const libraries = ["places", "geometry"];

  const google_key = config.google_key;

  const inputRef = useRef();
  const inputRef1 = useRef();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      //console.log(place);
      if (place.geometry.location.lat() && place.geometry.location.lng()) {
        setLatitude(place.geometry.location.lat());
        setLongitude(place.geometry.location.lng());
        setAddress(place.formatted_address);
      }
      formik.setFieldValue("address", place.formatted_address);
      formik.setFieldValue("latitude", place.geometry.location.lat());
      formik.setFieldValue("longitude", place.geometry.location.lng());
    }
  };

  const handlePlaceChangedOne = () => {
    const [place] = inputRef1.current.getPlaces();
    if (place) {
      //console.log(place);
      if (place.geometry.location.lat() && place.geometry.location.lng()) {
        setLatitude(place.geometry.location.lat());
        setLongitude(place.geometry.location.lng());
        setAddress(place.formatted_address);
      }
      formik.setFieldValue("address", place.formatted_address);
      formik.setFieldValue("latitude", place.geometry.location.lat());
      formik.setFieldValue("longitude", place.geometry.location.lng());
    }
  };

  const onLocationSearch = () => {
    window.$("#locationSearch").modal("show");
  };

  function handleLoad() {}

  function hanldePlacesChanged() {}

  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    setLatitude(latLng.lat());
    setLongitude(latLng.lng());
    formik.setFieldValue("latitude", latLng.lat());
    formik.setFieldValue("longitude", latLng.lng());
    formik.setFieldValue("addrsss", address);
  };

  const onBackHandler = () => {
    window.location.href = `${config.ADMIN_URL}/activities`;
  };

  return (
    <div>
      {instituteID === null && <Navbar />}
      <ScrollToTopOnMount />
      <MobPageTitleBar
        isBackShow={instituteID === null ? true : false}
        title={`${props.t("Submit")} ${props.t("Activity")}`}
        mobBackUrl="/profile"
      />

      {instituteID === null && <ProfileTitle title={props.t("Profile")} />}

      <section className="dashboard top-curve2 mob-brd-radius-0">
        <div className="container">
          <div className="row p-8">
            {instituteID === null && <ProfileSidebar />}
            <div className={instituteID === null ? "col-md-10" : "col-md-12"}>
              <div className="row">
                <div className="col-md-12">
                  <h2 className="mb-3 d-m-none">
                    {props.t("Submit")} {props.t("Activity")}
                  </h2>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <div className="dashboard-inner application-form">
                  {(parseInt(roleID) === constants.ROLE.INSTITUTE ||
                    parseInt(roleID) === constants.ROLE.SUBINSTITUTE) && (
                    <div className="form-group">
                      <div className="float-right">
                        <button
                          type="button"
                          onClick={onBackHandler}
                          className="btn btn-join"
                        >
                          {props.t("Back")}
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="form-group mb-4">
                    <label className="form-label">{props.t("Image")}</label>
                    <div className="d-flex">
                      <div className="logoContainer">
                        {selectedActivityImage ? (
                          <img
                            src={URL.createObjectURL(selectedActivityImage)}
                          />
                        ) : (
                          <img
                            src={
                              config.BASE_URL + "/assets/images/no-preview.jpg"
                            }
                          />
                        )}
                      </div>
                      <div className="fileContainer sprite">
                        <span>{props.t("Change")}</span>
                        <Input
                          name="activity_image"
                          type="file"
                          accept="image/*"
                          className="form-control"
                          onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                              setSelectedActivityImage(e.target.files[0]);
                            }
                          }}
                          invalid={
                            formik.touched.activity_image &&
                            formik.errors.activity_image
                              ? true
                              : false
                          }
                        />
                        <strong>
                          PNG, JPG <br /> (recommended: square size)
                        </strong>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-4">
                    <label className="form-label">
                      {props.t("Activity-Name")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="activity_name"
                      className={
                        formik.touched.activity_name &&
                        formik.errors.activity_name
                          ? "form-control bor"
                          : "form-control text-dark"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.activity_name}
                      placeholder={props.t("Activity-Name")}
                    />
                    {formik.touched.activity_name &&
                      formik.errors.activity_name && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.activity_name}
                        </div>
                      )}
                  </div>

                  <div className="form-group mb-4">
                    <label className="form-label">
                      {props.t("Activity")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      onChange={(e) => {
                        formik.setFieldValue("activity_group", e?.label);
                        fetchActivitySubTypes(e?.label);
                      }}
                      onBlur={formik.handleBlur}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderRadius: "50px",
                        }),
                      }}
                      name="activity_group"
                      className={
                        formik.touched.activity_group &&
                        formik.errors.activity_group
                          ? " bor"
                          : " text-dark"
                      }
                      options={activityTypes.map((value) => ({
                        value: `${value.id}`,
                        label: `${value.activity_group}`,
                      }))}
                    />
                    {formik.touched.activity_group &&
                      formik.errors.activity_group && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.activity_group}
                        </div>
                      )}
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="" className="form-label">
                      {props.t("Sub-Activity")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      onChange={(e) => {
                        formik.setFieldValue("activity_sub_type", e?.value);
                        getActivityTypeJson(e?.label);
                        setSelectedActivityType(e?.label);
                      }}
                      onBlur={formik.handleBlur}
                      name="activity_sub_type"
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderRadius: "50px",
                        }),
                      }}
                      className={
                        formik.touched.activity_sub_type &&
                        formik.errors.activity_sub_type
                          ? "bor"
                          : "text-dark"
                      }
                      options={activitySubTypes.map((value) => ({
                        value: `${value.id}`,
                        label: `${value.code}`,
                      }))}
                    />
                    {formik.touched.activity_sub_type &&
                      formik.errors.activity_sub_type && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.activity_sub_type}
                        </div>
                      )}
                  </div>

                  <div className="form-group mb-4">
                    <label className="form-label">
                      {props.t("Description")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      type="text"
                      rows={5}
                      className={
                        formik.touched.description && formik.errors.description
                          ? "form-control bor"
                          : "form-control text-dark"
                      }
                      name="description"
                      placeholder={props.t("Description")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      style={{ height: "unset" }}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.description}
                        </div>
                      )}
                  </div>

                  {isInstituteFiledEnable && (
                    <div className="form-group mb-4">
                      <label className="form-label">
                        {props.t("Institution")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className={
                          formik.touched.institute_id &&
                          formik.errors.institute_id
                            ? " bor"
                            : " text-dark  "
                        }
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderRadius: "50px",
                          }),
                        }}
                        onChange={(e) => {
                          formik.setFieldValue("institute_id", e?.value);
                        }}
                        onMenuScrollToBottom={onScrollInstituesDataHandler}
                        onInputChange={onSearchInstitutesHandler}
                        onMenuOpen={onOpenInstituteMenuHandler}
                        defaultValue={formik.values.institute_id}
                        onBlur={formik.handleBlur}
                        name="institute_id"
                        options={[
                          ...institutesData,
                          // isInstituteLoading
                          //   ? {
                          //       value: "loading",
                          //       label: "Loading more...",
                          //       disabled: true,
                          //     }
                          //   : {},
                        ]}
                        placeholder={<div>{props.t("typeToSearch")}</div>}
                      />
                      {formik.touched.institute_id &&
                        formik.errors.institute_id && (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.institute_id}
                          </div>
                        )}
                    </div>
                  )}

                  {activityTypeJson?.score_type_id ==
                    constants.SCORE_TYPE.EXAM_WITH_QUIZ && (
                    <div className="row form-group mb-4">
                      <div className="col-sm-6">
                        <Input
                          type="radio"
                          value="subtask"
                          name="taskTypeForInternship"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "taskTypeForInternship",
                              e.target.value
                            );
                            onValidationHandler(
                              "taskTypeForInternship",
                              e.target.value
                            );
                          }}
                          defaultChecked={true}
                        />
                        <label className="form-label">
                          &nbsp;{props.t("subActivityTask")}{" "}
                        </label>
                      </div>

                      <div className="col-sm-6">
                        <Input
                          type="radio"
                          value="quiz"
                          name="taskTypeForInternship"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "taskTypeForInternship",
                              e.target.value
                            );
                            onValidationHandler(
                              "taskTypeForInternship",
                              e.target.value
                            );
                          }}
                        />
                        <label className="form-label">
                          &nbsp;{props.t("quizType")}{" "}
                        </label>
                      </div>
                    </div>
                  )}
    
                  {formik.values.taskTypeForInternship === "quiz" &&
                    activitySubTypeDetail?.type === "internship" &&
                    activityTypeJson?.score_type_id ==
                      constants.SCORE_TYPE.EXAM_WITH_QUIZ && (
                      <>
                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Quiz-Type")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Select
                            onChange={(e) => {
                              formik.setFieldValue("quiz_type_id", e?.value);
                              getQuizTypeDetail(e?.value);
                              setSelectedQuizType(e?.label);
                            }}
                            onBlur={formik.handleBlur}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderRadius: "50px",
                              }),
                            }}
                            name="quiz_type_id"
                            className={
                              formik.touched.quiz_type_id &&
                              formik.errors.quiz_type_id
                                ? " bor"
                                : " text-dark"
                            }
                            options={quizTypes.map((value) => ({
                              value: `${value.id}`,
                              label: `${value.name_en}`,
                            }))}
                          />
                          {formik.touched.quiz_type_id &&
                            formik.errors.quiz_type_id && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.quiz_type_id}
                              </div>
                            )}
                        </div>

                        {(parseInt(roleID) !== constants.ROLE.INSTITUTE ||
                          parseInt(roleID) !== constants.ROLE.SUBINSTITUTE) && (
                          <>
                            {quizTypeDetail.id && (
                              <div className="form-group mb-4">
                                <div className="row">
                                  <div className="col-md-4 col-12">
                                    <label className="form-label">
                                      {props.t("Quiz-Provider-Score")} :{" "}
                                      {quizTypeDetail.quiz_provider_score}
                                    </label>
                                  </div>
                                  <div className="col-md-4 col-12">
                                    <label className="form-label">
                                      {props.t("Quiz-Taker-Score")} :{" "}
                                      {quizTypeDetail.quiz_taker_score}
                                    </label>
                                  </div>
                                  {/* <div className="col-md-4 col-12">
                                  <label className="form-label">
                                    {props.t("Max-Questions")} :{" "}
                                    {quizTypeDetail.max_questions}
                                  </label>
                                </div> */}
                                </div>
                              </div>
                            )}
                            <div className="form-group mb-4 row">
                              {isAddQuizBtnEnable && (
                                <>
                                  <div className="col-1">
                                    <img
                                      src="assets/images/plus.png"
                                      alt="link"
                                      width="15"
                                    />
                                  </div>
                                  <div className="col-11">
                                    <a
                                      href="#"
                                      onClick={onAddViewWuizHandler}
                                      className="text-blue"
                                    >
                                      {props.t("Add")} {props.t("Quiz")}
                                    </a>
                                    {addedQuizCount !== 0 && (
                                      <span
                                        style={{ color: "#198754" }}
                                      >{`  (${addedQuizCount} Question Added)`}</span>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            {
                              <AddEditQuizModal
                                setAddedQuizCout={setAddedQuizCout}
                                setQuizQuestions={setQuizQuestions}
                                maxQuestion={quizTypeDetail.max_questions}
                                selectedQuizType={selectedQuizType}
                                questions={[]}
                                isInstituteFiledEnable={
                                  roleID === constants.ROLE.DOCTOR
                                    ? true
                                    : false
                                }
                              />
                            }
                          </>
                        )}
                      </>
                    )}

                  {formik.values.taskTypeForInternship === "subtask" &&
                    activitySubTypeDetail?.type === "internship" &&
                    activityTypeJson?.score_type_id ==
                      constants.SCORE_TYPE.EXAM_WITH_QUIZ && (
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("subActivity")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={
                            formik.touched.task_type_id &&
                            formik.errors.task_type_id
                              ? " bor"
                              : " text-dark  "
                          }
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          onChange={(e) => {
                            formik.setFieldValue("task_type_id", e?.value);
                            onValidationHandler("task_type_id", e?.value);
                          }}
                          defaultValue={formik.values.task_type_id}
                          onBlur={formik.handleBlur}
                          name="task_type_id"
                          options={subActivityTask.map((value) => ({
                            value: value.id,
                            label: `${value.name_en}`,
                          }))}
                        />
                        {formik.touched.task_type_id &&
                          formik.errors.task_type_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.task_type_id}
                            </div>
                          )}
                      </div>
                    )}

                  {(formik.values.task_type_id ===
                    constants.TASKTYPE.INTERVIEWTEST ||
                    formik.values.task_type_id ===
                      constants.TASKTYPE.MEQSHORTASSAY ||
                    formik.values.taskTypeForInternship === "quiz") &&
                    (parseInt(roleID) === constants.ROLE.DOCTOR ||
                      parseInt(roleID) === constants.ROLE.INSTITUTE) && (
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("amountOfExaminee")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          name="amountOfExaminee"
                          className={
                            formik.touched.amountOfExaminee &&
                            formik.errors.amountOfExaminee
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.amountOfExaminee}
                          placeholder={props.t("amountOfExaminee")}
                        />
                        {formik.touched.amountOfExaminee &&
                          formik.errors.amountOfExaminee && (
                            <div style={{ color: "#f46a6a", fontSize: "11px" }}>
                              {formik.errors.amountOfExaminee}
                            </div>
                          )}
                      </div>
                    )}

                  {/* {((activityTypeJson?.assign_as_task && constants.ROLE.INSTITUTE == roleID) &&
                    (activityTypeJson?.task_type_id === constants.TASKTYPE.EXAMPROCESS)) && (
                      <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("amountOfExaminee")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            name="amountOfExaminee"
                            className={
                              formik.touched.amountOfExaminee &&
                              formik.errors.amountOfExaminee
                                ? "form-control bor"
                                : "form-control text-dark"
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.amountOfExaminee}
                            placeholder={props.t("amountOfExaminee")}
                          />
                          {formik.touched.amountOfExaminee &&
                            formik.errors.amountOfExaminee && (
                              <div style={{ color: "#f46a6a", fontSize: "11px" }}>
                                {formik.errors.amountOfExaminee}
                              </div>
                            )}
                      </div>
                  )} */}

                  {activityTypeJson?.can_attach_file &&
                    activitySubTypeDetail?.type === "normal" && (
                      <>
                        {activityTypeJson?.score_type_id ==
                          constants.SCORE_TYPE.FILE_ATTACHMENT && (
                          <div className="form-group mb-4">
                            <label htmlFor="" className="form-label">
                              {props.t("Attachment-Type")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "attachment_type_id",
                                  e?.value
                                );
                                onValidationHandler(
                                  "attachment_type_id",
                                  e?.value
                                );
                              }}
                              onBlur={formik.handleBlur}
                              name="attachment_type_id"
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  borderRadius: "50px",
                                }),
                              }}
                              className={
                                formik.touched.attachment_type_id &&
                                formik.errors.attachment_type_id
                                  ? "bor"
                                  : "text-dark"
                              }
                              options={attachmentTypes.map((value) => ({
                                value: `${value.id}`,
                                label: `${value.name_en}`,
                              }))}
                            />
                            {formik.touched.attachment_type_id &&
                              formik.errors.attachment_type_id && (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {formik.errors.attachment_type_id}
                                </div>
                              )}
                          </div>
                        )}
                     

                        
                      {selectedActivityType == '3.1' && (
                          <>
                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("Content-Type")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <Select
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "content_type",
                                    e?.value
                                  );
                                  onValidationHandler(
                                    "content_type",
                                    e?.value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    borderRadius: "50px",
                                  }),
                                }}
                                name="content_type"
                                // defaultValue={{
                                //   value: `${
                                //     activity.content_type
                                //       ? activity.content_type
                                //       : ""
                                //   }`,
                                //   label: `${
                                //     activity.content_type
                                //       ? activity.content_type
                                //       : ""
                                //   }`,
                                // }}
                                className={
                                  formik.touched.content_type &&
                                  formik.errors.content_type
                                    ? " bor"
                                    : " text-dark"
                                }
                                options={Object.keys(constants.CONTENT_ACTIVITY_TYPE).map(
                                  (key) => ({
                                    value: `${key}`,
                                    label: `${key}`,
                                  })
                                )}
                              />
                              {formik.touched.content_type &&
                                formik.errors.content_type && (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {formik.errors.content_type}
                                  </div>
                                )}
                            </div>
                          </>
                        )}






                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Attachment")}
                          </label>
                          <div className="fileupload">
                            <div className="text-center">
                              <label>
                                <a
                                  className="btn btnUpload"
                                  role="button"
                                  aria-disabled="false"
                                >
                                  <img src="assets/images/preview.png" />
                                  <br />
                                  <strong className="text-blue">
                                    {props.t("Upload")} {props.t("Attachments")}
                                  </strong>
                                  <br />
                                  <strong className="note">
                                    PNG, JPG, GIF, PDF, DOCX
                                  </strong>
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {props.t("File-Max-Size-Message")}
                                  </div>
                                </a>
                                <Input
                                  name="attachments"
                                  type="file"
                                  multiple
                                  className="form-control upload__inputfile"
                                  onBlur={(e) => {
                                    if (
                                      e.target.files.length == 0 &&
                                      formik.values.attachments &&
                                      formik.values.attachments.length == 0
                                    ) {
                                      formik.setFieldValue("attachments", null);
                                    }
                                  }}
                                  onChange={(e) => {
                                    const filesArray2 = [];
                                    for (
                                      let i = 0;
                                      i < e.target.files.length;
                                      i++
                                    ) {
                                      filesArray2.push(e.target.files[i]);
                                    }
                                    formik.setFieldValue(
                                      "attachments",
                                      formik.values.attachments
                                        ? [
                                            ...formik.values.attachments,
                                            ...filesArray2,
                                          ]
                                        : filesArray2
                                    );
                                  }}
                                  invalid={
                                    formik.touched.attachments &&
                                    formik.errors.attachments
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.attachments &&
                                  formik.errors.attachments && (
                                    <div
                                      style={{
                                        color: "#f46a6a",
                                        fontSize: "11px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      {formik.errors.attachments}
                                    </div>
                                  )}
                              </label>
                            </div>
                            <div className="pdf-block">
                              <ul>
                                {formik.values.attachments?.length > 0
                                  ? formik.values.attachments?.map(
                                      (item, index) => {
                                        return (
                                          <li key={index}>
                                            <div className="d-flex align-items-center mb-3 row">
                                              <div className="col-1">
                                                <img
                                                  src="assets/images/link.png"
                                                  alt="link"
                                                  width="15"
                                                />
                                              </div>
                                              <div className="col-10 text">
                                                <a
                                                  href={URL.createObjectURL(
                                                    item
                                                  )}
                                                  target="_blank"
                                                  className="text-blue"
                                                >
                                                  {item.name}
                                                </a>
                                              </div>
                                              <div className="col-1">
                                                <a
                                                  onClick={(e) => {
                                                    removeAttachment(
                                                      item.lastModified
                                                    );
                                                  }}
                                                  title="Remove"
                                                >
                                                  <img
                                                    src={require("assets/images/delete-icon.png")}
                                                    width="15"
                                                  />
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      }
                                    )
                                  : null}
                              </ul>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  {activityTypeJson?.can_attach_file &&
                    activitySubTypeDetail?.type === "internship" &&
                    roleID === constants.ROLE.DOCTOR && (
                      <>
                        {activityTypeJson?.score_type_id ==
                          constants.SCORE_TYPE.FILE_ATTACHMENT && (
                          <div className="form-group mb-4">
                            <label htmlFor="" className="form-label">
                              {props.t("Attachment-Type")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "attachment_type_id",
                                  e?.value
                                );
                                onValidationHandler(
                                  "attachment_type_id",
                                  e?.value
                                );
                              }}
                              onBlur={formik.handleBlur}
                              name="attachment_type_id"
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  borderRadius: "50px",
                                }),
                              }}
                              className={
                                formik.touched.attachment_type_id &&
                                formik.errors.attachment_type_id
                                  ? "bor"
                                  : "text-dark"
                              }
                              options={attachmentTypes.map((value) => ({
                                value: `${value.id}`,
                                label: `${value.name_en}`,
                              }))}
                            />
                            {formik.touched.attachment_type_id &&
                              formik.errors.attachment_type_id && (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {formik.errors.attachment_type_id}
                                </div>
                              )}
                          </div>
                        )}

                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Attachment")}
                          </label>
                          <div className="fileupload">
                            <div className="text-center">
                              <label>
                                <a
                                  className="btn btnUpload"
                                  role="button"
                                  aria-disabled="false"
                                >
                                  <img src="assets/images/preview.png" />
                                  <br />
                                  <strong className="text-blue">
                                    {props.t("Upload")} {props.t("Attachments")}
                                  </strong>
                                  <br />
                                  <strong className="note">
                                    PNG, JPG, GIF, PDF, DOCX
                                  </strong>
                                  <br />
                                  <strong
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {props.t("File-Max-Size-Message")}
                                  </strong>
                                </a>
                                <Input
                                  name="attachments"
                                  type="file"
                                  multiple
                                  className="form-control upload__inputfile"
                                  onBlur={(e) => {
                                    if (
                                      e.target.files.length == 0 &&
                                      formik.values.attachments &&
                                      formik.values.attachments.length == 0
                                    ) {
                                      formik.setFieldValue("attachments", null);
                                    }
                                  }}
                                  onChange={(e) => {
                                    const filesArray2 = [];
                                    for (
                                      let i = 0;
                                      i < e.target.files.length;
                                      i++
                                    ) {
                                      filesArray2.push(e.target.files[i]);
                                    }
                                    formik.setFieldValue(
                                      "attachments",
                                      formik.values.attachments
                                        ? [
                                            ...formik.values.attachments,
                                            ...filesArray2,
                                          ]
                                        : filesArray2
                                    );
                                    onValidationHandler(
                                      "attachments",
                                      e?.value
                                    );
                                  }}
                                  invalid={
                                    formik.touched.attachments &&
                                    formik.errors.attachments
                                      ? true
                                      : false
                                  }
                                />
                                {formik.touched.attachments &&
                                formik.errors.attachments ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {formik.errors.attachments}
                                  </div>
                                ) : null}
                              </label>
                            </div>
                            <div className="pdf-block">
                              <ul>
                                {formik.values.attachments?.length > 0
                                  ? formik.values.attachments?.map(
                                      (item, index) => {
                                        return (
                                          <li key={index}>
                                            <div className="d-flex align-items-center mb-3 row">
                                              <div className="col-1">
                                                <img
                                                  src="assets/images/link.png"
                                                  alt="link"
                                                  width="15"
                                                />
                                              </div>
                                              <div className="col-10 text">
                                                <a
                                                  href={URL.createObjectURL(
                                                    item
                                                  )}
                                                  target="_blank"
                                                  className="text-blue"
                                                >
                                                  {item.name}
                                                </a>
                                              </div>
                                              <div className="col-1">
                                                <a
                                                  onClick={(e) => {
                                                    removeAttachment(
                                                      item.lastModified
                                                    );
                                                  }}
                                                  title="Remove"
                                                >
                                                  <img
                                                    src={require("assets/images/delete-icon.png")}
                                                    width="15"
                                                  />
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      }
                                    )
                                  : null}
                              </ul>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  {activityTypeJson?.can_add_quiz && (
                    <>
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("Quiz-Type")}{" "}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <Select
                          onChange={(e) => {
                            formik.setFieldValue("quiz_type_id", e?.value);
                            getQuizTypeDetail(e?.value);
                            setSelectedQuizType(e?.label);
                          }}
                          onBlur={formik.handleBlur}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          name="quiz_type_id"
                          className={
                            formik.touched.quiz_type_id &&
                            formik.errors.quiz_type_id
                              ? " bor"
                              : " text-dark"
                          }
                          options={quizTypes.map((value) => ({
                            value: `${value.id}`,
                            label: `${value.name_en}`,
                          }))}
                        />
                        {formik.touched.quiz_type_id &&
                          formik.errors.quiz_type_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.quiz_type_id}
                            </div>
                          )}
                      </div>
                      {quizTypeDetail.id && (
                        <div className="form-group mb-4">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <label className="form-label">
                                {props.t("Quiz-Provider-Score")} :{" "}
                                {quizTypeDetail.quiz_provider_score}
                              </label>
                            </div>
                            <div className="col-md-4 col-12">
                              <label className="form-label">
                                {props.t("Quiz-Taker-Score")} :{" "}
                                {quizTypeDetail.quiz_taker_score}
                              </label>
                            </div>
                            {/* <div className="col-md-4 col-12">
                              <label className="form-label">
                                {props.t("Max-Questions")} :{" "}
                                {quizTypeDetail.max_questions}
                              </label>
                            </div> */}
                          </div>
                        </div>
                      )}
                      <div className="form-group mb-4 row">
                        {isAddQuizBtnEnable && (
                          <>
                            <div className="col-1">
                              <img
                                src="assets/images/plus.png"
                                alt="link"
                                width="15"
                              />
                            </div>
                            <div className="col-11">
                              <a
                                href="#"
                                onClick={onAddViewWuizHandler}
                                className="text-blue"
                              >
                                {props.t("Add")} {props.t("Quiz")}
                              </a>
                              {addedQuizCount !== 0 && (
                                <span
                                  style={{ color: "#198754" }}
                                >{`  (${addedQuizCount} Question Added)`}</span>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {
                        <AddEditQuizModal
                          setAddedQuizCout={setAddedQuizCout}
                          setQuizQuestions={setQuizQuestions}
                          maxQuestion={quizTypeDetail.max_questions}
                          selectedQuizType={selectedQuizType}
                          questions={[]}
                          isInstituteFiledEnable={
                            roleID === constants.ROLE.DOCTOR ? true : false
                          }
                        />
                      }
                    </>
                  )}
                  
                  {(activitySubTypeDetail?.type === "internship" &&
                    constants.SCORE_TYPE.FOUR_LEVEL_EVALUATION ==
                      activityTypeJson?.score_type_id) ||
                  ((formik.values.task_type_id ===
                    constants.TASKTYPE.INTERVIEWTEST ||
                    formik.values.task_type_id ===
                      constants.TASKTYPE.REVIEWEXAM) &&
                    parseInt(roleID) === constants.ROLE.INSTITUTE) ? (
                    <>
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("Doctor")} {" ("}
                          {props.t("Teacher")}
                          {")"}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={
                            formik.touched.doctor_id && formik.errors.doctor_id
                              ? " bor"
                              : " text-dark  "
                          }
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          onChange={(e) => {
                            formik.setFieldValue("doctor_id", e?.value);
                          }}
                          onMenuScrollToBottom={onScrollDoctorListHandler}
                          onInputChange={onSearchDoctorHandler}
                          onMenuOpen={onOpenDoctorMenuHandler}
                          defaultValue={formik.values.doctor_id}
                          onBlur={formik.handleBlur}
                          name="doctor_id"
                          options={[
                            ...doctorsData,
                            // isDoctorLoading
                            //   ? {
                            //       value: "loading",
                            //       label: "Loading more...",
                            //       disabled: true,
                            //     }
                            //   : {},
                          ]}
                          placeholder={<div>{props.t("typeToSearch")}</div>}
                        />
                        {formik.touched.doctor_id &&
                          formik.errors.doctor_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.doctor_id}
                            </div>
                          )}
                      </div>
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("Doctor-Intern")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={
                            formik.touched.intern_id && formik.errors.intern_id
                              ? " bor"
                              : " text-dark  "
                          }
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          onChange={(e) => {
                            formik.setFieldValue("intern_id", e?.value);
                          }}
                          onMenuScrollToBottom={onScrollDoctorListHandler}
                          onInputChange={onSearchDoctorHandler}
                          onMenuOpen={onOpenDoctorMenuHandler}
                          defaultValue={formik.values.intern_id}
                          onBlur={formik.handleBlur}
                          name="intern_id"
                          options={[
                            ...doctorsData,
                            // isDoctorLoading
                            //   ? {
                            //       value: "loading",
                            //       label: "Loading more...",
                            //       disabled: true,
                            //     }
                            //   : {},
                          ]}
                          placeholder={<div>{props.t("typeToSearch")}</div>}
                        />
                        {formik.touched.intern_id &&
                          formik.errors.intern_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.intern_id}
                            </div>
                          )}
                      </div>
                      {formik.values.task_type_id !==
                        constants.TASKTYPE.INTERVIEWTEST &&
                      formik.values.task_type_id !==
                        constants.TASKTYPE.REVIEWEXAM ? (
                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Doctor-hod")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            className={
                              formik.touched.doctor_hod_id &&
                              formik.errors.doctor_hod_id
                                ? " bor"
                                : " text-dark  "
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderRadius: "50px",
                              }),
                            }}
                            onChange={(e) => {
                              formik.setFieldValue("doctor_hod_id", e?.value);
                            }}
                            onMenuScrollToBottom={onScrollDoctorListHandler}
                            onInputChange={onSearchDoctorHandler}
                            onMenuOpen={onOpenDoctorMenuHandler}
                            defaultValue={formik.values.doctor_hod_id}
                            onBlur={formik.handleBlur}
                            name="doctor_hod_id"
                            options={[
                              ...doctorsData,
                              // isDoctorLoading
                              //   ? {
                              //       value: "loading",
                              //       label: "Loading more...",
                              //       disabled: true,
                              //     }
                              //   : {},
                            ]}
                            placeholder={<div>{props.t("typeToSearch")}</div>}
                          />
                          {formik.touched.doctor_hod_id &&
                            formik.errors.doctor_hod_id && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.doctor_hod_id}
                              </div>
                            )}
                        </div>
                      ) : (
                        ""
                      )}

                      {formik.values.task_type_id !==
                        constants.TASKTYPE.REVIEWEXAM && (
                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Evaluation-Form")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            onChange={(e) => {
                              formik.setFieldValue(
                                "evaluation_form_ids",
                                e?.value
                              );
                            }}
                            onBlur={formik.handleBlur}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderRadius: "50px",
                              }),
                            }}
                            name="evaluation_form_ids"
                            className={
                              formik.touched.evaluation_form_ids &&
                              formik.errors.evaluation_form_ids
                                ? " bor"
                                : " text-dark"
                            }
                            options={evaluationForms.map((value) => ({
                              value: `${value.id}`,
                              label: `${value.name_en}`,
                            }))}
                          />
                          {formik.touched.evaluation_form_ids &&
                            formik.errors.evaluation_form_ids && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.evaluation_form_ids}
                              </div>
                            )}
                        </div>
                      )}
                    </>
                  ) : (!activityTypeJson?.assign_as_task &&
                      (constants.ROLE.INSTITUTE === parseInt(roleID) ||
                        constants.ROLE.SUBINSTITUTE === parseInt(roleID)) &&
                      selectedActivityType === "3.1") ||
                    (!activityTypeJson?.assign_as_task &&
                      (constants.ROLE.INSTITUTE === parseInt(roleID) ||
                        constants.ROLE.SUBINSTITUTE === parseInt(roleID)) &&
                      activitySubTypeDetail?.type === "internship" &&
                      formik.values.task_type_id !== "" &&
                      formik.values.task_type_id !==
                        constants.TASKTYPE.INTERVIEWTEST) ? (
                    <div className="form-group mb-4">
                      <label className="form-label">
                        {props.t("Doctor")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className={
                          formik.touched.doctor_id && formik.errors.doctor_id
                            ? " bor"
                            : " text-dark  "
                        }
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderRadius: "50px",
                          }),
                        }}
                        onChange={(e) => {
                          formik.setFieldValue("doctor_id", e?.value);
                        }}
                        onInputChange={onSearchDoctorHandler}
                        onMenuScrollToBottom={onScrollDoctorListHandler}
                        onMenuOpen={onOpenDoctorMenuHandler}
                        defaultValue={formik.values.doctor_id}
                        onBlur={formik.handleBlur}
                        name="doctor_id"
                        options={[
                          ...doctorsData,
                          // isDoctorLoading
                          //   ? {
                          //       value: "loading",
                          //       label: "Loading more...",
                          //       disabled: true,
                          //     }
                          //   : {},
                        ]}
                        placeholder={<div>{props.t("typeToSearch")}</div>}
                      />
                      {formik.touched.doctor_id && formik.errors.doctor_id && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.doctor_id}
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {activitySubTypeDetail?.type === "internship" &&
                    parseInt(roleID) === constants.ROLE.INSTITUTE &&
                    constants.SCORE_TYPE.ONE_YEAR_ROTATION ==
                      activityTypeJson?.score_type_id && (
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("internshipSession")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            formik.setFieldValue(
                              "internship_session_id",
                              e?.value
                            );
                            onValidationHandler(
                              "internship_session_id",
                              e?.value
                            );
                            getInternshipSessionAllTasks(e?.value);
                          }}
                          onBlur={formik.handleBlur}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          name="internship_session_id"
                          className={
                            formik.touched.internship_session_id &&
                            formik.errors.internship_session_id
                              ? " bor"
                              : " text-dark"
                          }
                          options={internshipSessions}
                        />
                        {formik.touched.internship_session_id &&
                          formik.errors.internship_session_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.internship_session_id}
                            </div>
                          )}
                      </div>
                    )}

                  {activitySubTypeDetail?.type === "internship" &&
                    parseInt(roleID) === constants.ROLE.INSTITUTE &&
                    constants.SCORE_TYPE.ONE_YEAR_ROTATION ==
                      activityTypeJson?.score_type_id && (
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("internshipTask")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            formik.setFieldValue("session_task_id", e?.value);
                            onValidationHandler("session_task_id", e?.value);
                            getInternshipSessionTaskDetails(e?.value);
                          }}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          name="session_task_id"
                          className={
                            formik.touched.session_task_id &&
                            formik.errors.session_task_id
                              ? " bor"
                              : " text-dark"
                          }
                          options={sessionTasks}
                        />
                        {formik.touched.session_task_id &&
                          formik.errors.session_task_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.session_task_id}
                            </div>
                          )}
                      </div>
                    )}
                  {((!activityTypeJson?.can_add_quiz &&
                    activityTypeJson?.visible_to_other && !activityTypeJson?.can_other_join_activity) ||
                    (activityTypeJson?.can_add_quiz &&
                      activityTypeJson?.visible_to_other &&
                      activityTypeJson?.can_other_join_activity) || (!activityTypeJson?.can_add_quiz &&
                        activityTypeJson?.visible_to_other &&
                        !activityTypeJson?.can_other_join_activity) || (!activityTypeJson?.can_add_quiz &&
                          activityTypeJson?.visible_to_other &&
                          activityTypeJson?.can_other_join_activity)) && (
                    <div className="form-group mb-4">
                      <label className="form-label">
                        {props.t("educationType")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        onChange={(e) => {
                          formik.setFieldValue("educationType", e?.value);
                        }}
                        onBlur={formik.handleBlur}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderRadius: "50px",
                          }),
                        }}
                        name="educationType"
                        className={
                          formik.touched.educationType &&
                          formik.errors.educationType
                            ? " bor"
                            : " text-dark"
                        }
                        options={Object.keys(constants.EDUCATION_TYPE).map(
                          (key) => ({
                            value: `${key}`,
                            label: `${constants.EDUCATION_TYPE[key]}`,
                          })
                        )}
                      />
                      {formik.touched.educationType &&
                        formik.errors.educationType && (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.educationType}
                          </div>
                        )}
                    </div>
                  )}

                  
                                                          
                  {(activityTypeJson?.can_other_join_activity ||
                    (activitySubTypeDetail?.type === "internship" &&
                      constants.SCORE_TYPE.FOUR_LEVEL_EVALUATION ==
                        activityTypeJson?.score_type_id) ||
                    ((formik.values.task_type_id ===
                      constants.TASKTYPE.INTERVIEWTEST ||
                      formik.values.task_type_id ===
                        constants.TASKTYPE.REVIEWEXAM) &&
                      parseInt(roleID) === constants.ROLE.INSTITUTE) ||
                    (activitySubTypeDetail?.type === "internship" &&
                      parseInt(roleID) === constants.ROLE.INSTITUTE &&
                      constants.SCORE_TYPE.ONE_YEAR_ROTATION ==
                        activityTypeJson?.score_type_id)) && (
                    <>
                   
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("Type")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            formik.setFieldValue("type", e?.value);
                            onValidationHandler("type", e?.value);
                          }}
                          onBlur={formik.handleBlur}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          name="type"
                          className={
                            formik.touched.type && formik.errors.type
                              ? " bor"
                              : " text-dark"
                          }
                          options={Object.keys(constants.TYPE).map((key) => ({
                            value: `${key}`,
                            label: `${key}`,
                          }))}
                        />
                        {formik.touched.type && formik.errors.type && (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.type}
                          </div>
                        )}
                      </div>

                      {formik.values.type &&
                        (formik.values.type === constants.TYPE.Onsite ||
                          formik.values.type === constants.TYPE.Hybrid) && (
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("Address")}{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <LoadScript
                              googleMapsApiKey={google_key}
                              libraries={libraries}
                              region="th"
                              country="th"
                            >
                              <StandaloneSearchBox
                                onLoad={(ref) => (inputRef1.current = ref)}
                                onPlacesChanged={handlePlaceChangedOne}
                              >
                                <input
                                  type="text"
                                  name="address"
                                  className={
                                    formik.touched.address &&
                                    formik.errors.address
                                      ? "form-control bor"
                                      : "form-control text-dark"
                                  }
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.address}
                                  placeholder={props.t("Address")}
                                />
                              </StandaloneSearchBox>
                            </LoadScript>

                            {formik.touched.address &&
                              formik.errors.address && (
                                <div
                                  style={{ color: "#f46a6a", fontSize: "11px" }}
                                >
                                  {formik.errors.address}
                                </div>
                              )}
                            <div>
                              <a
                                href="#"
                                onClick={onLocationSearch}
                                className="btn btn-join"
                                style={{ float: "left" }}
                              >
                                <img
                                  className="img-fluid "
                                  src="assets/images/location-marker.png"
                                  alt=""
                                  width="12"
                                  height="12"
                                />
                                {props.t("FindLocation")}
                              </a>
                            </div>

                            <div className="modal fade in" id="locationSearch">
                              <div className="modal-dialog scrollDialog modal-lg">
                                <div className="modal-content mb-5">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      {props.t("Whatyourexactlocation")}
                                    </h5>
                                  </div>
                                  <div className="modal-body scrollAndFixedHeight">
                                    <LoadScript
                                      googleMapsApiKey={google_key}
                                      libraries={libraries}
                                      region="th"
                                      country="th"
                                    >
                                      <StandaloneSearchBox
                                        onLoad={(ref) =>
                                          (inputRef.current = ref)
                                        }
                                        onPlacesChanged={handlePlaceChanged}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder={props.t("Address")}
                                          style={{
                                            marginBottom: "15px",
                                            border: "1px solid #ccc",
                                          }}
                                        />
                                      </StandaloneSearchBox>

                                      <div>
                                        <a
                                          href="#"
                                          onClick={() => {
                                            window
                                              .$("#locationSearch")
                                              .modal("hide");
                                          }}
                                          className="btn btn-join"
                                          style={{ float: "left" }}
                                        >
                                          {props.t("Save")}
                                        </a>
                                      </div>
                                      <br></br>
                                      <br></br>

                                      <div>
                                        {latitude && longitude && (
                                          <GoogleMap
                                            zoom={14}
                                            center={{
                                              lat: latitude,
                                              lng: longitude,
                                            }}
                                            id="map"
                                            yesIWantToUseGoogleMapApiInternals
                                          >
                                            <MarkerF
                                              position={{
                                                lat: latitude,
                                                lng: longitude,
                                              }}
                                              name={address}
                                              draggable={true}
                                              onDragEnd={(event) =>
                                                onMarkerDragEnd(event)
                                              }
                                              // onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
                                            />
                                          </GoogleMap>
                                        )}
                                      </div>
                                    </LoadScript>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {formik.values.type &&
                        (formik.values.type === constants.TYPE.Onsite ||
                          formik.values.type === constants.TYPE.Hybrid) && (
                          <div
                            className="form-group mb-4"
                            style={{ clear: "both" }}
                          >
                            <label className="form-label">
                              {props.t("Latitude")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="latitude"
                              className={
                                formik.touched.latitude &&
                                formik.errors.latitude
                                  ? "form-control bor"
                                  : "form-control text-dark"
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.latitude}
                              placeholder={props.t("Latitude")}
                            />
                            {formik.touched.latitude &&
                              formik.errors.latitude && (
                                <div
                                  style={{ color: "#f46a6a", fontSize: "11px" }}
                                >
                                  {formik.errors.latitude}
                                </div>
                              )}
                          </div>
                        )}

                      {formik.values.type &&
                        (formik.values.type === constants.TYPE.Onsite ||
                          formik.values.type === constants.TYPE.Hybrid) && (
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("Longitude")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="longitude"
                              className={
                                formik.touched.longitude &&
                                formik.errors.longitude
                                  ? "form-control bor"
                                  : "form-control text-dark"
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.longitude}
                              placeholder={props.t("Longitude")}
                            />
                            {formik.touched.longitude &&
                              formik.errors.longitude && (
                                <div
                                  style={{ color: "#f46a6a", fontSize: "11px" }}
                                >
                                  {formik.errors.longitude}
                                </div>
                              )}
                          </div>
                        )}

                      {formik.values.type &&
                        (formik.values.type === constants.TYPE.Online ||
                          formik.values.type === constants.TYPE.Hybrid) && (
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("Online-Meeting-Url")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="online_meeting_url"
                              className={
                                formik.touched.online_meeting_url &&
                                formik.errors.online_meeting_url
                                  ? "form-control bor"
                                  : "form-control text-dark"
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.online_meeting_url}
                              placeholder={props.t("Online-Meeting-Url")}
                            />
                            {formik.touched.online_meeting_url &&
                              formik.errors.online_meeting_url && (
                                <div
                                  style={{ color: "#f46a6a", fontSize: "11px" }}
                                >
                                  {formik.errors.online_meeting_url}
                                </div>
                              )}
                          </div>
                        )}

                      {sessionTaskDetail && (
                        <>
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("Start")} {props.t("Date")}{" "}
                            </label>
                            <input
                              name="start_date"
                              type="date"
                              className="form-control"
                              disabled
                              readOnly="readOnly"
                              value={
                                moment(sessionTaskDetail.start_date).format(
                                  "YYYY-MM-DD"
                                ) || ""
                              }
                            />
                          </div>
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("End")} {props.t("Date")}{" "}
                            </label>
                            <input
                              name="end_date"
                              type="date"
                              className="form-control"
                              disabled
                              readOnly="readOnly"
                              value={
                                moment(sessionTaskDetail.end_date).format(
                                  "YYYY-MM-DD"
                                ) || ""
                              }
                            />
                          </div>
                        </>
                      )}
                      {activitySubTypeDetail?.type !== "internship" &&
                      (parseInt(roleID) !== constants.ROLE.INSTITUTE ||
                        parseInt(roleID) !== constants.ROLE.DOCTOR) &&
                      constants.SCORE_TYPE.ONE_YEAR_ROTATION !==
                        activityTypeJson?.score_type_id ? (
                        <>
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("Activity")} {props.t("Date")}{" "}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Input
                              name="activity_date"
                              type="date"
                              className={
                                formik.touched.activity_date &&
                                formik.errors.activity_date
                                  ? "form-control bor"
                                  : "form-control text-dark"
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              // min={moment().format("D-M-Y H:M")}
                              value={formik.values.activity_date || ""}
                              invalid={
                                formik.touched.activity_date &&
                                formik.errors.activity_date
                                  ? true
                                  : false
                              }
                            />
                            {formik.touched.activity_date &&
                              formik.errors.activity_date && (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                  }}
                                >
                                  {formik.errors.activity_date}
                                </div>
                              )}
                          </div>

                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("Start-Time")}{" "}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Input
                              name="start_time"
                              type="time"
                              className={
                                formik.touched.start_time &&
                                formik.errors.start_time
                                  ? "form-control bor"
                                  : "form-control text-dark"
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              // min={moment().format("Y-M-D")}
                              value={formik.values.start_time || ""}
                              invalid={
                                formik.touched.start_time &&
                                formik.errors.start_time
                                  ? true
                                  : false
                              }
                            />
                            {formik.touched.start_time &&
                              formik.errors.start_time && (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                  }}
                                >
                                  {formik.errors.start_time}
                                </div>
                              )}
                          </div>

                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("End-Time")}{" "}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Input
                              name="end_time"
                              type="time"
                              className={
                                formik.touched.end_time &&
                                formik.errors.end_time
                                  ? "form-control bor"
                                  : "form-control text-dark"
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              // min={moment().format("Y-M-D")}
                              value={formik.values.end_time || ""}
                              invalid={
                                formik.touched.end_time &&
                                formik.errors.end_time
                                  ? true
                                  : false
                              }
                            />
                            {formik.touched.end_time &&
                              formik.errors.end_time && (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                  }}
                                >
                                  {formik.errors.end_time}
                                </div>
                              )}
                          </div>
                        </>
                      ) : (
                        constants.SCORE_TYPE.ONE_YEAR_ROTATION !==
                          activityTypeJson?.score_type_id && (
                          <>
                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("Activity")} {props.t("Date")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <Input
                                name="activity_date"
                                type="date"
                                className={
                                  formik.touched.activity_date &&
                                  formik.errors.activity_date
                                    ? "form-control bor"
                                    : "form-control text-dark"
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // min={moment().format("D-M-Y H:M")}
                                value={formik.values.activity_date || ""}
                                invalid={
                                  formik.touched.activity_date &&
                                  formik.errors.activity_date
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.activity_date &&
                                formik.errors.activity_date && (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {formik.errors.activity_date}
                                  </div>
                                )}
                            </div>

                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("Start-Time")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <Input
                                name="start_time"
                                type="time"
                                className={
                                  formik.touched.start_time &&
                                  formik.errors.start_time
                                    ? "form-control bor"
                                    : "form-control text-dark"
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // min={moment().format("Y-M-D")}
                                value={formik.values.start_time || ""}
                                invalid={
                                  formik.touched.start_time &&
                                  formik.errors.start_time
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.start_time &&
                                formik.errors.start_time && (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {formik.errors.start_time}
                                  </div>
                                )}
                            </div>

                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("End-Time")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <Input
                                name="end_time"
                                type="time"
                                className={
                                  formik.touched.end_time &&
                                  formik.errors.end_time
                                    ? "form-control bor"
                                    : "form-control text-dark"
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // min={moment().format("Y-M-D")}
                                value={formik.values.end_time || ""}
                                invalid={
                                  formik.touched.end_time &&
                                  formik.errors.end_time
                                    ? true
                                    : false
                                }
                              />
                              {formik.touched.end_time &&
                                formik.errors.end_time && (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {formik.errors.end_time}
                                  </div>
                                )}
                            </div>
                          </>
                        )
                      )}

                      {activitySubTypeDetail?.type === "internship" &&
                        parseInt(roleID) === constants.ROLE.INSTITUTE &&
                        constants.SCORE_TYPE.ONE_YEAR_ROTATION ==
                          activityTypeJson?.score_type_id && (
                          <>
                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("performActivity")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                onChange={(e) => {
                                  formik.setFieldValue("frequency", e?.value);
                                }}
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    borderRadius: "50px",
                                  }),
                                }}
                                name="frequency"
                                className={
                                  formik.touched.frequency &&
                                  formik.errors.frequency
                                    ? " bor"
                                    : " text-dark"
                                }
                                options={constants.FREQUENCY}
                              />
                              {formik.touched.frequency &&
                                formik.errors.frequency && (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {formik.errors.frequency}
                                  </div>
                                )}
                            </div>
                            {formik.values.frequency === 7 && (
                              <div className="form-group mb-4">
                                <label className="form-label">
                                  {props.t("day")}{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "performActivityDay",
                                      e?.value
                                    );
                                  }}
                                  styles={{
                                    control: (styles) => ({
                                      ...styles,
                                      borderRadius: "50px",
                                    }),
                                  }}
                                  name="performActivityDay"
                                  className={
                                    formik.touched.performActivityDay &&
                                    formik.errors.performActivityDay
                                      ? " bor"
                                      : " text-dark"
                                  }
                                  options={constants.DAYS}
                                />
                                {formik.touched.performActivityDay &&
                                  formik.errors.performActivityDay && (
                                    <div
                                      style={{
                                        color: "#f46a6a",
                                        fontSize: "11px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      {formik.errors.performActivityDay}
                                    </div>
                                  )}
                              </div>
                            )}
                          </>
                        )}
                      {activityTypeJson?.can_other_join_activity && (
                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Speaker")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            className={
                              formik.touched.speakers && formik.errors.speakers
                                ? " bor"
                                : " text-dark  "
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderRadius: "50px",
                              }),
                            }}
                            onChange={(e) => {
                              const f = e.map((item, index) => {
                                return item.value;
                              });
                              formik.setFieldValue("speakers", f);
                              setSelectedOptionSpeaker(e);
                            }}
                            defaultValue={selectedOptionSpeaker}
                            onInputChange={onSearchDoctorHandler}
                            onMenuScrollToBottom={onScrollDoctorListHandler}
                            onMenuOpen={onOpenDoctorMenuHandler}
                            onBlur={formik.handleBlur}
                            name="speakers"
                            isMulti={true}
                            options={[
                              ...doctorsData,
                              // isDoctorLoading
                              //   ? {
                              //       value: "loading",
                              //       label: "Loading more...",
                              //       disabled: true,
                              //     }
                              //   : {},
                            ]}
                            placeholder={<div>{props.t("typeToSearch")}</div>}
                          />
                          {formik.touched.speakers &&
                            formik.errors.speakers && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.speakers}
                              </div>
                            )}
                        </div>
                      )}
                    </>
                  )}

                  {((activityTypeJson?.assign_as_task &&
                    constants.ROLE.INSTITUTE == roleID) ||
                    (constants.ROLE.DOCTOR == roleID &&
                      activityTypeJson?.score_type_id ===
                        constants.SCORE_TYPE.GIVING_ADVISE &&
                      activityTypeJson?.approval_rquired === false)) && (
                    <div className="form-group mb-4">
                      <label className="form-label">
                        {props.t("Doctor")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className={
                          formik.touched.doctor_id && formik.errors.doctor_id
                            ? " bor"
                            : " text-dark  "
                        }
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderRadius: "50px",
                          }),
                        }}
                        onChange={(e) => {
                          formik.setFieldValue("doctor_id", e?.value);
                        }}
                        onInputChange={onSearchDoctorHandler}
                        onMenuScrollToBottom={onScrollDoctorListHandler}
                        onMenuOpen={onOpenDoctorMenuHandler}
                        defaultValue={formik.values.doctor_id}
                        onBlur={formik.handleBlur}
                        name="doctor_id"
                        options={[
                          ...doctorsData,
                          // isDoctorLoading
                          //   ? {
                          //       value: "loading",
                          //       label: "Loading more...",
                          //       disabled: true,
                          //     }
                          //   : {},
                        ]}
                        placeholder={<div>{props.t("typeToSearch")}</div>}
                      />
                      {formik.touched.doctor_id && formik.errors.doctor_id && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.doctor_id}
                        </div>
                      )}
                    </div>
                  )}

                  {constants.ROLE.INSTITUTE == roleID &&
                    formik.values.taskTypeForInternship === "quiz" && (
                      <>
                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Doctor")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            className={
                              formik.touched.doctor_id &&
                              formik.errors.doctor_id
                                ? " bor"
                                : " text-dark  "
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderRadius: "50px",
                              }),
                            }}
                            onChange={(e) => {
                              formik.setFieldValue("doctor_id", e?.value);
                            }}
                            onInputChange={onSearchDoctorHandler}
                            onMenuScrollToBottom={onScrollDoctorListHandler}
                            onMenuOpen={onOpenDoctorMenuHandler}
                            defaultValue={formik.values.doctor_id}
                            onBlur={formik.handleBlur}
                            name="doctor_id"
                            options={[
                              ...doctorsData,
                              // isDoctorLoading
                              //   ? {
                              //       value: "loading",
                              //       label: "Loading more...",
                              //       disabled: true,
                              //     }
                              //   : {},
                            ]}
                            placeholder={<div>{props.t("typeToSearch")}</div>}
                          />
                          {formik.touched.doctor_id &&
                            formik.errors.doctor_id && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.doctor_id}
                              </div>
                            )}
                        </div>
                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Intern")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            className={
                              formik.touched.intern_id &&
                              formik.errors.intern_id
                                ? " bor"
                                : " text-dark  "
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderRadius: "50px",
                              }),
                            }}
                            onChange={(e) => {
                              formik.setFieldValue("intern_id", e?.value);
                            }}
                            onInputChange={onSearchDoctorHandler}
                            onMenuScrollToBottom={onScrollDoctorListHandler}
                            onMenuOpen={onOpenDoctorMenuHandler}
                            defaultValue={formik.values.intern_id}
                            onBlur={formik.handleBlur}
                            name="intern_id"
                            options={[
                              ...doctorsData,
                              // isDoctorLoading
                              //   ? {
                              //       value: "loading",
                              //       label: "Loading more...",
                              //       disabled: true,
                              //     }
                              //   : {},
                            ]}
                            placeholder={<div>{props.t("typeToSearch")}</div>}
                          />
                          {formik.touched.intern_id &&
                            formik.errors.intern_id && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.intern_id}
                              </div>
                            )}
                        </div>

                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Examiner")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            className={
                              formik.touched.examiner_id &&
                              formik.errors.examiner_id
                                ? " bor"
                                : " text-dark  "
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderRadius: "50px",
                              }),
                            }}
                            onChange={(e) => {
                              formik.setFieldValue("examiner_id", e?.value);
                            }}
                            onInputChange={onSearchDoctorHandler}
                            onMenuScrollToBottom={onScrollDoctorListHandler}
                            onMenuOpen={onOpenDoctorMenuHandler}
                            defaultValue={formik.values.examiner_id}
                            onBlur={formik.handleBlur}
                            name="examiner_id"
                            options={[
                              ...doctorsData,
                              // isDoctorLoading
                              //   ? {
                              //       value: "loading",
                              //       label: "Loading more...",
                              //       disabled: true,
                              //     }
                              //   : {},
                            ]}
                            placeholder={<div>{props.t("typeToSearch")}</div>}
                          />
                          {formik.touched.examiner_id &&
                            formik.errors.examiner_id && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.examiner_id}
                              </div>
                            )}
                        </div>
                      </>
                    )}

                  {activityTypeJson?.score_type_id ==
                    constants.SCORE_TYPE.DISPLAY_CONTENT_PROVIDING && (
                    <div className="form-group mb-4">
                      <label htmlFor="" className="form-label">
                        {props.t("Activity-Display-Type")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        onChange={(e) => {
                          formik.setFieldValue("display_type_id", e?.value);
                        }}
                        onBlur={formik.handleBlur}
                        name="display_type_id"
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderRadius: "50px",
                          }),
                        }}
                        className={
                          formik.touched.display_type_id &&
                          formik.errors.display_type_id
                            ? "bor"
                            : "text-dark"
                        }
                        options={activityDisplayTypes.map((value) => ({
                          value: `${value.id}`,
                          label: `${value.name_en}`,
                        }))}
                      />
                      {formik.touched.display_type_id &&
                        formik.errors.display_type_id && (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.display_type_id}
                          </div>
                        )}
                    </div>
                  )}

                  {activityTypeJson?.score_type_id ==
                    constants.SCORE_TYPE.CONTENT_TYPE && (
                    <>
                      <div className="form-group mb-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Activity-Content-Type")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            formik.setFieldValue("content_type_id", e?.value);
                          }}
                          onBlur={formik.handleBlur}
                          name="content_type_id"
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          className={
                            formik.touched.content_type_id &&
                            formik.errors.content_type_id
                              ? "bor"
                              : "text-dark"
                          }
                          options={activityContentTypes.map((value) => ({
                            value: `${value.id}`,
                            label: `${value.name_en}`,
                          }))}
                        />
                        {formik.touched.content_type_id &&
                          formik.errors.content_type_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.content_type_id}
                            </div>
                          )}
                      </div>

                      {((formik.values.content_type_id &&
                        formik.values.content_type_id ==
                          constants.CONTENT_TYPE.PUBMED_SCOPUS) ||
                        (formik.values.content_type_id &&
                          formik.values.content_type_id ==
                            constants.CONTENT_TYPE.TCI)) && (
                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Co-Author")}
                          </label>
                          <Select
                            className={
                              formik.touched.co_author_id &&
                              formik.errors.co_author_id
                                ? " bor"
                                : " text-dark  "
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderRadius: "50px",
                              }),
                            }}
                            onChange={(e) => {
                              formik.setFieldValue("co_author_id", e?.value);
                            }}
                            onMenuScrollToBottom={onScrollDoctorListHandler}
                            onInputChange={onSearchDoctorHandler}
                            onMenuOpen={onOpenDoctorMenuHandler}
                            defaultValue={formik.values.co_author_id}
                            onBlur={formik.handleBlur}
                            name="co_author_id"
                            options={[
                              ...doctorsData,
                              // isDoctorLoading
                              //   ? {
                              //       value: "loading",
                              //       label: "Loading more...",
                              //       disabled: true,
                              //     }
                              //   : {},
                            ]}
                            placeholder={<div>{props.t("typeToSearch")}</div>}
                          />
                          {formik.touched.co_author_id &&
                            formik.errors.co_author_id && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.co_author_id}
                              </div>
                            )}
                        </div>
                      )}

                      {formik.values.content_type_id &&
                        formik.values.content_type_id ==
                          constants.CONTENT_TYPE.ACADEMIC_REVIVAL && (
                          <>
                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("Book-Name")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <input
                                type="text"
                                name="book_name"
                                className={
                                  formik.touched.book_name &&
                                  formik.errors.book_name
                                    ? "form-control bor"
                                    : "form-control text-dark"
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.book_name}
                                placeholder={props.t("Book-Name")}
                              />
                              {formik.touched.book_name &&
                                formik.errors.book_name && (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {formik.errors.book_name}
                                  </div>
                                )}
                            </div>
                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("Chapter-Name")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <input
                                type="text"
                                name="chapter_name"
                                className={
                                  formik.touched.chapter_name &&
                                  formik.errors.chapter_name
                                    ? "form-control bor"
                                    : "form-control text-dark"
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.chapter_name}
                                placeholder={props.t("Chapter-Name")}
                              />
                              {formik.touched.chapter_name &&
                                formik.errors.chapter_name && (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {formik.errors.chapter_name}
                                  </div>
                                )}
                            </div>
                          </>
                        )}
                    </>
                  )}
                </div>

                <div className="justify-content-between align-items-center mt-5 mob-mt-2">
                  <div className="float-right">
                    <button type="submit" className="btn btn-join mb-5">
                      {props.t("Save")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {instituteID === null && (
        <div className="d-m-none">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(ActivityFormNew);
