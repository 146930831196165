module.exports = {
  ROLE: {
    SUPERADMIN: 1,
    CME: 2,
    INSTITUTE: 3,
    SUBINSTITUTE: 4,
    DOCTOR: 5,
    INTERN: 6,
    SPEAKER: 7,
    PHARMACTICAL: 8,
    COMPANY: 9,
  },
  STATUS: {
    1: "Active",
    0: "Deactive",
  },
  TYPE: {
    Online: "Online",
    Onsite: "Onsite",
    Hybrid: "Hybrid",
  },
  FEE_TYPE: {
    PAID: "PAID",
    FREE: "FREE",
  },
  TASKTYPE: {
    GIVEADVISE: 1,
    ADDQUIZ: 2,
    SIMPLE: 3,
    EVALUATIONFORMAPPROVAL: 4,
    EVALUATIONFORMREVIEW: 5,
    GIVEQUIZ: 6,
    REVIEWEXAM: 7,
    CREATIONTEACHINGMATERIAL: 8,
    INTERVIEWTEST: 9,
    MEQSHORTASSAY: 10,
    EVALUATIONFORMSUBMITION: 11,
    ROTATIONAL: 12,
    TASKREVIEWANDSUBMITEVALUATION: 13,
    EXAMPROCESS: 14,
    WATCHEXAM: 15,
    CHECKRESULT: 16,
  },
  SCORE_TYPE: {
    CONTENT_PROVIDING: 1,
    GIVING_ADVISE: 2,
    DISPLAY_CONTENT_PROVIDING: 3,
    CONTENT_TYPE: 4,
    FILE_ATTACHMENT: 5,
    ATTENDING_ACTIVITY: 6,
    TASK_COMPLETION: 7,
    FOUR_LEVEL_EVALUATION: 8,
    ONE_YEAR_ROTATION: 9,
    ROTATION_CERTIFICATE: 10,
    EXAM_WITH_QUIZ: 11,
  },
  CONTENT_TYPE: {
    PUBMED_SCOPUS: 1,
    TCI: 2,
    ACADEMIC_REVIVAL: 3,
  },
  APPROVAL_STATUS: {
    Pending: "Pending",
    Approved: "Approved",
    Rejected: "Rejected",
    Submited: "Submited",
    Assigned: "Assigned",
  },
  LEAVE_STATUS: {
    Pending: "Pending",
    Approved: "Approved",
    Rejected: "Rejected",
    Canceled: "Canceled",
  },
  LEAVE_TYPE: {
    FIRSTHALF: "First Half",
    SECONDHALF: "Second Half",
    FULLDAY: "Full Day",
  },
  APPROVALS: {
    Approved: "Approved",
    Rejected: "Rejected",
  },
  REQUEST_DOCTOR_STATUS: {
    APPROVED: "APPROVED",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
  },
  ATTACHMENT_TYPES: {
    SIMPLE_DOCUMENT: 1,
    CERTIFICATE: 2,
    LEARNING_MANUAL: 3,
  },
  FREQUENCY: [
    {
      value: 1,
      label: "Daily",
    },
    {
      value: 7,
      label: "Weekly",
    },
  ],
  DAYS: [
    {
      value: 0,
      label: "Sunday",
    },
    {
      value: 1,
      label: "Monday",
    },
    {
      value: 2,
      label: "Tuesday",
    },
    {
      value: 3,
      label: "Wednesday",
    },
    {
      value: 4,
      label: "Thursday",
    },
    {
      value: 5,
      label: "Friday",
    },
    {
      value: 6,
      label: "Saturday",
    },
  ],
  EDUCATION_TYPE: {
    CME: "Accredited Course",
    CPD: "Webinars",
    // INSTITUE: "INSTITUE",
  },
  CONTENT_ACTIVITY_TYPE: {
    SHORT: "SHORT",
    LONG: "LONG"
  },
};
