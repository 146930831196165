import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const CompanyAPI = {
  getCompanies: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_ALL_COMPANIES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getCompanyNameAPi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_COMPANY_NAME}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getCompanyDetail: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_COMPANY_DETAIL}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
};
