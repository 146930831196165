import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const ProductAPI = {
  getProductsByCompany: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_ALL_PRODUCTS_BY_COMPANY}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getProductDetail: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_PRODUCT_DETAIL}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
};
