import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";
export const BookmarkApi = {
  getActivitiesBookmark: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_BOOKMARKS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getLibraryBookmark: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_LIBRARY_BOOKMARKS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getOffersBookmark: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_OFFER_BOOKMARKS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  postActivityBookmark: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ADD_ACTIVITY_BOOKMARK}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  deleteActivityBookmark: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.REMOVE_ACTIVITY_BOOKMARK}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  postLibraryBookmark: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ADD_LIBRARY_BOOKMARK}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  deleteLibraryBookmark: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.REMOVE_LIBRARY_BOOKMARK}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  postOffersBookmark: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ADD_OFFERS_BOOKMARK}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  deleteOffersBookmark: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.REMOVE_OFFERS_BOOKMARK}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
};
