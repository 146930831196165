import { ProfileAPI } from "apis/ProfileAPI";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import config from "../../config";

const NotificationCard = (props) => {
  const navigate = useNavigate();
  const onViewSubmittedDetailsHandler = (id) => {
    window.$(`#activityDetailsmodal_${id}`).modal("show");
  };

  const notification = props.notification;

  const onChangeNotificationStatus = (id) => {
    ProfileAPI.updateNotificationStatus(id)
      .then((res) => {
        if (res.data.success) {
          if (notification?.link_path) {
            navigate(`/${notification?.link_path}`);
          } else {
            window.location.reload();
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <li
      className="notificationCard"
      onClick={() => onChangeNotificationStatus(notification?.id)}
    >
      <div className="d-flex p-relative">
        <div>
          <img
            src={
              notification?.FromUser?.profile_image
                ? notification?.FromUser?.profile_image
                : config.BASE_URL + "/assets/images/no-preview.png"
            }
            alt=""
            className="rounded"
            width="35"
            height="35"
          />
        </div>
        <div className="ml-1 mx-2">
          <h6 className="mb-0">
            {/* <strong className="text-primary">{notification?.title}</strong> <br /> */}
            <strong className="text-primary">
              {notification?.FromUser?.first_name} {notification?.FromUser?.last_name ? notification?.FromUser?.last_name : ""}
            </strong>{" "}
            <br />
            {notification?.message}
          </h6>
          <span className="time">
            <ReactTimeAgo
              date={notification?.created_at}
              locale="en-US"
              timeStyle="round"
            />
          </span>
        </div>
        {!notification?.status && <div className="status"></div>}
      </div>
    </li>
  );
};

export default withTranslation()(NotificationCard);
