import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/Footer';
import { withTranslation } from "react-i18next";
import Activities from '../Bookmark/Activities';
import Library from '../Bookmark/Library';
import Offers from '../Bookmark/Offers';
// import Explore from '../Bookmark/Explore';
import ProfileSidebar from "../../components/Layout/ProfileSidebar";

function Bookmark(props) {
    const [openpage, setOpenpage] = useState('1');

    function handleClick(pageno) {
        if (pageno === '1') {
            setOpenpage('1');
        } else if (pageno === '2') {
            setOpenpage('2');
        } else if (pageno === '3') {
            setOpenpage('3');
        }
        //  else if (pageno === '4') {
        //     setOpenpage('4');
        // }

    }

    return (
        <>
            <Navbar />
            {/* <!-- Mobile View Top Start --> */}
            <div className="mobile-gray-top d-none">
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between mob-mt-39">
                            <div>
                                <a href="/profile">
                                    <img
                                        src="assets/images/768/arrow-left.png"
                                    />
                                </a>
                            </div>
                            <div>
                                <h4 className="mb-0">{props.t("Bookmark")}</h4>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Mobile View Top End --> */}

            <section className="mt-5 top-curve2 mob-brd-radius-0">
                <div className="container">
                    <div className="row p-8">
                        <div className="col-md-12">
                            <h1 className="mt-5  mb-3 main-title d-m-none">{props.t("Profile")}</h1>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Bookmark List Start --> */}
            <section className="dashboard">
                <div className="container">
                    <div className="row p-8">
                        <ProfileSidebar />
                        <div className="col-md-10">
                            <div className="dashboard-inner ha-tab">
                                {/* <div className="top-head">
                                    <a href="">
                                        <img
                                            src="assets/images/768/arrow-left.png"
                                            className="mb-3 d-m-none"
                                            alt=""
                                            width=""
                                            height=""
                                        />
                                    </a>
                                </div> */}
                                <div className="mob-pr-0 specialiry mob-mb-0 mob-pl-8 list-style-none">
                                    <ul>
                                        <li>
                                            <Link to="" className={(openpage === "1") ? "active" : ""} onClick={() => handleClick('1')}>
                                                {props.t("Activities")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="" className={(openpage === "3") ? "active" : ""} onClick={() => handleClick('3')}>
                                                {props.t("Offers")}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                {(openpage) ? 
                                    ((openpage === "1") 
                                        ? 
                                            <Activities /> 
                                        : (openpage === "2") 
                                        
                                        ? 
                                            <Library /> 
                                        : (openpage === "3") 
                                        ? 
                                            <Offers /> 
                                        : 
                                            ''
                                    )   :   ''
                                }
                                {/* (openpage === "4") ? <Explore /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default withTranslation()(Bookmark)