import axios from "axios";
import { getToken } from "../Token";

export const setAuthorizationToken = async (token = null) => {
  if (token !== null && token !== undefined) {
    axios.interceptors.request.use(
      (config) => {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
};
