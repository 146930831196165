import { ActionTypes } from "../constants/home.actiontypes";

const initialState = {
    profile_image: "assets/images/profile.png",
};

export const userReducers = (state = initialState, action) => {
    switch (action.type) {
        // case ActionTypes.GET_USER_PROFILE_PIC:
        //     return {
        //         ...state,
        //         profile_image: action.payload,
        //     };
        case ActionTypes.ADD_USER_PROFILE_PIC:
            return {
                ...state,
                profile_image: action.payload,
            };
        default:
            return state;
    }
};