import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const LeaveAPI = {
  createLeave: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CREATE_LEAVE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },

  getLeaveDetails: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_LEAVE_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },

  updateLeave: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.UPDATE_LEAVE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },

  fetchReasonTypes : async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_REASON_TYPES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },

  cancelLeave: async (id) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CANCEL_LEAVE}/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(axiosConfig);
  },
};
