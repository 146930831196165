import React from "react";
import { withTranslation } from "react-i18next";
import { Input } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ProfileAPI } from "apis/ProfileAPI";
import FileCard from "./FileCard";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import constants from "constants";
import Select from "react-select";

const UserTaskApproved = (props) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      //   description: props?.userTask?.submited_details ? props?.userTask?.submited_details : "",
    },
    validationSchema: Yup.object().shape({
      approval_status: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("approval_status", values.approval_status);
      data.append("description", values.description);
      data.append("userTaskID", props?.userTask?.id);
      data.append("activityId", props?.userTask?.activity_id);

      ProfileAPI.approvedEvaluationform(data)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              text: "Thanks for feedback",
              icon: "success",
              imageAlt: "success image",
            }).then((result) => {
              navigate("/mycalendar");
            });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
          } else {
            err.response.data.error.errors?.map((erritem, errindex) => {});
          }
        });
    },
  });

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div
          className="dashboard-inner application-form"
          style={{ minHeight: "auto" }}
        >
          <div className="row">
            <div className="col-md-6">
              <h2 className="mb-3">{props.t("Evaluation_Approval")}</h2>
            </div>
          </div>
          <div className="form-group mb-4">
            <label className="form-label">
              {props.t("Approval-Status")}{" "}
              <span className="text-danger">*</span>
            </label>
            <Select
              onChange={(e) => {
                formik.setFieldValue("approval_status", e?.value);
              }}
              onBlur={formik.handleBlur}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderRadius: "50px",
                }),
              }}
              name="approval_status"
              className={
                formik.touched.approval_status && formik.errors.approval_status
                  ? " bor"
                  : " text-dark"
              }
              options={Object.keys(constants.APPROVALS).map((key) => ({
                value: `${key}`,
                label: `${key}`,
              }))}
            />
            {formik.touched.approval_status &&
              formik.errors.approval_status && (
                <div
                  style={{
                    color: "#f46a6a",
                    fontSize: "11px",
                    marginTop: "3px",
                  }}
                >
                  {formik.errors.approval_status}
                </div>
              )}
          </div>
          <div className="form-group mb-4">
            <label className="form-label">
              {" "}
              {props.t("Description")} <span className="text-danger">*</span>{" "}
            </label>
            <textarea
              type="text"
              rows={5}
              className="form-control  w-100"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              style={{ height: "unset" }}
              // readOnly={(props?.userTask?.status === "Approved" || props?.userTask?.status === "Submited")  ? "readOnly" : ""}
            />
            {formik.touched.description && formik.errors.description && (
              <div
                style={{ color: "#f46a6a", fontSize: "11px", marginTop: "3px" }}
              >
                {formik.errors.description}
              </div>
            )}
          </div>
        </div>

        <div
          className="justify-content-between align-items-center mt-5 mob-mt-2"
          style={{ marginBottom: "120px" }}
        >
          <div className="float-right">
            <button type="submit" className="btn btn-join">
              {props.t("Submit")}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default withTranslation()(UserTaskApproved);
