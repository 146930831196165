import { ProfileAPI } from "apis/ProfileAPI";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Header from "./Header";
import PdfArea from "./PdfArea";

const ExportPdf = (props) => {
  const [scoreFiveYearAndCurrentYear, setScoreFiveYearAndCurrentYear] =
    useState([]);
  const [summaryData, setSummaryData] = useState();
  const [yearlyScore, setYearlyScore] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    getScoreData();
  }, []);

  useEffect(() => {
    if (isUpdated) {
      generatePdf();
    }
  }, [isUpdated]);

  const generatePdf = () => {
    const input = document.getElementById("export-cme-score-report-pdf");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, 190);
      pdf.save(`cme-score-report.pdf`);
      // window.close();
    });
  };

  const getScoreData = () => {
    ProfileAPI.getScoreData({ type: "pdf" })
      .then((res) => {
        if (res.data.success) {
          setScoreFiveYearAndCurrentYear(res.data.data.fiveYearActivity);
          setSummaryData(res.data.data.summarydata);
          setYearlyScore(res.data.data.yearlyscore);
          setIsUpdated(true);
        } else {
          setSummaryData([]);
          setYearlyScore([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div id="export-cme-score-report-pdf">
      <Header />
      <PdfArea
        yearlyScore={yearlyScore}
        summaryData={summaryData}
        scoregroupwise={scoreFiveYearAndCurrentYear}
      />
    </div>
  );
};
export default withTranslation()(ExportPdf);
