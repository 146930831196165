import React from 'react'
import config from "../../../config";
import { NavLink, Link,useParams } from 'react-router-dom';


function MobileInnerPageHeader(props) {   
    return (
        <>
            <div className="mobile-gray-top d-none">
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between mob-mt-39">
                            <div>
                                <a href={config.BASE_URL +''+ props.back_page_url}>
                                    <img
                                    src={config.BASE_URL + '/assets/images/768/arrow-left.png'}
                                    className=""
                                    />
                                </a>
                            </div>
                            <div>
                                <h4 className="mb-0">{props.title}</h4>
                            </div>
                            <div>
                                <NavLink to="/education/institute-filter">
                                    <img
                                        src={config.BASE_URL + '/assets/images/768/adjustment.png'}
                                        className=""
                                    />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileInnerPageHeader