import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MobileLanguageDropdown from "../../Layout/Mobile/MobileLanguageDropdown";
import { getRoleID,isRestricted,isFda } from "../../../Token";
import { ProfileAPI } from "apis/ProfileAPI";

function MobileHeader(props) {
    const userReduxState = useSelector(state => state.profile_image);

    const [notifications, setNotifications] = useState([]);
    const getNotifications = () => {
        let payload = { page: 1, limit: 3 };
        ProfileAPI.getNotifications(payload)
        .then((res) => {
            if (res.data.success) {
            setNotifications(res.data);
            } else {
            setNotifications([]);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        getNotifications();
    }, []);


    return (
        <>
            <div className="mobile-top d-none" >
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between list-style-none">
                            <div>
                                <h4>{props.title}</h4>
                            </div>
                            <div>
                                <ul className="d-flex align-items-center justify-content-between list-style-none">
                                    <li>
                                        <MobileLanguageDropdown />
                                    </li>
                                    <li className={`restricted_${isRestricted()}`}>
                                        <NavLink to="/notifications">
                                        {notifications?.notifications?.count > 0 && (
                                            <span className="notificationCaunt">{notifications?.notifications?.count}</span>
                                        )}
                                            <img src="/assets/images/768/bell.png" alt="" />
                                        </NavLink>
                                    </li>
                                    <li>
                                    <NavLink to={isRestricted() ? "/logout" : "/profile"}   >
                                        <img
                                            src={userReduxState.profile_image ? userReduxState.profile_image : require("assets/images/profile.png")}
                                            className="rounded"
                                            alt=""
                                            width={40}
                                            height={40}
                                        />
                                    </NavLink> 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default MobileHeader