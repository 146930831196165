import { withTranslation } from "react-i18next";
import * as url from "../../helpers/common_helper";
import { Link } from "react-router-dom";

const CheckResultCard = (props) => {
  return (
    <>
      <li
        style={{ float: "unset" }}
        key={`checkresulttask_${props?.result?.id}`}
      >
        <Link
          to={`/checkresultDetail`}
          state={{
            activityId: props?.result.activity_id,
            userId: props?.result.user_id,
            resultFor: props?.resultFor,
            type: props.result.type,
            userTaskId: props?.result.id
          }}
        >
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="block2 list-style-none" style={{ width: "100%" }}>
              <span className="d-block check-result-textwrap">
                <span>
                  {props.result.Activity.title_en} -{" "}
                  {props.result.type.charAt(0).toUpperCase() +
                    props.result.type.slice(1).toLowerCase()}
                </span>
              </span>
              <ul className="ha-list" style={{ display: "block" }}>
                {props.result.submitted_task_id !== null ? (
                  <li>{props?.result?.internshipTaskName}</li>
                ) : (
                  ""
                )}
                <li>
                  <img
                    className="img-fluid"
                    src="assets/images/clock.png"
                    alt=""
                    width="15"
                    height="15"
                  />
                  {url.dateFormat(props?.result?.created_at)}
                </li>
                <li style={{ display: "block" }}>
                  <img
                    className="img-fluid "
                    src="assets/images/user.png"
                    alt=""
                    width="12"
                    height="12"
                  />
                  {props.result.userName}
                </li>
              </ul>
            </div>
          </div>
        </Link>
      </li>
    </>
  );
};

export default withTranslation()(CheckResultCard);
