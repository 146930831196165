import _ from "lodash";
import QuizQuestionList from "pages/ActivityJson/QuizQuestionList";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import "toastr/build/toastr.min.css";

const AddEditQuizModal = (props) => {
  const [activityQuestions, setActivityQuestions] = useState([]);
  const [formValues, setFormValues] = useState([
    { option_en: "", option_th: "", is_answer: false },
    { option_en: "", option_th: "", is_answer: false },
  ]);
  const [optionData, setOptionData] = useState([]);
  const [validateError, setValidateError] = React.useState({
    question_en: "",
    question_th: "",
    optionsErr: "",
  });
  const [questionEn, setQuestionEn] = useState("");
  const [questionTh, setQuestionTh] = useState("");
  const [isAnsweredRadio, setIsAnsweredRadio] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const [isAddedAnyQuestion, setIsAddedAnyQuestion] = useState(false);
  const [currentQueIndex, setCurrentQueIndex] = useState();

  useEffect(() => {
    if (props.questions.length > 0) {
      setActivityQuestions(props.questions);
    }
  }, []);

  useEffect(() => {
    if (props?.selectedQuizType) {
      setFormValues([
        { option_en: "", option_th: "", is_answer: false },
        { option_en: "", option_th: "", is_answer: false },
      ]);
      setValidateError({
        question_en: "",
        question_th: "",
        optionsErr: "",
      });
    }
  }, [props?.selectedQuizType]);

  const handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      { option_en: "", option_th: "", is_answer: "" },
    ]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const onRemoveOptionHandleChange = (index) => {
    const optionDataCopy = _.cloneDeep(optionData);
    optionDataCopy.splice(index, 1);
    setOptionData(optionDataCopy);
  };

  const onHandleChange = (value, type, index) => {
    const optionDataCopy = _.cloneDeep(optionData);
    if (type === "optionEn") {
      const editOptionData = optionDataCopy[index];
      if (!editOptionData) {
        const data = {
          option_en: value,
          option_th: "",
          is_answer: false,
        };
        optionDataCopy.push(data);
      } else {
        editOptionData.option_en = value;
        optionDataCopy.splice(index, 1, editOptionData);
      }
    } else if (type === "optionTh") {
      const editOptionData = optionDataCopy[index];
      if (!editOptionData) {
        const data = {
          option_en: "",
          option_th: value,
          is_answer: false,
        };
        optionDataCopy.push(data);
      } else {
        editOptionData.option_th = value;
        optionDataCopy.splice(index, 1, editOptionData);
      }
    } else if (type === "isAnswer") {
      const editOptionData = optionDataCopy[index];
      if (!editOptionData) {
        const data = {
          option_en: "",
          option_th: "",
          is_answer: value ? true : false,
        };
        optionDataCopy.push(data);
      } else {
        optionDataCopy.forEach((item) => {
          item.is_answer = false;
        });
        editOptionData.is_answer = value ? true : false;
        optionDataCopy.splice(index, 1, editOptionData);
      }
      setIsAnsweredRadio(true);
    }
    if (questionId != "") {
      setFormValues(optionDataCopy);
    }
    setOptionData(optionDataCopy);
  };

  const onSubmitQustionAndAnswer = async () => {
    let errors = await checkFormValidation(true);
    let errorsSize = Object.keys(errors).length;
    if (errorsSize != 0) {
      return false;
    }
    let questionArr = [];
    questionArr.push({
      question_en: questionEn,
      question_th: questionTh,
      QuizQuesOption: optionData,
    });
    setActivityQuestions([...activityQuestions, ...questionArr]);
    props.setQuizQuestions([...activityQuestions, ...questionArr]);
    props.setAddedQuizCout([...activityQuestions, ...questionArr].length);
    setIsSuccess(true);
    setSuccessMsg("Question Added Successfully!");
    setIsAddedAnyQuestion(true);
    setQuestionEn("");
    setQuestionTh("");
    setIsAnsweredRadio(false);
    window.$("#is_answer").attr("checked", false);
    window.$("#option_en").val("");
    window.$("#option_th").val("");
    setOptionData([]);
    setFormValues([{ option_en: "", option_th: "", is_answer: false },{ option_en: "", option_th: "", is_answer: false }]);
    setValidateError({
      question_en: "",
      question_th: "",
      optionsErr: "",
    });
  };

  const checkFormValidation = async (check) => {
    if (check) {
      setIsSuccess(false);
      let ValidateError = {};
      if (questionEn === "") {
        ValidateError.question_en = props.t("questionEnIsRequired");
      }

      if (questionTh === "") {
        ValidateError.question_th = props.t("questionThIsRequired");
      }

      if (optionData.length <= 1) {
        ValidateError.optionsErr = props.t("quizOptionErrorMsg");
      }

      if (optionData.length >= 2 && !isAnsweredRadio) {
        ValidateError.optionsErr = props.t("quizOptionAnswerErrorMsg");
      }

      // if(!isUpdate){
      //   if(activityQuestions.length >= props.maxQuestion || props.questions.length >= props.maxQuestion){
      //     ValidateError.optionsErr = props.t("quizMaxQuestionErrorMsg");
      //   }
      // }

      setValidateError(ValidateError);
      return ValidateError;
    } else {
      return {};
    }
  };

  const inputChange = (e) => {
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      let value = e.target.value;
      switch (e.target.name) {
        case "question_en":
          setQuestionEn(value);
          break;
        case "question_th":
          setQuestionTh(value);
          break;
        default:
        // code block
      }
    }
  };

  const onEditQuizQuestionHanlder = async (question, index) => {
    window.$("#addEditQuizModal").scrollTop(0);
    setCurrentQueIndex(index);
    setQuestionId(question.id);
    setQuestionEn(question.question_en);
    setQuestionTh(question.question_th);
    setIsAnsweredRadio(true);
    setFormValues(question.QuizQuesOption);
    setOptionData(question.QuizQuesOption);
    setIsUpdate(true);
    return true;
  };

  const onDeletetQuizQuestionHanlder = async (questionId, index) => {
    const existQuestionData = _.cloneDeep(activityQuestions);
    existQuestionData.splice(index, 1);
    setActivityQuestions(existQuestionData);
    props.setQuizQuestions(existQuestionData);
    props.setAddedQuizCout(existQuestionData.length);
  };

  const onUpdateQustionAndAnswer = async () => {
    let errors = await checkFormValidation(true);
    let errorsSize = Object.keys(errors).length;
    if (errorsSize != 0) {
      return false;
    }
    const existQuestionData = _.cloneDeep(activityQuestions);
    const updateQuestionData = existQuestionData[currentQueIndex];
    updateQuestionData.question_en = questionEn;
    updateQuestionData.question_th = questionTh;
    updateQuestionData.QuizQuesOption = optionData;
    existQuestionData.splice(currentQueIndex, 1, updateQuestionData);
    setActivityQuestions(existQuestionData);
    props.setQuizQuestions(existQuestionData);
    setIsSuccess(true);
    setSuccessMsg("Question Updated Successfully!");
    setIsAddedAnyQuestion(true);
    setQuestionEn("");
    setQuestionTh("");
    setIsAnsweredRadio(false);
    window.$("#is_answer").attr("checked", false);
    window.$("#option_en").val("");
    window.$("#option_th").val("");
    setOptionData([]);
    setFormValues([{ option_en: "", option_th: "", is_answer: false },{ option_en: "", option_th: "", is_answer: false }]);
    setIsUpdate(false);
  };

  return (
    <>
      <div className="modal fade in" id="addEditQuizModal">
        <div className="modal-dialog scrollDialog modal-lg">
          <div className="modal-content mb-5">
            <div className="modal-header">
              <h4 className="modal-title">
                {props.questions.length > 0
                  ? `${props.t("Edit")} ${props.t("Quiz")}`
                  : `${props.t("Add")} ${props.t("Quiz")}`}
              </h4>
            </div>
            <div className="modal-body scrollAndFixedHeight">
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="title mb-4">
                      {props.t("Add")} {props.t("Question")}
                    </h3>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      onClick={(e) =>
                        window.$("#addEditQuizModal").modal("hide")
                      }
                      className="btn btn-success float-end"
                      disabled={!isAddedAnyQuestion ? true : false}
                    >
                      {props.t("Submit")} {props.t("Quiz")}
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <label htmlFor="question_en" className="form-label">
                      {props.t("question_en")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={props.t("question_en")}
                      name="question_en"
                      onChange={inputChange}
                      value={questionEn || ""}
                    />
                    {validateError.question_en ? (
                      <p className="text-danger text-small">
                        {validateError.question_en}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="question_th" className="form-label">
                      {props.t("question_th")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={props.t("question_th")}
                      name="question_th"
                      onChange={inputChange}
                      value={questionTh || ""}
                    />
                    {validateError.question_th ? (
                      <p className="text-danger text-small">
                        {validateError.question_th}
                      </p>
                    ) : null}
                  </div>
                </div>
                {formValues.map((element, index) => (
                  <div className="row" key={`addform${index}`}>
                    <div className="col-sm-4 mb-2">
                      <label htmlFor="option_en" className="form-label">
                        {index + 1 + ". " + props.t("option_en")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        key={`{optionEng}` + index}
                        name="option_en"
                        type="text"
                        className="form-control addQuestionOptinClass"
                        id="option_en"
                        placeholder={props.t("option_en")}
                        onChange={(e) => {
                          handleChange(index, e);
                          onHandleChange(e.target.value, "optionEn", index);
                        }}
                        value={element.option_en || ""}
                      />
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label htmlFor="option_th" className="form-label">
                        {index + 1 + ". " + props.t("option_th")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        key={`{optionThai}` + index}
                        name="option_th"
                        type="text"
                        className="form-control addQuestionOptinClass"
                        id="option_th"
                        placeholder={props.t("option_th")}
                        onChange={(e) => {
                          handleChange(index, e);
                          onHandleChange(e.target.value, "optionTh", index);
                        }}
                        value={element.option_th || ""}
                      />
                    </div>
                    <div className="col-sm-2 mb-2">
                      <label htmlFor="is_answer" className="form-label">
                        {props.t("is_answer")} ?
                      </label>
                      <div className="">
                        <input
                          key={`{isAnswer}` + index}
                          name="is_answer"
                          type="radio"
                          className="addQuestionOptinClass"
                          id="is_answer"
                          onMouseUp={(e) => {
                            handleChange(index, e);
                            onHandleChange(e.target.value, "isAnswer", index);
                          }}
                          checked={element.is_answer ? true : false}
                        />
                      </div>
                    </div>
                    {props?.selectedQuizType !== "OSCE" && (
                      <div className="col-sm-2 mb-2">
                        {index ? (
                          <div>
                            <a
                              href="javascript:void();"
                              key={`{remove}` + index}
                              onClick={(e) => {
                                removeFormFields(index);
                                onRemoveOptionHandleChange(index);
                              }}
                              title="Delete"
                            >
                              <img
                                src={require("assets/images/delete-icon.png")}
                                width={15}
                                height={15}
                              />
                            </a>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                ))}
                {validateError.optionsErr ? (
                  <p className="text-danger text-small">
                    {validateError.optionsErr}
                  </p>
                ) : null}
                {props?.selectedQuizType !== "OSCE" && (
                  <div className="row">
                    <div className="col-sm-12 mb-2">
                      <button
                        color="success"
                        className="btn btn-success"
                        type="button"
                        onClick={(e) => addFormFields()}
                      >
                        {props.t("Add_More")}
                      </button>
                    </div>
                  </div>
                )}
                <div className="row">
                  {isSuccess && (
                    <div className="col-sm-12 mb-2">
                      <div className="alert alert-success">
                        <strong>{successMsg}</strong>
                      </div>
                    </div>
                  )}
                  <div className="col-sm-12 mb-2">
                    <div className="flex-wrap gap-2 mt-3">
                      {isUpdate ? (
                        <button
                          type="button"
                          onClick={(e) => onUpdateQustionAndAnswer()}
                          className="btn btn-join2"
                        >
                          {props.t("Update")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={(e) => onSubmitQustionAndAnswer()}
                          className="btn btn-join2"
                        >
                          {props.t("Add")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {activityQuestions.length > 0 && (
                <>
                  <hr />
                  <div>
                    <h3 className="title mb-4">
                      {props.t("List-Question-Answers")}
                    </h3>
                  </div>
                </>
              )}
              <QuizQuestionList
                from="add"
                onEditQuizQuestionHanlder={onEditQuizQuestionHanlder}
                onDeletetQuizQuestionHanlder={onDeletetQuizQuestionHanlder}
                questions={activityQuestions}
                isInstituteFiledEnable={props.isInstituteFiledEnable}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(AddEditQuizModal);
