import moment from "moment";
import { React, useState } from "react";
import { Link } from "react-router-dom";
import * as url from "../../helpers/common_helper";
import { withTranslation } from "react-i18next";
import config from '../../config'

const Certificate = (props) => {
    let certificate = props.certificate;
    let getFileExtension = certificate?.file_url.split(".").pop();
    const imgExtensions = ["jpg", "JPG", "jpeg", "png", "gif", "bmp"];
    return (
        <a className="col-md-6" target="_blank" href={certificate?.file_url}>
            <div className="block mb-3">
                {imgExtensions.includes(getFileExtension) ? (
                    <img
                        src={certificate?.file_url}
                        className="c-img"
                        alt=""
                    />
                ) :
                    <img
                        src={config.BASE_URL + '/assets/images/default-pdf.png'}
                        className="c-img"
                        alt=""
                    />
                }
                
                <div className="d-flex justify-content-between align-items-center list-content">
                    <div>
                        <h5>{certificate?.title_en}</h5>
                        <div>
                            <img
                                className="img-fluid"
                                src="assets/images/clock.png"
                                alt=""
                                width={12}
                                height={12}
                            />
                            <span className="p-l-3">{url.dateTimeFormat(certificate.created_at)}</span>
                        </div>
                    </div>
                    {/* <div>
                        <a href="" className="btn bg-secondary btn-request">Request</a>
                    </div> */}
                </div>
            </div>
        </a>
    );
};

export default withTranslation()(Certificate);
