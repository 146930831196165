import React, { useEffect, useState } from "react";
import config, { BASE_URL } from "../../config";
import { withTranslation } from "react-i18next";
import { getRoleID, isRestricted, isFda } from "../../Token";


const RightAdvertiseBanner = ({ banner, className }) => {
  return (
    <div className={className}>
      {banner.map((item, i) => {
        return (
          <div className={`img-block mb-3 fda_${isFda()}`}  key={"banner_" + i + item.id}>
            <a
              href={item.banner_link.includes('?') ? `${item.banner_link}&&type=banner` : `${item.banner_link}?type=banner`}
              title={item.title_en + " - " + item.banner_link}
              target="_blank"
              onClick={() => {
                window.parent.postMessage(JSON.stringify({type:"banner",url:item.banner_link}));
              }}
            >
              <img src={item.image_url} className="img-fluid" />
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default RightAdvertiseBanner;
