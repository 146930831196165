import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const ProfileAPI = {
  getUserInterestsAPI: async () => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_USER_INTERESTS}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(axiosConfig);
  },
  getScoreData: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_USER_SCORES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getScoreDetails: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_USER_SCORE_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getScoreActivityHistory: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_USER_SCORE_ACTIVITY_HISTORY}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getCertificates: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_USER_CERTIFICATES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  removeUserInterestAPI: async (id) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.REMOVE_USER_INTEREST}/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(axiosConfig);
  },
  changeUpdateProfilePictureAPI: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CHANGE_USER_PROFILE_PIC}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getMyActivitiesAPI: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MY_ACTIVITIES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getMyCalenderAPi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MY_MYCALENDER}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getMyCalenderUserTaskListAPi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MY_MYCALENDER_USERTASKLIST}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getUserTaskDetailApi: async (id) => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_MY_MYCALENDER_USERTASKDETAIL}/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(axiosConfig);
  },
  giveAdviseApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_TASKADVISE}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getNotifications: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_USER_NOTIFICATIONS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getAllNotifications: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_USER_ALL_NOTIFICATIONS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  updateNotificationStatus: async (id) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.UPDATE_NOTIFICATION_STATUS}/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(axiosConfig);
  },
  approvedEvaluationform: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_APPROVED_EVALUATION_FORM}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getInternshipTaskEvaluatorsApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MY_INTERNSHIPTASKEVALUATORS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  submitInternshipTaskToEvaluatorApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MY_SUBMITINTERNSHIPTASK}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  submitQuizQuestions: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.SUBMIT_QUIZ_QUESTIONS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  fetchInternshipTasks: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_INTERNSHIP_TASKS}`,
      headers: {
        "Content-Type": "application/json",
      },
      // data: data
    };

    return await axios(axiosConfig);
  },
  markTaskCompletedApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_MARKTASKCOMPLETED}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  submitEvaluatorTaskToIntern: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MY_SUBMITEVALUATORTASK}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  checkInternshipAndGetEvaluatorsApi: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MY_CHECKINTERNSHIPANDGETEVALUATORS}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(axiosConfig);
  },
  submitTaskEvaluationToEvaluators: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MY_SUBMITTASKEVALUATIONS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  checkInCheckOut: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CHECK_IN_CHECK_OUT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getMyLeaves: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MY_LEAVES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getMsfAndHodResultApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MSFANDHODRESULTS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getMsfAndTeacherResultApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_MSFANDTEACHERRESULTS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getUserEvaluationApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_USEREVALUATIONDETAIL}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  submitEvaluatorResultApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.SUBMITEVALUATORRESULT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  sendEvaluationTaskToHodApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.SENDEVALUATIONTASKTOHOD}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
};
