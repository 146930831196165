import React, { useEffect, useState } from "react";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { CommunityApi } from "../../apis/CommunityApi";
import config from "../../config";
import moment from "moment";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import MobileHeader from "../../components/Layout/Mobile/MobileHeader";
import { withTranslation } from "react-i18next";
import RightAdvertiseBanner from "../Components/RightAdvertiseBanner";
import { HomeApi } from "../../apis/HomeApi";
import constants from "../../constants";
import { getRoleID } from "../../Token";
import InfiniteScroll from "react-infinite-scroll-component";
import Linkify from 'react-linkify';
import i18n from "../../i18n";


function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Community(props) {
  const params = useParams();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product = urlParams.get("search");

  const query = useQuery();
  console.log(query);
  const type = query.get("type") || "D-CONNECT";

  const [communitypostData, setCommunitypostData] = useState({
    page: 1,
    limit: config.LIMIT,
    type,
    search: "",
    filterBy: {
      company_id: "All",
    },
    category_id: "All",
    institute_user_id: "All",
  });

  const [communitypost, setCommunitypost] = useState([]);
  const [communityTopicData, setCommunityData] = useState({
    parent_type: "community",
    type,
  });
  const [communityTopic, setCommunityTopic] = useState([]);

  const fetchScrollPostData = {};
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
    search: urlParams.get("search") == null ? "" : urlParams.get("search"),
    type: type,
    filterBy: { company_id: "All" },
    institute_user_id:
      urlParams.get("institute_user_id") == null
        ? "All"
        : urlParams.get("institute_user_id"),
    category_id:
      urlParams.get("category_id") == null
        ? "All"
        : urlParams.get("category_id"),
  });
  const [categorytActive, setCategoryActive] = useState("All");
  const [searchActive, setSearchActive] = useState("");
  const [instituteActive, setInstituteActive] = useState("All");

  const getCommunityPosts = (data, is_push_data) => {
    CommunityApi.getCommunityPosts(data)
      .then((res) => {
        // console.log(res.data, "is me hai");
        if (res.data.success) {
          if (is_push_data) {
            setCommunitypost([...communitypost, ...res.data.data]);
          } else {
            setCommunitypost(res.data.data);
          }
        } else {
          setCommunitypost([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCommunityTopic = (data) => {
    CommunityApi.getCommunityTopic(data)
      .then((res) => {
        // console.log(res.data,"abc")
        if (res.data.success) {
          setCommunityTopic(res.data.data);
        } else {
          setCommunityTopic([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMoreData = () => {
    fetchScrollPostData({
      page: activeData.page + 1,
      limit: config.LIMIT,
      search: activeData.search,
      type: type,
      filterBy: { company_id: activeData.filterBy.company_id },
      institute_user_id: activeData.institute_user_id,
      category_id: activeData.category_id,
    });
  };

  window.onscroll = async function (ev) {
    var pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    if (window.innerHeight + window.scrollY >= pageHeight) {
      const data = {
        page: activeData.page + 1,
        limit: config.LIMIT,
        search: searchActive,
        type: type,
        filterBy: { company_id: activeData.filterBy.company_id },
        institute_user_id: instituteActive,
        category_id: categorytActive,
      };
      setActiveData(data);
      getCommunityPosts(data, true);
    }
  };

  useEffect(() => {
    setCategoryActive(activeData.category_id);
    setSearchActive(activeData.search);
    setInstituteActive(activeData.institute_user_id);
  }, []);

  const [banners, setBanners] = useState([]);
  const [topBanner, setTopBanner] = useState({
    page_name: "Community-Blog",
    type: "Banner",
    position: "Top",
  });

  const getBanners = (data) => {
    HomeApi.getBanners(data)
      .then((res) => {
        if (res.data.success) {
          setBanners(res.data.data.banners);
        } else {
          setBanners([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBanners(topBanner);
    getCommunityPosts(communitypostData);
  }, [communitypostData.category_id, communitypostData.search]);

  useEffect(() => {
    getCommunityTopic(communityTopicData);
  }, []);

  function handleChangeSearch(event) {
    console.log(handleChangeSearch, "xyz");
    // alert (1)

    if (event.key === "Enter") {
      event.preventDefault();
      // console.log(event,"xyz");

      setCommunitypostData({
        ...communitypostData,
        search: event.target.value,
      });
    }
  }
  function handleChangeTopic(topicId) {
    // console.log(handleChangeSearch, "xyz");
    // alert (1)

    setCommunitypostData({
      ...communitypostData,
      category_id: topicId,
    });
  }

  function handleChangeLikeUnlikePost(isLike, communityPostId) {
    const data = {
      // post_comment_id: commentid,
      post_community_id: communityPostId,
      is_liked: isLike,
    };
    console.log(data);
    CommunityApi.CommunityPostsLikes(data)
      .then((res) => {
        getCommunityPosts(communitypostData);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Navbar />
      <ScrollToTopOnMount />
      {/* <!-- Mobile View Top Start --> */}
      {/* <MobileHeader title="Community" /> */}
      <MobileHeader title={props.t("Community")} />

      {/* <!-- Mobile View Top End --> */}

      {/* <!-- Mobile Bottom Menu Start --> */}
      <MobileBottomMenu />
      {/* <!-- Mobile Bottom Menu End --> */}

      <section className="pt-3 top-curve">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12">
              <h1 className="mt-5 mb-4 main-title d-m-none">
                {props.t("Community")}
              </h1>
              {/* <!-- Create Post Modal --> */}
              <div className="modal fade createpost" id="createpost">
                <div className="modal-dialog modal-dialog-centered modal-xs">
                  <div className="modal-content">
                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                      <div className="c-content">
                        <h5 className="mb-2">{props.t("Create-Post")}</h5>
                        <form>
                          <div className="mb-2">
                            <label for="" className="form-label">
                              {props.t("Topic")}
                            </label>
                            <input
                              type="text"
                              className="form-control w-100"
                              id=""
                              placeholder={props.t("Topic")}
                              name=""
                            />
                          </div>
                          <div>
                            <label for="" className="form-label">
                              {props.t("Description")}
                            </label>
                            <textarea
                              className="form-control w-100"
                              rows="7"
                              id=""
                              placeholder={props.t("Description")}
                              name=""
                            ></textarea>
                          </div>
                        </form>
                      </div>
                      <div className="upload__box">
                        <div className="upload__img-wrap mt-3">
                          {/* <!-- <div className="img-count">20</div> --> */}
                        </div>

                        <div className="upload__btn-box">
                          <label className="upload__btn">
                            <img
                              src="/assets/images/upload-img.png"
                              width="38"
                              height="38"
                              alt=""
                            />
                            <input
                              type="file"
                              multiple=""
                              data-max_length="20"
                              className="upload__inputfile"
                            />
                          </label>
                        </div>
                      </div>

                      <div className="d-flex collection-btn-list">
                        <div>
                          <a href="" className="float-right btn btn-cancel-d">
                            {props.t("Clear-Images")}
                          </a>
                          <a href="" className="float-right btn btn-cancel-d">
                            {props.t("Cancel")}
                          </a>
                          <a href="" className="float-right btn btn-create">
                            {props.t("Post")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="top-links d-none list-style-none">
                <ul>
                  <li>
                    <Link to={`/community`} className="">
                      {props.t("In-Focus")}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/community/news`} className="">
                      {props.t("News")}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/community/blog`} className="active">
                      {props.t("Dr-Connect")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Carousel Start --> */}
      {/* <section className="pt-2 d-none">
        <div className="container p-0 carousel-p">
          <div className="row">
            <div id="demo" className="carousel slide" data-bs-ride="carousel">
              <!-- Indicators/dots -->
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide-to="0"
                  className="active"
                ></button>
                <button
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide-to="1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide-to="2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide-to="3"
                ></button>
              </div>

              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="w-100"
                    src="/assets/images/community-banner.png"
                    alt="Image"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="w-100"
                    src="/assets/images/community-banner.png"
                    alt="Image"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="w-100"
                    src="/assets/images/community-banner.png"
                    alt="Image"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="w-100"
                    src="/assets/images/community-banner.png"
                    alt="Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Carousel End --> */}

      {/* <!-- In focus Start --> */}
      <section className="community pb-3 mob-pt-1 mob-mt-m-35 mob-pb-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-9 px-30 mob-px-30">
              <ul className="nav nav-tabs d-m-none" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Link to={`/community`} className="nav-link ">
                    {props.t("In-Focus")}
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community/news`}
                    className="nav-link "
                    id="news-tab"
                  >
                    {props.t("News")}
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community/blog`}
                    className="nav-link active"
                    id="blog-tab"
                  >
                    {props.t("Dr-Connect")}
                  </Link>
                </li>
              </ul>

              <div className="tab-content mob-mb-30 clearfix" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="focus"
                  role="tabpanel"
                  aria-labelledby="focus-tab"
                >
                  <div className="row">
                    <div className="col-md-12 col-mob-46">
                      <div className="mb-5">
                        <form action="">
                          <div className="row">
                            <div className="w-100 d-flex justify-content-center mb-1 rounded-4">
                              <button
                                type="button"
                                className="btn btn-primary w-50 rounded-5 text-white d-flex align-items-center justify-content-center"
                                style={{
                                  borderTopLeftRadius: 5,
                                  borderBottomLeftRadius: 5,
                                }}
                              >
                                <svg
                                  width="18"
                                  height="16"
                                  viewBox="0 0 18 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.58333 4.66927H12.9167M4.58333 8.0026H7.91667M8.75 14.6693L5.41667 11.3359H2.91667C2.47464 11.3359 2.05072 11.1603 1.73816 10.8478C1.42559 10.5352 1.25 10.1113 1.25 9.66927V3.0026C1.25 2.56058 1.42559 2.13665 1.73816 1.82409C2.05072 1.51153 2.47464 1.33594 2.91667 1.33594H14.5833C15.0254 1.33594 15.4493 1.51153 15.7618 1.82409C16.0744 2.13665 16.25 2.56058 16.25 3.0026V9.66927C16.25 10.1113 16.0744 10.5352 15.7618 10.8478C15.4493 11.1603 15.0254 11.3359 14.5833 11.3359H12.0833L8.75 14.6693Z"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                &ensp;{props.t("Feed")}
                              </button>
                              <Link
                                to="/community/blog/post"
                                className="  w-50 rounded-5 text-gray-black  d-flex align-items-center justify-content-center"
                                style={{
                                  borderTopRightRadius: 5,
                                  borderBottomRightRadius: 5,
                                  backgroundColor: "#eee",
                                }}
                                role="button"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="#A9A9A9"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
                                    fill="#A9A9A9"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z"
                                    fill="#A9A9A9"
                                  />
                                </svg>
                                &ensp;{props.t("Posts")}
                              </Link>
                            </div>
                            {/* <div className="col-md-12">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <img
                                    src="/assets/images/search.png"
                                    alt="search-icon"
                                    width="15"
                                    height="15"
                                  />
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  onKeyDown={handleChangeSearch}
                                />
                              </div>
                            </div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <div className="tab-content mob-mb-30 clearfix" id="myTabContent">

<div className="tab-pane fade show active" id="blog" role="tabpanel" aria-labelledby="blog-tab">
    <div className="row">
        <div className="col-md-10 bg-m-white">
            <div className="bg-gray mb-3">

                <div className="d-flex align-items-center">
                    <div>
                        <img src="/assets/images/profile.png" className="rounded" alt="" width="40"
                            height="40" />
                    </div>
                    <div className="ml-1">
                        <h5 className="mb-0">Kittiya Yuthasastrkosol</h5>
                        <span>1 day ago</span>
                    </div>
                </div>
                <div className="pl-55">
                    <h3>Doctors without borders in the future</h3>
                    <p className="desc">Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specia...</p>
                    <a href="blog-detail.html" className="view-text2">Read More</a>
                    <div className="slide-gallery my-3">

                        <div className="d-flex column-list">
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt=""/>
                                <div className="overlay">
                                    <span className="image-count">+20</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div
                    className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between pl-55">
                    <div>
                        <ul className="d-flex m-0 p-0 image-list">
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (1).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (2).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (1).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar2.png" className="rounded" alt=""
                                        width="35" height="35" />
                                    <span className="image-count">+2</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <a href="" className="text-cl">21 Comments</a>
                        <a href="" className="text-cl">27 Likes</a>
                    </div>
                </div>

            </div>
            <div className="bg-gray mb-3">

                <div className="d-flex align-items-center">
                    <div>
                        <img src="/assets/images/profile.png" className="rounded" alt="" width="40"
                            height="40" />
                    </div>
                    <div className="ml-1">
                        <h5 className="mb-0">Kittiya Yuthasastrkosol</h5>
                        <span>1 day ago</span>
                    </div>
                </div>
                <div className="pl-55">
                    <h3>Doctors without borders in the future</h3>
                    <p className="desc">Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specia...</p>
                    <a href="blog-detail.html" className="view-text2">Read More</a>

                </div>



                <div
                    className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between pl-55">
                    <div>
                        <ul className="d-flex m-0 p-0 image-list">
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (1).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (2).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (1).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar2.png" className="rounded" alt=""
                                        width="35" height="35" />
                                    <span className="image-count">+2</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <a href="" className="text-cl">21 Comments</a>
                        <a href="" className="text-cl">27 Likes</a>
                    </div>
                </div>
            </div>
            <div className="bg-gray mb-3">

                <div className="d-flex align-items-center">
                    <div>
                        <img src="/assets/images/profile.png" className="rounded" alt="" width="40"
                            height="40" />
                    </div>
                    <div className="ml-1">
                        <h5 className="mb-0">Kittiya Yuthasastrkosol</h5>
                        <span>1 day ago</span>
                    </div>
                </div>
                <div className="pl-55">
                    <h3>Doctors without borders in the future</h3>
                    <p className="desc">Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specia...</p>
                    <a href="blog-detail.html" className="view-text2">Read More</a>
                    <div className="slide-gallery my-3">
                        <img src="/assets/images/image 92.png" className="w-100 img-rouded-5"/>
                    </div>
                </div>



                <div
                    className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between pl-55">
                    <div>
                        <ul className="d-flex m-0 p-0 image-list">
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (1).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (2).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (1).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar2.png" className="rounded" alt=""
                                        width="35" height="35" />
                                    <span className="image-count">+2</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <a href="" className="text-cl">21 Comments</a>
                        <a href="" className="text-cl">27 Likes</a>
                    </div>
                </div>
            </div>
            <div className="bg-gray mb-3">

                <div className="d-flex align-items-center">
                    <div>
                        <img src="/assets/images/profile.png" className="rounded" alt="" width="40"
                            height="40" />
                    </div>
                    <div className="ml-1">
                        <h5 className="mb-0">Kittiya Yuthasastrkosol</h5>
                        <span>1 day ago</span>
                    </div>
                </div>
                <div className="pl-55">
                    <h3>Doctors without borders in the future</h3>
                    <p className="desc">Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specia...</p>
                    <a href="blog-detail.html" className="view-text2">Read More</a>
                    <div className="slide-gallery my-3">

                        <div className="d-flex column-list">
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                    alt="" />
                                <div className="overlay">
                                    <span className="image-count">+20</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div
                    className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between pl-55">
                    <div>
                        <ul className="d-flex m-0 p-0 image-list">
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (1).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (2).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (1).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar2.png" className="rounded" alt=""
                                        width="35" height="35" />
                                    <span className="image-count">+2</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <a href="" className="text-cl">21 Comments</a>
                        <a href="" className="text-cl">27 Likes</a>
                    </div>
                </div>

            </div>
            <div className="bg-gray mb-3">

                <div className="d-flex align-items-center">
                    <div>
                        <img src="/assets/images/profile.png" className="rounded" alt="" width="40"
                            height="40" />
                    </div>
                    <div className="ml-1">
                        <h5 className="mb-0">Kittiya Yuthasastrkosol</h5>
                        <span>1 day ago</span>
                    </div>
                </div>
                <div className="pl-55">
                    <h3>Doctors without borders in the future</h3>
                    <p className="desc">Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specia...</p>
                    <a href="blog-detail.html" className="view-text2">Read More</a>

                </div>



                <div
                    className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between pl-55">
                    <div>
                        <ul className="d-flex m-0 p-0 image-list">
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (1).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (2).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar (1).png" className="rounded"
                                        alt="" width="35" height="35" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="/assets/images/avatar2.png" className="rounded" alt=""
                                        width="35" height="35" />
                                    <span className="image-count">+2</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <a href="" className="text-cl">21 Comments</a>
                        <a href="" className="text-cl">27 Likes</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-2 d-m-none">
            <div className="img-block mb-3">
                <img src="/assets/images/image004.png" className="img-fluid" alt="" width=""
                    height="" />
            </div>
            <div className="img-block mb-3">
                <img src="/assets/images/image004.png" className="img-fluid" alt="" width=""
                    height="" />
            </div>
        </div>
    </div>
</div>

                    </div> */}
                    <div className="col-md-12 bg-m-white">
                      {communitypost.length > 0 && (
                        <InfiniteScroll
                          dataLength={communitypost.length}
                          next={fetchMoreData}
                          loader={
                            <h4 className="w-50 h-40 text-[18px]">
                              Loading...
                            </h4>
                          }
                          inverse={true}
                          hasMore={true}
                          endMessage={
                            <p
                              className="text-[16px]"
                              style={{ textAlign: "center", marginTop: "10px" }}
                            >
                              {" "}
                              <b>No more post</b>{" "}
                            </p>
                          }
                        >
                          {communitypost.length > 0 ? (
                            communitypost.map((item, i) => {
                              if (i === 2) {
                                return (
                                  <>
                                    <RightAdvertiseBanner
                                      banner={banners}
                                      className="mb-3 p-20 d-none"
                                    />
                                    <div className="bg-gray mb-3" key={i}>
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <img
                                            src={`${config.IMAGE_URL}${item.User.profile_image}`}
                                            className="rounded"
                                            alt=""
                                            width="40"
                                            height="40"
                                          />
                                        </div>
                                        <div className="ml-1  ">
                                          <h5 className="mb-0">
                                            {item?.User?.first_name}
                                            {/* &#xb7; */}
                                          </h5>
                                          <span className="ml-5">
                                            {moment(item.post_date).fromNow()}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="p-55">
                                        <Link
                                          to={`/community/details/${item.type}/${item.id}`}
                                        >
                                          <div className="slide-gallery my-3">
                                            <Link
                                              to={`/community/details/${item.type}/${item.id}`}
                                            >
                                              <h3 className="">
                                                {
                                                    i18n.language == 'en' && item.name_en ? (
                                                      item.name_en
                                                    ) : ( item.name_th )
                                                }
                                              </h3>
                                            </Link>
                                            <p className="desc  ">
                                            <Linkify componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key
                                              ) => (
                                                <a href={decoratedHref} key={key} target="_blank">
                                                  {decoratedText}
                                                </a>
                                              )}>
                                               {item?.description_en?.substring(
                                                0,
                                                200
                                              )}
                                              </Linkify>
                                            </p>
                                            <Link
                                              to={`/community/details/${item.type}/${item.id}`}
                                              className=""
                                            >
                                              {props.t("Read-More")}
                                            </Link>
                                            {item.CommunityPostMedia?.length >
                                            0 ? (
                                              item.CommunityPostMedia[0]
                                                .image_url ? (
                                                <div>
                                                <img className="center-cropped"  src={item.CommunityPostMedia[0]
                                                      .image_url} />
                                                </div>
                                              ) : (
                                                ""
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </Link>
                                        <Link
                                          to={`/community/details/${item.type}/${item.id}`}
                                        >
                                          <div className="slide-gallery my-3">
                                            <div className="d-flex  column-list col-md-4 row-cols-6 feedwidth">
                                              {item.CommunityPostMedia
                                                ? item.CommunityPostMedia.map(
                                                    (comitem, comindx) => {
                                                      return comindx !== 0 ? (
                                                        comindx <= 5 ? (
                                                          <div
                                                            className="column"
                                                            key={comindx}
                                                          >
                                                            <img
                                                              className="demo cursor w-100 h-100 img-fluid rounded-5"
                                                              src={
                                                                comitem.image_url
                                                              }
                                                              alt=""
                                                            />
                                                          </div>
                                                        ) : comindx === 6 ? (
                                                          <div className="column ">
                                                            <Link
                                                              to={`/community/details/${item.type}/${item.id}`}
                                                            >
                                                              <img
                                                                className="demo cursor w-100 h-100 img-fluid "
                                                                src={
                                                                  comitem.image_url
                                                                }
                                                                alt=""
                                                              />
                                                              <div className="overlay w-100  h-100">
                                                                <span className="image-count h-25 ">
                                                                  +
                                                                  {item
                                                                    .CommunityPostMedia
                                                                    .length - 3}
                                                                </span>
                                                              </div>
                                                            </Link>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )
                                                      ) : (
                                                        ""
                                                      );
                                                    }
                                                  )
                                                : ""}
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                      <div className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between">
                                        <ul className="d-flex justify-content-between m-0 p-0 image-list w-100">
                                          <li className="d-flex">
                                            {constants.ROLE.COMPANY !==
                                              getRoleID() && (
                                              <div>
                                                {item.isLike == 1 ? (
                                                  <button
                                                    type="button"
                                                    className="btn-dl like-btn"
                                                    onClick={
                                                      () =>
                                                        handleChangeLikeUnlikePost(
                                                          false,
                                                          item.id
                                                        )
                                                      // setCommunityPostId(item.id)
                                                    }
                                                  >
                                                    <img
                                                      src="/assets/images/like.png"
                                                      alt=""
                                                      width=""
                                                      height=""
                                                      className="m-1"
                                                    />
                                                    {props.t("Like")}
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    className="btn-dl dislike-btn"
                                                    onClick={() =>
                                                      handleChangeLikeUnlikePost(
                                                        true,
                                                        item.id
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src="/assets/images/dislike.png"
                                                      alt=""
                                                      width=""
                                                      height=""
                                                      className="m-1"
                                                    />
                                                    {props.t("Like")}
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                            <div className="ml-1 list-style-none">
                                              <ul className="d-flex m-0 p-0 image-list">
                                                {item.Likes.map(
                                                  (litem, linedx) => {
                                                    if (linedx <= 3) {
                                                      return (
                                                        <li>
                                                          <a href="">
                                                            <img
                                                              src={
                                                                litem.User
                                                                  .profile_image
                                                                  ? `${config.IMAGE_URL}${litem.User.profile_image}`
                                                                  : require("assets/images/profile.png")
                                                              }
                                                              className="rounded"
                                                              alt=""
                                                              width="35"
                                                              height="35"
                                                            />
                                                            {linedx === 3 && (
                                                              <span className="image-count">
                                                                {item.totalLikeCount -
                                                                  4 >
                                                                0
                                                                  ? `+${
                                                                      item.totalLikeCount -
                                                                      4
                                                                    }`
                                                                  : ""}
                                                              </span>
                                                            )}
                                                          </a>
                                                        </li>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                            {/* <li className="ml-1">
                                            {item.Likes.map((litem, linedx) => {
                                              return (
                                                <a>
                                                  {" "}
                                                  <img
                                                    src={litem.User.profile_image}
                                                  />{" "}
                                                </a>
                                              );
                                            })}
                                          </li> */}
                                          </li>

                                          <Link
                                            to={`/community/details/${item.type}/${item.id}`}
                                            className="view-text2 pt-1"
                                          >
                                            {props.t("Comments")} (
                                            {item.totalCommentCount})
                                          </Link>
                                        </ul>
                                      </div>
                                    </div>
                                  </>
                                );
                              } else {
                                return (
                                  <div className="bg-gray mb-3" key={i}>
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <img
                                          src={`${config.IMAGE_URL}${item.User.profile_image}`}
                                          className="rounded"
                                          alt=""
                                          width="40"
                                          height="40"
                                        />
                                      </div>
                                      <div className="ml-1  ">
                                        <h5 className="mb-0">
                                          {item?.User?.first_name}
                                          {/* &#xb7; */}
                                        </h5>
                                        <span className="ml-5">
                                          {moment(item.post_date).fromNow()}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="p-55">
                                      <Link
                                        to={`/community/details/${item.type}/${item.id}`}
                                      >
                                        <div className="slide-gallery my-3">
                                          <Link
                                            to={`/community/details/${item.type}/${item.id}`}
                                          >
                                            <h3 className="">{
                                                    i18n.language == 'en' && item.name_en ? (
                                                      item.name_en
                                                    ) : ( item.name_th )
                                                }</h3>
                                          </Link>
                                          <p className="desc  ">
                                          <Linkify componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                          ) => (
                                            <a href={decoratedHref} key={key} target="_blank">
                                              {decoratedText}
                                            </a>
                                          )}>
                                            
                                            {
                                                i18n.language == 'en' && item.description_en ? (
                                                  item?.description_en?.substring(
                                                    0,
                                                    200
                                                  )
                                                ) : ( item?.description_th?.substring(
                                                  0,
                                                  200
                                                ) )
                                            }
                                            
                                            </Linkify>
                                          </p>
                                          <Link
                                            to={`/community/details/${item.type}/${item.id}`}
                                            className=""
                                          >
                                            {props.t("Read-More")}
                                          </Link>
                                          {item.CommunityPostMedia?.length >
                                          0 ? (
                                            item.CommunityPostMedia[0]
                                              .image_url ? (
                                              <div>
                                              <img className="center-cropped"  src={ item.CommunityPostMedia[0]
                                                    .image_url} />
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Link>
                                      <Link
                                        to={`/community/details/${item.type}/${item.id}`}
                                      >
                                        <div className="slide-gallery my-3">
                                          <div className="d-flex  column-list col-md-4 row-cols-6 feedwidth">
                                            {item.CommunityPostMedia
                                              ? item.CommunityPostMedia.map(
                                                  (comitem, comindx) => {
                                                    return comindx !== 0 ? (
                                                      comindx <= 5 ? (
                                                        <div
                                                          className="column"
                                                          key={comindx}
                                                        >
                                                          <img
                                                            className="demo cursor w-100 h-100 img-fluid rounded-5"
                                                            src={
                                                              comitem.image_url
                                                            }
                                                            alt=""
                                                          />
                                                        </div>
                                                      ) : comindx === 6 ? (
                                                        <div className="column ">
                                                          <Link
                                                            to={`/community/details/${item.type}/${item.id}`}
                                                          >
                                                            <img
                                                              className="demo cursor w-100 h-100 img-fluid "
                                                              src={
                                                                comitem.image_url
                                                              }
                                                              alt=""
                                                            />
                                                            <div className="overlay w-100  h-100">
                                                              <span className="image-count h-25 ">
                                                                +
                                                                {item
                                                                  .CommunityPostMedia
                                                                  .length - 3}
                                                              </span>
                                                            </div>
                                                          </Link>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )
                                                    ) : (
                                                      ""
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between">
                                      <ul className="d-flex justify-content-between m-0 p-0 image-list w-100">
                                        <li className="d-flex">
                                          {constants.ROLE.COMPANY !==
                                            getRoleID() && (
                                            <div>
                                              {item.isLike == 1 ? (
                                                <button
                                                  type="button"
                                                  className="btn-dl like-btn"
                                                  onClick={
                                                    () =>
                                                      handleChangeLikeUnlikePost(
                                                        false,
                                                        item.id
                                                      )
                                                    // setCommunityPostId(item.id)
                                                  }
                                                >
                                                  <img
                                                    src="/assets/images/like.png"
                                                    alt=""
                                                    width=""
                                                    height=""
                                                    className="m-1"
                                                  />
                                                  {props.t("Like")}
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn-dl dislike-btn"
                                                  onClick={() =>
                                                    handleChangeLikeUnlikePost(
                                                      true,
                                                      item.id
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src="/assets/images/dislike.png"
                                                    alt=""
                                                    width=""
                                                    height=""
                                                    className="m-1"
                                                  />
                                                  {props.t("Like")}
                                                </button>
                                              )}
                                            </div>
                                          )}
                                          <div className="ml-1 list-style-none">
                                            <ul className="d-flex m-0 p-0 image-list">
                                              {item.Likes.map(
                                                (litem, linedx) => {
                                                  if (linedx <= 3) {
                                                    return (
                                                      <li>
                                                        <a href="">
                                                          <img
                                                            src={
                                                              litem.User
                                                                .profile_image
                                                                ? `${config.IMAGE_URL}${litem.User.profile_image}`
                                                                : require("assets/images/profile.png")
                                                            }
                                                            className="rounded"
                                                            alt=""
                                                            width="35"
                                                            height="35"
                                                          />
                                                          {linedx === 3 && (
                                                            <span className="image-count">
                                                              {item.totalLikeCount -
                                                                4 >
                                                              0
                                                                ? `+${
                                                                    item.totalLikeCount -
                                                                    4
                                                                  }`
                                                                : ""}
                                                            </span>
                                                          )}
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                          {/* <li className="ml-1">
                                            {item.Likes.map((litem, linedx) => {
                                              return (
                                                <a>
                                                  {" "}
                                                  <img
                                                    src={litem.User.profile_image}
                                                  />{" "}
                                                </a>
                                              );
                                            })}
                                          </li> */}
                                        </li>

                                        <Link
                                          to={`/community/details/${item.type}/${item.id}`}
                                          className="view-text2 pt-1"
                                        >
                                          {props.t("Comments")} (
                                          {item.totalCommentCount})
                                        </Link>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <h1>{props.t("No-News-Found")}</h1>
                          )}
                        </InfiniteScroll>
                      )}
                    </div>
                    {/* <div className="col-md-2 d-m-none">
                      <div className="img-block mb-3">
                        <img
                          src="/assets/images/image004.png"
                          className="img-fluid"
                          alt=""
                          width=""
                          height=""
                        />
                      </div>
                      <div className="img-block mb-3">
                        <img
                          src="/assets/images/image004.png"
                          className="img-fluid"
                          alt=""
                          width=""
                          height=""
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightAdvertiseBanner
              banner={banners}
              className="col-md-3 pt-3 communityBanner"
            />
          </div>
        </div>
      </section>
      {/* <!-- In focus End --> */}
      <Footer />
    </>
  );
}
export default withTranslation()(Community);
