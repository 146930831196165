import moment from "moment";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import MobileActivityCardArea from "./MobileActivityCardArea";
const MobileActivity = (props) => {
  let startTime = moment(props.activity.start_time, "HH:mm:ss a");
  let endTime = moment(props.activity.end_time, "HH:mm:ss a");
  let duration = moment.duration(endTime.diff(startTime));
  let hours = duration.asHours();
  let credits = Math.round(hours * 10) / 10;
  return (
    <div className="event-item text-center">
      {props.currentTab === "Multimedia" ? (
        <a href={props.activity?.ActivityMedia[0]?.file_url} target="_blank">
          <MobileActivityCardArea
            activity={props.activity}
            currentTab={props.currentTab}
            credits={credits}
          />
        </a>
      ) : (
        <NavLink
          to={"/education/education-activity-details/" + props.activity.id}
        >
          <MobileActivityCardArea
            activity={props.activity}
            currentTab={props.currentTab}
            credits={credits}
          />
        </NavLink>
      )}
    </div>
  );
};

export default withTranslation()(MobileActivity);
