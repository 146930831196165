import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  NonLoginRoute,
  PrivateRoute,
  ProtectedRoute,
  PublicRoute,
} from "./components/common";
import Logout from "./pages/Auth/Logout";

import {
  ActivityForm,
  Community,
  CommunityBlog,
  CommunityBlogDetails,
  CommunityBlogPost,
  CommunityDetails,
  CommunityNews,
  CommunitySlideDetails,
  CompanyProductGallery,
  DoctorRegistration,
  Education,
  EducationActivities,
  EducationActivityDetails,
  EducationLibraries,
  EducationLibraryDetails,
  ForgotPassword,
  Gallery,
  Home,
  Institute,
  Login,
  Offer,
  OfferDetail,
  ResubmitDoctorRegistration,
  ResubmitInstitute,
  ResubmitSubInstitute,
  SubInstitute,
  UpdatePassword,
} from "pages";

import ActivityInstituteFilter from "./pages/Activity/ActivityInstituteFilter";
import EducationInstituteFilter from "./pages/Education/EducationInstituteFilter";

import ActivitiesAboutClose from "pages/Activity/ActivitiesAboutClose";
import AddQuestion from "pages/Activity/AddQuestion";
import EditQuestion from "pages/Activity/EditQuestion";
import StartQuiz from "pages/Activity/StartQuiz";
import UpcomingActivities from "pages/Activity/UpcomingActivities";
import BookingDetail from "pages/Booking/BookingDetail";
import AboutUs from "pages/CMS/AboutUs";
import PrivacyPolicy from "pages/CMS/PrivacyPolicy";
import Support from "pages/CMS/Support";
import Booking from "./pages/Booking/Booking";
import ReviewBooking from "./pages/Booking/ReviewBooking";
import Activities from "./pages/Bookmark/Activities";
import Bookmark from "./pages/Bookmark/Bookmark";
import Library from "./pages/Bookmark/Library";
import Internship from "./pages/Profile/Internship";
import MyCalendar from "./pages/Profile/MyCalendar";
import MyMedx from "./pages/Profile/MyMedx";
import MyMedxDetails from "./pages/Profile/MyMedxDetails";
import Profile from "./pages/Profile/Profile";
import TMCCallback from "./pages/TMCCallback";

import { HomeApi } from "apis/HomeApi";
import { setAuthorizationToken } from "helpers/authorization";
import EvaluationForm from "pages/Activity/EvaluationForm";
import CopyActivityForm from "pages/ActivityJson/CopyActivityForm";
import EditActivityForm from "pages/ActivityJson/EditActivityForm";
import CompanyLogin from "pages/Auth/CompanyLogin";
import SignIn from "pages/Auth/SignIn";
import SignUp from "pages/Auth/SignUp";
import ValidateLogin from "pages/Auth/ValidateLogin";
import TermsAndCondition from "pages/CMS/TermsAndCondition";
import CommunityInFocusCompany from "pages/Community/CommunityInFocusCompany";
import CompanyProductDetail from "pages/Community/CompanyProductDetail";
import CompanyProducts from "pages/Community/CompanyProducts";
import UserTaskDetail from "pages/Components/UserTaskDetail";
import MyActivity from "pages/Profile/MyActivity";
import MyLeave from "pages/Profile/MyLeave";
import Notifications from "pages/Profile/Notifications";
import { useDispatch } from "react-redux";
import { getToken } from "Token";
import ApplyLeave from "pages/Leave/ApplyLeave";
import UpdateLeave from "pages/Leave/UpdateLeave";
import CheckResults from "pages/Profile/CheckResults";
import CheckResultDetail from "pages/Profile/CheckResultDetail";
import ExportPdf from "pages/Profile/exportPdf";

const TokenLoadComponent = () => {
  const search = useLocation().search;
  let userToken = "";
  userToken = new URLSearchParams(search).get("token");
  useEffect(() => {
    if (userToken !== "" && userToken !== null && userToken !== undefined) {
      setAuthorizationToken(userToken);
    } else {
      setAuthorizationToken(getToken());
    }
  }, []);
};

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (getToken() !== null && getToken() !== undefined) {
      getUserDetails();
    }
  }, []);

  const getUserDetails = async () => {
    HomeApi.checkUserDetailsAndInterestAPI()
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: "ADD_USER_PROFILE_PIC",
            payload: res.data.user
              ? res.data.user.profile_image
              : "assets/images/profile.png",
          });
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <TokenLoadComponent />
      <Routes>
        <Route element={<NonLoginRoute guest={false} />}>
          <Route path="login" exact element={<Login />} />
          <Route path="/tmc-callback" exact element={<TMCCallback />} />
          <Route path="/primary-institute" exact element={<Institute />} />
          <Route path="sub-institute" exact element={<SubInstitute />} />
          <Route
            path="/resubmit-primary-institute/:id"
            exact
            element={<ResubmitInstitute />}
          />
          <Route
            path="/resubmit-sub-institute/:id"
            exact
            element={<ResubmitSubInstitute />}
          />
          <Route path="company-login" exact element={<CompanyLogin />} />
          <Route path="validate_login_auto" exact element={<ValidateLogin />} />

          <Route path="sign-in" exact element={<SignIn />} />
          <Route path="sign-up" exact element={<SignUp />} />
          <Route path="/doctor-signup" exact element={<DoctorRegistration />} />
          <Route
            path="/resubmit-doctor-signup/:request_doctor_id"
            exact
            element={<ResubmitDoctorRegistration />}
          />
          <Route path="forgot-password" exact element={<ForgotPassword />} />
          <Route path="update-password" exact element={<UpdatePassword />} />
        </Route>

        <Route element={<PublicRoute />}>
          <Route path="about-us" exact element={<AboutUs />} />
          <Route path="privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="support" exact element={<Support />} />
          <Route path="/activity-form" exact element={<ActivityForm />} />
          <Route
            path="/edit-activity-form"
            exact
            element={<EditActivityForm />}
          />
          <Route path="/add-question" exact element={<AddQuestion />} />
          <Route
            path="/edit-question/:question_id"
            exact
            element={<EditQuestion />}
          />
          <Route
            path="terms-and-condition"
            exact
            element={<TermsAndCondition />}
          />
        </Route>

        <Route element={<ProtectedRoute guest={false} />}>
          <Route
            path="/edit-activity-form/:activity_id"
            exact
            element={<EditActivityForm />}
          />
          <Route
            path="/copy-activity-form/:activity_id"
            exact
            element={<CopyActivityForm />}
          />
          <Route path="logout" exact element={<Logout />} />
          <Route path="education" exact element={<Education />} />
          <Route
            path="education/education-activities/:education_type"
            exact
            element={<EducationActivities />}
          />
          <Route
            path="education/education-libraries/:education_type"
            exact
            element={<EducationLibraries />}
          />
          <Route
            path="education/education-activity-details/:activity_id"
            exact
            element={<EducationActivityDetails />}
          />
          <Route
            path="education/education-library-details/:library_id"
            exact
            element={<EducationLibraryDetails />}
          />
          <Route
            path="/education/review-booking/:activity_id"
            exact
            element={<ReviewBooking />}
          />

          <Route
            path="/education/institute-filter"
            exact
            element={<EducationInstituteFilter />}
          />
          <Route
            path="/activity/institute-filter"
            exact
            element={<ActivityInstituteFilter />}
          />
          <Route
            path="/upcoming-activities"
            exact
            element={<UpcomingActivities />}
          />
          <Route
            path="/activity-about-to-close"
            exact
            element={<ActivitiesAboutClose />}
          />
          <Route path="/activity-form" exact element={<ActivityForm />} />

          <Route path="/apply-leave" exact element={<ApplyLeave />} />
          <Route
            path="/edit-leave-form/:leave_id"
            exact
            element={<UpdateLeave />}
          />

          <Route path="/add-question" exact element={<AddQuestion />} />
          <Route
            path="/edit-question/:question_id"
            exact
            element={<EditQuestion />}
          />
          <Route
            path="/start-quiz/:activity_id"
            exact
            element={<StartQuiz />}
          />
          <Route
            path="/evaluation-feedback/:formID/:activityId/:userTaskID"
            exact
            element={<EvaluationForm />}
          />

          <Route path="community" exact element={<Community />} />

          <Route
            path="community/details/:type/:community_post_id"
            exact
            element={<CommunityDetails />}
          />
          <Route
            path="community/details/gallery/:community_post_id/:active_index"
            exact
            element={<Gallery />}
          />

          <Route path="community/news" exact element={<CommunityNews />} />
          <Route path="community/blog" exact element={<CommunityBlog />} />
          <Route
            path="community/blog/post"
            exact
            element={<CommunityBlogPost />}
          />
          <Route
            path="community/blog/details/:type/:community_post_id"
            exact
            element={<CommunityBlogDetails />}
          />
          <Route
            path="community/slide"
            exact
            element={<CommunitySlideDetails />}
          />

          <Route
            path="community/in-focus/company"
            exact
            element={<CommunityInFocusCompany />}
          />
          <Route
            path="community/company/products/:company_id"
            exact
            element={<CompanyProducts />}
          />
          <Route
            path="community/company/product/:product_id"
            exact
            element={<CompanyProductDetail />}
          />

          <Route path="offers" exact element={<Offer />} />
          <Route
            path="offer-detail/:offer_id"
            exact
            element={<OfferDetail />}
          />

          <Route path="/booking" exact element={<Booking />} />
          <Route
            path="/booking-details/:booking_id"
            exact
            element={<BookingDetail />}
          />

          <Route path="/my-activity" exact element={<MyActivity />} />
          <Route path="/my-leaves" exact element={<MyLeave />} />
          <Route path="/notifications" exact element={<Notifications />} />

          <Route path="bookmark" exact element={<Bookmark />} />
          <Route path="bookmark/activities" exact element={<Activities />} />
          <Route path="bookmark/library" exact element={<Library />} />

          <Route path="profile" exact element={<Profile />} />
          <Route path="internship" exact element={<Internship />} />
          <Route path="mymedx" exact element={<MyMedx />} />
          <Route path="export-pdf" exact element={<ExportPdf/>} />
          <Route path="mycalendar" exact element={<MyCalendar />} />
          <Route
            path="taskDetail/:userTaskID"
            exact
            element={<UserTaskDetail />}
          />
          <Route path="checkresults" exact element={<CheckResults />} />
          <Route
            path="checkresultDetail"
            exact
            element={<CheckResultDetail />}
          />

          <Route
            path="mymedx-details/:from_year/:to_year/:activity_group"
            exact
            element={<MyMedxDetails />}
          />

          <Route path="/" exact element={<Home />} />
          <Route path="education" exact element={<Education />} />
          <Route
            path="education/education-activities/:education_type"
            exact
            element={<EducationActivities />}
          />
          <Route
            path="education/education-libraries/:education_type"
            exact
            element={<EducationLibraries />}
          />
          <Route
            path="education/education-activity-details/:activity_id"
            exact
            element={<EducationActivityDetails />}
          />
          <Route
            path="education/education-library-details/:library_id"
            exact
            element={<EducationLibraryDetails />}
          />

          <Route path="community" exact element={<Community />} />
          <Route
            path="community/details/:type/:community_post_id"
            exact
            element={<CommunityDetails />}
          />
          <Route
            path="community/details/gallery/:community_post_id/:active_index"
            exact
            element={<Gallery />}
          />

          <Route path="community/news" exact element={<CommunityNews />} />
          <Route path="community/blog" exact element={<CommunityBlog />} />
          <Route
            path="community/blog/details/:type/:community_post_id"
            exact
            element={<CommunityBlogDetails />}
          />

          <Route path="offers" exact element={<Offer />} />
          <Route
            path="offer-detail/:offer_id"
            exact
            element={<OfferDetail />}
          />

          <Route
            path="community/product/images/:product_id"
            exact
            element={<CompanyProductGallery />}
          /> 
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="*" element={<div>404</div>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;