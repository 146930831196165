import React, { useEffect, useState } from "react";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { CommunityApi } from "../../apis/CommunityApi";
import config from "../../config";
import moment from "moment";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import MobileHeader from "../../components/Layout/Mobile/MobileHeader";
import { withTranslation } from "react-i18next";
import RightAdvertiseBanner from "../Components/RightAdvertiseBanner";
import { HomeApi } from "../../apis/HomeApi";
import constants from "../../constants";
import { getRoleID } from "../../Token";
import InfiniteScroll from "react-infinite-scroll-component";
import CommunityPostCard from "./CommunityPostCard";
import OwlCarousel from "react-owl-carousel";
import i18n from "../../i18n";

const options = {
  responsiveClass: true,
  dots: false,
  autoplay: false,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 6,
    },
    700: {
      items: 6,
    },
    1000: {
      items: 6,
    },
  },
};

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  return null;
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Community(props) {
  const params = useParams();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product = urlParams.get("search");

  const query = useQuery();
  const type = query.get("type") || "INFOCUS";
  const [communitypostData, setCommunitypostData] = useState({
    page: 1,
    limit: config.LIMIT,
    type,
    search: "",
    filterBy: {
      company_id: "All",
    },
    category_id: "All",
    institute_user_id: "All",
  });

  const [communitypost, setCommunitypost] = useState([]);
  const [communityTopicData, setCommunityData] = useState({
    parent_type: "community",
    type,
  });
  const [sliderInterval, setsliderInterval] = useState(10000);
  const [communityTopic, setCommunityTopic] = useState([]);
  const [autoSearch, setAutoSearchData] = useState([]);


  const fetchScrollPostData = {};
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
    search: urlParams.get("search") == null ? "" : urlParams.get("search"),
    type: type,
    filterBy: { company_id: "All" },
    institute_user_id:
      urlParams.get("institute_user_id") == null
        ? "All"
        : urlParams.get("institute_user_id"),
    category_id:
      urlParams.get("category_id") == null
        ? "All"
        : urlParams.get("category_id"),
  });
  const [categorytActive, setCategoryActive] = useState("All");
  const [searchActive, setSearchActive] = useState("");
  const [instituteActive, setInstituteActive] = useState("All");
  const [topicPosition, setTopicPosition] = useState(0);
  const [keywordActive, setKeywordActive] = useState("");

  const getCommunityPosts = (data, is_push_data) => {
    CommunityApi.getCommunityPosts(data)
      .then((res) => {
        if (res.data.success) {
          if (is_push_data) {
            setCommunitypost([...communitypost, ...res.data.data]);
          } else {
            setCommunitypost(res.data.data);
          }
        } else {
          setCommunitypost([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCommunityTopic = (data) => {
    CommunityApi.getCommunityTopic(data)
      .then((res) => {
        if (res.data.success) {
          setCommunityTopic(res.data.data);
        } else {
          setCommunityTopic([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMoreData = () => {
    fetchScrollPostData({
      page: activeData.page + 1,
      limit: config.LIMIT,
      search: activeData.search,
      type: type,
      filterBy: { company_id: activeData.filterBy.company_id },
      institute_user_id: activeData.institute_user_id,
      category_id: activeData.category_id,
    });
  };

  window.onscroll = async function (ev) {
    var pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    if (window.innerHeight + window.scrollY >= pageHeight) {
      const data = {
        page: activeData.page + 1,
        limit: config.LIMIT,
        search: keywordActive,
        type: type,
        filterBy: { company_id: activeData.filterBy.company_id },
        institute_user_id: instituteActive,
        category_id: categorytActive,
      };
      setActiveData(data);
      getCommunityPosts(data, true);
    }
  };

  const [banners, setBanners] = useState([]);
  const [topBanner, setTopBanner] = useState({
    page_name: "Community-In-Focus",
    type: "Banner",
    position: "Top",
  });

  const getBanners = (data) => {
    HomeApi.getBanners(data)
      .then((res) => {
        if (res.data.success) {
          setBanners(res.data.data.banners);
          setsliderInterval(res.data.data.speed_time_interval);
        } else {
          setBanners([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setCategoryActive(activeData.category_id);
    //setSearchActive(activeData.search);
    setInstituteActive(activeData.institute_user_id);
  }, []);

  useEffect(() => {
    getCommunityPosts(communitypostData, false);
  }, [communitypostData.category_id, communitypostData.search]);

  useEffect(() => {
    getBanners(topBanner);
    getCommunityTopic(communityTopicData);
  }, []);

  function handleChangeSearch(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      setCommunitypostData({
        ...communitypostData,
        search: event.target.value,
      });
    }
  }

  function handleChangeTopic(topicId, position) {
    setTopicPosition(position);
    setCommunitypostData({
      ...communitypostData,
      category_id: topicId,
    });
  }

  function handleChangeLikeUnlikePost(isLike, communityPostId) {
    const data = {
      // post_comment_id: commentid,
      post_community_id: communityPostId,
      is_liked: isLike,
    };
    console.log(data);
    CommunityApi.CommunityPostsLikes(data)
      .then((res) => {
        getCommunityPosts(communitypostData);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const OnSearchKeyUp = (e) => {
    setKeywordActive(e.target.value);
    if(e.key == "Enter") {
      activeData.keyword = e.target.value;
      setActiveData({
        page: 1,
        limit: config.LIMIT,
        search: e.target.value,
        type: type,
        filterBy: { company_id: activeData.filterBy.company_id },
        institute_user_id: instituteActive,
        category_id: categorytActive,
      });
      getCommunityPosts(activeData);
      setAutoSearchData([]);
      return false;
    }
  }


  const OnSearch = (e) => {
    let data = { keyword: e.target.value, type: type};
    console.log('e.target.value',e.target.value);
    setKeywordActive(e.target.value);
    
   
    if(e.target.value != '') {
      CommunityApi.getCommunityNameNameByKeyword(data)
        .then((res) => {
          if (res.data.success) {
            setAutoSearchData(res.data.data);
          } else {
            setAutoSearchData([]);
          }
        })
        .catch((err) => {
          console.log(err);
      });
    } else {
      setAutoSearchData([]);
    }
    // setActiveData({ ...activeData, search: e.target.value })
  }

  const searchActivity = (value) => {
    setKeywordActive(value);
    setCommunitypost([]);
    activeData.search = value;
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      search: value,
      type: type,
      filterBy: { company_id: activeData.filterBy.company_id },
      institute_user_id: instituteActive,
      category_id: categorytActive,
    });
    getCommunityPosts(activeData,false);
    setAutoSearchData([]);

  }

  const searchRecord = () => {
    setKeywordActive(keywordActive);
    setCommunitypost([]);
    activeData.keyword = (keywordActive !== undefined) ? keywordActive : "";
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      search: activeData.keyword,
      type: type,
      filterBy: { company_id: activeData.filterBy.company_id },
      institute_user_id: instituteActive,
      category_id: categorytActive,
    });
    getCommunityPosts(activeData,false);
    setAutoSearchData([]);
  }

  return (
    <>
      <Navbar />
      <ScrollToTopOnMount />

      {/* <!-- Mobile View Top Start --> */}
      <MobileHeader title={props.t("Community")} />
      {/* <!-- Mobile View Top End --> */}

      {/* <!-- Mobile Bottom Menu Start --> */}
      <MobileBottomMenu />
      {/* <!-- Mobile Bottom Menu End --> */}

      <section className="pt-3 top-curve">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12">
              <h1 className="mt-5 mb-4 main-title d-m-none">
                {props.t("Community")}
              </h1>
            </div>
            <div className="col-md-12">
              <div className="top-links d-none list-style-none">
                <ul>
                  <li>
                    <Link to={`/community`} className="active">
                      {props.t("In-Focus")}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/community/news`} className="">
                      {props.t("News")}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/community/blog`} className="">
                      {props.t("Dr-Connect")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- In focus Start --> */}
      <section className="community pb-3 mob-pt-1 mob-mt-m-35 mob-pb-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-9">
              <ul className="nav nav-tabs d-m-none" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community`}
                    className="nav-link active"
                    id="focus-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#focus"
                    type="button"
                    role="tab"
                    aria-controls="focus"
                    aria-selected="true"
                  >
                    {props.t("In-Focus")}
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community/news`}
                    className="nav-link"
                    id="news-tab"
                  >
                    {props.t("News")}
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community/blog`}
                    className="nav-link"
                    id="blog-tab"
                  >
                    {props.t("Dr-Connect")}
                  </Link>
                </li>
              </ul>

              <div className="tab-content mob-mb-30 clearfix" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="focus"
                  role="tabpanel"
                  aria-labelledby="focus-tab"
                >
                  <div className="row">
                    <div className="col-md-12 col-mob-46">
                      <div className="search-box  mb-2">
                          <div className="row">
                            <div className="w-100 d-flex justify-content-center mb-4 rounded-4">
                              <Link
                                // type="button"
                                to={`/community`}
                                className="btn-primary w-50 rounded-5 text-white d-flex align-items-center justify-content-center"
                                style={{
                                  borderTopLeftRadius: 5,
                                  borderBottomLeftRadius: 5,
                                }}
                              >
                                <svg
                                  width="18"
                                  height="16"
                                  viewBox="0 0 18 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.58333 4.66927H12.9167M4.58333 8.0026H7.91667M8.75 14.6693L5.41667 11.3359H2.91667C2.47464 11.3359 2.05072 11.1603 1.73816 10.8478C1.42559 10.5352 1.25 10.1113 1.25 9.66927V3.0026C1.25 2.56058 1.42559 2.13665 1.73816 1.82409C2.05072 1.51153 2.47464 1.33594 2.91667 1.33594H14.5833C15.0254 1.33594 15.4493 1.51153 15.7618 1.82409C16.0744 2.13665 16.25 2.56058 16.25 3.0026V9.66927C16.25 10.1113 16.0744 10.5352 15.7618 10.8478C15.4493 11.1603 15.0254 11.3359 14.5833 11.3359H12.0833L8.75 14.6693Z"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                &ensp;{props.t("Feed")}
                              </Link>
                              <Link
                                // type="button"
                                to={`/community/in-focus/company`}
                                // to="#"
                                className="border-0  w-50 rounded-5 text-gray-black  d-flex align-items-center justify-content-center"
                                style={{
                                  borderTopRightRadius: 5,
                                  borderBottomRightRadius: 5,
                                  backgroundColor: "#eee",
                                  lineHeight: 1.5,
                                  textAlign: 'center',
                                  padding: '.375rem .75rem'
                                }}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.5833 16.5V3.16667C14.5833 2.72464 14.4077 2.30072 14.0952 1.98816C13.7826 1.67559 13.3587 1.5 12.9167 1.5H4.58333C4.14131 1.5 3.71738 1.67559 3.40482 1.98816C3.09226 2.30072 2.91667 2.72464 2.91667 3.16667V16.5M14.5833 16.5H2.91667M14.5833 16.5H16.25M14.5833 16.5H10.4167M2.91667 16.5H1.25M2.91667 16.5H7.08333M10.4167 16.5V12.3333C10.4167 12.1123 10.3289 11.9004 10.1726 11.7441C10.0163 11.5878 9.80435 11.5 9.58333 11.5H7.91667C7.69565 11.5 7.48369 11.5878 7.32741 11.7441C7.17113 11.9004 7.08333 12.1123 7.08333 12.3333V16.5M10.4167 16.5H7.08333M6.25 4.83333H7.08333M6.25 8.16667H7.08333M10.4167 4.83333H11.25M10.4167 8.16667H11.25"
                                    stroke="#A9A9A9"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                &ensp; {props.t("companyHighlight")}
                              </Link>
                            </div>
                            <div className="col-md-12">
                              <div className="wrapper w-40">
                                <div className="search-input">
                             
                              <input type="text" value={keywordActive}  defaultValue={keywordActive} onChange={(e) => OnSearch(e) }  onKeyUp = {OnSearchKeyUp}    placeholder={props.t("Search")}/>
                                <div className="autocom-box">
                                {autoSearch?.map((searchdata, index) => {
                                  return (<li key={index}  onClick={() => searchActivity(searchdata.name_en)}  >{searchdata.name_en}</li>)
                                })}
                                </div>
                              <div className="icon"  onClick={() => searchRecord()}>
                                <img
                                  src={config.BASE_URL + "/assets/images/search.png"}
                                  alt="search-icon"
                                  width={15}
                                  height={15}
                                />
                              </div>
                                </div>
                              </div>
                              {/* <div className="input-group">
                                <span className="input-group-text">
                                  <img
                                    src="/assets/images/search.png"
                                    alt="search-icon"
                                    width="15"
                                    height="15"
                                  />
                                </span>
                                <input
                                  type="text"
                                  className="form-control text-dark"
                                  placeholder={props.t("Search")}
                                  onKeyDown={handleChangeSearch}
                                />
                              </div> */}
                            </div>
                          </div>
                      </div>
                      <div className="mob-pr-0 specialiry my-4 mob-mb-0 mob-mt-70 mob-pl-8 list-style-none">
                        {/* <h5 className="title mb-4">{props.t("Topic")}</h5> */}
                        <ul>
                        <OwlCarousel
                          autoWidth={true}
                          {...options}
                          nav={true}
                          startPosition={topicPosition}
                          navText={[
                            '<span class="interest-arrow interest-prev">‹</span>',
                            '<span class="interest-arrow interest-next">›</span>',
                          ]}
                        >
                          <li key={'allspecialties'}>
                            <a
                              href="javascript:void();"
                              onMouseUp={() => handleChangeTopic("All",0)}
                              className={`${communitypostData.category_id == "All" ? "active" : ""}`}
                            >
                              {props.t("All")}
                            </a>
                          </li>
                          {communityTopic
                            ? communityTopic.map((titem, tindex) => {
                                return (
                                  <div key={tindex} className="mx-1">
                                    <li>
                                      <a
                                        role="button"
                                        onClick={() =>
                                          handleChangeTopic(titem.id,tindex)
                                        }
                                        className={
                                          communitypostData.category_id ==
                                          titem.id
                                            ? "active "
                                            : "cursor pointer"
                                        }
                                      >
                                        {
                                            i18n.language == 'en' && titem.name_en ? (
                                              titem.name_en
                                            ) : ( titem.name_th )
                                        }
                                      </a>
                                    </li>
                                  </div>
                                );
                              })
                            : ""}
                        </OwlCarousel>
                        </ul>
                        
                      </div>
                    </div>
                    <div className="col-md-12 bg-m-white">
                      {communitypost.length > 0 && (
                        <InfiniteScroll
                          dataLength={communitypost.length}
                          next={fetchMoreData}
                          loader={
                            <h4 className="w-50 h-40 text-[18px]">
                              Loading...
                            </h4>
                          }
                          inverse={true}
                          hasMore={true}
                          endMessage={
                            <p
                              className="text-[16px]"
                              style={{ textAlign: "center", marginTop: "10px" }}
                            >
                              <b>No more post</b>
                            </p>
                          }
                        >
                          {communitypost.length > 0 ? (
                            communitypost.map((item, i) => {
                              if (i === 2) {
                                return (
                                  <>
                                    <RightAdvertiseBanner
                                      banner={banners}
                                      className="mb-3 p-20 d-none"
                                    />
                                    <CommunityPostCard
                                      key={`infocus${i}`}
                                      item={item}
                                      likeUnlikeHandler={
                                        handleChangeLikeUnlikePost
                                      }
                                    />
                                  </>
                                );
                              } else {
                                return (
                                  <CommunityPostCard
                                    key={`infocus${i}`}
                                    item={item}
                                    likeUnlikeHandler={
                                      handleChangeLikeUnlikePost
                                    }
                                  />
                                );
                              }
                            })
                          ) : (
                            <h1>{props.t("No-Post-Found")}</h1>
                          )}
                        </InfiniteScroll>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <RightAdvertiseBanner
              banner={banners}
              className="col-md-3 pt-3 communityBanner"
            />
          </div>
        </div>
      </section>
      {/* <!-- In focus End --> */}

      <Footer />
    </>
  );
}

export default withTranslation()(Community);
