import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { NavLink, Link } from 'react-router-dom';
// import Navbar from "../../components/Layout/Navbar";
// import Footer from "../../components/Layout/Footer";
// import upevent1 from "../../assets/images/right-arrow.png";
import config from "../../config";
// import * as url from "../../helpers/common_helper";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AuthApi } from "apis/AuthApi";
import { Token } from "../../Token";
// import toastr from "toastr";
// import "toastr/build/toastr.min.css";

function CompanyLogin(props) {

    const [errorMsg, setErrorMsg] = useState("");
    
  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required(props.t("Email-Required-Messages")),
      password: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: (values) => {
        AuthApi.companyLoginAPI(values)
        .then((res) => {
            if(res.data.success){
                Token.saveToken(res.data.user, res.data.token);
                window.location.href = "/";
            }else{
                setErrorMsg(res.data.message)
            }
        })
        .catch((error) => {
            console.log("error", error);
        });
    },
  });

  return (
    <div>
      <section className="login">
        <div className="blue-circle1" />
        <div className="login-object d-none">
          <img
            src={config.BASE_URL + "/assets/images/World-healthday.png"}
            alt=""
            width=""
            height=""
          />
        </div>
        <img
          src="assets/images/World-healthday.png"
          className="login-img d-m-none"
        />
        <div className="container">
        
          <div className="cme-logo">
            <img src="assets/images/logo/CME.png" className="" alt="CME" />
          </div>
          <div className="login-form cmp-login">
            <div className="row medx">
              <div className="col-md-2 MedxLogo">
                <img
                  src="assets/images/logo/svg/MedxLogo.svg"
                  className=""
                  alt="MedxLogo"
                />
              </div>
              <div className="col-md-10">
                <h1 className="text-gray-black title">{props.t("Hello")},</h1>
                <p className="text-gray-black subtitle2 mb-4">
                  {props.t("To-See-You")}
                </p>
              </div>
            </div>
            {errorMsg &&  <p className="text-danger text-small">
                  {errorMsg}
                </p>}
            <form onSubmit={validationType.handleSubmit}>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <img
                    src={config.BASE_URL + "/assets/images/icon2.png"}
                    alt="email"
                    width=""
                    height=""
                  />
                </span>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder={props.t("Email")}
                  onChange={validationType.handleChange}
                  onBlur={validationType.handleBlur}
                  value={validationType.values.email || ""}
                  //   invalid={
                  //     validationType.touched.email && validationType.errors.email
                  //       ? true
                  //       : false
                  //   }
                />
              </div>
              {validationType.touched.email && validationType.errors.email ? (
                <p className="text-danger text-small">
                  {validationType.errors.email}
                </p>
              ) : null}
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <img
                    src={config.BASE_URL + "/assets/images/icon1.png"}
                    alt="License number"
                    width=""
                    height=""
                  />
                </span>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder={props.t("Password")}
                  onChange={validationType.handleChange}
                  onBlur={validationType.handleBlur}
                  value={validationType.values.password || ""}
                  //   invalid={
                  //     validationType.touched.password &&
                  //     validationType.errors.password
                  //       ? true
                  //       : false
                  //   }
                />
              </div>
              {validationType.touched.password &&
              validationType.errors.password ? (
                <p className="text-danger text-small">
                  {validationType.errors.password}
                </p>
              ) : null}
              <button
                className="btn btn-blue btn-block mt-4 mb-3"
                type="submit"
              >
                {props.t("Login")}
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withTranslation()(CompanyLogin);
