import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from 'react-router-dom'
import Footer from '../../components/Layout/Footer'
import Navbar from '../../components/Layout/Navbar'
import { BookingApi } from "../../apis/BookingApi";
import config from "../../config";
import { withTranslation } from "react-i18next";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import { getUser } from "../../Token";
import { ProfileAPI } from "apis/ProfileAPI";
import * as url from "../../helpers/common_helper";

import MobileMedxHistory from "./components/MobileMedxHistory";
import i18n from "../../i18n";


function MyMedxDetails(props) {

    const params = useParams();
    const from_year = params.from_year;
    const to_year = params.to_year;
    const activity_group = params.activity_group;
    const [activeData, setActiveData] = useState({ page: 1, limit: config.LIMIT, from_year: from_year, to_year: to_year, activity_group: activity_group });
    const [activities, setActivity] = useState([]);
    const [noImage, setNoImage] = useState("");

    useEffect(() => {
        getScoreDetails(activeData);
    }, []);

    const getScoreDetails = (activeData) => {
        ProfileAPI.getScoreDetails(activeData)
            .then((res) => {
                if (res.data.success) {
                    setActivity(res.data.data.activites);
                    setNoImage(res.data.data.noImage);
                } else {
                    setActivity([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const user = getUser();
    return (
        <div>
            <Navbar />
            <MobileBottomMenu />
            <>
                <div className="mobile-top d-none">
                    <div className="container">
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-between mob-mt-39">
                                <div>
                                    <NavLink to="/mymedx">
                                        <img
                                            src={config.BASE_URL + "../assets/images/768/arrow-left-w.png"}
                                            className=""
                                            alt=""
                                            width=""
                                            height=""
                                        />
                                    </NavLink>
                                </div>
                                <div>
                                    <h4 className="mb-0">{props.t("My-Profile")}</h4>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Mobile View Top End */}
                <section className="mt-5 d-m-none">
                    <div className="container">
                        <div className="row p-8">
                            <div className="col-md-12">
                                <h1 className="mt-5  mb-3 main-title">{props.t("Profile")}</h1>
                            </div>
                        </div>
                    </div>
                </section>
            </>

            <section className="dashboard top-curve-profile">
                <div className="container">
                    <div className="row p-8">
                        <ProfileSidebar />
                        <div className="col-md-10">
                            <div className="dashboard-inner">
                                <div className="mb-12">
                                    <NavLink to="/mymedx">
                                        <img
                                            src={config.BASE_URL + "../assets/images/left-arrow.png"}
                                            className=""
                                            alt=""
                                            width=""
                                            height=""
                                        />
                                    </NavLink>
                                </div>
                                <div className="row">
                                    {/* Desktop Quiz Listing Start */}
                                    {activities.length !== 0 ? (
                                        <div className="events-listing mt-4 d-m-none list-style-none">
                                            {activities?.map((activity, index) => {
                                                return (<div className="event-item text-center">
                                                    <div className="position-relative main-img mb-2">
                                                        {activity.activity_id == null || activity.Activity == undefined || activity?.Activity?.ActivityMedia.length === 0 ? (
                                                            <img
                                                                className="img-fluid mx-auto"
                                                                src={config.BASE_URL + '/assets/images/default-activity.jpg'}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <NavLink 
                                                                to={'#'}
                                                                // to={'/education/education-activity-details/' + activity.activity_id} 
                                                            >
                                                                <img
                                                                    className="img-fluid mx-auto"
                                                                    src={activity?.Activity?.ActivityMedia[0]?.image_url}
                                                                    alt=""
                                                                />
                                                            </NavLink>
                                                        )}
                                                        <span>{activity?.credit_score} {props.t("Credit")}</span>
                                                    </div>
                                                    {activity.activity_id == null || activity.activity_id == undefined ? (
                                                        <h4>{
                                                            i18n.language == 'en' && activity.title_en ? (
                                                            activity.title_en
                                                            ) : ( activity.title_th)
                                                          }</h4>
                                                    ) : (
                                                        <NavLink 
                                                            to={'#'}
                                                            // to={'/education/education-activity-details/' + activity.activity_id} 
                                                        >
                                                            <h4>{
                                                                i18n.language == 'en' && activity.title_en ? (
                                                                activity.title_en
                                                                ) : ( activity.title_th)
                                                                }</h4>
                                                        </NavLink>
                                                    )}
                                                    <ul className="event-list">
                                                        <li>
                                                            <div>
                                                                <img
                                                                    className="img-fluid"
                                                                    src={config.BASE_URL + "assets/images/clock.png"}
                                                                    alt=""
                                                                    width={12}
                                                                    height={12}
                                                                />
                                                            </div>
                                                            <div>{url.dateFormat(activity.credit_date)}</div>
                                                        </li>

                                                        {activity.Activity?.type && (
                                                            <li>
                                                                <div>
                                                                    <img
                                                                        className="img-fluid "
                                                                        src={config.BASE_URL + "assets/images/location-marker.png"}
                                                                        alt=""
                                                                        width={12}
                                                                        height={12}
                                                                    />
                                                                </div>
                                                                {activity.activity_id != null || activity.activity_id != undefined ? (
                                                                    <div>{activity?.Activity?.type}</div>
                                                                ) : (
                                                                    <div>{props.t("Onsite")}</div>
                                                                )}
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>)
                                            })}
                                        </div>

                                    ) : (
                                        <div className="subtitle text-center d-m-none"></div>
                                    )}

                                    {/* Desktop Quiz Start */}
                                    <>
                                        {/* Mobile Event carousel Start */}

                                        {activities.length !== 0 ? (
                                        // <div className="owl-carousel events-carousel mt-4 px-20 d-none">
                                            <div className="collections  d-none">
                                            {activities?.map((activity, index) => {
                                            return (
                                                <MobileMedxHistory activity={activity} noImage={noImage} key={index} />
                                            )
                                            })}
                                            </div>
                                        // </div>
                                        ) : (
                                            <div className="subtitle text-center d-none">{props.t("No-Activities-Found")}</div>
                                        )}

                                        {/* Mobile Event carousel End */}
                                    </> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default withTranslation()(MyMedxDetails)