import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const QuestionApi = {
  getQuizTypes: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_QUIZ_TYPES}`,
      headers: {
        "Content-Type": "application/json",
      },
      // data: data
    };
    return await axios(axiosConfig);
  },

  fetchActivityQuestions: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_ACTIVITY_QUESTIONS}`,
      headers: {
        "Content-Type": "application/json",
      },
      // data: data
    };
    return await axios(axiosConfig);
  },

  fetchActivityQuizQuestions: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_ACTIVITY_QUIZ_QUESTIONS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  fetchActivityQuestionDetails: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_QUESTION_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  createActivityQuestions: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CREATE_ACTIVITY_QUESTIONS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  updateActivityQuestion: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.UPDATE_ACTIVITY_QUESTION}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  deleteActivityQuestionAnswers: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.DELETE_ACTIVITY_QUESTION_ANSWERS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  submitQuizQuestionAnswerApi: async (payload) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.SUBMIT_QUIZ}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    return await axios(axiosConfig);
  },
};
