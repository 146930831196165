import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
// import { NavLink, Link } from 'react-router-dom';
// import Navbar from "../../components/Layout/Navbar";
// import Footer from "../../components/Layout/Footer";
// import upevent1 from "../../assets/images/right-arrow.png";
import config from "../../config";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { AuthApi } from "apis/AuthApi";
import { Token } from "../../Token";

function SignIn(props) {
  // const doctorOlsSystemLogin = () => {
  //     const response_type = 'code';
  //     const state = 'DtZrxWiQrbapATGqEgwLdCkZr';
  //     const client_id = config.CLIENT_ID;
  //     const scope = 'profile';
  //     const redirect_uri = `${config.BASE_URL}tmc-callback`;

  //     window.location.href = `https://authen.tmc.or.th/oauth/signin?response_type=${response_type}&state=${state}&client_id=${client_id}&scope=${scope}&redirect_uri=${redirect_uri}`
  // }

  const [showPassword, setPasswordShow] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordShow(true);
  };

  const handleClickHidePassword = () => {
    setPasswordShow(false);
  };

  const [errorMsg, setErrorMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape(
      {
        username: Yup.string().email()
          // .typeError(props.t("Only-Number-Message"))
          .required(props.t("Required-Message")),
        password: Yup.string().required(props.t("Required-Message")),
      },
      []
    ),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("username", values.username);
      data.append("password", values.password);
      AuthApi.siginIn(values)
        .then((res) => {
          if (res.data.status) {
            Token.saveToken(res.data.user, res.data.token);
            window.location.href = "/";
          } else {
            setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  });

  return (
    <div>
      <section>
        <div className="blue-circle1" />
          <div className="login-object d-none">
            <img
              src={config.BASE_URL + "/assets/images/doctor_tem.png"}
              alt=""
            />
          </div>
          <img
            alt=""
            src="assets/images/doctor_tem.png"
            className="login-img d-m-none"
          />

          <div className="container">
            <div className="cme-logo">
              <img src="assets/images/logo/CME.png" className="" alt="CME" />
            </div>
            <div className="login-form">
              <div className="row medx">
                <div className="col-md-2 MedxLogo">
                  <img
                    src="assets/images/logo/svg/MedxLogo.svg"
                    className=""
                    alt="MedxLogo"
                  />
                </div>
                <div className="col-md-10">
                  <h1 className="text-gray-black title">{props.t("Hello")},</h1>
                  <p className="text-gray-black subtitle2 mb-4">
                    {props.t("To-See-You")}
                  </p>
                </div>
              </div>
              <br />
              {errorMsg && <p className="text-danger text-small">{errorMsg}</p>}

              <form onSubmit={formik.handleSubmit}>
                <div className="col-md-12 mt-2">
                  {showPassword}
                  <label
                    htmlFor="username"
                    style={{ float: "left" }}
                    className="form-label"
                  >
                    {props.t("Email")} {" "}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="email"
                    className={
                      formik.touched.username &&
                      formik.errors.username
                        ? "form-control bor"
                        : "form-control text-dark"
                    }
                    placeholder={props.t("Email")}
                    name="username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                    style={{ paddingLeft: "20px" }}
                  />
                  {formik.touched.username &&
                  formik.errors.username ? (
                    <p className="text-danger text-small text-left">
                      {formik.errors.username}
                    </p>
                  ) : null}
                </div>

                <div className="form-group mt-2">
                  <label
                    htmlFor="password"
                    style={{ float: "left" }}
                    className="form-label ck"
                  >
                    {props.t("Password")} <span className="text-danger">*</span>
                  </label>
                  <div
                    className={
                      formik.touched.password && formik.errors.password
                        ? "input-group input-password-border border-red"
                        : "input-group input-password-border"
                    }
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      className={
                        formik.touched.password && formik.errors.password
                          ? "form-control bb input-password-text"
                          : "form-control input-password-text"
                      }
                      placeholder={props.t("Password")}
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      style={{ paddingLeft: "20px" }}
                    />
                    {showPassword ? (
                      <div className="input-group-addon fapsicon">
                        <i
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          onClick={handleClickHidePassword}
                        ></i>
                      </div>
                    ) : (
                      <div className="input-group-addon fapsicon">
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          onClick={handleClickShowPassword}
                        ></i>
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  {formik.touched.password && formik.errors.password ? (
                    <p
                      className="text-danger text-small text-left"
                      style={{ marginTop: "1px" }}
                    >
                      {formik.errors.password}
                    </p>
                  ) : null}
                </div>

                <button type="submit" className="btn btn-blue btn-block mt-3">
                  {props.t("Doctor-Login")}
                </button>

                <p className="bottom-text mt-3">
                  {props.t("Don't-Have-Acoount")} {" "}
                    <Link to="/sign-up" className="text-primary">
                      {props.t("Sign-Up")}
                    </Link>
                </p>
                {/* <div className="mt-4">
                  <p className="applyHeading">
                    <span>
                      {props.t("Institution-Apply")}
                    </span>
                  </p>
                </div> */}
                {/* <div className="mt-4 w-100 align-items-center justify-content-between">
                  <div className="row instituteTab">
                    <div className="col-md-6">
                      <Link
                        to="/primary-institute"
                        className="btn btn-outline-primary rounded-pill btn-block mx-1 mb-3 px-3 fw-bolder shadow "
                      >
                        {props.t("Primary-Institute-Btn")}
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <Link
                        to="/sub-institute"
                        href="#!"
                        className="btn btn-outline-primary rounded-pill btn-block mb-3 px-3 fw-bolder shadow"
                      >
                        {props.t("Sub-Institute-Btn")}
                      </Link>
                    </div>
                    <div className="col-md-12">
                      <a
                        href={config.ADMIN_URL}
                        className="btn btn-outline-primary rounded-pill btn-block mb-3 px-3 fw-bolder shadow"
                      >
                        {props.t("adminLogin")}
                      </a>
                    </div>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
      </section>
    </div>
  );
}

export default withTranslation()(SignIn);
