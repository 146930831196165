import React, { useEffect, useState } from "react";
import { NavLink, Link, useParams } from 'react-router-dom';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/Footer';
import { CommunityApi } from "../../apis/CommunityApi";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";


function Gallery(props) {
    const navigate = useNavigate();
    const params = useParams();
    const community_post_id = params.community_post_id;
    const active_index = parseInt(params.active_index);

    const [current, setCurrent] = useState(active_index);
    const [communitypostdetailData, setCommunitypostdetailData] = useState({ community_post_id: community_post_id, page: 1, limit: config.LIMIT });
    const [communitypostdetail, setCommunitypostdetail] = useState([]);

    const getCommunityPostsDetails = (data) => {
        CommunityApi.getCommunityPostsDetails(data)
            .then((res) => {
                if (res.data.success) {
                    setCommunitypostdetail(res.data.data.post.CommunityPostMedia);

                } else {
                    setCommunitypostdetail([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getCommunityPostsDetails(communitypostdetailData);
    }, []);




    const next = () => {
        if (current >= communitypostdetail.length) return
        else setCurrent(current + 1)
    }

    const prev = () => {
        if (current === 0) return
        else setCurrent(current - 1)
    }

    const desired = e => {
        setCurrent(Number(e.target.id))
    }

    return (
        <>
            <Navbar />

            {/* <!-- Mobile View Top Start --> */}
            <div className="mobile-gray-top d-none zi-1">
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between mob-mt-39">
                            <div>
                                <a onClick={() => navigate(-1)}><img src="/assets/images/768/arrow-left.png" className="" alt=""
                                    width="" height="" /></a>
                            </div>
                            <div>
                                <h4 className="mb-0"></h4>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Mobile View Top End --> */}

            {/* <!-- Event / Activity Detail Gallery Start --> */}
            <section className="pb-3 gallery">
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-12 px-30">
                            <a onClick={() => navigate(-1)} className="left-arrow demo cursor poi d-m-none" style={{marginLeft:'13px',}}>
                                <img src="/assets/images/left-arrow.png" className="mr-2 demo cursor" alt="" width=""
                                    height="" /> {props.t("Back")}
                            </a>
                            <div className="slide-gallery mb-3 mx-auto">
                                {(communitypostdetail.length !== 0) ? <div className="text-center">
                                    <img src={communitypostdetail[current].image_url} 
                                        className="center-cropped" style={{position:'relative', zIndex:1, }}
                                    />
                                </div> : ''}

                                <a className="prev" onClick={prev} style={{zIndex:1}}>
                                    <img src="/assets/images/prev.png" alt="" width="" height="" />
                                </a>
                                {(current !== (communitypostdetail.length - 1)) ? <a className="next" onClick={next} style={{zIndex:1}}>
                                    <img src="/assets/images/next.png" alt="" width="" height="" />
                                </a> : ''}
                            </div>
                            <div className="slide-gallery mt-5">
                                <div className="d-flex">
                                    {(communitypostdetail.length !== 0) ? ((communitypostdetail).map((item, num) => {
                                        return (<div className="column" key={num}>
                                            <img className={`demo cursor ${num === current && 'active'}`} src={item.image_url} onClick={desired}
                                                id={num} alt="" />
                                        </div>)
                                    })) : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Event / Activity Detail Gallery Start --> */}
            <Footer />
        </>
    )
}
export default withTranslation()(Gallery)
