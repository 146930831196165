import React from "react";
import { withTranslation } from "react-i18next";
import { Input } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ProfileAPI } from "apis/ProfileAPI";
import FileCard from "./FileCard";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import constants from "constants";
import Select from "react-select";
import { useState } from "react";

const InternshipTaskForm = (props) => {
  const navigate = useNavigate();
  const [selectedEvaluator, setSelectedEvaluator] = useState(
    props?.userTask?.evaluator
  );
  const formik = useFormik({
    initialValues: {
      description: props?.userTask?.submited_details
        ? props?.userTask?.submited_details
        : "",
      attachments: null,
      // evaluator: props?.userTask?.evaluator_id
      //   ? props?.userTask?.evaluator_id
      //   : "",
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required(props.t("Required-Message")),
      // evaluator: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      if (values.attachments != null) {
        for (let i = 0; i < values.attachments.length; i++) {
          data.append("attachments", values.attachments[i]);
        }
      }
      data.append("description", values.description);
      data.append("userTaskID", props?.userTask?.id);
      data.append("activityId", props?.userTask?.activity_id);
      // data.append("evaluatorId", values?.evaluator);

      ProfileAPI.submitInternshipTaskToEvaluatorApi(data)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              text: "Task Submitted",
              icon: "success",
              imageAlt: "success image",
            }).then((result) => {
              navigate("/mycalendar");
            });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
          } else {
            err.response.data.error.errors?.map((erritem, errindex) => {});
          }
        });
    },
  });

  const removeAttachment = (lastModified) => {
    const updatedList = [
      ...formik.values.attachments.filter(
        (e) => e.lastModified !== lastModified
      ),
    ];
    formik.setFieldValue(
      "attachments",
      updatedList.length > 0 ? updatedList : null
    );
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div
          className="dashboard-inner application-form"
          style={{ minHeight: "auto" }}
        >
          <div className="row">
            <div className="col-md-6">
              <h2 className="mb-3">
                {props?.userTask?.status === "Approved"
                  ? ""
                  : props.t("taskSubmissionForm")}
              </h2>
            </div>
          </div>
          <div className="form-group mb-4">
            {props?.userTask?.Activity?.approval_status ===
              constants.APPROVAL_STATUS.Rejected && (
              <>
                {/* <div className="form-group">
                  <label className="form-label">{props.t("Status")}</label> {" : "} 
                  <label className="form-label" style={{color: "#f46a6a",}}>
                    {props?.userTask?.Activity?.approval_status}
                  </label>
                </div> */}
                <div className="form-group">
                  <label className="form-label">
                    {props.t("Rejected")} {props.t("Remark")}
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label" style={{ color: "#f46a6a" }}>
                    {props?.userTask?.Activity?.remark}
                  </label>
                </div>
              </>
            )}
          </div>
          <div className="form-group mb-4">
            <label className="form-label">
              {props?.userTask?.status === "Approved"
                ? props.t("advise")
                : props.t("Task-Description")}{" "}
              <span className="text-danger">*</span>
            </label>
            <textarea
              type="text"
              rows={5}
              className="form-control  w-100"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              style={{ height: "unset" }}
              readOnly={
                props?.userTask?.status === "Approved" ||
                props?.userTask?.status === "Submited"
                  ? "readOnly"
                  : ""
              }
            />
            {formik.touched.description && formik.errors.description && (
              <div
                style={{ color: "#f46a6a", fontSize: "11px", marginTop: "3px" }}
              >
                {formik.errors.description}
              </div>
            )}
          </div>
          {props?.userTask?.status !== "Approved" && (
            <div className="form-group mb-4">
              <label className="form-label">{props.t("Attachment")}</label>
              <div className="fileupload">
                {props?.userTask?.status !== "Pending" ||
                  (props?.userTask?.status !== "Rejected" && (
                    <div className="text-center">
                      <label>
                        <a
                          className="btn btnUpload"
                          role="button"
                          aria-disabled="false"
                        >
                          <img src="/assets/images/preview.png" />
                          <br />
                          <strong className="text-blue">
                            {props.t("Upload")} {props.t("Attachments")}
                          </strong>
                          <br />
                          <strong className="note">
                            PNG, JPG, GIF, PDF, DOCX
                          </strong>
                        </a>
                        <Input
                          name="attachments"
                          type="file"
                          multiple
                          className="form-control upload__inputfile"
                          onChange={(e) => {
                            const filesArray2 = [];
                            for (let i = 0; i < e.target.files.length; i++) {
                              filesArray2.push(e.target.files[i]);
                            }
                            formik.setFieldValue(
                              "attachments",
                              formik.values.attachments
                                ? [...formik.values.attachments, ...filesArray2]
                                : filesArray2
                            );
                          }}
                          invalid={
                            formik.touched.attachments &&
                            formik.errors.attachments
                              ? true
                              : false
                          }
                        />
                        {/* {formik.touched.attachments &&
                      formik.errors.attachments && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.attachments}
                        </div>
                      )} */}
                      </label>
                    </div>
                  ))}
                <div className="pdf-block list-style-none">
                  <ul>
                    {props?.userTask?.TaskAttachment?.length > 0
                      ? props?.userTask?.TaskAttachment.map((item, index) => {
                          return (
                            <li key={index}>
                              <div className="d-flex align-items-center mb-3 row">
                                <div className="col-1">
                                  <img
                                    src="/assets/images/link.png"
                                    alt="link"
                                    width="15"
                                  />
                                </div>
                                <div className="col-10 text">
                                  <a
                                    href={item.file_url}
                                    target="_blank"
                                    className="text-blue"
                                  >
                                    {item.title_en}
                                  </a>
                                </div>
                                {/* <div className="col-1">
                                <a
                                  onClick={() =>
                                    removeMedia(
                                      item.activity_id,
                                      item.id,
                                      "FILE",
                                      index
                                    )
                                  }
                                  title="Remove"
                                >
                                  <img
                                    src={require("assets/images/delete-icon.png")}
                                    width="15"
                                  />
                                </a>
                              </div> */}
                              </div>
                            </li>
                          );
                        })
                      : null}
                    {formik.values.attachments?.length > 0
                      ? formik.values.attachments?.map((item, index) => {
                          return (
                            <li>
                              <div className="d-flex align-items-center mb-3 row">
                                <div className="col-1">
                                  <img
                                    src="/assets/images/link.png"
                                    alt="link"
                                    width="15"
                                  />
                                </div>
                                <div className="col-10 text">
                                  <a
                                    href="#"
                                    target="_blank"
                                    className="text-blue"
                                  >
                                    {item.name}
                                  </a>
                                </div>
                                <div className="col-1">
                                  <a
                                    title="Remove"
                                    onClick={(e) => {
                                      removeAttachment(item.lastModified);
                                    }}
                                  >
                                    <img
                                      src={require("assets/images/delete-icon.png")}
                                      width="15"
                                    />
                                  </a>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          )}

          {props?.userTask?.status === "Approved" && (
            <div className="pdf-block">
              <label className="form-label">{props.t("Attachments")}</label>
              <ul>
                {props?.userTask?.TaskAttachment?.map((item, index) => {
                  return <FileCard key={`activityM_${index}`} item={item} />;
                })}
              </ul>
            </div>
          )}

          {/* <div className="form-group mb-4">
            <label className="form-label">
              {props.t("evaluator")} <span className="text-danger">*</span>
            </label>
            <Select
              onChange={(e) => {
                formik.setFieldValue("evaluator", e?.value);
                setSelectedEvaluator(e);
              }}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderRadius: "50px",
                }),
              }}
              value={selectedEvaluator || ""}
              name="evaluator"
              className={
                formik.touched.evaluator && formik.errors.evaluator
                  ? " bor"
                  : " text-dark"
              }
              isDisabled={props?.userTask?.status === "Pending" ? false : true}
              options={props.taskEvaluators}
            />
            {formik.touched.evaluator && formik.errors.evaluator && (
              <div
                style={{
                  color: "#f46a6a",
                  fontSize: "11px",
                  marginTop: "3px",
                }}
              >
                {formik.errors.evaluator}
              </div>
            )}
          </div> */}
        </div>
        {(props?.userTask?.status == "Pending" ||
          props?.userTask?.status == "Rejected") && (
          <div
            className="justify-content-between align-items-center mt-5 mob-mt-2"
            style={{ marginBottom: "120px" }}
          >
            <div className="float-right">
              <button type="submit" className="btn btn-join">
                {props.t("Submit")}
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default withTranslation()(InternshipTaskForm);
