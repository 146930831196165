import { ActionTypes } from "../constants/home.actiontypes";

const initialState = {
    banner: [],
    loading: false,
    setLoading: false,
};

export const homeReducers = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_BANNER:
            return {
                ...state,
                banner: action.payload,
                loading: false,
            };
        case ActionTypes.GET_UPCOMING_ACTIVITY:
            return {
                ...state,
                upactivity: action.payload,
                loading: false,
            };
        case ActionTypes.GET_NEWS:
            return {
                ...state,
                news: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};