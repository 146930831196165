import { Link, Outlet } from "react-router-dom"
import { isLoggedIn } from "../../Token";

const PrivateRoute= () => {


    if (isLoggedIn()) {
        return <Outlet />
    }

    return (
        <div className="flex flex-1 justify-center items-center flex-col h-screen">
            <h2 className="text-3xl font-bold text-white bg-black rounded-lg px-5 py-1">Page Not Found</h2>
            <Link to="/" className="text-md text-blue-500 cursor-pointer mt-5"> Go To Home </Link>
        </div>
    )
}

export default PrivateRoute