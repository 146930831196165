import QuizQuestionList from "pages/ActivityJson/QuizQuestionList";
import { withTranslation } from "react-i18next";
import config from "../../../config";

const TaskDetailModal = (props) => {
  let taskAttachments = props.data?.UserTaskActivity?.TaskAttachment;
  return (
    <div className="modal fade in" id="taskDetailsmodal">
      <div className="modal-dialog scrollDialog modal-lg">
        <div className="modal-content mb-5">
          <div className="modal-header">
            <h4 className="modal-title">{props.t("taskDetail")}</h4>
          </div>
          <div className="modal-body scrollAndFixedHeight">
            <div className="row">
              {props?.data?.UserTaskActivity?.submited_details && (
                <>
                  <div className="col-md-12">
                    <h6>{props.t("Description")}</h6>
                  </div>
                  <div className="col-md-12">
                    <p>{props?.data?.UserTaskActivity?.submited_details}</p>
                  </div>
                </>
              )}

              {props?.data?.UserTaskActivity?.amount_of_examinee && (
                <>
                  <div className="col-md-12">
                    <h6>{props.t("amountOfExaminee")}</h6>
                  </div>
                  <div className="col-md-12">
                    <p>{props?.data?.UserTaskActivity?.amount_of_examinee}</p>
                  </div>
                </>
              )}
              {props?.data?.UserTaskActivity?.amount && (
                <>
                  <div className="col-md-12">
                    <h6>{props.t("amount")}</h6>
                  </div>
                  <div className="col-md-12">
                    <p>{props?.data?.UserTaskActivity?.amount}</p>
                  </div>
                </>
              )}
            </div>
            {props?.quizQuestions?.length > 0 && (
              <div>
                <div className="col-md-12">
                  <h6>{props.t("Quiz")}</h6>
                </div>
                <QuizQuestionList
                  from="Edit"
                  onEditQuizQuestionHanlder=""
                  onDeletetQuizQuestionHanlder=""
                  isInstituteFiledEnable={false}
                  questions={props?.quizQuestions}
                />
              </div>
            )}
            {props?.data?.remark && (
              <div className="row">
                <div className="col-md-12">
                  <h6>{props.t("Remark")}</h6>
                </div>

                <div className="col-md-12">
                  <p>{props?.data?.remark}</p>
                </div>
              </div>
            )}
            {Array.isArray(taskAttachments) && taskAttachments.length > 0 && (
              <div className="row">
                <div className="col-md-12 mb-2">
                  <h6> {props.t("referenceFile")}</h6>
                </div>
                <div className="col-sm-12">
                  <div className="pdf-block">
                    <ul>
                      {taskAttachments.map((item, index) => {
                        return (
                          <a
                            href={`${config.IMAGE_URL}${item.file_url}`}
                            target="_blank"
                          >
                            <div className="pblock3 d-flex align-items-center mb-3">
                              <div className="image">
                                <img src="/assets/images/link.png" alt="link" />
                              </div>
                              <div className="text">
                                <strong>{item.title_en}</strong>
                              </div>
                            </div>
                          </a>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(TaskDetailModal);
