import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const CommunityApi = {
  getCommunityNameNameByKeyword: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_COMMUNITY_NAME_BY_KEYWORD}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getCommunityPosts: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_COMMUNITY_POSTS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getCommunityPostsDetails: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_COMMUNITY_POSTS_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  postCommunityPostComment: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ADD_COMMUNITY_POST_COMMENT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  postCommunityEditPostComment: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.EDIT_COMMUNITY_POST_COMMENT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  postCommunityPostCommentLike: async (data) => {
    console.log(data);
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ADD_COMMUNITY_POST_COMMENT_LIKE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  deleteComment: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ADD_COMMUNITY_POST_COMMENT_DELETE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },

  CommunityPostsLikes: async (data) => {
    console.log(data);
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ADD_COMMUNITY_POST_LIKE_UNLIKE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },

  getCommunityTopic: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_COMMUNITY_TOPIC}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getNewsTopic: async (data) => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_COMMUNITY_NEWS_TOPIC}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getCreateCommunityPost: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_CREATE_COMMUNITY_POST}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getUpdateCommunityPost: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_UPDATE_COMMUNITY_POST}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  deleteFile: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_REMOVE_COMMUNITY_POST_FILE}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getCommunityPostsByUser: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_COMMUNITY_POSTS_BY_USER}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  removeCommunity: async (id) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_REMOVE_COMMUNITY_POST}/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(axiosConfig);
  },
  getDconnectCommunityPostDetails: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_DCONNECT_COMMUNITY_POST_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
};
