import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
// import { NavLink, Link } from 'react-router-dom';
// import Navbar from "../../components/Layout/Navbar";
// import Footer from "../../components/Layout/Footer";
// import upevent1 from "../../assets/images/right-arrow.png";
import config from "../../config";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { AuthApi } from "apis/AuthApi";
import { Token } from "../../Token";

function ForgotPassword(props) {
  const [showPassword, setPasswordShow] = useState(false);
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState("");
  const [forgetError, setForgetError] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordShow(true);
  };

  const handleClickHidePassword = () => {
    setPasswordShow(false);
  };

  const [errorMsg, setErrorMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      license_number: "",
      password: "",
    },
    validationSchema: Yup.object().shape(
      {
        license_number: Yup.number()
          .typeError(props.t("Only-Number-Message"))
          .required(props.t("Required-Message"))
      },
      []
    ),
    onSubmit: (values) => {
        setShowLoader(true);
      const data = new FormData();
      data.append("license_number", values.license_number);
      AuthApi.doctorForgotPassowrd(values)
        .then((res) => {
            if (res.data.success) {
                setForgetError("")
                setForgetSuccessMsg(res.data.message);
                setShowLoader(false);
            } else { 
            setForgetSuccessMsg("")
            setForgetError(res.data.message);
            setShowLoader(false);
            }
        })
        .catch((err) => {
            if (err?.response?.data?.message) {
                //toastr.error(error?.response?.data?.message, "")
              } else {
                err.response.data.error.errors?.map((erritem, errindex) => {
                  if (erritem.param == "license_number") {
                    formik.setErrors({ license_number: `${erritem.msg}` });
                  }
                });
              }
      })
    },
  });

  return (
    <div>
      <section>
        
        <div className="blue-circle1" />
        <div className="login-object d-none">
          <img
            src={config.BASE_URL + "/assets/images/doctor_tem.png"}
            alt=""
            width=""
            height=""
          />
        </div>
        <img
          src="assets/images/doctor_tem.png"
          className="login-img d-m-none"
        />

        <div className="container">
          <div className="d-none">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                <Link to="/">
                    <img src={config.BASE_URL + "/assets/images/768/arrow-left.png"} alt="" />
                  </Link>
                </div>
                <div>
                  <h4 className="mb-0">{props.t("Forgot-Password")}</h4>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          </div>
        
          

      
          <div className="cme-logo">
            <img src="assets/images/logo/CME.png" className="" alt="CME" />
          </div>
          
          <div className="login-form update-password-form">
            <div className="row medx">
              <div className="col-md-2 MedxLogo">
                <img
                  src="assets/images/logo/svg/MedxLogo.svg"
                  className=""
                  alt="MedxLogo"
                />
              </div>
              <div className="col-md-10">
                <h1 className="text-gray-black title">{props.t("Hello")},</h1>
                <p className="text-gray-black subtitle2 mb-4">
                  {props.t("To-See-You")}
                </p>
              </div>
            </div>
            <br />

            {forgetError && forgetError ? (
                 <p className="text-danger text-small">{forgetError}</p>
            ) : null}
            {forgetSuccessMsg ? (
                <p className="text-success text-small">{forgetSuccessMsg}</p>
            ) : null}

            <form onSubmit={formik.handleSubmit}>
              <div className="col-md-12">
               
                <label
                  htmlFor="license_number"
                  style={{ float: "left" }}
                  className="form-label"
                >
                  {props.t("License-Number")}{" "}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className={
                    formik.touched.license_number &&
                    formik.errors.license_number
                      ? "form-control bor"
                      : "form-control text-dark"
                  }
                  id=""
                  placeholder={props.t("License-Number")}
                  name="license_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.license_number}
                  style={{ paddingLeft: "20px" }}
                />
                {formik.touched.license_number &&
                formik.errors.license_number ? (
                  <p className="text-danger text-small text-left">
                    {formik.errors.license_number}
                  </p>
                ) : null}
              </div>

            

              <button type="submit" className="btn btn-blue btn-block mt-3">
              {showLoader && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>} 
                {props.t("Submit")}
              </button>

            
            
            </form>
          </div>
        </div>

        {/* <img src="assets/images/logo/scb_logo.jpg" className="login-bottom-img d-m-none"/>
                
                <img src="assets/images/logo/scb_mob-logo.png" className="login-bottom-img d-none"/> */}
      </section>
    </div>
  );
}

export default withTranslation()(ForgotPassword);
