import { ProfileAPI } from "apis/ProfileAPI";
import Navbar from "components/Layout/Navbar";
import ProfileSidebar from "components/Layout/ProfileSidebar";
import constants from "constants";
import { useFormik } from "formik";
import AddEditQuizModal from "pages/Components/Modal/AddEditQuizModal";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input } from "reactstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import * as url from "../../helpers/common_helper";
import { getUser } from "../../Token";
import AdwiseUserTaskForm from "./AdwiseUserTaskForm";
import EvaluationReviewCard from "./EvaluationReviewCard";
import FileCard from "./FileCard";
import InternshipTaskForm from "./InternshipTaskForm";
import TeacherEvaluationForm from "./TeacherEvaluationForm";
import UserTaskApproved from "./UserTaskApproved";
import { CommanApi } from "apis/CommanApi";
import EvaluationFormDetails from "./EvaluationFormDetails";
import toastr from "toastr";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const UserTaskDetail = (props) => {
  const user = getUser();
  const navigate = useNavigate();
  const [userTask, setUserTask] = useState({});
  const [evaluationFormId, setEvaluationFormId] = useState(null);
  const [evaluationReviewDetail, setEvaluationReviewDetail] = useState(null);
  const [examTakerTaskDetail, setExamTakerTaskDetail] = useState(null);
  const [taskEvaluators, setTaskEvaluiators] = useState([]);
  const [teacherEvaluationForm, setTeacherEvaluationForm] = useState();
  const [teacherEvaluationFormDetails, setTeacherEvaluationFormDetails] =
    useState([]);
  const [msfEvaluationForm, setMsfEvaluationForm] = useState();
  const [hodEvaluationForm, setHodEvaluationForm] = useState();
  const [directorEvaluationForm, setDirectorEvaluationForm] = useState();
  const [isQuizExist, setIsQuizExist] = useState(false);
  const [isTeacherQuizApproved, setIsTeacherQuizApproved] = useState(false);
  const [teahcerQuizQuestions, setTeahcerQuizQuestions] = useState([]);
  const [internSubmittedResult, setInternSubmittedResult] = useState();
  const [checkTaskSubmittedToHod, setCheckTaskSubmittedToHod] = useState(false);
  const [teacherEvaluationResult, setTeacherEvaluationResult] = useState([]);
  const [internTeacherReportResult, setInternTeacherReportResult] = useState();
  const [isEvaluationFeedBackFromHod, setIsEvaluationFeedBackFromHod] = useState(false);
  
  const params = useParams();
  const userTaskID = atob(params.userTaskID);
  const [latitude, setLatitude] = useState(false);
  const [longitude, setLongitude] = useState(false);
  const [checkInDistance, setCheckInDistance] = useState("");
  const [error, setError] = useState("");

  const [addedQuizCount, setAddedQuizCout] = useState(0);
  const [quizTypeDetail, setQuizTypeDetail] = useState([]);
  const [isMsfResultExist, setIsMsfResultExist] = useState(false);
  const [isHodResultExist, setIsHodResultExist] = useState(false);
  const [quizQuetions, setQuizQuestions] = useState([]);

  let roleID;

  useEffect(() => {
    getUserTaskDetail();
    getSystemSetting();
    geolocation();
  }, []);

  const getUserTaskDetail = () => {
    ProfileAPI.getUserTaskDetailApi(userTaskID)
      .then((res) => {
        if (res.data.success) {
          const userTask = res.data.userTask;
          setUserTask(userTask);
          setTeahcerQuizQuestions(res.data.teacherQuizQuestions);
          setQuizQuestions(res.data.teacherQuizQuestions);
          setEvaluationReviewDetail(res.data.evaluationReviewDetail);
          setExamTakerTaskDetail(res.data.examTakerTaskDetail);
          setTeacherEvaluationForm(res.data.teacherEvaluationForm);
          setTeacherEvaluationFormDetails(
            res.data.teacherEvaluationFormDetails
          );
          setIsEvaluationFeedBackFromHod(res.data.isEvaluationFeedBackFromHod);
          setCheckTaskSubmittedToHod(res.data.checkTaskSubmittedToHod);
          setTeacherEvaluationResult(res.data.teacherEvaluationResult);
          setInternTeacherReportResult(res.data.internTeacherReportResult);
          setInternSubmittedResult(res.data.internSubmittedResult);
          setIsMsfResultExist(res.data.msfResults.length > 0 ? true : false);
          setIsHodResultExist(res.data.hodResults.length > 0 ? true : false);
          setMsfEvaluationForm(res.data.msfEvaluationForm);
          setHodEvaluationForm(res.data.hodEvaluationForm);
          setDirectorEvaluationForm(res.data.directorEvaluationForm);
          setIsQuizExist(res.data.isQuizExist);
          setIsTeacherQuizApproved(res.data.isTeacherQuizApproved);
          if (userTask.task_type_id === constants.TASKTYPE.ROTATIONAL) {
            const payload = {
              sessionId:
                userTask?.Activity?.ActivitySubDetail?.internship_session_id,
              sessionTasktypeId:
                userTask?.Activity?.ActivitySubDetail?.session_task_id,
            };
            getTaskEvaluatorsList(payload);
          }
          setEvaluationFormId(
            res.data.userTask?.Activity?.ActivityEvaluation[0]
              ?.evaluation_form_id
          );
        } else {
          setUserTask({});
          setEvaluationReviewDetail(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTaskEvaluatorsList = (data) => {
    ProfileAPI.getInternshipTaskEvaluatorsApi(data)
      .then((res) => {
        if (res.data.success) {
          setTaskEvaluiators(res.data.data.rows);
        } else {
          setTaskEvaluiators([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSystemSetting = () => {
    const payload = {
      config_key: "check_in_distance",
    };
    CommanApi.getSystemSetting(payload)
      .then((res) => {
        if (res.data.success) {
          setCheckInDistance(res.data?.data?.config_value);
        } else {
          setCheckInDistance("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: userTask ? userTask?.amount : "",
      description: userTask?.submited_details ? userTask.submited_details : "",
      attachments: null,
    },
    validationSchema: Yup.object().shape({
      // description: Yup.string().required(props.t("Required-Message")),
      amount: Yup.string().required(props.t("Required-Message")),
      // attachments: Yup.mixed().required(props.t("File-Required-Message")),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      if (values.attachments != null) {
        for (let i = 0; i < values.attachments.length; i++) {
          data.append("attachments", values.attachments[i]);
        }
      }
      data.append("quizQuestions", JSON.stringify(quizQuetions));
      data.append("description", values.description);
      data.append("amount", values.amount);
      data.append("user_task_id", userTask.id);

      ProfileAPI.submitQuizQuestions(data)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then((result) => {
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
          } else {
            err.response.data.error.errors?.map((erritem, errindex) => {});
          }
        });
    },
  });

  const formClear = () => {
    formik.resetForm({ values: { attachments: null } });
  };

  const onAddViewWuizHandler = () => {
    window.$("#addEditQuizModal").modal("show");
  };

  let statusStyle = {};

  if (userTask?.status === "Approved") {
    statusStyle.color = "#2A9C21";
  } else if (userTask?.status === "Rejected") {
    statusStyle.color = "#D10E0E";
  } else if (userTask?.status === "Pending") {
    statusStyle.color = "#FE951B";
  } else if (userTask?.status === "Submited") {
    statusStyle.color = "#00B7C5";
  }

  const onMarkTaskCompletedHandler = async (userTaskID) => {
    ProfileAPI.markTaskCompletedApi({ userTaskID })
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            text: res.data.message,
            icon: "success",
            imageAlt: "success image",
          }).then((result) => {
            navigate("/mycalendar");
          });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const geolocation = () => {
    // if ("geolocation" in navigator) {
    //   console.log("Geolocation Available");
    // } else {
    //   console.log("Geolocation Not Available");
    // }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log(error);
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 10000 }
      );
    }
  };

  const checkInCheckOut = async (userTaskID) => {
    let payload = {
      user_task_id: userTaskID,
      latitude: latitude,
      longitude: longitude,
    };

    if (
      latitude !== false &&
      longitude !== false &&
      latitude !== "" &&
      latitude !== null &&
      longitude !== "" &&
      longitude !== null
    ) {
      let geoData = {
        srcLatitude: parseFloat(userTask?.Activity?.latitude),
        srcLongitude: parseFloat(userTask?.Activity?.longitude),
        destLatitude: latitude,
        destLongitude: longitude,
      };
      // if(url.calculateDistance(geoData) && (parseFloat(url.calculateDistance(geoData)) <= parseFloat(checkInDistance))) {
      ProfileAPI.checkInCheckOut(payload)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then((result) => {
              getUserTaskDetail();
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // } else {
      //   Swal.fire({
      //     text: "Your are out of the range",
      //     icon: "error",
      //     imageAlt: "error image",
      //   }).then((result) => {
      //     getUserTaskDetail();
      //   });
      // }
    } else {
      Swal.fire({
        text: "Please allow your device location !",
        icon: "error",
        imageAlt: "error image",
      }).then((result) => {
        getUserTaskDetail();
      });
    }
  };

  const onSendEvaluationToHODHandler = async () => {
    const payload = {
      internshipTaskTypeId:
        userTask?.Activity?.ActivitySubDetail?.session_task_id,
      internshipSessionId:
        userTask?.Activity?.ActivitySubDetail?.internship_session_id,
      userTaskId: userTask?.id,
    };
    ProfileAPI.sendEvaluationTaskToHodApi(payload)
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            text: res.data.message,
            icon: "success",
            imageAlt: "success image",
          }).then(() => {
            window.location.reload();
          });
        } else {
          toastr.error(res?.data?.message, "");
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "");
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          );
        }
      });
  };

  return (
    <div>
      {/* <!-- Navbar Start --> */}
      <Navbar />
      {/* <!-- Navbar End --> */}
      <ScrollToTopOnMount />
      {/* <!-- Mobile View Top Start --> */}
      <div className="mobile-gray-top d-none">
        <div className="container">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between mob-mt-39">
              <div>
                <Link to="/mycalendar">
                  <img src="/assets/images/768/arrow-left.png" alt="" />
                </Link>
              </div>
              <div>
                <h4 className="mb-0">{props.t("userTaskDetail")}</h4>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Mobile View Top End --> */}

      <section className="mt-5  d-m-none">
        <div className="container">
          <div className="row p-8">
            <div className="col-md-12">
              <h1 className="mt-5  mb-3 main-title">{props.t("Profile")}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="dashboard top-curve2 mob-brd-radius-0">
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />
            <div className="col-md-10">
              {/* <div className="row">
                <div className="col-md-12">
                  <h2 className="mb-3 d-m-none">{props.t("userTaskDetail")}</h2>
                </div>
              </div> */}
              <div
                className="dashboard-inner mb-3"
                style={{ minHeight: "auto" }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <h2 className="mb-5 d-m-none">
                      {props.t("userTaskDetail")}{" "}
                      {/* {userTask?.type === "TEACHER" && (
                        <span>(Review Task and Submit Evaluation)</span>
                      )} */}
                    </h2>
                  </div>
                  {userTask?.type === "INTERN" && (
                    <div className="col-md-6 mb-4">
                      {userTask?.attendance_type === "CHECK-IN" && (
                        <Link
                          href="javascript:void();"
                          onClick={() => checkInCheckOut(userTask.id)}
                          style={{
                            marginRight: "10px",
                            textDecoration: "none",
                          }}
                          className="taskDetailCredit"
                        >
                          {props.t("Check-Out")}
                        </Link>
                      )}
                      {userTask?.attendance_type === null && (
                        <Link
                          href="javascript:void();"
                          onClick={() => checkInCheckOut(userTask.id)}
                          style={{
                            marginRight: "10px",
                            textDecoration: "none",
                          }}
                          className="taskDetailCredit"
                        >
                          {props.t("Check-In")}
                        </Link>
                      )}
                      {/* <span
                        style={{ marginRight: "10px" }}
                        className="taskDetailCredit"
                      >
                        0 {props.t("Credit")}
                      </span> */}
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="position-relative ">
                      <div className="row mb-3">
                        <div className="col-md-3 col-4">
                          <h6> {props.t("Activity-Name")}</h6>
                        </div>
                        <div className="col-md-9 col-8">
                          <h6>
                            {": "}
                            {(userTask?.type === "MSF" ||
                              userTask?.type === "DIRECTOR") &&
                            userTask?.task_type_id !==
                              constants.TASKTYPE.CHECKRESULT
                              ? props.t("fillEvaluationForm")
                              : userTask?.Activity?.title_en}
                          </h6>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-3 col-4">
                          <h6> {props.t("taskName")}</h6>
                        </div>
                        <div className="col-md-9 col-8">
                          <h6>
                            {": "}
                            {(userTask?.type === "TEACHER" ||
                              userTask?.type === "MSF" ||
                              userTask?.type === "HOD" ||
                              userTask?.type === "DIRECTOR") &&
                            userTask?.task_type_id !==
                              constants.TASKTYPE.CHECKRESULT
                              ? props.t("reviewTaskAndSubmitEvaluation")
                              : userTask?.Activity?.ActivitySubDetail
                                  ?.sessionTaskName
                              ? userTask?.Activity?.ActivitySubDetail
                                  ?.sessionTaskName
                              : userTask?.TaskType?.name_en}
                          </h6>
                        </div>
                      </div>

                      {userTask?.task_type_id ===
                        constants.TASKTYPE.INTERVIEWTEST &&
                        userTask.Activity.ActivitySubDetail
                          ?.amount_of_examinee && (
                          <div className="row mb-3">
                            <div className="col-md-3 col-4">
                              <h6> {props.t("amountOfExaminee")}</h6>
                            </div>
                            <div className="col-md-9 col-8">
                              <h6>
                                {": "}{" "}
                                {
                                  userTask.Activity.ActivitySubDetail
                                    ?.amount_of_examinee
                                }
                              </h6>
                            </div>
                          </div>
                        )}

                      {userTask?.task_type_id ===
                        constants.TASKTYPE.WATCHEXAM &&
                        examTakerTaskDetail?.amount && (
                          <div className="row mb-3">
                            <div className="col-md-3 col-4">
                              <h6> {props.t("Amount")}</h6>
                            </div>
                            <div className="col-md-9 col-8">
                              <h6>
                                {": "} {examTakerTaskDetail?.amount}
                              </h6>
                            </div>
                          </div>
                        )}

                      {userTask.type !== 'TEACHER' ? <div className="row mb-3">
                        <div className="col-md-3 col-4">
                          <h6>{props.t("requestBy")}</h6>
                        </div>
                        <div className="col-md-9 col-8">
                          <h6>
                            {" "}
                            {": "}{" "}
                            {`${userTask?.createdBy?.first_name} ${userTask?.createdBy?.last_name}`}
                          </h6>
                        </div>
                      </div> : ''}

                      {userTask?.internName  !== null ? <div className="row mb-3">
                        <div className="col-md-3 col-4">
                          <h6>{props.t("internName")}</h6>
                        </div>
                        <div className="col-md-9 col-8">
                          <h6>
                            {": "}
                            {userTask?.internName}
                          </h6>
                        </div>
                      </div> : ""}

                      <div className="row mb-3">
                        <div className="col-md-3 col-4">
                          <h6>{props.t("taskDate")} </h6>
                        </div>
                        <div className="col-md-9 col-8">
                          <h6>
                            {" "}
                            {": "} {url.dateFormat(userTask?.start_date_time)}
                          </h6>
                        </div>
                      </div>

                      {userTask?.type !== "TEACHER" &&
                        userTask?.type !== "MSF" && (
                          <div className="row mb-3">
                            <div className="col-md-3 col-4">
                              <h6>{props.t("Description")} </h6>
                            </div>
                            <div className="col-md-19 col-8 event-content">
                              <p>
                                {": "} {userTask?.Activity?.description_en}
                              </p>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {userTask?.Activity?.ActivityMediaImage.length > 0 && (
                  <div className="row">
                    <div>
                      <h6> {props.t("referenceFile")}</h6>
                    </div>
                    <div className="pdf-block">
                      <ul>
                        {userTask?.Activity?.ActivityMediaImage?.map(
                          (item, index) => {
                            return (
                              <FileCard
                                key={`activityM_${index}`}
                                item={item}
                              />
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                )}

                {(isMsfResultExist || isHodResultExist) && (
                  <div className="justify-content-between mb-5 align-items-center">
                    <div className="float-right">
                      <Link
                        to={`/checkresults`}
                        state={{
                          internshipSessionId: null,
                          activityId: userTask.activity_id,
                          type: isMsfResultExist ? ["MSF"] : ["MSF", "HOD"],
                          resultFor: "director",
                        }}
                        className="btn btn-join"
                      >
                        {props.t("checkResult")}
                      </Link>
                    </div>
                  </div>
                )}
              </div>

              {evaluationReviewDetail &&
                userTask?.TaskType?.id !==
                  constants.TASKTYPE.EVALUATIONFORMREVIEW && (
                  <EvaluationReviewCard
                    evaluationReviewDetail={evaluationReviewDetail}
                  />
                )}

              {userTask.task_type_id !== constants.TASKTYPE.ROTATIONAL &&
                userTask.task_type_id !==
                  constants.TASKTYPE.TASKREVIEWANDSUBMITEVALUATION &&
                userTask?.Activity?.UserEvaluation.length > 0 && (
                  <div
                    className="dashboard-inner mb-3"
                    style={{ minHeight: "auto" }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="mb-5 d-m-none">
                          {props.t("Evaluation-Details")}
                        </h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="position-relative ">
                          {Array.isArray(userTask?.Activity?.UserEvaluation) &&
                            userTask?.Activity?.UserEvaluation.length > 0 && (
                              <div className="row">
                                <div className="col-md-12 mb-2 d-none">
                                  <h6> {props.t("Evaluation-Details")}</h6>
                                </div>
                                {userTask?.Activity?.UserEvaluation.map(
                                  (item, index) => {
                                    return (
                                      <div className="row mb-3">
                                        <div className="col-md-3 col-4">
                                          <h6>
                                            {" "}
                                            {
                                              item?.UserEvalutionFormField
                                                ?.label_en
                                            }
                                          </h6>
                                        </div>
                                        <div className="col-md-9 col-8">
                                          <h6>
                                            {": "} {item.field_value}
                                          </h6>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                                {isEvaluationFeedBackFromHod ? <div className="col-sm-12">
                                <span className="text-green">
                                  {props.t("yourEvaluationFormApproved")}
                                </span>
                                
                                </div> : ""}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {teacherEvaluationResult.length > 0 && (
                <EvaluationFormDetails
                  evaluationFormDetails={teacherEvaluationResult}
                  internSubmittedResult={internTeacherReportResult}
                  onSendEvaluationToHOD={onSendEvaluationToHODHandler}
                  checkTaskSubmittedToHod={checkTaskSubmittedToHod}
                  title={props.t("teacherAndInternResult")}
                  type="teacherIntern"
                />
              )}
              {teacherEvaluationFormDetails.length > 0 && (
                <EvaluationFormDetails
                  evaluationFormDetails={teacherEvaluationFormDetails}
                  internSubmittedResult={internSubmittedResult}
                  onSendEvaluationToHOD={onSendEvaluationToHODHandler}
                  checkTaskSubmittedToHod={checkTaskSubmittedToHod}
                  title={props.t("Evaluation-Details")}
                  type="evaluation"
                />
              )}

              {userTask?.TaskType?.id === constants.TASKTYPE.REVIEWEXAM &&
                userTask?.Activity?.ActivitySubDetail?.intern_id ===
                  userTask?.user_id &&
                userTask?.status === "Pending" && (
                  <div className="mob-mt-2" style={{ marginBottom: "120px" }}>
                    <div className="float-right">
                      <button
                        type="button"
                        onClick={() => onMarkTaskCompletedHandler(userTask?.id)}
                        className="btn btn-join"
                      >
                        {props.t("markCompleted")}
                      </button>
                      <div
                        style={{
                          color: "#f46a6a",
                          fontSize: "15px",
                        }}
                      >
                        {props.t("markCompletedNote")}
                      </div>
                    </div>
                  </div>
                )}

              {(userTask?.TaskType?.id == constants.TASKTYPE.GIVEADVISE ||
                userTask?.TaskType?.id == constants.TASKTYPE.SIMPLE ||
                userTask?.TaskType?.id ==
                  constants.TASKTYPE.EVALUATIONFORMREVIEW ||
                userTask?.TaskType?.id == constants.TASKTYPE.WATCHEXAM ||
                userTask?.TaskType?.id == constants.TASKTYPE.CHECKRESULT ||
                userTask?.TaskType?.id ===
                  constants.TASKTYPE.CREATIONTEACHINGMATERIAL ||
                (userTask?.TaskType?.id === constants.TASKTYPE.REVIEWEXAM &&
                  userTask?.Activity?.ActivitySubDetail?.doctor_id ===
                    userTask?.user_id)) && (
                <AdwiseUserTaskForm
                  userTask={userTask}
                  activityId={userTask?.activity_id}
                  status={userTask?.status}
                />
              )}

              {userTask?.task_type_id === constants.TASKTYPE.ADDQUIZ &&
                (userTask.status === constants.APPROVAL_STATUS.Pending ||
                  userTask.status === constants.APPROVAL_STATUS.Rejected) && (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                    >
                      <div
                        className="dashboard-inner application-form"
                        style={{ minHeight: "auto" }}
                      >
                        <div className="row">
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("Amount")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              name="amount"
                              type="text"
                              placeholder={props.t("Amount")}
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.amount}
                            />
                            {formik.touched.amount && formik.errors.amount && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.amount}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props?.userTask?.status === "Approved"
                                ? props.t("advise")
                                : props.t("Task-Description")}{" "}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <textarea
                              type="text"
                              rows={5}
                              className="form-control  w-100"
                              name="description"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.description}
                              style={{ height: "unset" }}
                            />
                            {formik.touched.description &&
                              formik.errors.description && (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {formik.errors.description}
                                </div>
                              )}
                          </div>
                          {props?.userTask?.status !== "Approved" && (
                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("Attachment")}
                              </label>
                              <div className="fileupload">
                                <div className="text-center">
                                  <label>
                                    <a
                                      className="btn btnUpload"
                                      role="button"
                                      aria-disabled="false"
                                    >
                                      <img src="/assets/images/preview.png" />
                                      <br />
                                      <strong className="text-blue">
                                        {props.t("Upload")}{" "}
                                        {props.t("Attachments")}
                                      </strong>
                                      <br />
                                      <strong className="note">
                                        PNG, JPG, GIF, PDF, DOCX
                                      </strong>
                                      <br />
                                      <strong
                                        style={{
                                          color: "#f46a6a",
                                          fontSize: "11px",
                                          marginTop: "3px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {props.t("File-Max-Size-Message")}
                                      </strong>
                                    </a>
                                    <Input
                                      name="attachments"
                                      type="file"
                                      multiple
                                      className="form-control upload__inputfile"
                                      onChange={(e) => {
                                        const filesArray2 = [];
                                        for (
                                          let i = 0;
                                          i < e.target.files.length;
                                          i++
                                        ) {
                                          filesArray2.push(e.target.files[i]);
                                        }
                                        formik.setFieldValue(
                                          "attachments",
                                          formik.values.attachments
                                            ? [
                                                ...formik.values.attachments,
                                                ...filesArray2,
                                              ]
                                            : filesArray2
                                        );
                                      }}
                                      invalid={
                                        formik.touched.attachments &&
                                        formik.errors.attachments
                                          ? true
                                          : false
                                      }
                                    />
                                    {/* {formik.touched.attachments &&
                                    formik.errors.attachments && (
                                      <div
                                        style={{
                                          color: "#f46a6a",
                                          fontSize: "11px",
                                          marginTop: "3px",
                                        }}
                                      >
                                        {formik.errors.attachments}
                                      </div>
                                    )} */}
                                  </label>
                                </div>
                                <div className="pdf-block list-style-none">
                                  <ul>
                                    {props?.userTask?.TaskAttachment.length > 0
                                      ? props?.userTask?.TaskAttachment.map(
                                          (item, index) => {
                                            return (
                                              <li key={index}>
                                                <div className="d-flex align-items-center mb-3 row">
                                                  <div className="col-1">
                                                    <img
                                                      src="/assets/images/link.png"
                                                      alt="link"
                                                      width="15"
                                                    />
                                                  </div>
                                                  <div className="col-10 text">
                                                    <a
                                                      href={item.file_url}
                                                      target="_blank"
                                                      className="text-blue"
                                                    >
                                                      {item.title_en}
                                                    </a>
                                                  </div>
                                                  {/* <div className="col-1">
                                              <a
                                                onClick={() =>
                                                  removeMedia(
                                                    item.activity_id,
                                                    item.id,
                                                    "FILE",
                                                    index
                                                  )
                                                }
                                                title="Remove"
                                              >
                                                <img
                                                  src={require("assets/images/delete-icon.png")}
                                                  width="15"
                                                />
                                              </a>
                                            </div> */}
                                                </div>
                                              </li>
                                            );
                                          }
                                        )
                                      : null}
                                    {formik.values.attachments?.length > 0
                                      ? formik.values.attachments?.map(
                                          (item, index) => {
                                            return (
                                              <li>
                                                <div className="d-flex align-items-center mb-3 row">
                                                  <div className="col-1">
                                                    <img
                                                      src="/assets/images/link.png"
                                                      alt="link"
                                                      width="15"
                                                    />
                                                  </div>
                                                  <div className="col-10 text">
                                                    <a
                                                      href="#"
                                                      target="_blank"
                                                      className="text-blue"
                                                    >
                                                      {item.name}
                                                    </a>
                                                  </div>
                                                  <div className="col-1">
                                                    <a
                                                      title="Remove"
                                                      onClick={(e) => {
                                                        // removeAttachment(item.lastModified);
                                                      }}
                                                    >
                                                      <img
                                                        src={require("assets/images/delete-icon.png")}
                                                        width="15"
                                                      />
                                                    </a>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          }
                                        )
                                      : ""}
                                  </ul>
                                  <div className="clearfix"></div>
                                </div>
                              </div>
                            </div>
                          )}

                          {props?.userTask?.status === "Approved" && (
                            <div className="pdf-block">
                              <label className="form-label">
                                {props.t("Attachments")}
                              </label>
                              <ul>
                                {props?.userTask?.TaskAttachment?.map(
                                  (item, index) => {
                                    return (
                                      <FileCard
                                        key={`activityM_${index}`}
                                        item={item}
                                      />
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-1">
                            <img
                              src="/assets/images/plus.png"
                              alt="link"
                              width="15"
                            />
                          </div>
                          <div className="col-11">
                            <a
                              href="#"
                              onClick={onAddViewWuizHandler}
                              className="text-blue"
                            >
                              {teahcerQuizQuestions.length > 0
                                ? props.t("Edit")
                                : props.t("Add")}{" "}
                              {props.t("Quiz")}
                            </a>
                            {addedQuizCount !== 0 && (
                              <span
                                style={{ color: "#198754" }}
                              >{`  (${addedQuizCount} Question Added)`}</span>
                            )}
                          </div>
                        </div>
                        <div
                          className="justify-content-between align-items-center"
                          style={{ marginBottom: "120px" }}
                        >
                          <div className="float-right">
                            <button type="submit" className="btn btn-join">
                              {props.t("Submit")}
                            </button>
                          </div>
                        </div>
                        {
                          <AddEditQuizModal
                            setAddedQuizCout={setAddedQuizCout}
                            setQuizQuestions={setQuizQuestions}
                            maxQuestion={quizTypeDetail.max_questions}
                            selectedQuizType=""
                            questions={teahcerQuizQuestions}
                            isInstituteFiledEnable={
                              roleID === constants.ROLE.DOCTOR ? true : false
                            }
                          />
                        }
                      </div>
                    </form>
                  </>
                )}

              {isTeacherQuizApproved &&
              isQuizExist &&
              userTask?.task_type_id === constants.TASKTYPE.GIVEQUIZ &&
              userTask.status === constants.APPROVAL_STATUS.Pending ? (
                <div
                  className="justify-content-between align-items-center"
                  style={{ marginBottom: "120px" }}
                >
                  <div className="float-right">
                    <Link
                      to={`/start-quiz/${userTask?.activity_id}`}
                      className="btn btn-join"
                    >
                      {props.t("Start")} {props.t("Quiz")}
                    </Link>
                  </div>
                </div>
              ) : (
                isQuizExist &&
                userTask?.task_type_id === constants.TASKTYPE.GIVEQUIZ &&
                userTask.status === constants.APPROVAL_STATUS.Pending && (
                  <>
                    <div
                      className="justify-content-between align-items-center"
                      style={{ marginBottom: "120px" }}
                    >
                      <div className="float-right">
                        <Link
                          to={`/start-quiz/${userTask?.activity_id}`}
                          className="btn btn-join"
                        >
                          {props.t("Start")} {props.t("Quiz")}
                        </Link>
                      </div>
                    </div>
                  </>
                )
              )}

              {!isQuizExist &&
                userTask?.task_type_id === constants.TASKTYPE.GIVEQUIZ &&
                userTask.status === constants.APPROVAL_STATUS.Pending && (
                  <div
                    style={{
                      color: "#f46a6a",
                      fontSize: "15px",
                    }}
                    className="mb-3"
                  >
                    {props.t("noteQuizButton")}
                  </div>
                )}

              {/* {userTask?.internSubmittedTask && userTask.type === "TEACHER" && (
                <InternSubmittedTask
                  internTaskDetail={userTask?.internSubmittedTask}
                />
              )} */}

              {userTask.task_type_id === constants.TASKTYPE.ROTATIONAL &&
                userTask.type !== "TEACHER" && (
                  <InternshipTaskForm
                    userTask={userTask}
                    activityId={userTask?.activity_id}
                    status={userTask?.status}
                    taskEvaluators={taskEvaluators}
                  />
                )}

              {evaluationFormId !== null &&
                userTask?.status === "Pending" &&
                userTask?.TaskType?.id == constants.TASKTYPE.INTERVIEWTEST &&
                userTask?.Activity?.ActivityEvaluation[0]?.doctor_id ===
                  user?.id && (
                  <div className="mob-bg-white">
                    <Link
                      to={`/evaluation-feedback/${evaluationFormId}/${userTask?.activity_id}/${userTask?.id}`}
                      target="_blank"
                      className="btn btn-join mt-5"
                    >
                      {props.t("Submit")} {props.t("Feedback")}
                    </Link>
                  </div>
                )}

              {evaluationFormId !== null &&
                userTask?.status === "Pending" &&
                evaluationReviewDetail &&
                userTask?.TaskType?.id ==
                  constants.TASKTYPE.EVALUATIONFORMSUBMITION &&
                userTask?.Activity?.ActivityEvaluation[0]?.doctor_id ===
                  user?.id && (
                  <div className="mob-bg-white">
                    <Link
                      to={`/evaluation-feedback/${evaluationFormId}/${userTask?.activity_id}/${userTask?.id}`}
                      target="_blank"
                      className="btn btn-join mt-5 mb-5"
                    >
                      {props.t("Submit")} {props.t("Feedback")}
                    </Link>
                  </div>
                )}

              {teacherEvaluationForm &&
                userTask?.status !== constants.APPROVAL_STATUS.Approved &&
                userTask?.status !== "Submited" && (
                  <TeacherEvaluationForm
                    formID={teacherEvaluationForm.evaluation_form_id}
                    activityId={userTask?.activity_id}
                    userTaskID={userTask?.id}
                  />
                )}

              {msfEvaluationForm &&
                userTask?.status !== constants.APPROVAL_STATUS.Approved &&
                userTask?.status !== "Submited" && (
                  <TeacherEvaluationForm
                    formID={msfEvaluationForm.evaluation_form_id}
                    activityId={userTask?.activity_id}
                    userTaskID={userTask?.id}
                  />
                )}

              {hodEvaluationForm &&
                userTask?.status !== constants.APPROVAL_STATUS.Approved &&
                userTask?.status !== "Submited" && (
                  <TeacherEvaluationForm
                    formID={hodEvaluationForm.evaluation_form_id}
                    activityId={userTask?.activity_id}
                    userTaskID={userTask?.id}
                  />
                )}

              {directorEvaluationForm &&
                userTask?.status !== constants.APPROVAL_STATUS.Approved &&
                userTask?.status !== "Submited" && (
                  <TeacherEvaluationForm
                    formID={directorEvaluationForm.evaluation_form_id}
                    activityId={userTask?.activity_id}
                    userTaskID={userTask?.id}
                  />
                )}

              {userTask?.TaskType?.id ==
                constants.TASKTYPE.EVALUATIONFORMAPPROVAL &&
                userTask?.status !== constants.APPROVAL_STATUS.Approved && (
                  <UserTaskApproved
                    userTask={userTask}
                    activityId={userTask?.activity_id}
                    // status={userTask?.status}
                  />
                )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(UserTaskDetail);