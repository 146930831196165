import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Token, getRoleID } from "../../Token";
import constants from "../../constants";

const Logout = (props) => {

  useEffect(() => {
    var logout = {type:"logout"};
    window.parent.postMessage(JSON.stringify(logout));
    if (constants.ROLE.COMPANY === getRoleID()) {
      Token.removeStorageItem();
      window.location.href = "/company-login";
    }else{
      Token.removeStorageItem();
      if(window.navigator.userAgent.includes("iPhone")) {
        window.location.href = "/sign-in";
      } else {
        window.location.href = "/";
      }
    }
  }, []);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default Logout;
