import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
// import config from "../../config";
import { withTranslation } from "react-i18next";
import constants from "../../constants";
import { getRoleID, getUser } from "../../Token";
import { HomeApi } from "../../apis/HomeApi";

function ProfileSidebar(props) {
    const [active, setActive] = useState('profile');
    const [userDetail, setUserDetail] = useState("");
    const user = getUser();

    useEffect(() => {
        setMenuActive();
        getUserDetails();
    }, []);

    const setMenuActive = () => {
        var section = window.location.pathname.split('/').slice(0, 2).join('');
        setActive(section);
    };

    const getUserDetails = () => {
        HomeApi.checkUserDetailsAndInterestAPI()
        .then((res) => {
            if (res.data.success) {
                setUserDetail(res.data?.user);
            } else {
                setUserDetail("");
            }
        })
        .catch((err) => {
            console.log(err);
        });
      };

    return (
        <div className="col-md-2 d-m-none">
            <div className="sidebar list-style-none">
                <ul>
                    <li>
                        <NavLink to="/profile"  className={`${active === "profile" ? "active" : ""}`}>
                            {props.t("My-Profile")}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/mymedx"  className={`${(active === "mymedx" || active === "mymedx-details") ? "active" : ""}`}>
                            {props.t("My-Medx")}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/mycalendar"  className={`${active === "mycalendar" ? "active" : ""}`}>
                            {props.t("My-Calendar")}
                        </NavLink>
                    </li>
                    {constants.ROLE.COMPANY !== getRoleID() ? (
                        <li>
                            <NavLink to="/my-activity" className={`${active === "myactivity" ? "active" : ""}`}>
                                {props.t("My-Activity")}
                            </NavLink>
                        </li> ) 
                    : 
                        (<></> ) 
                    }
                    {userDetail?.is_intern ? (
                        <li>
                            <NavLink to="/my-leaves"  className={`${active === "my-leaves" ? "active" : ""}`}>
                                {props.t("My-Leaves")}
                            </NavLink>
                        </li>
                        ) : ""
                    }

                    <li>
                        <NavLink to="/booking" className={`${active === "booking" ? "active" : ""}`}>
                            {props.t("My-Booking")}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/internship"  className={`${active === "internship" ? "active" : ""}`}>
                            {props.t("Internship")}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/bookmark" className={`${active === "bookmark" ? "active" : ""}`}>
                            {props.t("Bookmark")}
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}
export default withTranslation()(ProfileSidebar)