import { ProfileAPI } from "apis/ProfileAPI";
import moment from "moment";
import InternshipTaskEvaluatorForm from "pages/Components/InternshipTaskEvaluatorForm";
import UserTaskCard from "pages/Components/UserTaskCard";
import { useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Footer from "../../components/Layout/Footer";
import Navbar from "../../components/Layout/Navbar";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import config from "../../config";

const localizer = momentLocalizer(moment);

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

//https://jquense.github.io/react-big-calendar/examples/?path=/docs/examples--example-9

function MyCalendar(props) {
  const [active, setActive] = useState("calendar");
  const [myEventsList, setMyEventList] = useState([]);
  const [userTaskList, setUserTaskList] = useState([]);
  const [userTaskActiveData, setUserTaskActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [internshipTasks, setInternshipTasks] = useState([]);
  const [msfList, setMsfList] = useState([]);
  const [internshipSessionId, setInternshipSessionId] = useState();

  useEffect(() => {
    const month = moment().month() + 1;
    const year = moment().year();
    getMyCalenderByMonthYear(month, year);
  }, []);

  const getMyCalenderByMonthYear = async (month, year) => {
    const payload = {
      month: month,
      year: year,
    };
    ProfileAPI.getMyCalenderAPi(payload)
      .then((res) => {
        if (res.data.success) {
          setMyEventList(res.data.data);
          if (res.data.isInternshipTaskExist) getInternshipTasksHandler();
        } else {
          setMyEventList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMyCalenderUserTaskList = (data, isPushData) => {
    ProfileAPI.getMyCalenderUserTaskListAPi(data)
      .then((res) => {
        if (res.data.success) {
          if (isPushData) {
            setUserTaskList([...userTaskList, ...res.data.userTasks]);
          } else {
            setUserTaskList(res.data.userTasks);
          }
        } else {
          setUserTaskList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onNavigateHandler = (date) => {
    const month = moment(date).month() + 1;
    const year = moment(date).year();
    getMyCalenderByMonthYear(month, year);
  };

  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 1),
      views: {
        month: true,
      },
    }),
    []
  );

  const onSelectEvent = (event) => {
    setStartDate(moment(event.start).format("YYYY-MM-DD"));
    setEndDate(moment(event.end).format("YYYY-MM-DD"));
    userTaskActiveData.startDate = moment(event.start).format("YYYY-MM-DD");
    userTaskActiveData.endDate = moment(event.end).format("YYYY-MM-DD");
    setUserTaskActiveData(userTaskActiveData);
    getMyCalenderUserTaskList(userTaskActiveData, false);
    setActive("list");
  };

  const handleClickTabActive = (value) => {
    if (value === "list") {
      const start = moment().startOf("month").format("YYYY-MM-DD");
      const end = moment().endOf("month").format("YYYY-MM-DD");
      const data = {
        page: 1,
        limit: config.LIMIT,
        startDate: start,
        endDate: end,
      };
      setStartDate(start);
      setEndDate(end);
      setUserTaskActiveData(data);
      getMyCalenderUserTaskList(data, false);
    } else {
      const month = moment().month() + 1;
      const year = moment().year();
      getMyCalenderByMonthYear(month, year);
    }
    setActive(value);
  };

  const onDateChangeHandler = (e) => {
    if (e.target.name === "startDate") {
      setStartDate(e.target.value);
      const data = {
        page: 1,
        limit: config.LIMIT,
        startDate: moment(e.target.value).format("YYYY-MM-DD"),
        endDate: userTaskActiveData.endDate,
      };
      setUserTaskActiveData(data);
      getMyCalenderUserTaskList(data, false);
    } else {
      setEndDate(e.target.value);
      const data = {
        page: 1,
        limit: config.LIMIT,
        startDate: userTaskActiveData.startDate,
        endDate: moment(e.target.value).format("YYYY-MM-DD"),
      };
      setUserTaskActiveData(data);
      getMyCalenderUserTaskList(data, false);
    }
  };

  const getInternshipTasksHandler = async () => {
    ProfileAPI.checkInternshipAndGetEvaluatorsApi()
      .then((res) => {
        if (res.data.success) {
          setInternshipTasks(res.data.currenInternshipTask);
          setMsfList(res.data.msfList)
          setInternshipSessionId(res.data.internshipSessionId)
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "");
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          );
        }
      });
  };

  // window.onscroll = async function (ev) {
  //   let pageHeight = Math.max(
  //     document.body.scrollHeight,
  //     document.body.offsetHeight,
  //     document.documentElement.clientHeight,
  //     document.documentElement.scrollHeight,
  //     document.documentElement.offsetHeight
  //   );
  //   if (window.innerHeight + window.scrollY >= pageHeight) {
  //     const data = {
  //       page: userTaskActiveData.page + 1,
  //       limit: config.LIMIT,
  //       startDate: userTaskActiveData.startDate,
  //       endDate: userTaskActiveData.endDate,
  //     };
  //     setUserTaskActiveData(data);
  //     getMyCalenderUserTaskList(data, true);
  //   }
  // };   
  
  return (
    <div>
      <Navbar />
      {/* <MobileBottomMenu /> */}
      <ScrollToTopOnMount />
      <>
        <div className="mobile-top d-none">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                  <NavLink to="/profile">
                    <img src="assets/images/768/arrow-left-w.png" alt="" />
                  </NavLink>
                </div>
                <div>
                  <h4 className="mb-0">{props.t("My-Calendar")}</h4>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View Top End */}
        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row p-8">
              <div className="col-md-12">
                <h1 className="mt-5  mb-3 main-title">{props.t("Profile")}</h1>
              </div>
            </div>
          </div>
        </section>
      </>

      <section className="dashboard top-curve-profile">
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />

            <div className="col-md-10">
              <div className="rbc-toolbar " style={{ float: "right" }}>
                <span className="rbc-btn-group">
                  <button
                    type="button"
                    className={`${active == "calendar" ? "tab-task-bg" : ""}`}
                    onClick={() => handleClickTabActive("calendar")}
                  >
                    Calendar
                  </button>
                  <button
                    type="button"
                    className={`${active == "list" ? "tab-task-bg" : ""}`}
                    onClick={() => handleClickTabActive("list")}
                  >
                    List
                  </button>
                </span>
                <span className="rbc-btn-group"></span>
              </div>

              {active == "calendar" ? (
                <div>
                  <Calendar
                    localizer={localizer}
                    events={myEventsList}
                    defaultView="month"
                    startAccessor="start"
                    endAccessor="end"
                    views={{
                      month: true,
                    }}
                    style={{ height: 600 }}
                    eventPropGetter={(myEventsList) => {
                      let dotWH;
                      var backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : "blue";
                      const color = myEventsList.color ? myEventsList.color : "blue";

                      if(myEventsList.attendance_type != null) {
                        dotWH = "10px";
                      } else if(myEventsList.leave_type != null) {
                        dotWH = "auto";
                      } else{
                        dotWH = "auto";
                      }

                      backgroundColor = myEventsList.attendance_type != null ? myEventsList.attendance_type_color : backgroundColor;
                      return { style: { backgroundColor, color, width:dotWH, height:dotWH } };
                    }}
                    onSelectEvent={onSelectEvent}
                    onNavigate={(date) => {
                      onNavigateHandler(date);
                    }}
                  />
                </div>
              ) : (
                <div>
                  <section
                    className="dashboard top-curve-profile"
                    style={{ clear: "both" }}
                  >
                    <div className="">
                      <div className="row p-12">
                        <div className="col-md-12">
                          <div className="dashboard-inner">
                            <div className="row">
                              <div className="col-md-6">
                                <h2 className="mb-3 d-m-none">
                                  {props.t("task_list")}
                                </h2>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <input
                                      type="date"
                                      className="form-control filterbor"
                                      id=""
                                      name="startDate"
                                      value={startDate}
                                      onChange={onDateChangeHandler}
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <input
                                      type="date"
                                      className="form-control filterbor"
                                      id=""
                                      name="endDate"
                                      value={endDate}
                                      onChange={onDateChangeHandler}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="library-list list-style-none">
                              <ul className="first">
                                {userTaskList.length > 0
                                  ? userTaskList.map((task, i) => {
                                      return (
                                        <UserTaskCard
                                          key={`userTask_${task.id}`}
                                          task={task}
                                        />
                                      );
                                    })
                                  : "No record found"}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )}

              {active === "calendar" && internshipTasks.length > 0 && (
                <div className="mt-5">
                  <InternshipTaskEvaluatorForm
                    internshipTasks={internshipTasks}
                    msfList={msfList}
                    internshipSessionId={internshipSessionId}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="d-m-none">
        <Footer />
      </div>
    </div>
  );
}

export default withTranslation()(MyCalendar);
