import React, { useEffect, useState } from "react";
import { NavLink, Link,useParams } from 'react-router-dom';
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import upevent1 from "../../assets/images/right-arrow.png";
import { EducationApi } from "../../apis/EducationApi";
import config from "../../config";
import * as url from "../../helpers/common_helper";
import { HomeApi } from "apis/HomeApi";
import { withTranslation } from "react-i18next";
import Activity from "../Education/components/Activity";
import InfiniteScroll from "react-infinite-scroll-component";


function ActivitiesAboutClose(props) {
  
  const params = useParams();
  const education_type = params.education_type;
  const [activityData, setActiveData] = useState({ "page": 1, "limit": config.LIMIT });
  const [activititiesData, setActivitiesData] = useState({ "page": 1, "limit": config.LIMIT });
  const [activitiesAboutToClose, setActivitiesAboutToClose] = useState([]);
  const [interests, setInterests] = useState([])
  const [activities, setActivities] = useState([])
  const [hasMore, sethasMore] = useState(true)
  

  useEffect(() => {
    window.scrollTo(0, 0);
    getActivityAboutToClose(activityData);
  }, []);

  const getActivityAboutToClose = (data) => {
    HomeApi.getActivityAboutToCloseAPI(data)
      .then((res) => {
        if (res.data.success) {
          setActivitiesAboutToClose([...activitiesAboutToClose, ...res.data.data.rows]);
          // const data = {
          //   page: activityData.page + 1,
          //   limit: config.LIMIT
          // };
          // setActiveData(data);
          if(res.data.data.rows.length == 0) {
            sethasMore(false);
          }
          // if(is_push_dataa) {
          //   setActivitiesAboutToClose([...activitiesAboutToClose, ...res.data.data.rows]);
          // } else {
          //   setActivitiesAboutToClose(res.data.data.rows);
          // }
        } else {
          setActivitiesAboutToClose([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // window.onscroll = async function (ev) {
  //   var pageHeight = Math.max(
  //     document.body.scrollHeight,
  //     document.body.offsetHeight,
  //     document.documentElement.clientHeight,
  //     document.documentElement.scrollHeight,
  //     document.documentElement.offsetHeight
  //   );
  //   if (window.innerHeight + window.scrollY >= pageHeight) {
  //     const data = {
  //       page: activityData.page + 1,
  //       limit: config.LIMIT
  //     };
  //     setActiveData(data);
  //     getActivityAboutToClose(data,true);
  //   }
  // };


  // const componentDidMount = () => {
  //   fetchMoreData();
  // }


  const fetchMoreData = () => {
    const data = {
      page: activityData.page + 1,
      limit: config.LIMIT
    };
    getActivityAboutToClose(data);
    setActiveData(data);
  };




  return (
    <div>
      <Navbar />

      <>

        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row">
              
              <div className="col-md-12 list-style-none">
                <ul className="breadcrumb mt-3">
                  <li>
                   <Link to={"/"}>{props.t("Activity")}</Link>
                  </li> 
                  <li>/</li>
                  <li className="active"> {props.t("Activities-That-Are-About-To-Close")}</li>
                </ul>
              </div>
            </div>
          </div> 
        </section>

        {/* CPD Activities Star */}
        <section className="mt-5 mb-0 mob-pt-2">
          <div className="container">
            <div className="row px-10">
              <div className="col-md-12">
                <h1 className="my-5 main-title d-m-none">
                {props.t("Activities-That-Are-About-To-Close")}
                </h1>
              </div>
              
              
            </div>
          </div>
        </section>
        {/* CPD Activities End */}
        {/* CME Events Start */}
        <section className="pb-5 mob-mt-m-15">
          <div className="container p-0">
            
            <div className="row">
              
              <div className="col-md-12 px-30">

              <div className="mb-3 d-none">
              <Link to={"/"}>
                  <img
                  src="../assets/images/left-arrow.png"
                  className=""
                  alt=""
                  width=""
                  height=""
                  />
              </Link>
              </div>
             
                <h3 className="title">{props.t("Activities")}</h3>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="subtitle">{props.t("Come-See-What’s-Happening")}</div>
                </div>
                <div className="collections">
                  {activitiesAboutToClose.length > 0 && <InfiniteScroll
                    dataLength={activitiesAboutToClose.length}
                    next={fetchMoreData}
                    loader={<h4 className="w-50 h-40 text-[18px]">Loading...</h4>}
                    hasMore={hasMore}
                    //height={500}
                    // endMessage={
                    //   <p className="text-[16px]" style={{ textAlign: "center", marginTop: '10px' }}>
                    //     <b>No more post</b>
                    //   </p>
                    // }
                  >

                  {activitiesAboutToClose.length > 0 ? (
                    activitiesAboutToClose?.map((activity, index) => {
                      return <Activity activity={activity} key={index} />;
                    })
                  ) : (
                    <div className="subtitle text-center">No Activities Found </div>
                  )}
                  </InfiniteScroll>}
                </div>
              </div>
              {/* <div className="col-md-12 px-30 mt-4 mob-mb-30">
                <div className="text-center">Loading...</div>
              </div> */}
            </div>
          </div>
        </section>
     
        {/* CME Events End */}
      </>





      <Footer />
    </div>
  );
}

export default withTranslation()(ActivitiesAboutClose)