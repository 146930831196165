import config from "../config";
import axios from "axios";
import * as url from "../helpers/url_helper";

export const DistrictApi = {
  getAllDistrict: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_DISTRICT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
};
