import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import config from "../../../config";
import { isRestricted } from "../../../Token";

const MobileBottomMenu = () => {
  const [active, setActive] = useState();
  useEffect(() => {
    setMenuActive();
  }, []);

  const setMenuActive = () => {
    let section = window.location.pathname.split("/").slice(0, 2).join("");
    if (section === "") section = "profile";
    setActive(section);
  };

  return (
    <div className={`d-none mob-menu bg-white list-style-none restricted_${isRestricted()}`}>
      <ul className="d-flex align-items-center justify-content-between">
        <li>
          {active === "profile" ? (
            <NavLink to="/">
              <img src={config.BASE_URL + "assets/images/768/svg/home_active.svg"} />
            </NavLink>
          ) : (
            <Link to="/" className="inactive">
              <img src={config.BASE_URL + "assets/images/768/svg/home.svg"} />
            </Link>
          )}
        </li>
        <li>
          {active === "education" ? (
            <NavLink to="/education">
              <img src={config.BASE_URL + "assets/images/768/svg/education_active.svg"} />
            </NavLink>
          ) : (
            <Link to="/education" className="inactive">
              <img src={config.BASE_URL + "assets/images/768/svg/education.svg"} />
            </Link>
          )}
        </li>
        <li>
          {active === "community" ? (
            <NavLink to="/community">
              <img src={config.BASE_URL + "assets/images/768/svg/community_active.svg"} />
            </NavLink>
          ) : (
            <Link to="/community" className="inactive">
              <img src={config.BASE_URL + "assets/images/768/svg/community.svg"} />
            </Link>
          )}
        </li>
        <li>
          {active === "offers" ? (
            <NavLink to="/offers">
              <img src={config.BASE_URL + "assets/images/768/svg/offer_active.svg"} />
            </NavLink>
          ) : (
            <Link to="/offers" className="inactive">
              <img src={config.BASE_URL + "assets/images/768/svg/offer.svg"} />
            </Link>
          )}
        </li>
        <li>
          {active === "mymedx" ? (
            <NavLink to="/mymedx">
              <img src={config.BASE_URL + "assets/images/768/svg/user_active.svg"} />
            </NavLink>
          ) : (
            <Link to="/mymedx" className="inactive">
              <img src={config.BASE_URL + "assets/images/768/svg/user.svg"} />
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
};
export default MobileBottomMenu;
