import axios from "axios";
import { publicIpv4 } from "public-ip";
import config from "../config";
import * as url from "../helpers/url_helper";

export const AuthApi = {
  doctorLogin: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.DOCTOR_LOGIN}`,
      headers: {
        "Content-Type": "application/json",
        ip: await publicIpv4(),
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  doctorLoginWithPassowrd: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.DOCTOR_LOGIN_WITH_PASSWORD}`,
      headers: {
        "Content-Type": "application/json",
        ip: await publicIpv4(),
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  companyLoginAPI: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.COMPANY_LOGIN}`,
      headers: {
        "Content-Type": "application/json",
        ip: await publicIpv4(),
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  mdConnectAPI: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.MD_CONNECT}`,
      headers: {
        "Content-Type": "application/json",
        ip: await publicIpv4(),
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  testDoctorLogin: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}api/user/testdoctorlogin`,
      headers: {
        "Content-Type": "application/json",
        ip: await publicIpv4(),
      },
      data: data,
    };

    return await axios(axiosConfig);
  },

  siginIn: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.SIGN_IN}`,
      headers: {
        "Content-Type": "application/json",
        ip: await publicIpv4(),
      },
      data: data,
    };

    return await axios(axiosConfig);
  },

  signUp: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.SIGN_UP}`,
      headers: {
        "Content-Type": "application/json",
        ip: await publicIpv4(),
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  doctorForgotPassowrd: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.DOCTOR_FORGOT_PASSWORD}`,
      headers: {
        "Content-Type": "application/json",
        ip: await publicIpv4(),
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  doctorUpdatePassowrd: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.DOCTOR_UPDATE_PASSWORD}`,
      headers: {
        "Content-Type": "application/json",
        ip: await publicIpv4(),
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
};
