import { CustomActivityApi } from "apis/CustomActivityApi";
import Footer from "components/Layout/Footer";
import Navbar from "components/Layout/Navbar";
import config from "config";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import MobPageTitleBar from "pages/Components/MobPageTitleBar";
import AddEditQuizModal from "pages/Components/Modal/AddEditQuizModal";
import TaskDetailModal from "pages/Components/Modal/TaskDetailModal";
import ProfileTitle from "pages/Components/ProfileTitle";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { Input } from "reactstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import constants from "../../constants";
import { getRoleID, getUser } from "../../Token";
import QuizQuestionList from "./QuizQuestionList";

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};

const EditActivityNew = (props) => {
  const search = useLocation().search;
  const params = useParams();
  let activity_id = "";
  let roleID;
  let userDetail;
  const [show, setShow] = useState(false);

  const session_id = new URLSearchParams(search).get("session_id");
  const uln = new URLSearchParams(search).get("uln");
  const instituteID = new URLSearchParams(search).get("institute_id");

  if (instituteID === null) {
    roleID = getRoleID();
    userDetail = getUser();
  } else {
    roleID = parseInt(new URLSearchParams(search).get("roleID"));
  }

  const [selectedActivityImage, setSelectedActivityImage] = useState();
  const [selectedQuizTypeValue, setSelectedQuizTypeValue] = useState({});
  const [selectedQuizType, setSelectedQuizType] = useState('');
  const [institutions, setInstitutions] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [activitySubTypes, setActivitySubTypes] = useState([]);
  const [activityTypeJson, setActivityTypeJson] = useState([]);
  const [activitySubTypeDetail, setActivitySubTypeDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activity, setActivityData] = useState([]);
  const [isTeacherSubmittedTask, setIsTeacherSubmittedTask] = useState(false);
  const [isExaminnerSubmittedTask, setIsExaminnerSubmittedTask] =
    useState(false);
  const [
    isTeacherCheckResultSubmittedTask,
    setIsTeacherCheckResultSubmittedTask,
  ] = useState(false);
  const [teacherQuizQuestions, setTeacherQuizQuestions] = useState([]);
  const [image, setActivityImage] = useState("");
  const [files, setActivityFile] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [invalidSession, setInvalidSesion] = useState(false);
  const [activityContentTypes, setActivityContentTypes] = useState([]);
  const [activityDisplayTypes, setActivityDisplayTypes] = useState([]);
  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const [quizTypes, setQuizTypes] = useState([]);
  const [isInstituteFiledEnable, setIsInstituteFiledEnable] = useState(false);
  const [addedQuizCount, setAddedQuizCout] = useState(0);
  const [fromUser, setFromUser] = useState("Doctor");
  const [subActivityTask, setSubActivityTask] = useState([]);
  const [defaultSubActivityTask, setDefaultSubActivityTask] = useState();
  const [doctorsData, setDoctorsData] = useState([]);
  const [isCertificateFiledEnable, setIsCertificateFiledEnable] =
    useState(false);
  const [doctorPaginateData, setDoctorPaginateData] = useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  });
  const [isMoreDoctors, setIsMoreDoctors] = useState(true);
  const [isDoctorLoading, setIsDoctorLoading] = useState(true);
  const [institutesData, setinstitutesData] = useState([]);
  const [institutesPaginateData, setinstitutesPaginateData] = useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  });
  const [isMoreInstitutes, setIsMoreInstitutes] = useState(true);
  const [isInstituteLoading, setIsInstituteLoading] = useState(true);
  const [customValidation, setCustomValidation] = useState({
    activity_name: Yup.string().required(props.t("Required-Message")),
    description: Yup.string().required(props.t("Required-Message")),
  });
  const [quizQuetions, setQuizQuestions] = useState([]);


  const activityFormValidation = (contentJson) => {
    let valSchema = {
      activity_name: Yup.string().required(props.t("Required-Message")),
      description: Yup.string().required(props.t("Required-Message")),
    };
    if (
      contentJson.approval_rquired &&
      contentJson.approver_roles.includes(constants.ROLE.INSTITUTE) &&
      // contentJson.activity_creators.includes(constants.ROLE.DOCTOR) &&
      // contentJson.activity_creators.includes(constants.ROLE.SUBINSTITUTE)
      roleID != constants.ROLE.INSTITUTE
    ) {
      setIsInstituteFiledEnable(true);
      valSchema.institute_id = Yup.string().required(
        props.t("Required-Message")
      );
    }

    if (contentJson.can_other_join_activity) {
      valSchema.type = Yup.string().required(props.t("Required-Message"));
      valSchema.activity_date = Yup.string().required(
        props.t("Required-Message")
      );
      valSchema.start_time = Yup.string().required(props.t("Required-Message"));
      valSchema.end_time = Yup.string().required(props.t("Required-Message"));
      valSchema.speaker_id = Yup.string().required(props.t("Required-Message"));
    }
    if (contentJson.can_attach_files) {
      valSchema.attachments = Yup.string().required(
        props.t("Required-Message")
      );
    }
    if (
      (contentJson.assign_as_task && constants.ROLE.INSTITUTE == roleID) ||
      (constants.ROLE.DOCTOR == roleID &&
        contentJson.score_type_id === constants.SCORE_TYPE.GIVING_ADVISE &&
        contentJson.approval_rquired === false)
    ) {
      valSchema.doctor_id = Yup.string().required(props.t("Required-Message"));
    }
    if (contentJson.can_add_quiz) {
      // valSchema.quiz_type_id = Yup.string().required(
      //   props.t("Required-Message")
      // );
    }
    if (
      contentJson.score_type_id ==
      constants.SCORE_TYPE.DISPLAY_CONTENT_PROVIDING
    ) {
      valSchema.display_type_id = Yup.string().required(
        props.t("Required-Message")
      );
    }
    if (contentJson.score_type_id == constants.SCORE_TYPE.CONTENT_TYPE) {
      valSchema.content_type_id = Yup.string().required(
        props.t("Required-Message")
      );
    }
    if (contentJson.score_type_id == constants.SCORE_TYPE.FILE_ATTACHMENT) {
      valSchema.attachment_type_id = Yup.string().required(
        props.t("Required-Message")
      );
    }

    if (contentJson.sub_activity) {
      valSchema.task_type_id = Yup.string().required(
        props.t("Required-Message")
      );
    }
    setCustomValidation(valSchema);
  };

  const getActivityTypeJson = (activity_sub_type) => {
    let data = { activity_sub_type: activity_sub_type };
    CustomActivityApi.getActivityTypeJson(data)
      .then((res) => {
        if (res.data.success) {
          setActivitySubTypeDetail(res.data.data);
          setActivityTypeJson(JSON.parse(res.data.data.form_content_json));
          activityFormValidation(JSON.parse(res.data.data.form_content_json));
        } else {
          setActivitySubTypeDetail([]);
          setActivityTypeJson([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchActivityTypes = () => {
    CustomActivityApi.fetchActivityTypes()
      .then((res) => {
        if (res.data.success) {
          setActivityTypes(res.data.data);
        } else {
          setActivityTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubActivityTasks = () => {
    CustomActivityApi.getTaskTypesApi()
      .then((res) => {
        if (res.data.success) {
          setSubActivityTask(res.data.data);
        } else {
          setSubActivityTask([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchActivityDisplayTypes = () => {
    CustomActivityApi.fetchActivityDisplayTypes()
      .then((res) => {
        if (res.data.success) {
          setActivityDisplayTypes(res.data.data);
        } else {
          setActivityDisplayTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchActivityContentTypes = () => {
    CustomActivityApi.fetchActivityContentTypes()
      .then((res) => {
        if (res.data.success) {
          setActivityContentTypes(res.data.data);
        } else {
          setActivityContentTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAttachmentTypes = () => {
    CustomActivityApi.fetchAttachmentTypes()
      .then((res) => {
        if (res.data.success) {
          setAttachmentTypes(res.data.data);
        } else {
          setAttachmentTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchActivitySubTypes = (activity_type) => {
    let data = { activity_type: activity_type };
    CustomActivityApi.fetchActivitySubTypes(data)
      .then((res) => {
        if (res.data.success) {
          setActivitySubTypes(res.data.data);
        } else {
          setActivitySubTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuizTypes = () => {
    setLoading(true);
    CustomActivityApi.getQuizTypes()
      .then((res) => {
        let quizTypeData = [];
        if (res?.data?.data.length > 0) {
          quizTypeData = res?.data?.data.map((value) => ({
            value: `${value.id}`,
            label: `${value.name_en}`,
          }));
        }
        setQuizTypes(quizTypeData);
        setLoading(false);
      })
      .catch((err) => {
        setQuizTypes([]);
        console.log(err);
      });
  };

  useEffect(() => {
    if (params.activity_id !== undefined) {
      activity_id = btoa(parseInt(params.activity_id));
      roleID = getRoleID();
      setShow(true);
    } else {
      activity_id = new URLSearchParams(search).get("activity_id");
      roleID = new URLSearchParams(search).get("roleID");
      setShow(false);
    }
    getActivityDetails();
  }, []);

  const getActivityDetails = () => {
    const payload = {
      activity_id: activity_id,
      session_id: session_id,
      uln: uln,
    };
    CustomActivityApi.getActivityDetailsAPI(payload)
      .then((res) => {
        if (res.data.success) {
          setActivityData(res.data.activity);
          if(activity.Question.length > 0){
            setQuizQuestions(res.data.activity.Question)
          }
          setIsTeacherSubmittedTask(res.data.isTeacherSubmittedTask);
          setIsExaminnerSubmittedTask(res.data.isExaminnerSubmittedTask);
          setIsTeacherCheckResultSubmittedTask(res.data.isteacherCheckResultSubmittedTask);
          setTeacherQuizQuestions(res.data.teacherQuizQuestions);
          setActivityImage(res.data.activity?.ActivityMediaImage[0]?.file_url);
          // setActivityFile(res.data.activity?.ActivityMediaFile);
          let typeJson = JSON.parse(
            res.data.activity?.ActivityType?.form_content_json
          );
          setActivityTypeJson(typeJson);
          if (
            typeJson.approval_rquired &&
            typeJson.approver_roles.includes(constants.ROLE.INSTITUTE) &&
            roleID != constants.ROLE.INSTITUTE
          ) {
            setIsInstituteFiledEnable(true);
          }

          if (
            (res.data.activity?.approval_status ===
              constants.APPROVAL_STATUS.Pending ||
              res.data.activity?.approval_status ===
                constants.APPROVAL_STATUS.Submited) &&
            instituteID !== null
          ) {
            setCustomValidation({
              approval_status: Yup.string().required(
                props.t("Required-Message")
              ),
            });
          }

          if (res.data.activity?.ActivitySubDetail?.QuizType) {
            let quizType = {
              value: `${res.data.activity?.ActivitySubDetail?.QuizType.id}`,
              label: `${res.data.activity?.ActivitySubDetail?.QuizType.name_en}`,
            };
            setSelectedQuizTypeValue(quizType);
            setSelectedQuizType(res.data.activity?.ActivitySubDetail?.QuizType.name_en)
          }

          if (res.data.activity?.ActivitySubDetail?.TaskType) {
            getSubActivityTasks();
            let taskType = {
              value: res.data.activity?.ActivitySubDetail?.TaskType.id,
              label: `${res.data.activity?.ActivitySubDetail?.TaskType.name_en}`,
            };
            setDefaultSubActivityTask(taskType);
          }

          if (
            res.data.activity?.ActivityMediaFile.length <= 0 &&
            res.data.activity?.ActivityType?.type === "internship" &&
            typeJson.score_type_id !== constants.SCORE_TYPE.EXAM_WITH_QUIZ
          ) {
            setIsCertificateFiledEnable(true);
          }

          setAddedQuizCout(res?.data?.activity?.Question?.length);
        } else {
          setInvalidSesion(true);
          setActivityData([]);
          setActivityImage("");
          setActivityFile([]);
          setActivityTypeJson({});
          setAddedQuizCout(0);
          setIsCertificateFiledEnable(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchActivityTypes();
    getQuizTypes();
    fetchActivityDisplayTypes();
    fetchActivityContentTypes();
    fetchAttachmentTypes();
  }, []);

  const onViewSubmittedDetailsHandler = () => {
    window.$("#taskDetailsmodal").modal("show");
  };
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      activity_id: activity ? activity.id : null,
      activity_image: null,
      attachments: [],
      activity_name: activity ? activity.title_en : "",
      activity_group: activity ? activity.activity_group : null,
      activity_sub_type: activity ? activity.activity_type_id : null,
      description: activity ? activity.description_en : "",
      institute_id: activity ? activity.institute_id : null,
      quiz_type_id: activity?.ActivitySubDetail
        ? activity?.ActivitySubDetail?.quiz_type_id
        : null,
      type: activity ? activity.type : "",
      address: activity ? activity.address : "",
      latitude: activity ? activity.latitude : "",
      longitude: activity ? activity.longitude : "",
      online_meeting_url: activity ? activity.online_meeting_url : "",
      activity_date: activity.activity_date
        ? moment(activity.activity_date).format("YYYY-MM-DD")
        : "",
      start_time: activity ? activity.start_time : null,
      end_time: activity ? activity.end_time : null,
      speaker_id: activity ? activity.speaker_user_id : null,
      doctor_id: activity ? activity.doctor_id : null,
      co_author_id: activity ? activity.co_author_id : null,
      display_type_id: activity?.ActivitySubDetail
        ? activity?.ActivitySubDetail?.activity_display_type_id
        : null,
      content_type_id: activity?.ActivitySubDetail
        ? activity?.ActivitySubDetail?.content_type_id
        : null,
      attachment_type_id:
        activity?.ActivityMediaFile &&
        activity?.ActivityMediaFile[0]?.attachment_type_id !== null
          ? activity.ActivityMediaFile[0]?.attachment_type_id
          : null,
      book_name: activity?.ActivityMediaFile
        ? activity.ActivityMediaFile[0]?.book_name
        : "",
      chapter_name: activity?.ActivityMediaFile
        ? activity.ActivityMediaFile[0]?.chapter_name
        : "",
      // approval_status: activity?.approval_status
      //   ? activity?.approval_status
      //   : "",
      approval_status: "",
      remark: activity?.remark ? activity?.remark : "",
      fromUser: fromUser,
      certificate: "",
      task_type_id: activity?.ActivitySubDetail?.task_type_id
        ? activity?.ActivitySubDetail?.task_type_id
        : null,
      amountOfExaminee: activity?.ActivitySubDetail?.amount_of_examinee
        ? activity?.ActivitySubDetail?.amount_of_examinee
        : null,
      questions: activity?.Question?.length > 0 ? activity?.Question : [],
    },
    validationSchema: Yup.object().shape({
      ...customValidation,
    }),
    onSubmit: (values) => {
      const data = new FormData();
      if (values.attachments != null) {
        for (let i = 0; i < values.attachments.length; i++) {
          data.append("attachments", values.attachments[i]);
        }
      }

      data.append("quizQuestions", JSON.stringify(quizQuetions));
      data.append("activity_id", values.activity_id);
      data.append("activity_image", selectedActivityImage);
      data.append("activity_name", values.activity_name);
      data.append("activity_group", values.activity_group);
      data.append("activity_sub_type", values.activity_sub_type);
      data.append("description", values.description);
      data.append("institute_id", values.institute_id);
      data.append("attachments", values.attachments);
      data.append("quiz_type_id", values.quiz_type_id);
      data.append("type", values.type);
      data.append("address", values.address);
      data.append("latitude", values.latitude);
      data.append("longitude", values.longitude);
      data.append("online_meeting_url", values.online_meeting_url);
      data.append("activity_date", values.activity_date);
      data.append("start_time", values.start_time);
      data.append("end_time", values.end_time);
      data.append("speaker_id", values.speaker_id);
      data.append("doctor_id", values.doctor_id);
      data.append("co_author_id", values.co_author_id);
      data.append("display_type_id", values.display_type_id);
      data.append("content_type_id", values.content_type_id);
      data.append("attachment_type_id", values.attachment_type_id);
      data.append("book_name", values.book_name);
      data.append("chapter_name", values.chapter_name);
      data.append("approval_status", values.approval_status);
      data.append("remark", values.remark);
      data.append("fromUser", instituteID ? "Institute" : "Doctor");
      data.append("certificate", certificate);
      data.append("task_type_id", values.task_type_id);
      data.append("amountOfExaminee", values.amountOfExaminee);
      data.append("isTeacherSubmittedTask", isTeacherSubmittedTask);
      data.append("isExaminnerSubmittedTask", isExaminnerSubmittedTask);
      data.append("isTeacherCheckResultSubmittedTask", isTeacherCheckResultSubmittedTask);
      CustomActivityApi.copyActivity(data)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then((result) => {
              if (result?.isConfirmed) {
                window.close();
                window.location.reload(config.ADMIN_URL);
              }
              if (show) {
                window.location.href = "/my-activity";
              }
            });
            // window.location.href = "/my-activity";
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
          } else {
            err.response.data.error.errors?.map((erritem, errindex) => {});
          }
        });
    },
  });

  const onAddViewWuizHandler = () => {
    window.$("#addEditQuizModal").modal("show");
  };

  const onValidationHandler = (fieldName, value) => {
    const customValidationCopy = _.cloneDeep(customValidation);
    if (fieldName === "type") {
      if (value === "Online") {
        customValidationCopy.online_meeting_url = Yup.string().required(
          props.t("Required-Message")
        );
      }
      if (value === "Onsite") {
        customValidationCopy.address = Yup.string().required(
          props.t("Required-Message")
        );
        customValidationCopy.latitude = Yup.string().required(
          props.t("Required-Message")
        );
        customValidationCopy.longitude = Yup.string().required(
          props.t("Required-Message")
        );
      }
    }
    if (
      fieldName === "approval_status" &&
      value === constants.APPROVAL_STATUS.Approved
    ) {
      if (
        activity?.ActivityMediaFile.length <= 0 &&
        activity?.ActivityType?.type === "internship" &&
        activityTypeJson?.score_type_id !== constants.SCORE_TYPE.EXAM_WITH_QUIZ
      ) {
        customValidationCopy.certificate = Yup.string().required(
          props.t("Required-Message")
        );
      }
    } else {
      customValidationCopy.certificate = undefined;
    }
    setCustomValidation(customValidationCopy);
  };

  const removeAttachment = (lastModified) => {
    const updatedList = [
      ...formik.values.attachments.filter(
        (e) => e.lastModified !== lastModified
      ),
    ];
    formik.setFieldValue(
      "attachments",
      updatedList.length > 0 ? updatedList : null
    );
  };

  const removeMedia = (activity_id, activity_media_id, type, index) => {
    Swal.fire({
      title: props.t("Are-you-sure"),
      text: props.t("Able-to-revert-this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes-delete-it"),
      cancelButtonText: props.t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        let activityMediaId = {
          activity_id: activity_id,
          activity_media_id: activity_media_id,
          type: type,
        };
        CustomActivityApi.removeActivityMedia(activityMediaId)
          .then((res) => {
            if (res.data.success) {
              const existFiles = _.cloneDeep(files);
              existFiles.splice(index, 1);
              setActivityFile(existFiles);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const getAllDoctorsPaginate = () => {
    CustomActivityApi.getAllDoctorsApi(doctorPaginateData)
      .then((res) => {
        if (res.data.success) {
          const pageNo = doctorPaginateData.page;
          setDoctorPaginateData({
            ...doctorPaginateData,
            totalRecords: res.data.data.count,
            page: res.data.data.metadata.page,
            limit: res.data.data.metadata.limit,
          });
          if (pageNo === 1) {
            setDoctorsData([...res.data.data.rows]);
          } else {
            if (res.data.data.rows.length === 0) {
              setIsMoreDoctors(false);
            }
            setDoctorsData([...doctorsData, ...res.data.data.rows]);
          }
        } else {
          setDoctorsData([]);
        }
      })
      .catch((err) => {
        setDoctorsData([]);
      });
  };

  const getAllInstitutesPaginate = (searchKeyword = "") => {
    if (searchKeyword !== "") {
      institutesPaginateData.search = searchKeyword;
    }
    CustomActivityApi.getAllInstitutesApi(institutesPaginateData)
      .then((res) => {
        if (res.data.success) {
          const pageNo = institutesPaginateData.page;
          setinstitutesPaginateData({
            ...institutesPaginateData,
            totalRecords: res.data.data.count,
            page: res.data.data.metadata.page,
            limit: res.data.data.metadata.limit,
          });
          if (pageNo === 1) {
            setinstitutesData([...res.data.data.rows]);
          } else {
            if (res.data.data.rows.length === 0) {
              setIsMoreInstitutes(false);
              setIsInstituteLoading(false);
            }
            setinstitutesData([...institutesData, ...res.data.data.rows]);
          }
        } else {
          setinstitutesData([]);
        }
      })
      .catch((err) => {
        setinstitutesData([]);
      });
  };

  const onScrollDoctorListHandler = _.debounce(async function () {
    if (isMoreDoctors) {
      setIsDoctorLoading(true);
      getAllDoctorsPaginate();
    }
  }, 500);

  const onScrollInstituesDataHandler = _.debounce(async function () {
    if (isMoreInstitutes) {
      setIsInstituteLoading(true);
      getAllInstitutesPaginate();
    }
  }, 500);

  const onSearchDoctorHandler = _.debounce(async function (searchInput) {
    setDoctorPaginateData({
      ...doctorPaginateData,
      page: 1,
      search: searchInput,
    });
    if (searchInput.length >= 2) getAllDoctorsPaginate(searchInput);
  }, 500);

  const onSearchInstitutesHandler = _.debounce(async function (searchInput) {
    setinstitutesPaginateData({
      ...institutesPaginateData,
      page: 1,
      search: searchInput,
    });
    if (searchInput.length >= 3) getAllInstitutesPaginate(searchInput);
  }, 500);

  const onOpenInstituteMenuHandler = () => {
    setinstitutesPaginateData({
      ...institutesPaginateData,
      page: 1,
    });
    getAllInstitutesPaginate();
  };

  const onOpenDoctorMenuHandler = () => {
    setDoctorPaginateData({
      ...doctorPaginateData,
      page: 1,
    });
    getAllDoctorsPaginate();
  };

  return (
    <div>
      {show && <Navbar />}
      <ScrollToTopOnMount />
      <MobPageTitleBar
        isBackShow={show ? true : false}
        title={`${props.t("Copy")} ${props.t("Activity")}`}
        mobBackUrl="/profile"
      />
      {show && <ProfileTitle title={props.t("Profile")} />}

      {!invalidSession && (
        <section className="dashboard top-curve2 mob-brd-radius-0">
          <div className="container">
            <div className="row p-8">
              {show && <ProfileSidebar />}
              <div className={show ? "col-md-10" : "col-md-12"}>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="mb-3 d-m-none">
                      {props.t("Copy")} {props.t("Activity")}
                    </h2>
                  </div>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  <div className="dashboard-inner application-form">
                    {activity?.approval_status ===
                      constants.APPROVAL_STATUS.Rejected && (
                      <>
                        {/* <div className="form-group">
                          <label className="form-label">{props.t("Status")}</label> {" : "} 
                          <label className="form-label" style={{color: "#f46a6a",}}>
                            {activity.approval_status}
                          </label>
                        </div>  */}
                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Rejected")} {props.t("Comment")}
                          </label>{" "}
                          {" : "}
                          <label
                            className="form-label"
                            style={{ color: "#f46a6a" }}
                          >
                            {activity.remark}
                          </label>
                        </div>
                      </>
                    )}

                    <div className="form-group mb-4">
                      <label className="form-label">{props.t("Image")}</label>
                      <div className="d-flex">
                        <div className="logoContainer">
                          {selectedActivityImage || image ? (
                            <img
                              src={
                                selectedActivityImage
                                  ? URL.createObjectURL(selectedActivityImage)
                                  : image
                              }
                            />
                          ) : (
                            <img
                              src={
                                config.BASE_URL +
                                "/assets/images/no-preview.jpg"
                              }
                            />
                          )}
                        </div>
                        <div className="fileContainer sprite">
                          <span>{props.t("Change")}</span>
                          <Input
                            name="activity_image"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={(e) => {
                              if (e.target.files && e.target.files.length > 0) {
                                setSelectedActivityImage(e.target.files[0]);
                              }
                            }}
                            invalid={
                              formik.touched.activity_image &&
                              formik.errors.activity_image
                                ? true
                                : false
                            }
                          />
                          <strong>
                            PNG, JPG <br /> (recommended: square size)
                          </strong>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <label className="form-label">
                        {" "}
                        {props.t("Activity-Name")}{" "}
                        <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        name="activity_name"
                        className={
                          formik.touched.activity_name &&
                          formik.errors.activity_name
                            ? "form-control bor"
                            : "form-control text-dark"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.activity_name || ""}
                        placeholder={props.t("Activity-Name")}
                        readOnly={
                          userDetail?.id != activity?.created_by
                            ? "readOnly"
                            : ""
                        }
                      />
                      {formik.touched.activity_name &&
                        formik.errors.activity_name && (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.activity_name}
                          </div>
                        )}
                    </div>

                    <div className="form-group mb-4">
                      <label className="form-label">
                        {props.t("Activity")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        onChange={(e) => {
                          formik.setFieldValue("activity_group", e?.label);
                          fetchActivitySubTypes(e?.label);
                        }}
                        onBlur={formik.handleBlur}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderRadius: "50px",
                          }),
                        }}
                        name="activity_group"
                        isDisabled={true}
                        value={{
                          value: `${
                            formik.values.activity_group
                              ? formik.values.activity_group
                              : ""
                          }`,
                          label: `${
                            activity.ActivityType
                              ? activity.ActivityType.activity_group
                              : ""
                          }`,
                        }}
                        className={
                          formik.touched.activity_group &&
                          formik.errors.activity_group
                            ? " bor"
                            : " text-dark"
                        }
                        options={activityTypes.map((value) => ({
                          value: `${value.id}`,
                          label: `${value.activity_group}`,
                        }))}
                      />
                      {formik.touched.activity_group &&
                        formik.errors.activity_group && (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.activity_group}
                          </div>
                        )}
                    </div>

                    <div className="form-group mb-4">
                      <label htmlFor="" className="form-label">
                        {props.t("Sub-Activity")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        onChange={(e) => {
                          formik.setFieldValue("activity_sub_type", e?.value);
                          getActivityTypeJson(e?.label);
                        }}
                        onBlur={formik.handleBlur}
                        name="activity_sub_type"
                        isDisabled={true}
                        value={{
                          value: `${
                            formik.values.activity_sub_type
                              ? formik.values.activity_sub_type
                              : ""
                          }`,
                          label: `${
                            activity.ActivityType
                              ? activity.ActivityType.code
                              : ""
                          }`,
                        }}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderRadius: "50px",
                          }),
                        }}
                        className={
                          formik.touched.activity_sub_type &&
                          formik.errors.activity_sub_type
                            ? "bor"
                            : "text-dark"
                        }
                        options={activitySubTypes.map((value) => ({
                          value: `${value.id}`,
                          label: `${value.code}`,
                        }))}
                      />
                      {formik.touched.activity_sub_type &&
                        formik.errors.activity_sub_type && (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.activity_sub_type}
                          </div>
                        )}
                    </div>

                    <div className="form-group mb-4">
                      <label className="form-label">
                        {props.t("Description")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        rows={5}
                        className={
                          formik.touched.description &&
                          formik.errors.description
                            ? "form-control bor"
                            : "form-control text-dark"
                        }
                        name="description"
                        placeholder={props.t("Description")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description || ""}
                        style={{ height: "unset" }}
                        readOnly={
                          userDetail?.id != activity?.created_by
                            ? "readOnly"
                            : ""
                        }
                      />
                      {formik.touched.description &&
                        formik.errors.description && (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.description}
                          </div>
                        )}
                    </div>

                    {isInstituteFiledEnable && (
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("Institution")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={
                            formik.touched.institute_id &&
                            formik.errors.institute_id
                              ? " bor"
                              : " text-dark  "
                          }
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          onChange={(e) => {
                            formik.setFieldValue("institute_id", e?.value);
                          }}
                          onBlur={formik.handleBlur}
                          name="institute_id"
                          defaultValue={{
                            value: `${
                              activity.institute_id ? activity.institute_id : ""
                            }`,
                            label: `${
                              activity.Institute
                                ? activity.Institute.institution_name
                                : ""
                            }`,
                          }}
                          onMenuScrollToBottom={onScrollInstituesDataHandler}
                          onInputChange={onSearchInstitutesHandler}
                          onMenuOpen={onOpenInstituteMenuHandler}
                          options={[
                            ...institutesData,
                            // isInstituteLoading
                            //   ? {
                            //       value: "loading",
                            //       label: "Loading more...",
                            //       disabled: true,
                            //     }
                            //   : {},
                          ]}
                        />
                        {formik.touched.institute_id &&
                          formik.errors.institute_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.institute_id}
                            </div>
                          )}
                      </div>
                    )}

                    {defaultSubActivityTask && (
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("subActivity")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={
                            formik.touched.task_type_id &&
                            formik.errors.task_type_id
                              ? " bor"
                              : " text-dark  "
                          }
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          onChange={(e) => {
                            formik.setFieldValue("task_type_id", e?.value);
                          }}
                          value={defaultSubActivityTask}
                          onBlur={formik.handleBlur}
                          name="task_type_id"
                          isDisabled={
                            roleID === constants.ROLE.INSTITUTE ||
                            roleID === constants.ROLE.SUBINSTITUTE ||
                            roleID === constants.ROLE.SUPERADMIN
                              ? true
                              : false
                          }
                          options={subActivityTask.map((value) => ({
                            value: value.id,
                            label: `${value.name_en}`,
                          }))}
                        />
                        {formik.touched.task_type_id &&
                          formik.errors.task_type_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.task_type_id}
                            </div>
                          )}
                      </div>
                    )}

                    {(formik.values?.task_type_id ===
                      constants.TASKTYPE.INTERVIEWTEST ||
                      formik.values?.task_type_id ===
                        constants.TASKTYPE.MEQSHORTASSAY) &&
                      (parseInt(roleID) === constants.ROLE.DOCTOR ||
                        parseInt(roleID) === constants.ROLE.INSTITUTE ||
                        parseInt(roleID) == constants.ROLE.SUPERADMIN) && (
                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("amountOfExaminee")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            name="amountOfExaminee"
                            className={
                              formik.touched.amountOfExaminee &&
                              formik.errors.amountOfExaminee
                                ? "form-control bor"
                                : "form-control text-dark"
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.amountOfExaminee}
                            placeholder={props.t("amountOfExaminee")}
                            disabled={
                              roleID === constants.ROLE.INSTITUTE ||
                              roleID === constants.ROLE.SUBINSTITUTE ||
                              roleID === constants.ROLE.SUPERADMIN
                                ? true
                                : false
                            }
                          />
                          {formik.touched.amountOfExaminee &&
                            formik.errors.amountOfExaminee && (
                              <div
                                style={{ color: "#f46a6a", fontSize: "11px" }}
                              >
                                {formik.errors.amountOfExaminee}
                              </div>
                            )}
                        </div>
                      )}

                    {activityTypeJson.can_attach_file && (
                      <>
                        {activityTypeJson.score_type_id ==
                          constants.SCORE_TYPE.FILE_ATTACHMENT && (
                          <div className="form-group mb-4">
                            <label htmlFor="" className="form-label">
                              {props.t("Attachment-Type")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "attachment_type_id",
                                  e?.value
                                );
                              }}
                              onBlur={formik.handleBlur}
                              name="attachment_type_id"
                              defaultValue={{
                                value: `${
                                  activity?.ActivityMediaFile.length > 0
                                    ? activity?.ActivityMediaFile[0]
                                        ?.AttachmentType?.id
                                    : ""
                                }`,
                                label: `${
                                  activity?.ActivityMediaFile.length > 0
                                    ? activity?.ActivityMediaFile[0]
                                        ?.AttachmentType?.name_en
                                    : ""
                                }`,
                              }}
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  borderRadius: "50px",
                                }),
                              }}
                              className={
                                formik.touched.attachment_type_id &&
                                formik.errors.attachment_type_id
                                  ? "bor"
                                  : "text-dark"
                              }
                              options={attachmentTypes.map((value) => ({
                                value: `${value.id}`,
                                label: `${value.name_en}`,
                              }))}
                              isDisabled={
                                userDetail?.id != activity?.created_by
                                  ? true
                                  : false
                              }
                            />
                            {formik.touched.attachment_type_id &&
                              formik.errors.attachment_type_id && (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {formik.errors.attachment_type_id}
                                </div>
                              )}
                          </div>
                        )}

                        {(defaultSubActivityTask?.value !==
                          constants.TASKTYPE.INTERVIEWTEST ||
                          roleID === constants.ROLE.DOCTOR) && (
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("Attachment")}
                            </label>
                            <div className="fileupload">
                              <div className="text-center">
                                <label>
                                  <a
                                    className="btn btnUpload"
                                    role="button"
                                    aria-disabled="false"
                                  >
                                    <img src="/assets/images/preview.png" />
                                    <br />
                                    <strong className="text-blue">
                                      {props.t("Upload")}{" "}
                                      {props.t("Attachments")}
                                    </strong>
                                    <br />
                                    <strong className="note">
                                      PNG, JPG, GIF, PDF, DOCX
                                    </strong>
                                    <br />
                                    <strong
                                      style={{
                                        color: "#f46a6a",
                                        fontSize: "11px",
                                        marginTop: "3px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {props.t("File-Max-Size-Message")}
                                    </strong>
                                  </a>
                                  <Input
                                    name="attachments"
                                    type="file"
                                    multiple
                                    className="form-control upload__inputfile"
                                    onBlur={(e) => {
                                      if (
                                        e.target.files.length == 0 &&
                                        formik.values.attachments &&
                                        formik.values.attachments.length == 0
                                      ) {
                                        formik.setFieldValue(
                                          "attachments",
                                          null
                                        );
                                      }
                                    }}
                                    onChange={(e) => {
                                      const filesArray2 = [];
                                      for (
                                        let i = 0;
                                        i < e.target.files.length;
                                        i++
                                      ) {
                                        filesArray2.push(e.target.files[i]);
                                      }
                                      formik.setFieldValue(
                                        "attachments",
                                        formik.values.attachments
                                          ? [
                                              ...formik.values.attachments,
                                              ...filesArray2,
                                            ]
                                          : filesArray2
                                      );
                                    }}
                                    invalid={
                                      formik.touched.attachments &&
                                      formik.errors.attachments
                                        ? true
                                        : false
                                    }
                                  />
                                </label>
                              </div>
                              <div className="pdf-block">
                                <ul>
                                  {files?.length > 0
                                    ? files?.map((item, index) => {
                                        return (
                                          <li key={index}>
                                            <div className="d-flex align-items-center mb-3 row">
                                              <div className="col-1">
                                                <img
                                                  src="/assets/images/link.png"
                                                  alt="link"
                                                  width="15"
                                                />
                                              </div>
                                              <div className="col-10 text">
                                                <a
                                                  href={item.file_url}
                                                  target="_blank"
                                                  className="text-blue"
                                                >
                                                  {item.name}
                                                </a>
                                              </div>
                                              <div className="col-1">
                                                <a
                                                  onClick={() =>
                                                    removeMedia(
                                                      item.activity_id,
                                                      item.id,
                                                      "FILE",
                                                      index
                                                    )
                                                  }
                                                  title="Remove"
                                                >
                                                  <img
                                                    src={require("assets/images/delete-icon.png")}
                                                    width="15"
                                                  />
                                                </a>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      })
                                    : null}

                                  {formik.values.attachments?.length > 0
                                    ? formik.values.attachments?.map(
                                        (item, index) => {
                                          return (
                                            <li key={index}>
                                              <div className="d-flex align-items-center mb-3 row">
                                                <div className="col-1">
                                                  <img
                                                    src={require("assets/images/link.png")}
                                                    alt="link"
                                                    width="15"
                                                  />
                                                </div>
                                                <div className="col-10 text">
                                                  <a
                                                    // href={URL.createObjectURL(item)}
                                                    target="_blank"
                                                    className="text-blue"
                                                  >
                                                    {item.name}
                                                  </a>
                                                </div>
                                                <div className="col-1">
                                                  <a
                                                    onClick={(e) => {
                                                      removeAttachment(
                                                        item.lastModified
                                                      );
                                                    }}
                                                    title="Remove"
                                                  >
                                                    <img
                                                      src={require("assets/images/delete-icon.png")}
                                                      width="15"
                                                    />
                                                  </a>
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )
                                    : null}
                                </ul>

                                <div className="clearfix"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {(activityTypeJson.can_add_quiz ||
                      activity?.ActivitySubDetail?.quiz_type_id) && (
                      <>
                        <div className="form-group mb-4">
                          <label className="form-label">
                            {props.t("Quiz-Type")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Select
                            onChange={(e) => {
                              formik.setFieldValue("quiz_type_id", e?.value);
                            }}
                            value={selectedQuizTypeValue}
                            onBlur={formik.handleBlur}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderRadius: "50px",
                              }),
                            }}
                            name="quiz_type_id"
                            isDisabled={true}
                            className={
                              formik.touched.quiz_type_id &&
                              formik.errors.quiz_type_id
                                ? " bor"
                                : " text-dark"
                            }
                            options={quizTypes}
                          />
                          {formik.touched.quiz_type_id &&
                            formik.errors.quiz_type_id && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.quiz_type_id}
                              </div>
                            )}
                        </div>

                        {isInstituteFiledEnable && (
                          <div className="form-group mb-4 row">
                            <div className="col-md-1 col-1">
                              <img
                                src="/assets/images/plus.png"
                                alt="link"
                                width="15"
                              />
                            </div>
                            <div className="col-md-11 col-11">
                              <a
                                href="#"
                                onClick={onAddViewWuizHandler}
                                className="text-blue"
                              >
                                {activity?.Question.length > 0
                                  ? `${props.t("Edit")} ${props.t("Quiz")}`
                                  : `${props.t("Add")} ${props.t("Quiz")}`}
                              </a>
                              {addedQuizCount !== 0 && (
                                <span
                                  style={{ color: "#198754" }}
                                >{`  (${addedQuizCount} Question Added)`}</span>
                              )}
                            </div>
                          </div>
                        )}

                        {!isTeacherSubmittedTask &&
                          activity?.Question.length > 0 &&
                          !isInstituteFiledEnable && (
                            <div>
                              <QuizQuestionList
                                from="Edit"
                                onEditQuizQuestionHanlder=""
                                onDeletetQuizQuestionHanlder=""
                                isInstituteFiledEnable={isInstituteFiledEnable}
                                questions={activity?.Question}
                              />
                            </div>
                          )}
                        <AddEditQuizModal
                          setAddedQuizCout={setAddedQuizCout}
                          setQuizQuestions={setQuizQuestions}
                          maxQuestion={activity?.QuizType?.max_questions}
                          selectedQuizType={selectedQuizType}
                          questions={activity?.Question}
                          isInstituteFiledEnable={isInstituteFiledEnable}
                          activityId={activity?.id}
                        />
                      </>
                    )}

                    {activityTypeJson.can_other_join_activity ||
                      (formik.values.task_type_id ===
                        constants.TASKTYPE.REVIEWEXAM &&
                        (parseInt(roleID) === constants.ROLE.INSTITUTE ||
                          parseInt(roleID) == constants.ROLE.SUPERADMIN) && (
                          <>
                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("Type")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                onChange={(e) => {
                                  formik.setFieldValue("type", e?.value);
                                }}
                                onBlur={formik.handleBlur}
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    borderRadius: "50px",
                                  }),
                                }}
                                name="type"
                                isDisabled={
                                  userDetail?.id != activity?.created_by
                                    ? true
                                    : false
                                }
                                value={{
                                  value: `${
                                    activity.type ? activity.type : ""
                                  }`,
                                  label: `${
                                    activity.type ? activity.type : ""
                                  }`,
                                }}
                                className={
                                  formik.touched.type && formik.errors.type
                                    ? " bor"
                                    : " text-dark"
                                }
                                options={Object.keys(constants.TYPE).map(
                                  (key) => ({
                                    value: `${key}`,
                                    label: `${key}`,
                                  })
                                )}
                              />
                              {formik.touched.type && formik.errors.type && (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {formik.errors.type}
                                </div>
                              )}
                            </div>

                            {formik.values.type &&
                              formik.values.type == "Onsite" && (
                                <div className="form-group mb-4">
                                  <label className="form-label">
                                    {props.t("Address")}{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="address"
                                    className={
                                      formik.touched.address &&
                                      formik.errors.address
                                        ? "form-control bor"
                                        : "form-control text-dark"
                                    }
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address || ""}
                                    placeholder={props.t("Address")}
                                  />
                                </div>
                              )}

                            {formik.values.type &&
                              formik.values.type == "Onsite" && (
                                <div className="form-group mb-4">
                                  <label className="form-label">
                                    {props.t("Latitude")}{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="latitude"
                                    className={
                                      formik.touched.latitude &&
                                      formik.errors.latitude
                                        ? "form-control bor"
                                        : "form-control text-dark"
                                    }
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.latitude || ""}
                                    placeholder={props.t("Latitude")}
                                  />
                                </div>
                              )}

                            {formik.values.type &&
                              formik.values.type == "Onsite" && (
                                <div className="form-group mb-4">
                                  <label className="form-label">
                                    {props.t("Longitude")}{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="longitude"
                                    className={
                                      formik.touched.longitude &&
                                      formik.errors.longitude
                                        ? "form-control bor"
                                        : "form-control text-dark"
                                    }
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.longitude || ""}
                                    placeholder={props.t("Longitude")}
                                  />
                                </div>
                              )}

                            {formik.values.type &&
                              formik.values.type == "Online" && (
                                <div className="form-group mb-4">
                                  <label className="form-label">
                                    {props.t("Online-Meeting-Url")}{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="online_meeting_url"
                                    className={
                                      formik.touched.online_meeting_url &&
                                      formik.errors.online_meeting_url
                                        ? "form-control bor"
                                        : "form-control text-dark"
                                    }
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.online_meeting_url}
                                    placeholder={props.t("Online-Meeting-Url")}
                                  />
                                </div>
                              )}

                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("Activity")} {props.t("Date")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                name="activity_date"
                                type="date"
                                className={
                                  formik.touched.activity_date &&
                                  formik.errors.activity_date
                                    ? "form-control bor"
                                    : "form-control text-dark"
                                }
                                onChange={formik.handleChange}
                                value={formik.values.activity_date || ""}
                              />
                              {formik.touched.activity_date &&
                                formik.errors.activity_date && (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {formik.errors.activity_date}
                                  </div>
                                )}
                            </div>

                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("Start-Time")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                name="start_time"
                                type="time"
                                className={
                                  formik.touched.start_time &&
                                  formik.errors.start_time
                                    ? "form-control bor"
                                    : "form-control text-dark"
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // min={moment().format("Y-M-D")}
                                value={formik.values.start_time || ""}
                                invalid={
                                  formik.touched.start_time &&
                                  formik.errors.start_time
                                    ? true
                                    : false
                                }
                              />
                            </div>

                            <div className="form-group mb-4">
                              <label className="form-label">
                                {props.t("End-Time")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                name="end_time"
                                type="time"
                                className={
                                  formik.touched.end_time &&
                                  formik.errors.end_time
                                    ? "form-control bor"
                                    : "form-control text-dark"
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // min={moment().format("Y-M-D")}
                                value={formik.values.end_time || ""}
                                invalid={
                                  formik.touched.end_time &&
                                  formik.errors.end_time
                                    ? true
                                    : false
                                }
                              />
                            </div>

                            {!activity?.ActivitySubDetail?.task_type_id ||
                              activity?.ActivitySubDetail?.task_type_id !==
                                constants.TASKTYPE.INTERVIEWTEST ||
                              (formik.values.task_type_id !==
                                constants.TASKTYPE.REVIEWEXAM && (
                                <div className="form-group mb-4">
                                  <label className="form-label">
                                    {props.t("Speaker")}
                                  </label>
                                  <Select
                                    className={
                                      formik.touched.speaker_id &&
                                      formik.errors.speaker_id
                                        ? " bor"
                                        : " text-dark  "
                                    }
                                    styles={{
                                      control: (styles) => ({
                                        ...styles,
                                        borderRadius: "50px",
                                      }),
                                    }}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "speaker_id",
                                        e?.value
                                      );
                                    }}
                                    onMenuScrollToBottom={
                                      onScrollDoctorListHandler
                                    }
                                    onInputChange={onSearchDoctorHandler}
                                    onMenuOpen={onOpenDoctorMenuHandler}
                                    defaultValue={formik.values.speaker_id}
                                    onBlur={formik.handleBlur}
                                    name="speaker_id"
                                    value={{
                                      value: `${
                                        activity.speaker_user_id
                                          ? activity.speaker_user_id
                                          : ""
                                      }`,
                                      label: `${
                                        activity.SpeakerUser
                                          ? activity.SpeakerUser.first_name +
                                            " " +
                                            activity.SpeakerUser.last_name
                                          : ""
                                      }`,
                                    }}
                                    options={doctorsData.map((value) => ({
                                      value: value.id,
                                      label:
                                        value.first_name +
                                        " " +
                                        value.last_name,
                                    }))}
                                    placeholder={
                                      <div>{props.t("typeToSearch")}</div>
                                    }
                                  />
                                  {formik.touched.speaker_id &&
                                    formik.errors.speaker_id && (
                                      <div
                                        style={{
                                          color: "#f46a6a",
                                          fontSize: "11px",
                                          marginTop: "3px",
                                        }}
                                      >
                                        {formik.errors.speaker_id}
                                      </div>
                                    )}
                                </div>
                              ))}
                          </>
                        ))}

                    {((activityTypeJson.assign_as_task &&
                      (constants.ROLE.INSTITUTE === roleID ||
                        roleID === constants.ROLE.SUPERADMIN) &&
                      formik.values.doctor_id !== null) ||
                      (constants.ROLE.DOCTOR === roleID &&
                        activityTypeJson.score_type_id ===
                          constants.SCORE_TYPE.GIVING_ADVISE &&
                        activityTypeJson.approval_rquired === false)) && (
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("Doctor")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={
                            formik.touched.doctor_id && formik.errors.doctor_id
                              ? " bor"
                              : " text-dark  "
                          }
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          onChange={(e) => {
                            formik.setFieldValue("doctor_id", e?.value);
                          }}
                          onMenuScrollToBottom={onScrollDoctorListHandler}
                          onInputChange={onSearchDoctorHandler}
                          onMenuOpen={onOpenDoctorMenuHandler}
                          defaultValue={formik.values.doctor_id}
                          onBlur={formik.handleBlur}
                          name="doctor_id"
                          value={{
                            value: `${
                              activity.doctor_id ? activity.doctor_id : ""
                            }`,
                            label: `${
                              activity.Doctor
                                ? activity.Doctor.first_name +
                                  " " +
                                  activity.Doctor.last_name
                                : ""
                            }`,
                          }}
                          isDisabled={
                            roleID === constants.ROLE.INSTITUTE ||
                            roleID === constants.ROLE.SUBINSTITUTE ||
                            roleID === constants.ROLE.SUPERADMIN
                              ? true
                              : false
                          }
                          options={doctorsData.map((value) => ({
                            value: value.id,
                            label: value.first_name + " " + value.last_name,
                          }))}
                          placeholder={<div>{props.t("typeToSearch")}</div>}
                        />
                        {formik.touched.doctor_id &&
                          formik.errors.doctor_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.doctor_id}
                            </div>
                          )}
                      </div>
                    )}

                    {activityTypeJson.score_type_id ==
                      constants.SCORE_TYPE.DISPLAY_CONTENT_PROVIDING && (
                      <div className="form-group mb-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Activity-Display-Type")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            formik.setFieldValue("display_type_id", e?.value);
                          }}
                          onBlur={formik.handleBlur}
                          name="display_type_id"
                          defaultValue={{
                            value: `${
                              activity.ActivitySubDetail
                                ? activity.ActivitySubDetail.display_type_id
                                : ""
                            }`,
                            label: `${
                              activity.ActivitySubDetail.ActivityDisplayType
                                ? activity.ActivitySubDetail.ActivityDisplayType
                                    .name_en
                                : ""
                            }`,
                          }}
                          isDisabled={
                            roleID === constants.ROLE.INSTITUTE ||
                            roleID === constants.ROLE.SUBINSTITUTE ||
                            roleID === constants.ROLE.SUPERADMIN
                              ? true
                              : false
                          }
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          className={
                            formik.touched.display_type_id &&
                            formik.errors.display_type_id
                              ? "bor"
                              : "text-dark"
                          }
                          options={activityDisplayTypes.map((value) => ({
                            value: `${value.id}`,
                            label: `${value.name_en}`,
                          }))}
                        />
                        {formik.touched.display_type_id &&
                          formik.errors.display_type_id && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.display_type_id}
                            </div>
                          )}
                      </div>
                    )}

                    {activityTypeJson.score_type_id ==
                      constants.SCORE_TYPE.CONTENT_TYPE && (
                      <>
                        <div className="form-group mb-4">
                          <label htmlFor="" className="form-label">
                            {props.t("Activity-Content-Type")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            onChange={(e) => {
                              formik.setFieldValue("content_type_id", e?.value);
                            }}
                            onBlur={formik.handleBlur}
                            name="content_type_id"
                            defaultValue={{
                              value: `${
                                activity.ActivitySubDetail
                                  ? activity.ActivitySubDetail.display_type_id
                                  : ""
                              }`,
                              label: `${
                                activity.ActivitySubDetail.ActivityContentType
                                  ? activity.ActivitySubDetail
                                      .ActivityContentType.name_en
                                  : ""
                              }`,
                            }}
                            isDisabled={
                              roleID === constants.ROLE.INSTITUTE ||
                              roleID === constants.ROLE.SUBINSTITUTE ||
                              roleID === constants.ROLE.SUPERADMIN
                                ? true
                                : false
                            }
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderRadius: "50px",
                              }),
                            }}
                            className={
                              formik.touched.content_type_id &&
                              formik.errors.content_type_id
                                ? "bor"
                                : "text-dark"
                            }
                            options={activityContentTypes.map((value) => ({
                              value: `${value.id}`,
                              label: `${value.name_en}`,
                            }))}
                          />
                          {formik.touched.content_type_id &&
                            formik.errors.content_type_id && (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.content_type_id}
                              </div>
                            )}
                        </div>

                        {((formik.values.content_type_id &&
                          formik.values.content_type_id ==
                            constants.CONTENT_TYPE.PUBMED_SCOPUS) ||
                          (formik.values.content_type_id &&
                            formik.values.content_type_id ==
                              constants.CONTENT_TYPE.TCI)) && (
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {props.t("Co-Author")}
                            </label>
                            <Select
                              className={
                                formik.touched.co_author_id &&
                                formik.errors.co_author_id
                                  ? " bor"
                                  : " text-dark  "
                              }
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  borderRadius: "50px",
                                }),
                              }}
                              onChange={(e) => {
                                formik.setFieldValue("co_author_id", e?.value);
                              }}
                              onMenuScrollToBottom={onScrollDoctorListHandler}
                              onInputChange={onSearchDoctorHandler}
                              onMenuOpen={onOpenDoctorMenuHandler}
                              onBlur={formik.handleBlur}
                              name="co_author_id"
                              defaultValue={{
                                value: `${
                                  activity.co_author_id
                                    ? activity.co_author_id
                                    : ""
                                }`,
                                label: `${
                                  activity.CoAuthor
                                    ? activity.CoAuthor.first_name +
                                      " " +
                                      activity.CoAuthor.last_name
                                    : ""
                                }`,
                              }}
                              isDisabled={
                                roleID === constants.ROLE.INSTITUTE ||
                                roleID === constants.ROLE.SUBINSTITUTE ||
                                roleID === constants.ROLE.SUPERADMIN
                                  ? true
                                  : false
                              }
                              options={doctorsData.map((value) => ({
                                value: value.id,
                                label: value.first_name + " " + value.last_name,
                              }))}
                              placeholder={<div>{props.t("typeToSearch")}</div>}
                            />
                            {formik.touched.co_author_id &&
                              formik.errors.co_author_id && (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {formik.errors.co_author_id}
                                </div>
                              )}
                          </div>
                        )}

                        {formik.values.content_type_id &&
                          formik.values.content_type_id ==
                            constants.CONTENT_TYPE.ACADEMIC_REVIVAL && (
                            <>
                              <div className="form-group mb-4">
                                <label className="form-label">
                                  {props.t("Book-Name")}{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="book_name"
                                  className={
                                    formik.touched.book_name &&
                                    formik.errors.book_name
                                      ? "form-control bor"
                                      : "form-control text-dark"
                                  }
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.book_name || ""}
                                  placeholder={props.t("Book-Name")}
                                />
                                {formik.touched.book_name &&
                                  formik.errors.book_name && (
                                    <div
                                      style={{
                                        color: "#f46a6a",
                                        fontSize: "11px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      {formik.errors.book_name}
                                    </div>
                                  )}
                              </div>
                              <div className="form-group mb-4">
                                <label className="form-label">
                                  {props.t("Chapter-Name")}{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="chapter_name"
                                  className={
                                    formik.touched.chapter_name &&
                                    formik.errors.chapter_name
                                      ? "form-control bor"
                                      : "form-control text-dark"
                                  }
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.chapter_name || ""}
                                  placeholder={props.t("Chapter-Name")}
                                />
                                {formik.touched.chapter_name &&
                                  formik.errors.chapter_name && (
                                    <div
                                      style={{
                                        color: "#f46a6a",
                                        fontSize: "11px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      {formik.errors.chapter_name}
                                    </div>
                                  )}
                              </div>
                            </>
                          )}
                      </>
                    )}
                  </div>
                  {!show && parseInt(roleID) !== constants.ROLE.SUPERADMIN && (
                    <div
                      className="dashboard-inner application-form mt-5"
                      style={{ minHeight: "auto" }}
                    >
                      {!show &&
                        (activityTypeJson?.assign_as_task ||
                          activity?.UserTaskActivity?.task_type_id ===
                            constants.TASKTYPE.REVIEWEXAM) &&
                        activity?.UserTaskActivity?.submited_details && (
                          <>
                            <div className="form-group mb-4">
                              <a
                                href="#"
                                onClick={onViewSubmittedDetailsHandler}
                                title="view"
                              >
                                {props.t("viewSubmittedtDetails")}{" "}
                              </a>
                            </div>
                            <TaskDetailModal data={activity} />
                          </>
                        )}
                      {isTeacherSubmittedTask && (
                        <>
                          <div className="form-group mb-4">
                            <a
                              href="#"
                              onClick={onViewSubmittedDetailsHandler}
                              title="view"
                            >
                              {props.t("viewTeacherSubmittedtDetails")}{" "}
                            </a>
                          </div>
                          <TaskDetailModal
                            data={activity}
                            quizQuestions={teacherQuizQuestions}
                          />
                        </>
                      )}
                      {isExaminnerSubmittedTask && (
                        <>
                          <div className="form-group mb-4">
                            <a
                              href="#"
                              onClick={onViewSubmittedDetailsHandler}
                              title="view"
                            >
                              {props.t("viewExaminnerSubmittedtDetails")}{" "}
                            </a>
                          </div>
                          <TaskDetailModal data={activity} quizQuestions={[]} />
                        </>
                      )}
                      {isTeacherCheckResultSubmittedTask && (
                        <>
                          <div className="form-group mb-4">
                            <a
                              href="#"
                              onClick={onViewSubmittedDetailsHandler}
                              title="view"
                            >
                              {props.t("viewTeacherSubmittedtDetails")}{" "}
                            </a>
                          </div>
                          <TaskDetailModal data={activity} quizQuestions={[]} />
                        </>
                      )}
                      <div className="form-group mb-4">
                        <label className="form-label">
                          {props.t("Approval-Status")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            formik.setFieldValue("approval_status", e?.value);
                            onValidationHandler("approval_status", e?.value);
                          }}
                          onBlur={formik.handleBlur}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          name="approval_status"
                          // value={{
                          //     value: `${activity.approval_status ? activity.approval_status : ""}`,
                          //     label: `${activity.approval_status ? activity.approval_status : ""}`,
                          // }}
                          className={
                            formik.touched.approval_status &&
                            formik.errors.approval_status
                              ? " bor"
                              : " text-dark"
                          }
                          options={Object.keys(constants.APPROVALS).map(
                            (key) => ({
                              value: `${key}`,
                              label: `${key}`,
                            })
                          )}
                        />
                        {formik.touched.approval_status &&
                          formik.errors.approval_status && (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {formik.errors.approval_status}
                            </div>
                          )}
                      </div>

                      {isCertificateFiledEnable &&
                        formik.values.approval_status ===
                          constants.APPROVAL_STATUS.Approved && (
                          <div className="form-group mb-4">
                            <label className="form-label">
                              {" "}
                              {props.t("Certificate")}{" "}
                              <span className="text-danger">*</span>{" "}
                            </label>
                            <input
                              name="certificate"
                              type="file"
                              className="form-control"
                              onChange={(e) => {
                                setCertificate(e.target.files[0]);
                                formik.setFieldValue(
                                  "certificate",
                                  e.target.files[0]
                                );
                              }}
                              invalid={
                                formik.touched.certificate &&
                                formik.errors.certificate
                                  ? true
                                  : false
                              }
                            />
                            {formik.touched.certificate &&
                            formik.errors.certificate ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {formik.errors.certificate}
                              </div>
                            ) : null}
                          </div>
                        )}

                      <div className="form-group mb-4">
                        <label className="form-label">
                          {" "}
                          {props.t("Remark")}{" "}
                        </label>
                        <textarea
                          type="text"
                          rows={5}
                          className={
                            formik.touched.remark && formik.errors.remark
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          name="remark"
                          placeholder={props.t("Remark")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          // value={activity.remark || ""}
                          style={{ height: "unset" }}
                        />
                        {formik.touched.remark && formik.errors.remark && (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.remark}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="justify-content-between align-items-center mt-5  mob-mt-2">
                    <div className="float-right">
                      <button className="btn btn-join mb-5">
                        {props.t("Save")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}

      {invalidSession && (
        <section className="dashboard top-curve2 mob-brd-radius-0">
          <div className="container">
            <div className="row p-8">
              {/* <ProfileSidebar /> */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="mb-3 d-m-none">
                      {props.t("invalid_session")}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {show && (
        <div className="d-m-none">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(EditActivityNew);