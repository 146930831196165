import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const ActivityApi = {
  createActivity: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CREATE_ACTIVITY}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getEvaluationFeedbackFormApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_EVALUATIONFORM}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  postEvaluationFormApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_EVALUATIONFORM}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
};
