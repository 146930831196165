import { ActivityApi } from "apis/ActivityApi";
import { ProfileAPI } from "apis/ProfileAPI";
import _ from "lodash";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import EvaluationFormFields from "./EvaluationFormFields";

const TeacherEvaluationForm = (props) => {
  const navigate = useNavigate();
  const [evaluationFormFields, setEvaluationFormFields] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [validationErr, setValidationErr] = useState({});
  const [status, setStatus] = useState("");

  useEffect(() => {
    getEvaluationFeedbackForm();
  }, []);

  const getEvaluationFeedbackForm = () => {
    let data = { form_id: parseInt(props.formID) };
    ActivityApi.getEvaluationFeedbackFormApi(data)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.length > 0) {
            let fields = [];
            let formValuesCopy = {};
            res.data.data.forEach((element, i) => {
              fields[i] = {
                fieldId: element.id,
                fieldLabel: element.label_en,
                fieldValue: "",
                fieldOptionId: null,
                formType: element.form_type,
                isRequired: element.is_required,
                isRemark: element.is_remark,
                EvaluationFormFieldOption: element.EvaluationFormFieldOption,
              };

              if (element.is_required)
                formValuesCopy[`field_${element.id}`] = "";
            });
            setEvaluationFormFields(fields);
            setFormValues(formValuesCopy);
          }
          return true;
        } else {
          setEvaluationFormFields([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onHandleChange = (fieldId, type, fieldValue, optionLabel, index, isRemark = false) => {
    const evaluationFormFieldsCopy = _.cloneDeep(evaluationFormFields);
    const formValuesCopy = _.cloneDeep(formValues);
    let addEditFormValues = evaluationFormFieldsCopy[index];
    if (type === "QUESTION") {
      if(isRemark){
        addEditFormValues.remark = fieldValue;
        evaluationFormFieldsCopy.splice(index, 1, addEditFormValues);
  
        formValuesCopy[`field_remark_${fieldId}`] = fieldValue;
      }else{
        addEditFormValues.fieldValue = optionLabel;
        addEditFormValues.fieldOptionId = fieldValue;
        evaluationFormFieldsCopy.splice(index, 1, addEditFormValues);
  
        formValuesCopy[`field_${fieldId}`] = fieldValue;
      }
    } else {
      addEditFormValues.fieldValue = fieldValue;
      evaluationFormFieldsCopy.splice(index, 1, addEditFormValues);

      formValuesCopy[`field_${fieldId}`] = fieldValue;
    }
    setEvaluationFormFields(evaluationFormFieldsCopy);
    setFormValues(formValuesCopy);
    return true;
  };

  const onSubmitEvaluationForm = async () => {
    let errors = await checkFormValidation();
    let errorsSize = Object.keys(errors).length;
    if (errorsSize != 0) {
      return false;
    }
    const payload = {
      form_id: props.formID,
      activity_id: props.activityId,
      userTaskID: props.userTaskID,
      formData: evaluationFormFields,
      status: status,
    };
    ProfileAPI.submitEvaluatorTaskToIntern(payload)
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            text: "Task Submitted",
            icon: "success",
            imageAlt: "success image",
          }).then((result) => {
            navigate("/mycalendar");
          });
        } else {
          toastr.warning(res?.data?.message, "");
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "");
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          );
        }
      });
  };

  const checkFormValidation = async () => {
    let validationErrorMsg = {};
    const keys = Object.keys(formValues);
    keys.forEach((key) => {
      if (formValues[key] === "") {
        validationErrorMsg[key] = props.t("Required-Message");
      }
      if (status === "") {
        validationErrorMsg.teacherTaskStatus = props.t("Required-Message");
      }
    });
    setValidationErr(validationErrorMsg);
    return validationErrorMsg;
  };

  return (
    <>
      <div
        className="dashboard-inner application-form mt-3"
        style={{ minHeight: "auto" }}
      >
        <div className="row">
          <div className="col-md-6">
            <h2 className="mb-3">
              {props?.userTask?.status === "Approved"
                ? ""
                : props.t("fillEvaluationForm")}
            </h2>
          </div>
        </div>
        <form>
          <EvaluationFormFields
            evaluationFormFields={evaluationFormFields}
            validationErr={validationErr}
            onHandleChange={onHandleChange}
            onSubmitEvaluationForm={onSubmitEvaluationForm}
            forPage="internship"
            setStatus={setStatus}
          />
        </form>
      </div>
    </>
  );
};

export default withTranslation()(TeacherEvaluationForm);
