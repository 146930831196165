import moment from "moment";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { isRestricted } from "../../../Token";
import ActivityCardArea from "./ActivityCardArea";

const Activity = (props) => {
  const activity = props.activity;

  const makeTimer = (id) => {
    let activityDate = moment(activity.activity_date).format("Y-M-D");
    let activityTime = activity.start_time;
    let currentDateTime = moment().format("YYYY-MM-DD HH:mm");
    let activityDateTime = moment(`${activityDate} ${activityTime}`);
    const duration = moment.duration(activityDateTime.diff(currentDateTime));
    const totalSeconds = duration.asSeconds();
    let days = 0;
    hours = 0;
    days = Math.floor(totalSeconds / 86400);
    hours = Math.floor((totalSeconds - days * 86400) / 3600);
    let minutes = Math.floor((totalSeconds - days * 86400 - hours * 3600) / 60);
    let seconds = Math.floor(
      totalSeconds - days * 86400 - hours * 3600 - minutes * 60
    );

    if (hours < "10") {
      hours = "0" + hours;
    }
    if (minutes < "10") {
      minutes = "0" + minutes;
    }
    if (seconds < "10") {
      seconds = "0" + seconds;
    }

    window
      .$(`.days_${id}`)
      .html((isNaN(days) ? 0 : days) + " " + props.t("Days"));
    window
      .$(`.hours_${id}`)
      .html((isNaN(hours) ? 0 : hours) + " " + props.t("Hours"));
  };

  let startTime = moment(activity.start_time, "HH:mm:ss a");
  let endTime = moment(activity.end_time, "HH:mm:ss a");
  let duration = moment.duration(endTime.diff(startTime));
  let hours = duration.asHours();
  let credits = Math.round(hours * 10) / 10;

  useEffect(() => {
    window.setInterval(function () {
      makeTimer(activity.id);
    }, 1000);
  }, []);

  return (
    <div className={`collection-item restricted_${isRestricted()}`}>
      {props?.forSection === "Multimedia" ? (
        <a href={activity?.ActivityMedia[0]?.file_url} target="_blank">
          <ActivityCardArea
            activity={activity}
            forSection={props?.forSection}
            credits={credits}
          />
        </a>
      ) : (
        <NavLink to={"/education/education-activity-details/" + activity.id}>
          <ActivityCardArea
            activity={activity}
            forSection={props?.forSection}
            credits={credits}
          />
        </NavLink>
      )}
    </div>
  );
};

export default withTranslation()(Activity);
