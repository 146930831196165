import moment from 'moment'
import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import config from '../../../config'
import * as url from "../../../helpers/common_helper";
import { withTranslation } from "react-i18next";


function MobileMedxHistory(props) {
    const activity = props.activity;
    const noImage = props.noImage;
    return (
        <div className="collection-item list-style-none">
                        <div className="position-relative main-img mb-2">
                        {activity.activity_id == null || activity.activity_id == undefined ? (
                            <img
                                className="img-fluid mx-auto"
                                src={noImage}
                                alt=""
                            />
                        ) : (
                            <NavLink to={'/education/education-activity-details/' + activity.activity_id} >
                                <img
                                    className="img-fluid mx-auto"
                                    src={activity?.Activity?.ActivityMedia[0]?.image_url}
                                    alt=""
                                />
                            </NavLink>
                        )}
                          <span>{activity?.credit_score}  {props.t("Credit")}</span>
                        </div>
                        {activity.activity_id == null || activity.activity_id == undefined ? (
                              <h4>{activity.title_en}1</h4>
                          ) : (
                              <NavLink to={'/education/education-activity-details/' + activity.activity_id} >
                                  <h4>{activity.title_en}2</h4>
                              </NavLink>
                          )}
                        <ul className="event-list">
                          <li>
                            <div>
                              <img
                                className="img-fluid"
                                src={config.BASE_URL + '/assets/images/clock.png'}
                                alt=""
                                width={12}
                                height={12}
                              />
                            </div>
                            <div>{url.dateFormat(activity.credit_date)}</div>
                          </li>
                          <li>
                            <div>
                              <img
                                className="img-fluid "
                                src={config.BASE_URL + '/assets/images/location-marker.png'}
                                alt=""
                                width={12}
                                height={12}
                              />
                            </div>
                            {activity.activity_id != null || activity.activity_id != undefined ? (
                                  <div>{activity?.Activity?.type}</div>
                              ) : (
                                  <div>Onsite</div>
                              )}
                          </li>
                        </ul>
                    </div>
    )
}
export default withTranslation()(MobileMedxHistory)