import { CommanApi } from "apis/CommanApi";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { NavLink, Link } from 'react-router-dom';

const TermsAndCondition = (props) => {
  const [termsAndCondition, setTermsAndCondition] = useState(null);

  useEffect(() => {
    CommanApi.cmsPageByTypeApi("TermsAndCondition")
      .then((res) => {
        if (res.data.success) {
          setTermsAndCondition(res.data.data);
        } else {
          toastr.error(res?.data?.message, "");
        }
      })
      .catch((err) => {
        toastr.error(err?.message, "");
      });
  }, []);

  return (
    <>
      <main className="content-row mt-5 ">
        <div className="container">
        <div className="mb-3 d-none">
        <Link to={"/"}>
            <img
            src="../assets/images/left-arrow.png"
            className=""
            alt=""
            width=""
            height=""
            />
        </Link>
        </div>
          <div className="row">
            <center>
              <h1 style={{ fontSize: "30px" }}>
              {termsAndCondition?.name_en}
              </h1>
            </center>
          </div>
          <hr />
          <div className="row">
            <div
              dangerouslySetInnerHTML={{
                __html: termsAndCondition?.content_en,
              }}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default withTranslation()(TermsAndCondition);
