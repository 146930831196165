import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import * as url from "../../helpers/common_helper";
import { withTranslation } from "react-i18next";
import FileCard from "../Components/FileCard";

const EvaluationReviewCard = (props) => {
    let evaluationReviewDetail = props.evaluationReviewDetail;
    return (
        <>
            <div
                className="dashboard-inner mb-3"
                style={{ minHeight: "auto" }}
            >
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="mb-2 d-m-none">
                            {props.t("Task-Submission-Detail")}
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="position-relative ">
                            <div className="row">
                                <div className="col-md-12 mb-2 d-none">
                                    <h6> {props.t("Task-Submission-Detail")}</h6>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-12 col-4">
                                        <p>{evaluationReviewDetail?.submited_details}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {props.evaluationReviewDetail?.TaskAttachment.length > 0 && (
                    <div className="pdf-block">
                        <label className="form-label">{props.t("Attachments")}</label>
                        <ul>
                            {props.evaluationReviewDetail?.TaskAttachment?.map((item, index) => {
                                return <FileCard key={`activityM_${index}`} item={item} />;
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};    

export default withTranslation()(EvaluationReviewCard);