import React, { useEffect, useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import { BookmarkApi } from "../../apis/BookmarkApi";
import config from "../../config";
import moment from 'moment';
import { withTranslation } from "react-i18next";

function Offers(props) {
    const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
        if (!value) return value
        return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
    }

    const [offersbookmarkData, setOffersbookmarkData] = useState({ education_type: "CME", page: 1, limit: config.LIMIT });

    const [offersbookmark, setoffersbookmark] = useState([]);

    const getOffersBookmark = (data) => {
        BookmarkApi.getOffersBookmark(data)
            .then((res) => {
                console.log(res.data)
                if (res.data.success) {
                    setoffersbookmark(res.data.data.rows);
                } else {
                    setoffersbookmark([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };



    function handleRemoveOffersclick(ofrid) {
        //delete bookmark
        const deleteOffersBookmark = (data) => {
            BookmarkApi.deleteOffersBookmark(data)
                .then((res) => {
                    //console.log(res.data)
                    if (res.data.success) {
                        getOffersBookmark(offersbookmarkData);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        const adddata = {
            bookmark_id: ofrid
        }

        deleteOffersBookmark(adddata);
    }

    useEffect(() => {
        getOffersBookmark(offersbookmarkData);
    }, []);

    return (
        <>

            <div className="offer-list mt-4">

                {(offersbookmark) ? ((offersbookmark).map((item, i) => {
                    return <Link to={`/offer-detail/${item.Offer?.id}?redirect=/bookmark`}>
                    <div className="offer-block mb-3 position-relative" key={i}>
                        <div>
                            <img
                                 src={`${item.Offer?.image_url}`}
                                className="w-100 brd-23"
                                alt=""
                                width=""
                                height=""
                            />
                        </div>
                        <div className="offer-content d-flex align-items-center justify-content-between">
                            <div>
                                <h5 className="mb-0">
                                    {item.Offer.name_en}
                                </h5>
                                <strong>{formatDate(item.Offer.start_date)} - {formatDate(item.Offer.end_date)}</strong>
                            </div>
                            <div>
                            <span
                                className="badge mb-2"
                                style={{
                                  backgroundColor:
                                  item.Offer?.OfferType?.bg_color_code,
                                }}
                              >
                                {item.Offer?.OfferType?.name_en}
                              </span>
                            </div>
                        </div>
                        <Link onClick={() => handleRemoveOffersclick(item.id)} to="">

                        <span className="shadow" style={{
                            position: "absolute",
                            top: "0",
                            marginRight: "16px",
                            right: "0",
                            width: "32px",
                            background: "#ffffff",
                            borderRadius: "50%",
                            height: "32px",
                            verticalAlign: "middle",
                            padding: "2px 10px",
                            marginTop: "16px"
                        }}>
                                <img
                                    className="img-fluid"
                                    src="assets/images/bookmark.png"
                                    alt=""
                                    width="10"
                                    height="10"
                                    style={{ borderRadius: "0px" }}
                                />
                        </span>
                            </Link>
                    </div>
                </Link>
                })) : ''}

                {/* <div className="offer-block mb-3">
                    <div>
                        <img
                            src="assets/images/offer3.png"
                            className="w-100 brd-23"
                            alt=""
                            width=""
                            height=""
                        />
                    </div>
                    <div className="offer-content d-flex align-items-center justify-content-between">
                        <div>
                            <h5 className="mb-0"> Course 20% discount</h5>
                            <strong>16 - 30 Sep 2022</strong>
                        </div>
                        <div>
                            <span className="badge badge-orange">Course</span>
                        </div>
                    </div>
                </div>
                <div className="offer-block mb-3">
                    <div>
                        <img
                            src="assets/images/offer1.png"
                            className="w-100 brd-23"
                            alt=""
                            width=""
                            height=""
                        />
                    </div>
                    <div className="offer-content d-flex align-items-center justify-content-between">
                        <div>
                            <h5 className="mb-0">Chicken 50% discount</h5>
                            <strong>16 - 30 Sep 2022</strong>
                        </div>
                        <div>
                            <span className="badge badge-blue">Food</span>
                        </div>
                    </div>
                </div>
                <div className="offer-block mb-3">
                    <div>
                        <img
                            src="assets/images/offer3.png"
                            className="w-100 brd-23"
                            alt=""
                            width=""
                            height=""
                        />
                    </div>
                    <div className="offer-content d-flex align-items-center justify-content-between">
                        <div>
                            <h5 className="mb-0">
                                {" "}
                                Different word 30% off on booking in this week
                            </h5>
                            <strong>16 - 30 Sep 2022</strong>
                        </div>
                        <div>
                            <span className="badge badge-orange">Course</span>
                        </div>
                    </div>
                </div> */}

            </div>
        </>
    );
}

export default withTranslation()(Offers)