import { DoctorApi } from "apis/DoctorApi";
import Footer from "components/Layout/Footer";
import Navbar from "components/Layout/Navbar";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate,Link } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { EducationApi } from "../../apis/EducationApi";
import config from "../../config";

function Registration(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [interestList, setinterestList] = useState([]);
  const [specialistList, setspecialistList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userInterests, setUserInterests] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 100,
  });

  const getAllCommunityTypes = (data) => {
    setLoading(true);
    EducationApi.getInterests(data)
      .then((res) => {
        const x = res?.data?.data;
        const y = x?.map((item, index) => {
          return {
            label: item.name_en,
            value: item.id,
          };
        });
        // console.log(y)
        setinterestList(y);
        setspecialistList(y);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCommunityTypes(activeData);
  }, []);

  const onInterestHandler = async (e, interestID) => {
    const userInterestsCopy = cloneDeep(userInterests);
    if (e.target.checked) {
      const interestIndex = userInterestsCopy.indexOf(interestID);
      userInterestsCopy.splice(interestIndex, 1);
    } else {
      userInterestsCopy.push(interestID);
    }
    const uniq = [...new Set(userInterestsCopy)];
    setUserInterests(uniq);
  };

  //meta title
  document.title = "Edit Doctor";
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      license_number: "",
      unique_id: "",
      email: "",
      mobile: "",
      interest_list: [],
      specialist_list: "",
      //interestCheck : userInterests ? userInterests : 0,
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(props.t("Required-Message")),
      last_name: Yup.string().required(props.t("Required-Message")),
      license_number: Yup.string()
        .required(props.t("Required-Message"))
        .matches(/^[0-9\b]+$/, props.t("Only-Number-Message")),
      unique_id: Yup.string()
        .required(props.t("Required-Message"))
        .matches(/^[0-9]+$/, props.t("Only-Number-Message"))
        .min(13, "Must be exactly 13 digits")
        .max(13, "Must be exactly 13 digits"),
      email: Yup.string()
        .email(props.t("Valid-Email-Message"))
        .required(props.t("Required-Message")),
      mobile: Yup.string()
        .matches(/^[0-9\b]+$/, props.t("Only-Number-Message"))
        .min(8, props.t("Contact-Number-Message"))
        .max(10, props.t("Contact-Number-Message"))
        .required(props.t("Required-Message")),
      specialist_list: Yup.string().required(props.t("Required-Message")),
      //interest_list:  Yup.array().min(3, props.t("select_interest_any_three")).max(3, props.t("select_interest_any_three")).required(props.t("Required-Message")),
    }),
    onSubmit: (values) => {
      if (userInterests.length != 3) {
        formik.setErrors({
          interest_list: `${props.t("select_interest_any_three")}`,
        });
        return false;
      }
      setButtonDisable(true);
      const data = new FormData();
      data.append("first_name", values.first_name);
      data.append("last_name", values.last_name);
      data.append("license_number", values.license_number);
      data.append("unique_id", values.unique_id);
      data.append("email", values.email);
      data.append("mobile", values.mobile);
      for (let i = 0; i < userInterests.length; i++) {
        data.append("interests", userInterests[i]);
      }
      data.append("specialities", values.specialist_list);
      DoctorApi.requestDoctor(data)
        .then((res) => {
          if (res.data.success) {
            alert(props.t("Request-Submitted-Message"));
            navigate("/");
          }
          setButtonDisable(false);
        })
        .catch(function (err) {
          setButtonDisable(false);
          if (err?.response?.data?.message) {
            //toastr.error(error?.response?.data?.message, "")
          } else {
            err.response.data.error.errors?.map((erritem, errindex) => {
              if (erritem.param == "license_number") {
                formik.setErrors({ license_number: `${erritem.msg}` });
              }
              if (erritem.param == "unique_id") {
                formik.setErrors({ unique_id: `${erritem.msg}` });
              }
              if (erritem.param == "email") {
                formik.setErrors({ email: `${erritem.msg}` });
              }
              if (erritem.param == "mobile") {
                formik.setErrors({ mobile: `${erritem.msg}` });
              }
            });
          }
        });
    },
  });

  return (
    <div>
      <Navbar />

      <section className="pb-5 application">
        <div className="container">
        
          <div className="d-none">
            <div className="container">
              <div className="row">
                <div className="d-flex align-items-center justify-content-between mob-mt-39">
                  <div>
                  <Link to="/">
                      <img src={config.BASE_URL + "/assets/images/768/arrow-left.png"} alt="" />
                    </Link>
                  </div>
                  <div>
                    <h4 className="mb-0">{props.t("Sign Up")}</h4>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row2">
            <div className="col-md-12">
              <div className="align-items-center justify-content-between mb-4 mob-mt-30 d-m-none">
                <h3 className="mb-0 text-center">
                    {props.t("Sign Up")}
                </h3>
              </div>

              <div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="application-form mb-3">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                          {props.t("First-Name")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.first_name}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.first_name &&
                            formik.errors.first_name
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          placeholder={props.t("First-Name")}
                          name="first_name"
                        />
                        {formik.touched.first_name &&
                        formik.errors.first_name ? (
                          <p className="text-danger text-small">
                            {formik.errors.first_name}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                          {props.t("Last-Name")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.last_name}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.last_name && formik.errors.last_name
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          placeholder={props.t("Last-Name")}
                          name="last_name"
                        />
                        {formik.touched.last_name && formik.errors.last_name ? (
                          <p className="text-danger text-small">
                            {formik.errors.last_name}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                          {props.t("License Number")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.license_number}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.license_number &&
                            formik.errors.license_number
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          placeholder={props.t("License Number")}
                          name="license_number"
                        />
                        {formik.touched.license_number &&
                        formik.errors.license_number ? (
                          <p className="text-danger text-small">
                            {formik.errors.license_number}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                          {props.t("unique_id")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.unique_id}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.unique_id && formik.errors.unique_id
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          placeholder={props.t("unique_id")}
                          name="unique_id"
                        />
                        {formik.touched.unique_id && formik.errors.unique_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.unique_id}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                          {props.t("Email")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.email && formik.errors.email
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          placeholder={props.t("Email")}
                          name="email"
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <p className="text-danger text-small">
                            {formik.errors.email}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                          {props.t("Phone")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.mobile}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.mobile && formik.errors.mobile
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          placeholder={props.t("Phone")}
                          name="mobile"
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <p className="text-danger text-small">
                            {formik.errors.mobile}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="" className="form-label">
                          {props.t("select_specialist")}{" "}
                          <span className="text-danger">*</span>
                        </label>

                        <Select
                          className="text-dark"
                          styles={{
                            control: (styles) => ({
                              ...styles,
                            }),
                          }}
                          defaultValue={formik.values.specialist_list}
                          onBlur={formik.handleBlur}
                          name="specialist_list"
                          options={specialistList}
                          onChange={(e) => {
                            formik.setFieldValue("specialist_list", e?.value);
                          }}
                          //onInputChange={onSearchDoctorHandler}
                          //placeholder={<div>{props.t("typeToSearch")}</div>}
                        />

                        <p></p>
                        {formik.touched.specialist_list &&
                        formik.errors.specialist_list ? (
                          <p className="text-danger text-small">
                            {formik.errors.specialist_list}
                          </p>
                        ) : null}
                      </div>

                      <div className="row">
                        <label htmlFor="" className="form-label">
                          {props.t("Interests")}{" "}
                          <span className="text-danger">*</span>
                        </label>

                        {interestList.length > 0
                          ? interestList.map((interest, i) => {
                              return (
                                <div
                                  className="col-md-6 col-xs-12 col-12 "
                                  key={`interest_${i}`}
                                >
                                  <input
                                    type="checkbox"
                                    onMouseUp={(e) =>
                                      onInterestHandler(e, interest.value)
                                    }
                                    name="interest_list"
                                  />{" "}
                                  {interest.label}
                                </div>
                              );
                            })
                          : props.t("No-Post-Found")}
                        <p></p>
                        {formik.touched.interest_list &&
                        formik.errors.interest_list ? (
                          <p className="text-danger text-small">
                            {formik.errors.interest_list}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <button
                      type="submit"
                      disabled={buttonDisable}
                      className="btn btn-join2 mt-5"
                    >
                      {props.t("Submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default withTranslation()(Registration);
