import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const InstituteApi = {
  getInstitutes: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_INSTITUES}`,
      headers: {
        "Content-Type": "application/json",
      },
      //data: data
    };

    return await axios(axiosConfig);
  },
  getInstitutesFilterbyName: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_INSTITUES_FILTER_BY_NAME}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getInstituteDetail: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_INSTITUE_DETAIL}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  resubmitPrimaryInstitute: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.RESUMIT_PRIMARY_INSTITUE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getPrimaryInstituteNames: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_PRIMARY_INSTITUTE_NAMES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getUserRequestSubInsititute: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_USER_REQUEST_SUB_INSTITUTE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getSubInstituteDetail: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_SUB_INSTITUTE_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getSubInstituteNames: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_SUB_INSTITUTE_NAMES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getAllInstitutesApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}api/common/getAllInstitutesPaginate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
};
