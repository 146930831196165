import React, { useEffect, useState } from "react";
import { NavLink, Link, useParams } from 'react-router-dom';
import Navbar from '../../components/Layout/Navbar';
import Footer from '../../components/Layout/Footer';
import { CommunityApi } from "../../apis/CommunityApi";
import config from "../../config";
import moment from 'moment';
import { withTranslation } from "react-i18next";

function CommunityBlogDetails(props) {

    const params = useParams();
    const community_post_id = params.community_post_id;

    const [communitypostdetailData, setCommunitypostdetailData] = useState({ community_post_id: community_post_id, page: 1, limit: config.LIMIT });
    const [communitypostdetail, setCommunitypostdetail] = useState({});
    const [communityuser, setCommunityuser] = useState({});
    const [usercomment, setUsercomment] = useState([]);

    const getCommunityPostsDetails = (data) => {
        CommunityApi.getCommunityPostsDetails(data)
            .then((res) => {
                //console.log(res.data)
                if (res.data.success) {
                    // const gdata = res.data.data.post;
                    // console.log(gdata.id);
                    //console.log(res.data.data.comment.count);
                    setCommunitypostdetail(res.data.data.post);
                    setCommunityuser(res.data.data.post.User);
                    if (res.data.data.comment) {
                        if (res.data.data.comment.count !== 0) {

                            setUsercomment(res.data.data.comment.rows);
                        } else {
                            setUsercomment([]);
                        }
                    } else {
                        setUsercomment([]);
                    }


                } else {
                    setCommunitypostdetail({});
                    setCommunityuser({});
                    setUsercomment([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function handleChangeAddComment(event) {

        if (event.key === 'Enter') {
            event.preventDefault();
            //console.log(event.target.value);
            if (event.target.value) {
                //add comments
                const postCommunityPostComment = (data) => {
                    CommunityApi.postCommunityPostComment(data)
                        .then((res) => {
                            //console.log(res.data)
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                };

                const adddata = {
                    parent_id: 0,
                    comment: event.target.value,
                    community_post_id: community_post_id
                }

                postCommunityPostComment(adddata);

                window.location.reload();
            }
        }
    }

    function handleChangeLikePost(commentid) {
        const adddata = {
            post_comment_id: commentid
        }

        const postCommunityPostCommentLike = (data) => {
            CommunityApi.postCommunityPostCommentLike(data)
                .then((res) => {
                    //console.log(res.data)
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        postCommunityPostCommentLike(adddata);
        getCommunityPostsDetails(communitypostdetailData);
    }

    useEffect(() => {
        getCommunityPostsDetails(communitypostdetailData);
    }, []);

    return (
        <>
            <Navbar />
            <section className="pt-3 mb-0">
                <div className="container">
                    <div className="row px-10">
                        <div className="col-md-12">
                            <h1 className="mt-5 mb-4 main-title d-m-none">Community</h1>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- In focus Detail Start --> */}
            <section className="community pb-3 mob-pt-1 mob-mt-m-35 mob-pb-0">
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-12 px-30 d-m-flex">
                            <ul className="nav nav-tabs d-m-none" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="focus-tab" data-bs-toggle="tab" data-bs-target="#focus"
                                        type="button" role="tab" aria-controls="focus" aria-selected="true">In focus</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="news-tab" href='/community/news'>News</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="blog-tab" href='/community/blog'>Blog</a>
                                </li>
                            </ul>
                            <div className="tab-content mob-mb-30 clearfix" id="myTabContent">
                                <div className="tab-pane fade show active blog-d" id="blog" role="tabpanel"
                                    aria-labelledby="blog-tab">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <img src="/assets/images/768/top-gray-circle.png" className="d-none top-circle" alt=""
                                                width="" height="" />
                                            <div className="bg-gray focus-detail">
                                                <div className="mb-3">
                                                    <Link to="/community">
                                                        <img src="/assets/images/left-arrow.png" className="" alt="" width=""
                                                            height="" />
                                                    </Link>
                                                </div>

                                                {/* {console.log(communitypostdetail)}
                                                {console.log(communityuser)} */}
                                                <div className="px-4">

                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <img src="/assets/images/profile.png" className="rounded" alt=""
                                                                width="40" height="40" />
                                                        </div>
                                                        <div className="ml-1">
                                                            <h5 className="mb-0">{communityuser.name}</h5>
                                                            <span>{(moment(communitypostdetail.post_date).fromNow())}</span>
                                                        </div>
                                                    </div>
                                                    <h3>{communitypostdetail.name_en}</h3>
                                                    <p className="desc my-3">
                                                        {communitypostdetail.description_en}
                                                    </p>
                                                    <div className="specialiry sp-bg mb-3 list-style-none">
                                                        <ul>
                                                            <li><a href="" className="">Cardiology</a></li>
                                                            {/* <li><a href="" className="">Oncology</a></li> */}
                                                        </ul>
                                                    </div>

                                                    <div className="slide-gallery my-3">
                                                      
                                                        <div className="d-flex column-list">
                                                            {(communitypostdetail.CommunityPostMedia) ? (communitypostdetail.CommunityPostMedia.map((comitem, comindx) => {
                                                                return ((comindx !== 0) ? (((comindx <= 3) ? <div className="column" key={comindx}>
                                                                    <img className="demo cursor w-100" src={comitem.image_url} alt="" />
                                                                </div> : ((comindx === 4) ? <div className="column">
                                                                    <Link to={`/community/details/gallery/${communitypostdetail.id}/${comindx}`}>
                                                                        <img className="demo cursor w-100" src={comitem.image_url} alt="" />
                                                                        <div className="overlay">
                                                                            <span className="image-count">+{(communitypostdetail.CommunityPostMedia.length - 3)}</span>
                                                                        </div>
                                                                    </Link>
                                                                </div> : ''))) : '')
                                                            })) : ''}

                                                        </div>
                                                    </div>
                                                    <div
                                                        className="pt-3 mt-3 mb-4 d-flex align-items-center list-style-none justify-content-between">
                                                        <div>
                                                            <ul className="d-flex m-0 p-0 image-list">
                                                                {(communitypostdetail.CommunityPostView) ? (communitypostdetail.CommunityPostView.map((pvitem, pvindx) => {
                                                                    return ((((pvindx <= 2) ? <li key={pvindx}>
                                                                        <a href="">
                                                                            <img src="/assets/images/avatar (1).png" className="rounded"
                                                                                alt="" width="35" height="35" />
                                                                        </a>
                                                                    </li> : ((pvindx === 3) ? <li>
                                                                        <a href="">
                                                                            <img src="/assets/images/avatar2.png" className="rounded" alt=""
                                                                                width="35" height="35" />
                                                                            <span className="image-count">+ {(communitypostdetail.CommunityPostView.length - 3)}</span>
                                                                        </a>
                                                                    </li> : ''))))
                                                                })) : ''}

                                                                {/* <li>
                                                                    <a href="">
                                                                        <img src="/assets/images/avatar (1).png" className="rounded"
                                                                            alt="" width="35" height="35" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="">
                                                                        <img src="/assets/images/avatar (2).png" className="rounded"
                                                                            alt="" width="35" height="35" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="">
                                                                        <img src="/assets/images/avatar (1).png" className="rounded"
                                                                            alt="" width="35" height="35" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="">
                                                                        <img src="/assets/images/avatar2.png" className="rounded"
                                                                            alt="" width="35" height="35" />
                                                                        <span className="image-count">+2</span>
                                                                    </a>
                                                                </li> */}
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            {/* <!-- <a href="javascript:void(0)" className="btn-dl like-btn">
                                                    <img src="/assets/images/like.png" alt="" width="" height="" /> Like
                                                </a> --> */}
                                                            <button type="button" className="btn-dl dislike-btn">
                                                                <img src="/assets/images/dislike.png" alt="" width=""
                                                                    height="" />
                                                                Like
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="comment-section pt-4">
                                                        <h4 className="title mb-3">Comments({usercomment.length})</h4>

                                                        {/* {console.log(usercomment)} */}

                                                        {(usercomment) ? (usercomment.map((uitem, uindx) => {
                                                            return (<div key={uindx}> <div className="d-flex">

                                                                <div>
                                                                    <img src="/assets/images/profile.png" className="rounded" alt=""
                                                                        width="40" height="40" />
                                                                </div>
                                                                <div className="ml-1">
                                                                    <h5 className="mb-1">{uitem.User.name}</h5>
                                                                    <p className="desc mb-2">{uitem.comment}</p>
                                                                    <span>{(moment(uitem.created_at).fromNow())}</span>
                                                                    <span><NavLink to="#" className={(uitem.likeCount >= 1) ? "text-danger" : "text-b"} onClick={() => handleChangeLikePost(uitem.id)}>Like</NavLink></span>
                                                                    <span><a href="" className="text-b">Reply</a></span>
                                                                </div>

                                                            </div>
                                                                {(uitem.reply.length !== 0) ? (uitem.reply.map((ritem, rindx) => {
                                                                    return (<div className="reply-blocks pl-6 mt-3" key={rindx}>
                                                                        <div className="">
                                                                            <h5 className="mb-1">{ritem.User.name}</h5>
                                                                            <p className="desc mb-2"><a href="">@{uitem.User.name}</a> {ritem.comment} </p>
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                                    <span>{(moment(ritem.created_at).fromNow())}</span>
                                                                                    <span>
                                                                                        <a href="javascript:void(0)" className="text-b">Like</a>
                                                                                    </span>
                                                                                    <span><a href="" className="text-b">Reply</a></span>
                                                                                </div>
                                                                                <div>
                                                                                    <strong>+1</strong> <img src="/assets/images/like.png"
                                                                                        className="" alt="" width="" height="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>)
                                                                })) : ""}
                                                            </div>)
                                                        })) : ''}

                                                        {/* <div className="mt-3 pl-55">
                                                            <h5 className="mb-1">Kittiya Yuthasastrkosol</h5>
                                                            <p className="desc mb-2">Lorem Ipsum is simply dummy text of the
                                                                printing and typesetting industry. </p>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    <span>1 day ago</span>
                                                                    <span>
                                                                        <a href="javascript:void(0)" className="text-r">Like</a>
                                                                    </span>
                                                                    <span><a href="" className="text-b">Reply</a></span>
                                                                </div>
                                                                <div>
                                                                    <strong>+1</strong> <img src="/assets/images/like.png"
                                                                        className="" alt="" width="" height="" />
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="comment-message mb-3">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <img src="/assets/images/profile.png" className="rounded" alt="" width="40"
                                                            height="40" />
                                                    </div>
                                                    <div className="w-100">
                                                        <form>
                                                            <div className="form-group">
                                                                <input type="text" className="form-control"
                                                                    placeholder="write a comment..." onKeyDown={handleChangeAddComment} />
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="d-none">
                                                        <img src="/assets/images/768/send.png" className="send" alt="" width=""
                                                            height="" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-2 d-m-none">
                                            <div className="img-block mb-3">
                                                <img src="/assets/images/image004.png" className="img-fluid" alt="" width=""
                                                    height="" />
                                            </div>
                                            <div className="img-block mb-3">
                                                <img src="/assets/images/image004.png" className="img-fluid" alt="" width=""
                                                    height="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- In focus Detail End --></> */}
            <Footer />
        </>
    )
}

export default withTranslation()(CommunityBlogDetails)