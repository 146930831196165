import Footer from "components/Layout/Footer";
import Navbar from "components/Layout/Navbar";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ProvinceApi } from "apis/ProvinceApi";
import config from "config";
import { DistrictApi } from "apis/DistrictApi";
import { SubDistrictApi } from "apis/SubDistrictApi";
import { InstituteApi } from "apis/InstituteApi";
import { useNavigate, useParams, Link } from "react-router-dom";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

function ResubmitSubInstitute(props) {
  const [province_id, setprovince_id] = useState([]);
  const [primaryInstitutionName, setPrimaryInstitutionName] = useState([]);
  const [origanizationType, setOriganizationType] = useState([]);
  const [educationType, setEducationType] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [district, setdistrict] = useState([]);
  const [subdistrict, setSubDistrict] = useState([]);
  const [subInstituteDetail, setSubInstituteDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [defaultProvince, setDefaultProvince] = useState({
    label: props.t("Select"),
    value: "",
  });
  const [defaultDistrict, setDefaultDistrict] = useState({
    label: props.t("Select"),
    value: "",
  });
  const [defaultSubDistrict, setDefaultSubDistrict] = useState({
    label: props.t("Select"),
    value: "",
  });
  const [defaultProvince2, setDefaultProvince2] = useState({
    label: props.t("Select"),
    value: "",
  });
  const [defaultDistrict2, setDefaultDistrict2] = useState({
    label: props.t("Select"),
    value: "",
  });
  const [defaultSubDistrict2, setDefaultSubDistrict2] = useState({
    label: props.t("Select"),
    value: "",
  });
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [activeData, setActiveData] = useState({
    page: 1,
    total: 1,
    search: "",
    limit: config.LIMIT,
  });

  const params = useParams();

  const getAllProvince = (data) => {
    setLoading(true);
    ProvinceApi.getAllProvince(data)

      .then((res) => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        });
        setprovince_id(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllProvince(activeData);
  }, []);

  const getOriganizationTypes = (data) => {
    ProvinceApi.getOriganizationTypes(data)
      .then((res) => {
        if (res.data.success) {
          setOriganizationType(res.data.data);
        } else {
          setOriganizationType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOriganizationTypes();
  }, []);

  const getEducationTypes = (data) => {
    ProvinceApi.getEducationTypes(data)
      .then((res) => {
        if (res.data.success) {
          setEducationType(res.data.data);
        } else {
          setEducationType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEducationTypes();
  }, []);

  const getServiceTypes = (data) => {
    ProvinceApi.getServiceTypes(data)
      .then((res) => {
        if (res.data.success) {
          setServiceType(res.data.data);
        } else {
          setServiceType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getServiceTypes();
  }, []);

  const getPrimaryInstituteNames = (data) => {
    setLoading(true);
    InstituteApi.getPrimaryInstituteNames(data)

      .then((res) => {
        setPrimaryInstitutionName(res?.data?.institutes);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPrimaryInstituteNames();
  }, []);

  const getSubInstituteDetail = (data) => {
    InstituteApi.getSubInstituteDetail(data)
      .then((res) => {
        if (res.data.success) {
          setSubInstituteDetail({ ...res.data.requestinstitute });
          getDistrict(res.data.requestinstitute?.contact_province_id);
          getSubDistrict({
            province_id: res.data.requestinstitute?.contact_province_id,
            district_id: res.data.requestinstitute?.contact_district_id,
          });
          let editData = res.data.requestinstitute;

          setDefaultProvince({
            label:
              i18n.language == "en" && editData.OfficerProvince?.name_en
                ? editData.OfficerProvince?.name_en
                : editData.OfficerProvince?.name_th,
            value: res.data.requestinstitute?.OfficerProvince?.id,
          });
          setDefaultDistrict({
            label:
              i18n.language == "en" && editData.OfficerDistrict?.name_en
                ? editData.OfficerDistrict?.name_en
                : editData.OfficerDistrict?.name_th,
            value: res.data.requestinstitute?.OfficerDistrict?.id,
          });
          setDefaultSubDistrict({
            label:
              i18n.language == "en" && editData.OfficerSubDistrict?.name_en
                ? editData.OfficerSubDistrict?.name_en
                : editData.OfficerSubDistrict?.name_th,
            value: res.data.requestinstitute?.OfficerSubDistrict?.id,
          });
          setDefaultProvince2({
            label:
              i18n.language == "en" && editData.DoctorProvince?.name_en
                ? editData.DoctorProvince?.name_en
                : editData.DoctorProvince?.name_th,
            value: res.data.requestinstitute?.DoctorProvince?.id,
          });
          setDefaultDistrict2({
            label:
              i18n.language == "en" && editData.DoctorDistrict?.name_en
                ? editData.DoctorDistrict?.name_en
                : editData.DoctorDistrict?.name_th,
            value: res.data.requestinstitute?.DoctorDistrict?.id,
          });
          setDefaultSubDistrict2({
            label:
              i18n.language == "en" && editData.DoctorSubDistrict?.name_en
                ? editData.DoctorSubDistrict?.name_en
                : editData.DoctorSubDistrict?.name_th,
            value: res.data.requestinstitute?.DoctorSubDistrict?.id,
          });
        } else {
          // alert("Institute under verification.");
          // navigate("/")
          setSubInstituteDetail(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInstituteCode = (code) => {
    formik.setFieldValue("code", code);
  };

  useEffect(() => {
    getSubInstituteDetail({ request_sub_institute_id: params?.id });
  }, []);

  const getDistrict = (province_id) => {
    const dist = {
      pages: 1,
      limit: config.LIMIT,
      province_id: province_id,
    };
    setLoading(true);
    DistrictApi.getAllDistrict(dist)
      .then((res) => {
        setdistrict(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubDistrict = (district_id) => {
    const SubDist = {
      pages: 1,
      limit: config.LIMIT,
      province_id: formik.values.province_id,
      district_id: district_id,
    };
    setLoading(true);
    SubDistrictApi.getAllSubDistrict(SubDist)
      .then((res) => {
        setSubDistrict(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      institution_name: subInstituteDetail?.institution_name ?? "",
      affiliation_name: subInstituteDetail?.affiliation ?? "",
      organization: subInstituteDetail?.organization_type_id ?? "",
      established: subInstituteDetail?.established_year ?? "",
      picked: subInstituteDetail?.contact_name_title ?? "",
      First_name: subInstituteDetail?.contact_first_name ?? "",
      Last_name: subInstituteDetail?.contact_last_name ?? "",
      Address: subInstituteDetail?.contact_institution_address ?? "",
      pin_code: subInstituteDetail?.contact_post_code ?? "",
      Phone: subInstituteDetail?.contact_phone ?? "",
      email: subInstituteDetail?.contact_email ?? "",
      Website: subInstituteDetail?.contact_person_website ?? "",
      picked_dr: subInstituteDetail?.doctor_name_title ?? "",
      First_name_dr: subInstituteDetail?.doctor_first_name ?? "",
      Last_name_dr: subInstituteDetail?.doctor_last_name ?? "",
      License_number: subInstituteDetail?.doctor_license_number ?? "",
      Position_dr: subInstituteDetail?.doctor_organization_position ?? "",
      Address_dr: subInstituteDetail?.doctor_institution_address ?? "",
      Phone_dr: subInstituteDetail?.doctor_phone ?? "",
      email_dr: subInstituteDetail?.doctor_email ?? "",
      Website_dr: subInstituteDetail?.doctor_website ?? "",
      primary_institution_name: subInstituteDetail?.parent_id ?? "",
      pin_code_dr: subInstituteDetail?.doctor_post_code ?? "",
      trained: subInstituteDetail?.cme_workshop ?? "",
      code: subInstituteDetail?.Institute?.new_code ?? "",
      members: subInstituteDetail?.number_of_members ?? "",
      edutypeId: subInstituteDetail?.education_type_id ?? "",
      medical_professors:
        subInstituteDetail?.education_number_of_medical_with ?? "",
      number_member: subInstituteDetail?.education_number_of_beds ?? "",
      serTypeId: subInstituteDetail?.service_type_id ?? "",
      number_medical: subInstituteDetail?.service_number_of_medical_with ?? "",
      number_bed: subInstituteDetail?.service_number_of_beds ?? "",
      province_id: subInstituteDetail?.contact_province_id ?? "",
      district_id: subInstituteDetail?.contact_district_id ?? "",
      subdistrict_id: subInstituteDetail?.contact_sub_district_id ?? "",
      province_dr_id: subInstituteDetail?.doctor_province_id ?? "",
      district_dr_id: subInstituteDetail?.doctor_district_id ?? "",
      subdistrict_dr_id: subInstituteDetail?.doctor_sub_district_id ?? "",
    },
    validationSchema: Yup.object().shape(
      {
        institution_name: Yup.string().required(props.t("Required-Message")),
        // affiliation_name: Yup.string().required(props.t("Required-Message")),
        organization: Yup.string().when(["edutypeId", "serTypeId"], {
          is: (edutypeId, serTypeId) =>
            (!edutypeId || edutypeId.length === 0) &&
            (!serTypeId || serTypeId.length === 0),
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        established: Yup.string().matches(
          /^[0-9]{4}$/,
          props.t("Must-Be-4-Digit")
        ),
        // edutypeId: Yup.string().required(props.t("Required-Message")),
        // serTypeId: Yup.string().required(props.t("Required-Message")),
        edutypeId: Yup.string().when(["organization", "serTypeId"], {
          is: (organization, serTypeId) =>
            (!organization || organization.length === 0) &&
            (!serTypeId || serTypeId.length === 0),
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        serTypeId: Yup.string().when(["edutypeId", "organization"], {
          is: (edutypeId, organization) =>
            (!edutypeId || edutypeId.length === 0) &&
            (!organization || organization.length === 0),
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        number_member: Yup.number().typeError(props.t("Only-Number-Message")),
        number_medical: Yup.number().typeError(props.t("Only-Number-Message")),
        number_bed: Yup.number().typeError(props.t("Only-Number-Message")),
        picked: Yup.string().required(props.t("Required-Message")),
        First_name: Yup.string().required(props.t("Required-Message")),
        Last_name: Yup.string().required(props.t("Required-Message")),
        Address: Yup.string().required(props.t("Required-Message")),
        province_id: Yup.string().required(props.t("Required-Message")),
        district_id: Yup.string().required(props.t("Required-Message")),
        subdistrict_id: Yup.string().required(props.t("Required-Message")),
        pin_code: Yup.number()
        .typeError(props.t("Only-Number-Message"))
        .required(props.t("Required-Message")),
        Phone: Yup.string()
        .min(8, props.t("Contact-Number-Message"))
        .max(10, props.t("Contact-Number-Message"))
        .required(props.t(props.t("Required-Message"))),
        email: Yup.string()
          .email(props.t("Valid-Email-Message"))
          .required(props.t("Required-Message")),
        picked_dr: Yup.string().required(props.t("Required-Message")),
        //Website: Yup.string().required(props.t("Required-Message")),
        First_name_dr: Yup.string().required(props.t("Required-Message")),
        Last_name_dr: Yup.string().required(props.t("Required-Message")),
        Position_dr: Yup.string().required(props.t("Required-Message")),
        Address_dr: Yup.string().required(props.t("Required-Message")),
        province_dr_id: Yup.string().required(props.t("Required-Message")),
        district_dr_id: Yup.string().required(props.t("Required-Message")),
        subdistrict_dr_id: Yup.string().required(props.t("Required-Message")),
        pin_code_dr: Yup.number()
          .typeError(props.t("Only-Number-Message"))
          .required(props.t("Required-Message")),
        Phone_dr: Yup.string()
          .min(8, props.t("Contact-Number-Message"))
          .max(10, props.t("Contact-Number-Message"))
          .required(props.t(props.t("Required-Message"))),
        //Website_dr: Yup.string().required(props.t("Required-Message")),
        //email_dr: Yup.string().email(props.t("Valid-Email-Message")).required(props.t("Required-Message")),
        email_dr: Yup.string()
          .email(props.t("Valid-Email-Message"))
          .required(props.t("Required-Message"))
          .notOneOf(
            [Yup.ref("email")],
            props.t("Officer-Doctor-Unique-Message")
          ),
        primary_institution_name: Yup.string().required(
          props.t("Required-Message")
        ),
        code: Yup.string().required(props.t("Required-Message")),
        trained: Yup.string().required(props.t("Required-Message")),
        License_number: Yup.number()
          .typeError(props.t("Only-Number-Message"))
          .required(props.t("Required-Message")),
        // .required(props.t("Required-Message")),
        // members: Yup.number().required(props.t("Required-Message")),
        // medical_professors: Yup.string().required(props.t("Required-Message")),
        //.required(props.t("Required-Message")),
      },
      [
        ["organization", "serTypeId"],
        ["organization", "edutypeId"],
        ["serTypeId", "edutypeId"],
      ]
    ),
    onSubmit: (values) => {
      // alert(JSON.stringify(values));
      const data = new FormData();

      data.append("request_sub_institute_id", params?.id);
      data.append("institute_id", values.primary_institution_name);
      data.append("code", values.code);
      data.append("institution_name", values.institution_name);
      data.append("affiliation", values.affiliation_name);
      data.append("organization_type_id", values.organization);
      //   data.append('established_year', values.organization);
      data.append("established_year", values.established);
      data.append("number_of_members", values.members);
      data.append("education_type_id", values.edutypeId);
      data.append(
        "education_number_of_medical_with",
        values.medical_professors
      );
      data.append("education_number_of_beds", values.number_member);
      data.append("service_type_id", values.serTypeId);
      data.append("service_number_of_medical_with", values.number_medical);
      data.append("service_number_of_beds", values.number_bed);
      data.append("contact_name_title", values.picked);
      data.append("contact_first_name", values.First_name);
      data.append("contact_last_name", values.Last_name);
      data.append("contact_institution_address", values.Address);
      data.append("contact_province_id", values.province_id);
      data.append("contact_district_id", values.district_id);
      data.append("contact_sub_district_id", values.subdistrict_id);
      data.append("contact_post_code", values.pin_code);
      data.append("contact_phone", values.Phone);
      data.append("contact_email", values.email);
      data.append("contact_person_website", values.Website);
      data.append("doctor_name_title", values.picked_dr);
      data.append("doctor_first_name", values.First_name_dr);
      data.append("doctor_last_name", values.Last_name_dr);
      data.append("doctor_license_number", values.License_number);
      data.append("doctor_organization_position", values.Position_dr);
      data.append("doctor_institution_address", values.Address_dr);
      data.append("doctor_province_id", values.province_dr_id);
      data.append("doctor_district_id", values.district_dr_id);
      data.append("doctor_sub_district_id", values.subdistrict_dr_id);
      data.append("doctor_post_code", values.pin_code_dr);
      data.append("doctor_phone", values.Phone_dr);
      data.append("doctor_email", values.email_dr);
      data.append("doctor_website", values.Website_dr);
      data.append("cme_workshop", values.trained);

      InstituteApi.getSubInstituteNames(data)
        .then((res) => {
          if (res.data.success == true) {
            alert("Your request submitted successfully");
            navigate("/");
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
          } else {
            err.response.data.error.errors?.map((erritem, errindex) => {
              if (erritem.param === "contact_phone") {
                formik.setErrors({ Phone: `${erritem.msg}` });
              }
              if (erritem.param == "contact_email") {
                formik.setErrors({ email: `${erritem.msg}` });
              }
              if (erritem.param === "doctor_phone") {
                formik.setErrors({ Phone_dr: `${erritem.msg}` });
              }
              if (erritem.param == "doctor_email") {
                formik.setErrors({ email_dr: `${erritem.msg}` });
              }
              if (erritem.param == "doctor_license_number") {
                formik.setErrors({ License_number: `${erritem.msg}` });
              }
            });
          }
        });
    },
  });


  useEffect(() => {
    if (isFormSubmited) {
      const { errors } = formik;
      const keys = Object.keys(errors);
      if (keys.length > 0) {
        const errorElement = document.getElementById(keys[0]);
        errorElement.focus();
        setIsFormSubmited(false);
      }
    }
  }, [isFormSubmited, formik]);

  return (
    <div>
      <Navbar />
      {/* Application Start */}
      <section className="pb-5 application">
        <div className="container">
          <div className="d-none">
            <div className="container">
              <div className="row">
                <div className="d-flex align-items-center justify-content-between mob-mt-39">
                  <div>
                    <Link to="/">
                      <img
                        src={
                          config.BASE_URL + "/assets/images/768/arrow-left.png"
                        }
                        alt=""
                      />
                    </Link>
                  </div>
                  <div>
                    <h4 className="mb-0">{props.t("Sign Up")}</h4>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row2">
            <div className="col-md-12">
              <div className="mb-0 d-m-none">
                <div className="d-flex align-items-center justify-content-between mb-4 mob-mt-30">
                  <h3 className="mb-0">{props.t("Sub-Institute-Heading")}</h3>
                </div>
              </div>
              <div>
                <h4>
                  {props.t("Status")} :{" "}
                  <span className="text-danger">{props.t("REJECTED")}</span>
                </h4>
                <p>
                  <strong>{props.t("Comment")}</strong> :{" "}
                  {subInstituteDetail?.comment}
                </p>
              </div>
              <div>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    setIsFormSubmited(true);
                    return true;
                  }}>
                  <div className="application-form mb-3">
                    <div className="row bg3 mb-3">
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                          {props.t("Institution-Name")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.institution_name &&
                            formik.errors.institution_name
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="institution_name"
                          name="institution_name"
                          placeholder={props.t("Institution-Name")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.institution_name}
                        />
                        {formik.touched.institution_name &&
                        formik.errors.institution_name ? (
                          <p className="text-danger text-small ">
                            {formik.errors.institution_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                          {props.t("Affiliation")}
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.affiliation_name &&
                            formik.errors.affiliation_name
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="affiliation_name"
                          placeholder={props.t("Affiliation")}
                          name="affiliation_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.affiliation_name}
                        />
                        {formik.touched.affiliation_name &&
                        formik.errors.affiliation_name ? (
                          <p className="text-danger text-small">
                            {formik.errors.affiliation_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="" className="form-label">
                          {props.t("Type-Of-Organization")}
                          <span className="text-danger">*</span>
                        </label>
                        {formik.touched.organization &&
                        formik.errors.organization ? (
                          <p className="text-danger text-small">
                            {formik.errors.organization}
                          </p>
                        ) : null}
                      </div>
                      {origanizationType.map((orgitem, orgindx) => {
                        return (
                          <div className="col-md-3 mb-3">
                            <div
                              className={
                                formik.touched.organization &&
                                formik.errors.organization
                                  ? "form-control bor text-dark fw-bolder"
                                  : "form-control redio-outer text-dark fw-bolder"
                              }
                            >
                              <input
                                onChange={formik.handleChange}
                                // selected={formik.values == `{orgitem.name_en}`}
                                checked={
                                  formik.values.organization == orgitem.id
                                }
                                type="radio"
                                id="organization"
                                name="organization"
                                value={orgitem.id}
                              />{" "}
                              {orgitem.name_en}
                            </div>
                            {formik.touched.organization &&
                            formik.errors.organization ? (
                              <p className="text-danger text-small ">
                                {formik.errors.organization}
                              </p>
                            ) : null}
                          </div>
                        );
                      })}
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Established")}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.established}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.established &&
                            formik.errors.established
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="established"
                          placeholder={props.t("Year")}
                          name="established"
                        />
                        {formik.touched.established &&
                        formik.errors.established ? (
                          <p className="text-danger text-small">
                            {formik.errors.established}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Number-Of-Members")}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="d-flex align-items-center">
                          <div>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.members}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.members && formik.errors.members
                                  ? "form-control bor"
                                  : "form-control mb-0 text-dark"
                              }
                              id="members"
                              placeholder={props.t("Number-Of-Members")}
                              name="members"
                            />
                            {formik.touched.members && formik.errors.members ? (
                              <p className="text-danger text-small">
                                {formik.errors.members}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <span>{props.t("People")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row bg3 mb-3">
                      {educationType
                        ? educationType?.map((editem, edindx) => {
                            return (
                              <div className="col-md-4 mb-3">
                                <div
                                  className={
                                    formik.touched.edutypeId &&
                                    formik.errors.edutypeId
                                      ? "form-control bor text-dark fw-bolder"
                                      : "form-control redio-outer text-dark fw-bolder"
                                  }
                                >
                                  <input
                                    onChange={formik.handleChange}
                                    checked={
                                      formik.values.edutypeId == editem.id
                                    }
                                    type="radio"
                                    id="edutypeId"
                                    name="edutypeId"
                                    value={editem.id}
                                  />{" "}
                                  {editem.name_en}
                                </div>
                                {formik.touched.edutypeId &&
                                formik.errors.edutypeId ? (
                                  <p className="text-danger text-small">
                                    {formik.errors.edutypeId}
                                  </p>
                                ) : null}
                              </div>
                            );
                          })
                        : ""}

                      <div className="col-md-7">
                        <label htmlFor="" className="form-label">
                          {props.t("Number-Medical-Professors-Doctors")}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="d-flex align-items-center">
                          <div>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.medical_professors}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.medical_professors &&
                                formik.errors.medical_professors
                                  ? "form-control bor"
                                  : "form-control mb-0 text-dark"
                              }
                              id="medical_professors"
                              placeholder={props.t("Number")}
                              name="medical_professors"
                            />
                            {formik.touched.medical_professors &&
                            formik.errors.medical_professors ? (
                              <p className="text-danger text-small">
                                {formik.errors.medical_professors}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <span>{props.t("People")}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Number-Of-Beds")}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="d-flex align-items-center">
                          <div>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.number_member}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.established &&
                                formik.errors.established
                                  ? "form-control bor"
                                  : "form-control mb-0 text-dark"
                              }
                              id="number_member"
                              placeholder={props.t("Number")}
                              name="number_member"
                            />
                            {formik.touched.number_member &&
                            formik.errors.number_member ? (
                              <p className="text-danger text-small ">
                                {formik.errors.number_member}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <span>{props.t("Bed")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row bg3 mb-3">
                      {serviceType
                        ? serviceType?.map((sritem, srindx) => {
                            return (
                              <div className="col-md-3 mb-3">
                                <div
                                  className={
                                    formik.touched.serTypeId &&
                                    formik.errors.serTypeId
                                      ? "form-control bor text-dark fw-bolder"
                                      : "form-control redio-outer text-dark fw-bolder"
                                  }
                                >
                                  <input
                                    onChange={formik.handleChange}
                                    checked={
                                      formik.values.serTypeId == sritem.id
                                    }
                                    type="radio"
                                    id="serTypeId"
                                    name="serTypeId"
                                    value={sritem.id}
                                  />{" "}
                                  {sritem.name_en}
                                </div>
                                {formik.touched.serTypeId &&
                                formik.errors.serTypeId ? (
                                  <p className="text-danger text-small ">
                                    {formik.errors.serTypeId}
                                  </p>
                                ) : null}
                              </div>
                            );
                          })
                        : ""}

                      <div className="col-md-7">
                        <label htmlFor="" className="form-label">
                          {props.t("Number-Medical-Professors-Doctors")}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="d-flex align-items-center">
                          <div>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.number_medical}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.number_medical &&
                                formik.errors.number_medical
                                  ? "form-control bor"
                                  : "form-control mb-0 text-dark"
                              }
                              id="number_medical"
                              placeholder={props.t("Number")}
                              name="number_medical"
                            />
                            {formik.touched.number_medical &&
                            formik.errors.number_medical ? (
                              <p className="text-danger text-small ">
                                {formik.errors.number_medical}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <span>{props.t("People")}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Number-Of-Beds")}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="d-flex align-items-center">
                          <div>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.number_bed}
                              onBlur={formik.handleBlur}
                              className={
                                formik.touched.number_bed &&
                                formik.errors.number_bed
                                  ? "form-control bor"
                                  : "form-control mb-0 text-dark"
                              }
                              id="number_bed"
                              placeholder={props.t("Number")}
                              name="number_bed"
                            />
                            {formik.touched.number_bed &&
                            formik.errors.number_bed ? (
                              <p className="text-danger text-small ">
                                {formik.errors.number_bed}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <span>{props.t("Bed")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="application-form mb-3">
                    <h4 className="mb-3">{props.t("Contact-Person")}</h4>
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="" className="form-label">
                          {props.t("title")}
                        </label>
                        {formik.touched.picked && formik.errors.picked ? (
                          <p className="text-danger text-small">
                            {formik.errors.picked}
                          </p>
                        ) : null}
                        <div className="d-flex align-items-center justify-content-between rd">
                          <div
                            className={
                              formik.touched.organization &&
                              formik.errors.organization
                                ? " form-control bor mx-2 d-flex align-items-center"
                                : " form-control d-flex align-items-center "
                            }
                          >
                            <input
                              type="radio"
                              id="picked"
                              name="picked"
                              defaultChecked=""
                              value="Mr"
                              onChange={formik.handleChange}
                              //   selected={formik.values == "mr"}
                              checked={formik.values.picked == "Mr"}
                            />{" "}
                            {props.t("Mr")}
                          </div>
                          <div
                            className={
                              formik.touched.organization &&
                              formik.errors.organization
                                ? " form-control bor mx-2 d-flex align-items-center"
                                : " form-control d-flex align-items-center "
                            }
                          >
                            <input
                              type="radio"
                              id="picked"
                              name="picked"
                              value="Mrs"
                              onChange={formik.handleChange}
                              selected={formik.values == "Mrs"}
                              checked={formik.values.picked == "Mrs"}
                            />{" "}
                            {props.t("Mrs")}
                          </div>
                          <div
                            className={
                              formik.touched.organization &&
                              formik.errors.organization
                                ? " form-control bor mx-2 d-flex align-items-center"
                                : " form-control d-flex align-items-center "
                            }
                          >
                            <input
                              type="radio"
                              id="picked"
                              name="picked"
                              value="Ms"
                              onChange={formik.handleChange}
                              selected={formik.values == "Ms"}
                              checked={formik.values.picked == "Ms"}
                            />{" "}
                            {props.t("Ms")}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("First-Name")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.First_name &&
                            formik.errors.First_name
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="First_name"
                          placeholder={props.t("First-Name")}
                          name="First_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.First_name}
                        />
                        {formik.touched.First_name &&
                        formik.errors.First_name ? (
                          <p className="text-danger text-small">
                            {formik.errors.First_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Last-Name")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.Last_name && formik.errors.Last_name
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="Last_name"
                          placeholder={props.t("Last-Name")}
                          name="Last_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Last_name}
                        />
                        {formik.touched.Last_name && formik.errors.Last_name ? (
                          <p className="text-danger text-small">
                            {formik.errors.Last_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="" className="form-label">
                          {props.t("Address")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.Address && formik.errors.Address
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="Address"
                          placeholder={props.t("Address")}
                          name="Address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Address}
                        />
                        {formik.touched.Address && formik.errors.Address ? (
                          <p className="text-danger text-small">
                            {formik.errors.Address}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                          {props.t("Select-Province")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={
                            formik.touched.province_id &&
                            formik.errors.province_id
                              ? " bor"
                              : " text-dark  "
                          }
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          onChange={(e) => {
                            setDefaultProvince(e);
                            formik.setFieldValue("province_id", e?.value);
                            setDefaultDistrict({
                              label: props.t("Select-District"),
                              value: "",
                            });
                            setDefaultSubDistrict({
                              label: props.t("Select-Sub-District"),
                              value: "",
                            });
                            getDistrict(e?.value);
                          }}
                          value={defaultProvince}
                          onBlur={formik.handleBlur}
                          name="province_id"
                          inputId="province_id"
                          placeholder={props.t("Select")}
                          options={province_id?.map((item, index) => {
                            return {
                              value: item.id,
                              label:
                                i18n.language == "en" && item.name_en
                                  ? item.name_en
                                  : item.name_th,
                            };
                          })}
                        />
                        {formik.touched.province_id &&
                        formik.errors.province_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.province_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                          {props.t("Select-District")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            setDefaultDistrict(e);
                            formik.setFieldValue("district_id", e?.value);
                            setDefaultSubDistrict({
                              label: props.t("Select-Sub-District"),
                              value: "",
                            });
                            getSubDistrict(e?.value);
                          }}
                          value={defaultDistrict}
                          onBlur={formik.handleBlur}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          name="district_id"
                          inputId="district_id"
                          className={
                            formik.touched.district_id &&
                            formik.errors.district_id
                              ? " bor"
                              : " text-dark"
                          }
                          placeholder={props.t("Select")}
                          options={district?.map((item, index) => {
                            return {
                              value: item.id,
                              label:
                                i18n.language == "en" && item.name_en
                                  ? item.name_en
                                  : item.name_th,
                            };
                          })}
                        />
                        {formik.touched.district_id &&
                        formik.errors.district_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.district_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                          {props.t("Select-Sub-District")}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            setDefaultSubDistrict(e);
                            formik.setFieldValue("subdistrict_id", e?.value);
                            formik.setFieldValue("pin_code", e?.post_code);
                          }}
                          value={defaultSubDistrict}
                          onBlur={formik.handleBlur}
                          name="subdistrict_id"
                          inputId="subdistrict_id"
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          className={
                            formik.touched.subdistrict_id &&
                            formik.errors.subdistrict_id
                              ? "bor"
                              : "text-dark"
                          }
                          placeholder={props.t("Select")}
                          options={subdistrict?.map((item, index) => {
                            return {
                              value: item.id,
                              post_code: item.post_code,
                              label:
                                i18n.language == "en" && item.name_en
                                  ? item.name_en
                                  : item.name_th,
                            };
                          })}
                        />
                        {formik.touched.subdistrict_id &&
                        formik.errors.subdistrict_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.subdistrict_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                          {props.t("Post-Code")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className={
                            formik.touched.pin_code && formik.errors.pin_code
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="pin_code"
                          placeholder={props.t("Post-Code")}
                          name="pin_code"
                          readOnly={true}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.pin_code}
                        />
                        {formik.touched.pin_code && formik.errors.pin_code ? (
                          <p className="text-danger text-small">
                            {formik.errors.pin_code}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="border-dashed">
                      <div className="clearfix" />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Phone")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className={
                            formik.touched.Phone && formik.errors.Phone
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="Phone"
                          placeholder={props.t("Phone")}
                          name="Phone"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Phone}
                        />
                        {formik.touched.Phone && formik.errors.Phone ? (
                          <p className="text-danger text-small">
                            {formik.errors.Phone}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("E-mail")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className={
                            formik.touched.email && formik.errors.email
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="email"
                          placeholder={props.t("E-mail")}
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <p className="text-danger text-small">
                            {formik.errors.email}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Website")}
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.Website && formik.errors.Website
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="Website"
                          placeholder={props.t("Website")}
                          name="Website"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Website}
                        />
                        {formik.touched.Website && formik.errors.Website ? (
                          <p className="text-danger text-small">
                            {formik.errors.Website}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="application-form mb-3">
                    <h4 className="mb-3">
                      {props.t("Contact")} ({props.t("Doctor")})
                    </h4>
                    <div className="row ">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="" className="form-label">
                          {props.t("title")}{" "}
                          <span className="text-danger">*</span>
                        </label>

                        <div className="d-flex align-items-center  rd">
                          <div
                            className={
                              formik.touched.Website && formik.errors.Website
                                ? " form-control bor mx-2 d-flex align-items-center"
                                : " form-control d-flex align-items-center "
                            }
                          >
                            <input
                              type="radio"
                              id="picked_dr"
                              name="picked_dr"
                              defaultChecked=""
                              value="DrM"
                              onChange={formik.handleChange}
                              selected={formik.values == "DrM"}
                              checked={formik.values.picked_dr == "DrM"}
                            />{" "}
                            {props.t("Dr")}.{props.t("Male")}
                          </div>

                          <div
                            className={
                              formik.touched.Website && formik.errors.Website
                                ? " form-control bor mx-2 d-flex align-items-center"
                                : " form-control d-flex align-items-center "
                            }
                          >
                            <input
                              type="radio"
                              id="picked_dr"
                              name="picked_dr"
                              value="DrF"
                              onChange={formik.handleChange}
                              selected={formik.values == "DrF"}
                              checked={formik.values.picked_dr == "DrF"}
                            />{" "}
                            {props.t("Dr")}.{props.t("Female")}
                          </div>
                        </div>
                        {formik.touched.picked_dr && formik.errors.picked_dr ? (
                          <p className="text-danger text-small mt-1">
                            {formik.errors.picked_dr}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Name")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.First_name_dr &&
                            formik.errors.First_name_dr
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="First_name_dr"
                          placeholder={props.t("Name")}
                          name="First_name_dr"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.First_name_dr}
                        />
                        {formik.touched.First_name_dr &&
                        formik.errors.First_name_dr ? (
                          <p className="text-danger text-small">
                            {formik.errors.First_name_dr}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Last-Name")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.Last_name_dr &&
                            formik.errors.Last_name_dr
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="Last_name_dr"
                          placeholder={props.t("Last-Name")}
                          name="Last_name_dr"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Last_name_dr}
                        />
                        {formik.touched.Last_name_dr &&
                        formik.errors.Last_name_dr ? (
                          <p className="text-danger text-small">
                            {formik.errors.Last_name_dr}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("License-Number")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.License_number &&
                            formik.errors.License_number
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="License_number"
                          placeholder={props.t("License-Number")}
                          name="License_number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.License_number}
                        />
                        {formik.touched.License_number &&
                        formik.errors.License_number ? (
                          <p className="text-danger text-small">
                            {formik.errors.License_number}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Position")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.Position_dr &&
                            formik.errors.Position_dr
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="Position_dr"
                          placeholder={props.t("Position")}
                          name="Position_dr"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Position_dr}
                        />
                        {formik.touched.Position_dr &&
                        formik.errors.Position_dr ? (
                          <p className="text-danger text-small">
                            {formik.errors.Position_dr}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="" className="form-label">
                          {props.t("Address")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.Address_dr &&
                            formik.errors.Address_dr
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="Address_dr"
                          placeholder={props.t("Address")}
                          name="Address_dr"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Address_dr}
                        />
                        {formik.touched.Address_dr &&
                        formik.errors.Address_dr ? (
                          <p className="text-danger text-small">
                            {formik.errors.Address_dr}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                          {props.t("Select-Province")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={
                            formik.touched.province_dr_id &&
                            formik.errors.province_dr_id
                              ? " bor"
                              : " text-dark  "
                          }
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          onChange={(e) => {
                            setDefaultProvince2(e);
                            formik.setFieldValue("province_dr_id", e?.value);
                            setDefaultDistrict2({
                              label: props.t("Select-District"),
                              value: "",
                            });
                            setDefaultSubDistrict2({
                              label: props.t("Select-Sub-District"),
                              value: "",
                            });
                            getDistrict(e?.value);
                          }}
                          placeholder={props.t("Select")}
                          value={defaultProvince2}
                          onBlur={formik.handleBlur}
                          inputId="province_dr_id"
                          name="province_dr_id"
                          options={province_id?.map((item, index) => {
                            return {
                              value: item.id,
                              label:
                                i18n.language == "en" && item.name_en
                                  ? item.name_en
                                  : item.name_th,
                            };
                          })}
                        />

                        {formik.touched.province_dr_id &&
                        formik.errors.province_dr_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.province_dr_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                          {props.t("Select-District")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            setDefaultDistrict2(e);
                            formik.setFieldValue("district_dr_id", e?.value);
                            setDefaultSubDistrict2({
                              label: props.t("Select-Sub-District"),
                              value: "",
                            });
                            getSubDistrict(e?.value);
                          }}
                          value={defaultDistrict}
                          onBlur={formik.handleBlur}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          name="district_dr_id"
                          inputId="district_dr_id"
                          className={
                            formik.touched.district_dr_id &&
                            formik.errors.district_dr_id
                              ? " bor"
                              : " text-dark"
                          }
                          placeholder={props.t("Select")}
                          options={district?.map((item, index) => {
                            return {
                              value: item.id,
                              label:
                                i18n.language == "en" && item.name_en
                                  ? item.name_en
                                  : item.name_th,
                            };
                          })}
                        />
                        {formik.touched.district_dr_id &&
                        formik.errors.district_dr_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.district_dr_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                          {props.t("Select-Sub-District")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            setDefaultSubDistrict2(e);
                            formik.setFieldValue("subdistrict_dr_id", e?.value);
                            formik.setFieldValue("pin_code_dr", e?.post_code);
                          }}
                          value={defaultSubDistrict2}
                          onBlur={formik.handleBlur}
                          name="subdistrict_dr_id"
                          inputId="subdistrict_dr_id"
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          className={
                            formik.touched.subdistrict_dr_id &&
                            formik.errors.subdistrict_dr_id
                              ? "bor"
                              : "text-dark"
                          }
                          placeholder={props.t("Select")}
                          options={subdistrict?.map((item, index) => {
                            return {
                              value: item.id,
                              post_code: item.post_code,
                              label:
                                i18n.language == "en" && item.name_en
                                  ? item.name_en
                                  : item.name_th,
                            };
                          })}
                        />
                        {formik.touched.subdistrict_dr_id &&
                        formik.errors.subdistrict_dr_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.subdistrict_dr_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                          {props.t("Post-Code")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className={
                            formik.touched.pin_code_dr &&
                            formik.errors.pin_code_dr
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="pin_code_dr"
                          placeholder={props.t("Post-Code")}
                          name="pin_code_dr"
                          readOnly={true}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.pin_code_dr}
                        />
                        {formik.touched.pin_code_dr &&
                        formik.errors.pin_code_dr ? (
                          <p className="text-danger text-small">
                            {formik.errors.pin_code_dr}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="border-dashed">
                      <div className="clearfix" />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Phone")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className={
                            formik.touched.Phone_dr && formik.errors.Phone_dr
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="Phone_dr"
                          placeholder={props.t("Phone")}
                          name="Phone_dr"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Phone_dr}
                        />
                        {formik.touched.Phone_dr && formik.errors.Phone_dr ? (
                          <p className="text-danger text-small">
                            {formik.errors.Phone_dr}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("E-mail")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className={
                            formik.touched.email_dr && formik.errors.email_dr
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="email_dr"
                          placeholder={props.t("E-mail")}
                          name="email_dr"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email_dr}
                        />
                        {formik.touched.email_dr && formik.errors.email_dr ? (
                          <p className="text-danger text-small">
                            {formik.errors.email_dr}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Website")}
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.Website_dr &&
                            formik.errors.Website_dr
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="Website_dr"
                          placeholder={props.t("Website")}
                          name="Website_dr"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Website_dr}
                        />
                        {formik.touched.Website_dr &&
                        formik.errors.Website_dr ? (
                          <p className="text-danger text-small">
                            {formik.errors.Website_dr}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="application-form mb-3">
                    <h4 className="mb-3">{props.t("Express-Intention")}</h4>
                    <div className="row ">
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Primary-Institution")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          onChange={(e) => {
                            formik.handleChange(e);
                            getDistrict(e.target.value);
                            getInstituteCode(
                              e.target.selectedOptions[0].getAttribute("code")
                            );
                          }}
                          //   onBlur={() => getDistrict()}
                          value={formik.values.primary_institution_name}
                          // className="form-select"
                          className={
                            formik.touched.primary_institution_name &&
                            formik.errors.primary_institution_name
                              ? "form-select bor"
                              : "form-select"
                          }
                          name="primary_institution_name"
                          id="primary_institution_name"
                        >
                          {formik.touched.primary_institution_name &&
                          formik.errors.primary_institution_name ? (
                            <p className="text-danger">
                              {formik.errors.primary_institution_name}
                            </p>
                          ) : null}
                          <option value="">
                            {props.t("Primary-Institution")}
                          </option>
                          {primaryInstitutionName
                            ? primaryInstitutionName?.map((item, index) => {
                                return (
                                  <option
                                    value={item.id}
                                    key={index}
                                    code={item.new_code}
                                  >
                                    {item?.institution_name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                        {/* <input
                          type="text"
                          className={
                            formik.touched.primary_institution_name &&
                            formik.errors.primary_institution_name
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id=""
                          placeholder="Primary institution name"
                          name="primary_institution_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.primary_institution_name}
                        /> */}
                        {formik.touched.primary_institution_name &&
                        formik.errors.primary_institution_name ? (
                          <p className="text-danger text-small">
                            {formik.errors.primary_institution_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                          {props.t("Code")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            formik.touched.code && formik.errors.code
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="code"
                          placeholder={props.t("Code")}
                          name="code"
                          readOnly={true}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.code}
                        />
                        {formik.touched.code && formik.errors.code ? (
                          <p className="text-danger text-small">
                            {formik.errors.code}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="border-dashed">
                      <div className="clearfix" />
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="" className="form-label">
                          {props.t("Passed-CME-Workshop")}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <div
                          className={
                            formik.touched.trained && formik.errors.trained
                              ? "radio-outer bor"
                              : "radio-outer"
                          }
                        >
                          <input
                            type="radio"
                            id="trained"
                            name="trained"
                            defaultChecked=""
                            value="1"
                            onChange={formik.handleChange}
                            checked={
                              formik.values.trained == "NEVER_TRAINED" ||
                              formik.values.trained == "1"
                            }
                          />{" "}
                          {props.t("Never-Trained")}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className={
                            formik.touched.trained && formik.errors.trained
                              ? "radio-outer bor"
                              : "radio-outer"
                          }
                        >
                          <input
                            type="radio"
                            id="trained"
                            name="trained"
                            defaultChecked=""
                            value="2"
                            onChange={formik.handleChange}
                            checked={
                              formik.values.trained == "USED_TO_TRAIN" ||
                              formik.values.trained == "2"
                            }
                          />{" "}
                          {props.t("Used-To-Train")}
                          <small>{props.t("Version")}</small>
                        </div>
                      </div>
                      {formik.touched.trained && formik.errors.trained ? (
                        <p className="text-danger text-small mt-1">
                          {formik.errors.trained}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="text5 px-4">
                    <h6>{props.t("Sub-Institute-Advice")}</h6>
                    <ol>
                      <li>{props.t("Sub-Institute-Advice1")}</li>
                      <li> {props.t("Sub-Institute-Advice2")}</li>
                      <li> {props.t("Sub-Institute-Advice3")}</li>
                      <li> {props.t("Sub-Institute-Advice4")}</li>
                      <li> {props.t("Sub-Institute-Advice5")}</li>
                    </ol>
                  </div>
                  <div className="">
                    <button type="submit" className="btn btn-join2 mt-5">
                      {props.t("Submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Application End */}
      <Footer />
    </div>
  );
}

export default withTranslation()(ResubmitSubInstitute);
