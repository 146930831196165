import React, { useEffect, useState } from "react";
import { NavLink, Link,useParams } from 'react-router-dom';
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import upevent1 from "../../assets/images/right-arrow.png";
import { EducationApi } from "../../apis/EducationApi";
import config from "../../config";
import * as url from "../../helpers/common_helper";
import { HomeApi } from "apis/HomeApi";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Activity from "../Education/components/Activity";
import MobileInnerPageHeader2 from "../../components/Layout/Mobile/MobileInnerPageHeader2";
import { InstituteApi } from "../../apis/InstituteApi";
import Select from "react-select";
import _ from "lodash";
import { CustomActivityApi } from "../../apis/CustomActivityApi";


function UpcomingActivities(props) {
  
  const params = useParams();
  const institute_id = params?.institute_id ? params?.institute_id : "All";
  const [activityData, setActiveData] = useState({ "page": 1, "limit":config.LIMIT, keyword:"",institute_id:institute_id});
  const [upcomingAct, setUpcomingAct] = useState([]);
  const [hasMore, sethasMore] = useState(true)

const [keywordActive, setKeywordActive] = useState('');
const [instituteActive, setInstituteActive] = useState('All');
const [activeInstitutesData, setActiveInstitutesData] = useState([]);
const [autoSearch, setAutoSearchData] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0);
    getUpcomingActivities(activityData);
    getInstitutes();
  }, []);

  const [selectedInstitute, setSelectedInstitute] = useState();
  const [institutesData, setinstitutesData] = useState([]);
  const [institutesPaginateData, setinstitutesPaginateData] = useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  });
  const [isMoreInstitutes, setIsMoreInstitutes] = useState(true);
  const [isInstituteLoading, setIsInstituteLoading] = useState(true);

  const onScrollInstituesDataHandler = _.debounce(async function () {
    if (isMoreInstitutes) {
      setIsInstituteLoading(true);
      getAllInstitutesPaginate();
    }
  }, 500);

  const onSearchInstitutesHandler = _.debounce(async function (searchInput) {
    setinstitutesPaginateData({
      ...institutesPaginateData,
      page: 1,
      search: searchInput,
    });
    if (searchInput.length >= 3) getAllInstitutesPaginate(searchInput);
  }, 500);

  const onOpenInstituteMenuHandler = () => {
    setinstitutesPaginateData({
      ...institutesPaginateData,
      page: 1,
    });
    getAllInstitutesPaginate();
  };


  const getAllInstitutesPaginate = (searchKeyword = "") => {
    if (searchKeyword !== "") {
      institutesPaginateData.search = searchKeyword;
    }
    CustomActivityApi.getAllInstitutesApi(institutesPaginateData)
      .then((res) => {
        if (res.data.success) {
          const pageNo = institutesPaginateData.page;
          setinstitutesPaginateData({
            ...institutesPaginateData,
            totalRecords: res.data.data.count,
            page: res.data.data.metadata.page,
            limit: res.data.data.metadata.limit,
          });
          if (pageNo === 1) {
            setinstitutesData([...res.data.data.rows]);
          } else {
            if (res.data.data.rows.length === 0) {
              setIsMoreInstitutes(false);
              setIsInstituteLoading(false);
            }
            setinstitutesData([...institutesData, ...res.data.data.rows]);
          }
        } else {
          setinstitutesData([]);
        }
      })
      .catch((err) => {
        setinstitutesData([]);
      });
  };

    const getUpcomingActivities = (data) => {
        HomeApi.getUpcomingActivities(data)
        .then((res) => {
            if (res.data.success) {
                const pageNo = data.page;
                if(pageNo === 1){
                    setUpcomingAct([...res.data.data.rows]);
                } else {
                    setUpcomingAct([...upcomingAct, ...res.data.data.rows]);
                }
                if(res.data.data.rows.length == 0) {
                  sethasMore(false);
                }                
            } else {
                setUpcomingAct([]);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    };

    const getInstitutes = () => {
        InstituteApi.getInstitutes()
            .then((res) => {
            if(res.data.success){
                setActiveInstitutesData(res.data.data);
            } else {
                setActiveInstitutesData([]);
            }
            })
            .catch((err) => {
                console.log(err);
            });
      };

    const fetchMoreData = () => {
        getUpcomingActivities(activityData);
        setActiveData(activityData);
    };

    const handleClickInstitute = (value) => {
        setInstituteActive(value);
        activityData.institute_id = value;
        setActiveData({page: 1,limit: config.LIMIT,keyword:keywordActive,institute_id:value});
        getUpcomingActivities(activityData);
    };
    
    const handleKeywordActive = (value) => {
        setKeywordActive(value);
        activityData.keyword = value;
        setActiveData({page: 1,limit: config.LIMIT,keyword:value,institute_id:instituteActive});
        getUpcomingActivities(activityData);
    };

    window.onscroll = async function (ev) {
        let pageHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );
        if (window.innerHeight + window.scrollY >= pageHeight) {
            const data = {
                page: activityData.page + 1,
                limit: config.LIMIT,
                keyword:keywordActive,
                institute_id:instituteActive
            };
            setActiveData(data);
            getUpcomingActivities(data,true);
        }
    };

    const OnSearchKeyUp = (e) => {
      setKeywordActive(e.target.value);
      if(e.key == "Enter") {
          activityData.keyword = e.target.value;
          setActiveData({
            page: 1,
            limit: config.LIMIT,
            keyword: e.target.value,
            institute_id: instituteActive,
          });
          getUpcomingActivities(activityData);
          setAutoSearchData([]);
          return false;
      }
    }

    const OnSearch = (e) => {
        let data = { keyword: e.target.value };
        setKeywordActive(e.target.value);
        if(e.target.value != '') {
          EducationApi.getActivityNameByKeyword(data)
            .then((res) => {
              if (res.data.success) {
                setAutoSearchData(res.data.data);
              } else {
                setAutoSearchData([]);
              }
            })
            .catch((err) => {
              console.log(err);
          });
        }  else {
          setAutoSearchData([]);
        }
      }
    
      const searchActivity = (value) => {
        setKeywordActive(value);
        sethasMore(true);
        setUpcomingAct([]);
        activityData.keyword = value;
        setActiveData({
          page: 1,
          limit: config.LIMIT,
          keyword: value,
          institute_id: instituteActive,
        });
        getUpcomingActivities(activityData);
        setAutoSearchData([]);
    
      }
    
      const searchRecord = () => {
        setKeywordActive(keywordActive);
        sethasMore(true);
        setUpcomingAct([]);
        activityData.keyword = (keywordActive !== undefined) ? keywordActive : "";
        setActiveData({
          page: 1,
          limit: config.LIMIT,
          keyword: activityData.keyword,
          institute_id: instituteActive,
        });
        getUpcomingActivities(activityData);
        setAutoSearchData([]);
      }

return (
    <div>
        <Navbar />
        <MobileInnerPageHeader2 title="Filter" back_page_url="" nav_url="/activity/institute-filter" />
        <>
            <section className="mt-5 d-m-none">
            <div className="container">
                <div className="row">
                <div className="col-md-12 list-style-none">
                    <ul className="breadcrumb mt-3">
                    <li>
                        <Link to={"/"}>{props.t("Activity")}</Link>
                    </li>
                    <li>/</li>
                    <li className="active">{props.t("Upcoming-Activities")}</li>
                    </ul>
                </div>
                </div>
            </div> 
            </section>

            {/* CPD Activities Star */}
            <section className="mb-3 mob-pt-5">
                <div className="container">
                    <div className="row px-10">
                        <div className="col-md-12">
                            <h1 className="my-5 main-title d-m-none">
                                {props.t("Upcoming-Activities")}
                            </h1>
                        </div>
                        <div className="col-md-12">
                            <div className="search-box mob-pt-5">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-9 pr-0">
                                        <div className="wrapper w-40">
                                        <div className="search-input">
                                            <a href="" target="_blank" hidden="" />
                                            <input type="text" value={keywordActive}  defaultValue={keywordActive}  onChange={(e) => OnSearch(e) }  onKeyUp = {OnSearchKeyUp}  placeholder={props.t("Search")}/>
                                            <div className="autocom-box">
                                            {autoSearch?.map((searchdata, index) => {
                                                return (<li key={index}  onClick={() => searchActivity(searchdata.title_en)}  >{searchdata.title_en}</li>)
                                            })}
                                            </div>
                                            <div className="icon"  onClick={() => searchRecord()}>
                                            <img
                                                src={config.BASE_URL + "/assets/images/search.png"}
                                                alt="search-icon"
                                                width={15}
                                                height={15}
                                            />
                                            </div>
                                            </div>
                                            </div>
                                            {/* <div className="input-group mb-3">
                                                <span className="input-group-text">
                                                    <img
                                                        src={config.BASE_URL + '/assets/images/search.png'}
                                                        alt="search-icon"
                                                        width={15}
                                                        height={15}
                                                    />
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control searchTextEductaion"
                                                    placeholder={props.t("Search")}
                                                      onChange={(e) => handleKeywordActive(e.target.value)}
                                                />
                                            </div> */}
                                        </div>
                                        <div className="col-md-3 pl-0 d-m-none">
                                            
                                            <Select
                                                styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    borderRadius: "50px",
                                                }),
                                                }}
                                                onChange={(e) => {
                                                handleClickInstitute(e?.value)
                                                }}
                                                onMenuScrollToBottom={onScrollInstituesDataHandler}
                                                onInputChange={onSearchInstitutesHandler}
                                                onMenuOpen={onOpenInstituteMenuHandler}
                                                defaultValue={selectedInstitute}
                                                //onBlur={formik.handleBlur}
                                                name="institute_id"
                                                options={[
                                                ...institutesData,
                                                ]}
                                                placeholder={<div>{props.t("Filter-By-Institution")}</div>}
                                            />      

                                            {/* <div className="dropdown filter-institution">
                                                <button
                                                    type="button"
                                                    className="btn btn-default dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                >
                                                {props.t("Filter-By-Institution")}
                                                <img
                                                    src={config.BASE_URL + '/assets/images/angle-down.png'}
                                                    alt=""
                                                    style={{margin:"0px 6px"}}
                                                />
                                                </button>
                                                <ul className="dropdown-menu" style={{width:"320px"}}>
                                                <li>
                                                    <div className="form-check">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id="radio1"
                                                        name="optradio"
                                                        defaultValue="option1"
                                                        defaultChecked=""
                                                        onClick={() => handleClickInstitute('All')}
                                                    />
                                                    <label className="form-check-label" htmlFor="radio1">
                                                        {props.t("All")}
                                                    </label>
                                                    </div>
                                                </li>

                                                {activeInstitutesData?.map((institutes, i) => (
                                                    <li key={`activeInstitutesData${i}`}>
                                                    <div className="form-check">
                                                        <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id="radio1"
                                                        name="optradio"
                                                        defaultValue="option1"
                                                        defaultChecked=""
                                                        onClick={() => handleClickInstitute(institutes.id)}
                                                        />
                                                        <label className="form-check-label" htmlFor="radio1">
                                                        {institutes.institution_name}
                                                        </label>
                                                    </div>
                                                    </li>
                                                ))}

                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* CPD Activities End */}
            {/* CME Events Start */}
            <section className="pb-5 pt-5 mob-mt-m-15">
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-12 px-30">
                            <h3 className="title mb-2">{props.t("Activities")}</h3>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="subtitle">{props.t("Come-See-What’s-Happening")}</div>
                            </div>
                            <div className="collections">
                                {/* {upcomingAct.length > 0 && <InfiniteScroll
                                    dataLength={upcomingAct.length}
                                    next={fetchMoreData}
                                    // loader={<h4 className="w-50 h-40 text-[18px]">Loading...</h4>}
                                    hasMore={hasMore}
                                >
                                    {upcomingAct.length > 0 ? (
                                        upcomingAct?.map((activity, index) => {
                                        return <Activity activity={activity} key={index} />;
                                        })
                                    ) : (
                                        <div className="subtitle text-center">No Activities Found </div>
                                    )}
                                </InfiniteScroll>} */}
                                {upcomingAct.length > 0 ? upcomingAct?.map((activity, index) => {
                                    return (
                                        <Activity activity={activity} key={index} />
                                    )
                                })
                                    : <div className="subtitle text-center">{props.t("No-Record-Found")}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* CME Events End */}
        </>
      <Footer />
    </div>
  );
}

export default withTranslation()(UpcomingActivities)