import React, { useEffect, useState } from "react";
import { NavLink, Link,useParams } from 'react-router-dom';
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import upevent1 from "../../assets/images/right-arrow.png";
import { EducationApi } from "../../apis/EducationApi";
import config from "../../config";
import * as url from "../../helpers/common_helper";
import { InstituteApi } from "../../apis/InstituteApi";
import MobileInnerPageHeader from "../../components/Layout/Mobile/MobileInnerPageHeader";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import Library from "./components/Library";


function EducationLibraries(props) {
    
    const params = useParams(); 
    const education_type = params.education_type;
    const [activeData, setActiveData] = useState({ page: 1, limit: config.LIMIT, keyword:"",institute_id:"All",interest_id:"All",education_type:education_type });
    const [libraries, setLibrariesData] = useState([]);
    const [interests, setInterests] = useState([]);

    const [active, setActive] = useState('All');

    const [interestActive, setInterestActive] = useState('All');
    const [keywordActive, setKeywordActive] = useState('');
    const [instituteActive, setInstituteActive] = useState('All');

    const [activeInstitutesData, setActiveInstitutesData] = useState([]);

    useEffect(() => {
      getEducationLibraryTypeWise(activeData);
      getInterests();
      getInstitutes();
    }, []);

    const getInstitutes = () => {
      InstituteApi.getInstitutes()
            .then((res) => {
              if(res.data.success){
                setActiveInstitutesData(res.data.data);
              } else {
                setActiveInstitutesData([]);
              }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getInterests = () => {
      EducationApi.getInterests()
            .then((res) => {
              if(res.data.success){
                setInterests(res.data.data);
              } else {
                setInterests([]);
              }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getEducationLibraryTypeWise = (data) => {
      EducationApi.getEducationLibraryTypeWise(data)
        .then((res) => {
          if (res.data.success) {
            setLibrariesData(res.data.data.rows);
          } else {
            setLibrariesData([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };


    const handleClickTabActive = (value) => {
      setInterestActive(value);
      activeData.interest_id = value;
      setActiveData({page: 1,limit: config.LIMIT,keyword:keywordActive,institute_id:instituteActive,interest_id:value,education_type:education_type});
      getEducationLibraryTypeWise(activeData);
    };
  
    const handleClickInstitute = (value) => {
      setInstituteActive(value);
      activeData.institute_id = value;
      setActiveData({page: 1,limit: config.LIMIT,keyword:keywordActive,institute_id:value,interest_id:interestActive,education_type:education_type});
      getEducationLibraryTypeWise(activeData);
    };
  
    const handleKeywordActive = (value) => {
      setKeywordActive(value);
      activeData.keyword = value;
      setActiveData({page: 1,limit: config.LIMIT,keyword:value,institute_id:instituteActive,interest_id:interestActive,education_type:education_type});
      getEducationLibraryTypeWise(activeData);
    };

    return (
        <div>
            <Navbar />
            <MobileInnerPageHeader title="Library"  back_page_url="education"/>
            <MobileBottomMenu />

            <>
 
            <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row">
              <div className="col-md-12 list-style-none">
                <ul className="breadcrumb mt-3">
                  <li>
                    <a href="">Education</a>
                  </li>
                  <li>/</li>
                  <li className="active">{education_type}: Library</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-5 mb-0 mob-pt-2">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12">
              <h1 className="my-5 main-title d-m-none">
                <span>{education_type}:</span> Library
              </h1>
            </div>
            <div className="col-md-12">
          <div className="search-box">
            <form action="">
              <div className="row">
                <div className="col-md-9 pr-0">
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <img
                        src={config.BASE_URL + '/assets/images/search.png'}
                        alt="search-icon"
                        width={15}
                        height={15}
                      />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => handleKeywordActive(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-3 pl-0 d-m-none">
                  <div className="dropdown list-style-none">
                    <button
                      type="button"
                      className="btn btn-default dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      Filter by institution
                      <img
                        src={config.BASE_URL + '/assets/images/angle-down.png'}
                        alt=""
                        className=""
                        width=""
                        height=""
                      />
                    </button>
                    <ul className="dropdown-menu">
                    <li>
                            <div className="form-check">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="radio1"
                                name="optradio"
                                defaultValue="option1"
                                defaultChecked=""
                                onClick={() => handleClickInstitute('All')} 
                              />
                              <label className="form-check-label" htmlFor="radio1">
                                All
                              </label>
                            </div>
                          </li>

                          {activeInstitutesData?.map( (institutes, i) => ( 
                           <li>
                            <div className="form-check">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="radio1"
                                name="optradio"
                                defaultValue="option1"
                                defaultChecked=""
                                onClick={() => handleClickInstitute(institutes.id)} 
                              />
                              <label className="form-check-label" htmlFor="radio1">
                                {institutes.institution_name}
                              </label>
                            </div>
                          </li>
                          ))}
                    </ul>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-12 mob-pr-0 specialiry mt-5 mob-mb-0 mob-mt-30 mob-pl-8 mob-mr-m-4 list-style-none">
          <h5 className="title mb-4 d-m-none">Specialiry</h5>
          <ul>
          <li>
                  <a href="javascript:void();" onClick={() => handleClickTabActive('All')} className={`${interestActive == "All" ? "active" : ""}`}>
                    All
                  </a>
                </li>
                {interests?.map( (interest, i) => ( 
                <li key={i}>
                  <a href="javascript:void();" className={`${interestActive == interest.id ? "active" : ""}`} onClick={() => handleClickTabActive(interest.id)} >
                    {interest.name_en}
                  </a>
                </li>
                ))}
            
          </ul>
        </div>
      </div>
    </div>
  </section> 
  {/* CME Library Start */}
  <section className="pb-5 mob-mt-m-15">
    <div className="container p-0">
      <div className="row">
        <div className="col-md-12 px-30">
          <div className="d-flex justify-content-between align-items-center mb-2">
            {/* <h3 className="title">Library</h3> */}
          </div>
          <div className="library-list mob-lib-list">
            <ul className="first">

                   {libraries.length > 0 ? libraries?.map((library, index) => {
                      return (
                        <Library library={library} key={index} />
                      )
                    })
                      : <h3>No Record Found</h3>
                    }
                  
                 
              
            </ul> 
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* CME Library End */}
</>


            <Footer />
        </div>
    );
}

export default EducationLibraries