export const GET_BANNER = "api/common/fetch-banners";
export const GET_ADVERTISEMENT_BANNER = "api/common/fetch-banner";
export const GET_UPCOMING_ACTIVITIES = "api/common/fetch-upcoming-activities";
export const GET_LIVE_ACTIVITIES = "api/common/fetch-live-activities";
export const GET_ACTIVITY_ABOUT_TO_CLOSE = "api/common/fetch-activity-about-to-close";
export const GET_NEWS = "api/common/fetch-community-posts";
export const GET_ACTIVITY_NAME_KEYWORD = "api/common/fetch-activity-name-by-keyword";
export const GET_EDUCATION_ACTIVITIES = "api/common/fetch-education-activities";
export const GET_EDUCATION_LIBRARIES = "api/common/fetch-education-libraries";
export const GET_EDUCATION_ACTIVITIES_BY_TYPE = "api/common/fetch-education-activity-by-type";
export const GET_EDUCATION_LIBRARIES_TYPE_WISE = "api/common/fetch-education-library-by-type";
export const CHECK_USER_DETAILS = "api/user/checkusermoreinformation";
export const GET_ACTIVITY_DETAILS = "api/user/fetch-activity-details";
export const GET_ACTIVITY_DETAILSWITHOULOGIN = "api/user/getActivityDetailsByID";
export const GET_LIBRARY_DETAILS = "api/user/fetch-library-details";
export const GET_INTERESTS = "api/common/fetch-interests";
export const GET_SPECIALISTS = "api/common/fetch-specialist";
export const GET_COMMUNITY_POSTS = "api/user/fetch-community-posts";
export const GET_COMMUNITY_NAME_BY_KEYWORD = "api/user/fetch-community-postname-by-keyword";

export const GET_COMMUNITY_POSTS_DETAILS = "api/user/fetch-community-post-details";
export const GET_COMMUNITY_TOPIC = "api/common/fetch-categories";
export const GET_COMMUNITY_NEWS_TOPIC = "api/user/fetch-institutes";
export const ADD_COMMUNITY_POST_COMMENT = "api/user/add-community-post-comment";
export const EDIT_COMMUNITY_POST_COMMENT = "api/user/update-post-comment";
export const ADD_COMMUNITY_POST_COMMENT_LIKE = "api/user/like-unlike-community-post-comment";
export const ADD_COMMUNITY_POST_COMMENT_DELETE = "api/user/remove-comment";
export const ADD_COMMUNITY_POST_LIKE_UNLIKE = "api/user/like-unlike-community-post";
export const GET_UPCOMING_BOOKING = "api/user/fetch-upcoming-booking";
export const GET_PAST_BOOKING = "api/user/fetch-past-booking";
export const GET_CANCELLED_BOOKING = "api/user/fetch-cancelled-booking";
export const CANCELLED_BOOKING = "api/user/cancel-booking";
export const GET_BOOKING_DETAILS = "api/user/fetch-booking-details";
export const GET_INSTITUES = "api/common/fetch-institutes";
export const GET_INSTITUES_FILTER_BY_NAME = "api/common/fetch-institutes-filter-by-name";
export const GET_INSTITUE_DETAIL = "api/user/fetch-institute-details";
export const RESUMIT_PRIMARY_INSTITUE = "api/user/update-request-institute";
export const GET_OFFERS = "api/common/fetch-offers";
export const GET_OFFER_DETAIL = "api/user/fetch-offer-details";
export const DOCTOR_LOGIN = "api/user/doctor-login";
export const DOCTOR_LOGIN_WITH_PASSWORD = "api/user/doctor-login-with-password";
export const COMPANY_LOGIN = "api/user/company-login";
export const MD_CONNECT = "api/user/mdlogin";
export const GET_ACTIVITY_BOOKMARKS = "api/user/fetch-activity-bookmarks";
export const GET_LIBRARY_BOOKMARKS = "api/user/fetch-library-bookmarks";
export const GET_OFFER_BOOKMARKS = "api/user/fetch-offer-bookmarks";
export const ADD_ACTIVITY_BOOKMARK = "api/user/add-activity-bookmark";
export const REMOVE_ACTIVITY_BOOKMARK = "api/user/remove-activity-bookmark";
export const ADD_LIBRARY_BOOKMARK = "api/user/add-library-bookmark";
export const REMOVE_LIBRARY_BOOKMARK = "api/user/remove-library-bookmark";
export const ADD_OFFERS_BOOKMARK = "api/user/add-offer-bookmark";
export const REMOVE_OFFERS_BOOKMARK = "api/user/remove-offer-bookmark";
export const GET_OFFER_TYPES = "api/common/fetch-offer-type";
export const GET_PROVINCE = "api/common/fetch-province";
export const GET_DISTRICT = "api/common/fetch-district";
export const GET_SUB_DISTRICT = "api/common/fetch-sub-district";
export const CONFIRM_BOOKING = "api/user/confim-booking";
export const REJOIN_BOOKING = "api/user/rejoin-booking";
export const ADD_UPDATE_USER_INFO = "api/user/addUpdateUserInformation";
export const GET_USER_INTERESTS = "api/user/fetch-user-interests";
export const REMOVE_USER_INTEREST = "api/user/removeInterest";
export const ACTIVITY_CHECK_IN = "api/user/activity-check-in";
export const ACTIVITY_CHECK_OUT = "api/user/activity-check-out";
export const ACTIVITY_CHECK_OUT_ONLINE = "api/user/activity-check-out-online";
export const BOOKING_ACTIVITY_JOIN = "api/user/booking-activity-join";


export const CHECK_ACTIVITY_BOOKED = "api/user/checkBookingExist";
export const GET_USER_SCORES = "api/user/fetch-my-score";
export const GET_USER_SCORE_DETAILS = "api/user/fetch-my-score-details";
export const GET_USER_SCORE_ACTIVITY_HISTORY = "api/user/fetch-my-medx-history";
export const GET_USER_CERTIFICATES = "api/user/fetch-my-certificates";

export const GET_FETCH_ORIGANIZATION_TYPES = "api/common/fetch-organization-types";
export const GET_FETCH_MAIN_ORIGANIZATION_TYPES = "api/common/fetch-main-organization-types";
export const GET_FETCH_EDUCATION_TYPES = "api/common/fetch-education-types";
export const GET_FETCH_SERVICE_TYPES = "api/common/fetch-service-types";
export const GET_FETCH_DOCUMENT_TYPES = "api/common/fetch-document-types";
export const GET_USER_REQUEST_INSTITUTE = "api/user/request-institute";
export const GET_CMSPAGE = "api/common/cmspage";


export const GET_USER_REQUEST_INSTITUTE_DETAILS = "api/user/fetch-institute-details";
export const GET_PRIMARY_INSTITUTE_NAMES = "api/user/get-institutes";
export const GET_ALL_INSTITUTE_NAMES = "api/user/get-all-institutes";
export const GET_USER_REQUEST_SUB_INSTITUTE = "api/user/request-sub-institute";
export const GET_SUB_INSTITUTE_DETAILS = "api/user/fetch-sub-institute-details";
export const GET_SUB_INSTITUTE_NAMES = "api/user/update-sub-request-institute";
export const CHANGE_USER_PROFILE_PIC = "api/user/changeUpdateProfilePicture";
export const GET_MY_ACTIVITIES = "api/user/fetch-my-activities";
export const GET_MY_MYCALENDER = "api/user/myCalender";
export const GET_MY_MYCALENDER_USERTASKLIST = "api/user/userTaskList";
export const GET_MY_MYCALENDER_USERTASKDETAIL = "api/user/userTaskDetail";
export const POST_TASKADVISE = "api/user/giveTaskAdvise";
export const GET_EVALUATIONFORM = "api/user/getEvaluationForm";
export const POST_EVALUATIONFORM = "api/user/postevaluationform";
export const POST_APPROVED_EVALUATION_FORM = "api/user/approvedEvaluationform";
export const GET_MY_INTERNSHIPTASKEVALUATORS = "api/user/getSessionTaskEvaluators";
export const GET_MY_SUBMITINTERNSHIPTASK = "api/user/submitInternshipTaskToEvaluator";
export const POST_MARKTASKCOMPLETED = "api/user/markTaskCompleted";
export const GET_MY_SUBMITEVALUATORTASK = "api/user/submitEvaluatorTaskToIntern";
export const GET_MY_CHECKINTERNSHIPANDGETEVALUATORS = "api/user/checkInternshipAndGetEvaluators";
export const GET_MY_SUBMITTASKEVALUATIONS = "api/user/submitTaskToEvaluator";

// create community posts
export const POST_CREATE_COMMUNITY_POST = "api/user/create-community-post";
export const POST_UPDATE_COMMUNITY_POST = "api/user/update-community-post";
export const POST_IMAGE_DELETE_COMMUNITY = "api/user/remove-community-post-file";
export const FETCH_COMMUNITY_POSTS_BY_USER = "api/user/fetch-community-posts-by-user";
export const FETCH_REMOVE_COMMUNITY_POST = "api/user/remove-community-post";
export const FETCH_REMOVE_COMMUNITY_POST_FILE = "api/user/remove-community-post-file";
export const FETCH_DCONNECT_COMMUNITY_POST_DETAILS = "api/user/fetch-dconnect-post-details";

export const FETCH_ACTIVITY_QUESTIONS = "api/user/fetch-activity-questions";
export const GET_ACTIVITY_QUESTION_DETAILS = "api/user/fetch-question-details";
export const CREATE_ACTIVITY_QUESTIONS = "api/user/create-question";
export const UPDATE_ACTIVITY_QUESTION = "api/user/update-question";
export const DELETE_ACTIVITY_QUESTION_ANSWERS = "api/user/delete-question";
export const SUBMIT_QUIZ = "api/user/submit-activity-quiz";

export const FETCH_ACTIVITY_QUIZ_QUESTIONS = "api/user/fetch-activity-quiz-questions";

export const GET_SPEAKER_USERS = "api/common/fetch-speaker-users";
export const GET_ACTIVITY_TYPES = "api/user/fetch-activity-types";
export const GET_ACTIVITY_SUB_TYPES = "api/user/fetch-activity-sub-types";
export const GET_ACTIVITY_TYPE_DETAILS = "api/user/fetch-activity-type-details";
export const FETCH_QUIZ_TYPES = "api/user/fetch-quiz_types";
export const FETCH_QUIZ_TYPE_DETAILS = "api/user/fetch-quiz-type-details"

export const GET_ACTIVITY_DISPLAY_TYPES = "api/user/fetch-activity-display-types";
export const GET_ACTIVITY_CONTENT_TYPES = "api/user/fetch-activity-content-types";
export const GET_ATTACHMENT_TYPES = "api/user/fetch-attachment-types";

export const CREATE_ACTIVITY = "api/user/createActivity";
export const UPDATE_ACTIVITY = "api/user/updateActivity";
export const COPY_ACTIVITY = "api/user/copyActivity";
export const POST_REMOVE_ACTIVITY_MEDIA = "api/user/delete-activity-media";

export const GET_USER_NOTIFICATIONS = "api/user/fetch-my-notifications";
export const GET_USER_ALL_NOTIFICATIONS = "api/user/fetch-all-notifications";
export const UPDATE_NOTIFICATION_STATUS = "api/user/update-notification-status";
export const GET_EVALUTION_FORMS_BY_INSTITUTE = "api/user/get-evalution-forms-by-institute";

export const FETCH_TASKTYPES = "api/user/fetch-task-types";

export const FETCH_ALL_COMPANIES = "api/user/fetch-all-companies";
export const FETCH_COMPANY_NAME = "api/user/fetch-companies-name";


export const FETCH_COMPANY_DETAIL = "api/common/fetch-company-details";
export const FETCH_ALL_PRODUCTS_BY_COMPANY = "api/user/fetch-all-products";
export const FETCH_PRODUCT_DETAIL = "api/user/fetch-product-details";
export const GET_INTERNSHIP_TASKS = "api/user/fetch-internship-tasks";

export const SIGN_IN = "api/user/sign-in"
export const SIGN_UP = "api/user/sign-up"

//Doctor Request Sign Up
export const REQUEST_DOCTOR_SIGNUP = "api/user/create-request-doctor"
export const FETCH_REQUEST_DOCTOR_BY_ID = "api/user/fetch-request-doctor-by-id"
export const UPDATE_REQUEST_DOCTOR_SIGNUP = "api/user/update-request-doctor"

// Doctor forgot password
export const DOCTOR_FORGOT_PASSWORD = "api/user/forgot-password";
export const DOCTOR_UPDATE_PASSWORD = "api/user/update-password";

export const SUBMIT_QUIZ_QUESTIONS = "api/user/submit-quiz-questions"
export const CHECK_IN_CHECK_OUT = "api/user/check-in-check-out";

export const FETCH_REASON_TYPES = "api/common/fetch-leave-reasons";

export const GET_MY_LEAVES = "api/user/fetch-leaves";
export const CREATE_LEAVE = "api/user/create-leave";
export const UPDATE_LEAVE = "api/user/update-leave";
export const FETCH_LEAVE_DETAILS = "api/user/fetch-leave-detail";
export const CANCEL_LEAVE = "api/user/cancel-leave"

export const GET_SYSTEM_SETTINGS = "api/common/get-system-setting";

export const GET_MSFANDHODRESULTS = "api/user/getMsfAndHodResults";
export const GET_USEREVALUATIONDETAIL = "api/user/getUserEvaluationDetail";
export const GET_MSFANDTEACHERRESULTS = "api/user/getMsfAndTeacherResults";
export const SUBMITEVALUATORRESULT = "api/user/submitEvaluatorResult";
export const SENDEVALUATIONTASKTOHOD = "api/user/sendEvaluationTaskToHod";
