import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import upevent1 from "../../assets/images/right-arrow.png";
import { EducationApi } from "../../apis/EducationApi";
import { InstituteApi } from "../../apis/InstituteApi";
import config from "../../config";
import * as url from "../../helpers/common_helper";
import Tabs from "./components/Tabs";
import MobileNavbar from "./components/MobileNavbar";
import MobileCarousel from "./components/MobileCarousel";
import MobileTabs from "./components/MobileTabs";
import { OfferApi } from "../../apis/OfferAPI";
import OfferItem from "./components/OfferItem";
import { OfferTypeApi } from "apis/OfferTypeApi";
import { withTranslation } from "react-i18next";
import RightAdvertiseBanner from "../Components/RightAdvertiseBanner";
import { HomeApi } from "../../apis/HomeApi";

function Offer(props) {
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
    offer_type_id: "All",
  });
  const [offerTypeActiveData, setOfferTypeActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
  });
  const [active, setActive] = useState("All");
  const [offersData, setOffersData] = useState([]);
  const [offerTypesData, setOfferTypesData] = useState([]);

  const [banners, setBanners] = useState([]);
  const [topBanner, setTopBanner] = useState({
    page_name: "Offer",
    type: "Banner",
    position: "Top",
  });

  const getBanners = (data) => {
    HomeApi.getBanners(data)
      .then((res) => {
        if (res.data.success) {
          setBanners(res.data.data.banners);
        } else {
          setBanners([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOffers();
    getBanners(topBanner);
  }, [activeData.offer_type_id]);

  useEffect(() => {
    getOfferTypes();
  }, []);

  const getOffers = () => {
    OfferApi.getOffers(activeData)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.count > activeData.limit) {
            setActiveData({
              ...activeData,
              page: activeData.page + 1,
            });
          } else {
            setActiveData({
              ...activeData,
              page: -1,
            });
          }

          setOffersData(res.data.data.rows);
        } else {
          setOffersData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOfferTypes = () => {
    OfferTypeApi.getOfferTypes(offerTypeActiveData)
      .then((res) => {
        if (res.data.success) {
          setOfferTypesData(res.data.data);
        } else {
          setOfferTypesData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadMore = () => {
    OfferApi.getOffers(activeData)
      .then((res) => {
        if (res.data.success) {
          const newOfferData = [...offersData, ...res.data.data.rows];
          setOffersData(newOfferData);
          if (res.data.data.count > newOfferData.length) {
            setActiveData({
              ...activeData,
              page: activeData.page + 1,
            });
          } else {
            setActiveData({
              ...activeData,
              page: -1,
            });
          }
        } else {
          setOffersData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickTabActive = (value) => {
    setActive(value);
  };

  return (
    <div>
      <Navbar />
      <MobileNavbar />
      <section className="pt-3 top-curve">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12">
              <h1 className="mt-5 mb-4 main-title d-m-none">
                {props.t("Offer")}
              </h1>
            </div>
            <MobileTabs />
          </div>
        </div>
      </section>
      {/* <MobileCarousel /> */}
      <section className="community pb-3 mob-pt-1 mob-mt-m-35 mob-pb-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12 px-30">
              <ul className="nav nav-tabs d-m-none" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="offer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#offer"
                    type="button"
                    role="tab"
                    aria-controls="offer"
                    aria-selected="true"
                  >
                    {props.t("Offer")}
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="service-tab"
                                    >Service</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="explore-tab"
                                    >Explore</button>
                                </li> */}
              </ul>
              <div className="tab-content mob-mb-30 clearfix" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="offer"
                  role="tabpanel"
                  aria-labelledby="offer-tab"
                >
                  <div className="row">
                    <div className="col-md-9">
                      <div className="mob-pr-0 specialiry mb-4 mob-mb-0 mob-pl-8 list-style-none">
                        <h5 className="title mb-4">{props.t("Offer-Type")}</h5>
                        <ul>
                          <li>
                            <a
                              href="#"
                              onClick={() =>
                                setActiveData({
                                  ...activeData,
                                  page: 1,
                                  offer_type_id: "All",
                                })
                              }
                              className={
                                activeData.offer_type_id == "All"
                                  ? "active"
                                  : ""
                              }
                            >
                              {props.t("All")}
                            </a>
                          </li>
                          {offerTypesData.length > 0
                            ? offerTypesData.map((offerType, index) => {
                                return (
                                  <li key={index}>
                                    <a
                                      href="#"
                                      onClick={() =>
                                        setActiveData({
                                          ...activeData,
                                          page: 1,
                                          offer_type_id: offerType.id,
                                        })
                                      }
                                      className={
                                        activeData.offer_type_id == offerType.id
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      {offerType?.name_en}
                                    </a>
                                  </li>
                                );
                              })
                            : null}
                        </ul>
                      </div>

                      <div className="offer-list">
                        {offersData.length > 0 ? (
                          offersData?.map((offer, i) => {
                            if (i === 1) {
                              return (
                                <>
                                  <OfferItem offer={offer} key={i} />
                                  <RightAdvertiseBanner
                                    banner={banners}
                                    className="mb-3 p-20 d-none"
                                    key={`offerbanner${offer.id}`}
                                  />
                                </>
                              );
                            }
                            return <OfferItem offer={offer} key={i} />;
                          })
                        ) : (
                          <div className="text-center">{props.t("No-Offer-Found")}</div>
                        )}
                        {activeData.page != -1 ? (
                          <div className="row">
                            <button
                              onClick={() => loadMore()}
                              className="btn btn-primary text-white text-center m-auto  col-md-2 col-4"
                            >
                              {props.t("LOAD-MORE")}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <RightAdvertiseBanner
                      banner={banners}
                      className="col-md-3 pt-3 communityBanner"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default withTranslation()(Offer);
