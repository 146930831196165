import { AuthApi } from "apis/AuthApi";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getToken, Token } from "Token";

const ValidateLogin = () => {
  const [errorMsg, setErrorMsg] = useState("Wait for a seconds");
  const search = useLocation().search;
  useEffect(() => {
    const authToken = getToken()
    if(authToken === "" || authToken === null){
        const sessionID = new URLSearchParams(search).get("session_id");
        const payload = {
          session_id: sessionID,
        };
        AuthApi.mdConnectAPI(payload)
          .then((res) => {
            if (res.data.success) {
              Token.saveToken(res.data.user, res.data.token);
              window.location.href = "/mymedx";
            } else {
              setErrorMsg(res.data.message);
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
    }
  }, []);

  return (
    <>
      <h1>{errorMsg}</h1>
    </>
  );
};

export default ValidateLogin;
