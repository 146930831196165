import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const CommanApi = {
  getSpecialistAPI: async () => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_SPECIALISTS}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios(axiosConfig);
  },
  getSpeakerUsers: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_SPEAKER_USERS}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios(axiosConfig);
  },
  cmsPageByTypeApi: async (data) => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_CMSPAGE}/${data}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios(axiosConfig);
  },
  getSystemSetting: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_SYSTEM_SETTINGS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
};
