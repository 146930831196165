import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
// import { NavLink, Link } from 'react-router-dom';
// import Navbar from "../../components/Layout/Navbar";
// import Footer from "../../components/Layout/Footer";
// import upevent1 from "../../assets/images/right-arrow.png";
import config from "../../config";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { AuthApi } from "apis/AuthApi";
import { Token } from "../../Token";

function ForgotPassword(props) {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const user_id = params.get('id');
  
  const [showPassword, setPasswordShow] = useState(false);
  const [showConfirmPassword, setConfirmPasswordShow] = useState(false);
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState("");
  const [forgetError, setForgetError] = useState("");
  const [showLoader, setShowLoader] = useState(false);


  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordShow(true);
  };

  const handleClickHideConfirmPassword = () => {
    setConfirmPasswordShow(false);
  };

  const handleClickShowPassword = () => {
    setPasswordShow(true);
  };

  const handleClickHidePassword = () => {
    setPasswordShow(false);
  };

  const [errorMsg, setErrorMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      password : "",
      confirm_password : ""
    },
    validationSchema: Yup.object().shape(
      {
        password: Yup.string().required('Password is required'),
        confirm_password: Yup.string().required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
      },
      []
    ),
    onSubmit: (values) => {
      values.user_id = parseInt(atob(user_id));
      AuthApi.doctorUpdatePassowrd(values)
        .then((res) => {
            if (res.data.success) {
                setForgetError("")
                setForgetSuccessMsg(res.data.message);
                setInterval(() => {
                  window.location.href = "/login";
               },2000);
            } else {
                setForgetSuccessMsg("")
                setForgetError(res.data.message);
            }
        })
        .catch((err) => {
            if (err?.response?.data?.message) {
                //toastr.error(error?.response?.data?.message, "")
              } else {
                err.response.data.error.errors?.map((erritem, errindex) => {
                  if (erritem.param == "password") {
                    formik.setErrors({ password: `${erritem.msg}` });
                  }
                });
              }
      })
    },
  });

  return (
    <div>
      <section>
        <div className="blue-circle1" />
        <div className="login-object d-none">
          <img
            src={config.BASE_URL + "/assets/images/doctor_tem.png"}
            alt=""
            width=""
            height=""
          />
        </div>
        <img
          src="assets/images/doctor_tem.png"
          className="login-img d-m-none"
        />

        <div className="container">
        <div className="d-none">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                <Link to="/">
                    <img src={config.BASE_URL + "/assets/images/768/arrow-left.png"} alt="" />
                  </Link>
                </div>
                <div>
                  <h4 className="mb-0">{props.t("Update-Password")}</h4>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>


          <div className="cme-logo">
            <img src="assets/images/logo/CME.png" className="" alt="CME" />
          </div>
          <div className="login-form update-password-form">
            <div className="row medx">
              <div className="col-md-2 MedxLogo">
                <img
                  src="assets/images/logo/svg/MedxLogo.svg"
                  className=""
                  alt="MedxLogo"
                />
              </div>
              <div className="col-md-10">
                <h1 className="text-gray-black title">{props.t("Hello")},</h1>
                <p className="text-gray-black subtitle2 mb-4">
                  {props.t("To-See-You")}
                </p>
              </div>
            </div>
            <br />

            {forgetError && forgetError ? (
                 <p className="text-danger text-small">{forgetError}</p>
            ) : null}
            {forgetSuccessMsg ? (
                <p className="text-success text-small">{forgetSuccessMsg}</p>
            ) : null}

            <form onSubmit={formik.handleSubmit}>
              
             <div className="form-group">
                <label
                  htmlFor="password"
                  style={{ float: "left" }}
                  className="form-label ck"
                >
                  {props.t("New Password")} <span className="text-danger">*</span>
                </label>
                <div
                  className={
                    formik.touched.password && formik.errors.password
                      ? "input-group input-password-border border-red"
                      : "input-group input-password-border"
                  }
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    className={
                      formik.touched.password && formik.errors.password
                        ? "form-control bb input-password-text"
                        : "form-control input-password-text"
                    }
                    id=""
                    placeholder={props.t("New Password")}
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    style={{ paddingLeft: "20px" }}
                  />

                  {showPassword ? (
                    <div className="input-group-addon fapsicon">
                      <i
                        className="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={handleClickHidePassword}
                      ></i>
                    </div>
                  ) : (
                    <div className="input-group-addon fapsicon">
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={handleClickShowPassword}
                      ></i>
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group">
                {formik.touched.password && formik.errors.password ? (
                  <p
                    className="text-danger text-small text-left"
                    style={{ marginTop: "1px" }}
                  >
                    {formik.errors.password}
                  </p>
                ) : null}
              </div>


              <div className="form-group">
                <label
                  htmlFor="confirm_password"
                  style={{ float: "left" }}
                  className="form-label ck"
                >
                  {props.t("Confirm-Password")} <span className="text-danger">*</span>
                </label>
                <div
                  className={
                    formik.touched.confirm_password && formik.errors.confirm_password
                      ? "input-group input-password-border border-red"
                      : "input-group input-password-border"
                  }
                >
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className={
                      formik.touched.confirm_password && formik.errors.confirm_password
                        ? "form-control bb input-password-text"
                        : "form-control input-password-text"
                    }
                    id=""
                    placeholder={props.t("Confirm-Password")}
                    name="confirm_password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirm_password}
                    style={{ paddingLeft: "20px" }}
                  />

                  {showConfirmPassword ? (
                    <div className="input-group-addon fapsicon">
                      <i
                        className="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={handleClickHideConfirmPassword}
                      ></i>
                    </div>
                  ) : (
                    <div className="input-group-addon fapsicon">
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={handleClickShowConfirmPassword}
                      ></i>
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group">
                {formik.touched.confirm_password && formik.errors.confirm_password ? (
                  <p
                    className="text-danger text-small text-left"
                    style={{ marginTop: "1px" }}
                  >
                    {formik.errors.confirm_password}
                  </p>
                ) : null}
              </div>

            

              <button type="submit" className="btn btn-blue btn-block mt-3">
                {props.t("Submit")}
              </button>

            
            
            </form>
          </div>
        </div>

        {/* <img src="assets/images/logo/scb_logo.jpg" className="login-bottom-img d-m-none"/>
                
                <img src="assets/images/logo/scb_mob-logo.png" className="login-bottom-img d-none"/> */}
      </section>
    </div>
  );
}

export default withTranslation()(ForgotPassword);
