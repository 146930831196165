import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import config from '../../../config'

function OfferItem({offer}) {
    return (
        <div className="offer-block mb-3">
            {}
            <Link to={"/offer-detail/"+offer?.id}>
                <div>
                    <img src={`${offer?.image_url}`} className="w-100" alt="" />
                </div>
                <div
                    className="offer-content d-flex align-items-center justify-content-between">
                    <div>
                        <h5 className="mb-0">{offer.name_en}</h5>
                        <strong>{moment(offer?.start_date).format("DD")} - {moment(offer?.end_date).format("DD MMM YYYY")}</strong>
                    </div>
                    <div>
                        <span className="badge " style={{backgroundColor:offer?.OfferType?.bg_color_code}}>{offer?.OfferType?.name_en}</span>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default OfferItem