import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Navbar from "../../components/Layout/Navbar";
import config from "../../config";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import { withTranslation } from "react-i18next";
import { ProfileAPI } from "apis/ProfileAPI";
import LeaveCard from "../Components/LeaveCard";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const MyLeave = (props) => {
  const [leaveActiveData, setLeaveActiveData] = useState({
    page: 1,
    limit: config.LIMIT
  });
  const [myLeaves, setMyLeaves] = useState([]);

  useEffect(() => {
    getMyLeaves(leaveActiveData, false);
  }, []);

  const getMyLeaves = (data, isPushData) => {
    ProfileAPI.getMyLeaves(data)
      .then((res) => {
        if (res.data.success) {
          if (isPushData) {
            setMyLeaves([...myLeaves, ...res.data.data]);
          } else {
            setMyLeaves(res.data.data);
          }
        } else {
          setMyLeaves([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  window.onscroll = async function (ev) {
    let pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    if (window.innerHeight + window.scrollY >= pageHeight) {
      const data = {
        page: leaveActiveData.page + 1,
        limit: config.LIMIT,
      };
      setLeaveActiveData(data);
      getMyLeaves(data, true);
    }
  };

  return (
    <div>
      {/* <!-- Navbar Start --> */}
      <Navbar />
      {/* <!-- Navbar End --> */}
      <ScrollToTopOnMount />
      {/* <!-- Mobile View Top Start --> */}
      <div className="mobile-gray-top d-none">
        <div className="container">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between mob-mt-39">
              <div>
                <Link to="/profile">
                  <img src="assets/images/768/arrow-left.png" alt="" />
                </Link>
              </div>
              <div>
                <h4 className="mb-0">{props.t("My-Leaves")}</h4>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Mobile View Top End --> */}

      <section className="mt-5  d-m-none">
        <div className="container">
          <div className="row p-8">
            <div className="col-md-12">
              <h1 className="mt-5  mb-3 main-title">{props.t("Profile")}</h1>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Booking Start --> */}
      <section className="dashboard top-curve2 mob-brd-radius-0">
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />
            <div className="col-md-10">
              <div className="dashboard-inner">
                <div className="col-md-12">
                  <div className="dashboard-inner">
                    <div className="row d-m-none">
                      <div className="col-md-9">
                        <h2 className="mb-3">{props.t("Leave")}</h2>
                      </div>
                      <div className="col-md-3">
                        <Link to="/apply-leave" className="mb-3 btn btn-join">
                          {props.t("Apply-Leave")}
                        </Link>
                      </div>
                    </div>

                    <div className="library-list list-style-none">
                      <ul className="first">
                        {myLeaves.length > 0
                          ? myLeaves.map((leave, i) => {
                              return <LeaveCard key={`myLeave__${i}`} leave={leave} />;
                            })
                          : "No record found"}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 d-none">
                    <Link to="/apply-leave" className="btn btn-join">
                      {props.t("Apply-Leave")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Booking End --> */}

      {/* <!-- Footer Start --> */}
      <div className="d-m-none">
        <Footer />
      </div>
      {/* <!-- Footer End --> */}
    </div>
  );
};

export default withTranslation()(MyLeave);
