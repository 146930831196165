import React, { useEffect, useState } from "react";
import { Link, NavLink } from 'react-router-dom'
import Footer from '../../components/Layout/Footer'
import Navbar from '../../components/Layout/Navbar'
import { QuestionApi } from "../../apis/QuestionApi";
import config from "../../config";
import { withTranslation } from "react-i18next";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import { getUser } from "../../Token";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
    Row,
    Col,
    Button,
    Label,
    Input,
    Form,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";

const AddQuestion = props => {
    const user = getUser();
    const navigate = useNavigate();
    const [activityQuestions, setActivityQuestions] = useState([]);
    const [activityId, setActivityId] = useState([]);
    
    const [formValues, setFormValues] = useState([{ option_en: "", option_th: "", is_answer : "" }])
    const [optionData, setOptionData] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    
    const fetchActivityQuestions = () => {
        QuestionApi.fetchActivityQuestions()
        .then((res) => {
            if (res.data.success) {
                setActivityQuestions(res.data.data);
            } else {
                setActivityQuestions([]);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    };

    const [modalDelete, setModalDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null)
    const [delTitle, setDelTitle] = useState(null)
    const toggleDelete = () => setModalDelete(!modalDelete);

    const deleteActivityQuestionAnswers = () => {
        let data = { question_id : deleteId }
        QuestionApi.deleteActivityQuestionAnswers(data)
        .then((res) => {
            if (res.data.success) {
                fetchActivityQuestions();
                toggleDelete();
            } else {
                fetchActivityQuestions([]);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    };

    let addFormFields = () => {
        setFormValues([...formValues, { option_en: "", option_th: "", is_answer : ""}])
    }

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    const onRemoveOptionHandleChange = (index) => {
        const optionDataCopy = _.cloneDeep(optionData);
        optionDataCopy.splice(index,1);
        setOptionData(optionDataCopy);
    }

    const onHandleChange = (value, type, index) => {
        const optionDataCopy = _.cloneDeep(optionData);
        if (type === "optionEn") {
            const editOptionData = optionDataCopy[index];
            if(!editOptionData){
                const data = {
                    option_en : value,
                    option_th : "",
                    is_answer : false,
                };
                optionDataCopy.push(data);
            } else {
                editOptionData.option_en = value;
                optionDataCopy.splice(index,1, editOptionData);
            }
        } else if(type === "optionTh") {
            const editOptionData = optionDataCopy[index];
            if(!editOptionData){
                const data = {
                    option_en : "",
                    option_th : value,
                    is_answer : false,
                };
                optionDataCopy.push(data);
            } else {
                editOptionData.option_th = value;
                optionDataCopy.splice(index,1, editOptionData);
            }
        } else if(type === "isAnswer") {
            const editOptionData = optionDataCopy[index];
            if(!editOptionData){
                const data = {
                    option_en : "",
                    option_th : "",
                    is_answer : value ? true : false,
                };
                optionDataCopy.push(data);
            } else {
                optionDataCopy.forEach(item => {
                    item.is_answer = false;
                });
                editOptionData.is_answer = value ? true : false;
                optionDataCopy.splice(index,1, editOptionData);
            }
        }
        setOptionData(optionDataCopy);
    }

    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            question_en: '',
            question_th: '',
            provider_id : '',
            optionsArr : [],
        },
        validationSchema: Yup.object().shape({
            question_en: Yup.string().required(props.t("Required-Message")),
            question_th: Yup.string().required(props.t("Required-Message")),
        },[ [ 'question_en', 'question_th']]),
        onSubmit: (values) => {
            setIsSubmit(true);
            const data = new FormData();
            data.append('question_en', values.question_en);
            data.append('question_th', values.question_th);
            data.append('provider_id', values.provider_id);
            data.append('optionsArr', JSON.stringify(optionData));
            console.log(optionData);
            QuestionApi.createActivityQuestions(data)
            .then(res => {
                setIsSubmit(false);
                if (res.data.success) {
                    Swal.fire({
                        text: res.data.message,
                        icon: 'success',
                        imageAlt: 'success image',
                    }).then((result) => {
                        if (result?.isConfirmed) {
                            formClear();
                            fetchActivityQuestions();
                            setFormValues([{ option_en: "", option_th: "", is_answer : "" }]);
                        }
                    });
                } else {
                    toastr.error(res?.data?.message, '');
                }
            }).catch(function (error) {
                setIsSubmit(false);
                if (error?.response?.data?.message) {
                    toastr.error(error?.response?.data?.message, '');
                }
                else {
                    toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                }
            });
        }
    });

    const formClear = () => {
        validationType.resetForm();
    };
    
    useEffect(() => {
        fetchActivityQuestions();
    }, []);

    return (
        <div>
            <Navbar />

            <section className="pb-5 application">
                <div className="container">
                    <div className="row row2">
                        <div className="align-items-center justify-content-between mb-4 mob-mt-30">
                            <div className="mb-3">
                                {/* <Link onClick={() => navigate(-1)} className="poi">
                                    <img
                                        src="/assets/images/left-arrow.png"
                                        alt=""
                                    />
                                </Link> */}
                                <Link to="/activity-form" className="poi">
                                    <img
                                        src="/assets/images/left-arrow.png"
                                        alt=""
                                    />
                                </Link>
                            </div>
                            <div className="px-4">
                                <h3 className="mb-0">
                                    {props.t("Add")} {props.t("Question")}
                                </h3>
                            </div>
                        </div>
                        <Row className="application-form">
                            <Col sm={12}>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validationType.handleSubmit();
                                    }}>
                                    <Row>
                                        <Col sm={6}>
                                            <label htmlFor="question_en" className="form-label">
                                                {props.t("question_en")}{" "}
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={props.t("question_en")}
                                                name="question_en"
                                                onChange={validationType.handleChange}
                                                onBlur={validationType.handleBlur}
                                                value={validationType.values.question_en || ""}
                                            />
                                            {validationType.touched.question_en && validationType.errors.question_en ? (
                                                <p className="text-danger text-small">
                                                    {validationType.errors.question_en}
                                                </p>
                                            ) : null}
                                        </Col>
                                        <Col sm={6}>
                                            <label htmlFor="question_th" className="form-label">
                                                {props.t("question_th")}{" "}
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={props.t("question_th")}
                                                name="question_th"
                                                onChange={validationType.handleChange}
                                                onBlur={validationType.handleBlur}
                                                value={validationType.values.question_th || ""}
                                            />
                                            {validationType.touched.question_th && validationType.errors.question_th ? (
                                                <p className="text-danger text-small">
                                                    {validationType.errors.question_th}
                                                </p>
                                            ) : null}
                                        </Col>
                                    </Row>

                                    <Row>
                                        {formValues.map((element, index) => (
                                            <>
                                                <Col sm={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="option_en" className="form-label">
                                                            {index + 1 + "] " + props.t("option_en")} {" "}
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            key={`{optionEng}`+index}
                                                            name="option_en"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={props.t("option_en")}
                                                            onChange={e => {
                                                                handleChange(index, e)
                                                                onHandleChange(e.target.value, "optionEn", index);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="option_th" className="form-label">
                                                            {index + 1 + "] " + props.t("option_th")} {" "}
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            key={`{optionThai}`+index}
                                                            name="option_th"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={props.t("option_th")}
                                                            onChange={e => {
                                                                handleChange(index, e)
                                                                onHandleChange(e.target.value, "optionTh", index);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col sm={2}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="is_answer">
                                                            {props.t("is_answer")}
                                                        </Label>
                                                        <div className="form-check g-4">
                                                            <Input
                                                                key={`{isAnswer}`+index}
                                                                name="is_answer"
                                                                type="radio"
                                                                className="form-check-input"
                                                                onClick={e => {
                                                                    handleChange(index, e)
                                                                    onHandleChange(e.target.value, "isAnswer", index);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col sm={2}>
                                                    <div className="mb-3">
                                                        {
                                                            index ?
                                                                <a href="#" key={`{remove}`+index} onClick={e => { 
                                                                    removeFormFields(index);
                                                                    onRemoveOptionHandleChange(index);
                                                                }} title="Edit" >
                                                                    <img src={require("../../assets/images/delete-icon.png")} width={15} height={15} />
                                                                </a>
                                                            : null
                                                        }
                                                    </div>
                                                </Col>
                                            </>
                                        ))}
                                        <Col sm={12}>
                                            <Button color="success" className="btn btn-success" onClick={e => addFormFields()}>
                                                {props.t("Add_More")}
                                            </Button>
                                        </Col>
                                    </Row>

                                    <div className="flex-wrap gap-2 mt-3">
                                        <Button type="submit" className="btn btn-join2">
                                            {props.t("Submit")}
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>

                        <div className="mb-4 mt-5 application-form">
                            <div>
                                <h3 className="title mb-4">
                                    {props.t("List-Question-Answers")}
                                </h3>
                            </div>
                            <Row>
                                {activityQuestions?.map((question, i) => (
                                    <>
                                        <Row>
                                            <Col className="col-md-11 col-9">
                                                <label className="form-label">{i+1 + "] "} {question.question_en}</label>
                                            </Col>
                                            <Col className="col-md-1 col-3">
                                                <Link to={`/edit-question/${question.id}`} title="Edit" className="p-2">
                                                    <img src={require("../../assets/images/pencil-icon.png")} width={15} height={15} />
                                                </Link>
                                                <a onClick={() => {
                                                        setDeleteId(question.id)
                                                        setDelTitle(question.question_en)
                                                        toggleDelete()
                                                    }} title="Delete" >
                                                    <img src={require("../../assets/images/delete-icon.png")} width={15} height={15} />
                                                </a>
                                            </Col>
                                        </Row>
                                        <Row className="p-2">
                                            {question?.QuizQuesOption?.map((option, i) => (
                                                <Col sm={6}>
                                                    <div className="form-control text-dark fw-bolder d-flex align-items-center">
                                                        <input
                                                            disabled={!option.is_answer}
                                                            checked={option.is_answer}
                                                            type="radio"
                                                            className="m-1"
                                                            name={"option" + i }
                                                            key={`{option}`+ i}
                                                        /> {" "}
                                                            {option.option_en}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </section>


            {/* <!-- Remove Question & Answer Modal --> */}
                <Modal style={{ background: "transparent", borderRadius: 0, borderWidth: 0, boxShadow: "none" }} centered isOpen={modalDelete} toggle={toggleDelete}>
                    <ModalBody >
                        <div className="d-flex collection-btn-list">
                            <img
                                src="/assets/images/cancel.png"
                                width="38"
                                height="38"
                                alt=""
                                className="m-3"
                            />
                            <div>
                                <h5>{props.t("Delete Post")}</h5>
                                <p>{props.t("Are-you-Sure-Delete")} '{delTitle}' ? {props.t("Action-Cannot-Undone")} </p>
                            </div>
                        </div>
                        <div className="bg-gray d-flex justify-content-end">
                            <Button
                                className="float-right btn btn-cancel-d mx-2"
                                onClick={() => {
                                    setDeleteId(null)
                                    toggleDelete()
                                }}
                            >
                                {props.t("Cancel")} 
                            </Button>
                            <Button
                                className="float-right btn-cancel"
                                onClick={() =>
                                    deleteActivityQuestionAnswers()
                                }
                            >
                                {props.t("Yes-Delete")}
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            {/* <!-- End Remove Question & Answer Modal --> */}
        </div>
    )
}

export default withTranslation()(AddQuestion);
