import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const BookingApi = {
  getUpcomingBooking: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_UPCOMING_BOOKING}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getPastBookingAPI: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_PAST_BOOKING}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getCancelledBooking: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_CANCELLED_BOOKING}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getBookingDetails: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_BOOKING_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  cancelBookingAPI: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CANCELLED_BOOKING}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  bookedActivityCheckIn: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ACTIVITY_CHECK_IN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  bookedActivityJoin: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.BOOKING_ACTIVITY_JOIN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  bookedActivityCheckOut: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ACTIVITY_CHECK_OUT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  bookedActivityCheckOut: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ACTIVITY_CHECK_OUT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  bookedActivityCheckOutOnline: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ACTIVITY_CHECK_OUT_ONLINE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  checkActivityBookedOrNotAPI: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CHECK_ACTIVITY_BOOKED}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
};
