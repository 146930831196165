import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link, useParams } from "react-router-dom";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import { CommunityApi } from "../../apis/CommunityApi";
import config from "../../config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import constants from "../../constants";
import { getRoleID, getUserID } from "../../Token";
import { Button, Form, Input, Label } from "reactstrap";
import Linkify from "react-linkify";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import i18n from "../../i18n";

function CommunityDetails(props) {
  const navigate = useNavigate();
  const params = useParams();
  const community_post_id = params.community_post_id;
  const type = params.type;

  const [communitypostdetailData, setCommunitypostdetailData] = useState({
    community_post_id: community_post_id,
    page: 1,
    limit: config.LIMIT,
  });

  const [communitypostdetail, setCommunitypostdetail] = useState({});
  const [communityuser, setCommunityuser] = useState({});
  const [usercomment, setUsercomment] = useState([]);
  const [replyid, setReplyId] = useState(0);
  const [comment, setComment] = useState("");
  const [editcomment, setEditComment] = useState("");
  const [editcomment_id, setEditCommentId] = useState("");

  const userReduxState = useSelector((state) => state.profile_image);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);

  const [isEditTextShow, setisEditTextShow] = useState(false);
  const [isEditCommentId, setisEditCommentId] = useState(false);

  const toggleDelete = () => setModalDelete(!modalDelete);
  const modaldelete = useRef(null);

  const getCommunityPostsDetails = (data) => {
    CommunityApi.getCommunityPostsDetails(data)
      .then((res) => {
        if (res.data.success) {
          setCommunitypostdetail(res.data.data.post);
          setCommunityuser(res.data.data.post.User);
          if (res.data.data.comment) {
            if (res.data.data.comment.count !== 0) {
              setUsercomment(res.data.data.comment.rows);
            } else {
              setUsercomment([]);
            }
          } else {
            setUsercomment([]);
          }
        } else {
          setCommunitypostdetail({});
          setCommunityuser({});
          setUsercomment([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleChangeAddComment(event, direct) {
    if (direct) {
      const data = {
        parent_id: 0,
        comment: comment,
        community_post_id: community_post_id,
      };
      CommunityApi.postCommunityPostComment(data)
        .then((res) => {
          getCommunityPostsDetails(communitypostdetailData);
          setComment("");
        })
        .catch((err) => {
          console.log(err);
        });

      return;
    }

    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.value) {
        //add comments
        const data = {
          parent_id: 0,
          comment: event.target.value,
          community_post_id: community_post_id,
        };
        CommunityApi.postCommunityPostComment(data)
          .then((res) => {
            getCommunityPostsDetails(communitypostdetailData);
            setComment("");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  function handleChangeEditComment(event, direct, comment_id) {
    if (direct) {
      const data = {
        parent_id: 0,
        comment: editcomment,
        community_post_id: community_post_id,
        comment_id: comment_id,
      };
      CommunityApi.postCommunityEditPostComment(data)
        .then((res) => {
          getCommunityPostsDetails(communitypostdetailData);
          setComment("");
          setisEditTextShow(false);
          setisEditCommentId(false);
        })
        .catch((err) => {
          console.log(err);
        });

      return;
    }

    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.value) {
        //add comments
        const data = {
          parent_id: 0,
          comment: editcomment,
          community_post_id: community_post_id,
          comment_id: comment_id,
        };
        CommunityApi.postCommunityEditPostComment(data)
          .then((res) => {
            getCommunityPostsDetails(communitypostdetailData);
            setComment("");
            setisEditTextShow(false);
            setisEditCommentId(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  const handleChangeReplyComment = (event, parentId) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.value) {
        const data = {
          parent_id: parentId,
          comment: event.target.value,
          community_post_id: community_post_id,
        };
        CommunityApi.postCommunityPostComment(data)
          .then((res) => {
            if (res.data.success) {
              getCommunityPostsDetails(communitypostdetailData);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  function handleChangeLikePost(commentid) {
    const data = {
      post_comment_id: commentid,
      is_liked: true,
    };

    CommunityApi.postCommunityPostCommentLike(data)
      .then((res) => {
        getCommunityPostsDetails(communitypostdetailData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCommunityPostsDetails(communitypostdetailData);
  }, []);

  function handleChangeLikeReply(commentid) {
    const data = {
      post_comment_id: commentid,
      is_liked: true,
    };
    CommunityApi.postCommunityPostCommentLike(data)
      .then((res) => {
        if (res.data.success) {
          getCommunityPostsDetails(communitypostdetailData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function activeEditText(commentid, comment) {
    setEditComment(comment);
    setisEditTextShow(true);
    setisEditCommentId(commentid);
  }

  function deleteComment(commentid) {
    const data = {
      comment_id: isEditCommentId,
    };
    CommunityApi.deleteComment(data)
      .then((res) => {
        if (res.data.success) {
          toggleDelete();
          getCommunityPostsDetails(communitypostdetailData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCommunityPostsDetails(communitypostdetailData);
  }, []);

  function handleChangeLikeUnlikePost(isLike) {
    const data = {
      // post_comment_id: isLike,
      post_community_id: community_post_id,
      is_liked: isLike,
    };

    CommunityApi.CommunityPostsLikes(data)
      .then((res) => {
        getCommunityPostsDetails(communitypostdetailData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Navbar />
      <section className="pt-3 mb-0">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12">
              <h1 className="mt-5 mb-4 main-title d-m-none">
                {props.t("Community")}
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- In focus Detail Start --> */}
      <section className="community pb-3 mob-pt-1 mob-mt-m-35 mob-pb-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12 px-30 d-m-flex">
              <ul className="nav nav-tabs d-m-none" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community`}
                    className={`nav-link ${type == "INFOCUS" ? "active" : ""}`}
                  >
                    {props.t("In-Focus")}
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community/news`}
                    className={`nav-link ${type == "NEWS" ? "active" : ""}`}
                  >
                    {props.t("News")}
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to={`/community/blog`}
                    className={`nav-link ${
                      type == "D-CONNECT" ? "active" : ""
                    }`}
                  >
                    {props.t("Dr-Connect")}
                  </Link>
                </li>
              </ul>
              <div className="tab-content mob-mb-30 clearfix" id="myTabContent">
                <div
                  className="tab-pane fade show active focus-d"
                  id="focus"
                  role="tabpanel"
                  aria-labelledby="focus-tab"
                >
                  <div className="row">
                    <div className="col-md-10">
                      <img
                        src="/assets/images/768/top-gray-circle.png"
                        className="d-none top-circle"
                        alt=""
                        width=""
                        height=""
                      />
                      <div className="bg-gray focus-detail">
                        <div className="mb-3">
                          <a onClick={() => navigate(-1)} className="poi">
                            <img
                              src="/assets/images/left-arrow.png"
                              className=""
                              alt=""
                              width=""
                              height=""
                            />
                          </a>
                        </div>

                        <div className="px-4">
                          <h3>
                            {i18n.language == "en" &&
                            communitypostdetail.name_en
                              ? communitypostdetail.name_en
                              : communitypostdetail.name_th}
                          </h3>
                          <div className="d-flex align-items-center">
                            {/* <div>
                              <img
                                src="/assets/images/profile.png"
                                className="rounded"
                                alt=""
                                width="40"
                                height="40"
                              />
                            </div> */}
                            <div className="ml-1">
                              <h5 className="mb-0">
                                {i18n.language == "en" &&
                                communitypostdetail?.Company?.name_en
                                  ? communitypostdetail?.Company?.name_en
                                  : communitypostdetail?.Company?.name_th}
                              </h5>
                              <span>
                                {moment(
                                  communitypostdetail?.post_date
                                ).fromNow()}
                              </span>
                            </div>
                          </div>

                          <p className="desc my-3">
                            {i18n.language == "en" &&
                            communitypostdetail?.description_en ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: communitypostdetail?.description_en,
                                }}
                              ></div>
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: communitypostdetail?.description_th,
                                }}
                              ></div>
                            )}
                          </p>
                          <div className="specialiry sp-bg mb-3 list-style-none">
                            <ul className="d-flex">
                              {communitypostdetail?.CommunityPostInterest
                                ? communitypostdetail?.CommunityPostInterest.map(
                                    (citem, cindex) => {
                                      return (
                                        <div className="d-flex " key={cindex}>
                                          <li>
                                            <a className="d-flex">
                                              {i18n.language == "en" &&
                                              citem.Interest?.name_en
                                                ? citem.Interest?.name_en
                                                : citem.Interest?.name_th}
                                            </a>
                                          </li>
                                        </div>
                                      );
                                    }
                                  )
                                : ""}
                              {/* <li><a href="" className="">Oncology</a></li> */}
                            </ul>
                          </div>

                          <div className="slide-gallery my-3">
                            {communitypostdetail?.CommunityPostMedia ? (
                              communitypostdetail?.CommunityPostMedia[0]
                                .image_url ? (
                                communitypostdetail?.CommunityPostMedia[0]
                                  ?.media_type === "VIDEO" ? (
                                  <img
                                    className="center-cropped w-100"
                                    src={require("../../assets/images/videothumbnail.png")}
                                    alt="videothumnail"
                                  />
                                ) : (
                                  <img
                                    src={
                                      communitypostdetail?.CommunityPostMedia[0]
                                        .image_url
                                    }
                                    className="w-100 img-rouded-5"
                                  />
                                )
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="slide-gallery my-3">
                            {/*<div className="mySlides">
                                <img src="/assets/images/image 94.png" className="w-100 img-rounded-5" />
                            </div>

                            <div className="mySlides">
                                <img src="/assets/images/image 94.png" className="w-100 img-rounded-5" />
                            </div>

                            <div className="mySlides">
                                <img src="/assets/images/image 94.png" className="w-100 img-rounded-5" />
                            </div>

                            <div className="mySlides">
                                <img src="/assets/images/image 94.png" className="w-100 img-rounded-5" />
                            </div>

                            <div className="mySlides">
                                <img src="/assets/images/image 94.png" className="w-100 img-rounded-5" />
                            </div>

                            <div className="mySlides">
                                <img src="/assets/images/image 94.png" className="w-100 img-rounded-5" />
                            </div>

                            <a className="prev" onclick="plusSlides(-1)">❮</a>
                            <a className="next" onclick="plusSlides(1)">❯</a>  */}

                            <div className="d-flex  column-list row-cols-4">
                              {communitypostdetail?.CommunityPostMedia
                                ? communitypostdetail?.CommunityPostMedia.map(
                                    (comitem, comindx) => {
                                      return comindx !== 0 ? (
                                        comindx <= 3 ? (
                                          <div
                                            className="column "
                                            key={comindx}
                                          >
                                            <Link
                                              to={`/community/details/gallery/${communitypostdetail.id}/${comindx}`}
                                            >
                                              {comitem.media_type ===
                                              "VIDEO" ? (
                                                <img
                                                  className="center-cropped w-100"
                                                  src={require("../../assets/images/videothumbnail.png")}
                                                  alt="videothumnail"
                                                />
                                              ) : (
                                                <img
                                                  className="demo cursor h-75 img-fluid rounded-5"
                                                  src={comitem.image_url}
                                                  alt=""
                                                />
                                              )}
                                            </Link>
                                          </div>
                                        ) : comindx === 4 ? (
                                          <div className="column ">
                                            <Link
                                              to={`/community/details/gallery/${communitypostdetail.id}/${comindx}`}
                                            >
                                              {comitem.media_type ===
                                              "VIDEO" ? (
                                                <img
                                                  className="center-cropped w-100"
                                                  src={require("../../assets/images/videothumbnail.png")}
                                                  alt="videothumnail"
                                                />
                                              ) : (
                                                <img
                                                  className="demo cursor h-75 img-fluid "
                                                  src={comitem?.image_url}
                                                  alt=""
                                                />
                                              )}
                                              <div className="overlay  h-75">
                                                <span className="image-count h-25 ">
                                                  +
                                                  {communitypostdetail
                                                    .CommunityPostMedia
                                                    ?.length - 3}
                                                </span>
                                              </div>
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      );
                                    }
                                  )
                                : ""}

                              {/* <div className="column">
                                    <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                        onclick="currentSlide(1)" alt="" />
                                </div>
                                <div className="column">
                                    <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                        onclick="currentSlide(2)" alt="" />
                                </div>
                                <div className="column">
                                    <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                        onclick="currentSlide(3)" alt="" />
                                </div>
                                <div className="column">
                                    <img className="demo cursor w-100" src="/assets/images/image 92.png"
                                        onclick="currentSlide(4)" alt="" />
                                    <div className="overlay">
                                        <span className="image-count">+20</span>
                                    </div>
                                </div> */}
                            </div>
                          </div>
                          <div className="pt-3 mt-3 mb-4 d-flex align-items-center justify-content-between list-style-none">
                            <div>
                              <ul className="d-flex m-0 p-0 image-list">
                                {communitypostdetail?.Likes
                                  ? communitypostdetail?.Likes.map(
                                      (pvitem, pvindx) => {
                                        return pvindx <= 2 ? (
                                          <li key={pvindx}>
                                            <a href="">
                                              <img
                                                src={
                                                  pvitem.User.profile_image
                                                    ? `${config.IMAGE_URL}${pvitem.User.profile_image}`
                                                    : require("assets/images/profile.png")
                                                }
                                                className="rounded"
                                                alt=""
                                                width="35"
                                                height="35"
                                              />
                                            </a>
                                          </li>
                                        ) : pvindx === 3 ? (
                                          <li>
                                            <Link
                                              to={`/community/details/gallery/${communitypostdetail.id}/${pvindx}`}
                                            >
                                              <img
                                                src="/assets/images/avatar2.png"
                                                className="rounded img-thumbnail"
                                                alt=""
                                                width="50"
                                                height="50"
                                              />

                                              <span className="image-count">
                                                +
                                                {communitypostdetail
                                                  .CommunityPostView.length - 3}
                                              </span>
                                            </Link>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      }
                                    )
                                  : ""}
                              </ul>
                            </div>
                            {constants.ROLE.COMPANY !== getRoleID() && (
                              <div>
                                {communitypostdetail?.isLike == 1 ? (
                                  <button
                                    type="button"
                                    className="btn-dl like-btn"
                                    onClick={
                                      () => handleChangeLikeUnlikePost(false)
                                      // setCommunityPostId(item.id)
                                    }
                                  >
                                    <img
                                      src="/assets/images/like.png"
                                      alt=""
                                      width=""
                                      height=""
                                      className="m-1"
                                    />
                                    {props.t("Like")}
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn-dl dislike-btn"
                                    onClick={() =>
                                      handleChangeLikeUnlikePost(true)
                                    }
                                  >
                                    <img
                                      src="/assets/images/dislike.png"
                                      alt=""
                                      className="m-1"
                                    />
                                    {props.t("Like")}
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="comment-section pt-4">
                            <h4 className="title mb-3">
                              {props.t("Comments")} ({usercomment.length})
                            </h4>
                            {usercomment
                              ? usercomment.map((uitem, uindx) => {
                                  return (
                                    <div key={uindx}>
                                      {" "}
                                      <div className="d-flex">
                                        <div>
                                          <img
                                            src={
                                              uitem.User.profile_image
                                                ? `${config.IMAGE_URL}${uitem.User.profile_image}`
                                                : require("assets/images/profile.png")
                                            }
                                            className="rounded"
                                            alt=""
                                            width="40"
                                            height="40"
                                          />
                                        </div>
                                        <div className="ml-1 w-100">
                                          <h5 className="mb-1">
                                            {uitem.User.name}
                                          </h5>
                                          <p className="desc mb-2">
                                            {isEditTextShow &&
                                            isEditCommentId == uitem.id ? (
                                              // comment-message
                                              <div
                                                className="comment-message"
                                                style={{ position: "relative" }}
                                              >
                                                <div className="d-flex justify-content-between align-items-center">
                                                  <div className="w-100">
                                                    <form>
                                                      <div className="form-group d-flex align-items-center justify-content-center">
                                                        <input
                                                          type="text"
                                                          className="form-control text-dark"
                                                          placeholder={props.t(
                                                            "Write-Comment"
                                                          )}
                                                          value={editcomment}
                                                          onChange={(e) =>
                                                            setEditComment(
                                                              e.target.value
                                                            )
                                                          }
                                                          onKeyDown={(e) =>
                                                            handleChangeEditComment(
                                                              e,
                                                              false,
                                                              uitem.id
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <a
                                                    role="button"
                                                    onClick={(e) =>
                                                      handleChangeEditComment(
                                                        e,
                                                        true,
                                                        uitem.id
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src="/assets/images/768/send.png"
                                                      className="send"
                                                      alt=""
                                                      width=""
                                                      height=""
                                                    />
                                                  </a>
                                                </div>
                                              </div>
                                            ) : (
                                              <>{uitem.comment}</>
                                            )}
                                          </p>

                                          <span>
                                            {moment(uitem.created_at).fromNow()}
                                          </span>
                                          <span>
                                            {/* <NavLink
                                            to="#"
                                            className={
                                              "text-b"
                                            }
                                            // onClick={() =>
                                            //   handleChangeLikePost(uitem.id)
                                            // }
                                          >
                                            Like
                                          </NavLink> */}
                                          </span>

                                          {uitem.user_id == getUserID() ? (
                                            <>
                                              <span>
                                                <NavLink
                                                  to="#"
                                                  className={"text-b"}
                                                  onClick={() =>
                                                    activeEditText(
                                                      uitem.id,
                                                      uitem.comment
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </NavLink>
                                              </span>
                                              <span>
                                                <a
                                                  role="button"
                                                  className="text-b"
                                                  onClick={() => {
                                                    setisEditCommentId(
                                                      uitem.id
                                                    );
                                                    toggleDelete();
                                                  }}
                                                >
                                                  Delete
                                                </a>
                                              </span>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {/* <!-- Remove Post Modal --> */}
                                          <Modal
                                            style={{
                                              background: "transparent",
                                              borderRadius: 0,
                                              borderWidth: 0,
                                              boxShadow: "none",
                                            }}
                                            centered
                                            isOpen={modalDelete}
                                            toggle={toggleDelete}
                                          >
                                            <ModalBody>
                                              <div className="d-flex collection-btn-list">
                                                <img
                                                  src="/assets/images/cancel.png"
                                                  width="38"
                                                  height="38"
                                                  alt=""
                                                  className="m-3"
                                                />

                                                <div>
                                                  <h5>
                                                    {props.t("Delete Comment")}
                                                  </h5>
                                                  {/* {communitypost.name_en} */}

                                                  <p>
                                                    Are you sure you want to
                                                    delete this comment?
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="bg-gray d-flex justify-content-end">
                                                <Button
                                                  className="float-right btn btn-cancel-d mx-2"
                                                  onClick={() => {
                                                    toggleDelete();
                                                  }}
                                                >
                                                  {props.t("Cancel")}
                                                </Button>
                                                <Button
                                                  className="float-right btn-cancel"
                                                  onClick={() =>
                                                    deleteComment()
                                                  }
                                                >
                                                  {props.t(
                                                    " Yes, delete comment"
                                                  )}
                                                </Button>
                                              </div>
                                            </ModalBody>
                                          </Modal>

                                          {/* <a
                                            role="button"
                                            className="text-b"
                                            onClick={() =>
                                              setReplyId(replyid == uitem.id ? undefined : uitem.id)
                                            }
                                          >
                                            Reply
                                          </a> */}
                                          {/* {uitem.id == replyid ? (
                                            <div className="w-100 comment-message">
                                              <form>
                                                <div className="form-group ">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="write a comment..."
                                                    onKeyDown={(e) =>
                                                      handleChangeReplyComment(e, uitem.id)
                                                    }

                                                  />
                                                </div>
                                              </form>
                                            </div>
                                          ) : null} */}
                                        </div>
                                        {/* {uitem?.likeCount > 0 ?
                                        <div>
                                          <strong>{uitem.likeCount}</strong>{" "}
                                          <img
                                            src="/assets/images/like.png"
                                            className=""
                                            alt=""
                                            width=""
                                            height=""
                                          />
                                        </div>
                                        : null} */}
                                      </div>
                                      {/* {uitem.reply.length !== 0
                                      ? uitem.reply.map((ritem, rindx) => {
                                        return (
                                          <div
                                            className="reply-blocks pl-6 mt-3"
                                            key={rindx}
                                          >
                                            <div className="">
                                              <h5 className="mb-1">
                                                {ritem.User.first_name}
                                              </h5>
                                              <p className="desc mb-2">

                                                {ritem.comment}{" "}
                                              </p>
                                              <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                  <span>
                                                    {moment(
                                                      ritem.created_at
                                                    ).fromNow()}
                                                  </span>
                                                  <span>
                                                    <a
                                                      href="javascript:void(0)"
                                                      className="text-b"
                                                      onClick={() => handleChangeLikeReply(ritem.id)}
                                                    >
                                                      Like 
                                                    </a>
                                                  </span>
                                                </div>
                                                {ritem.likeCount > 0 ?
                                                <div>
                                                  <strong>{ritem.likeCount}</strong>{" "}
                                                  <img
                                                    src="/assets/images/like.png"
                                                    className=""
                                                    alt=""
                                                    width=""
                                                    height=""
                                                  />
                                                </div>
                                                :null}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })
                                      : ""} */}
                                    </div>
                                  );
                                })
                              : ""}
                          </div>
                        </div>
                      </div>
                      {constants.ROLE.COMPANY !== getRoleID() && (
                        <div className="comment-message mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <img
                                src={
                                  userReduxState.profile_image
                                    ? userReduxState.profile_image
                                    : require("assets/images/profile.png")
                                }
                                className="rounded"
                                alt=""
                                width="40"
                                height="40"
                              />
                            </div>
                            <div className="w-100">
                              <form>
                                <div className="form-group d-flex align-items-center justify-content-center">
                                  <input
                                    type="text"
                                    className="form-control text-dark"
                                    placeholder={props.t("Write-Comment")}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    onKeyDown={(e) =>
                                      handleChangeAddComment(e, false)
                                    }
                                  />
                                </div>
                              </form>
                            </div>
                            <a
                              role="button"
                              onClick={(e) => handleChangeAddComment(e, true)}
                            >
                              <img
                                src="/assets/images/768/send.png"
                                className="send"
                                alt=""
                                width=""
                                height=""
                              />
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="col-md-2 d-m-none">
                      <div className="img-block mb-3">
                        <img
                          src="/assets/images/image004.png"
                          className="img-fluid"
                          alt=""
                          width=""
                          height=""
                        />
                      </div>
                      <div className="img-block mb-3">
                        <img
                          src="/assets/images/image004.png"
                          className="img-fluid"
                          alt=""
                          width=""
                          height=""
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- In focus Detail End --></> */}
      <Footer />
    </>
  );
}
export default withTranslation()(CommunityDetails);
