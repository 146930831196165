import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { ProvinceApi } from "apis/ProvinceApi";
import config from "config";
import * as Yup from "yup";
import Navbar from "components/Layout/Navbar";
import Footer from "components/Layout/Footer";
import { DistrictApi } from "apis/DistrictApi";
import { SubDistrictApi } from "apis/SubDistrictApi";
import { useNavigate, useParams, Link} from "react-router-dom";
import { InstituteApi } from "apis/InstituteApi";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

const UNLIMITED_MEMBER = "UNLIMITED_MEMBER";
// const NO_OF_MEDICAL_MEMBERS = "NO_OF_MEDICAL_MEMBERS"
// const NO_OF_AFFILIATED_INSTITUTIONS = "NO_OF_AFFILIATED_INSTITUTIONS"

// const UNLIMITED_MEMBER = "UNLIMITED_MEMBER"
const NO_OF_MEDICAL_MEMBERS = "NUMBER_OF_MEDICAL_MEMBERS";
const NO_OF_AFFILIATED_INSTITUTIONS = "NUMBER_OF_AFFILIATED_INSTITUTIONS";

function Institute(props) {
  const [provinceId, setprovince_id] = useState([]);
  const [districtId, setdistrict_id] = useState([]);
  const [subDistrictId, setsubdistrict_id] = useState([]);
  const [origanizationType, setOriganizationType] = useState([]);
  const [educationType, setEducationType] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [sererror, setSerError] = useState([]);
  const [mainOrganizationTypeId, setmainOrganizationTypeId] = useState([]);
  const [files1, setFiles1] = useState([]);
  const [instituteDetail, setInstituteDetail] = useState(null);
  const [documentValidation, setDocumentValidation] = useState({});
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [defaultProvince, setDefaultProvince] = useState({
    label: props.t("Select"), 
    value: ""
  })
  const [defaultDistrict, setDefaultDistrict] = useState({
    label: props.t("Select"),
    value: ""
  })
  const [defaultSubDistrict, setDefaultSubDistrict] = useState({
    label:  props.t("Select"),
    value: ""
  })

  const [defaultMainOrgType, setDefaultMainOrgType] = useState({
    label: props.t("Select"),
    value: ""
  })

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [activeData, setActiveData] = useState({
    page: 1,
    total: 1,
    search: "",
    limit: config.LIMIT,
  });

  const params = useParams();

  const getAllProvince = (data) => {
    setLoading(true);
    ProvinceApi.getAllProvince(data)
      .then((res) => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        });
        setprovince_id(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMainOrganizationType = (data) => {
    ProvinceApi.getMainOrganizationType(data)
      .then((res) => {
        setmainOrganizationTypeId(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllProvince(activeData);
    getMainOrganizationType(activeData);

  }, []);

  const getOriganizationTypes = (data) => {
    ProvinceApi.getOriganizationTypes(data)
      .then((res) => {
        if (res.data.success) {
          setOriganizationType(res.data.data);
        } else {
          setOriganizationType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOriganizationTypes();
  }, []);

  const getEducationTypes = (data) => {
    ProvinceApi.getEducationTypes(data)
      .then((res) => {
        if (res.data.success) {
          setEducationType(res.data.data);
        } else {
          setEducationType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEducationTypes();
  }, []);

  const getServiceTypes = (data) => {
    ProvinceApi.getServiceTypes(data)
      .then((res) => {
        if (res.data.success) {
          setServiceType(res.data.data);
        } else {
          setServiceType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getServiceTypes();
  }, []);

  const getDocumentTypes = (data) => {
    ProvinceApi.getDocumentTypes(data)
      .then((res) => {
        if (res.data.success) {
          const docVal = {};
          for (let i = 0; i < res.data.data.length; i++) {
            docVal[`document_${res.data.data[i].id}`] =
              Yup.mixed().required(props.t("File-Required-Message"));;
          }
          setDocumentValidation({ ...docVal });
          setDocumentType(res.data.data);
        } else {
          setDocumentType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  

  // useEffect(() => {

  // }, []);

  const getInstituteDetails = (data) => {
    InstituteApi.getInstituteDetail(data)
      .then((res) => {
        if (res.data.success) {
          let editData = res.data.requestinstitute;
          setInstituteDetail({ ...res.data.requestinstitute });
          setDefaultProvince({label:(i18n.language == 'en' && editData.Province?.name_en) ? editData.Province?.name_en : editData.Province?.name_th,value:res.data.requestinstitute?.Province?.id})
          setDefaultDistrict({label:(i18n.language == 'en' && editData.District?.name_en) ? editData.District?.name_en : editData.Province?.name_th,value:res.data.requestinstitute?.District?.id})
          setDefaultSubDistrict({label:(i18n.language == 'en' && editData.SubDistrict?.name_en) ? editData.SubDistrict?.name_en : editData.Province?.name_th,value:res.data.requestinstitute?.SubDistrict?.id})
          setDefaultMainOrgType({label:(i18n.language == 'en' && editData.MainOrganizationType?.name_en) ? editData.MainOrganizationType?.name_en : editData.Province?.name_th,value:res.data.requestinstitute?.MainOrganizationType?.id})
          setTimeout(() => {
            const docs = res.data.requestinstitute.RequestInstituteDocument;
            const groupByCategory = docs.reduce((group, product) => {
              const { document_type_id } = product;
              group[document_type_id] = group[document_type_id] ?? [];
              group[document_type_id].push(product);
              return group;
            }, {});

            Object.keys(groupByCategory).forEach((item, index) => {
              formik.setFieldValue(
                `document_${groupByCategory[item][0]["document_type_id"]}`,
                formik.values[
                  `document_${groupByCategory[item][0]["document_type_id"]}`
                ]
                  ? [
                    ...formik.values[
                    `document_${groupByCategory[item][0]["document_type_id"]}`
                    ],
                    ...groupByCategory[item],
                  ]
                  : groupByCategory[item]
              );
            });


            getAllDistrict(res.data.requestinstitute?.province_id);
            getAllSubDistrict(res.data.requestinstitute?.district_id);
            getDocumentTypes({ type: "INSTITUTE" });
          }, 1000);
        } else {
          alert("Institute under verification.");
          navigate("/");
          setInstituteDetail(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInstituteDetails({ request_institute_id: params?.id });
  }, []);

  const getAllDistrict = (province_id) => {
    setLoading(true);
    const district = {
      page: 1,
      limit: 10,
      province_id: province_id,
    };

    DistrictApi.getAllDistrict(district)

      .then((res) => {
        setdistrict_id(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getAllSubDistrict = (district_id) => {
    // alert(1);
   
    setLoading(true);
    const sub_district = {
      page: 1,
      limit: 10,
      province_id: formik.values.province_id,
      district_id: district_id,
    };
    SubDistrictApi.getAllSubDistrict(sub_district)
      .then((res) => {
        setsubdistrict_id(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      institution_name: instituteDetail?.institution_name ?? "",
      affiliation: instituteDetail?.affiliation ?? "",
      main_organization_type_id : instituteDetail?.main_organization_type_id ?? "",
      old_institute_code : instituteDetail?.old_institute_code ?? "",
      institution_address: instituteDetail?.institution_address ?? "",
      province_id: instituteDetail?.province_id ?? "",
      district_id: instituteDetail?.district_id ?? "",
      sub_district_id: instituteDetail?.sub_district_id ?? "",
      post_code: instituteDetail?.post_code ?? "",
      phone: instituteDetail?.phone ?? "",
      email: instituteDetail?.email ?? "",
      website: instituteDetail?.website ?? "",
      established: instituteDetail?.established_year ?? "",
      members: instituteDetail?.number_of_members ?? "",
      medical_professors:
      instituteDetail?.education_number_of_medical_with ?? "",
      number_member: instituteDetail?.education_number_of_beds ?? "",
      number_medical: instituteDetail?.service_number_of_medical_with ?? "",
      number_bed: instituteDetail?.service_number_of_beds ?? "",
      title: instituteDetail?.contact_name_title ?? "",
      first_name: instituteDetail?.contact_first_name ?? "",
      last_name: instituteDetail?.contact_last_name ?? "",
      position: instituteDetail?.organization_position ?? "",
      other_phone: instituteDetail?.contact_phone ?? "",
      other_email: instituteDetail?.contact_email ?? "",
      other_website: instituteDetail?.contact_website ?? "",
      affiliated_institutions: instituteDetail?.network_with_affiliated_institutions ?? "",
      affiliated_number: instituteDetail?.affiliated_number ?? "",
      orgTypeId: instituteDetail?.organization_type_id ?? "",
      edutypeId: instituteDetail?.education_type_id ?? "",
      serTypeId: instituteDetail?.service_type_id ?? "",
    },
    validationSchema: Yup.object().shape({
      institution_name: Yup.string().required(props.t("Required-Message")),
      // affiliation: Yup.string().required(props.t("Required-Message")),
      institution_address: Yup.string().required(props.t("Required-Message")),
      main_organization_type_id: Yup.string(props.t("Select-Main-Organization-Message")).required(
        props.t("Required-Message")
      ),
      province_id: Yup.string(props.t("Select-Province-Message")).required(
        props.t("Required-Message")
      ),
      district_id: Yup.string(props.t("Select-District-Message")).required(
        props.t("Required-Message")
      ),
      sub_district_id: Yup.string(props.t("Select-SubDistrict-Message")).required(
        props.t("Required-Message")
      ),
      post_code: Yup.number()
        .typeError(props.t("Only-Number-Message"))
        .required(props.t("Required-Message")),
      // phone: Yup.number()
      //   .typeError(props.t("Only-Number-Message"))
      //   .min(2, "Min value 1.")
      //   .required(props.t("Required-Message")),
      // email: Yup.string().required(props.t("Required-Message")),
      // website: Yup.string().required(props.t("Required-Message")),
      //   medical: Yup.string().required(props.t("Required-Message")),
      established: Yup.string().matches(/^[0-9]{4}$/, props.t("Must-Be-4-Digit")),
      // members: Yup.string().required(props.t("Required-Message")),
      // medical_professors: Yup.string().required(props.t("Required-Message")),
      // number_member: Yup.number()
      // .typeError(props.t("Only-Number-Message"))
      // .required(props.t("Required-Message")),
      // number_medical: Yup.number()
      //   .typeError(props.t("Only-Number-Message"))
      //   .required(props.t("Required-Message")),
      // number_bed: Yup.number()
      //   .typeError(props.t("Only-Number-Message"))
      //   .required(props.t("Required-Message")),
      title: Yup.string().required(props.t("Required-Message")),
      first_name: Yup.string().required(props.t("Required-Message")),
      last_name: Yup.string().required(props.t("Required-Message")),
      position: Yup.string().required(props.t("Required-Message")),
      // other_phone: Yup.number()
      //   .typeError(props.t("Only-Number-Message"))
      //   .min(2, "Min value 1.")
      //   .required(props.t("Required-Message")),
      // other_email: Yup.string().required(props.t("Required-Message")),
      // other_website: Yup.string().required(props.t("Required-Message")),
      affiliated_institutions: Yup.string().required(props.t("Required-Message")),
      orgTypeId: Yup.string().when(['edutypeId','serTypeId'], {
        is: (edutypeId,serTypeId) => (!edutypeId || edutypeId.length === 0) && (!serTypeId || serTypeId.length === 0),
        then: Yup.string().required((props.t("Required-Message"))),
        otherwise: Yup.string()
      }),
      edutypeId: Yup.string().when(['orgTypeId','serTypeId'], {
        is: (orgTypeId,serTypeId) => (!orgTypeId || orgTypeId.length === 0) && (!serTypeId || serTypeId.length === 0),
        then: Yup.string().required((props.t("Required-Message"))),
        otherwise: Yup.string()
      }),
      serTypeId: Yup.string().when(['edutypeId','orgTypeId'], {
        is: (edutypeId,orgTypeId) => (!edutypeId || edutypeId.length === 0) && (!orgTypeId || orgTypeId.length === 0),
        then: Yup.string().required((props.t("Required-Message"))),
        otherwise: Yup.string()
      }),
      affiliated_number: Yup.string().when(["affiliated_institutions"], {
        is: (affiliated_institutions) =>
          (!affiliated_institutions || affiliated_institutions == "NUMBER_OF_MEDICAL_MEMBERS") ||
          (!affiliated_institutions || affiliated_institutions == "NUMBER_OF_AFFILIATED_INSTITUTIONS"),
        then: Yup.string().required(props.t("Required-Message")).matches(/^[0-9\b]+$/, props.t("Only-Number-Message")),
        otherwise: Yup.string(),
      }),
    },[["affiliated_institutions"],['orgTypeId','serTypeId'],['orgTypeId','edutypeId'],['serTypeId','edutypeId']]),
    onSubmit: (values) => {
      let flag = 0;

      documentType.forEach((element) => {
        if (values["document_" + element.id]?.length > 0) {
          values["document_" + element.id].forEach((doc) => {
            if (doc.status == "REJECTED") {
              if (!values["document_" + element.id].find((e) => e.name)) {
                formik.setFieldError(
                  "document_" + element.id,
                  "Please Select File"
                );
                flag = 1;
              }
            }
          });
        }
      });
      if (flag == 1) {
        return;
      }

      const data = new FormData();
      data.append("request_institute_id", params?.id);
      data.append("institution_name", values.institution_name);
      data.append("affiliation", values.affiliation);
      data.append("institution_address", values.institution_address);
      data.append("province_id", values.province_id);
      data.append("district_id", values.district_id);
      data.append("sub_district_id", values.sub_district_id);
      data.append("post_code", values.post_code);
      data.append('phone', values.phone);
      data.append('email', values.email);
      data.append("website", values.website);
      data.append("established_year", values.established);
      data.append("number_of_members", values.members);
      data.append("contact_name_title", values.title);
      data.append("contact_first_name", values.first_name);
      data.append("contact_last_name", values.last_name);
      data.append('contact_phone', values.other_phone);
      data.append('contact_email', values.other_email);
      data.append("contact_website", values.other_website);
      data.append("organization_type_id", values.orgTypeId);
      data.append("education_type_id", values.edutypeId);
      data.append(
        "education_number_of_medical_with",
        values.medical_professors
      );
      data.append("education_number_of_beds", values.number_member);
      data.append("service_type_id", values.serTypeId);
      data.append("service_number_of_medical_with", values.number_medical);
      data.append("service_number_of_beds", values.number_bed);
      data.append("organization_position", values.position);
      data.append("main_organization_type_id", values.main_organization_type_id);
      data.append("old_institute_code", values.old_institute_code);
      data.append("affiliated_number", values.affiliated_number);
      data.append(
        "network_with_affiliated_institutions",
        values.affiliated_institutions
      );
      for (let i = 0; i < files1.length; i++) {
        for (let j = 0; j < files1[i].file.length; j++) {
          data.append(`document_${files1[i].id}`, files1[i].file[j]);
        }
      }

      InstituteApi.resubmitPrimaryInstitute(data)
        .then((res) => {
          if (res.data.success == true) {
            alert(props.t("Request-Submitted-Message"));
            navigate("/");
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {

          } else {
            err.response.data.error.errors?.map((erritem, errindex) => {
              if(erritem.param === 'phone') {
              formik.setErrors({phone:`${erritem.msg}`});
              } 
              if(erritem.param == 'email') {
              formik.setErrors({email:`${erritem.msg}`});
              } 
              if(erritem.param == 'contact_phone') {
              formik.setErrors({other_phone:`${erritem.msg}`});
              } 
              if(erritem.param == 'contact_email') {
              formik.setErrors({other_email:`${erritem.msg}`});
              }
            })
          }

        });
    },
  });

  useEffect(() => {
    if (isFormSubmited) {
      const { errors } = formik
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        const errorElement = document.getElementById(keys[0])
        errorElement.focus()
        setIsFormSubmited(false)
      }
    }
  }, [isFormSubmited, formik])

  const removeDoc = (doc_id, lastModified) => {
    const updatedList = [
      ...formik.values[doc_id].filter((e) => e.lastModified !== lastModified),
    ];
    formik.setFieldValue(doc_id, updatedList.length > 0 ? updatedList : null);
  };

  const setAffiliateNumber = (val) => {
    formik.setFieldValue('affiliated_number','');
  };

  return (
    <div>
      <Navbar />

      <section className="pb-5 application">
        <div className="container">
        <div className="d-none">
            <div className="container">
              <div className="row">
                <div className="d-flex align-items-center justify-content-between mob-mt-39">
                  <div>
                  <Link to="/">
                      <img src={config.BASE_URL + "/assets/images/768/arrow-left.png"} alt="" />
                    </Link>
                  </div>
                  <div>
                    <h4 className="mb-0">{props.t("Sign Up")}</h4>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
           </div>
          <div className="row row2">
            <div className="col-md-12">
            <div  className="mb-0 d-m-none">
              <div className="d-flex align-items-center justify-content-between mb-4 mob-mt-30">
                <h3 className="mb-0">
                {props.t("Primary-Institute-Heading")}
                </h3>
              </div>
              </div>
              <div>
                {sererror?.map((erritem, errindex) => {
                  return (
                    <h4 className="text-danger fw-bolder">{erritem.msg}</h4>
                  );
                })}
              </div>
              <div>
                <h4>
                  {props.t("Status")} : <span className="text-danger">{props.t("REJECTED")}</span>
                </h4>
                <p><strong>{props.t("Comment")}</strong> : {instituteDetail?.comment}</p>
              </div>
              <div>
                <form onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  setIsFormSubmited(true);
                  return true;
                }}>
                  <div className="application-form mb-3">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                        {props.t("Institution-Name")} {" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.institution_name}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.institution_name &&
                              formik.errors.institution_name
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          // className="form-control text-dark"
                          id="institution_name"
                          placeholder={props.t("Institution-Name")}
                          name="institution_name"
                        // invalid={
                        //     formik.touched.institution_name && formik.errors.institution_name ? true : false
                        // }
                        />
                        {formik.touched.institution_name &&
                          formik.errors.institution_name ? (
                          <p className="text-danger text-small">
                            {formik.errors.institution_name}
                          </p>
                        ) : null}
                        {/* {formik.errors.institution_name ? <p className='text-danger border-danger'>{formik.errors.institution_name}</p> : null } */}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                        {props.t("Affiliation")}{" "}
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.affiliation}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.affiliation &&
                              formik.errors.affiliation
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="affiliation"
                          placeholder={props.t("Affiliation")}
                          name="affiliation"
                        />
                        {formik.touched.affiliation &&
                          formik.errors.affiliation ? (
                          <p className="text-danger text-small">
                            {formik.errors.affiliation}
                          </p>
                        ) : null}
                        {/* {formik.errors.affiliation ? <p className='text-danger border-danger'>{formik.errors.affiliation}</p> : null } */}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="" className="form-label">
                        {props.t("Institution-Address")} {" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.institution_address}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.institution_address &&
                              formik.errors.institution_address
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="institution_address"
                          placeholder={props.t("Institution-Address")}
                          name="institution_address"
                        />
                        {formik.touched.institution_address &&
                          formik.errors.institution_address ? (
                          <p className="text-danger text-small">
                            {formik.errors.institution_address}
                          </p>
                        ) : null}
                        {/* {formik.errors.institution_address ? <p className='text-danger border-danger'>{formik.errors.institution_address}</p> : null } */}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                        {props.t("Select-Province")} <span className="text-danger">*</span>
                        </label>
                        <Select
                          className={
                            formik.touched.province_id &&
                              formik.errors.province_id
                              ? " bor"
                              : " text-dark  "
                          }
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          onChange={(e) => {
                            setDefaultProvince(e)
                            formik.setFieldValue("province_id", e?.value);
                            setDefaultDistrict({
                              label:"Select",
                              value:""
                            })
                            setDefaultSubDistrict({
                              label:"Select",
                              value:""
                            })
                            getAllDistrict(e?.value);
                          }}
                          value={defaultProvince}
                          onBlur={formik.handleBlur}
                          name="province_id"
                          inputId="province_id"
                          placeholder={props.t("Select-Province")}
                          options={
                            provinceId?.map((item, index) => {
                              return {
                              value: item.id,
                              label: (i18n.language == 'en' && item.name_en) ? item.name_en : item.name_th,
                              };
                            })
                          }

                        />
                        {formik.touched.province_id &&
                          formik.errors.province_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.province_id}
                          </p>
                        ) : null}
                        {/* {formik.errors.province_id ? <p className='text-danger border-danger'>{formik.errors.province_id}</p> : null } */}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                          {props.t("Select-District")} <span className="text-danger">*</span>
                        </label>
                        <Select

                          onChange={(e) => {
                            setDefaultDistrict(e)
                            formik.setFieldValue('district_id', e?.value);
                            setDefaultSubDistrict({
                              label:"Select",
                              value:""
                            })
                            getAllSubDistrict(e?.value);
                          }}
                          value={defaultDistrict}
                          onBlur={formik.handleBlur}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            })
                          }}
                          name="district_id"
                          inputId="district_id"
                          className={
                            formik.touched.district_id &&
                              formik.errors.district_id
                              ? " bor"
                              : " text-dark"
                          }
                          placeholder={props.t("Select-District")} 
                          options={
                            districtId?.map((item, index) => {
                              return {
                              value: item.id,
                              label: (i18n.language == 'en' && item.name_en) ? item.name_en : item.name_th,
                              };
                            })
                          }
                        />
                        {formik.touched.district_id &&
                          formik.errors.district_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.district_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                        {props.t("Select-Sub-District")} {" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            setDefaultSubDistrict(e)
                            formik.setFieldValue('sub_district_id', e?.value);
                            formik.setFieldValue('post_code',e?.post_code);
                          }}
                          value={defaultSubDistrict}
                          onBlur={formik.handleBlur}
                          name="sub_district_id"
                          inputId="sub_district_id"
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            })
                          }}
                          className={
                            formik.touched.sub_district_id &&
                              formik.errors.sub_district_id
                              ? "bor"
                              : "text-dark"
                          }
                          placeholder={props.t("Select")}
                          options={
                            subDistrictId?.map((item, index) => {
                              return {
                              value: item.id,
                              post_code: item.post_code,
                              label: (i18n.language == 'en' && item.name_en) ? item.name_en : item.name_th,
                              };
                            })
                          }
                        />
                        {formik.touched.sub_district_id &&
                          formik.errors.sub_district_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.sub_district_id}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">
                        {props.t("Post-Code")} <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.post_code}
                          onBlur={formik.handleBlur}
                          readOnly={true}
                          className={
                            formik.touched.post_code && formik.errors.post_code
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="post_code"
                          placeholder={props.t("Post-Code")}
                          name="post_code"
                        />
                        {formik.touched.post_code && formik.errors.post_code ? (
                          <p className="text-danger text-small">
                            {formik.errors.post_code}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="border-dashed">
                      <div className="clearfix" />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Phone")} <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                          onBlur={formik.handleBlur}
                          readOnly={true}
                          type="text"
                          className={
                            formik.touched.phone && formik.errors.phone
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="phone"
                          placeholder={props.t("Phone")}
                          name="phone"
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <p className="text-danger text-small">
                            {formik.errors.phone}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("E-mail")} <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                          type="email"
                          readOnly={true}
                          className={
                            formik.touched.email && formik.errors.email
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="email"
                          placeholder={props.t("E-mail")}
                          name="email"
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <p className="text-danger text-small">
                            {formik.errors.email}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Website")} 
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.website}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.website && formik.errors.website
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="website"
                          placeholder={props.t("Website")}
                          name="website"
                        />
                        {formik.touched.website && formik.errors.website ? (
                          <p className="text-danger text-small">
                            {formik.errors.website}
                          </p>
                        ) : null}
                      </div>



                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Main-Organzation-Type")} <span className="text-danger">*</span>
                        </label>
                        <Select
                          onChange={(e) => {
                            setDefaultMainOrgType(e)
                            formik.setFieldValue('main_organization_type_id', e?.value);
                          }}
                          value={defaultMainOrgType}
                          options={
                            mainOrganizationTypeId?.map((item, index) => {
                              return {
                              value: item.id,
                              label: (i18n.language == 'en' && item.name_en) ? item.name_en : item.name_th,
                              };
                            })
                          }
                          onBlur={formik.handleBlur}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            })
                          }}
                          placeholder={props.t("Select")}
                          name="main_organization_type_id"
                          inputId="main_organization_type_id"
                          className={
                            formik.touched.main_organization_type_id &&
                              formik.errors.main_organization_type_id
                              ? " bor"
                              : " text-dark"
                          }
                        />

                        {formik.touched.main_organization_type_id &&
                          formik.errors.main_organization_type_id ? (
                          <p className="text-danger text-small">
                            {formik.errors.main_organization_type_id}
                          </p>
                        ) : null}
                      </div>


                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Old-Institute-Code")}
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.old_institute_code}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.old_institute_code && formik.errors.old_institute_code
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="old_institute_code"
                          placeholder={props.t("Old-Institute-Code")}
                          name="old_institute_code"
                        />
                        {formik.touched.old_institute_code && formik.errors.old_institute_code ? (
                          <p className="text-danger text-small">
                            {formik.errors.old_institute_code}
                          </p>
                        ) : null}
                      </div>
                          



                    </div>
                    <div className="border-dashed">
                      <div className="clearfix" />
                    </div>
                    <div className="row bg3 mb-3">
                      {origanizationType.map((orgitem, orgindx) => {
                        return (
                          <div className="col-md-3 mb-3">
                            <div
                              className={
                                formik.touched.orgTypeId &&
                                  formik.errors.orgTypeId
                                  ? "form-control bor text-dark fw-bolder"
                                  : "form-control redio-outer text-dark fw-bolder"
                              }
                            >
                              <input
                                onChange={formik.handleChange}
                                checked={formik.values.orgTypeId == orgitem.id}
                                type="radio"
                                id="orgTypeId"
                                name="orgTypeId"
                                value={orgitem.id}
                              />{" "}
                              {orgitem.name_en}
                            </div>
                            {formik.touched.orgTypeId &&
                              formik.errors.orgTypeId ? (
                              <p className="text-danger text-small ">
                                {formik.errors.orgTypeId}
                              </p>
                            ) : null}
                          </div>
                        );
                      })}
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Established")} 
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.established}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.established &&
                              formik.errors.established
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="established"
                          placeholder={props.t("Year")}
                          name="established"
                        />
                        {formik.touched.established &&
                          formik.errors.established ? (
                          <p className="text-danger text-small">
                            {formik.errors.established}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Number-Of-Members")}{" "}
                        </label>
                        <div className="d-flex align-items-center">
                          <div>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.members}
                              onBlur={formik.handleBlur}
                              type="text"
                              className={
                                formik.touched.established &&
                                  formik.errors.established
                                  ? "form-control bor"
                                  : "form-control mb-0 text-dark"
                              }
                              id="members"
                              placeholder={props.t("Number-Of-Members")}
                              name="members"
                            />
                            {formik.touched.members && formik.errors.members ? (
                              <p className="text-danger text-small">
                                {formik.errors.members}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <span>{props.t("People")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row bg3 mb-3">
                      {educationType
                        ? educationType?.map((editem, edindx) => {
                          return (
                            <div className="col-md-4 mb-3">
                              <div
                                className={
                                  formik.touched.edutypeId &&
                                    formik.errors.edutypeId
                                    ? "form-control bor text-dark fw-bolder"
                                    : "form-control redio-outer text-dark fw-bolder"
                                }
                              >
                                <input
                                  onChange={formik.handleChange}
                                  checked={
                                    formik.values.edutypeId == editem.id
                                  }
                                  type="radio"
                                  id="edutypeId"
                                  name="edutypeId"
                                  value={editem.id}
                                />{" "}
                                {editem.name_en}
                              </div>
                              {formik.touched.edutypeId &&
                                formik.errors.edutypeId ? (
                                <p className="text-danger text-small">
                                  {formik.errors.edutypeId}
                                </p>
                              ) : null}
                            </div>
                          );
                        })
                        : ""}

                      <div className="col-md-7">
                        <label htmlFor="" className="form-label">
                        {props.t("Number-Medical-Professors-Doctors")}{" "}
                       
                        </label>
                        <div className="d-flex align-items-center">
                          <div>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.medical_professors}
                              onBlur={formik.handleBlur}
                              type="text"
                              className={
                                formik.touched.established &&
                                  formik.errors.established
                                  ? "form-control bor"
                                  : "form-control mb-0 text-dark"
                              }
                              id="medical_professors"
                              placeholder={props.t("Number")}
                              name="medical_professors"
                            />
                            {formik.touched.medical_professors &&
                              formik.errors.medical_professors ? (
                              <p className="text-danger text-small">
                                {formik.errors.medical_professors}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <span>{props.t("People")}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Number-Of-Beds")}{" "}
                        </label>
                        <div className="d-flex align-items-center">
                          <div>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.number_member}
                              onBlur={formik.handleBlur}
                              type="text"
                              className={
                                formik.touched.established &&
                                  formik.errors.established
                                  ? "form-control bor"
                                  : "form-control mb-0 text-dark"
                              }
                              id="number_member"
                              placeholder={props.t("Number")}
                              name="number_member"
                            />
                            {formik.touched.number_member &&
                              formik.errors.number_member ? (
                              <p className="text-danger text-small ">
                                {formik.errors.number_member}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <span>{props.t("Bed")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row bg3 mb-3">
                      {serviceType
                        ? serviceType?.map((sritem, srindx) => {
                          return (
                            <div className="col-md-3 mb-3">
                              <div
                                className={
                                  formik.touched.serTypeId &&
                                    formik.errors.serTypeId
                                    ? "form-control bor text-dark fw-bolder"
                                    : "form-control redio-outer text-dark fw-bolder"
                                }
                              >
                                <input
                                  onChange={formik.handleChange}
                                  checked={
                                    formik.values.serTypeId == sritem.id
                                  }
                                  type="radio"
                                  id="serTypeId"
                                  name="serTypeId"
                                  value={sritem.id}
                                />{" "}
                                {sritem.name_en}
                              </div>
                              {formik.touched.serTypeId &&
                                formik.errors.serTypeId ? (
                                <p className="text-danger text-small ">
                                  {formik.errors.serTypeId}
                                </p>
                              ) : null} 
                            </div>
                          );
                        })
                        : ""}

                      <div className="col-md-7">
                        <label htmlFor="" className="form-label">
                        {props.t("Number-Medical-Professors-Doctors")}{" "}
                        </label>
                        <div className="d-flex align-items-center">
                          <div>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.number_medical}
                              onBlur={formik.handleBlur}
                              type="text"
                              className={
                                formik.touched.number_medical &&
                                  formik.errors.number_medical
                                  ? "form-control bor"
                                  : "form-control mb-0 text-dark"
                              }
                              id="number_medical"
                              placeholder={props.t("Number")}
                              name="number_medical"
                            />
                            {formik.touched.number_medical &&
                              formik.errors.number_medical ? (
                              <p className="text-danger text-small ">
                                {formik.errors.number_medical}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <span>{props.t("People")}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Number-Of-Beds")}
                          
                        </label>
                        <div className="d-flex align-items-center">
                          <div>
                            <input
                              onChange={formik.handleChange}
                              value={formik.values.number_bed}
                              onBlur={formik.handleBlur}
                              type="text"
                              className={
                                formik.touched.number_bed &&
                                  formik.errors.number_bed
                                  ? "form-control bor"
                                  : "form-control mb-0 text-dark"
                              }
                              id="number_bed"
                              placeholder={props.t("Number")}
                              name="number_bed"
                            />
                            {formik.touched.number_bed &&
                              formik.errors.number_bed ? (
                              <p className="text-danger text-small ">
                                {formik.errors.number_bed}
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <span>{props.t("Bed")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="application-form mb-3">
                    <h4 className="mb-3">
                      {props.t("Contact-Person-Title")}
                    </h4>
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="" className="form-label">
                        {props.t("Title")} <span className="text-danger">*</span>
                        </label>
                        <div className="d-flex align-items-center rd">
                          <div className="col-4">
                            <div
                              className={
                                formik.touched.title && formik.errors.title
                                  ? "form-control bor text-dark fw-bolder"
                                  : "form-control redio-outer text-dark fw-bolder"
                              }
                            >
                              <input
                                onChange={formik.handleChange}
                                checked={formik.values.title == "Mr"}
                                type="radio"
                                id="title"
                                name="title"
                                value="Mr"
                              // defaultChecked=""
                              />{" "}
                              {props.t("Mr")}
                            </div>

                            {formik.touched.title && formik.errors.title ? (
                              <p className="text-danger text-small ">
                                {formik.errors.title}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-4">
                            <div
                              className={
                                formik.touched.title && formik.errors.title
                                  ? "form-control bor text-dark fw-bolder"
                                  : "form-control redio-outer text-dark fw-bolder"
                              }
                            >
                              <input
                                onChange={formik.handleChange}
                                checked={formik.values.title == "Mrs"}
                                type="radio"
                                id="title"
                                name="title"
                                value="Mrs"
                              />{" "}
                              {props.t("Mrs")}
                            </div>

                            {formik.touched.title && formik.errors.title ? (
                              <p className="text-danger text-small ">
                                {formik.errors.title}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-4">
                            <div
                              className={
                                formik.touched.title && formik.errors.title
                                  ? "form-control bor mx-2 text-dark fw-bolder"
                                  : "form-control redio-outer text-dark fw-bolder"
                              }
                            >
                              <input
                                onChange={formik.handleChange}
                                checked={formik.values.title == "Ms"}
                                type="radio"
                                id="title"
                                name="title"
                                value="Ms"
                              />{" "}
                              {props.t("Ms")}
                            </div>

                            {formik.touched.title && formik.errors.title ? (
                              <p className="text-danger text-small ">
                                {formik.errors.title}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("First-Name")}  <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.first_name}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.first_name &&
                              formik.errors.first_name
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="first_name"
                          placeholder={props.t("First-Name")} 
                          name="first_name"
                        />
                        {formik.touched.first_name &&
                          formik.errors.first_name ? (
                          <p className="text-danger text-small">
                            {formik.errors.first_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Last-Name")} <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.last_name}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.last_name && formik.errors.last_name
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="last_name"
                          placeholder={props.t("Last-Name")}
                          name="last_name"
                        />
                        {formik.touched.last_name && formik.errors.last_name ? (
                          <p className="text-danger text-small">
                            {formik.errors.last_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Position")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.position}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.position && formik.errors.position
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="position"
                          placeholder={props.t("Position")}
                          name="position"
                        />
                        {formik.touched.position && formik.errors.position ? (
                          <p className="text-danger text-small">
                            {formik.errors.position}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="border-dashed">
                      <div className="clearfix" />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Phone")} <span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.other_phone}
                          onBlur={formik.handleBlur}
                          type="text"
                          readOnly={true}
                          className={
                            formik.touched.other_phone &&
                              formik.errors.other_phone
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="other_phone"
                          placeholder={props.t("Phone")}
                          name="other_phone"
                        />
                        {formik.touched.other_phone &&
                          formik.errors.other_phone ? (
                          <p className="text-danger text-small">
                            {formik.errors.other_phone}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("E-mail")}<span className="text-danger">*</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.other_email}
                          onBlur={formik.handleBlur}
                          type="email"
                          readOnly={true}
                          className={
                            formik.touched.other_email &&
                              formik.errors.other_email
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="other_email"
                          placeholder={props.t("E-mail")}
                          name="other_email"
                        />
                        {formik.touched.other_email &&
                          formik.errors.other_email ? (
                          <p className="text-danger text-small">
                            {formik.errors.other_email}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="" className="form-label">
                        {props.t("Website")} 
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.other_website}
                          onBlur={formik.handleBlur}
                          type="text"
                          className={
                            formik.touched.other_website &&
                              formik.errors.other_website
                              ? "form-control bor"
                              : "form-control text-dark"
                          }
                          id="other_website"
                          placeholder={props.t("Website")}
                          name="other_website"
                        />
                        {formik.touched.other_website &&
                          formik.errors.other_website ? (
                          <p className="text-danger text-small">
                            {formik.errors.other_website}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="application-form mb-3">
                    <h5 className="mb-3">{props.t("Main-Institution-Features")}</h5>
                    <ol>
                      <li>{props.t("Professional-Organization")} </li>
                      <li>
                        {props.t("Executive-Committee")}{" "}
                      </li>
                      <li>{props.t("Designated-Officer")} </li>
                      <li>{props.t("Continuing-Medical-Education")}</li>
                    </ol>
                    <div className="note">
                      {props.t("Institution-Note")}
                    </div>
                    <div className="border-dashed">
                      <div className="clearfix" />
                    </div>
                    <div className="row bg4">
                      <div className="col-md-12">
                        <label htmlFor="" className="form-label">
                        {props.t("Affiliated-Institutions")}
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <span>{props.t("Number")}</span>
                        </div>
                        <div
                          className={
                            formik.touched.affiliated_institutions &&
                              formik.errors.affiliated_institutions
                              ? "form-control bor mx-2 text-dark fw-bolder"
                              : "form-control redio-outer text-dark fw-bolder"
                          }
                        >
                          <input
                            onChange={formik.handleChange}
                            checked={
                              formik.values.affiliated_institutions ==
                              UNLIMITED_MEMBER
                            }
                            type="radio"
                            id="affiliated_institutions"
                            value={UNLIMITED_MEMBER}
                            name="affiliated_institutions"
                          />{" "}
                          {props.t("Unlimited-Number")}
                        </div>
                        {formik.touched.affiliated_institutions &&
                          formik.errors.affiliated_institutions ? (
                          <p className="text-danger text-small ">
                            {formik.errors.affiliated_institutions}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <div className=" d-flex  flex-column">
                          <div>
                            <span>{props.t("People")}</span>
                          </div>
                          <div
                            className={
                              formik.touched.affiliated_institutions &&
                                formik.errors.affiliated_institutions
                                ? "form-control bor mx-2 text-dark fw-bolder"
                                : "form-control redio-outer text-dark fw-bolder"
                            }
                          >
                            <input
                              onChange={(e) => {
                                formik.setFieldValue("affiliated_institutions",NO_OF_MEDICAL_MEMBERS);
                                setAffiliateNumber(NO_OF_MEDICAL_MEMBERS);
                              }}
                              checked={
                                formik.values.affiliated_institutions ==
                                NO_OF_MEDICAL_MEMBERS
                              }
                              type="radio"
                              id="affiliated_institutions"
                              value={NO_OF_MEDICAL_MEMBERS}
                              name="affiliated_institutions"
                            />{" "}
                            {props.t("Number-Of-Medical-Members")}
                          </div>
                        </div>
                        {formik.touched.affiliated_institutions &&
                          formik.errors.affiliated_institutions ? (
                          <p className="text-danger text-small ">
                            {formik.errors.affiliated_institutions}
                          </p>
                        ) : null}

                          { formik.values.affiliated_institutions == 'NUMBER_OF_MEDICAL_MEMBERS' ? (
                          <>
                              <label htmlFor="" className="form-label">
                                {props.t("Please-Enter-Number")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                onChange={formik.handleChange}
                                value={formik.values.affiliated_number}
                                onBlur={formik.handleBlur}
                                type="text"
                                className={
                                  formik.touched.affiliated_number &&
                                  formik.errors.affiliated_number
                                    ? "form-control bor"
                                    : "form-control text-dark"
                                }
                                id="affiliated_number"
                                placeholder={props.t("Please-Enter-Number")}
                                name="affiliated_number"
                                
                              />
                              {formik.touched.affiliated_number &&
                              formik.errors.affiliated_number ? (
                                <p className="text-danger text-small">
                                  {formik.errors.affiliated_number}
                                </p>
                              ) : null}
                          </>
                        ) : null}
                        


                      </div>
                      <div className="col-md-4">
                        <div className="d-flex  flex-column">
                          <div>
                            <span>{props.t("Place")}</span>
                          </div>
                          <div
                            className={
                              formik.touched.affiliated_institutions &&
                                formik.errors.affiliated_institutions
                                ? "form-control bor mx-2 text-dark fw-bolder"
                                : "form-control redio-outer text-dark fw-bolder"
                            }
                          >
                            <input
                              onChange={(e) => {
                                formik.setFieldValue("affiliated_institutions",NO_OF_AFFILIATED_INSTITUTIONS);
                                setAffiliateNumber(NO_OF_AFFILIATED_INSTITUTIONS);
                              }}
                              checked={
                                formik.values.affiliated_institutions ==
                                NO_OF_AFFILIATED_INSTITUTIONS
                              }
                              type="radio"
                              id="affiliated_institutions"
                              value={NO_OF_AFFILIATED_INSTITUTIONS}
                              name="affiliated_institutions"
                            />{" "}
                            {props.t("Number-Of-Affiliated-Institutions")}
                          </div>
                        </div>
                        {formik.touched.affiliated_institutions &&
                          formik.errors.affiliated_institutions ? (
                          <p className="text-danger text-small ">
                            {formik.errors.affiliated_institutions}
                          </p>
                        ) : null}

                        { formik.values.affiliated_institutions == 'NUMBER_OF_AFFILIATED_INSTITUTIONS' ? (
                           <>
                              <label htmlFor="" className="form-label">
                                {props.t("Please-Enter-Number")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                onChange={formik.handleChange}
                                value={formik.values.affiliated_number}
                                onBlur={formik.handleBlur}
                                type="text"
                                className={
                                  formik.touched.affiliated_number &&
                                  formik.errors.affiliated_number
                                    ? "form-control bor"
                                    : "form-control text-dark"
                                }
                                id="affiliated_number"
                                placeholder={props.t("Please-Enter-Number")}
                                name="affiliated_number"
                                
                              />
                              {formik.touched.affiliated_number &&
                              formik.errors.affiliated_number ? (
                                <p className="text-danger text-small">
                                  {formik.errors.affiliated_number}
                                </p>
                              ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="border-dashed">
                      <div className="clearfix" />
                    </div>
                    <div className="row text5">
                      <h6>
                        {props.t("Experience-Heading")}
                      </h6>
                      <p>
                        {props.t("Experience-Note")}
                      </p>
                    </div>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th />
                          <th> {props.t("Education-Activities-Table-Head")}</th>
                          <th>{props.t("Status")}</th>
                          <th>{props.t("Attachment") + " "} 
                            <span className="text-danger" style={{fontSize:"12px"}}>
                              {props.t("Any-One-Is-Mandatory")}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {documentType
                          ? documentType?.map((doitem, doindx) => {
                            return (
                              <tr
                                style={{
                                  background:
                                    formik?.values[`document_${doitem.id}`]
                                      ?.length > 0 &&
                                      formik?.values[
                                      `document_${doitem.id}`
                                      ][0]["status"] == "REJECTED"
                                      ? "#f003"
                                      : "#0f02",
                                }}
                              >
                                <td className="">
                                  <div className="">
                                    <input
                                      type="checkbox"
                                      id="checkbox"
                                      checked={
                                        formik.errors[
                                          `document_${doitem.id}`
                                        ] ||
                                          !formik.values[
                                          `document_${doitem.id}`
                                          ]
                                          ? false
                                          : true
                                      }
                                    />
                                    <label htmlFor="checkbox" />
                                  </div>
                                </td>
                                <td style={{ width: "50%" }}>
                                  {doitem.id}. {doitem.title_en}{" "}
                                  {doitem?.is_required ? (
                                    <span className="text-danger"> *</span>
                                  ) : null}
                                  {formik.values[`document_${doitem.id}`]
                                    ?.length > 0 &&
                                    formik?.values[`document_${doitem.id}`][0]
                                      ?.status == "REJECTED" ? (
                                    <>
                                      <br />

                                      <div className="bg-white  my-2 p-2 px-3 rounded-2">
                                        <h6>Comment:</h6>
                                        <p>
                                          {" "}
                                          {
                                            formik?.values[
                                              `document_${doitem.id}`
                                            ][0]?.comment
                                          }
                                        </p>
                                      </div>
                                    </>
                                  ) : null}
                                </td>
                                <td>
                                  {formik.values[`document_${doitem.id}`]
                                    ?.length > 0 &&
                                    formik.values[`document_${doitem.id}`][0][
                                    "status"
                                    ]}
                                </td>
                                <td>
                                  <div className="mb-3 ">
                                    <label htmlFor="checkbox" />
                                    {(formik.values[`document_${doitem.id}`]
                                      ?.length > 0 &&
                                      formik?.values[
                                        `document_${doitem.id}`
                                      ][0]?.status == "REJECTED") ||
                                      !formik.values[
                                      `document_${doitem.id}`
                                      ] ? (
                                      <>
                                        <input
                                          name={`document_${doitem.id}`}
                                          id={`document_${doitem.id}`}
                                          placeholder=""
                                          type="file"
                                          multiple
                                          className="form-control text-dark"
                                          // onChange={formik.handleChange}
                                          onChange={(e) => {
                                            // formik.handleChange(e)

                                            const filesArray2 = [];
                                            for (
                                              let i = 0;
                                              i < e.target.files.length;
                                              i++
                                            ) {
                                              filesArray2.push(
                                                e.target.files[i]
                                              );
                                            }
                                            formik.setFieldValue(
                                              `document_${doitem.id}`,
                                              formik.values[
                                                `document_${doitem.id}`
                                              ]
                                                ? [
                                                  ...formik.values[
                                                  `document_${doitem.id}`
                                                  ],
                                                  ...filesArray2,
                                                ]
                                                : filesArray2
                                            );

                                            const fileobg = {
                                              id: doitem.id,
                                              file: e.target.files,
                                            };
                                            setFiles1([...files1, fileobg]);
                                          }}
                                        />

                                        {formik.errors[
                                          `document_${doitem.id}`
                                        ] ? (
                                          <p className="text-danger text-small ">
                                            {
                                              formik.errors[
                                              `document_${doitem.id}`
                                              ]
                                            }
                                          </p>
                                        ) : null}
                                      </>
                                    ) : null}

                                    {formik.values[`document_${doitem.id}`]
                                      ?.length > 0
                                      ? formik.values[
                                        `document_${doitem.id}`
                                      ]?.map((item, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className=" row  py-1 mx-2 my-1 rounded-3"
                                          >
                                            <div className="col-md-11">
                                              <svg
                                                width="16"
                                                height="18"
                                                viewBox="0 0 12 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M10.1766 2.07461C8.7047 0.602734 6.30783 0.602734 4.83751 2.07461L0.75939 6.14961C0.732827 6.17617 0.718764 6.21211 0.718764 6.24961C0.718764 6.28711 0.732827 6.32305 0.75939 6.34961L1.33595 6.92617C1.36231 6.95241 1.39798 6.96714 1.43517 6.96714C1.47236 6.96714 1.50804 6.95241 1.53439 6.92617L5.61251 2.85117C6.11877 2.34492 6.7922 2.0668 7.50783 2.0668C8.22345 2.0668 8.89689 2.34492 9.40158 2.85117C9.90783 3.35742 10.186 4.03086 10.186 4.74492C10.186 5.46055 9.90783 6.13242 9.40158 6.63867L5.24533 10.7934L4.57189 11.4668C3.9422 12.0965 2.91876 12.0965 2.28908 11.4668C1.98439 11.1621 1.8172 10.7574 1.8172 10.3262C1.8172 9.89492 1.98439 9.49024 2.28908 9.18555L6.41252 5.06367C6.5172 4.96055 6.6547 4.90273 6.80158 4.90273H6.80314C6.95002 4.90273 7.08595 4.96055 7.18908 5.06367C7.29376 5.16836 7.35002 5.30586 7.35002 5.45273C7.35002 5.59805 7.2922 5.73555 7.18908 5.83867L3.81876 9.20586C3.7922 9.23242 3.77814 9.26836 3.77814 9.30586C3.77814 9.34336 3.7922 9.3793 3.81876 9.40586L4.39533 9.98242C4.42168 10.0087 4.45736 10.0234 4.49455 10.0234C4.53174 10.0234 4.56741 10.0087 4.59377 9.98242L7.96251 6.61367C8.27345 6.30273 8.44377 5.89023 8.44377 5.45117C8.44377 5.01211 8.27189 4.59805 7.96251 4.28867C7.32033 3.64648 6.27658 3.64805 5.63439 4.28867L5.23439 4.69023L1.51251 8.41055C1.25991 8.66167 1.05967 8.96045 0.923413 9.28956C0.787158 9.61866 0.717597 9.97154 0.718764 10.3277C0.718764 11.0512 1.00158 11.7309 1.51251 12.2418C2.0422 12.7699 2.73595 13.034 3.4297 13.034C4.12345 13.034 4.8172 12.7699 5.34533 12.2418L10.1766 7.41367C10.8875 6.70117 11.2813 5.75273 11.2813 4.74492C11.2828 3.73555 10.8891 2.78711 10.1766 2.07461Z"
                                                  fill="black"
                                                  fill-opacity="0.45"
                                                />
                                              </svg>
                                              &ensp;
                                              <a
                                                target={"_blank"}
                                                href={item?.document_url}
                                              >
                                                {item?.name
                                                  ? item?.name + " (New)"
                                                  : `document_${index}`}
                                              </a>
                                            </div>
                                            {item?.name ? (
                                              <div className="col-md-1">
                                                <a
                                                  role="button"
                                                  onClick={() =>
                                                    removeDoc(
                                                      `document_${doitem.id}`,
                                                      item.lastModified
                                                    )
                                                  }
                                                >
                                                  <svg
                                                    width="12"
                                                    height="14"
                                                    viewBox="0 0 12 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M3.625 1.87305H3.5C3.56875 1.87305 3.625 1.8168 3.625 1.74805V1.87305H8.375V1.74805C8.375 1.8168 8.43125 1.87305 8.5 1.87305H8.375V2.99805H9.5V1.74805C9.5 1.19648 9.05156 0.748047 8.5 0.748047H3.5C2.94844 0.748047 2.5 1.19648 2.5 1.74805V2.99805H3.625V1.87305ZM11.5 2.99805H0.5C0.223438 2.99805 0 3.22148 0 3.49805V3.99805C0 4.0668 0.05625 4.12305 0.125 4.12305H1.06875L1.45469 12.2949C1.47969 12.8277 1.92031 13.248 2.45313 13.248H9.54688C10.0813 13.248 10.5203 12.8293 10.5453 12.2949L10.9313 4.12305H11.875C11.9438 4.12305 12 4.0668 12 3.99805V3.49805C12 3.22148 11.7766 2.99805 11.5 2.99805ZM9.42656 12.123H2.57344L2.19531 4.12305H9.80469L9.42656 12.123Z"
                                                      fill="black"
                                                      fill-opacity="0.45"
                                                    />
                                                  </svg>
                                                </a>
                                              </div>
                                            ) : null}
                                          </div>
                                        );
                                      })
                                      : null}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                  <div className="text5 px-4 list-style-none">
                    <h6>{props.t("Primary-Advice")} </h6>
                    <ul>
                      <li>
                        {props.t("Primary-Advice1")}</li>
                      <li>
                        {" "}
                        {props.t("Primary-Advice2")}
                      </li>
                      <li>
                        {props.t("Primary-Advice3")}
                      </li>
                      <li>
                        {props.t("Primary-Advice4")}
                      </li>
                    </ul>
                  </div>
                  <div className="">
                    <button type="submit" className="btn btn-join2 mt-5">
                      {props.t("Submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default withTranslation()(Institute);