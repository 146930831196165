import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const ProvinceApi = {
  getAllProvince: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_PROVINCE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getOriganizationTypes: async (data) => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_FETCH_ORIGANIZATION_TYPES}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getMainOrganizationType: async (data) => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_FETCH_MAIN_ORIGANIZATION_TYPES}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getEducationTypes: async (data) => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_FETCH_EDUCATION_TYPES}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getServiceTypes: async (data) => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_FETCH_SERVICE_TYPES}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getDocumentTypes: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_FETCH_DOCUMENT_TYPES}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getUserRequestInsititute: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_USER_REQUEST_INSTITUTE}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getUserRequestInsitituteDetails: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_USER_REQUEST_INSTITUTE_DETAILS}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
};
