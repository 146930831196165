import React, { useEffect, useState } from "react";
import { NavLink, Link, useParams } from "react-router-dom";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import { CommunityApi } from "../../apis/CommunityApi";
import config from "../../config";
import moment from "moment";

export default function CommunitySlideDetails() {
  const params = useParams();
  const community_post_id = params.community_post_id;

  const type = params.type;

  const [communitypostdetailData, setCommunitypostdetailData] = useState({
    community_post_id: community_post_id,
    page: 1,
    limit: config.LIMIT,
  });
  const [communitypostdetail, setCommunitypostdetail] = useState({});
  const [communityuser, setCommunityuser] = useState({});
  const [usercomment, setUsercomment] = useState([]);
  const [replyid, setReplyId] = useState(0);
  const [comment, setComment] = useState("")

  const getCommunityPostsDetails = (data) => {
    CommunityApi.getCommunityPostsDetails(data)
      .then((res) => {
        //console.log(res.data)
        if (res.data.success) {
          // const gdata = res.data.data.post;
          console.log(res.data.data.post);
          //console.log(res.data.data.comment.count);
          setCommunitypostdetail(res.data.data.post);
          setCommunityuser(res.data.data.post.User);
          if (res.data.data.comment) {
            if (res.data.data.comment.count !== 0) {
              setUsercomment(res.data.data.comment.rows);
            } else {
              setUsercomment([]);
            }
          } else {
            setUsercomment([]);
          }
        } else {
          setCommunitypostdetail({});
          setCommunityuser({});
          setUsercomment([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleChangeAddComment(event,direct) {


    if(direct){
      const data = {
        parent_id: 0,
        comment: comment,
        community_post_id: community_post_id,
      };
      CommunityApi.postCommunityPostComment(data)
        .then((res) => {
          getCommunityPostsDetails(communitypostdetailData);
          setComment("")
        })
        .catch((err) => {
          console.log(err);
        });

      return
    }


    if (event.key === "Enter") {
      event.preventDefault();
      //console.log(event.target.value);
      if (event.target.value) {
        //add comments
        const data = {
          parent_id: 0,
          comment: event.target.value,
          community_post_id: community_post_id,
        };
        CommunityApi.postCommunityPostComment(data)
          .then((res) => {
            getCommunityPostsDetails(communitypostdetailData);
            setComment("")
          })
          .catch((err) => {
            console.log(err);
          });

      }
    }
  }

  const handleChangeReplyComment = (event, parentId) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.value) {
        const data = {
          parent_id: parentId,
          comment: event.target.value,
          community_post_id: community_post_id,
        };
        CommunityApi.postCommunityPostComment(data)
          .then((res) => {
            if (res.data.success) {
              getCommunityPostsDetails(communitypostdetailData);
            }
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  function handleChangeLikePost(commentid) {
    const data = {
      post_comment_id: commentid,
      is_liked: true
    };

    CommunityApi.postCommunityPostCommentLike(data)
      .then((res) => {
        console.log(res.data)
        getCommunityPostsDetails(communitypostdetailData);
      })
      .catch((err) => {
        console.log(err);
      });



  }


  useEffect(() => {
    getCommunityPostsDetails(communitypostdetailData);
  }, []);


  function handleChangeLikeReply(commentid) {

    const data = {
      post_comment_id: commentid,
      is_liked: true
    };
    console.log(data, "HHHEJIHEKBKNK")
    CommunityApi.postCommunityPostCommentLike(data)
      .then((res) => {
        if (res.data.success) {
          getCommunityPostsDetails(communitypostdetailData);
        }
        //   console.log(res.data, "reply ho rha h like");
      })
      .catch((err) => {
        console.log(err);
      });

  }


  useEffect(() => {
    getCommunityPostsDetails(communitypostdetailData);
  }, []);




  function handleChangeLikeUnlikePost(isLike) {
    const data = {
      // post_comment_id: isLike,
      post_community_id: community_post_id,
      is_liked: isLike,
    };

    CommunityApi.CommunityPostsLikes(data)
      .then((res) => {
        getCommunityPostsDetails(communitypostdetailData);
      })
      .catch((err) => {
        console.log(err);
      });


  }


  return (
    <>
      <Navbar />
      <section className="pt-3 mb-0">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12">
              <h1 className="mt-5 mb-4 main-title d-m-none">Community</h1>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Explore Gallery Start --> */}
    <section className="pb-3 gallery">
        <div className="container p-0">
            <div className="row">
                <div className="col-md-12 px-30">
                    <a href="news.html" className="left-arrow d-m-none">
                        <img src="assets/images/left-arrow.png" className="mr-2" alt="" width="" height="" /> Back
                    </a>
                    <div className="slide-gallery mb-3 mx-auto">
                        <div className="mySlides">
                            <div className="numbertext d-none">1 / 11</div>
                            <img src="assets/images/image 1044.png" className=""/>
                        </div>

                        <div className="mySlides">
                            <div className="numbertext d-none">2 / 11</div>
                            <img src="assets/images/image 92.png" className=""/>
                        </div>

                        <div className="mySlides">
                            <div className="numbertext d-none">3 / 11</div>
                            <img src="assets/images/image 94.png" className="" />
                        </div>

                        <div className="mySlides">
                            <div className="numbertext d-none">4 / 11</div>
                            <img src="assets/images/image 92.png" className="" />
                        </div>

                        <div className="mySlides">
                            <div className="numbertext d-none">5 / 11</div>
                            <img src="assets/images/image 94.png" className="" />
                        </div>

                        <div className="mySlides">
                            <div className="numbertext d-none">6 / 11</div>
                            <img src="assets/images/image 92.png" className="" />
                        </div>

                        <div className="mySlides">
                            <div className="numbertext d-none">7 / 11</div>
                            <img src="assets/images/image 94.png" className="" />
                        </div>
                        <div className="mySlides">
                            <div className="numbertext d-none">8 / 11</div>
                            <img src="assets/images/image 92.png" className="" />
                        </div>

                        <div className="mySlides">
                            <div className="numbertext d-none">9 / 11</div>
                            <img src="assets/images/image 94.png" className="" />
                        </div>
                        <div className="mySlides">
                            <div className="numbertext d-none">10 / 11</div>
                            <img src="assets/images/image 92.png" className="" />
                        </div>

                        <div className="mySlides">
                            <div className="numbertext d-none">11 / 11</div>
                            <img src="assets/images/image 94.png" className="" />
                        </div>

                        <a className="prev" onclick="plusSlides(-1)">
                            <img src="assets/images/prev.png" alt="" width="" height="" />
                        </a>

                        <a className="next" onclick="plusSlides(1)">
                            <img src="assets/images/next.png" alt="" width="" height="" />
                        </a>

                        <div className="d-flex justify-content-center column-list">
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 104.png" onclick="currentSlide(1)"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 92.png" onclick="currentSlide(2)"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 94.png" onclick="currentSlide(3)"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 92.png" onclick="currentSlide(4)"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 94.png" onclick="currentSlide(5)"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 92.png" onclick="currentSlide(6)"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 94.png" onclick="currentSlide(7)"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 92.png" onclick="currentSlide(8)"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 94.png" onclick="currentSlide(9)"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 92.png" onclick="currentSlide(10)"
                                    alt="" />
                            </div>
                            <div className="column">
                                <img className="demo cursor" src="assets/images/image 94.png" onclick="currentSlide(11)"
                                    alt="" />
                            </div>

                        </div>
                    </div>
                    <div className="explore-listing  d-none">
                        <div className="my-3 mx-4">
                            <div>
                                <a href="news.html">
                                    <h4 className="mb-0">Product Name</h4>
                                </a>
                            </div>
                            <div>
                                <span>1,090 <small>THB</small></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Explore gallery End --> */}

      <Footer />
    </>
  );
}
