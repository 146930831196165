import config from "../../config";
import React from "react";
import { withTranslation } from "react-i18next";

const FileCard = (props) => {
  return (
    // <li>
      <a href={props?.item.file_url} target="_blank">
        <div className="pblock3 d-flex align-items-center mb-3">
          <div className="image">
            <img
              src="/assets/images/link.png"
              alt="link"
              className=""
              width="30"
              height=""
            />
          </div>
          <div className="text">
            <strong>{props?.item.title_en}</strong>
          </div>
        </div>
      </a>
    // </li>
  );
};

export default withTranslation()(FileCard);
