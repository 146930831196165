import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import config from '../../../config'
import { withTranslation } from "react-i18next";

const Product = (props) => {
    let product = props.product;
    return (
        <div className="explore-item text-center">
            <div className="position-relative main-img mb-2">
                <Link to={`/community/company/product/`+product.id}>
                    <img 
                        className="img-fluid mx-auto" 
                        src={ product.image_url ? 
                            product.image_url : 
                            config.BASE_URL + "/assets/images/default-activity.jpg"
                        }   
                        alt="" 
                    />
                </Link>
                {/* <span className="badge badge-violet">{product?.Interest?.name_en}</span> */}
            </div>
            <h4>{product.name_en}</h4>
        </div>
    )
}
export default withTranslation()(Product);