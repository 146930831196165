import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Layout/Navbar";
import { QuestionApi } from "../../apis/QuestionApi";
import { withTranslation } from "react-i18next";
import { getUser } from "../../Token";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { Row, Col, Button } from "reactstrap";
import _ from "lodash";

const StartQuiz = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const activity_id = params.activity_id;
  const [activityQuestions, setActivityQuestions] = useState([]);
  const [userQuizData, setUserQuizData] = useState([]);

  const fetchActivityQuizQuestions = () => {
    let data = { activity_id: activity_id };
    QuestionApi.fetchActivityQuizQuestions(data)
      .then((res) => {
        if (res.data.success) {
          setActivityQuestions(res.data.data);
        } else {
          setActivityQuestions([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchActivityQuizQuestions();
  }, []);

  const onHandleChange = (questionId, selectedOptionId, index) => {
    const userQuizDataCopy = _.cloneDeep(userQuizData);
    const addEditUserQuizData = userQuizDataCopy[index];
    if (!addEditUserQuizData) {
      const data = {
        question_id: questionId,
        answer_id: selectedOptionId,
      };
      userQuizDataCopy.push(data);
    } else {
      addEditUserQuizData.answer_id = selectedOptionId;
      userQuizDataCopy.splice(index, 1, addEditUserQuizData);
    }
    setUserQuizData(userQuizDataCopy);
    return true;
  };

  const onSubmitQuizAnswers = async () => {
    const payload = {
      activity_id: activity_id,
      userQuizData: userQuizData,
    };
    QuestionApi.submitQuizQuestionAnswerApi(payload)
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            text: res.data.message,
            icon: "success",
            imageAlt: "success image",
          }).then((result) => {
            if (result?.isConfirmed) {
              navigate(-1);
            }
          });
        } else {
          toastr.error(res?.data?.message, "");
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "");
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          );
        }
      });
  };

  return (
    <div>
      <Navbar />

      <section className="pb-5 application">
        <div className="container">
          <div className="row row2">
            <div className="align-items-center justify-content-between mob-mt-30">
              <div className="mb-3">
                <a onClick={() => navigate(-1)} className="poi">
                  <img src="/assets/images/left-arrow.png" alt="" />
                </a>
              </div>
            </div>

            <div className="mb-4 application-form">
              <div>
                <h3 className="title mb-4">{props.t("Quiz")}</h3>
              </div>
              <form>
                <Row>
                  {activityQuestions?.map((question, i) => (
                    <>
                      <Row>
                        <Col sm={12}>
                          <label className="form-label">
                            {i + 1 + ". "} {question.question_en}
                          </label>
                        </Col>
                      </Row>
                      <Row className="p-2">
                        {question?.QuizQuesOption?.map((option, j) => (
                          <Col sm={6}>
                            <div className="form-control text-dark fw-bolder d-flex align-items-center">
                              <input
                                type="radio"
                                className="m-1"
                                name={"option" + i}
                                key={`{option}` + i}
                                onChange={(e) =>
                                  onHandleChange(question.id, option.id, i)
                                }
                              />{" "}
                              {option.option_en}
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </>
                  ))}
                </Row>
                <div className="flex-wrap gap-2 mt-3">
                  <Button
                    type="button"
                    onClick={onSubmitQuizAnswers}
                    className="btn btn-join2"
                  >
                    {props.t("Submit")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(StartQuiz);
