import { withTranslation } from "react-i18next";

const QuizQuestionList = (props) => {

  return (
    <>
      {props.questions?.map((question, index) => (
        <div key={`quelist${index}`}>
          <row className="row">
            <div className="col-sm-9 col-9">
              <label className="form-label">
                {index + 1 + ". "} {question.question_en}
              </label>
            </div>
            {props.isInstituteFiledEnable && (
              <div className="col-sm-3 col-3">
                <a
                  href="javascript:void(0);"
                  onClick={(e) =>
                    props.onEditQuizQuestionHanlder(question, index)
                  }
                  title="Edit"
                  className="p-2"
                >
                  <img
                    src={require("assets/images/pencil-icon.png")}
                    width={15}
                    height={15}
                  />
                </a>
                <a
                  href="javascript:void(0);"
                  onClick={(e) =>
                    props.onDeletetQuizQuestionHanlder(question.id, index)
                  }
                  title="Delete"
                >
                  <img
                    src={require("assets/images/delete-icon.png")}
                    width="15"
                  />
                </a>
              </div>
            )}
          </row>
          <row className="row p-2">
            {question?.QuizQuesOption?.map((option, i) => (
              <div className="col-sm-6" key={`${props.from}opion${i}`}>
                <div className="form-control text-dark fw-bolder d-flex align-items-center">
                  <input
                    disabled={!option.is_answer}
                    checked={option.is_answer ? true : false}
                    type="radio"
                    className="m-1"
                    name={"option" + props.from + index}
                    key={`{option}` + index}
                  />{" "}
                  {option.option_en}
                </div>
              </div>
            ))}
          </row>
        </div>
      ))}
    </>
  );
};

export default withTranslation()(QuizQuestionList);
