import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import config from "../../config";
import * as url from "../../helpers/common_helper";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import MobileHeaderArrowBanner from "../../components/Layout/Mobile/MobileHeaderArrowBanner";
import MobileHeader from "../../components/Layout/Mobile/MobileHeader";
import constants from "../../constants";
import { CommunityApi } from "../../apis/CommunityApi";
import { ProductAPI } from "../../apis/ProductAPI";
import { CompanyAPI } from "../../apis/CompanyAPI";
import { withTranslation } from "react-i18next";
import Product from "pages/Community/components/Product";
import InfiniteScroll from "react-infinite-scroll-component";


const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};

function CompanyProducts(props) {

  const params = useParams();
  const company_id = params.company_id;
  const [companyDetail, setCompanyDetail] = useState({});
  const [searchActive, setSearchActive] = useState("");
  const [products, setProducts] = useState([]);
  const [productsData, setProductsData] = useState({
    page: 1,
    limit: config.LIMIT,
    search: "",
    company_id: company_id,
    // category_id: "All",
  });
  const [hasMore, sethasMore] = useState(true);

  const [communityTopic, setCommunityTopic] = useState([]);
  const [communityTopicData, setCommunityData] = useState({
    parent_type: "community",
  });

  // useEffect(() => {
  //     getCommunityTopic(communityTopicData);
  //   }, []);

  // const getCommunityTopic = (data) => {
  //     CommunityApi.getCommunityTopic(data)
  //       .then((res) => {
  //         if (res.data.success) {
  //           setCommunityTopic(res.data.data);
  //         } else {
  //           setCommunityTopic([]);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  // };

  const getProductsByCompany = (data, is_push_data) => {
    ProductAPI.getProductsByCompany(data)
      .then((res) => {
        if (res.data.success) {
          // if (is_push_data) {
          //   setProducts([...products, ...res.data.data]);
          // } else {
          //   setProducts(res.data.data);
          // }
          setProducts(res.data.data);
          if (res.data.data.length == 0) {
            sethasMore(false);
          }
        } else {
          setProducts([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const fetchMoreData = () => {
  //   const data = {
  //     page: productsData.page + 1,
  //     limit: config.LIMIT,
  //     company_id: company_id,
  //     search: searchActive,
  //   };
  //   getProductsByCompany(data, true);
  //   setProductsData(data);
  // };

  const getCompanyDetail = () => {
    const payload = {
      company_id: company_id,
    }
    CompanyAPI.getCompanyDetail(payload)
      .then((res) => {
        if (res.data.success) {
          setCompanyDetail(res.data.data);
        } else {
          setCompanyDetail({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // function handleChangeSearch(event) {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     setProductsData({
  //       ...productsData,
  //       company_id: company_id,
  //       search: event.target.value,
  //     });
  //   }
  // }

  // function handleChangeTopic(topicId) {
  //   setProductsData({
  //     ...productsData,
  //     category_id: topicId,
  //   });
  // }

  useEffect(() => {
    getProductsByCompany(productsData, false);
    getCompanyDetail();
  }, []);

  // window.onscroll = async function (ev) {
  //   var pageHeight = Math.max(
  //     document.body.scrollHeight,
  //     document.body.offsetHeight,
  //     document.documentElement.clientHeight,
  //     document.documentElement.scrollHeight,
  //     document.documentElement.offsetHeight
  //   );
  //   if (window.innerHeight + window.scrollY >= pageHeight) {
  //     const data = {
  //       page: productsData.page + 1,
  //       limit: config.LIMIT,
  //       company_id: company_id,
  //       search: searchActive,
  //     };
  //     setProductsData(data);
  //     getProductsByCompany(data, true);
  //   }
  // };

  return (
    <div>
      <Navbar />
      <ScrollToTopOnMount />
      {/* <!-- Mobile View Top Start --> */}
      {/* <MobileHeader title={props.t("Products")} /> */}
      {/* <!-- Mobile View Top End --> */}

      {/* <!-- Mobile Bottom Menu Start --> */}
      <MobileBottomMenu />
      {/* <!-- Mobile Bottom Menu End --> */}
        
      <>
        <div className="mobile-top d-none">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                  <NavLink to={"/community/in-focus/company"}>
                    <img
                      src="/assets/images/768/arrow-left-w.png"
                      className=""
                      alt=""
                    />
                  </NavLink>
                </div>
                {/* <div>
                  <h4 className="mb-0">{props.t("Products")}</h4>
                </div> */}
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row">
              <div className="col-md-12 list-style-none">
                <ul className="breadcrumb mt-3">
                  <li>
                    <Link to="/community">{props.t("Community")}</Link>
                  </li>
                  <li>/</li>
                  <li className="active">
                    <Link to="/community">{props.t("In-Focus")}</Link>
                  </li>
                  <li>/</li>
                  <li className="active">{companyDetail?.name_en}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-3 pb-5 details top-curve">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 event-item">
                <div className="row">
                  <div className="col-md-9 col-sm-12">
                    <h5>{companyDetail.name_en}</h5>
                    <p className="mt-2 mob-mt-2">{companyDetail.description_en}</p>
                    <p className="mt-2 mob-mt-2">{companyDetail?.address}</p>
                    <div className="row">
                      <div className="col-md-3 col-5">
                        <p>{props.t("Contact")}</p>
                        <div className="effect">
                          <div className="buttons">
                            {companyDetail.phone_no && (
                              <a href={`tel:` + companyDetail.phone_no} className="fb" title="Join us on Phone">
                                <img
                                  className="img-fluid mx-auto"
                                  src={config.BASE_URL + "/assets/images/social/phone.png"}
                                  alt=""
                                />
                                {/* <i className="fa fa-phone" aria-hidden="true"></i> */}
                              </a>
                            )}

                            {companyDetail.email && (
                              <a href={`mailto:` + companyDetail.email} className="tw" title="Join us on Envelope">
                                <img
                                  className="img-fluid mx-auto"
                                  src={config.BASE_URL + "/assets/images/social/message.png"}
                                  alt=""
                                />
                                {/* <i className="fa fa-envelope" aria-hidden="true"></i> */}
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9 col-7">
                        <p>{props.t("Social-Media")}</p>
                        <div className="effect">
                          <div className="buttons">
                            {companyDetail.website && (
                              <a href={companyDetail.website} target="_blank" className="dribbble" title="Join us on Website">
                                <img
                                  className="img-fluid mx-auto"
                                  src={config.BASE_URL + "/assets/images/social/website.png"}
                                  alt=""
                                />
                                {/* <i className="fa fa-globe" aria-hidden="true"></i> */}
                              </a>
                            )}

                            {companyDetail.facebook_url && (
                              <a href={companyDetail.facebook_url} target="_blank" className="fb" title="Join us on Facebook">
                                <img
                                  className="img-fluid mx-auto"
                                  src={config.BASE_URL + "/assets/images/social/facebook.png"}
                                  alt=""
                                />
                                {/* <i className="fa fa-facebook" aria-hidden="true"></i> */}
                              </a>
                            )}

                            {companyDetail.insta_url && (
                              <a href={companyDetail.insta_url} target="_blank" className="insta" title="Join us on Instagram">
                                <img
                                  className="img-fluid mx-auto"
                                  src={config.BASE_URL + "/assets/images/social/instagram.png"}
                                  alt=""
                                />
                                {/* <i className="fa fa-instagram" aria-hidden="true"></i> */}
                              </a>
                            )}

                            {companyDetail.line_url && (
                              <a href={companyDetail.line_url} target="_blank" className="in" title="Join us on Line In">
                                <img
                                  className="img-fluid mx-auto"
                                  src={config.BASE_URL + "/assets/images/social/line.png"}
                                  alt=""
                                />
                                {/* <i className="fa fa-comment" aria-hidden="true"></i> */}
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 company-detail d-m-none">
                    <img
                      className="img-fluid mx-auto"
                      src={companyDetail.logo_url ?
                        companyDetail.logo_url :
                        config.BASE_URL + "/assets/images/default-activity.jpg"
                      }
                      alt=""
                    />
                  </div>
                </div>

                <hr className="mob-mt-2"></hr>

                <div className="explore-listing mt-4 mob-mt-1">
                   {/* <InfiniteScroll
                      dataLength={products.length}
                      next={fetchMoreData}
                      loader={
                        <h4 className="w-50 h-40 text-[18px]">Loading...</h4>
                      }
                      hasMore={hasMore}
                    > */}
                  {(products.length > 0) ? ((products).map((product, i) => {
                    return (
                      <Product product={product} key={i} />
                    )
                  })
                  ) :
                    <div className="subtitle text-center">{props.t('No-Record-Found')}</div>
                  }
                 {/* </InfiniteScroll> */}
                </div>
              </div>
            </div>

            {/* <div className="row">
                    <div className="col-md-12">
                        <div className="search-box mb-2">
                            <div className="input-group">
                                <span className="input-group-text">
                                <img
                                    src="/assets/images/search.png"
                                    alt="search-icon"
                                    width="15"
                                    height="15"
                                />
                                </span>
                                <input
                                    type="text"
                                    className="form-control text-dark"
                                    placeholder={props.t("Search")}
                                    onKeyDown={handleChangeSearch}
                                />
                            </div>
                            <div className="mob-pr-0 specialiry my-4 mob-mb-2 mob-mt-30 mob-pl-8">
                                <h5 className="title mb-4">{props.t("Product-Type")}</h5>
                                <ul className="d-flex cursor pointer hidescroll">
                                <li>
                                    <a
                                        role="button"
                                        onClick={() => handleChangeTopic("All")}
                                        className={ productsData.category_id == "All" ? "active  " : ""}
                                    >
                                        {props.t("All")}
                                    </a>
                                </li>
                                {communityTopic
                                    ? communityTopic.map((titem, tindex) => {
                                        return (
                                        <div key={tindex} className="mx-1">
                                            <li>
                                            <a
                                                role="button"
                                                onClick={() =>
                                                handleChangeTopic(titem.id)
                                                }
                                                className={
                                                productsData.category_id ==
                                                titem.id
                                                    ? "active "
                                                    : "cursor pointer"
                                                }
                                            >
                                                {titem.name_en}
                                            </a>
                                            </li>
                                        </div>
                                        );
                                    })
                                    : ""}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
          </div>
        </section>
      </>
      <Footer />
    </div>
  );
}

export default withTranslation()(CompanyProducts);
