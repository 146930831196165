import config from "config";
import constants from "constants";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getRoleID } from "Token";
import i18n from "../../i18n";

const CommunityPostCard = (props) => (
  <div className="bg-gray mb-3">
    <Link to={`/community/details/${props.item.type}/${props.item.id}`}>
      <h3>
        {i18n.language == "en" && props.item.name_en
          ? props.item.name_en
          : props.item.name_th}
      </h3>
    </Link>
    <div className="d-flex align-items-center">
      {/* <div>
                                      <img
                                        src="/assets/images/profile.png"
                                        className="rounded"
                                        alt=""
                                        width="40"
                                        height="40"
                                      />
                                    </div> */}
      <div className="ml-1 d-flex justify-content-center align-items-center">
        <h5 className="mb-0">{props.item?.Company?.name_en} &#xb7;</h5>
        <span className="ml-5">{moment(props.item.post_date).fromNow()}</span>
      </div>
    </div>
    <Link to={`/community/details/${props.item.type}/${props.item.id}`}>
      <div className="slide-gallery my-3">
        {props.item.CommunityPostMedia ? (
          props.item.CommunityPostMedia[0]?.image_url ? (
            <div>
              {props.item.CommunityPostMedia[0]?.media_type === "VIDEO" ? (
                <img
                  className="center-cropped w-100"
                  src={require("../../assets/images/videothumbnail.png")}
                  alt="videothumnail"
                />
              ) : (
                <img
                  className="center-cropped w-100"
                  src={props.item.CommunityPostMedia[0]?.image_url}
                />
              )}
            </div>
          ) : (
            // <img
            //   src={props.item.CommunityPostMedia[0].image_url}
            //   className="w-100 img-rouded-5"
            // />
            ""
          )
        ) : (
          ""
        )}
      </div>
    </Link>
    <Link to={`/community/details/${props.item.type}/${props.item.id}`}>
      <div className="slide-gallery my-3">
        <div className="d-flex  column-list col-md-3 row-cols-4 w-100">
          {props.item.CommunityPostMedia
            ? props.item.CommunityPostMedia.map((comitem, comindx) => {
                return comindx !== 0 ? (
                  comindx <= 3 ? (
                    <div className="column" key={comindx}>
                      {comitem.media_type === "VIDEO" ? (
                        <img
                          className="center-cropped w-100"
                          src={require("../../assets/images/videothumbnail.png")}
                          alt="videothumnail"
                        />
                      ) : (
                        <img
                          className="demo cursor w-100 h-75 img-fluid rounded-5"
                          src={comitem.image_url}
                          alt=""
                        />
                      )}
                    </div>
                  ) : comindx === 4 ? (
                    <div className="column ">
                      <Link
                        to={`/community/details/${props.item.type}/${props.item.id}`}
                      >
                        {comitem.media_type === "VIDEO" ? (
                          <img
                            className="center-cropped w-100"
                            src={require("../../assets/images/videothumbnail.png")}
                            alt="videothumnail"
                          />
                        ) : (
                          <img
                            className="demo cursor w-100 h-75 img-fluid "
                            src={comitem.image_url}
                            alt=""
                          />
                        )}
                        <div className="overlay  h-75">
                          <span className="image-count h-25 ">
                            +{props.item.CommunityPostMedia.length - 3}
                          </span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                );
              })
            : ""}
        </div>
      </div>
    </Link>
    <div className="bottom-block pt-3 mt-3 d-flex align-items-center justify-content-between">
      <ul className="d-flex justify-content-between m-0 p-0 image-list w-100">
        <li className="d-flex">
          {constants.ROLE.COMPANY !== getRoleID() && (
            <div>
              {props.item.isLike == 1 ? (
                <button
                  type="button"
                  className="btn-dl like-btn"
                  onClick={
                    () => props.likeUnlikeHandler(false, props.item.id)
                    // setCommunityPostId(props.item.id)
                  }
                >
                  <img
                    src="/assets/images/like.png"
                    alt=""
                    width=""
                    height=""
                    className="m-1"
                  />
                  {props.t("Like")}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-dl dislike-btn"
                  onClick={() => props.likeUnlikeHandler(true, props.item.id)}
                >
                  <img
                    src="/assets/images/dislike.png"
                    alt=""
                    className="m-1"
                  />
                  {props.t("Like")}
                </button>
              )}
            </div>
          )}
          <div className="ml-1 list-style-none">
            <ul className="d-flex m-0 p-0 image-list">
              {props.item.Likes.map((litem, linedx) => {
                if (linedx <= 3) {
                  return (
                    <li>
                      <a href="">
                        <img
                          src={
                            litem.User.profile_image
                              ? `${config.IMAGE_URL}${litem.User.profile_image}`
                              : require("assets/images/profile.png")
                          }
                          className="rounded"
                          alt=""
                          width="35"
                          height="35"
                        />
                        {linedx === 3 && (
                          <span className="image-count">
                            {props.item.totalLikeCount - 4 > 0
                              ? `+${props.item.totalLikeCount - 4}`
                              : ""}
                          </span>
                        )}
                      </a>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          {/* <div>
                                        <ul className="d-flex m-0 p-0 image-list">
                                          {item.Likes.map((litem, linedx) => {
                                            return (
                                              <li className="ml-1">
                                              <a>
                                                {" "}
                                                <img
                                                  src={`${config.IMAGE_URL}${litem.User.profile_image}`}
                                                  className="rounded" alt="" width="35" height="35"
                                                />{" "}
                                              </a>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                        </div> */}
        </li>

        <Link
          to={`/community/details/${props.item.type}/${props.item.id}`}
          className="view-text2 pt-1"
        >
          {props.t("Comments")} ({props.item.totalCommentCount})
        </Link>
      </ul>
    </div>
  </div>
);

export default withTranslation()(CommunityPostCard);
