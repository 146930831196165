import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Navbar from "../../components/Layout/Navbar";
import { BookingApi } from "../../apis/BookingApi";
import config from "../../config";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import * as url from "../../helpers/common_helper";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import { withTranslation } from "react-i18next";
import constants from "../../constants";
import i18n from "../../i18n";


const Booking = (props) =>  {
  const formatDate = (
    value,
    formatting = { month: "short", day: "numeric", year: "numeric" }
  ) => {
    if (!value) return value;
    return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
  };

  const [upcomingBookingData, setUpcomingBookingData] = useState({
    page: 1,
    limit: config.LIMIT,
  });
  const [upcomingBooking, setUpcomingBooking] = useState([]);

  const [cancelledBookingData, setCancelledBookingData] = useState({
    page: 1,
    limit: config.LIMIT,
  });
  const [pastBookingData, setPastBookingData] = useState({
    page: 1,
    limit: config.LIMIT,
  });

  const [cancelledBooking, setCancelledBooking] = useState([]);
  const [pastBookings, setPastBookings] = useState([]);

  const getUpcomingBooking = (data) => {
    BookingApi.getUpcomingBooking(data)
      .then((res) => {
        //console.log(res.data)
        if (res.data.success) {
          setUpcomingBooking(res.data.data.rows);
        } else {
          setUpcomingBooking([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCancelledBooking = (data) => {
    BookingApi.getCancelledBooking(data)
      .then((res) => {
        //console.log(res.data.data.Activity)
        if (res.data.success) {
          // console.log(res.data.data);
          setCancelledBooking(res.data.data.rows);
        } else {
          setCancelledBooking([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPastBooking = (data) => {
    BookingApi.getPastBookingAPI(data)
      .then((res) => {
        //console.log(res.data.data.Activity)
        if (res.data.success) {
          setPastBookings(res.data.data.rows);
        } else {
          setPastBookings([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUpcomingBooking(upcomingBookingData);
    getCancelledBooking(cancelledBookingData);
    getPastBooking(pastBookingData);
  }, []);

  return (
    <div>
      {/* <!-- Navbar Start --> */}
      <Navbar />

      {/* <!-- Navbar End --> */}

      {/* <!-- Mobile View Top Start --> */}
      <div className="mobile-gray-top d-none">
        <div className="container">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between mob-mt-39">
              <div>
                <Link to="/profile">
                  <img
                    src="assets/images/768/arrow-left.png"
                    className=""
                    alt=""
                    width=""
                    height=""
                  />
                </Link>
              </div>
              <div>
                <h4 className="mb-0">{props.t("My-Booking")}</h4>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Mobile View Top End --> */}

      {/* <!-- Mobile Bottom Menu Start --> */}
      <MobileBottomMenu/>
      {/* <!-- Mobile Bottom Menu End --> */}

      <section className="mt-5  d-m-none">
        <div className="container">
          <div className="row p-8">
            <div className="col-md-12">
              <h1 className="mt-5  mb-3 main-title">{props.t("Profile")}</h1>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Booking Start --> */}
      <section className="dashboard top-curve2 mob-brd-radius-0">
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />
            <div className="col-md-10">
              <div className="dashboard-inner">
                <h2 className="mb-3">{props.t("Upcoming")}</h2>
                <div className="row booking mb-4">
                  {upcomingBooking.length > 0 ? (
                    upcomingBooking.map((item, i) => {
                      return (
                        <div
                          className="col-md-6 coupon mb-3"
                          key={"upcomingBooking_" + i}
                        >
                          <div className="d-flex">
                            <div
                              className={`block1 list-style-none ${
                                item.Activity.type == "Online" ? "bg-green" : ""
                              }`}
                            >
                              {/* <img
                                src={item.qr_code ? item.qr_code : require("../../assets/images/qr-code.png")}
                                className=""
                                width=""
                                height=""
                                alt=""
                              /> */}
                              <span>{props.t("Date-And-Time")}</span>
                              <h4>
                                {url.dateFormat(item?.Activity?.activity_date)}
                              </h4>
                              <h5>
                                {url.TimeFormat(item.Activity.start_time)} -{" "}
                                {url.TimeFormat(item.Activity.end_time)}
                              </h5>
                              <ul>
                                {/* <li>
                                      <span>Seal</span>
                                      <strong>23A</strong>
                                  </li> */}
                                <li>
                                  <span>{props.t("Price")}</span>
                                  <strong>
                                    {item.Activity.fee_type == "FREE"
                                      ? "Free"
                                      : "฿ " + item.Activity.fees}
                                  </strong>
                                </li>
                              </ul>
                            </div>
                            <div className="block2">
                              <Link to={`/booking-details/${btoa(item.id)}`}>
                                <img
                                  src={
                                    item?.Activity?.ActivityMedia[0]?.image_url
                                      ? item?.Activity?.ActivityMedia[0]
                                          ?.image_url
                                      : require("../../assets/images/default-activity.jpg")
                                  }
                                  className=""
                                  width=""
                                  height=""
                                  alt=""
                                />
                              </Link>
                              <Link to={`/booking-details/${btoa(item.id)}`}>
                                <h4
                                  className={`${
                                    item.Activity.type == "Online"
                                      ? "text-green"
                                      : ""
                                  }`}
                                >
                                  
                                  {
                                    i18n.language == 'en' && item.Activity.title_en ? (
                                        item.Activity.title_en.length > 32
                                          ? item.Activity.title_en.substring(0, 31) +
                                            "..."
                                          : item.Activity.title_en
                                    ) : ( 

                                      item.Activity.title_th.length > 32
                                          ? item.Activity.title_th.substring(0, 31) +
                                            "..."
                                          : item.Activity.title_th
                                    )
                                  }
                                </h4>
                              </Link>
                              <span
                                className={`text-center ${
                                  item.Activity?.type == "Online"
                                    ? "text-green"
                                    : ""
                                }`}
                              >
                               {item.Activity.type === constants.TYPE.Hybrid
                                ? 'Online/Onsite' 
                                : item.Activity.type
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-md-6 coupon mb-3">{props.t("No-Record-Found")}</div>
                  )}
                </div>
                <h2 className="mb-3">{props.t("Past")}</h2>
                <div className="row booking mb-4">
                  {pastBookings.length > 0 ? (
                    pastBookings.map((item, i) => {
                      return (
                        <div
                          className="col-md-6 coupon mb-3"
                          key={"pastBooking_" + i}
                        >
                          <div className="d-flex">
                            <div
                              className={`block1 list-style-none ${
                                item.Activity.type == "Online" ? "bg-green" : ""
                              }`}
                            >
                              {/* <img
                                src={item.qr_code ? item.qr_code : require("../../assets/images/qr-code.png")}
                                className=""
                                width=""
                                height=""
                                alt=""
                              /> */}
                              <span>{props.t("Date-And-Time")}</span>
                              <h4>
                                {url.dateFormat(item?.Activity?.activity_date)}
                              </h4>
                              <h5>
                                {url.TimeFormat(item.Activity.start_time)} -{" "}
                                {url.TimeFormat(item.Activity.end_time)}
                              </h5>
                              <ul>
                                {/* <li>
                                                            <span>Seal</span>
                                                            <strong>23A</strong>
                                                        </li> */}
                                <li>
                                  <span>{props.t("Price")}</span>
                                  <strong>
                                    {item.Activity.fee_type == "FREE"
                                      ? "Free"
                                      : "฿ " + item.Activity.fees}
                                  </strong>
                                </li>
                              </ul>
                            </div>
                            <div className="block2">
                              <Link to={`/booking-details/${btoa(item.id)}`}>
                                <img
                                  src={
                                    item?.Activity?.ActivityMedia[0]?.image_url
                                      ? item?.Activity?.ActivityMedia[0]
                                          ?.image_url
                                      : require("../../assets/images/default-activity.jpg")
                                  }
                                  className=""
                                  width=""
                                  height=""
                                  alt=""
                                />
                              </Link>
                              <Link to={`/booking-details/${btoa(item.id)}`}>
                                <h4
                                  className={`${
                                    item.Activity?.type == "Online"
                                      ? "text-green"
                                      : ""
                                  }`}
                                >
                                  {
                                    i18n.language == 'en' && item.Activity.title_en ? (
                                        item.Activity.title_en.length > 32
                                          ? item.Activity.title_en.substring(0, 31) +
                                            "..."
                                          : item.Activity.title_en
                                    ) : ( 

                                      item.Activity.title_th.length > 32
                                          ? item.Activity.title_th.substring(0, 31) +
                                            "..."
                                          : item.Activity.title_th
                                    )
                                  }
                                </h4>
                              </Link>
                              <span
                                className={`text-center ${
                                  item.Activity?.type == "Online"
                                    ? "text-green"
                                    : ""
                                }`}
                              >
                                {item.Activity.type === constants.TYPE.Hybrid
                                ? 'Online/Onsite' 
                                : item.Activity.type
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-md-6 coupon mb-3">{props.t("No-Record-Found")}</div>
                  )}
                </div>
                <h2 className="mb-3">{props.t("Cancel")}</h2>
                <div className="row booking mob-mb-30 clearfix">
                  {cancelledBooking.length > 0 ? (
                    cancelledBooking.map((item, i) => {
                      return (
                        <div
                          className="col-md-6 coupon mb-3"
                          key={"cancelledBooking" + i}
                        >
                          <div className="d-flex">
                            <div className="block1 list-style-none bg-orange">
                              {/* <img
                                src={item.qr_code ? item.qr_code : require("../../assets/images/qr-code.png")}
                                className=""
                                width=""
                                height=""
                                alt=""
                              /> */}
                              <span>{props.t("Date-And-Time")}</span>
                              <h4>
                                {url.dateFormat(item?.Activity?.activity_date)}
                              </h4>
                              <h5>
                                {url.TimeFormat(item?.Activity?.start_time)} -{" "}
                                {url.TimeFormat(item?.Activity?.end_time)}
                              </h5>
                              <ul>
                                {/* <li>
                                        <span>Seal</span>
                                        <strong>23A</strong>
                                    </li> */}
                                <li>
                                  <span>{props.t("Price")}</span>
                                  <strong>
                                    {item?.Activity?.fee_type == "FREE"
                                      ? "Free"
                                      : "฿ " + item?.Activity?.fees}
                                  </strong>
                                </li>
                              </ul>
                            </div>
                            <div className="block2">
                              <Link to={`/booking-details/${btoa(item.id)}`}>
                                <img
                                  src={
                                    item?.Activity?.ActivityMedia[0]?.image_url
                                      ? item?.Activity?.ActivityMedia[0]
                                          ?.image_url
                                      : require("../../assets/images/default-activity.jpg")
                                  }
                                  className=""
                                  width=""
                                  height=""
                                  alt=""
                                />
                              </Link>
                              <Link to={`/booking-details/${btoa(item.id)}`}>
                                <h4 className="text-orange">
                                {
                                    i18n.language == 'en' && item.Activity.title_en ? (
                                        item.Activity.title_en.length > 32
                                          ? item.Activity.title_en.substring(0, 31) +
                                            "..."
                                          : item.Activity.title_en
                                    ) : ( 

                                      item.Activity.title_th.length > 32
                                          ? item.Activity.title_th.substring(0, 31) +
                                            "..."
                                          : item.Activity.title_th
                                    )
                                  }
                                </h4>
                              </Link>
                              <span className="text-center text-orange2">
                              {item.Activity.type === constants.TYPE.Hybrid
                                ? 'Online/Onsite' 
                                : item.Activity.type
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-md-6 coupon mb-3">{props.t("No-Record-Found")}</div>
                  )}

                  {/* <div className="col-md-6 coupon mb-3">
                      <div className="d-flex">
                          <div className="block1 bg-orange">
                              <img src="assets/images/qr-code.png" className="" width="" height="" alt="" />
                              <span>Date and Time</span>
                              <h4>16 Sep 2022</h4>
                              <h5>10:00 - 10: 13</h5>
                              <ul>
                                  <li>
                                      <span>Seal</span>
                                      <strong>23A</strong>
                                  </li>
                                  <li>
                                      <span>Price</span>
                                      <strong>฿ 300.00</strong>
                                  </li>
                              </ul>
                          </div>
                          <div className="block2">
                              <a href=""><img src="assets/images/Ellipse 98.png" className="" width="" height=""
                                  alt="" /></a>
                              <a href="">
                                  <h4 className="text-orange">Dental Implants specialists in ...</h4>
                              </a>
                              <span className="text-center text-orange2">Onsite</span>
                          </div>
                      </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Booking End --> */}

      {/* <!-- Footer Start --> */}
      <Footer />
      {/* <!-- Footer End --> */}
    </div>
  );
}

export default withTranslation()(Booking);