import { ProfileAPI } from "apis/ProfileAPI";
import CheckResultCard from "pages/Components/CheckResultCard";
import { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { withTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Navbar from "../../components/Layout/Navbar";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const CheckResults = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { internshipSessionId, activityId, type, resultFor } = location.state;
  const [results, setResults] = useState([]);

  useEffect(() => {
    if(resultFor === "director"){
      getMsfAndHodResults();
    }else{
      getMsfAndTeachersResults()
    }
    
  }, []);

  const getMsfAndHodResults = async () => {
    const payload = {
      activity_id: activityId,
      type: type,
    };
    ProfileAPI.getMsfAndHodResultApi(payload)
      .then((res) => {
        if (res.data.success) {
          setResults(res.data.msfAndHods);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "");
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          );
        }
      });
  };

  const getMsfAndTeachersResults = async () => {
    const payload = {
      internshipSessionId: internshipSessionId,
      type: type,
    };
    ProfileAPI.getMsfAndTeacherResultApi(payload)
      .then((res) => {
        if (res.data.success) {
          setResults(res.data.msfAndTeachers);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "");
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          );
        }
      });
  };

  return (
    <div>
      <Navbar />
      <ScrollToTopOnMount />
      <>
        <div className="mobile-top d-none">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                  <NavLink to="/profile">
                    <img src="assets/images/768/arrow-left-w.png" alt="" />
                  </NavLink>
                </div>
                <div>
                  <h4 className="mb-0">{props.t("My-Calendar")}</h4>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View Top End */}
        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row p-8">
              <div className="col-md-12">
                <h1 className="mt-5  mb-3 main-title">{props.t("Profile")}</h1>
              </div>
            </div>
          </div>
        </section>
      </>

      <section className="dashboard top-curve-check-results">
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />
            <div className="col-md-10">
              <div className="align-items-center d-m-none justify-content-between mob-mt-30">
                <div className="mb-3">
                  <a onClick={() => navigate(-1)} className="poi">
                    <img src="/assets/images/left-arrow.png" alt="" />
                  </a>
                </div>
              </div>
              <div>
                <section
                  className="dashboard top-curve-check-results"
                  style={{ clear: "both" }}
                >
                  <div className="">
                    <div className="row p-12">
                      <div className="col-md-12">
                        <div className="dashboard-inner">
                          <div className="library-list list-style-none">
                            <ul className="first">
                              {results.length > 0
                                ? results.map((result, i) => {
                                    return (
                                      <CheckResultCard
                                        key={`cgeckresult${result.id}`}
                                        result={result}
                                        resultFor={resultFor}
                                      />
                                    );
                                  })
                                : "No record found"}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(CheckResults);
