// import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import config from '../../../config'
import { withTranslation } from "react-i18next";

const Company = (props) => {
    let company = props.company;
    return (
        <div className="explore-item text-center">
            <div className="position-relative company-img">
                <Link to={`/community/company/products/${company.id}`}>
                    <img 
                        className="img-fluid mx-auto mb-2" 
                        src={ company.logo_url ? company.logo_url : config.BASE_URL + "/assets/images/logo/svg/MedxLogo.svg"} 
                        alt="" 
                    />
                </Link>
            </div>
            <div className='row'>
                <div className='col-md-12 '>
                    <Link to={`/community/company/products/${company.id}`}>
                        <h4>{company.name_en}</h4>
                    </Link>
                    {/* <p className='long-text'>
                        {company.description_en}
                    </p> */}
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(Company);