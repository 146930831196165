import moment from 'moment'
import React from 'react'
import config from '../../../config'
import * as url from "../../../helpers/common_helper";
import { NavLink, Link } from 'react-router-dom';


function Library({library}) {
    return (
        <>
                     <li key={library.id}>
                     <NavLink to={'/education/education-library-details/' + library.id} >
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="book">
                              <img
                                src={library.image_url}
                                alt="book"
                                className=""
                                width={30}
                                height=""
                              />
                            </div>
                            <div className="block2">

                              <span>
                                {library.title_en}
                              </span>

                            </div>
                            <div className='list-style-none'>
                              <ul className="event-list">
                                <li>
                                  <div>
                                    <img
                                      className="img-fluid"
                                      src={config.BASE_URL + '/assets/images/clock.png'}
                                      alt=""
                                      width={12}
                                      height={12}
                                    />
                                  </div>
                                  <div>{url.dateFormat(library.created_at)}</div>
                                </li>
                                <li>
                                  <div>
                                    <img
                                      className="img-fluid "
                                      src={config.BASE_URL + '/assets/images/user.png'}
                                      alt=""
                                      width={12}
                                      height={12}
                                    />
                                  </div>
                                  <div>SICres</div>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <span className="credits">5 Credits</span>
                            </div>
                          </div>
                        </NavLink>
                      </li>
        </>
    )
}

export default Library