import { ProfileAPI } from "apis/ProfileAPI";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import Navbar from "../../components/Layout/Navbar";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import config from "../../config";
import constants from "../../constants";
import * as url from "../../helpers/common_helper";
import i18n from "../../i18n";
import Certificate from "../Components/Certificate";
import MobileMedxHistory from "./components/MobileMedxHistory";

function MyMedx(props) {
  const navigate = useNavigate();
  const [scoregroupthroughout, setScoreGroupWise] = useState([]);
  const [scoregroupwise, setScoreGroupW] = useState([]);
  const [summarydata, setSummaryData] = useState([]);
  const [yearlyscore, setYearlyScore] = useState([]);
  const [noImage, setNoImage] = useState("");
  const [activities, setActivity] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
  });

  useEffect(() => {
    getScoreData();
    getActivityHistory(activeData);
    getCertificates(activeData);
  }, []);

  const getScoreData = () => {
    ProfileAPI.getScoreData({ type: "" })
      .then((res) => {
        if (res.data.success) {
          setScoreGroupWise(res.data.data.scoregroupthroughout);
          setScoreGroupW(res.data.data.scoregroupwise);
          setSummaryData(res.data.data.summarydata);
          setYearlyScore(res.data.data.yearlyscore);
        } else {
          setScoreGroupWise([]);
          setScoreGroupW([]);
          setSummaryData([]);
          setYearlyScore([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getActivityHistory = (activeData) => {
    ProfileAPI.getScoreActivityHistory(activeData)
      .then((res) => {
        if (res.data.success) {
          setActivity(res.data.data.activites);
          setNoImage(res.data.data.noImage);
        } else {
          setActivity([]);
          setNoImage([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCertificates = (activeData) => {
    ProfileAPI.getCertificates(activeData)
      .then((res) => {
        if (res.data.success) {
          setCertificates(res.data.certificates.rows);
        } else {
          setCertificates([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onExportPdfHandler = () => {
    window.open("export-pdf", "_blank").focus();
    // const input = document.getElementById("export-cme-score-report-pdf");
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF("p", "mm", "a4");
    //   var width = pdf.internal.pageSize.getWidth();
    //   // var height = pdf.internal.pageSize.getHeight();
    //   pdf.addImage(imgData, "JPEG", 0, 0, width, 230);
    //   pdf.save(`cme-score-report.pdf`);
    // });
  };

  return (
    <div>
      <Navbar />
      <MobileBottomMenu />
      <>
        <div className="mobile-top d-none" id="test123">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                  <NavLink to="/">
                    <img
                      src="assets/images/768/arrow-left-w.png"
                      className=""
                      alt=""
                      width=""
                      height=""
                    />
                  </NavLink>
                </div>
                <div>
                  <h4 className="mb-0">{props.t("My-Medx")}</h4>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View Top End */}
        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row p-8">
              <div className="col-md-12">
                <h1 className="mt-5  mb-3 main-title">{props.t("Profile")}</h1>
              </div>
            </div>
          </div>
        </section>
      </>

      <section className="dashboard top-curve-profile">
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />
            <div className="col-md-10">
              <div className="dashboard-inner">
                <div className="row">
                  <div className="passport">
                    {summarydata.credit_score_year ? <div className="justify-content-between align-items-center">
                      <div className="float-right">
                        <button
                          type="button"
                          className="btn btn-join2"
                          onClick={onExportPdfHandler}
                        >
                          {props.t("exportPdf")}
                        </button>
                      </div>
                    </div> : ''}
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="score-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#score"
                          type="button"
                          role="tab"
                          aria-controls="score"
                          aria-selected="true"
                        >
                          {props.t("My")} {props.t("Score")}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="history-activity-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#history-activity"
                          type="button"
                          role="tab"
                          aria-controls="history-activity"
                          aria-selected="false"
                        >
                          {props.t("Activity")} {props.t("History")}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="certificate-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#certificate"
                          type="button"
                          role="tab"
                          aria-controls="certificate"
                          aria-selected="false"
                        >
                          {props.t("My")} {props.t("Certificate")}
                        </button>
                      </li>
                    </ul>

                    <div
                      className="tab-content mob-mb-30 clearfix"
                      id="myTabContent"
                    >
                      <div
                        className="tab-pane myscore fade show active"
                        id="score"
                        role="tabpanel"
                        aria-labelledby="score-tab"
                      >
                        <div className="row">
                          <h4 className="d-none tab-head">
                            {props.t("My-Score")}
                          </h4>
                          {summarydata.credit_score_year ? (
                            <>
                              <div className="col-md-6">
                                <div className="block">
                                  <small className="total-score">
                                    {props.t("Total-Score")}{" "}
                                    <span className="text-primary">
                                      ({summarydata.last_year} -{" "}
                                      {summarydata.current_year})
                                    </span>
                                  </small>
                                  <h4 className="pt-2 mb-0">
                                    {summarydata.credit_score_year.toFixed(2)}{" "}
                                    <small> {props.t("Credit")}</small>
                                  </h4>
                                  <strong className="text-primary">
                                    <NavLink
                                      to={
                                        "/mymedx-details/" +
                                        summarydata.last_year +
                                        "/" +
                                        summarydata.current_year +
                                        "/All"
                                      }
                                    >
                                      {summarydata.total_activity_year}{" "}
                                      {props.t("Activity")}
                                    </NavLink>
                                  </strong>
                                  <div className="progress my-3">
                                    {scoregroupwise.length > 0 ? (
                                      scoregroupwise?.map((score, index) => {
                                        return (
                                          <div
                                            key={`score_mymex_${index}`}
                                            className={
                                              "progress-bar progress-color" +
                                              (index + 1)
                                            }
                                            role="progressbar"
                                            style={{
                                              width: score.credit_score + "%",
                                            }}
                                            aria-valuenow={score.credit_score}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                          />
                                        );
                                      })
                                    ) : (
                                      <div className="subtitle text-center">
                                        {props.t("No-Yearly-Score-Found")}
                                      </div>
                                    )}
                                  </div>
                                  {scoregroupwise.length > 0 ? (
                                    scoregroupwise?.map((score, index) => {
                                      return (
                                        <div
                                          className="progress-list"
                                          key={`scoregroupwise_${index}`}
                                        >
                                          <ul className="d-flex flex-column">
                                            <li className="d-flex align-items-center justify-content-between">
                                              <div>
                                                <span
                                                  className={
                                                    "progress-color" +
                                                    (index + 1)
                                                  }
                                                ></span>
                                                {props.t("Activity Group")}{" "}
                                                {score.activity_group}
                                              </div>
                                              <div>
                                                <NavLink
                                                  to={
                                                    "/mymedx-details/" +
                                                    summarydata.last_year +
                                                    "/" +
                                                    summarydata.current_year +
                                                    "/" +
                                                    score.activity_group +
                                                    ""
                                                  }
                                                >
                                                  {score.total_activity}{" "}
                                                  {props.t("Activity")}
                                                </NavLink>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="subtitle text-center">
                                      {props.t("No-Yearly-Score-Found")}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="block">
                                  <small className="total-score">
                                    {props.t("Total-Score")}{" "}
                                    <span className="text-primary">
                                      ({props.t("Throughout-The-Period")})
                                    </span>
                                  </small>
                                  <h4 className="pt-2 mb-0">
                                    {summarydata.credit_score_througout.toFixed(
                                      2
                                    )}{" "}
                                    <small>{props.t("Credit")}</small>
                                  </h4>

                                  <strong className="text-primary">
                                    <NavLink
                                      to={
                                        "/mymedx-details/All/" +
                                        summarydata.current_year +
                                        "/All"
                                      }
                                    >
                                      {summarydata.total_activity_througout}{" "}
                                      {props.t("Activity")}
                                    </NavLink>
                                  </strong>
                                  <div className="progress my-3">
                                    {scoregroupthroughout.length > 0 ? (
                                      scoregroupthroughout?.map(
                                        (score, index) => {
                                          return (
                                            <div
                                              className={
                                                "progress-bar progress-color" +
                                                (index + 1)
                                              }
                                              key={`scoregroupthroughout__${index}`}
                                              role="progressbar"
                                              style={{
                                                width: score.credit_score + "%",
                                              }}
                                              aria-valuenow={score.credit_score}
                                              aria-valuemin={0}
                                              aria-valuemax={100}
                                            />
                                          );
                                        }
                                      )
                                    ) : (
                                      <div className="subtitle text-center">
                                        {props.t("No-Yearly-Score-Found")}
                                      </div>
                                    )}
                                  </div>
                                  {scoregroupthroughout.length > 0 ? (
                                    scoregroupthroughout?.map(
                                      (score, index) => {
                                        return (
                                          <div
                                            className="progress-list"
                                            key={`scoregroupthroughout_group_${index}`}
                                          >
                                            <ul className="d-flex flex-column">
                                              <li className="d-flex align-items-center justify-content-between">
                                                <div>
                                                  <span
                                                    className={
                                                      "progress-color" +
                                                      (index + 1)
                                                    }
                                                  ></span>
                                                  {props.t("Activity Group")}{" "}
                                                  {score.activity_group}
                                                </div>
                                                <div>
                                                  <NavLink
                                                    to={
                                                      "/mymedx-details/" +
                                                      summarydata.last_year +
                                                      "/" +
                                                      summarydata.current_year +
                                                      "/" +
                                                      score.activity_group +
                                                      ""
                                                    }
                                                  >
                                                    {score.total_activity}{" "}
                                                    {props.t("Activity")}
                                                  </NavLink>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <div className="subtitle text-center">
                                      {props.t("No-Yearly-Score-Found")}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="text-center">
                              {props.t("No-Score-Found")}
                            </div>
                          )}
                        </div>
                        <div className="score2 mt-4">
                          <h5>{props.t("Yearly-Score")}</h5>
                          <p>
                            {props.t("Scores-From-The-Past")} {props.t("Year")}
                          </p>

                          <div className="d-flex justify-content-between mb-3 row">
                            {yearlyscore.length > 0 ? (
                              yearlyscore?.map((score, index) => {
                                return (
                                  <div className="col-md-6">
                                    <div
                                      className="block"
                                      key={`yearlyscore_${index}`}
                                      style={{ margin: "10px 0px" }}
                                    >
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <strong className="year">
                                            {score.credit_year}
                                          </strong>
                                          <p className="mb-0 text-primary">
                                            <NavLink
                                              to={
                                                "/mymedx-details/" +
                                                score.credit_year +
                                                "/" +
                                                score.credit_year +
                                                "/All"
                                              }
                                            >
                                              {score.total_activity}{" "}
                                              {props.t("Activity")}
                                            </NavLink>
                                          </p>
                                        </div>
                                        <div className="activity">
                                          {score.credit_score.toFixed(2)}
                                        </div>
                                      </div>
                                      <div className="progress my-3 mb-0">
                                        <div
                                          className="progress-bar bg-secondary"
                                          role="progressbar"
                                          style={{ width: "30%" }}
                                          aria-valuenow={30}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="text-center">
                                {props.t("No-Yearly-Score-Found")}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane certificate fade"
                        id="certificate"
                        role="tabpanel"
                        aria-labelledby="certificate-tab"
                      >
                        {/* <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <h3 className="title mb-4">{props.t("Prescription")}</h3>
                                                    <div>
                                                        <a href="" className="btn btn-request bg-secondary">
                                                            {props.t("Request")}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="prescription d-flex align-items-center justify-content-center mb-5">
                                                    <img
                                                        src="assets/images/not-found.png"
                                                        className=""
                                                        alt=""
                                                        width=""
                                                        height=""
                                                    />
                                                    <p>
                                                        No prescription has
                                                        <br /> been requested.
                                                    </p>
                                                </div> */}
                        {/* <h3 className="title mb-4">{props.t("My")} {props.t("Certificate")}</h3> */}
                        {certificates.length !== 0 ? (
                          <div className="row certificate-list">
                            {certificates?.map((certificate, index) => {
                              return (
                                <Certificate
                                  certificate={certificate}
                                  key={`certificates_${index}`}
                                />
                              );
                            })}
                          </div>
                        ) : (
                          <div className="subtitle text-center">
                            {props.t("No-Record-Found")}
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane ha-tab fade"
                        id="history-activity"
                        role="tabpanel"
                        aria-labelledby="history-activity-tab"
                      >
                        {/* <div className="col-md-12 d-none">
                                                    <div className="search-box">
                                                        <form action="">
                                                            <div className="row">
                                                                <div className="col-md-9 pr-0">
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text">
                                                                            <img
                                                                                src="assets/images/search.png"
                                                                                alt="search-icon"
                                                                                width={15}
                                                                                height={15}
                                                                            />
                                                                        </span>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Search"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 pl-0 d-m-none">
                                                                    <div className="dropdown">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default dropdown-toggle"
                                                                            data-bs-toggle="dropdown"
                                                                        >
                                                                            {props.t("Filter-By-Institution")}
                                                                            <img
                                                                                src="assets/images/angle-down.png"
                                                                                alt=""
                                                                                className=""
                                                                                width=""
                                                                                height=""
                                                                            />
                                                                        </button>
                                                                        <ul className="dropdown-menu">
                                                                            <li>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="form-check-input"
                                                                                        id="radio1"
                                                                                        name="optradio"
                                                                                        defaultValue="option1"
                                                                                        defaultChecked=""
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="radio1">
                                                                                        Siriraj
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="form-check-input"
                                                                                        id="radio2"
                                                                                        name="optradio"
                                                                                        defaultValue="option2"
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="radio2">
                                                                                        Chulalongkorn
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="form-check-input"
                                                                                        id="radio3"
                                                                                        name="optradio"
                                                                                        defaultValue="option3"
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="radio3">
                                                                                        Chiangmai
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        type="radio"
                                                                                        className="form-check-input"
                                                                                        id="radio4"
                                                                                        name="optradio"
                                                                                        defaultValue="option4"
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="radio4">
                                                                                        Ramathibodi
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div> */}
                        <div className="col-md-12 mob-pr-0 specialiry mob-mb-0 mob-mt-30 mob-pl-8 "></div>

                        {/* Desktop Quiz Listing Start */}

                        {activities.length !== 0 ? (
                          <div className="events-listing mt-4 d-m-none">
                            {activities?.map((activity, index) => {
                              return (
                                <div
                                  className="event-item text-center list-style-none"
                                  key={`activities_${index}`}
                                >
                                  <div className="position-relative main-img mb-2">
                                    {activity.activity_id === null ||
                                    activity.Activity === null ||
                                    activity?.Activity?.ActivityMedia.length ===
                                      0 ? (
                                      <img
                                        className="img-fluid mx-auto"
                                        src={
                                          config.BASE_URL +
                                          "/assets/images/default-activity.jpg"
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <NavLink
                                        to={"#"}
                                        // to={'/education/education-activity-details/' + activity.activity_id}
                                      >
                                        <img
                                          className="img-fluid mx-auto"
                                          src={
                                            activity?.Activity?.ActivityMedia[0]
                                              ?.image_url
                                          }
                                          alt=""
                                        />
                                      </NavLink>
                                    )}
                                    <span>
                                      {activity?.credit_score.toFixed(2)}{" "}
                                      {props.t("Credit")}
                                    </span>
                                  </div>
                                  {activity.activity_id == null ||
                                  activity.activity_id == undefined ? (
                                    <h4>
                                      {i18n.language == "en" &&
                                      activity.title_en
                                        ? activity.title_en
                                        : activity.title_th}
                                    </h4>
                                  ) : (
                                    <NavLink
                                      to={"#"}
                                      // to={'/education/education-activity-details/' + activity.activity_id}
                                    >
                                      <h4>
                                        {i18n.language == "en" &&
                                        activity.title_en
                                          ? activity.title_en
                                          : activity.title_th}
                                      </h4>
                                    </NavLink>
                                  )}
                                  <ul className="event-list">
                                    <li>
                                      <div>
                                        <img
                                          className="img-fluid"
                                          src={
                                            config.BASE_URL +
                                            "assets/images/clock.png"
                                          }
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </div>
                                      <div>
                                        {url.dateFormat(activity.credit_date)}
                                      </div>
                                    </li>

                                    {activity?.Activity?.type && (
                                      <li>
                                        <div>
                                          <img
                                            className="img-fluid "
                                            src={
                                              config.BASE_URL +
                                              "assets/images/location-marker.png"
                                            }
                                            alt=""
                                            width={12}
                                            height={12}
                                          />
                                        </div>
                                        <div>
                                          {activity?.Activity?.type ===
                                          constants.TYPE.Hybrid
                                            ? "Online/Onsite"
                                            : activity?.Activity?.type}
                                        </div>
                                        {/* {activity.activity_id != null || activity.activity_id != undefined ? (
                                                                                <div>{activity?.Activity?.type}</div>
                                                                            ) : (
                                                                                <div>{props.t("Onsite")}</div>
                                                                            )} */}
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="subtitle text-center">
                            {props.t("No-Record-Found")}
                          </div>
                        )}

                        {/* Desktop Quiz Start */}
                        {/* Mobile Quiz carousel Start */}

                        <>
                          {/* Mobile Event carousel Start */}

                          {activities.length !== 0 ? (
                            // <div className="owl-carousel events-carousel mt-4 px-20 d-none">
                            <div className="collections  d-none">
                              {activities?.map((activity, index) => {
                                return (
                                  <MobileMedxHistory
                                    activity={activity}
                                    noImage={noImage}
                                    key={`_activities_mob_${index}`}
                                  />
                                );
                              })}
                            </div>
                          ) : (
                            // </div>
                            <div className="subtitle text-center d-none">
                              {props.t("No-Activities-Found")}
                            </div>
                          )}

                          {/* Mobile Event carousel End */}
                        </>

                        {/* <div className="owl-carousel events-carousel mt-4 mob-mb-5 d-none">
                                                    {activities.length !== 0 && activities?.map((activity, index) => {
                                                        return (<div className="event-item text-center">
                                                            <div className="position-relative main-img mb-2">
                                                                {activity.activity_id == null || activity.activity_id == undefined ? (
                                                                    <img
                                                                        className="img-fluid mx-auto"
                                                                        src={noImage}
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <NavLink to={'/education/education-activity-details/' + activity.activity_id} >
                                                                        <img
                                                                            className="img-fluid mx-auto"
                                                                            src={activity?.Activity?.ActivityMedia[0]?.image_url}
                                                                            alt=""
                                                                        />
                                                                    </NavLink>
                                                                )}

                                                                <span>{activity?.credit_score}  {props.t("Credit")}</span>
                                                            </div>
                                                            {activity.activity_id == null || activity.activity_id == undefined ? (
                                                                <h4>{activity.title_en}1</h4>
                                                            ) : (
                                                                <NavLink to={'/education/education-activity-details/' + activity.activity_id} >
                                                                    <h4>{activity.title_en}2</h4>
                                                                </NavLink>
                                                            )}
                                                            <ul className="event-list">
                                                                <li>
                                                                    <div>
                                                                        <img
                                                                            className="img-fluid"
                                                                            src={config.BASE_URL + "assets/images/clock.png"}
                                                                            alt=""
                                                                            width={12}
                                                                            height={12}
                                                                        />
                                                                    </div>
                                                                    <div>{url.dateFormat(activity.credit_date)}</div>
                                                                </li>
                                                                <li>
                                                                    <div>
                                                                        <img
                                                                            className="img-fluid "
                                                                            src={config.BASE_URL + "assets/images/location-marker.png"}
                                                                            alt=""
                                                                            width={12}
                                                                            height={12}
                                                                        />
                                                                    </div>
                                                                    {activity.activity_id != null || activity.activity_id != undefined ? (
                                                                        <div>{activity?.Activity?.type}</div>
                                                                    ) : (
                                                                        <div>Onsite</div>
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </div>)
                                                      })}





                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {/* <div id="export-cme-score-report-pdf">
        <ExportPdf
          yearlyScore={yearlyscore}
          summaryData={summarydata}
          scoregroupwise={scoregroupwise}
        />
      </div> */}
    </div>
  );
}

export default withTranslation()(MyMedx);
