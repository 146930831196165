import moment from "moment";
import { withTranslation } from "react-i18next";

const Header = (props) => {
  return (
    <>
      <div className="navbar navbar-expand-sm export-pdf-nav">
        <div className="container">
          <div className="d-flex align-items-center">
            <div className="me-4">
              <a className="navbar-brand" href="#">
                <img src={require("assets/images/logo.png")} width="120" />
              </a>
            </div>
            <div>
              <h2 className="pdf-head-title">CME Score Report</h2>
              <h6 className="pdf-head-date">Date : {moment().format("DD/MM/YYYY")}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="blue-strip">
        <img src={require("assets/images/blue-strip.png")} />
      </div>
    </>
  );
};

export default withTranslation()(Header);
