import React from 'react'
import config from "../../../config";
import { NavLink, Link, useParams } from 'react-router-dom';


function MobileHeaderArrowBanner(props) {
    return (
        <>
            {/* Mobile View Top Start */}
            <div className="mobile-detail-bg d-none">
                {props.image == null || props.image == undefined || props.image == "" ? (
                    <img
                        className="img-fluid"
                        style={{width:"50%"}}
                        src={config.BASE_URL + '/assets/images/no-image.png'}
                        alt=""
                    />
                ) : (
                    // <NavLink to={'/education/education-activity-details/' + activity?.ActivityMedia[0]?.file_url} >
                        <img
                            className="img-fluid"
                            src={props.image}
                            alt=""
                        />
                    // </NavLink>
                )}
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between mob-mt-39">
                            <div>
                                <a href={config.BASE_URL + props.back_page_url}>
                                    <img
                                        src={config.BASE_URL + '/assets/images/768/arrow-left.png'}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile View Top End */}

        </>

    );
}
export default MobileHeaderArrowBanner