import { BookingApi } from "apis/BookingApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getUser } from "../../Token";
import Footer from "../../components/Layout/Footer";
import Navbar from "../../components/Layout/Navbar";
import * as url from "../../helpers/common_helper";
import constants from "../../constants";
import { EducationApi } from "apis/EducationApi";
import i18n from "../../i18n";


function BookingDetail(props) {
  const user = getUser();
  const navigate = useNavigate();
  const params = useParams();
  const booking_id = atob(params.booking_id);
  const [activeData, setactiveData] = useState({ booking_id: booking_id });
  const [booking, setBooking] = useState([]);
  const [error, setError] = useState("");
  const [isCancelBooking, setIsCancelBooking] = useState(false);
  const [isNotTimeYet, setIsNotTimeYet] = useState(false);

  const [latitude, setLatitude] = useState(false);
  const [longitude, setLongitude] = useState(false);
  const [onlineMinAttendTime, setOnlineMinAttendTime] = useState("");
  const [getScoreButtonVisibleTime, setgetScoreButtonVisibleTime] =
    useState("");
  const [joinActivityButtonVisible, setjoinActivityButtonVisible] =
    useState("");

  const [check_in_out_status, setCheckInOutStatus] = useState(false);

  const [activityJoinBeforeMin, setActivityJoinBeforeMin] = useState(0);
  const [checkOutButtonVisible, setcheckOutButtonVisible] = useState(0);
  
  useEffect(() => {
    getBookingDetails(activeData);
    // componentDidMount();
  }, []);

  const getBookingDetails = (data) => {
    BookingApi.getBookingDetails(data)
      .then((res) => {
        if (res.data.success) {
          setBooking(res.data.data.booking);

          setActivityJoinBeforeMin(res.data.data.configdata
            .join_activity_before_minute);
          setcheckOutButtonVisible(res.data.data.configdata
                .check_out_button_visible_after_minute);

          const visibletimeminute =
            res.data.data.configdata
              .online_get_score_button_visible_minute_till;
          const joinActivityButtonVisibleMinute =
            res.data.data.configdata.online_join_activity_button_visible_minute;
          const onlineGetScoreButtonVisible =
            res.data.data.configdata.online_get_score_button_visible_percent;
          const joinActivityButtonVisible = moment(
            res.data.data.booking.Activity.start_time,
            "HH:mm:ss"
          )
            .add(joinActivityButtonVisibleMinute, "minutes")
            .format("HH:mm:ss");

          const startTime = moment(
            res.data.data.booking.Activity.start_time,
            "HH:mm:ss"
          );
          const endTime = moment(
            res.data.data.booking.Activity.end_time,
            "HH:mm:ss"
          );
          const duration = moment.duration(endTime.diff(startTime));
          const hours = parseInt(duration.asHours());
          const minutes = parseInt(duration.asMinutes()) % 60;
          const totalHourtoMinute = parseInt(hours * 60) + parseInt(minutes);
          const halftimeadd =
            (totalHourtoMinute / 100) * onlineGetScoreButtonVisible;
          const getScoreTimeMinute =
            parseInt(halftimeadd) + parseInt(visibletimeminute);
          const activityMinAttendTime = moment(
            res.data.data.booking.Activity.start_time,
            "HH:mm:ss"
          )
            .add(halftimeadd, "minutes")
            .format("HH:mm:ss");
          const getScoreVisibleTime = moment(
            res.data.data.booking.Activity.start_time,
            "HH:mm:ss"
          )
            .add(getScoreTimeMinute, "minutes")
            .format("HH:mm:ss");

          //console.log('50% time',activityMinAttendTime);
          //console.log('Valid Time',getScoreVisibleTime);
          setjoinActivityButtonVisible(joinActivityButtonVisible);
          setgetScoreButtonVisibleTime(getScoreVisibleTime);
          setOnlineMinAttendTime(activityMinAttendTime);
          setCheckInOutStatus(res.data.data.booking.check_in_out_status);
          if (res.data.data.booking.status == "CANCELLED") {
            setIsCancelBooking(true);
          }
          // console.log(url.dateFormat(res.data.data?.Activity?.activity_date));
          // console.log(
          //   moment(res.data.data?.Activity?.activity_date).format(
          //     "YYYY-MM-DD"
          //   ) == moment().format("YYYY-MM-DD")
          // );
          // console.log(moment().format("YYYY-MM-DD"));
        } else {
          setBooking([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCancelBooking = () => {
    BookingApi.cancelBookingAPI(activeData)
      .then((res) => {
        if (res.data.success) {
          setIsCancelBooking(true);
          window.$("#cancelbooking").modal("hide");
          navigate("/booking");
        } else {
          setIsCancelBooking(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const joinBookingActivity = (bookingID, type) => {
    setError("");
    const payload = {
      booking_id: bookingID,
      latitude: "",
      longitude: "",
      type: type,
    };
    BookingApi.bookedActivityJoin(payload)
      .then((res) => {
        if (res.data.success) {
            setError(res.data.message);
            window.$("#activityBookingExistAlready").modal("show");
            getBookingDetails(activeData);
        } else {
            setError(res.data.message);
            window.$("#activityBookingExistAlready").modal("show");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  

  const RedirectJoinOnlineLink = (bookingID, meetURL) => {
         var urlRedirect = meetURL.includes("?")
            ? meetURL + "&&type=joinactivity"
            : meetURL + "?type=joinactivity";
          var joinActivityObj = { type: "joinactivity", url: urlRedirect };
          window.parent.postMessage(JSON.stringify(joinActivityObj));
          window.open(
            meetURL,
            "_blank",
            "width=1600,height=1024,center=true,frame=false"
          );
  };


  const onJoinBookedActivityHandler = (bookingID, meetURL) => {
    setError("");

    const payload = {
      booking_id: bookingID,
      latitude: "",
      longitude: "",
      type: constants.TYPE.Online,
    };
    BookingApi.bookedActivityCheckIn(payload)
      .then((res) => {
        if (res.data.success) {
          setCheckInOutStatus(res.data?.success);
          setError(res.data.message);
          getBookingDetails(activeData);

          var urlRedirect = meetURL.includes("?")
            ? meetURL + "&&type=joinactivity"
            : meetURL + "?type=joinactivity";
          var joinActivityObj = { type: "joinactivity", url: urlRedirect };
          window.parent.postMessage(JSON.stringify(joinActivityObj));
          window.open(
            meetURL,
            "_blank",
            "width=1600,height=1024,center=true,frame=false"
          );
        } else {
          if (res.data?.itsNotTimeYet) {
            setIsNotTimeYet(true);
          }
          // if (
          //   moment().format("HH:mm:ss") <= joinActivityButtonVisible &&
          //   booking?.type === constants.TYPE.Online
          // ) {
          //   var joinActivityObj = { type: "joinactivity", url: meetURL };
          //   window.parent.postMessage(JSON.stringify(joinActivityObj));
          //   window.open(
          //     meetURL,
          //     "_blank",
          //     "width=1600,height=1024,center=true,frame=false"
          //   );
          // } else {
          //   //setError(props.t("itsNotTimeYet"));
          //   setError(res.data.message);
          //   window.$("#activityBookingExistAlready").modal("show");
          // }
          setError(res.data.message);
          window.$("#activityBookingExistAlready").modal("show");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSiteCheckInActivityHandler = (bookingID) => {
    setError("");
 
    const payload = {
      booking_id: bookingID,
      latitude: latitude,
      longitude: longitude,
      type: constants.TYPE.Onsite,
    };
    BookingApi.bookedActivityCheckIn(payload)
      .then((res) => {
        if (res.data.success) {
          setCheckInOutStatus(res.data?.success);
          setError(res.data.message);
          getBookingDetails(activeData);
          window.$("#activityBookingExistAlready").modal("show");
        } else {
          if (res.data?.alreadyCheckedIn) {
            setError(props.t("alreadyCheckedIn"));
          } else {
            setError(res.data.message);
          }
          window.$("#activityBookingExistAlready").modal("show");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //     setError("Oops! This browser does not support HTML Geolocation.");
    //     window.$("#activityBookingExistAlready").modal("show");
    // }
  };

  const onlineCheckOutActivityHandler = (bookingID) => {
    
    setError("");
    setIsCancelBooking(true);
    // if (navigator.geolocation) {
    // if(!latitude && !longitude) {
    //   setError('Please allow location');
    //   window.$("#activityBookingExistAlready").modal("show");
    //   return false;
    // }
    const payload = {
      booking_id: bookingID,
      latitude: latitude,
      longitude: longitude,
    };
    BookingApi.bookedActivityCheckOutOnline(payload)
      .then((res) => {
        if (res.data.success) {
          setCheckInOutStatus(res.data?.success);
          setError(res.data.message);
          getBookingDetails(activeData);
          window.$("#activityBookingExistAlready").modal("show");
        } else {
          setError(res.data.message);
          window.$("#activityBookingExistAlready").modal("show");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //     setError("Oops! This browser does not support HTML Geolocation.");
    //     window.$("#activityBookingExistAlready").modal("show");
    // }
  };



  const onSiteCheckOutActivityHandler = (bookingID) => {
    setError("");
    window.$("#checkOutConfirmAlert").modal("hide");
    // if (navigator.geolocation) {
    // if(!latitude && !longitude) {
    //   setError('Please allow location');
    //   window.$("#activityBookingExistAlready").modal("show");
    //   return false;
    // }
    const payload = {
      booking_id: bookingID,
      latitude: latitude,
      longitude: longitude,
    };
    BookingApi.bookedActivityCheckOutOnline(payload)
      .then((res) => {
        if (res.data.success) {
          setCheckInOutStatus(res.data?.success);
          setError(res.data.message);
          getBookingDetails(activeData);
          window.$("#activityBookingExistAlready").modal("show");
        } else {
          setError(res.data.message);
          window.$("#activityBookingExistAlready").modal("show");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //     setError("Oops! This browser does not support HTML Geolocation.");
    //     window.$("#activityBookingExistAlready").modal("show");
    // }
  };

  const rejoinBooking = (bookingId) => {
    const payload = { bookingID: parseInt(bookingId) };
    EducationApi.rejoinBooking(payload)
      .then((res) => {
        if (res.data.success) {
          window.location.href = "/booking";
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          //toastr.error(error?.response?.data?.message, '');
        } else {
          // setError(error?.response?.data?.error?.errors[0].msg);
        }
      });
  };

  return (
    <div>
      <Navbar />

      <section className="mt-5 d-m-none">
        <div className="container">
          <div className="row">
            <div className="col-md-12 list-style-none">
              <ul className="breadcrumb mt-3">
                <li>
                  <Link to="/profile">{props.t("Profile")}</Link>
                </li>
                <li>/</li>
                <li className="active"> {
                          i18n.language == 'en' && booking?.Activity?.title_en ? (
                            booking?.Activity?.title_en
                          ) : ( booking?.Activity?.title_th)
                          }</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <>
        <MobileHeaderArrowBanner back_page_url="education" />
      </> */}

      {/* <!-- Desktop Booking Start --> */}
      <section className="pt-3 pb-5 d-m-none">
        <div className="container">
          <div className="row p-20">
            <div className="review-bg">
              <div className="booking mt-4 mob-mt-0">
                <h3 className="title my-3 mob-mt-0">
                  {props.t("Review-Booking-Information")}
                </h3>
                <div className="row mb-4">
                  <div className="col-md-6 coupon">
                    <div className="d-flex">
                      <div
                        className={`block1 list-style-none ${
                          booking.status === "CANCELLED"
                            ? "bg-orange"
                            : booking?.Activity?.type == "Online"
                            ? "bg-green"
                            : ""
                        }`}
                      >
                        {/* <img
                          src="/assets/images/qr-code.png"
                          className=""
                          alt=""
                          width=""
                          height=""
                        /> */}
                        <span>{props.t("Date-And-Time")}</span>
                        <h4>
                          {url.dateFormat(booking?.Activity?.activity_date)}
                        </h4>
                        <h5>
                          {url.TimeFormat(booking?.Activity?.start_time)} -{" "}
                          {url.TimeFormat(booking?.Activity?.end_time)}
                        </h5>
                        <ul>
                          {/* <li>
                                                        <span>Seal</span>
                                                        <strong>23A</strong>
                                                    </li> */}
                          <li>
                            <span>{props.t("Price")}</span>
                            <strong>
                              {booking?.Activity?.fee_type == "FREE"
                                ? "Free"
                                : "฿ " + booking?.Activity?.fees}
                            </strong>
                          </li>
                        </ul>
                      </div>
                      <div className="block2">
                        {/* <a href=""> */}
                        <img
                          src={
                            booking?.Activity?.ActivityMedia[0]?.image_url
                              ? booking?.Activity?.ActivityMedia[0]?.image_url
                              : require("../../assets/images/default-activity.jpg")
                          }
                          className=""
                          alt=""
                          width=""
                          height=""
                        />
                        {/* </a> */}
                        {/* <a href=""> */}
                        <h4
                          className={`${
                            booking.status === "CANCELLED"
                              ? "text-orange"
                              : booking?.Activity?.type == "Online"
                              ? "text-green"
                              : ""
                          }`}
                        >
                          {
                          i18n.language == 'en' && booking?.Activity?.title_en ? (
                            booking?.Activity?.title_en
                          ) : ( booking?.Activity?.title_th)
                          }
                        </h4>
                        {/* </a> */}
                        <span
                          className={`text-center ${
                            booking.status === "CANCELLED"
                              ? "text-orange"
                              : booking?.Activity?.type == "Online"
                              ? "text-green"
                              : ""
                          }`}
                        >
                          {booking?.Activity?.type == constants.TYPE.Hybrid
                            ? "Online/Onsite"
                            : booking?.Activity?.type}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 c-details">
                    <div className="d-flex">
                      <div className="list-style-none">
                        <ul>
                          <li>
                            <span>{props.t("First-Name")}</span>
                            <strong>{booking?.User?.first_name}</strong>
                          </li>
                          <li>
                            <span>{props.t("Email")}</span>
                            <strong>
                              {booking?.User?.email
                                ? booking?.User?.email
                                : "-"}
                            </strong>
                          </li>
                        </ul>
                      </div>
                      <div className="list-style-none">
                        <ul>
                          <li>
                            <span>{props.t("Last-Name")}</span>
                            <strong>{booking?.User?.last_name}</strong>
                          </li>
                          <li>
                            <span>{props.t("Mobile-Phone-Number")}</span>
                            <strong>
                              {booking?.User?.mobile
                                ? booking?.User?.mobile
                                : "-"}
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {/* <h3 className="title my-3">Payment Method</h3>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="pblock">
                                            <img
                                                src="/assets/images/image 57.png"
                                                alt=""
                                                className=""
                                                width=""
                                                height=""
                                            />
                                            <div>
                                                <strong>Kittiya Yuthasastrkosol</strong>
                                                <span>Promptpay 0901234567</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="pblock">
                                            <img
                                                src="/assets/images/image 58.png"
                                                alt=""
                                                className=""
                                                width=""
                                                height=""
                                            />
                                            <div>
                                                <strong>Kittiya Yuthasastrkosol</strong>
                                                <span>SCB 5052565979</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                {/* {error != "" && (
                  <div className="alert alert-danger alert-dismissible">
                    {error}
                  </div>
                )} */}
              </div>
            </div>
            {booking?.status === "CANCELLED" &&
              moment(booking?.Activity?.activity_date).format("YYYY-MM-DD") >=
                moment().format("YYYY-MM-DD") && (
                <div className="col-md-12">
                  <div className="clearfix"></div>
                  <div className="mob-bg-white">
                    <button
                      type="button"
                      onClick={() => {
                        rejoinBooking(booking?.id);
                      }}
                      target="_blank"
                      className="btn btn-join bg-primary mt-4 ml-5"
                    >
                      {props.t("rejoining")}
                    </button>
                  </div>
                </div>
              )}
              {booking.status === "BOOKED" &&
              moment(booking?.Activity?.activity_date).format("YYYY-MM-DD") >=
                moment().format("YYYY-MM-DD") && (
                <div className="col-md-12">
                  <div className="clearfix"></div>
                  {(booking?.Activity?.type === constants.TYPE.Online ||
                    booking?.Activity?.type === constants.TYPE.Hybrid) && booking?.type !== constants.TYPE.Onsite &&
                    (!check_in_out_status ? (
                      booking?.type &&
                          booking?.type === constants.TYPE.Online ? (
                              <>
                                <div className="mob-bg-white">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      onJoinBookedActivityHandler(
                                        booking.id,
                                        booking?.Activity?.online_meeting_url
                                      );
                                    }}
                                    target="_blank"
                                    className="btn btn-join bg-primary mt-4 ml-5"
                                  >
                                  {props.t("Join") + " " + props.t("Activity")}
                                  </button>
                                  {}
                                </div>
                              </>
                          ) : (
                              <>
                                <div className="mob-bg-white">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      joinBookingActivity(booking.id,constants.TYPE.Online);
                                    }}
                                    target="_blank"
                                    className="btn btn-join bg-primary mt-4 ml-5"
                                  >
                                    {props.t("Join") + " " + props.t("Online")}
                                  </button>
                                  {}
                                </div>
                              </>
                          )
                      ) : booking?.type === constants.TYPE.Online &&
                        check_in_out_status == "CHECK-IN" &&
                        moment().format("HH:mm:ss") >= onlineMinAttendTime &&
                        moment().format("HH:mm:ss") <=
                          getScoreButtonVisibleTime ? (
                        <div className="mob-bg-white">
                          <button
                            type="button"
                            onClick={() => {
                              onlineCheckOutActivityHandler(booking.id);
                            }}
                            target="_blank"
                            className="btn btn-join bg-primary mt-4 ml-5"
                          >
                            {props.t("Get-Score")}
                          </button>
                          {}
                        </div>
                      ) : (

                        booking?.type === constants.TYPE.Online &&
                        check_in_out_status == "CHECK-IN"  ? (
                            <>
                            <div className="mob-bg-white">
                              <button
                                type="button"
                                onClick={() => {
                                  RedirectJoinOnlineLink(
                                    booking.id,
                                    booking?.Activity?.online_meeting_url
                                  );
                                }}
                                target="_blank"
                                className="btn btn-join bg-primary mt-4 ml-5"
                              >
                              {props.t("Join") + " " + props.t("Activity")}
                              </button>
                              {}
                            </div>
                            </>
                          ) : (
                            <></>
                          )
                      ))}
               
                  {(booking?.Activity?.type === constants.TYPE.Onsite ||
                    booking?.Activity?.type === constants.TYPE.Hybrid) && booking?.type !== constants.TYPE.Online &&
                     (!check_in_out_status ? (
                            booking?.type &&
                            booking?.type === constants.TYPE.Onsite ? (
                              <>
                                <div className="mob-bg-white">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        onSiteCheckInActivityHandler(booking.id);
                                      }}
                                      target="_blank"
                                      className="btn btn-join bg-primary mt-4 ml-5"
                                    >
                                    {props.t("Check-In")}
                                    </button>
                                </div>
                              </>
                      ) : (
                          <>
                            <div className="mob-bg-white">
                                <button
                                  type="button"
                                  onClick={() => {
                                    joinBookingActivity(booking.id,constants.TYPE.Onsite);
                                  }}
                                  target="_blank"
                                  className="btn btn-join bg-primary mt-4 ml-5"
                                >
                                {props.t("Join") + " " + props.t("Offline")}
                                </button>
                            </div>
                          </>
                      )
                    ) : booking?.type === constants.TYPE.Onsite &&
                      check_in_out_status == "CHECK-IN" &&  moment().format("HH:mm:ss")  >= moment(`${booking?.Activity?.activity_date} ${booking?.Activity?.start_time}`).add(checkOutButtonVisible, "minutes").format("HH:mm:ss")
                       ? (
                      <div className="mob-bg-white">
                        <button
                          type="button"
                          onClick={() => {
                            window.$("#checkOutConfirmAlert").modal("show");
                            //onSiteCheckOutActivityHandler(booking.id);
                          }}
                          target="_blank"
                          className="btn btn-join bg-primary mt-4 ml-5"
                        >
                          {props.t("Check-Out")}
                        </button>
                      </div>
                    ) : (
                      <></>
                    ))}

                  <div className="mob-bg-white">
                    {!isCancelBooking && !check_in_out_status ? (
                      <button
                        className="btn btn-join bg-secondary mt-4"
                        data-bs-target="#cancelbooking"
                        data-bs-toggle="modal"
                      >
                        {props.t("Cancel-Booking")}
                      </button>
                    ) : (
                      <></>
                      // <button
                      //   disabled
                      //   className="btn btn-join bg-secondary mt-4"
                      //   data-bs-toggle="modal"
                      // >
                      //   Booking Cancelled
                      // </button>
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
      </section>

      {/* Mobile View Top Start */}
      {/* {`block1 ${booking.status === "CANCELLED"
        ? "bg-orange"
        : booking?.Activity?.type == "Online"
          ? "bg-green"
          : ""
        }`} */}

      <section className="bg-blue2 mob-booking d-none">
        <div className="container">
          <div className="row">
            <div
              style={{ height: "100%" }}
              className={`col-md-12 ${
                booking.status === "CANCELLED"
                  ? "bg-red"
                  : booking?.Activity?.type == "Online"
                  ? "bg-green2 green"
                  : ""
              }`}
            >
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                  <Link to="/booking">
                    <img
                      src="/assets/images/768/arrow-left-w.png"
                      className=""
                      alt=""
                      width=""
                      height=""
                    />
                  </Link>
                </div>
                <div>
                  <h4 className="mb-0 text-white">
                    {props.t("Booking-Detail")}
                  </h4>
                </div>
                <div></div>
              </div>
              <div
                className={`m-coupon mobBookingDetailHeight ${
                  booking.status === "CANCELLED"
                    ? "red"
                    : booking?.Activity?.type == "Online"
                    ? "green"
                    : ""
                }`}
              >
                {/* <div className="text-center mh-300">
                  <div className="qr">
                    <img
                      src="/assets/images/768/qrcode.png"
                      className="opacity-2 mb-2"
                      alt=""
                      width=""
                      height=""
                    />
                    {booking.status === "CANCELLED" ? (
                      <div
                        className={`overlay-text ${
                          booking.status === "CANCELLED"
                            ? "text-red"
                            : booking?.Activity?.type == "Online"
                            ? "text-green"
                            : ""
                        }`}
                      >
                        {props.t("Cancelled")}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <p className="opacity-2">
                  {props.t("Dont-Share-QR-Msg")}
                  </p>
                </div> */}
                <div className="m-coupon-data">
                  <div>
                    <a href="">
                      <img
                        src={
                          booking?.Activity?.ActivityMedia[0]?.image_url
                            ? booking?.Activity?.ActivityMedia[0]?.image_url
                            : require("../../assets/images/no-image.png")
                        }
                        className="rounded"
                        width="97"
                        height="97"
                        alt=""
                      />
                    </a>
                  </div>
                  <div>
                    <a href="">
                      <h4 className="mb-0"> {
                          i18n.language == 'en' && booking?.Activity?.title_en ? (
                            booking?.Activity?.title_en
                          ) : ( booking?.Activity?.title_th)
                          }</h4>
                    </a>
                    <span className="text-center">
                      <img
                        src="/assets/images/location-marker.png"
                        className=""
                        width=""
                        height=""
                        alt=""
                      />
                      {booking?.Activity?.type === constants.TYPE.Hybrid
                        ? "Online/Onsite"
                        : booking?.Activity?.type}
                    </span>
                  </div>
                </div>
                <div className="mt-3 mb-4 m-data">
                  <div className="d-flex">
                    <div className="w-130">
                      <span>{props.t("Date")}</span>
                      <h4>
                        {url.dateFormat(booking?.Activity?.activity_date)}
                      </h4>
                    </div>
                    <div>
                      <span>{props.t("Time")}</span>
                      <h4>
                        {url.TimeFormat(booking?.Activity?.start_time)} -{" "}
                        {url.TimeFormat(booking?.Activity?.end_time)}
                      </h4>
                    </div>
                  </div>
                  <div className="d-flex">
                    {/* <div className="w-130">
                      <span>Seal</span>
                      <h4>23A</h4>
                    </div> */}
                    <div>
                      <span>{props.t("Price")}</span>
                      <h4>
                        {booking?.Activity?.fee_type == "FREE"
                          ? "Free"
                          : "฿ " + booking?.Activity?.fees}
                      </h4>
                    </div>
                  </div>
                  {/* {error != "" && (
                    <div className="alert alert-danger alert-dismissible">
                      {error}
                    </div>
                  )} */}

                  {/* <div className="mb-3 m-btn">
                    <a
                      href={`${
                        booking?.Activity?.online_meeting_url
                          ? booking?.Activity?.online_meeting_url
                          : ""
                      }`}
                      target="_blank"
                      className="btn btn-join2 bg-primary"
                    >
                      {props.t("Join")} {" "} {props.t("Activity")}
                    </a>
                    <a
                      href=""
                      className="btn btn-join2 bg-secondary"
                      data-bs-target="#cancelbooking"
                      data-bs-toggle="modal"
                    >
                      Cancel Booking
                    </a>
                  </div> */}
                </div>
                {booking?.status === "CANCELLED" &&
                  moment(booking?.Activity?.activity_date).format("YYYY-MM-DD") >=
                    moment().format("YYYY-MM-DD") && (
                    <div className="col-md-12">
                      <div className="clearfix"></div>
                      <div className="mb-3 m-btn">
                        <button
                          type="button"
                          onClick={() => {
                            rejoinBooking(booking?.id);
                          }}
                          target="_blank"
                          className="btn btn-join bg-primary mt-4 ml-5"
                        >
                          {props.t("rejoining")}
                        </button>
                      </div>
                    </div>
                  )}

              {booking.status === "BOOKED" &&
              moment(booking?.Activity?.activity_date).format("YYYY-MM-DD") >=
                moment().format("YYYY-MM-DD") && (
                <div className="col-md-12">
                  <div className="clearfix"></div>
                  {(booking?.Activity?.type === constants.TYPE.Online ||
                    booking?.Activity?.type === constants.TYPE.Hybrid) && booking?.type !== constants.TYPE.Onsite &&
                    (!check_in_out_status ? (
                      booking?.type &&
                          booking?.type === constants.TYPE.Online ? (
                              <>
                                <div className="mob-bg-white">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      onJoinBookedActivityHandler(
                                        booking.id,
                                        booking?.Activity?.online_meeting_url
                                      );
                                    }}
                                    target="_blank"
                                    className="btn btn-join bg-primary mt-4 ml-5"
                                  >
                                  {props.t("Join") + " " + props.t("Activity")}
                                  </button>
                                  {}
                                </div>
                              </>
                          ) : (
                              <>
                                <div className="mob-bg-white">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      joinBookingActivity(booking.id,constants.TYPE.Online);
                                    }}
                                    target="_blank"
                                    className="btn btn-join bg-primary mt-4 ml-5"
                                  >
                                    {props.t("Join") + " " + props.t("Online")}
                                  </button>
                                  {}
                                </div>
                              </>
                          )
                      ) : booking?.type === constants.TYPE.Online &&
                        check_in_out_status == "CHECK-IN" &&
                        moment().format("HH:mm:ss") >= onlineMinAttendTime &&
                        moment().format("HH:mm:ss") <=
                          getScoreButtonVisibleTime ? (
                        <div className="mob-bg-white">
                          <button
                            type="button"
                            onClick={() => {
                              onlineCheckOutActivityHandler(booking.id);
                            }}
                            target="_blank"
                            className="btn btn-join bg-primary mt-4 ml-5"
                          >
                            {props.t("Get-Score")}
                          </button>
                          {}
                        </div>
                      ) : (

                        booking?.type === constants.TYPE.Online &&
                        check_in_out_status == "CHECK-IN"  ? (
                            <>
                            <div className="mob-bg-white">
                              <button
                                type="button"
                                onClick={() => {
                                  RedirectJoinOnlineLink(
                                    booking.id,
                                    booking?.Activity?.online_meeting_url
                                  );
                                }}
                                target="_blank"
                                className="btn btn-join bg-primary mt-4 ml-5"
                              >
                              {props.t("Join") + " " + props.t("Activity")}
                              </button>
                              {}
                            </div>
                            </>
                          ) : (
                            <></>
                          )
                      ))}
               
                  {(booking?.Activity?.type === constants.TYPE.Onsite ||
                    booking?.Activity?.type === constants.TYPE.Hybrid) && booking?.type !== constants.TYPE.Online &&
                     (!check_in_out_status ? (
                            booking?.type &&
                            booking?.type === constants.TYPE.Onsite ? (
                              <>
                                <div className="mob-bg-white">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        onSiteCheckInActivityHandler(booking.id);
                                      }}
                                      target="_blank"
                                      className="btn btn-join bg-primary mt-4 ml-5"
                                    >
                                    {props.t("Check-In")}
                                    </button>
                                </div>
                              </>
                      ) : (
                          <>
                            <div className="mob-bg-white">
                                <button
                                  type="button"
                                  onClick={() => {
                                    joinBookingActivity(booking.id,constants.TYPE.Onsite);
                                  }}
                                  target="_blank"
                                  className="btn btn-join bg-primary mt-4 ml-5"
                                >
                                {props.t("Join") + " " + props.t("Offline")}
                                </button>
                            </div>
                          </>
                      )
                    ) : booking?.type === constants.TYPE.Onsite &&
                      check_in_out_status == "CHECK-IN" &&  moment().format("HH:mm:ss")  >= moment(`${booking?.Activity?.activity_date} ${booking?.Activity?.start_time}`).add(checkOutButtonVisible, "minutes").format("HH:mm:ss")
                       ? (
                      <div className="mob-bg-white">
                        <button
                          type="button"
                          onClick={() => {
                            window.$("#checkOutConfirmAlert").modal("show");
                            //onSiteCheckOutActivityHandler(booking.id);
                          }}
                          target="_blank"
                          className="btn btn-join bg-primary mt-4 ml-5"
                        >
                          {props.t("Check-Out")}
                        </button>
                      </div>
                    ) : (
                      <></>
                    ))}

                  <div className="mob-bg-white">
                    {!isCancelBooking && !check_in_out_status ? (
                      <button
                        className="btn btn-join bg-secondary mt-4"
                        data-bs-target="#cancelbooking"
                        data-bs-toggle="modal"
                      >
                        {props.t("Cancel-Booking")}
                      </button>
                    ) : (
                      <></>
                      // <button
                      //   disabled
                      //   className="btn btn-join bg-secondary mt-4"
                      //   data-bs-toggle="modal"
                      // >
                      //   Booking Cancelled
                      // </button>
                    )}
                  </div>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile View Top End */}
      <div className="modal fade" id="cancelbooking" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-xs">
          <div className="modal-content">
            <div className="modal-body">
              <div className="c-content">
                <div>
                  <img
                    src={require("../../assets/images/cancel.png")}
                    alt=""
                    width="40"
                    height="40"
                  />
                </div>
                <div className="p-l-20">
                  <h5> {props.t("Cancel-Booking")}</h5>
                  <p>{props.t("Cancel-Booking-Msg")}</p>
                </div>
              </div>
              <div className="d-flex pt-4 cancel-btn-list">
                <button
                  type="button"
                  className="float-right btn btn-cancel-d btn-blue"
                  onClick={() => {
                    window.$("#cancelbooking").modal("hide");
                  }}
                >
                  {props.t("Cancel")}
                </button>
                <button
                  type="button"
                  className="float-right btn btn-cancel"
                  style={{ marginLeft: "5px" }}
                  onClick={onCancelBooking}
                >
                  {props.t("Cancel-Booking-Btn")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="activityBookingExistAlready"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered modal-xs">
          <div className="modal-content">
            <div className="modal-body">
              <div className="c-content">
                <div className="row">
                  <div className="col-md-2">
                    <img
                      src={
                        isNotTimeYet
                          ? require("../../assets/images/icon_nottime.png")
                          : require("../../assets/images/icon_already_checkin.png")
                      }
                      alt=""
                      width="50"
                      height="50"
                    />
                  </div>

                  <div className="col-md-10">
                    <h5>Alert</h5>
                    <p>{error}</p>
                  </div>
                </div>
              </div>
              <div className="d-flex pt-4 cancel-btn-list">
                <button
                  type="button"
                  className="float-right btn btn-cancel"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    //window.location.reload();
                    window.$("#activityBookingExistAlready").modal("hide");
                  }}
                >
                  {props.t("Okay")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      
      <div
        className="modal fade"
        id="checkOutConfirmAlert"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered modal-xs">
          <div className="modal-content">
            <div className="modal-body">
              <div className="c-content">
                <div className="row">
                  <div className="col-md-2">
                    <img
                      src={
                        isNotTimeYet
                          ? require("../../assets/images/icon_nottime.png")
                          : require("../../assets/images/icon_already_checkin.png")
                      }
                      alt=""
                      width="50"
                      height="50"
                    />
                  </div>

                  <div className="col-md-10">
                    <h5>Alert</h5>
                    <p>On checkout you cannot check in to this activity again. Are you sure you want to checkout this activity?</p>

                  </div>
                </div>
              </div>
              <div className="d-flex pt-4 cancel-btn-list">
                <button
                  type="button"
                  className="float-right btn btn-success"
                  style={{ marginLeft: "5px",borderRadius: "6px",width:"20%" }}
                  onClick={() => {
                    onSiteCheckOutActivityHandler(booking.id);
                  }}
                >
                  {props.t("Yes")}
                </button>

                <button
                  type="button"
                  className="float-right btn btn-danger"
                  style={{ marginLeft: "5px",borderRadius: "6px" }}
                  onClick={() => {
                    window.$("#checkOutConfirmAlert").modal("hide");
                  }}
                  >
                  {props.t("No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <!-- Booking End --> */}

      {/* <!-- Footer Start --> */}
      <div className="hideFooter">
        <Footer />
      </div>
      {/* <!-- Footer End --> */}
    </div>
  );
}

export default withTranslation()(BookingDetail);
