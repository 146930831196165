
import {  useEffect } from "react"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { isLoggedIn } from "../../Token";


const NonLoginRoute= ({ guest }) => {


    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn()) { 
            navigate('/')
        }
      

    }, [isLoggedIn(), guest])


    if (!isLoggedIn()) {
        return <Outlet />
    }

 
    return (
        <div className="flex flex-1 justify-center items-center flex-col h-screen">
            <h2 className="text-3xl font-bold text-white bg-black rounded-lg px-5 py-1">UnAuthorized</h2>
            <Link to="/login" className="text-md text-blue-500 cursor-pointer mt-5"> Go To Login Page </Link>
        </div>
    )
}

export default NonLoginRoute