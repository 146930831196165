import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Navbar from "../../components/Layout/Navbar";
import config from "../../config";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import { withTranslation } from "react-i18next";
import { ProfileAPI } from "apis/ProfileAPI";
import ActivityCard from "../Components/ActivityCard";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const MyActivity = (props) => {
  const [activityActiveData, setActivityActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
    search: "",
  });
  const [myActivities, setMyActivities] = useState([]);
  const [searchActive, setSearchActive] = useState("");

  useEffect(() => {
    getMyActivities(activityActiveData, false);
  }, [activityActiveData.search]);

  useEffect(() => {
    setSearchActive(activityActiveData.search);
  }, []);

  const getMyActivities = (data, isPushData) => {
    ProfileAPI.getMyActivitiesAPI(data)
      .then((res) => {
        if (res.data.success) {
          if (isPushData) {
            setMyActivities([...myActivities, ...res.data.activities.rows]);
          } else {
            setMyActivities(res.data.activities.rows);
          }
        } else {
          setMyActivities([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleChangeSearch(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      setActivityActiveData({
        page: 1,
        limit: config.LIMIT,
        search: event.target.value,
      });
    }
  }

  window.onscroll = async function (ev) {
    let pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    if (window.innerHeight + window.scrollY >= pageHeight) {
      const data = {
        page: activityActiveData.page + 1,
        limit: config.LIMIT,
        search: searchActive,
      };
      setActivityActiveData(data);
      getMyActivities(data, true);
    }
  };

  return (
    <div>
      {/* <!-- Navbar Start --> */}
      <Navbar />
      {/* <!-- Navbar End --> */}
      <ScrollToTopOnMount />
      {/* <!-- Mobile View Top Start --> */}
      <div className="mobile-gray-top d-none">
        <div className="container">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between mob-mt-39">
              <div>
                <Link to="/profile">
                  <img src="assets/images/768/arrow-left.png" alt="" />
                </Link>
              </div>
              <div>
                <h4 className="mb-0">{props.t("My-Activity")}</h4>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Mobile View Top End --> */}

      <section className="mt-5  d-m-none">
        <div className="container">
          <div className="row p-8">
            <div className="col-md-12">
              <h1 className="mt-5  mb-3 main-title">{props.t("Profile")}</h1>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Booking Start --> */}
      <section className="dashboard top-curve2 mob-brd-radius-0">
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />
            <div className="col-md-10">
              <div className="dashboard-inner">
                <div className="col-md-12">
                  <div className="dashboard-inner">
                    <div className="row d-m-none">
                      <div className="col-md-9">
                        <h2 className="mb-3">{props.t("Activity")}</h2>
                      </div>
                      <div className="col-md-3">
                        <Link to="/activity-form" className="mb-3 btn btn-join">
                          {props.t("createActivity")}
                        </Link>
                      </div>
                    </div>

                    <div className="search-box mb-2">
                      <form action="">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-group">
                              <span className="input-group-text">
                                <img
                                  src="/assets/images/search.png"
                                  alt="search-icon"
                                  width="15"
                                  height="15"
                                />
                              </span>
                              <input
                                type="text"
                                className="form-control text-dark"
                                placeholder={props.t("Search")}
                                onKeyDown={handleChangeSearch}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="library-list list-style-none">
                      <ul className="first">
                        {myActivities.length > 0
                          ? myActivities.map((activity, i) => {
                              return <ActivityCard key={`myActivity__${i}`} activity={activity} />;
                            })
                          : "No record found"}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 d-none">
                    <Link to="/activity-form" className="btn btn-join">
                      {props.t("createActivity")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Booking End --> */}

      {/* <!-- Footer Start --> */}
      <div className="d-m-none">
        <Footer />
      </div>
      {/* <!-- Footer End --> */}
    </div>
  );
};

export default withTranslation()(MyActivity);
