import React, { useEffect, useState } from "react";
import { NavLink, Link } from 'react-router-dom';
import config, { BASE_URL } from "../../config";
import { withTranslation } from "react-i18next";
// import AdvertiseImg from "../../assets/images/advertisement/image-3.jpeg";
import Carousel from 'react-bootstrap/Carousel';
import { getRoleID, isRestricted, isFda } from "../../Token";


function Advertise({ banner, bannersCount, name, sliderInterval }) {
    return (
        <section className={`restricted_${isRestricted()} fda_${isFda()}`}>
            {bannersCount > 1 ?
                <>
                    <div className="container p-0 carousel-p">
                        <div className="row">
                            <Carousel>
                                {banner.map((item, i) => {
                                    return (
                                        <Carousel.Item interval={sliderInterval} key={i}>
                                            <a  href={item.banner_link.includes('?') ? `${item.banner_link}&&type=banner` : `${item.banner_link}?type=banner`} title={item.title_en + ' - ' + item.banner_link} target="_blank" 
                                            onClick={() => {
                                                window.parent.postMessage(JSON.stringify({type:"banner",url:item.banner_link}));
                                              }}>
                                                <img
                                                    className="w-100 d-m-none"
                                                    src={item.image_url}
                                                    alt="Image"
                                                />
                                                <img
                                                    className="w-100 d-none"
                                                    src={item.image_url}
                                                    alt="Image"
                                                />
                                            </a>
                                        </Carousel.Item>
                                    );
                                })}
                            </Carousel>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="container p-0 carousel-p">
                        <div className="row">
                            {banner.map((item, i) => {
                                return (
                                    <a href={item.banner_link.includes('?') ? `${item.banner_link}&&type=banner` : `${item.banner_link}?type=banner`} title={item.title_en + ' - ' + item.banner_link} target="_blank" key={"banner_" + i} 
                                    onClick={() => {
                                        window.parent.postMessage(JSON.stringify({type:"banner",url:item.banner_link}));
                                      }}>
                                        <img
                                            className="w-100"
                                            src={item.image_url}
                                            alt="Image"
                                        />
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </>
            }
        </section>
    );
}

export default withTranslation()(Advertise)