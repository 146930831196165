import usFlag from "../../assets/images/flags/us.jpg"
import thFlag from "../../assets/images/flags/th.png"

const languages = {
  th: {
    label: "ไทย",
    flag: thFlag,
  },
  en: {
    label: "English",
    flag: usFlag,
  }
  
}

export default languages