import { ProfileAPI } from "apis/ProfileAPI";
import { useFormik } from "formik";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import toastr from "toastr";
import * as Yup from "yup";

const InternshipTaskEvaluatorForm = (props) => {
  const [taskEvaluators, setTaskEvaluators] = useState();
  const [selectedEvaluator, setSelectedEvaluator] = useState();
  const [selectedInternshiptask, setSelectedInternshiptask] = useState();
  const [selectedMsfUsers, setSelectedMsfUsers] = useState();
  const [errorMsg, setErrorMsg] = useState("");

  const [customValidation, setCustomValidation] = useState({
    internshipTaskTypeId: Yup.string().required(props.t("Required-Message")),
    evaluatorId: Yup.string().required(props.t("Required-Message")),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    initialValues: {
      type: "doctor",
      internshipTaskTypeId: "",
      evaluatorId: "",
      msgUsers: "",
    },
    validationSchema: Yup.object().shape({
      ...customValidation,
    }),
    onSubmit: (values) => {
      if (values.type === "doctor") {
        values.internshipSessionId =
          selectedInternshiptask.internship_session_id;
      } else {
        if (values.msgUsers.length >= 3) {
          values.instituteId = selectedMsfUsers[0].institute_id;
          values.internshipSessionId = props.internshipSessionId;
        } else {
          setErrorMsg(props.t("selectAtleastLeastThreeMSF"));
          return false;
        }
      }
      ProfileAPI.submitTaskEvaluationToEvaluators(values)
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              text: "Task Evaluation Submitted",
              icon: "success",
              imageAlt: "success image",
            }).then((result) => {
              window.location.reload();
            });
          } else {
            toastr.error(res?.data?.message, "");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "");
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            );
          }
        });
    },
  });

  const onTaskGetEvaluatorsListHandler = async (data) => {
    const payload = {
      sessionId: data.internship_session_id,
      sessionTasktypeId: data.value,
    };
    ProfileAPI.getInternshipTaskEvaluatorsApi(payload)
      .then((res) => {
        if (res.data.success) {
          setTaskEvaluators(res.data.data.rows);
        } else {
          setTaskEvaluators([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeValidationHandler = (value) => {
    if (value === "msf") {
      setCustomValidation({
        msgUsers: Yup.array().required(props.t("Required-Message")),
      });
    } else {
      setCustomValidation({
        internshipTaskTypeId: Yup.string().required(
          props.t("Required-Message")
        ),
        evaluatorId: Yup.string().required(props.t("Required-Message")),
      });
    }
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div
          className="dashboard-inner application-form"
          style={{ minHeight: "auto" }}
        >
          <div className="row">
            <div className="col-md-6">
              <h2 className="mb-3">{props.t("taskEvaluation")}</h2>
            </div>
            <div className="col-md-6">
              <div className="justify-content-between mb-5 align-items-center">
                <div className="float-right">
                  <Link
                    to={`/checkresults`}
                    state={{
                      internshipSessionId: props.internshipSessionId,
                      activityId: null,
                      type: ["MSF", "TEACHER", "HOD"],
                      resultFor: "intern",
                    }}
                    className="btn btn-join"
                  >
                    {props.t("checkResult")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {props.msfList.length > 0 && (
            <>
              <div className="form-group mb-4">
                <div className="row">
                  <div className="col-md-6 col-xs-12 col-12">
                    <input
                      type="radio"
                      id="type"
                      name="type"
                      value="doctor"
                      onChange={(e) => {
                        formik.setFieldValue("type", e.target.value);
                        onChangeValidationHandler(e.target.value);
                      }}
                      checked={formik.values.type == "doctor" ? true : false}
                    />
                    &nbsp; Doctor Evaluations
                  </div>
                  <div className="col-md-6 col-xs-12 col-12">
                    <input
                      type="radio"
                      id="type"
                      name="type"
                      value="msf"
                      onChange={(e) => {
                        formik.setFieldValue("type", e.target.value);
                        onChangeValidationHandler(e.target.value);
                      }}
                      selected={formik.values.type == "msf" ? true : false}
                    />
                    &nbsp; MSF Evaluations
                  </div>
                </div>
              </div>
            </>
          )}
          {formik.values.type == "doctor" ? (
            <>
              <div className="form-group mb-4">
                <label className="form-label">
                  {props.t("selectInternshipTask")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <Select
                  onChange={(e) => {
                    formik.setFieldValue("internshipTaskTypeId", e?.value);
                    setSelectedInternshiptask(e);
                    onTaskGetEvaluatorsListHandler(e);
                  }}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      borderRadius: "50px",
                    }),
                  }}
                  value={selectedInternshiptask || ""}
                  name="internshipTaskTypeId"
                  className={
                    formik.touched.internshipTaskTypeId &&
                    formik.errors.internshipTaskTypeId
                      ? " bor"
                      : " text-dark"
                  }
                  options={props.internshipTasks}
                />
                {formik.touched.internshipTaskTypeId &&
                  formik.errors.internshipTaskTypeId && (
                    <div
                      style={{
                        color: "#f46a6a",
                        fontSize: "11px",
                        marginTop: "3px",
                      }}
                    >
                      {formik.errors.internshipTaskTypeId}
                    </div>
                  )}
              </div>
              <div className="form-group mb-4">
                <label className="form-label">
                  {props.t("selectEvaluator")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <Select
                  onChange={(e) => {
                    formik.setFieldValue("evaluatorId", e?.value);
                    setSelectedEvaluator(e);
                  }}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      borderRadius: "50px",
                    }),
                  }}
                  value={selectedEvaluator || ""}
                  name="evaluatorId"
                  className={
                    formik.touched.evaluatorId && formik.errors.evaluatorId
                      ? " bor"
                      : " text-dark"
                  }
                  options={taskEvaluators}
                />
                {formik.touched.evaluatorId && formik.errors.evaluatorId && (
                  <div
                    style={{
                      color: "#f46a6a",
                      fontSize: "11px",
                      marginTop: "3px",
                    }}
                  >
                    {formik.errors.evaluatorId}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="form-group mb-4">
                <label className="form-label">
                  {props.t("selectMSF")} <span className="text-danger">*</span>
                </label>
                <Select
                  onChange={(e) => {
                    const f = e.map((item, index) => {
                      return item.value;
                    });
                    formik.setFieldValue("msgUsers", f);
                    setSelectedMsfUsers(e);
                  }}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      borderRadius: "50px",
                    }),
                  }}
                  // value={selectedEvaluator || ""}
                  value={selectedMsfUsers}
                  name="msgUsers"
                  isMulti={true}
                  className={
                    formik.touched.msgUsers && formik.errors.msgUsers
                      ? " bor"
                      : " text-dark"
                  }
                  options={props.msfList}
                />
                {formik.touched.msgUsers && formik.errors.msgUsers && (
                  <div
                    style={{
                      color: "#f46a6a",
                      fontSize: "11px",
                      marginTop: "3px",
                    }}
                  >
                    {formik.errors.msgUsers}
                  </div>
                )}
                {errorMsg && (
                  <div
                    style={{
                      color: "#f46a6a",
                      fontSize: "11px",
                      marginTop: "3px",
                    }}
                  >
                    {errorMsg}
                  </div>
                )}
              </div>
            </>
          )}
          <div className="justify-content-between align-items-center mt-5 mob-mt-2">
            <div className="float-right">
              <button type="submit" className="btn btn-join">
                {props.t("Submit")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withTranslation()(InternshipTaskEvaluatorForm);
