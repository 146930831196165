import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import config from "config";
import * as Yup from "yup";
import Navbar from "components/Layout/Navbar";
import Footer from "components/Layout/Footer";
import Select from "react-select";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import { withTranslation } from "react-i18next";
import { Input } from "reactstrap";
import constants from "../../constants";
import Swal from "sweetalert2";
import { getRoleID, getToken } from "../../Token";
import { LeaveAPI } from "../../apis/LeaveAPI";
import MobPageTitleBar from "pages/Components/MobPageTitleBar";
import ProfileTitle from "pages/Components/ProfileTitle";
import _ from "lodash";
import moment from "moment";

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};

const ApplyLeave = (props) => {

  const [reasonTypes, setReasonTypes] = useState([]);

  const fetchReasonTypes = () => {
    LeaveAPI.fetchReasonTypes()
      .then((res) => {
        if (res.data.success) {
          setReasonTypes(res.data.data);
        } else {
          setReasonTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchReasonTypes();
  }, []);

  const formik = useFormik({
    initialValues: {
      // reason_type_id: '',
      // type: '',
      subject: '',
      from_date: '',
      to_date: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      // reason_type_id: Yup.string().required(
      //   props.t("Required-Message")
      // ),
      // type: Yup.string().required(
      //   props.t("Required-Message")
      // ),
      subject: Yup.string().required(
        props.t("Required-Message")
      ),
      from_date: Yup.string().required(
        props.t("Required-Message")
      ),
      to_date: Yup.string().required(
        props.t("Required-Message")
      ),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      // data.append("reason_type_id", values.reason_type_id);
      // data.append("type", values.type);
      data.append("subject", values.subject);
      data.append("from_date", values.from_date);
      data.append("to_date", values.to_date);
      data.append("description", values.description);

      LeaveAPI.createLeave(data)
        .then((res) => {
          if (res.data.success) {
            formClear();
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then((result) => {
              window.location.href = "/my-leaves";
            });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
          } else {
            err.response.data.error.errors?.map((erritem, errindex) => {});
          }
        });
    },
  });

  const formClear = () => {
    formik.resetForm({ values: { attachments: null, activity_image: null } });
  };

  return (
    <div>
      <Navbar />
      <ScrollToTopOnMount />
      <MobPageTitleBar
        isBackShow={true}
        title={`${props.t("Submit")} ${props.t("Leave")}`}
        mobBackUrl="/profile"
      />
      <ProfileTitle title={props.t("Profile")} />

      <section className="dashboard top-curve2 mob-brd-radius-0">
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />
            <div className={"col-md-10"}>
              <div className="row">
                <div className="col-md-12">
                  <h2 className="mb-3 d-m-none">
                    {props.t("Apply")} {props.t("Leave")}
                  </h2>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <div className="dashboard-inner application-form">
                  
                  {/* <div className="form-group mb-4">
                    <label className="form-label">
                      {props.t("Leave-Reason")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      onChange={(e) => {
                        formik.setFieldValue("reason_type_id", e?.value);
                      }}
                      onBlur={formik.handleBlur}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderRadius: "50px",
                        }),
                      }}
                      name="reason_type_id"
                      className={
                        formik.touched.reason_type_id &&
                        formik.errors.reason_type_id
                          ? " bor"
                          : " text-dark"
                      }
                      options={reasonTypes.map((value) => ({
                        value: `${value.id}`,
                        label: `${value.name_en}`,
                      }))}
                    />
                    {formik.touched.reason_type_id &&
                      formik.errors.reason_type_id && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.reason_type_id}
                        </div>
                      )}
                  </div> */}

                  <div className="form-group mb-4">
                    <label className="form-label">
                      {props.t("Leave-Reason")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      rows={5}
                      className={
                        formik.touched.subject && formik.errors.subject
                          ? "form-control bor"
                          : "form-control text-dark"
                      }
                      name="subject"
                      placeholder={props.t("Leave-Reason")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subject}
                      style={{ height: "unset" }}
                    />
                    {formik.touched.subject &&
                      formik.errors.subject && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.subject}
                        </div>
                      )}
                  </div>

                  {/* <div className="form-group mb-4">
                    <label className="form-label">
                      {props.t("Type")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      onChange={(e) => {
                        formik.setFieldValue("type", e?.value);
                      }}
                      onBlur={formik.handleBlur}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderRadius: "50px",
                        }),
                      }}
                      name="type"
                      className={
                        formik.touched.type && formik.errors.type
                          ? " bor"
                          : " text-dark"
                      }
                      options={Object.keys(constants.LEAVE_TYPE).map((key) => ({
                        value: `${key}`,
                        label: `${key}`,
                      }))}
                    />
                    {formik.touched.type &&
                      formik.errors.type && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.type}
                        </div>
                      )}
                  </div> */}

                  <div className="form-group mb-4">
                    <label className="form-label">
                      {props.t("From-Date")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      name="from_date"
                      className={
                        formik.touched.from_date &&
                        formik.errors.from_date
                          ? "form-control bor"
                          : "form-control text-dark"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.from_date}
                      placeholder={props.t("From-Date")}
                    />
                    {formik.touched.from_date &&
                      formik.errors.from_date && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.from_date}
                        </div>
                      )}
                  </div>

                  <div className="form-group mb-4">
                    <label className="form-label">
                      {props.t("To-Date")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      name="to_date"
                      className={
                        formik.touched.to_date &&
                        formik.errors.to_date
                          ? "form-control bor"
                          : "form-control text-dark"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.to_date}
                      placeholder={props.t("To-Date")}
                    />
                    {formik.touched.to_date &&
                      formik.errors.to_date && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.to_date}
                        </div>
                      )}
                  </div>

                  <div className="form-group mb-4">
                    <label className="form-label">
                      {props.t("Description")}{" "}
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <textarea
                      type="text"
                      rows={5}
                      className={
                        formik.touched.description && formik.errors.description
                          ? "form-control bor"
                          : "form-control text-dark"
                      }
                      name="description"
                      placeholder={props.t("Description")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      style={{ height: "unset" }}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div
                          style={{
                            color: "#f46a6a",
                            fontSize: "11px",
                            marginTop: "3px",
                          }}
                        >
                          {formik.errors.description}
                        </div>
                      )}
                  </div>

                </div>

                <div className="justify-content-between align-items-center mt-5 mob-mt-2">
                  <div className="float-right">
                    <button type="submit" className="btn btn-join mb-5">
                      {props.t("Save")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <div className="d-m-none">
        <Footer />
      </div>
    </div>
  );
};

export default withTranslation()(ApplyLeave);
