import { ProfileAPI } from "apis/ProfileAPI";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Navbar from "../../components/Layout/Navbar";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import config from "../../config";
import NotificationCard from "../Components/NotificationCard";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const Notifications = (props) => {
  const [notificationActiveData, setNotificationActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
  });
  const [notifications, setNotifications] = useState([]);
  const [todaysNotifications, setTodaysNotifications] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  useEffect(() => {
    getAllNotifications(notificationActiveData, false);
  }, []);

  const getAllNotifications = (data, isPushData) => {
    ProfileAPI.getAllNotifications(data)
      .then((res) => {
        if (res.data.success) {
          if (isPushData) {
            setUnreadNotificationCount(res.data.unreadNotificationCount);
            setNotifications([
              ...notifications,
              ...res.data.notifications.rows,
            ]);
            setTodaysNotifications([
              ...todaysNotifications,
              ...res.data.todaysNotifications.rows,
            ]);
          } else {
            setUnreadNotificationCount(res.data.unreadNotificationCount);
            setNotifications(res.data.notifications.rows);
            setTodaysNotifications(res.data.todaysNotifications.rows);
          }
        } else {
          setUnreadNotificationCount(0);
          setNotifications([]);
          setTodaysNotifications([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  window.onscroll = async function (ev) {
    let pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    if (window.innerHeight + window.scrollY >= pageHeight) {
      const data = {
        page: notificationActiveData.page + 1,
        limit: config.LIMIT,
      };
      setNotificationActiveData(data);
      getAllNotifications(data, true);
    }
  };

  return (
    <div>
      {/* <!-- Navbar Start --> */}
      <Navbar />
      {/* <!-- Navbar End --> */}
      <ScrollToTopOnMount />
      {/* <!-- Mobile View Top Start --> */}
      <div className="mobile-gray-top d-none">
        <div className="container">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between mob-mt-39">
              <div>
                <Link to="/profile">
                  <img src="assets/images/768/arrow-left.png" alt="" />
                </Link>
              </div>
              <div>
                <h4 className="mb-0">{props.t("Notifications")}</h4>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Mobile View Top End --> */}

      <section className="mt-5  d-m-none">
        <div className="container">
          <div className="row p-8">
            <div className="col-md-12">
              <h1 className="mt-5  mb-3 main-title">{props.t("Profile")}</h1>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Booking Start --> */}
      <section className="dashboard top-curve2 mob-brd-radius-0">
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />
            <div className="col-md-10">
              <div className="dashboard-inner">
                <div className="col-md-12">
                  <div className="dashboard-inner">
                    <div className="row d-m-none">
                      <div className="col-md-10">
                        <h2 className="mb-3">{props.t("Notifications")}</h2>
                      </div>
                    </div>
                    <p>
                      {props.t("You-have")}{" "}
                      <span className="text-primary">
                        {unreadNotificationCount ? unreadNotificationCount : 0}
                      </span>{" "}
                      {props.t("Notifications")}
                    </p>

                    <div className="notification-list list-style-none">
                      <h4 className="mt-0">{props.t("Today")}</h4>
                      <ul className="first" style={{ paddingLeft: "unset" }}>
                        {todaysNotifications.length > 0
                          ? todaysNotifications.map((notification, i) => {
                              return (
                                <NotificationCard
                                  key={`todaysNotification__${i}`}
                                  notification={notification}
                                />
                              );
                            })
                          : "No record found"}
                      </ul>
                    </div>

                    <div className="notification-list  list-style-none">
                      <h4 className="mt-0">{props.t("This-Week")}</h4>
                      <ul className="first" style={{ paddingLeft: "unset" }}>
                        {notifications.length > 0
                          ? notifications.map((notification, i) => {
                              return (
                                <NotificationCard
                                  key={`notification__${i}`}
                                  notification={notification}
                                />
                              );
                            })
                          : "No record found"}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Booking End --> */}

      {/* <!-- Footer Start --> */}
      <div className="d-m-none">
        <Footer />
      </div>
      {/* <!-- Footer End --> */}
    </div>
  );
};

export default withTranslation()(Notifications);
