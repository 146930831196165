import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
// import { NavLink, Link } from 'react-router-dom';
import config from "../../config";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { AuthApi } from "apis/AuthApi";
import { Token } from "../../Token";

function SignUp(props) {

  const [showPassword, setPasswordShow] = useState(false);
  const handleClickShowPassword = () => {
    setPasswordShow(true);
  };

  const handleClickHidePassword = () => {
    setPasswordShow(false);
  };

  const [errorMsg, setErrorMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      // license_number: "",
      password: "",
    },
    validationSchema: Yup.object().shape(
      {
        first_name: Yup.string().required(props.t("Required-Message")),
        last_name: Yup.string().required(props.t("Required-Message")),
        email: Yup.string().email().required(props.t("Required-Message")),
        // license_number: Yup.number()
        //   .typeError(props.t("Only-Number-Message"))
        //   .required(props.t("Required-Message")),
        password: Yup.string().required(props.t("Required-Message")),
      },
      []
    ),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("first_name", values.first_name);
      data.append("last_name", values.last_name);
      data.append("email", values.email);
      // data.append("license_number", values.license_number);
      data.append("password", values.password);
      AuthApi.signUp(values)
        .then((res) => {
          if (res.data.status) {
            Token.saveToken(res.data.user, res.data.token);
            window.location.href = "/";
          } else {
            setErrorMsg(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  });

  return (
    <div>
      <section>
        <div className="blue-circle1" />
          <div className="login-object d-none">
            <img
              src={config.BASE_URL + "/assets/images/doctor_tem.png"}
              alt=""
            />
          </div>
          <img
            alt=""
            src="assets/images/doctor_tem.png"
            className="login-img d-m-none"
          />

          <div className="container">
          <div className="d-none">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                <Link to="/">
                    <img src={config.BASE_URL + "/assets/images/768/arrow-left.png"} alt="" />
                  </Link>
                </div>
                <div>
                  <h4 className="mb-0">{props.t("Sign-Up")}</h4>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          </div>


            <div className="cme-logo">
              <img src="assets/images/logo/CME.png" className="" alt="CME" />
            </div>
            <div className="login-form login-form-top1">
              <div className="row medx">
                <div className="col-md-2 MedxLogo">
                  <img
                    src="assets/images/logo/svg/MedxLogo.svg"
                    className=""
                    alt="MedxLogo"
                  />
                </div>
                <div className="col-md-10">
                  <h1 className="text-gray-black title">{props.t("Hello")},</h1>
                  <p className="text-gray-black subtitle2 mb-4">
                    {props.t("To-See-You")}
                  </p>
                </div>
              </div>
              <br />
              {errorMsg && <p className="text-danger text-small">{errorMsg}</p>}

              <form onSubmit={formik.handleSubmit}>
                <div className="col-md-12 mt-2">
                  <label
                    htmlFor="first_name"
                    style={{ float: "left" }}
                    className="form-label"
                  >
                    {props.t("First-Name")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      formik.touched.first_name &&
                      formik.errors.first_name
                        ? "form-control bor"
                        : "form-control text-dark"
                    }
                    placeholder={props.t("First-Name")}
                    name="first_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                    style={{ paddingLeft: "20px" }}
                  />
                  {formik.touched.first_name &&
                  formik.errors.first_name ? (
                    <p className="text-danger text-small text-left">
                      {formik.errors.first_name}
                    </p>
                  ) : null}
                </div>

                <div className="col-md-12 mt-2">
                  <label
                    htmlFor="last_name"
                    style={{ float: "left" }}
                    className="form-label"
                  >
                    {props.t("Last-Name")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      formik.touched.last_name &&
                      formik.errors.last_name
                        ? "form-control bor"
                        : "form-control text-dark"
                    }
                    placeholder={props.t("Last-Name")}
                    name="last_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                    style={{ paddingLeft: "20px" }}
                  />
                  {formik.touched.last_name &&
                  formik.errors.last_name ? (
                    <p className="text-danger text-small text-left">
                      {formik.errors.last_name}
                    </p>
                  ) : null}
                </div>

                <div className="col-md-12 mt-2">
                  {showPassword}
                  <label
                    htmlFor="email"
                    style={{ float: "left" }}
                    className="form-label"
                  >
                    {props.t("Email")}{" "}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="email"
                    className={
                      formik.touched.email &&
                      formik.errors.email
                        ? "form-control bor"
                        : "form-control text-dark"
                    }
                    placeholder={props.t("Email")}
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    style={{ paddingLeft: "20px" }}
                  />
                  {formik.touched.email &&
                  formik.errors.email ? (
                    <p className="text-danger text-small text-left">
                      {formik.errors.email}
                    </p>
                  ) : null}
                </div>

                {/* <div className="col-md-12">
                  {showPassword}
                  <label
                    htmlFor="email"
                    style={{ float: "left" }}
                    className="form-label"
                  >
                    {props.t("License-Number")}{" "}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="text"
                    className={
                      formik.touched.license_number &&
                      formik.errors.license_number
                        ? "form-control bor"
                        : "form-control text-dark"
                    }
                    placeholder={props.t("License-Number")}
                    name="license_number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.license_number}
                    style={{ paddingLeft: "20px" }}
                  />
                  {formik.touched.license_number &&
                  formik.errors.license_number ? (
                    <p className="text-danger text-small text-left">
                      {formik.errors.license_number}
                    </p>
                  ) : null}
                </div> */}

                <div className="form-group mt-2">
                  <label
                    htmlFor="password"
                    style={{ float: "left" }}
                    className="form-label ck"
                  >
                    {props.t("Password")} <span className="text-danger">*</span>
                  </label>
                  <div
                    className={
                      formik.touched.password && formik.errors.password
                        ? "input-group input-password-border border-red"
                        : "input-group input-password-border"
                    }
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      className={
                        formik.touched.password && formik.errors.password
                          ? "form-control bb input-password-text"
                          : "form-control input-password-text"
                      }
                      placeholder={props.t("Password")}
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      style={{ paddingLeft: "20px" }}
                    />
                    {showPassword ? (
                      <div className="input-group-addon fapsicon">
                        <i
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          onClick={handleClickHidePassword}
                        ></i>
                      </div>
                    ) : (
                      <div className="input-group-addon fapsicon">
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          onClick={handleClickShowPassword}
                        ></i>
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group mt-2">
                  {formik.touched.password && formik.errors.password ? (
                    <p
                      className="text-danger text-small text-left"
                      style={{ marginTop: "1px" }}
                    >
                      {formik.errors.password}
                    </p>
                  ) : null}
                </div>

                <button type="submit" className="btn btn-blue btn-block mt-3">
                  {props.t("Sign-Up")}
                </button>

                <p className="bottom-text mt-3">
                  <Link to="/sign-in" className="text-primary">
                    {props.t("Sign-In")}
                  </Link>
                </p>
              </form>
            </div>
          </div>
      </section>
    </div>
  );
}

export default withTranslation()(SignUp);
