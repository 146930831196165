import React, { useEffect, useState } from "react";
import { NavLink, Link } from 'react-router-dom';
import config from "../../config";
import { withTranslation } from "react-i18next";

function AboutUs(props) {
    return (
        <>
            <main className="content-row">
                <div className="page-title-wrapp">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="title-03 text-center text-blue"> <span>About Us</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-box-01 pad-top-50 pad-bt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-justify cms">
                                <p>
                                    Platform for Medical Education Development Experience for lifelong learning. Sharing knowledge, skill, attitude, experience, motivation, etc. among medical communities through digital transformation of existing practices and database, allowing a physician focus education by real time learning, lesser burden and adaptive activities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default withTranslation()(AboutUs)