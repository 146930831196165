import React, { useEffect, useState } from "react";
import { NavLink, Link, useParams, useLocation } from "react-router-dom";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import { CommunityApi } from "../../apis/CommunityApi";
import config, { IMAGE_URL } from "../../config";
import moment from "moment";
import { OfferApi } from "../../apis/OfferAPI";
import { BookmarkApi } from "apis/BookmarkApi";
import Linkify from "react-linkify";
import i18n from "i18n";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function OfferDetail() {
  const params = useParams();
  const offer_id = params.offer_id;

  const query = useQuery();
  const redirect = query.get("redirect");

  const [offerDetailData, setOfferDetailData] = useState({
    offer_id: offer_id,
  });

  const [offereducationtype, setOfferOfferEducationType] = useState({
    education_type: "CME",
    page: 1,
    limit: config.LIMIT,
  });
  const [offer, setOffer] = useState({});
  const [isBookmark, setBookmark] = useState(false);

  const getOfferDetail = () => {
    OfferApi.getOfferDetails(offerDetailData)
      .then((res) => {
        if (res.data.success) {
          setOffer(res.data.data);
          setBookmark(res.data.data?.isBookmark ? true : false);
        } else {
          setOffer({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOfferDetail();
  }, []);

  const postOfferBookmark = (isBookmark) => {
    const payload = {
      offer_id: offer_id,
      isBookmark: isBookmark,
    };
    BookmarkApi.postOffersBookmark(payload)
      .then((res) => {
        if (res.data.success) {
          if (isBookmark) {
            setBookmark(true);
          } else {
            setBookmark(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Navbar />
      <section className="pt-3 d-m-none">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12">
              <h1 className="mt-5 mb-4 main-title d-m-none">Offer</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="community pb-3">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12 px-30">
              <ul className="nav nav-tabs d-m-none" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="offer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#offer"
                    type="button"
                    role="tab"
                    aria-controls="offer"
                    aria-selected="true"
                  >
                    Offer
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <button className="nav-link" id="service-tab"
                    >Service</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="explore-tab"
                    >Explore</button>
                </li> */}
              </ul>
              <div className="tab-content mob-mb-30 clearfix" id="myTabContent">
                <div
                  className="tab-pane fade show active offer-d"
                  id="offer"
                  role="tabpanel"
                  aria-labelledby="offer-tab"
                >
                  <div className="row">
                    <div className="col-md-10">
                      <img
                        src="../assets/images/768/top-gray-circle.png"
                        className="d-none top-circle"
                        alt=""
                        width=""
                        height=""
                      />
                      <div className="bg-gray offer-detail">
                        <div className="mb-3">
                          <Link to={redirect ? redirect : "/offers"}>
                            <img
                              src="../assets/images/left-arrow.png"
                              className=""
                              alt=""
                              width=""
                              height=""
                            />
                          </Link>
                        </div>

                        <div className="px-4">
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <span
                                className="badge mb-2"
                                style={{
                                  backgroundColor:
                                    offer?.OfferType?.bg_color_code,
                                }}
                              >
                                {offer?.OfferType?.name_en}
                              </span>
                              <h4>{offer?.name_en}</h4>
                              <p className="date">
                                {moment(offer?.start_date).format("DD")} -{" "}
                                {moment(offer?.end_date).format("DD MMM YYYY")}
                              </p>
                            </div>

                            {!isBookmark ? (
                              <a
                                onClick={() => postOfferBookmark(true)}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="bookmark bookmark-show ">
                                  <div>
                                    <img
                                      className="img-fluid"
                                      src="../assets/images/bookmark-outline.png"
                                      alt=""
                                      width="25"
                                      height=""
                                    />
                                  </div>
                                </div>
                              </a>
                            ) : (
                              <>
                                <a
                                  onClick={() => postOfferBookmark(false)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="bookmark bookmark-show">
                                    <div>
                                      <img
                                        className="img-fluid"
                                        src={
                                          config.BASE_URL +
                                          "/assets/images/bookmark.png"
                                        }
                                        alt=""
                                        width={25}
                                        height=""
                                      />
                                    </div>
                                  </div>
                                </a>
                              </>
                            )}

                            <div className="bookmark-hide bookmark">
                              <div>
                                <img
                                  className="img-fluid"
                                  src="../assets/images/bookmark.png"
                                  alt=""
                                  width="25"
                                  height=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="my-3 mob-myb-0">
                            <a href={`${offer?.image_link}`} target="_blank">
                              <img
                                src={`${offer?.image_url}`}
                                className="w-100 brd-23"
                                alt=""
                                width=""
                                height=""
                              />
                            </a>
                          </div>
                          <Linkify
                            componentDecorator={(
                              decoratedHref,
                              decoratedText,
                              key
                            ) => (
                              <a href={decoratedHref} key={key} target="_blank">
                                {decoratedText}
                              </a>
                            )}
                          >
                            {i18n.language == "en" && offer?.description_en
                              ? offer?.description_en
                              : offer?.description_en}
                          </Linkify>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 d-m-none">
                      <div className="img-block mb-3">
                        <img
                          src="assets/images/image004.png"
                          className="img-fluid"
                          alt=""
                          width=""
                          height=""
                        />
                      </div>
                      <div className="img-block mb-3">
                        <img
                          src="assets/images/image004.png"
                          className="img-fluid"
                          alt=""
                          width=""
                          height=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      {/* <!-- Add Collection Modal --> */}
      <div className="modal fade" id="createcollection">
        <div className="modal-dialog modal-dialog-centered modal-xs">
          <div className="modal-content">
            {/* <!-- Modal body --> */}
            <div className="modal-body">
              <div className="c-content">
                <h5 className="mb-2">New Collection</h5>
                <form>
                  <div>
                    <label for="" className="form-label">
                      Collection name
                    </label>
                    <input
                      type="text"
                      className="form-control w-100"
                      id=""
                      placeholder="Collection Name"
                      name=""
                    />
                  </div>
                </form>
              </div>
              <div className="d-flex pt-4 collection-btn-list">
                <a className="float-right btn btn-cancel-d">Cancel</a>
                <a className="float-right btn btn-create close-modal">
                  Yes, Create Collection
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Bookmark To Modal --> */}
      <div className="modal fade" id="bookmarkto">
        <div className="modal-dialog modal-dialog-centered modal-xs">
          <div className="modal-content">
            {/* <!-- Modal body --> */}
            <div className="modal-body">
              <div className="c-content">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h5 className="mb-0">Bookmark To</h5>
                  </div>
                  <div>
                    <a
                      className="create"
                      data-bs-target="#createcollection"
                      data-bs-toggle="modal"
                    >
                      Create
                    </a>
                    <img
                      src="assets/images/close.png"
                      className=""
                      alt=""
                      width=""
                      height=""
                    />
                  </div>
                </div>
                <div className="b-list mt-3">
                  <a className="">
                    <div className="d-flex align-items-center b-list-block mb-2">
                      <div>
                        <img
                          src="assets/images/image 15.png"
                          className="brd-radius-10"
                          alt=""
                          width="33"
                          height=""
                        />
                      </div>
                      <div>For Later</div>
                    </div>
                  </a>
                  <a className="">
                    <div className="d-flex align-items-center b-list-block mb-2">
                      <div>
                        <img
                          src="assets/images/image 15.png"
                          className="brd-radius-10"
                          alt=""
                          width="33"
                          height=""
                        />
                      </div>
                      <div>specialized research for year 2 📑</div>
                    </div>
                  </a>
                  <a className="">
                    <div className="d-flex align-items-center b-list-block mb-2">
                      <div>
                        <img
                          src="assets/images/image 15.png"
                          className="brd-radius-10"
                          alt=""
                          width="33"
                          height=""
                        />
                      </div>
                      <div>Food discount 🍕🍔</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
