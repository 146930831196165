import React, { useEffect, useState } from "react";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import { InstituteApi } from "../../apis/InstituteApi";
import config from "../../config";
import * as url from "../../helpers/common_helper";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

function ActivityInstituteFilter() {
    const [activeInstitutesData, setActiveInstitutesData] = useState();
    const [instituteActive, setInstituteActive] = useState({keyword:"All"});
    const navigate = useNavigate();

    useEffect(() => {
        getInstitutes(instituteActive);
    }, []);

    const getInstitutes = () => {
        InstituteApi.getInstitutesFilterbyName(instituteActive)
            .then((res) => {
                if (res.data.success) {
                    setActiveInstitutesData(res.data.data);
                } else {
                    setActiveInstitutesData([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleKeywordActive = (value) => {
        instituteActive.keyword = value;
        setInstituteActive({
            keyword: value
        });
        getInstitutes(activeInstitutesData);
      };

      const handleClickInstitute = (value) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const product = urlParams.get('keyword');
        // navigate('/upcoming-activities?keyword='+urlParams.get('keyword')+'&&institute_id='+value+'');
        navigate('/upcoming-activities?institute_id='+value+'');
        //setInstituteActive(value);
      };

    return (
        <div>
            <>
                <Navbar />
                <ScrollToTopOnMount />
                <div className="mobile-gray-top d-none">
                    <div className="container">
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-between mob-mt-39">
                                <div>
                                    <NavLink to='/upcoming-activities'>
                                        <img
                                            src={config.BASE_URL + '/assets/images/768/arrow-left.png'}
                                            className=""
                                        />
                                    </NavLink>
                                </div>
                                <div>
                                    <h4 className="mb-0">Filter</h4>
                                </div>
                                <div>
                                    <NavLink to="/education/institute-filter">
                                        <img
                                            src={config.BASE_URL + '/assets/images/768/adjustment.png'}
                                            className=""
                                        />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MobileBottomMenu />

                <section className="mt-5 mb-0 mob-mt-4 filter">
                    <div className="container">
                        <div className="row px-10">
                            <div className="col-md-12 list-style-none">
                                <p>Find events by institution here.</p>

                                <div className="col-md-12">
                                    <div className="search-box">
                                        <form action="">
                                            <div className="row">
                                                <div className="col-md-9 pr-0">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">
                                                            <img
                                                                src={
                                                                    config.BASE_URL + "/assets/images/search.png"
                                                                }
                                                                alt="search-icon"
                                                                width={15}
                                                                height={15}
                                                            />
                                                        </span>
                                                        <input
                                                            type="text"
                                                            className="form-control searchTextEductaion"
                                                            placeholder="Search"
                                                            onChange={(e) =>
                                                              handleKeywordActive(e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                                                            
                                <ul className="filter-list  mt-3">
                                    <li>
                                        <div className="form-check">
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                id="radio1"
                                                name="optradio"
                                                defaultValue="option1"
                                                defaultChecked=""
                                                onClick={() =>
                                                    handleClickInstitute('All')
                                                    }
                                            />
                                            <label className="form-check-label" htmlFor="radio1">
                                                All
                                            </label>
                                        </div>
                                    </li>

                                    {activeInstitutesData?.map((institutes, i) => (
                                        <li key={`education-activities-${i}`}>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    id="radio1"
                                                    name="optradio"
                                                    defaultValue="option1"
                                                    defaultChecked=""
                                                    onClick={() =>
                                                        handleClickInstitute(institutes.id)
                                                      }
                                                />
                                                <label className="form-check-label" htmlFor="radio1" style={{width:"95%"}}>
                                                    {institutes.institution_name}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* CME Events End */}
            </>

            <Footer />
        </div>
    );
}

export default ActivityInstituteFilter;
