import { React } from "react";
import { withTranslation } from "react-i18next";
// import constants from "../../../constants";
import * as url from "../../../helpers/common_helper";
// import config from "../../../config";

const LeaveDetailModal = (props) => {
  const leave = props.data;
  return (
    <div className="modal fade in" id={`leaveDetailsmodal_${leave.id}`}>
      <div className="modal-dialog scrollDialog modal-lg">
        <div className="modal-content mb-5">
          <div className="modal-header">
            <h4 className="modal-title">{props.t("Leave-Detail")}</h4>
          </div>
          <div className="modal-body scrollAndFixedHeight">
            <div className="row">
              <div className="col-md-4 col-4">
                <h6>
                  {props.t("Subject")}
                </h6>
              </div>
              <div className="col-md-8 col-8">
                <p>: {leave?.subject}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-4">
                <h6>
                  {props.t("From-Date")}
                </h6>
              </div>
              <div className="col-md-8 col-8">
                <p>: {url.dateFormat(leave.from_date)}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-4">
                <h6>
                  {props.t("To-Date")}
                </h6>
              </div>
              <div className="col-md-8 col-8">
                <p>: {url.dateFormat(leave.to_date)}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-4">
                <h6>
                  {props.t("Status")}
                </h6>
              </div>
              <div className="col-md-8 col-8">
                <p>: {leave?.status}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-4">
                <h6>
                  {props.t("Description")}
                </h6>
              </div>
              <div className="col-md-8 col-8">
                <p>: {leave?.description}</p>
              </div>
            </div>
            {leave?.comment ? (
              <div className="row">
                <div className="col-md-4 col-4">
                  <h6>
                    {props.t("Comment")}
                  </h6>
                </div>
                <div className="col-md-8 col-8">
                  <p>: {leave?.comment}</p>
                </div>
              </div>
            ) : "" }
            
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(LeaveDetailModal);