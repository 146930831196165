import { ProfileAPI } from "apis/ProfileAPI";
import { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { withTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Navbar from "../../components/Layout/Navbar";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import Swal from "sweetalert2";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const CheckResultDetail = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { activityId, userId, resultFor, type, userTaskId } = location.state;
  const [userEvaluations, setUserEvaluations] = useState([]);
  const [internSubmittedResult, setInternSubmittedResult] = useState();
  const [haveConcern, setHaveConcern] = useState(false);
  const [concernComment, setConcernComment] = useState(null);
  useEffect(() => {
    getUserEvaluationdetails();
  }, []);
  const getUserEvaluationdetails = async () => {
    const payload = {
      activityId: activityId,
      userId: userId,
      evaluatorType: type,
      userTaskId: userTaskId
    };
    ProfileAPI.getUserEvaluationApi(payload)
      .then((res) => {
        if (res.data.success) {
          setUserEvaluations(res.data.userEvaluation);
          if (res.data.internSubmittedResult) {
            setInternSubmittedResult(res.data.internSubmittedResult);
            if (res.data.internSubmittedResult?.type !== "Accepted") {
              setConcernComment(res.data.internSubmittedResult.concern_comment);
              setHaveConcern(true);
            }
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "");
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          );
        }
      });
  };

  const onAcceptEvaluatorResultHandler = (submitType) => {
    const payload = {
      activityId: activityId,
      formId: userEvaluations[0]?.form_id,
      resultEvaluatorId: userId,
      evaluatorType: type,
      type: submitType,
      concernComment: concernComment,
    };
    ProfileAPI.submitEvaluatorResultApi(payload)
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            text: res.data.message,
            icon: "success",
            imageAlt: "success image",
          }).then((result) => {
            window.location.reload();
          });
        } else {
          toastr.error(res?.data?.message, "");
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "");
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          );
        }
      });
  };

  return (
    <div>
      <Navbar />
      <ScrollToTopOnMount />
      <>
        <div className="mobile-top d-none">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                  <NavLink to="/profile">
                    <img src="assets/images/768/arrow-left-w.png" alt="" />
                  </NavLink>
                </div>
                <div>
                  <h4 className="mb-0">{props.t("My-Calendar")}</h4>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View Top End */}
        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row p-8">
              <div className="col-md-12">
                <h1 className="mt-5  mb-3 main-title">{props.t("Profile")}</h1>
              </div>
            </div>
          </div>
        </section>
      </>

      <section className="dashboard top-curve-check-results">
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />
            <div className="col-md-10">
              <div className="align-items-center d-m-none justify-content-between mob-mt-30">
                <div className="mb-3">
                  <a onClick={() => navigate(-1)} className="poi">
                    <img src="/assets/images/left-arrow.png" alt="" />
                  </a>
                </div>
              </div>
              <div>
                <section
                  className="dashboard top-curve-check-results"
                  style={{ clear: "both" }}
                >
                  <div className="">
                    <div className="row p-12">
                      <div className="col-md-12">
                        <div
                          className="dashboard-inner mb-3"
                          style={{ minHeight: "auto" }}
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <h2 className="mb-5 d-m-none">
                                {props.t("Evaluation-Details")}
                              </h2>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="position-relative ">
                                {Array.isArray(userEvaluations) &&
                                  userEvaluations.length > 0 && (
                                    <div className="row">
                                      <div className="col-md-12 mb-2 d-none">
                                        <h4>{props.t("Evaluation-Details")}</h4>
                                      </div>
                                      {userEvaluations.map((item, index) => {
                                        return (
                                          <div
                                            className="row mb-3"
                                            key={`internshipevaluationresult_${index}`}
                                          >
                                            <>
                                              <div className="col-md-3 col-4">
                                                <h6>
                                                  {
                                                    item?.UserEvalutionFormField
                                                      ?.label_en
                                                  }
                                                </h6>
                                              </div>
                                              <div className="col-md-9 col-8">
                                                <h6>
                                                  {": "} {item.field_value}
                                                </h6>
                                              </div>
                                            </>
                                            {item.remark && (
                                              <>
                                                <div className="col-md-3 col-4">
                                                  <h6>Remark</h6>
                                                </div>
                                                <div className="col-md-9 col-8">
                                                  <h6>
                                                    {": "} {item.remark}
                                                  </h6>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          {type === "TEACHER" && resultFor === "intern" && !haveConcern && (
                            <div className="row">
                              {!internSubmittedResult && (
                                <div className="justify-content-between align-items-center mt-3">
                                  <div className="float-right">
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      onClick={() =>
                                        onAcceptEvaluatorResultHandler(
                                          "Accepted"
                                        )
                                      }
                                    >
                                      {props.t("Accept")}
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-join"
                                      onClick={() => setHaveConcern(true)}
                                    >
                                      {props.t("haveConcern")}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          {internSubmittedResult && (
                            <div className="row">
                              <div className="col-sm-12">
                                <span className="text-green">
                                  {internSubmittedResult?.type === "Accepted"
                                    ? props.t("youAcceptedResult")
                                    : props.t("youSubmittedConcern")}
                                </span>
                              </div>
                            </div>
                          )}
                          {type === "TEACHER" &&  resultFor === "intern" && haveConcern && (
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group mt-3 mb-4">
                                  <label className="form-label">
                                    {props.t("concernComment")}
                                  </label>
                                  <textarea
                                    type="text"
                                    rows={5}
                                    className="form-control  w-100 teacher-remark"
                                    name="description"
                                    style={{ height: "unset" }}
                                    onChange={(e) => {
                                      setConcernComment(e.target.value);
                                    }}
                                    value={concernComment}
                                    disabled={
                                      internSubmittedResult ? true : false
                                    }
                                  />
                                </div>
                              </div>
                              {!internSubmittedResult && (
                                <div className="col-sm-12">
                                  <div className="justify-content-between align-items-center">
                                    <div className="float-right">
                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={() =>
                                          onAcceptEvaluatorResultHandler(
                                            "HaveConcern"
                                          )
                                        }
                                      >
                                        {props.t("Submit")}
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-join"
                                        onClick={() => setHaveConcern(false)}
                                      >
                                        {props.t("Cancel")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(CheckResultDetail);
