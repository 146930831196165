import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const EducationApi = {
  getEducationActivities: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_EDUCATION_ACTIVITIES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getEducationLibraries: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_EDUCATION_LIBRARIES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getActivityNameByKeyword: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_NAME_KEYWORD}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getEducationActivitiesByType: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_EDUCATION_ACTIVITIES_BY_TYPE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getEducationLibraryTypeWise: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_EDUCATION_LIBRARIES_TYPE_WISE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getActivityDetails: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getLibraryDetails: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_LIBRARY_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getInterests: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_INTERESTS}`,
      headers: {
        "Content-Type": "application/json",
      },
      //data: data
    };

    return await axios(axiosConfig);
  },
  confirmBooking: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CONFIRM_BOOKING}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  rejoinBooking: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.REJOIN_BOOKING}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
};
