import moment from "moment";
import { React, useState } from "react";
// import * as url from "../../helpers/common_helper";
import { withTranslation } from "react-i18next";

const InternshipTask = (props) => {
    const internshipTask = props.task;
    const internship = props.internship;
    const internshipArray = internship.InternshipTaskIntern;
   
    const betweenMonths = props.months;
    const totalMaxTask = props.count;
    var taskmonthWidth = (70 / totalMaxTask);
    
    return (
        <div className="timeline">
            {betweenMonths ? betweenMonths.map((task, j) => {
            const myArray = task.split("-"); 
            const dataArray = internshipTask[task];
            return (
                <>
                    <div className="d-m-none" >
                    <div className="d-flex brd-block" key={j}>
                    <div className="year">
                        <h5>{moment().month(myArray[0]-1).format("MMM")}</h5>
                        <small>{myArray[1]}</small>
                    </div>

                    {Array.apply(0, Array(totalMaxTask)).map(function (x, s) {
                            var checkPreTaskExist = false;
                            var checkNextTaskExist = false;
                            var marginBottom   = "13px";
                            var marginRight   = "15px";
                            const internshipTaskData = internshipArray.find(obj => obj.id === dataArray[s]);
                            
                            var showDate = '';
                            if(internshipTaskData != undefined) {
                            const startDate = moment(internshipTaskData.start_date).format("DD-MM-YYYY");
                            const endDate = moment(internshipTaskData.end_date).format("DD-MM-YYYY");
                            if(startDate == endDate) {
                                showDate = moment(internshipTaskData.start_date).format("DD MMM YYYY");
                            } else {
                                showDate =  moment(internshipTaskData.start_date).format("DD MMM YYYY")+' - '+moment(internshipTaskData.end_date).format("DD MMM YYYY");
                            }
                            console.log(startDate);
                            }
                            if(j > 0) {
                                const preArray = internshipTask[betweenMonths[j-1]];
                                checkPreTaskExist = preArray.includes(dataArray[s]);

                                if(internshipTask[betweenMonths[j+1]] != undefined) {
                                    const nextArray = internshipTask[betweenMonths[j+1]];
                                    checkNextTaskExist = nextArray.includes(dataArray[s]);
                                    if(checkNextTaskExist) {
                                        marginBottom   = "0px";
                                    }
                                }

                            } 
                            return (
                            <>
                            {dataArray[s] != 0 ? (
                                        <>
                                        {!checkPreTaskExist ? (
                                                <div className="year-block" key={s+''+j}  style={{borderLeft:'10px solid'+internshipTaskData?.task?.task_type_color,width:taskmonthWidth+'%',marginRight:marginRight}}>
                                                {/* <small className="text3">Intern
                                                </small> */}
                                                <h4 style={{fontSize:"13px"}}>{internshipTaskData?.task?.title_en}</h4>
                                                <div style={{fontSize:"11px"}}>{internshipTaskData?.instituteHost?.institution_name}</div>
                                                <span>{showDate}</span>
                                                </div>
                                        ) : 

                                                <div className="year-block" key={s+''+j}  style={{borderLeft:'10px solid'+internshipTaskData?.task?.task_type_color,width:taskmonthWidth+'%',top:"0px",marginRight:marginRight,marginBottom:marginBottom}}>
                                                <small className="text3">
                                                </small>
                                                </div>
                                        }
                                    </>
                                ) : 
                                
                                <>
                                <div className="" key={s+''+j}  style={{width:taskmonthWidth+'%',marginRight:marginRight}}>
                                <small className="text3">
                                </small>
                                </div>
                                </>

                            }
                            </>
                            )
                    })}
                                
                    </div>
                    </div>



                <div className="d-none" >
                <div className="" key={j+j}>
                   

                    {Array.apply(0, Array(totalMaxTask)).map(function (x, s) {
                            var checkPreTaskExist = false;
                            var checkNextTaskExist = false;
                            var marginBottom   = "13px";
                            var marginRight   = "15px";
                            const internshipTaskData = internshipArray.find(obj => obj.id === dataArray[s]);
                            
                            var showDate = '';
                            if(internshipTaskData != undefined) {
                            const startDate = moment(internshipTaskData.start_date).format("DD-MM-YYYY");
                            const endDate = moment(internshipTaskData.end_date).format("DD-MM-YYYY");
                            if(startDate == endDate) {
                                showDate = moment(internshipTaskData.start_date).format("DD MMM YYYY");
                            } else {
                                showDate =  moment(internshipTaskData.start_date).format("DD MMM YYYY")+' - '+moment(internshipTaskData.end_date).format("DD MMM YYYY");
                            }
                            }
                            if(j > 0) {
                                const preArray = internshipTask[betweenMonths[j-1]];
                                checkPreTaskExist = preArray.includes(dataArray[s]);

                                if(internshipTask[betweenMonths[j+1]] != undefined) {
                                    const nextArray = internshipTask[betweenMonths[j+1]];
                                    checkNextTaskExist = nextArray.includes(dataArray[s]);
                                    if(checkNextTaskExist) {
                                        marginBottom   = "0px";
                                    }
                                }

                            } 
                            return (
                            <>
                            {dataArray[s] != 0 ? (
                                        <>
                                        {!checkPreTaskExist ? (
                                                <div className="year-block d-none w-100" style={{borderLeft:'10px solid'+internshipTaskData?.task?.task_type_color}} key={s+''+j}>
                                                {/* <small className="text3">Intern
                                                </small> */}
                                                <h4 style={{fontSize:"13px"}}>{internshipTaskData?.task?.title_en}</h4>
                                                <div style={{fontSize:"11px"}}>{internshipTaskData?.instituteHost?.institution_name}</div>
                                                <span>{showDate}</span>
                                                </div>
                                        ) : 

                                                <></>
                                        }
                                    </>
                                ) : 
                                
                                <>
                                </>

                            }
                            </>
                            )
                    })}
                                
                </div>
                </div>
                </>

             );
            })
            : "1 No record found"}

            
           
        </div>
    );
};

export default withTranslation()(InternshipTask);
