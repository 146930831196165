import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Layout/Navbar";
import { withTranslation } from "react-i18next";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Select from "react-select";
import _ from "lodash";
import { ActivityApi } from "apis/ActivityApi";
import EvaluationFormFields from "pages/Components/EvaluationFormFields";

const EvaluationForm = (props) => {
  const { formID, activityId, userTaskID } = useParams();
  const [evaluationFormFields, setEvaluationFormFields] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [validationErr, setValidationErr] = useState({});

  useEffect(() => {
    getEvaluationFeedbackForm();
  }, []);

  const getEvaluationFeedbackForm = () => {
    let data = { form_id: parseInt(formID) };
    ActivityApi.getEvaluationFeedbackFormApi(data)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.length > 0) {
            let fields = [];
            let formValuesCopy = {};
            res.data.data.forEach((element, i) => {
              fields[i] = {
                fieldId: element.id,
                fieldLabel: element.label_en,
                fieldValue: "",
                fieldOptionId: null,
                formType: element.form_type,
                isRequired: element.is_required,
                isRemark: element.is_remark,
                EvaluationFormFieldOption: element.EvaluationFormFieldOption,
              };

              if (element.is_required)
                formValuesCopy[`field_${element.id}`] = "";
            });
            setEvaluationFormFields(fields);
            setFormValues(formValuesCopy);
          }
          return true;
        } else {
          setEvaluationFormFields([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onHandleChange = (fieldId, type, fieldValue, optionLabel, index, isRemark = false) => {
    const evaluationFormFieldsCopy = _.cloneDeep(evaluationFormFields);
    const formValuesCopy = _.cloneDeep(formValues);
    let addEditFormValues = evaluationFormFieldsCopy[index];
    if (type === "QUESTION") {
      if(isRemark){
        addEditFormValues.remark = fieldValue;
        evaluationFormFieldsCopy.splice(index, 1, addEditFormValues);
  
        formValuesCopy[`field_remark_${fieldId}`] = fieldValue;
      } else{
        addEditFormValues.fieldValue = optionLabel;
        addEditFormValues.fieldOptionId = fieldValue;
        evaluationFormFieldsCopy.splice(index, 1, addEditFormValues);

        formValuesCopy[`field_${fieldId}`] = fieldValue;
      }
    } else {
      addEditFormValues.fieldValue = fieldValue;
      evaluationFormFieldsCopy.splice(index, 1, addEditFormValues);

      formValuesCopy[`field_${fieldId}`] = fieldValue;
    }
    setEvaluationFormFields(evaluationFormFieldsCopy);
    setFormValues(formValuesCopy);
    return true;
  };

  const onSubmitEvaluationForm = async () => {
    let errors = await checkFormValidation();
    let errorsSize = Object.keys(errors).length;
    if (errorsSize != 0) {
      return false;
    }
    const payload = {
      form_id: formID,
      activity_id: activityId,
      userTaskID: userTaskID,
      formData: evaluationFormFields,
    };
    ActivityApi.postEvaluationFormApi(payload)
      .then((res) => {
        if (res.data.success) {
          toastr.success(res?.data?.message, "");
          // window.location.reload();
          window.close();
        } else {
          toastr.warning(res?.data?.message, "");
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "");
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          );
        }
      });
  };

  const checkFormValidation = async () => {
    let validationErrorMsg = {};
    const keys = Object.keys(formValues);
    keys.forEach((key) => {
      if (formValues[key] === "") {
        validationErrorMsg[key] = props.t("Required-Message");
      }
    });
    setValidationErr(validationErrorMsg);
    return validationErrorMsg;
  };

  return (
    <div>
      <Navbar />

      <section className="pb-5 application">
        <div className="container">
          <div className="row row2">
            <div className="col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-4 mob-mt-30">
                <h3 className="mb-0">{props.t("feedbackForm")}</h3>
              </div>
            </div>
            <form>
              <EvaluationFormFields
                evaluationFormFields={evaluationFormFields}
                validationErr={validationErr}
                onHandleChange={onHandleChange}
                onSubmitEvaluationForm={onSubmitEvaluationForm}
                forPage="normalActivity"
              />
              {/* <div className="row application-form">
                {evaluationFormFields?.map((field, index) => {
                  index = index + 1;

                  if (field.formType === "TEXT") {
                    return (
                      <div
                        className="col-md-6"
                        key={`evaluationFormFiels${index}`}
                      >
                        <label htmlFor="" className="form-label">
                          {index}. {field.fieldLabel}{" "}
                          {field.isRequired ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          name={`field_${field.fieldId}`}
                          placeholder={field.fieldLabel}
                          onChange={(e) =>
                            onHandleChange(
                              field.fieldId,
                              field.formType,
                              e.target.value,
                              "",
                              index - 1
                            )
                          }
                        />
                        {validationErr[`field_${field.fieldId}`] ? (
                          <p className="text-danger text-small">
                            {validationErr[`field_${field.fieldId}`]}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  } else if (field.formType === "MULTITEXT") {
                    return (
                      <div
                        className="col-md-12"
                        key={`evaluationFormFiels${index}`}
                      >
                        <label htmlFor="" className="form-label">
                          {index}. {field.fieldLabel}{" "}
                          {field.isRequired ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <textarea
                          rows={5}
                          className="form-control"
                          id=""
                          name={`field_${field.fieldId}`}
                          placeholder={field.fieldLabel}
                          style={{ height: "unset" }}
                          onChange={(e) =>
                            onHandleChange(
                              field.fieldId,
                              field.formType,
                              e.target.value,
                              "",
                              index - 1
                            )
                          }
                        />
                        {validationErr[`field_${field.fieldId}`] ? (
                          <p className="text-danger text-small">
                            {validationErr[`field_${field.fieldId}`]}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  } else if (field.formType === "QUESTION") {
                    return (
                      <div
                        className="col-md-6"
                        key={`evaluationFormFiels${index}`}
                      >
                        <label className="form-label">
                          {index}. {field.fieldLabel}{" "}
                          {field.isRequired ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <Select
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                              marginBottom: "15px",
                            }),
                          }}
                          name={`field_${field.fieldId}`}
                          options={field?.EvaluationFormFieldOption.map(
                            (option, i) => ({
                              value: option.id,
                              label: `${option.option_en}`,
                            })
                          )}
                          onChange={(e) =>
                            onHandleChange(
                              field.fieldId,
                              field.formType,
                              e.value,
                              e.label,
                              index - 1
                            )
                          }
                        />
                        {validationErr[`field_${field.fieldId}`] ? (
                          <p className="text-danger text-small mb-3">
                            {validationErr[`field_${field.fieldId}`]}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }
                })}

                <div className="flex-wrap gap-2 mt-3">
                  <button
                    type="button"
                    onClick={onSubmitEvaluationForm}
                    className="btn btn-join2"
                  >
                    {props.t("Submit")}
                  </button>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(EvaluationForm);
