import React from "react";

const ProfileTitle = ({title}) => (
  <section className="mt-5  d-m-none">
    <div className="container">
      <div className="row p-8">
        <div className="col-md-12">
          <h1 className="mt-5  mb-3 main-title">{title}</h1>
        </div>
      </div>
    </div>
  </section>
);

export default ProfileTitle;
