import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom'
import Footer from '../../components/Layout/Footer'
import Navbar from '../../components/Layout/Navbar'
// import config from "../../config";
import { withTranslation } from "react-i18next";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import { ProfileAPI } from "apis/ProfileAPI";
import InternshipTask from "../Components/InternshipTask";
// import { getUser } from "../../Token";
import * as url from "../../helpers/common_helper";
import moment from "moment";


function Internship(props) {
    const [internshipTasks, setInternshipTasks] = useState([]);
    const [internship, setInternship] = useState([]);
    const [mothList, setmothList] = useState([]);

    const [thaiYear, setThaiYear] = useState("");

    const [maxTaskCount, setmaxTaskCount] = useState(0);
    useEffect(() => {
        fetchInternshipTasks();
    }, []);

    const fetchInternshipTasks = (data) => {
        ProfileAPI.fetchInternshipTasks(data)
            .then((res) => {
                
                if (res.data.success) {
                    //console.log(res.data.internshipTasks);
                    setInternship(res.data.internship);
                    setInternshipTasks(res.data.internshipTasks);
                    setmaxTaskCount(res.data.maxTaskCount);
                    setmothList(res.data.mothList);
                    var showDate = '';
                    if(res.data.internship != undefined) {
                    const startDate = moment(res.data.internship.start_date).format("YYYY");
                    const endDate = moment(res.data.internship.end_date).format("YYYY");
                        if(startDate == endDate) {
                            showDate = url.currentYeareTH(res.data.internship.start_date);
                        } else {
                            showDate =  url.currentYeareTH(res.data.internship.start_date)+' - '+url.currentYeareTH(res.data.internship.end_date);
                        }
                    }
                    setThaiYear(showDate);


                } else {
                    setInternship([]);
                    setInternshipTasks([]);
                    setmaxTaskCount(0);
                    setmothList([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        };
        
    return (
        <div>
            <Navbar />
            <MobileBottomMenu />
            <>
                <div className="mobile-top d-none">
                    <div className="container">
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-between mob-mt-39">
                                <div>
                                    <NavLink to="/education">
                                        <img
                                            src="assets/images/768/arrow-left-w.png"
                                            className=""
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                                <div>
                                    <h4 className="mb-0">{props.t("Internship")}</h4>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Mobile View Top End */}
                <section className="mt-5 d-m-none">
                    <div className="container">
                        <div className="row p-8">
                            <div className="col-md-12">
                                <h1 className="mt-5  mb-3 main-title">{props.t("Internship")}</h1>
                            </div>
                        </div>
                    </div>
                </section>
            </>

            <section className="dashboard top-curve-profile">
                <div className="container">
                    <div className="row p-8">
                        <ProfileSidebar />
                        <div className="col-md-10">
                            <div className="dashboard-inner">
                                <div className="d-flex justify-content-between align-items-center mb-4 mob-block">
                                    <div>
                                        <h3 className="title">
                                            {props.t("Annual-Work-Schedule")}
                                            <span className="text-primary"> {thaiYear}</span>
                                        </h3>
                                    </div>
                                    <div className="d-flex in-border">
                                        <div>
                                            <span>{props.t("Type")}</span>
                                            <h5>{internship?.title_en}</h5>
                                        </div>
                                        <div className="brd-left">
                                            <span>{props.t("Institution")}</span>
                                            <h5>{internship?.institute?.institution_name}</h5>
                                        </div>
                                    </div>
                                </div>
                                {internshipTasks ? (
                                        <InternshipTask internship={internship} months={mothList} task={internshipTasks} count={maxTaskCount} />

                                 ) : 
                                        <div className="subtitle text-center">{props.t('No-Record-Found')}</div>
                                }
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default withTranslation()(Internship)
