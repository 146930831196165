import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Navbar from "../../components/Layout/Navbar";
import { BookingApi } from "../../apis/BookingApi";
import config from "../../config";
import { withTranslation } from "react-i18next";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import ProfileSidebar from "../../components/Layout/ProfileSidebar";
import { getUser } from "../../Token";
import { ProfileAPI } from "apis/ProfileAPI";
import { HomeApi } from "apis/HomeApi";
import { EducationApi } from "apis/EducationApi";
import validator from "validator";
import _ from "lodash";
import { CommanApi } from "apis/CommanApi";
import Select from "react-select";
import { useDispatch } from "react-redux";
import constants from "../../constants";
import { getRoleID } from "../../Token";

function Profile(props) {
  const user = getUser();
  const [userInterests, setUserInterests] = useState([]);
  const [interests, setInterests] = useState([]);
  const [specialistArr, setSpecialistArr] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [userLineID, setUserLineID] = useState("");
  const [profilePic, setProfilePic] = useState("assets/images/profile.png");
  const [userMobile, setUserMobile] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [isUserIntern, setIsUserIntern] = useState(false);
  const [specialistID, setSpecialistID] = useState("");
  const [specialistName, setSpecialistName] = useState("");
  const [validateError, setValidateError] = React.useState({
    email: "",
    lineid: "",
  });
  const [userInt, setUserInt] = useState([]);
  const [userSpecialities, setUserSpecialities] = useState([]);
  const [userSpecialitiesViewData, setUserSpecialitiesViewData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    checkUserDetailsAndInterest();
  }, []);

  const checkUserDetailsAndInterest = () => {
    HomeApi.checkUserDetailsAndInterestAPI()
      .then((res) => {
        if (res.data.success) {
          setUserEmail(res.data.user.email ? res.data.user.email : "");
          setUserLineID(res.data.user.line_id ? res.data.user.line_id : "");
          setUserMobile(res.data.user.mobile ? res.data.user.mobile : "");
          setUniqueId(res.data.user.unique_id ? res.data.user.unique_id : "");
          setProfilePic(
            res.data.user
              ? res.data.user.profile_image
              : "assets/images/profile.png"
          );
          setSpecialistID(
            res.data.user.Specialist ? res.data.user.Specialist.id : ""
          );
          setSpecialistName(
            res.data.user.Specialist ? res.data.user.Specialist.name_en : "-"
          );
          setUserInterests(
            res.data.userInt === undefined ? [] : res.data.userInt
          );
          let userIntArr = [];
          res.data.userInt !== undefined &&
            res.data.userInt.forEach((element, i) => {
              userIntArr.push(element.interest_id);
            });
          setUserInt(userIntArr);

          setUserSpecialitiesViewData(
            res.data.userSpecialties === undefined
              ? []
              : res.data.userSpecialties
          );
          let userSpecialitiesArr = [];
          let specialtiesArr = [];
          res.data.userSpecialties !== undefined &&
            res.data.userSpecialties.forEach((element, i) => {
              userSpecialitiesArr.push({
                label: element.Specialties.name_en,
                value: element.Specialties.id,
              });
              specialtiesArr.push(element.Specialties.id);
            });
          setUserSpecialities(specialtiesArr);
          setSelectedSpecialities(userSpecialitiesArr);
          setIsUserIntern(res.data.user.is_intern ? true : false);
        } else {
          setUserInterests([]);
          setUserSpecialities([]);
          setSelectedSpecialities([]);
          setIsUserIntern(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInterest = () => {
    EducationApi.getInterests()
      .then((res) => {
        if (res.data.success) {
          setInterests(res.data.data);
          setSpecialistArr(res.data.data);
        } else {
          setInterests([]);
          setSpecialistArr([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getSpecialist = () => {
  //   CommanApi.getSpecialistAPI()
  //     .then((res) => {
  //       if (res.data.success) {
  //         setSpecialistArr(res.data.data);
  //       } else {
  //         setSpecialistArr([]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const onRemoveUserInterest = (id) => {
    ProfileAPI.removeUserInterestAPI(id)
      .then((res) => {
        if (res.data.success) {
          const userInterestsEdit = userInterests;
          const filterUserInterest = userInterestsEdit.filter(function (item) {
            return item.id !== id;
          });
          setUserInterests(filterUserInterest);
        } else {
          setUserInterests([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const inputChange = (e) => {
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      var value = e.target.value;
      switch (e.target.name) {
        case "email":
          setUserEmail(value);
          break;
        case "lineid":
          setUserLineID(value);
          break;
        case "mobile":
          setUserMobile(value);
          break;
        case "unique_id":
          setUniqueId(value);
          break;
        case "specialist_id":
          setSpecialistID(value);
          break;
        default:
        // code block
      }
    }
  };

  const onInterestHandler = async (e, interestID) => {
    const userInterestsCopy = _.cloneDeep(userInt);
    if (e.target.checked) {
      const interestIndex = userInterestsCopy.indexOf(interestID);
      userInterestsCopy.splice(interestIndex, 1);
    } else {
      userInterestsCopy.push(interestID);
    }
    setUserInt(userInterestsCopy);
  };

  const onSaveUserInformation = async () => {
    var errors = await userInformationValidation(true);
    var errorsSize = Object.keys(errors).length;
    if (errorsSize != 0) {
      return false;
    }

    const payload = {
      email: userEmail,
      lineID: userLineID,
      mobile: userMobile,
      unique_id: uniqueId,
      interests: userInt,
      specialities: userSpecialities,
    };
    HomeApi.addUpdateUserInformationAPI(payload)
      .then((res) => {
        if (res.data.success) {
          checkUserDetailsAndInterest();
          window.$("#more-information").modal("hide");
          return true;
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userInformationValidation = async (check) => {
    if (check) {
      var ValidateError = {};
      if (userEmail === "") {
        ValidateError.email = props.t("Email-Required-Messages");
      }

      if (!validator.isEmail(userEmail) && userEmail !== "") {
        ValidateError.email = props.t("Valid-Email-Message");
      }

      // if (userLineID === "") {
      //   ValidateError.lineid = props.t("Line-Id-Required-Messages");
      // }

      if (userMobile === "") {
        ValidateError.userMobile = props.t("Phone-Number-Messages");
      }

      // if (specialistID === "") {
      //   ValidateError.specialistID = props.t("Specialist-Required-Messages");
      // }
      if (userSpecialities.length === 0) {
        ValidateError.specialistID = props.t("Specialist-Required-Messages");
      }

      if (uniqueId !== "" && uniqueId.length !== 13) {
        ValidateError.uniqueId = props.t("Digit-ID-Limit-Messages");
      }

      if (userInt.length === 0) {
        ValidateError.interests = props.t("Min-Interest-Messages");
      }
      if (userInt.length > 3) {
        ValidateError.interests = props.t("Mix-Interest-Messages");
      }

      setValidateError(ValidateError);
      return ValidateError;
    } else {
      return {};
    }
  };

  const changeProfilePictureHandler = (e) => {
    let validExtensions = ["png", "jpg", "jpeg"]; //array of valid extensions
    if (e.target && e.target.files) {
      let reader = new FileReader();
      let file = e.target.files[0];
      const data = new FormData();
      data.append("profile_pic", file);
      ProfileAPI.changeUpdateProfilePictureAPI(data)
        .then((res) => {
          if (res.data.success) {
            let url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
              if (reader.result) {
                // setTokenFile(reader.result);
                window.$("#userProfilePic").attr("src", e.target.result);
                dispatch({
                  type: "ADD_USER_PROFILE_PIC",
                  payload: config.IMAGE_URL + res.data.path,
                });
              }
            }.bind(this);

            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onEditProfileDetailsHandler = () => {
    getInterest();
    // getSpecialist();
    window.$("#more-information").modal("show");
  };

  const [selectedSpecialities, setSelectedSpecialities] = useState();
  function handleSelect(data) {
    let userSpecialitiesArr = [];
    data.map((item, index) => {
      userSpecialitiesArr.push(item.value);
    });
    setUserSpecialities(userSpecialitiesArr);
    setSelectedSpecialities(data);
  }

  return (
    <div>
      <Navbar />
      <MobileBottomMenu />
      <>
        <div className="mobile-top d-none">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                  <NavLink to="/">
                    <img
                      src="assets/images/768/arrow-left-w.png"
                      className=""
                      alt=""
                    />
                  </NavLink>
                </div>
                <div>
                  <h4 className="mb-0">{props.t("My-Profile")}</h4>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View Top End */}
        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row p-8">
              <div className="col-md-12">
                <h1 className="mt-5 mb-3 main-title">{props.t("Profile")}</h1>
              </div>
            </div>
          </div>
        </section>
      </>

      <section className="dashboard top-curve-profile profileTop profileCardMobHeight" >
        <div className="container">
          <div className="row p-8">
            <ProfileSidebar />
            <div className="col-md-10">
              <div className="dashboard-inner border-middle">
                <div className="row">
                  <div className="profile d-flex">
                    <div className="profile-img">
                      {/* <img
                        src="assets/images/profile.png"
                        className=""
                        id="userProfilePic"
                        alt=""
                        width=""
                        height=""
                      /> */}
                      {/* <input
                      type="file"
                      id="photo"
                      required={true}
                      name="image"
                      accept="image/png, image/gif, image/jpeg, image/webp"
                      onChange={changeProfilePictureHandler}
                    /> */}
                      <div className="p-relative">
                        <img
                          src={
                            profilePic
                              ? profilePic
                              : require("assets/images/profile.png")
                          }
                          className="profile-image"
                          id="userProfilePic"
                          alt=""
                          width=""
                          height=""
                        />
                        <div className="profile-btn">
                          <img
                            src="assets/images/edit.png"
                            className=""
                            alt=""
                            width=""
                            height=""
                          />
                          <input
                            type="file"
                            name="profile_pic"
                            accept="image/png, image/gif, image/jpeg, image/webp"
                            onChange={changeProfilePictureHandler}
                          />
                        </div>
                      </div>
                      {/* <div className="token text-left">
                        <h5 className="mb-0">1,695</h5>
                        <small>Token</small>
                      </div> */}
                    </div>
                    <div className="profile-content list-style-none" style={{ width: "100%" }}>
                      <ul className="mb-5 pb-3 list">
                        <li>
                          <small>{user?.license_number}</small>
                          <br />
                          <strong>
                            {user?.first_name} {user?.last_name}{" "}
                          </strong>
                        </li>
                      </ul>
                      <ul className="c-list my-2">
                        <li>
                          <small>{props.t("Email")}</small>
                          <br />
                          <strong>{userEmail ? userEmail : "-"}</strong>
                        </li>
                        <li>
                          <small>{props.t("Line-ID")}</small>
                          <br />
                          <strong>{userLineID ? userLineID : "-"}</strong>
                        </li>
                      </ul>
                      <ul className="c-list my-2">
                        <li>
                          <small>{props.t("phone_number")}</small>
                          <br />
                          <strong>{userMobile ? userMobile : "-"}</strong>
                        </li>
                        {/* <li>
                          <small>{props.t("unique_id")}</small>
                          <br />
                          <strong>{uniqueId ? uniqueId : "-"}</strong>
                        </li> */}
                      </ul>
                      {/* <ul className="c-list my-2">
                        <li>
                          <small>{props.t("specialist")}</small>
                          <br />
                          <strong>{specialistName ? specialistName : "-"}</strong>
                        </li>
                      </ul> */}

                      <div className="specialiry mt-3 mob-mt-0">
                        <h5 className="mb-3">{props.t("Specialities")}</h5>
                        <ul className="s-profile-list hidescroll">
                          {userSpecialitiesViewData.length > 0 ? (
                            userSpecialitiesViewData.map((item, i) => {
                              return (
                                <li key={`uinterst_${i}`}>
                                  <a className="" >
                                    {item?.Specialties?.name_en}
                                  </a>
                                </li>
                              );
                            })
                          ) : (
                            <span>{props.t("No-Specialties-Found")}</span>
                          )}
                        </ul>
                      </div>

                      <div className="specialiry mt-3 mob-mt-0">
                        <h5 className="mb-3">{props.t("Interest")}</h5>
                        <ul className="s-profile-list hidescroll">
                          {userInterests.length > 0 ? (
                            userInterests.map((interest, i) => {
                              return (
                                <li key={`uinterst_${i}`}>
                                  <a
                                    // href="javascript:void(0);"
                                    className=""
                                    // onClick={() =>
                                    //   onRemoveUserInterest(interest.id)
                                    // }
                                  >
                                    {interest?.Interest?.name_en}
                                  </a>
                                </li>
                              );
                            })
                          ) : (
                            <span>{props.t("No-Interest-Found")}</span>
                          )}
                        </ul>
                      </div>

                      <div className="p-menu-list mob-mb-50 d-none">
                        <NavLink
                          to="/mycalendar"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <img
                              src="assets/images/768/calendar.png"
                              alt=""
                              width="20"
                              height="20"
                            />
                            <strong>{props.t("My-Calendar")}</strong>
                          </div>
                          <div>
                            <img
                              src="assets/images/768/right-blue-arrow.png"
                              alt=""
                              width=""
                              height=""
                            />
                          </div>
                        </NavLink>

                        {constants.ROLE.COMPANY !== getRoleID() ? (
                        <NavLink
                          to="/my-activity"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <img
                              src="assets/images/768/Vector.png"
                              alt=""
                              width=""
                              height=""
                            />
                            <strong>{props.t("My-Activity")}</strong>
                          </div>
                          <div>
                            <img
                              src="assets/images/768/right-blue-arrow.png"
                              alt=""
                              width=""
                              height=""
                            />
                          </div>
                        </NavLink>
                        ) : (<></> ) }
                        <NavLink
                          to="/booking"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <img
                              src="assets/images/768/book.png"
                              alt=""
                              width=""
                              height=""
                            />
                            <strong>{props.t("My-Booking")}</strong>
                          </div>
                          <div>
                            <img
                              src="assets/images/768/right-blue-arrow.png"
                              alt=""
                              width=""
                              height=""
                            />
                          </div>
                        </NavLink>
                        {isUserIntern ? (
                          <NavLink
                            to="/my-leaves"
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div>
                              <img
                                src="assets/images/768/calendar.png"
                                alt=""
                                width="20"
                                height="20"
                              />
                              <strong>{props.t("My-Leaves")}</strong>
                            </div>
                            <div>
                              <img
                                src="assets/images/768/right-blue-arrow.png"
                                alt=""
                              />
                            </div>
                          </NavLink>
                          ) : ""
                        }
                        
                        <NavLink
                          to="/internship"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <img
                              src="assets/images/768/internship.png"
                              alt=""
                              width="20"
                            />
                            <strong>{props.t("Internship")}</strong>
                          </div>
                          <div>
                            <img
                              src="assets/images/768/right-blue-arrow.png"
                              alt=""
                            />
                          </div>
                        </NavLink>
                        <NavLink
                          to="/bookmark"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <img
                              src="assets/images/768/bookmark.png"
                              alt=""
                              width=""
                              height="20"
                            />
                            <strong>{props.t("Bookmark")}</strong>
                          </div>
                          <div>
                            <img
                              src="assets/images/768/right-blue-arrow.png"
                              alt=""
                              width=""
                              height=""
                            />
                          </div>
                        </NavLink>
                        <NavLink
                          to="/logout"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <img
                              src="assets/images/768/logout.png"
                              alt=""
                              width=""
                              height=""
                            />
                            <strong>{props.t("Log-Out")}</strong>
                          </div>
                          <div>
                            <img
                              src="assets/images/768/right-blue-arrow.png"
                              alt=""
                              width=""
                              height=""
                            />
                          </div>
                        </NavLink>
                      </div>
                    </div>
                    {constants.ROLE.COMPANY !== getRoleID() && (
                      <div className="editPrfoileInfo">
                        <a
                          href="#"
                          onClick={onEditProfileDetailsHandler}
                          title="Edit"
                        >
                          <img
                            src={require("../../assets/images/pencil-icon.png")}
                            width={15}
                            height={15}
                          />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* more information modal */}
      <div className="modal fade in" id="more-information">
        <div className="modal-dialog scrollDialog modal-lg">
          <div className="modal-content mb-5">
            <div className="modal-header">
              <h4 className="modal-title">
                {props.t("Edit")} {props.t("Information")}
                {/* <a href="" className="close d-none" data-dismiss="modal">
                  <img
                    src={require("../assets/images/768/x-circle.png")}
                    className=""
                    alt=""
                    height=""
                    width=""
                  />
                </a> */}
              </h4>
            </div>

            <div className="modal-body scrollAndFixedHeight">
              <h5>
                {props.t("Contact")} {props.t("Information")}{" "}
              </h5>
              <form>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <label htmlFor="email" className="form-label">
                      {props.t("Email")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder={props.t("Email")}
                      name="email"
                      value={userEmail || ""}
                      onChange={inputChange}
                    />
                    {validateError.email && (
                      <span className="text-danger">{validateError.email}</span>
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="lineid" className="form-label">
                      {props.t("Line-ID")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lineid"
                      placeholder={props.t("Line-ID")}
                      name="lineid"
                      value={userLineID || ""}
                      onChange={inputChange}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="lineid" className="form-label">
                      {props.t("phone_number")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      placeholder={props.t("phone_number")}
                      name="mobile"
                      value={userMobile || ""}
                      onChange={inputChange}
                    />
                    {validateError.userMobile && (
                      <span className="text-danger">
                        {validateError.userMobile}
                      </span>
                    )}
                  </div>
                  {/* <div className="col-md-6 mb-2">
                    <label htmlFor="lineid" className="form-label">
                      {props.t("unique_id")}{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="unique_id"
                      placeholder={props.t("unique_id")}
                      name="unique_id"
                      value={uniqueId || ""}
                      onChange={inputChange}
                    />
                    {validateError.uniqueId && (
                      <span className="text-danger">
                        {validateError.uniqueId}
                      </span>
                    )}
                  </div> */}
                  {/* <div className="col-md-6 mb-2">
                      <label htmlFor="" className="form-label">
                        {props.t("select_specialist")}{" "}
                          <span className="text-danger">*</span>
                      </label>
                      <select className="form-control" onChange={inputChange} name="specialist_id" placeholder="Select specialist" value={specialistID}>
                        <option>--Select Specialist--</option>
                        {specialistArr.map((specialist, i) => {
                            return (<option value={specialist.id} >{specialist.name_en}</option>);
                        })}
                      </select>
                      {validateError.specialistID && (
                      <span className="text-danger">
                        {validateError.specialistID}
                      </span>
                    )}
                  </div> */}
                  <div className="col-md-12 mb-2">
                    <label htmlFor="" className="form-label">
                      {props.t("select_specialist")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={specialistArr.map((value) => ({
                          value: value.id,
                          label: `${value.name_en}`,
                        }))}
                        placeholder={props.t("select_specialist")}
                        value={selectedSpecialities}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                        // className="multi-select"
                      />
                    </div>
                    {validateError.specialistID && (
                      <span className="text-danger">
                        {validateError.specialistID}
                      </span>
                    )}
                  </div>
                </div>
              </form>
              <h5>{props.t("Interest")}</h5>
              {validateError.interests && (
                <span className="text-danger">{validateError.interests}</span>
              )}
              <div className="row userinterestscroll">
                {interests.length > 0
                  ? interests.map((interest, i) => {
                      let isChecked = false;
                      userInt.forEach((intrst, i) => {
                        if (intrst === interest.id) {
                          isChecked = true;
                        }
                      });
                      return (
                        <div
                          className="col-md-6 col-xs-12 col-12 userinterest-inner"
                          key={`interest_${i}`}
                        >
                          <input
                            type="checkbox"
                            onMouseUp={(e) => onInterestHandler(e, interest.id)}
                            id="intrest"
                            name="intrest"
                            checked={isChecked}
                          />{" "}
                          {interest.name_en}
                        </div>
                      );
                    })
                  : props.t("No-Post-Found")}
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="float-right btn btn-blue"
                onClick={onSaveUserInformation}
              >
                {props.t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Profile);
