import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";
export const HomeApi = {
  getBanner: async (data) => {
    const axiosConfig = {
      method: "GET",
      url: `${config.API_URL}${url.GET_BANNER}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },

  getBanners: async (data) => {
    const axiosConfig = {
      method: "POST",
      url: `${config.API_URL}${url.GET_ADVERTISEMENT_BANNER}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },

  getUpcomingActivities: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_UPCOMING_ACTIVITIES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getActivityAboutToCloseAPI: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_ABOUT_TO_CLOSE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getNews: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_NEWS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  checkUserDetailsAndInterestAPI: async () => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.CHECK_USER_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(axiosConfig);
  },
  addUpdateUserInformationAPI: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ADD_UPDATE_USER_INFO}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(axiosConfig);
  },
  getAllLiveActivities: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_LIVE_ACTIVITIES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
};
