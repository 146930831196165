import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import config from "../../../config";
import Activity from "./Activity";

const EducationActivitySection = (props) => {
  return (
    <section className={`py-5 events ${props.background} mob-pt-0 d-m-none`}>
      <div className="container p-0">
        <div className="row">
          <div className="col-md-12 px-30 d-m-flex">
            <h1 className="main-title d-m-none">{props.title}</h1>
            {props.activity.length > 0 && (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="subtitle d-m-none"></div>
                  <div>
                    <Link
                      to={
                        props.section === "articles"
                          ? `education-activities/ARTICLE`
                          : `education-activities/${props.section}`
                      }
                      className="view-text"
                    >
                      <span className="view-m">{props.t("See-All")}</span>
                      <span className="see-m">{props.t("See-All")}</span>{" "}
                      <img
                        src={config.BASE_URL + "/assets/images/right-arrow.png"}
                        alt="icon"
                        className="ml-2"
                        width={18}
                        height={18}
                      />
                    </Link>
                  </div>
                </div>
              </>
            )}
            {props.activity.length !== 0 ? (
              <div className="events-listing mt-4 d-m-none justify-content-between">
                {props.activity?.map((activity, index) => {
                  return (
                    <Activity
                      activity={activity}
                      key={`activity_${index}_${activity.id}`}
                      forSection={props.section}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="subtitle text-center d-m-none">
                {props.noDataFoundText}
              </div>
            )}
            {/* Desktop activities End */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation()(EducationActivitySection);
