import { ProfileAPI } from "apis/ProfileAPI";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { getUser, isLoggedIn, isRestricted } from "../../Token";
import config from "../../config";
import LanguageDropdown from "../Layout/LanguageDropdown";

function Navbar(props) {
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  const user = getUser();

  const [notifications, setNotifications] = useState([]);

  const getNotifications = () => {
    let payload = { page: 1, limit: 3 };
    ProfileAPI.getNotifications(payload)
      .then((res) => {
        if (res.data.success) {
          setNotifications(res.data);
        } else {
          setNotifications([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeNotificationStatus = (id, path) => {
    ProfileAPI.updateNotificationStatus(id)
      .then((res) => {
        if (res.data.success) {
          // const notificationsData = notifications;
          // const filterNotification = notificationsData.filter(function (item) {
          //   return item.id !== id;
          // });
          // setNotifications(filterNotification);
          if(path){
            navigate(`/${path}`); 
          }
          getNotifications();
        } else {
          setNotifications([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setMenuActive();
    getNotifications();
  }, []);

  const setMenuActive = () => {
    var section = window.location.pathname.split("/").slice(0, 2).join("");
    setActive(section);
  };

  const userReduxState = useSelector((state) => state.profile_image);

  return (
    <div>
      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <div className="container">
          <NavLink to="/" className="navbar-brand nlogo">
            <img
              src={config.BASE_URL + "/assets/images/logo/svg/logo.svg"}
              alt="logo"
            />
          </NavLink>
          <NavLink to="/" className="navbar-brand slogo">
            <img
              src={
                config.BASE_URL + "/assets/images/logo/svg/logo-with-tagline.svg"
              }
              alt="logo"
            />
            {/* <img src={config.BASE_URL + '/assets/images/logo/medx2.png'} alt="tagline" /> */}
          </NavLink>
          <button
            type="button"
            className="navbar-toggler me-4"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div
              className={`navbar-nav ms-auto p-4  p-lg-0 restricted_${isRestricted()}`}
            >
              {isLoggedIn() ? (
                <>
                  {/* <a href="index.html" className="nav-item nav-link active">Home</a> */}
                  <NavLink
                    className={`nav-item nav-link ${
                      active == "" ? "active" : ""
                    }`}
                    to="/"
                  >
                    {props.t("Home")}
                  </NavLink>
                  <NavLink
                    className={`nav-item nav-link ${
                      active == "education" ? "active" : ""
                    }`}
                    to="/education"
                  >
                    {props.t("Education")}
                  </NavLink>
                  <NavLink
                    className={`nav-item nav-link ${
                      active == "community" ? "active" : ""
                    }`}
                    to="/community"
                  >
                    {props.t("Community")}
                  </NavLink>
                  <NavLink
                    className={`nav-item nav-link ${
                      active == "offers" ? "active" : ""
                    }`}
                    to="/offers"
                  >
                    {props.t("SpecialOffer")}
                  </NavLink>
                  {/* <NavLink className={`nav-item nav-link ${active == "bookmark" ? "active" : ""}`}  to="/bookmark">Bookmark</NavLink> */}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="d-flex float-right menu-right align-items-center">
              {isLoggedIn() ? (
                <>
                  <div className="nav-item dropdown">
                    <NavLink
                      to="/notifications"
                      className="nav-link dropdown-toggle"
                    >
                      {notifications?.notifications?.count > 0 && (
                        <span className="notificationCaunt">
                          {notifications?.notifications?.count}
                        </span>
                      )}
                      <img
                        src={config.BASE_URL + "/assets/images/bell.png"}
                        alt="bell"
                        width="17"
                        className=""
                      />
                      {/* </a> */}
                    </NavLink>
                    <div className="dropdown-menu notification-dropdown bg-light m-0">
                      <div className="card">
                        <div className="card-header">
                          <h4>{props.t("Notifications")}</h4>
                          <p>
                            {props.t("You-have")}{" "}
                            <span className="text-primary">
                              {" "}
                              {notifications?.notifications
                                ? notifications?.notifications?.count
                                : 0}
                            </span>{" "}
                            {props.t("Notifications")}
                          </p>
                        </div>
                        <div className="card-body">
                          <div className="notification-list">
                            {notifications?.notifications?.rows.length > 0 ? (
                              notifications?.notifications?.rows.map(
                                (item, i) => {
                                  return (
                                    <a
                                      href="#"
                                      key={`navbar_${i}`}
                                      className="border-bottom-n"
                                      onClick={() =>
                                        onChangeNotificationStatus(
                                          item?.id,
                                          item.link_path
                                        )
                                      }
                                    >
                                      <div className="d-flex p-relative">
                                        <div>
                                          <img
                                            src={
                                              item?.FromUser?.profile_image
                                                ? item?.FromUser?.profile_image
                                                : config.BASE_URL +
                                                  "/assets/images/no-preview.png"
                                            }
                                            className="rounded"
                                            alt=""
                                            width="35"
                                            height="35" 
                                          />
                                        </div>
                                        <div className="ml-1 mx-2">
                                          <h6 className="mb-0">
                                            {/* <strong className="text-primary">{item?.title}</strong> <br /> */}
                                            <strong className="text-primary">
                                            {item?.FromUser?.first_name} 
                                            {item?.FromUser?.last_name ? item?.FromUser?.last_name : ""}
                                            </strong>{" "}
                                            <br />
                                            {item?.message}
                                          </h6>
                                          <span className="time">
                                            <ReactTimeAgo
                                              date={item?.created_at}
                                              locale="en-US"
                                              timeStyle="round"
                                            />
                                          </span>
                                        </div>
                                        <div className="status"></div>
                                      </div>
                                    </a>
                                  );
                                }
                              )
                            ) : (
                              <span>
                                {props.t("No-Notifications-Available")}
                              </span>
                            )}
                          </div>
                        </div>
                        {notifications?.notifications?.count > 3 && (
                          <div className="card-footer">
                            <NavLink to="/notifications">
                              {props.t("View-More")}
                            </NavLink>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <LanguageDropdown />

              {!isLoggedIn() ? (
                <>
                  <a href={config.BASE_URL + "login"} className="btn btn-login">
                    {props.t("Log-In")}
                  </a>
                </>
              ) : (
                <>
                  <div className="nav-item dropdown">
                    <a
                      href="#"
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <img
                        src={
                          userReduxState.profile_image
                            ? userReduxState.profile_image
                            : require("assets/images/profile.png")
                        }
                        className="nav-avtar"
                        alt="user-icon"
                      />
                      {` ${user.first_name}`}
                      <img
                        src={config.BASE_URL + "/assets/images/angle-down.png"}
                        className="arrow-down"
                        alt="arrow"
                        width="9"
                        height=""
                      />
                    </a>
                    <div className="dropdown-menu bg-light m-0">
                      <NavLink
                        to="/profile"
                        className={`dropdown-item restricted_${isRestricted()}`}
                      >
                        {props.t("Profile")}
                      </NavLink>
                      <NavLink to="/logout" className="dropdown-item">
                        {props.t("Log-Out")}
                      </NavLink>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </div>
  );
}
export default withTranslation()(Navbar);
