import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthApi } from "../apis/AuthApi";
import Navbar from "../components/Layout/Navbar";
import config from "../config";
import { Token } from "../Token";

function TMCCallback() {
  const search = useLocation().search;

  useEffect(() => {
    const data = {
      code: new URLSearchParams(search).get("code"),
      state: new URLSearchParams(search).get("state"),
      client_id: config.CLIENT_ID,
      redirect_uri: `${config.BASE_URL}tmc-callback`,
    };

    AuthApi.doctorLogin(data)
      .then((res) => {
        Token.saveToken(res.data.user, res.data.token);
        window.location.href = "/";
        // console.log("success", res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div>
      <Navbar />
      <section className="pb-5 application">
        <div className="container">
          <div className="row row2">
            <div className="col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-4 mob-mt-30">
                <h1 className="mb-0">Wait 5 seconds...</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TMCCallback;
