import _ from "lodash";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import Select from "react-select";
import { CustomActivityApi } from "../../apis/CustomActivityApi";
import { EducationApi } from "../../apis/EducationApi";
import { HomeApi } from "../../apis/HomeApi";
import Footer from "../../components/Layout/Footer";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import MobileHeader from "../../components/Layout/Mobile/MobileHeader";
import Navbar from "../../components/Layout/Navbar";
import config from "../../config";
import Advertise from "../Components/Advertise";
import Activity from "./components/Activity";
import EducationActivitySection from "./components/EducationActivitySection";
import MobileActivity from "./components/MobileActivity";

const options = {
  responsiveClass: true,
  dots: false,
  autoplay: false,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 6,
    },
    700: {
      items: 6,
    },
    1000: {
      items: 6,
    },
  },
};

const Education = (props) => {
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 4,
    keyword: "",
    institute_id: "All",
    interest_id: "All",
  });
  const [activeLibraryData, setActiveLibraryData] = useState({
    page: 1,
    limit: 3,
    keyword: "",
    institute_id: "All",
    interest_id: "All",
  });
  const [cmeactivities, setCMEActivitiesData] = useState([]);
  const [cpdactivities, setCPDActivitiesData] = useState([]);
  const [articles, setArticles] = useState([]);
  const [cmelibraries, setCMELibrariesData] = useState([]);
  const [cpdlibraries, setCPDLibrariesData] = useState([]);
  const [mactivities, setMActivitiesData] = useState([]);
  const [mlibraries, setMLibrariesData] = useState([]);
  const [mTab, setMTabData] = useState("CME");
  const [interests, setInterests] = useState([]);
  const [libraries, setLibrariesData] = useState([]);
  const [interestActive, setInterestActive] = useState("All");
  const [interestPosition, setInterestPosition] = useState(0);
  const [keywordActive, setKeywordActive] = useState("");
  const [instituteActive, setInstituteActive] = useState("All");
  const [activeInstitutesData, setActiveInstitutesData] = useState([]);
  const [isCMEActive, setCMEActive] = useState("active");
  const [isCPDActive, setCPDActive] = useState("");
  const [isArticleActive, setArticleActive] = useState("");
  const [isMultimediaActive, setIsMultimediaActive] = useState("");

  const [topBanners, setTopBanners] = useState({
    page_name: "Education",
    type: "Banner",
    position: "Top",
  });
  const [banners, setBanners] = useState([]);
  const [bannersCount, setBannersCount] = useState(0);

  const [bottomLeftBanner, setBottomLeftBanner] = useState({
    page_name: "Education",
    type: "Banner",
    position: "BottomLeft",
  });
  const [bottomLeftbanners, setBottomLeftBanners] = useState([]);
  const [bottomLeftbannersCount, setBottomLeftBannersCount] = useState(0);

  const [bottomRightBanner, setBottomRightBanner] = useState({
    page_name: "Education",
    type: "Banner",
    position: "BottomRight",
  });
  const [bottomRightbanners, setBottomRightBanners] = useState([]);
  const [bottomRightbannersCount, setBottomRightBannersCount] = useState(0);
  const [sliderInterval, setsliderInterval] = useState(10000);

  const [selectedInstitute, setSelectedInstitute] = useState();
  const [institutesData, setinstitutesData] = useState([]);
  const [institutesPaginateData, setinstitutesPaginateData] = useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  });
  const [isMoreInstitutes, setIsMoreInstitutes] = useState(true);
  const [isInstituteLoading, setIsInstituteLoading] = useState(true);
  const [multimedia, setMultimedia] = useState([]);

  const [autoSearch, setAutoSearchData] = useState([]);

  useEffect(() => {
    getEducationActivities(activeData, mTab);
    //getEducationLibraries(activeLibraryData, mTab);
    getInterests();
    getBanners(topBanners);
    getBottomRightBanners(bottomRightBanner);
    getBottomLeftBanners(bottomLeftBanner);
  }, []);

  const onScrollInstituesDataHandler = _.debounce(async function () {
    if (isMoreInstitutes) {
      setIsInstituteLoading(true);
      getAllInstitutesPaginate();
    }
  }, 500);

  const onSearchInstitutesHandler = _.debounce(async function (searchInput) {
    setinstitutesPaginateData({
      ...institutesPaginateData,
      page: 1,
      search: searchInput,
    });
    if (searchInput.length >= 3) getAllInstitutesPaginate(searchInput);
  }, 500);

  const onOpenInstituteMenuHandler = () => {
    setinstitutesPaginateData({
      ...institutesPaginateData,
      page: 1,
    });
    getAllInstitutesPaginate();
  };

  const getAllInstitutesPaginate = (searchKeyword = "") => {
    if (searchKeyword !== "") {
      institutesPaginateData.search = searchKeyword;
    }
    CustomActivityApi.getAllInstitutesApi(institutesPaginateData)
      .then((res) => {
        if (res.data.success) {
          const pageNo = institutesPaginateData.page;
          setinstitutesPaginateData({
            ...institutesPaginateData,
            totalRecords: res.data.data.count,
            page: res.data.data.metadata.page,
            limit: res.data.data.metadata.limit,
          });
          if (pageNo === 1) {
            setinstitutesData([...res.data.data.rows]);
          } else {
            if (res.data.data.rows.length === 0) {
              setIsMoreInstitutes(false);
              setIsInstituteLoading(false);
            }
            setinstitutesData([...institutesData, ...res.data.data.rows]);
          }
        } else {
          setinstitutesData([]);
        }
      })
      .catch((err) => {
        setinstitutesData([]);
      });
  };

  const getInterests = () => {
    EducationApi.getInterests()
      .then((res) => {
        if (res.data.success) {
          setInterests(res.data.data);
        } else {
          setInterests([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEducationActivities = async (data, activeTab) => {
    EducationApi.getEducationActivities(data)
      .then((res) => {
        if (res.data.success) {
          setCMEActivitiesData(res.data.data.cmeactivities.rows);
          setCPDActivitiesData(res.data.data.cpdactivities.rows);
          setArticles(res.data.data.articles.rows);
          setMultimedia(res.data.data.multimedia.rows);
          return true;
        } else {
          setCMEActivitiesData([]);
          setCPDActivitiesData([]);
          setArticles([]);
          setMultimedia([]);
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  const getEducationLibraries = (data, activeTab) => {
    EducationApi.getEducationLibraries(data)
      .then((res) => {
        if (res.data.success) {
          setCMELibrariesData(res.data.data.cmelibraries.rows);
          setCPDLibrariesData(res.data.data.cpdlibraries.rows);
          if (activeTab == "CME") {
            setMLibrariesData(res.data.data.cmelibraries.rows);
          } else if (activeTab == "CPD") {
            setMLibrariesData(res.data.data.cpdlibraries.rows);
          } else {
            setMLibrariesData([]);
          }
        } else {
          setCMELibrariesData([]);
          setCPDLibrariesData([]);
          setMLibrariesData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mTabActive = (value) => {
    setMTabData(value);
    setCMEActive(value === "CME" ? "active" : "");
    setCPDActive(value === "CPD" ? "active" : "");
    setArticleActive(value === "ARTICLE" ? "active" : "");
    setIsMultimediaActive(value === "Multimedia" ? "active" : "");
  };

  const handleClickTabActive = (value, position) => {
    setInterestPosition(position);
    setInterestActive(value);
    activeData.interest_id = value;
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      keyword: keywordActive,
      institute_id: instituteActive,
      interest_id: value,
    });
    getEducationActivities(activeData, mTab);
  };

  const handleClickInstitute = (value) => {
    setInstituteActive(value);
    activeData.institute_id = value;
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      keyword: keywordActive,
      institute_id: value,
      interest_id: interestActive,
    });
    getEducationActivities(activeData, mTab);
  };

  const handleKeywordActive = (value) => {
    setKeywordActive(value);
    activeData.keyword = value;
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      keyword: value,
      institute_id: instituteActive,
      interest_id: interestActive,
    });
    getEducationActivities(activeData);
  };

  const getBanners = (data) => {
    HomeApi.getBanners(data)
      .then((res) => {
        if (res.data.success) {
          setBanners(res.data.data.banners);
          setBannersCount(res.data.data.banners.length);
          setsliderInterval(res.data.data.speed_time_interval);
        } else {
          setBanners([]);
          setBannersCount(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBottomRightBanners = (data) => {
    HomeApi.getBanners(data)
      .then((res) => {
        if (res.data.success) {
          setBottomRightBanners(res.data.data.banners);
          setBottomRightBannersCount(res.data.data.banners.length);
          setsliderInterval(res.data.data.speed_time_interval);
        } else {
          setBottomRightBanners([]);
          setBottomRightBannersCount(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBottomLeftBanners = (data) => {
    HomeApi.getBanners(data)
      .then((res) => {
        if (res.data.success) {
          setBottomLeftBanners(res.data.data.banners);
          setBottomLeftBannersCount(res.data.data.banners.length);
          setsliderInterval(res.data.data.speed_time_interval);
        } else {
          setBottomLeftBanners([]);
          setBottomLeftBannersCount(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const OnSearchKeyUp = (e) => {
    setKeywordActive(e.target.value);
    if(e.key == "Enter") {
      activeData.keyword = e.target.value;
      setActiveData({
        page: 1,
        limit: config.LIMIT,
        keyword: e.target.value,
        institute_id: instituteActive,
        interest_id: interestActive,
      });
      getEducationActivities(activeData);
      setAutoSearchData([]);
      return false;
    }
  }

  const OnSearch = (e) => {
    let data = { keyword: e.target.value };
    setKeywordActive(e.target.value);
    if(e.target.value != '') {
      EducationApi.getActivityNameByKeyword(data)
        .then((res) => {
          if (res.data.success) {
            setAutoSearchData(res.data.data);
          } else {
            setAutoSearchData([]);
          }
        })
        .catch((err) => {
          console.log(err);
      });
    }  else {
      setAutoSearchData([]);
    }
    // setActiveData({ ...activeData, search: e.target.value })
  }

  const searchActivity = (value) => {
    setKeywordActive(value);
    activeData.keyword = value;
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      keyword: value,
      institute_id: instituteActive,
      interest_id: interestActive,
    });
    getEducationActivities(activeData);
    setAutoSearchData([]);
  }

  const searchRecord = () => {
    setKeywordActive(keywordActive);
    activeData.keyword = (keywordActive !== undefined) ? keywordActive : "";
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      keyword: activeData.keyword,
      institute_id: instituteActive,
      interest_id: interestActive,
    });
    getEducationActivities(activeData);
    setAutoSearchData([]);
  }

  return (
    <div>
      <Navbar />
      <MobileHeader title="Education" />
      <MobileBottomMenu />
      <section className="py-3 top-curve">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12">
              <h1 className="my-5 main-title d-m-none">
                {props.t("Education")}
              </h1>
            </div>
            <div className="col-md-12">
              <div className="top-links d-none list-style-none">
                <ul>
                  <li>
                    <a
                      href="#"
                      onClick={() => mTabActive("CME")}
                      className={`${mTab == "" ? "CME" : ""}` + isCMEActive}
                    >
                      {props.t("Accredited-Course")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => mTabActive("CPD")}
                      className={`${mTab == "" ? "CPD" : ""}` + isCPDActive}
                    >
                      {props.t("Webinars")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => mTabActive("Multimedia")}
                      className={
                        `${mTab == "" ? "Multimedia" : ""}` + isMultimediaActive
                      }
                    >
                      {props.t("multimedia")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => mTabActive("ARTICLE")}
                      className={
                        `${mTab == "" ? "ARTICLE" : ""}` + isArticleActive
                      }
                    >
                      {props.t("articles")}
                    </a>
                  </li>
                  {/* <li>
                    <a href="javascript:void();" onClick={() => mTabActive('INTERNSHIP')}  className={`${mTab == "" ? "INTERNSHIP" : ""}`}>
                      Internship
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              <div className="search-box">
                <form action="">
                  <div className="row">

                  

                    <div className="col-md-9 pr-0">
                    <div className="wrapper w-40">
                    <div className="search-input">
                      <a href="" target="_blank" hidden="" />
                      <input type="text" value={keywordActive}  defaultValue={keywordActive}  onChange={(e) => OnSearch(e)} onKeyUp = {OnSearchKeyUp}  placeholder={props.t("Search")}/>
                        <div className="autocom-box">
                        {autoSearch?.map((searchdata, index) => {
                          return (<li key={index}  onClick={() => searchActivity(searchdata.title_en)}  >{searchdata.title_en}</li>)
                        })}
                        </div>
                      <div className="icon"  onClick={() => searchRecord()}>
                        <img
                          src={config.BASE_URL + "/assets/images/search.png"}
                          alt="search-icon"
                          width={15}
                          height={15}
                        />
                      </div>
                        </div>
                      </div>
                      {/* <div className="input-group mb-3">
                        <span className="input-group-text">
                          <img
                            src={config.BASE_URL + "/assets/images/search.png"}
                            alt="search-icon"
                            width={15}
                            height={15}
                          />
                        </span> 
                        <input
                          type="text" 
                          className="form-control searchTextEductaion"
                          placeholder={props.t("Search")}
                          onChange={(e) => handleKeywordActive(e.target.value)}
                        />
                        <ul><li>2</li></ul>
                      </div> */}
                    </div>
                    <div className="col-md-3 pl-0 d-m-none">
                      <Select
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderRadius: "50px",
                          }),
                        }}
                        onChange={(e) => {
                          handleClickInstitute(e?.value);
                        }}
                        onMenuScrollToBottom={onScrollInstituesDataHandler}
                        onInputChange={onSearchInstitutesHandler}
                        onMenuOpen={onOpenInstituteMenuHandler}
                        defaultValue={selectedInstitute}
                        //onBlur={formik.handleBlur}
                        name="institute_id"
                        options={[...institutesData]}
                        placeholder={
                          <div>{props.t("Filter-By-Institution")}</div>
                        }
                      />

                      {/* <input
                          type="text"
                          className="btn btn-default dropdown-toggle w-100"
                          data-bs-toggle="dropdown"
                          defaultValue={selectedInstitute}
                          placeholder={props.t("Filter-By-Institution")}
                          onChange={(e) => handleInstitueKeywordActive(e.target.value)}
                        /> */}

                      {/* <ul
                          className="dropdown-menu"
                          style={{ width: "320px" }}
                        >
                          <li>
                            <div className="form-check">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="radio1"
                                name="optradio"
                                defaultValue="option1"
                                defaultChecked=""
                                onClick={() => handleClickInstitute("All","")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="radio1"
                              >
                                {props.t("All")}
                              </label>
                            </div>
                          </li>

                          {activeInstitutesData?.map((institutes, i) => (
                            <li key={`aceactiveInstitutesData${i}`}>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id="radio1"
                                  name="optradio"
                                  defaultValue="option1"
                                  defaultChecked=""
                                  onClick={() =>
                                    handleClickInstitute(institutes.id,institutes.institution_name)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="radio1"
                                >
                                  {institutes.institution_name}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* advertiseSlider */}
      <div className="">
        <Advertise
          banner={banners}
          bannersCount={bannersCount}
          name="TopBanner"
          sliderInterval={sliderInterval}
        />
      </div>

      <section className="py-3">
        <div className="container">
          <div className="row px-10">
            <div className="col-md-12 mob-pr-0 specialiry mt-5 mob-mb-0 mob-mt-30 mob-pl-8  mob-mr-m-4">
              <h5 className="title mb-4">{props.t("Specialities")}</h5>
              <ul>
                <OwlCarousel
                  autoWidth={true}
                  {...options}
                  nav={true}
                  startPosition={interestPosition}
                  navText={[
                    '<span class="interest-arrow interest-prev">‹</span>',
                    '<span class="interest-arrow interest-next">›</span>',
                  ]}
                >
                  <li key={'allspecialties'}>
                    <a
                      href="javascript:void();"
                      onMouseUp={() => handleClickTabActive("All",0)}
                      className={`${interestActive == "All" ? "active" : ""}`}
                    >
                      {props.t("All")}
                    </a>
                  </li>
                  {interests?.map((interest, i) => (
                    <li key={`bhactiveInstitutesDatai_${i}`}>
                      <a 
                        key={`interest01_${i}`}
                        href="javascript:void();"
                        className={`${
                          interestActive == interest.id ? "active" : ""
                        }`}
                        onMouseUp={() => handleClickTabActive(interest.id,i)}
                      >
                        {interest.name_en}
                      </a>
                    </li>
                  ))}
                </OwlCarousel>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <>
        {/* CME Events Start */}
        <section className="py-5 events bg-gray2 mob-pt-1 mob-mt-m-35 mob-pb-0">
          <div className="container p-0">
            <div className="row">
              <div className="col-md-12 px-30 d-m-flex">
                <h1 className="main-title d-m-none">
                  {props.t("Accredited-Course")}
                </h1>

                {cmeactivities.length > 0 && (
                  <>
                    <h3 className="title">{props.t("Activities")}</h3>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="subtitle d-m-none">
                        {props.t("Come-See-What’s-Happening")}
                      </div>
                      <div>
                        <Link
                          to={`education-activities/${mTab}`}
                          className="view-text"
                        >
                          <span className="view-m">{props.t("See-All")}</span>
                          <span className="see-m">
                            {props.t("See-All")}
                          </span>{" "}
                          <img
                            src={
                              config.BASE_URL + "/assets/images/right-arrow.png"
                            }
                            alt="icon"
                            className="ml-2"
                            width={18}
                            height={18}
                          />
                        </Link>
                      </div>
                    </div>
                  </>
                )}

                {/* Desktop Event Listing Start */}
                {cmeactivities.length !== 0 ? (
                  <div className="events-listing mt-4 d-m-none justify-content-between">
                    {cmeactivities?.map((activity, index) => {
                      return (
                        <Activity
                          activity={activity}
                          key={`accreditedcourse_${index}`}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="subtitle text-center d-m-none">
                    {props.t("No-Accredited-Course-Activities-Found")}{" "}
                  </div>
                )}
                {/* Desktop Event Listing End */}
              </div>

              <>
                {/* Mobile Event carousel Start */}
                {mTab === "CME" && cmeactivities.length !== 0 ? (
                  // <div className="owl-carousel events-carousel mt-4 px-20 d-none">
                  <OwlCarousel
                    items={6}
                    autoplay={false}
                    className="owl-carousel events-carousel mt-4 px-20 d-none"
                    {...options}
                  >
                    {cmeactivities?.map((activity, index) => {
                      return (
                        <MobileActivity
                          activity={activity}
                          key={`mobileActivity_${index}`}
                          currentTab={mTab}
                        />
                      );
                    })}
                  </OwlCarousel>
                ) : (
                  mTab === "CME" && (
                    // </div>
                    <div className="row">
                      <div className="col-md-9 pr-0">
                        <div className="subtitle text-center d-none mb-3">
                          {props.t("No-Activities-Found")}
                        </div>
                      </div>
                    </div>
                  )
                )}

                {mTab === "CPD" && cpdactivities.length !== 0 ? (
                  // <div className="owl-carousel events-carousel mt-4 px-20 d-none">
                  <OwlCarousel
                    items={6}
                    autoplay={false}
                    className="owl-carousel events-carousel mt-4 px-20 d-none"
                    {...options}
                  >
                    {cpdactivities?.map((activity, index) => {
                      return (
                        <MobileActivity
                          activity={activity}
                          key={`mobileActivity_${index}`}
                          currentTab={mTab}
                        />
                      );
                    })}
                  </OwlCarousel>
                ) : (
                  mTab === "CPD" && (
                    // </div>
                    <div className="row">
                      <div className="col-md-9 pr-0">
                        <div className="subtitle text-center d-none mb-3">
                          {props.t("No-Activities-Found")}
                        </div>
                      </div>
                    </div>
                  )
                )}

                {mTab === "Multimedia" && multimedia.length !== 0 ? (
                  // <div className="owl-carousel events-carousel mt-4 px-20 d-none">
                  <OwlCarousel
                    items={6}
                    autoplay={false}
                    className="owl-carousel events-carousel mt-4 px-20 d-none"
                    {...options}
                  >
                    {multimedia?.map((activity, index) => {
                      return (
                        <MobileActivity
                          activity={activity}
                          key={`mobileActivity_${index}`}
                          currentTab={mTab}
                        />
                      );
                    })}
                  </OwlCarousel>
                ) : (
                  mTab === "Multimedia" && (
                    // </div>
                    <div className="row">
                      <div className="col-md-9 pr-0">
                        <div className="subtitle text-center d-none mb-3">
                          {props.t("No-Activities-Found")}
                        </div>
                      </div>
                    </div>
                  )
                )}

                {mTab === "ARTICLE" && articles.length !== 0 ? (
                  // <div className="owl-carousel events-carousel mt-4 px-20 d-none">
                  <OwlCarousel
                    items={6}
                    autoplay={false}
                    className="owl-carousel events-carousel mt-4 px-20 d-none"
                    {...options}
                  >
                    {articles?.map((activity, index) => {
                      return (
                        <MobileActivity
                          activity={activity}
                          key={`mobileActivity_${index}`}
                          currentTab={mTab}
                        />
                      );
                    })}
                  </OwlCarousel>
                ) : (
                  mTab === "ARTICLE" && (
                    // </div>
                    <div className="row">
                      <div className="col-md-9 pr-0">
                        <div className="subtitle text-center d-none mb-3">
                          {props.t("No-Activities-Found")}
                        </div>
                      </div>
                    </div>
                  )
                )}
                {/* Mobile Event carousel End */}
              </>

              {/* <div className="col-md-12 px-30 mt-4 d-m-flex2">
                <div className="d-flex justify-content-between align-items-center mb-4 mob-mb-5">
                  <h3 className="title">Library</h3>
                  <div>
                    <Link to="education-libraries/CME" className="view-text">
                      <span className="view-m">View More</span>
                      <span className="see-m">See All</span>{" "}
                      <img
                        src={config.BASE_URL + '/assets/images/right-arrow.png'}
                        alt="icon"
                        className="ml-2"
                        width={18}
                        height={18}
                      />
                    </Link>
                  </div>
                </div>
                <div className="library-list">
                  <ul className="first">
                    {cmelibraries.length > 0 ? cmelibraries?.map((library, index) => {
                      return (
                        <Library library={library} key={index} />
                      )
                    })
                      : <h3>No Record Found</h3>
                    }
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/* CME Events End */}

        <EducationActivitySection
          title={props.t("Webinars")}
          background=""
          activity={cpdactivities}
          section="CPD"
          noDataFoundText={props.t("No-Webinar-Activities-Found")}
        />
        <EducationActivitySection
          title={props.t("multimedia")}
          background="bg-gray2"
          activity={multimedia}
          section="Multimedia"
          noDataFoundText={props.t("noMultimdeiaFounds")}
        />
        <EducationActivitySection
          title={props.t("articles")}
          background=""
          activity={articles}
          section="articles"
          noDataFoundText={props.t("noArticlesFounds")}
        />
      </>
      {/* d-m-none */}
      <section className="py-5 events mob-pt-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-6 col-xs-6 col-sm-6">
              <Advertise
                banner={bottomLeftbanners}
                bannersCount={bottomLeftbannersCount}
                name="BottomLeftBanner"
                sliderInterval={sliderInterval}
              />
            </div>
            <div className="col-md-6 col-xs-6 col-sm-6">
              <Advertise
                banner={bottomRightbanners}
                bannersCount={bottomRightbannersCount}
                name="BottomRightBanner"
                sliderInterval={sliderInterval}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default withTranslation()(Education);
