import React from "react";
import { Link } from "react-router-dom";
const MobPageTitleBar = ({ isBackShow, title, mobBackUrl }) => {
  return (
    <div className="mobile-gray-top d-none">
      <div className="container">
        <div className="row">
          <div className="d-flex align-items-center justify-content-between mob-mt-39">
            {isBackShow && (
              <div>
                <Link to={`${mobBackUrl}`}>
                  <img
                    src={require("assets/images/768/arrow-left.png")}
                    alt=""
                  />
                </Link>
              </div>
            )}
            <div>
              <h4 className="mb-0">{title}</h4>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobPageTitleBar;
