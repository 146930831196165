import axios from "axios";
import config from "../config";
import * as url from "../helpers/url_helper";

export const CustomActivityApi = {
  getActivityDetailsAPI: async (data) => {
    let axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_DETAILSWITHOULOGIN}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  fetchActivityTypes: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_TYPES}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios(axiosConfig);
  },

  fetchActivitySubTypes: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_SUB_TYPES}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  getActivityTypeJson: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_TYPE_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  fetchActivityDisplayTypes: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_DISPLAY_TYPES}`,
      headers: {
        "Content-Type": "application/json",
      },
      // data: data
    };
    return await axios(axiosConfig);
  },

  fetchActivityContentTypes: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ACTIVITY_CONTENT_TYPES}`,
      headers: {
        "Content-Type": "application/json",
      },
      // data: data
    };
    return await axios(axiosConfig);
  },

  fetchAttachmentTypes: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ATTACHMENT_TYPES}`,
      headers: {
        "Content-Type": "application/json",
      },
      // data: data
    };
    return await axios(axiosConfig);
  },

  getQuizTypes: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_QUIZ_TYPES}`,
      headers: {
        "Content-Type": "application/json",
      },
      // data: data
    };
    return await axios(axiosConfig);
  },

  getQuizTypeDetail: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_QUIZ_TYPE_DETAILS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  getPrimaryInstituteNames: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_ALL_INSTITUTE_NAMES}`,
      headers: {
        "Content-Type": "application/json",
      },
      // data: data
    };

    return await axios(axiosConfig);
  },

  getSpecialistAPI: async () => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_SPECIALISTS}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios(axiosConfig);
  },

  getSpeakerUsers: async () => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_SPEAKER_USERS}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios(axiosConfig);
  },

  createActivity: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CREATE_ACTIVITY}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  updateActivity: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.UPDATE_ACTIVITY}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  copyActivity: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.COPY_ACTIVITY}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },

  removeActivityMedia: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_REMOVE_ACTIVITY_MEDIA}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  fetchActivityQuestions: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.FETCH_ACTIVITY_QUESTIONS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data
    };
    return await axios(axiosConfig);
  },
  createActivityQuestions: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.CREATE_ACTIVITY_QUESTIONS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  deleteActivityQuestionAnswers: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.DELETE_ACTIVITY_QUESTION_ANSWERS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  updateActivityQuestion: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.UPDATE_ACTIVITY_QUESTION}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  fetchEvaluationForms: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.GET_EVALUTION_FORMS_BY_INSTITUTE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getTaskTypesApi: async () => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.FETCH_TASKTYPES}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios(axiosConfig);
  },
  getAllDoctorsApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}api/common/getAllDoctorPaginate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getAllInstitutesApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}api/common/getAllInstitutesPaginate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getMySessionsApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}api/common/fetch-my-sessions`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getInternshipSessionsAllTasksApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}api/common/fetch-sessions-tasks`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
  getInternshipSessionsTaskDetailApi: async (data) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}api/common/fetch-sessions-tasks-detail`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(axiosConfig);
  },
};
