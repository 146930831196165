import React, { useEffect, useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import { BookmarkApi } from "../../apis/BookmarkApi";
import config from "../../config";
import moment from 'moment';
import { withTranslation } from "react-i18next";
import * as url from "../../helpers/common_helper"

function Activities(props) {
    const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
        if (!value) return value
        return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
    }

    const [activitiesbookmarkData, setActivitiesbookmarkData] = useState({ education_type: "All", page: 1, limit: config.LIMIT });

    const [activitiesbookmark, setActivitiesbookmark] = useState([]);

    const getActivitiesBookmark = (data) => {
        BookmarkApi.getActivitiesBookmark(data)
            .then((res) => {
                if (res.data.success) {
                    setActivitiesbookmark(res.data.data.rows);
                } else {
                    setActivitiesbookmark([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function handleRemoveBookmarkclick(actid) {
        //delete bookmark
        const deleteActivityBookmark = (data) => {
            BookmarkApi.deleteActivityBookmark(data)
                .then((res) => {
                    if (res.data.success) {
                        getActivitiesBookmark(activitiesbookmarkData);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        const adddata = {
            bookmark_id: actid
        }

        deleteActivityBookmark(adddata);
    }

    useEffect(() => {
        getActivitiesBookmark(activitiesbookmarkData);
    }, []);

    return (
        <>
            {/* <div className="d-flex justify-content-between align-items-center">
                <h3 className="title mb-0">Activities</h3>
            </div> */}

            {/* <!-- Desktop Activities Listing Start --> */}
            <div
                className="events-listing d-m-none"
                style={{ justifyContent: "space-between" }}
            >
                {(activitiesbookmark.length > 0) ? ((activitiesbookmark).map((item, i) => {
                    return <div className="event-item text-center list-style-none" key={i}>
                        <NavLink to={'/education/education-activity-details/' + item.activity_id} >
                        <div className="position-relative main-img mb-2">  
                            {item?.Activity?.ActivityMediaImage[0]?.file_url == null || item?.Activity?.ActivityMediaImage[0]?.file_url == undefined || item?.Activity?.ActivityMediaImage[0]?.file_url == 0 ? (
                                <img
                                    style={{height:"148px"}}
                                    className="img-fluid mx-auto"
                                    src={config.BASE_URL + '/assets/images/default-activity.jpg'}
                                    alt=""
                                />
                            ) : (
                                // <NavLink to={'/education/education-activity-details/' + activity?.ActivityMedia[0]?.file_url} >
                                    <img
                                        style={{height:"148px"}}
                                        className="img-fluid mx-auto"
                                        src={item?.Activity?.ActivityMediaImage[0]?.file_url}
                                        alt=""
                                    />
                                // </NavLink>
                            )}
                            <span className="shadow" style={{
                                bottom: "auto", top: "10px", width: "32px", borderRadius: "50%", padding: "3px 10px", background: "#ffffff",
                                height: "32px",
                                verticalAlign: "middle",
                                lineHeight: "24px"
                             }}>
                                <Link onClick={() => handleRemoveBookmarkclick(item.id)} to="">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/bookmark.png"
                                        width="10"
                                        height="10"
                                        style={{ borderRadius: "0px" }}
                                    />
                                </Link>
                            </span>
                        </div>
                        <a href="">
                            <h4>{item.Activity ? item.Activity.title_en : ""}</h4>
                        </a>
                        <ul className="event-list">
                            <li>
                                <div>
                                    <img
                                        className="img-fluid"
                                        src="assets/images/clock.png"
                                        alt=""
                                        width="12"
                                        height="12"
                                    />
                                </div>
                                <div>{formatDate(item.Activity?.activity_date)}</div>
                            </li>
                            <li>
                                <div>
                                    <img
                                        className="img-fluid "
                                        src="assets/images/location-marker.png"
                                        alt=""
                                        width="12"
                                        height="12"
                                    />
                                </div>
                                <div>{item.Activity ? item.Activity.type : ""}</div>
                            </li>
                        </ul>
                        </NavLink>
                    </div>
                })) 
                : 
                    <div className="row noBookmark">
                        <div className="col-12 ">
                            <img
                                className="img-fluid"
                                src="assets/images/no-bookmark.png"
                            />
                        </div>
                    </div>
                }

            </div>
            {/* <!-- Desktop Activities Listing End --> */}

            {/* <!-- Mobile Activities carousel Start --> */}
            <div className="collections d-none">
                {(activitiesbookmark.length > 0 ) ? ((activitiesbookmark).map((item, i) => {
                    return ( 
                        <div className="collection-item" key={i}>
                            <div className="position-relative main-img mb-2">
                                {item?.Activity?.ActivityMediaImage[0]?.file_url == null || item?.Activity?.ActivityMediaImage[0]?.file_url == undefined || item?.Activity?.ActivityMediaImage[0]?.file_url == 0 ? (
                                    <img
                                        style={{height:"148px"}}
                                        className="img-fluid"
                                        src={config.BASE_URL + '/assets/images/default-activity.jpg'}
                                        alt=""
                                    />
                                ) : (
                                    // <NavLink to={'/education/education-activity-details/' + activity?.ActivityMedia[0]?.file_url} >
                                        <img
                                            style={{height:"148px"}}
                                            className="img-fluid"
                                            src={item?.Activity?.ActivityMediaImage[0]?.file_url}
                                            alt=""
                                        />
                                    // </NavLink>
                                )}

                                <span className="shadow" style={{
                                    bottom: "auto", 
                                    top: "10px", 
                                    width: "32px", 
                                    borderRadius: "50%", 
                                    padding: "3px 10px", 
                                    background: "#ffffff",
                                    height: "32px",
                                    verticalAlign: "middle",
                                    lineHeight: "24px",
                                    // zIndex: 333333333333,
                                    position:"absolute",
                                    right:"10px"
                                }}>
                                    <Link onClick={() => handleRemoveBookmarkclick(item.id)} to="">
                                        <img
                                            className="img-fluid"
                                            src="assets/images/bookmark.png"
                                            width="10"
                                            height="10"
                                            style={{ borderRadius: "0px", height:"18px" }}
                                        />
                                    </Link>
                                </span>
                            </div>
                            <a href="">
                                <h4>{item.Activity ? item.Activity.title_en : ""}</h4>
                            </a>
                            <ul className="event-list">
                                <li>
                                    <div>
                                        <img
                                            className="img-fluid"
                                            src="assets/images/clock.png"
                                            alt=""
                                            width="12"
                                            height="12"
                                        />
                                    </div>
                                    <div>{formatDate(item.Activity?.activity_date)}</div>
                                </li>
                                <li>
                                    <div>
                                        <img
                                            className="img-fluid "
                                            src="assets/images/location-marker.png"
                                            alt=""
                                            width="12"
                                            height="12"
                                        />
                                    </div>
                                    <div>{item.Activity ? item.Activity.type : ""}</div>
                                </li>
                            </ul>
                        </div>
                    )
                })) 
                : 
                    <div className="row noBookmark">
                        <div className="col-12 ">
                            <img
                                className="img-fluid"
                                src="assets/images/no-bookmark.png"
                            />
                        </div>
                    </div>
                }

            </div>
            {/* <!-- Mobile Event carousel End --> */}
        </>
    );
}

export default withTranslation()(Activities)