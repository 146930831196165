import _ from "lodash";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, NavLink, useParams } from "react-router-dom";
import Select from "react-select";
import { CustomActivityApi } from "../../apis/CustomActivityApi";
import { EducationApi } from "../../apis/EducationApi";
import Footer from "../../components/Layout/Footer";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import Navbar from "../../components/Layout/Navbar";
import config from "../../config";
import Activity from "./components/Activity";
import OwlCarousel from "react-owl-carousel";

const options = {
  responsiveClass: true,
  dots: false,
  autoplay: false,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 6,
    },
    700: {
      items: 6,
    },
    1000: {
      items: 6,
    },
  },
};


function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function EducationActivities(props) {
  const params = useParams();
  const education_type = params.education_type;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product = urlParams.get("keyword");

  const [activeData, setActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
    keyword: urlParams.get("keyword") == null ? "" : urlParams.get("keyword"),
    institute_id:
      urlParams.get("institute_id") == null
        ? "All"
        : urlParams.get("institute_id"),
    interest_id:
      urlParams.get("interest_id") == null
        ? "All"
        : urlParams.get("interest_id"),
    education_type: education_type,
  });
  const [activities, setActivitiesData] = useState([]);
  const [interests, setInterests] = useState([]);
  const [active, setActive] = useState("All");
  const [interestActive, setInterestActive] = useState("All");
  const [keywordActive, setKeywordActive] = useState("");
  const [instituteActive, setInstituteActive] = useState("All");
  const [interestPosition, setInterestPosition] = useState(0);
  const [activeInstitutesData, setActiveInstitutesData] = useState([]);
  const fetchScrollPostData = {};
  const [hasMorePost, sethasMorePost] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [hasMore, sethasMore] = useState(true);

  const [selectedInstitute, setSelectedInstitute] = useState();
  const [institutesData, setinstitutesData] = useState([]);
  const [institutesPaginateData, setinstitutesPaginateData] = useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  });
  const [isMoreInstitutes, setIsMoreInstitutes] = useState(true);
  const [isInstituteLoading, setIsInstituteLoading] = useState(true);
  const [autoSearch, setAutoSearchData] = useState([]);


  const onScrollInstituesDataHandler = _.debounce(async function () {
    if (isMoreInstitutes) {
      setIsInstituteLoading(true);
      getAllInstitutesPaginate();
    }
  }, 500);

  const onSearchInstitutesHandler = _.debounce(async function (searchInput) {
    setinstitutesPaginateData({
      ...institutesPaginateData,
      page: 1,
      search: searchInput,
    });
    if (searchInput.length >= 3) getAllInstitutesPaginate(searchInput);
  }, 500);

  const onOpenInstituteMenuHandler = () => {
    setinstitutesPaginateData({
      ...institutesPaginateData,
      page: 1,
    });
    getAllInstitutesPaginate();
  };

  const getAllInstitutesPaginate = (searchKeyword = "") => {
    if (searchKeyword !== "") {
      institutesPaginateData.search = searchKeyword;
    }
    CustomActivityApi.getAllInstitutesApi(institutesPaginateData)
      .then((res) => {
        if (res.data.success) {
          const pageNo = institutesPaginateData.page;
          setinstitutesPaginateData({
            ...institutesPaginateData,
            totalRecords: res.data.data.count,
            page: res.data.data.metadata.page,
            limit: res.data.data.metadata.limit,
          });
          if (pageNo === 1) {
            setinstitutesData([...res.data.data.rows]);
          } else {
            if (res.data.data.rows.length === 0) {
              setIsMoreInstitutes(false);
              setIsInstituteLoading(false);
            }
            setinstitutesData([...institutesData, ...res.data.data.rows]);
          }
        } else {
          setinstitutesData([]);
        }
      })
      .catch((err) => {
        setinstitutesData([]);
      });
  };

  useEffect(() => {
    getEducationActivities(activeData, false);
    getInterests();
    //getInstitutes();
    if (education_type === "CME") {
      setPageTitle(props.t("Accredited-Course"));
    } else if (education_type === "CPD") {
      setPageTitle(props.t("Webinars"));
    } else if (education_type === "ARTICLE") {
      setPageTitle(props.t("articles"));
    } else if (education_type === "Multimedia") {
      setPageTitle(props.t("multimedia"));
    }
    setInterestActive(activeData.interest_id);
    setKeywordActive(activeData.keyword);
    setInstituteActive(activeData.institute_id);
  }, []);

  // const getInstitutes = () => {
  //   InstituteApi.getInstitutes()
  //     .then((res) => {
  //       if (res.data.success) {
  //         setActiveInstitutesData(res.data.data);
  //         if (res.data.data.length == 0) {
  //           sethasMorePost(true);
  //         }
  //       } else {
  //         setActiveInstitutesData([]);
  //         sethasMorePost(true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getInterests = () => {
    EducationApi.getInterests()
      .then((res) => {
        if (res.data.success) {
          setInterests(res.data.data);
        } else {
          setInterests([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEducationActivities = (data, is_push_data) => {
    EducationApi.getEducationActivitiesByType(data)
      .then((res) => {
        if (res.data.success) {
          if (is_push_data) {
            setActivitiesData([...activities, ...res.data.data.rows]);
          } else {
            setActivitiesData(res.data.data.rows);
          }
          //setActivitiesData([...activities, ...res.data.data.rows]);
          if (res.data.data.rows.length == 0) {
            sethasMore(false);
          }
        } else {
          setActivitiesData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickTabActive = (value) => {
    sethasMore(true);
    setActivitiesData([]);
    setInterestActive(value);
    activeData.interest_id = value;
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      keyword: keywordActive,
      institute_id: instituteActive,
      interest_id: value,
      education_type: education_type,
    });

    getEducationActivities(activeData, false);
  };

  const handleClickInstitute = (value) => {
    sethasMore(true);
    setActivitiesData([]);
    setInstituteActive(value);
    activeData.institute_id = value;
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      keyword: keywordActive,
      institute_id: value,
      interest_id: interestActive,
      education_type: education_type,
    });
    getEducationActivities(activeData, false);
  };

  const handleKeywordActive = (value) => {
    sethasMore(true);
    setActivitiesData([]);
    setKeywordActive(value);
    activeData.keyword = value;
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      keyword: value,
      institute_id: instituteActive,
      interest_id: interestActive,
      education_type: education_type,
    });
    getEducationActivities(activeData, false);
  };

  const fetchMoreData = () => {
    const data = {
      page: activeData.page + 1,
      limit: config.LIMIT,
      keyword: keywordActive,
      institute_id: instituteActive,
      interest_id: activeData.interest_id,
      education_type: education_type,
    };
    getEducationActivities(data, true);
    setActiveData(data);
  };

  // window.onscroll = async function (ev) {
  //   var pageHeight = Math.max(
  //     document.body.scrollHeight,
  //     document.body.offsetHeight,
  //     document.documentElement.clientHeight,
  //     document.documentElement.scrollHeight,
  //     document.documentElement.offsetHeight
  //   );
  //   if (window.innerHeight + window.scrollY >= pageHeight) {
  //     const data = {
  //       page: activeData.page + 1,
  //       limit: config.LIMIT,
  //       keyword: activeData.keyword,
  //       institute_id: instituteActive,
  //       interest_id: interestActive,
  //       education_type: education_type,
  //     };
  //     setActiveData(data);
  //     getEducationActivities(data,true);
  //   }
  // };

  const OnSearchKeyUp = (e) => {
    setKeywordActive(e.target.value);
    if(e.key == "Enter") {
      activeData.keyword = e.target.value;
      setActiveData({
        page: 1,
        limit: config.LIMIT,
        keyword: e.target.value,
        institute_id: instituteActive,
        interest_id: interestActive,
        education_type: education_type,
      });
      getEducationActivities(activeData);
      setAutoSearchData([]);
      return false;
    }
  }

  const OnSearch = (e) => {
    let data = { keyword: e.target.value };
    setKeywordActive(e.target.value);
    if(e.target.value != '') {
      EducationApi.getActivityNameByKeyword(data)
        .then((res) => {
          if (res.data.success) {
            setAutoSearchData(res.data.data);
          } else {
            setAutoSearchData([]);
          }
        })
        .catch((err) => {
          console.log(err);
      });
    }  else {
      setAutoSearchData([]);
    }
    // setActiveData({ ...activeData, search: e.target.value })
  }

  const searchActivity = (value) => {
    setKeywordActive(value);
    sethasMore(true);
    setActivitiesData([]);
    activeData.keyword = value;
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      keyword: value,
      institute_id: instituteActive,
      interest_id: interestActive,
      education_type: education_type,
    });
    getEducationActivities(activeData,false);
    setAutoSearchData([]);

    // sethasMore(true);
    // setActivitiesData([]);
    // setKeywordActive(value);
    // activeData.keyword = value;
    // setActiveData({
    //   page: 1,
    //   limit: config.LIMIT,
    //   keyword: value,
    //   institute_id: instituteActive,
    //   interest_id: interestActive,
    //   education_type: education_type,
    // });
    // getEducationActivities(activeData, false);
  }

  const searchRecord = () => {
    setKeywordActive(keywordActive);
    sethasMore(true);
    setActivitiesData([]);
    activeData.keyword = (keywordActive !== undefined) ? keywordActive : "";
    setActiveData({
      page: 1,
      limit: config.LIMIT,
      keyword: activeData.keyword,
      institute_id: instituteActive,
      interest_id: interestActive,
      education_type: education_type,
    });
    getEducationActivities(activeData,false);
    setAutoSearchData([]);
  }

  return (
    <div>
      <>
        <Navbar />
        <ScrollToTopOnMount />

        <div className="mobile-gray-top d-none">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between mob-mt-39">
                <div>
                  <NavLink to="/education">
                    <img
                      src={
                        config.BASE_URL + "/assets/images/768/arrow-left.png"
                      }
                      className=""
                    />
                  </NavLink>
                </div>
                <div>
                  <h4 className="mb-0">{props.t("Filter")}</h4>
                </div>
                <div>
                  {/* <NavLink to="/education/institute-filter?keyword=&institute_id=All&interest_id=All&education_type=CME"> */}
                  <NavLink
                    to={`/education/institute-filter?keyword${activeData.keyword}&institute_id=${activeData.institute_id}&interest_id=${activeData.interest_id}&education_type=${activeData.education_type}`}
                  >
                    <img
                      src={
                        config.BASE_URL + "/assets/images/768/adjustment.png"
                      }
                      className=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <MobileInnerPageHeader title="Activity" back_page_url="education"  /> */}
        <MobileBottomMenu />
        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row">
              <div className="col-md-12 list-style-none">
                <ul className="breadcrumb mt-3">
                  <li>
                    <Link to={"/education"}>{props.t("Education")}</Link>
                  </li>
                  <li>/</li>
                  <li className="active">
                    {pageTitle}: {props.t("Activities")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* CPD Activities Star */}
        <section className="mb-0 mob-pt-2">
          <div className="container">
            <div className="row px-10">
              <div className="col-md-12">
                <h1 className="my-5 main-title d-m-none">
                  <span>{pageTitle}:</span> {props.t("Activities")}
                </h1>
              </div>
              <div className="col-md-12">
                <div className="search-box mob-pt-5">
                  <form action="">
                    <div className="row">
                      <div className="col-md-9 pr-0">

                      <div className="wrapper w-40">
                       <div className="search-input">
                        <a href="" target="_blank" hidden="" />
                        <input type="text" value={keywordActive}  defaultValue={keywordActive}  onChange={(e) => OnSearch(e) } onKeyUp = {OnSearchKeyUp}  placeholder={props.t("Search")}/>
                          <div className="autocom-box">
                          {autoSearch?.map((searchdata, index) => {
                            return (<li key={index}  onClick={() => searchActivity(searchdata.title_en)}  >{searchdata.title_en}</li>)
                          })}
                          </div>
                        <div className="icon"  onClick={() => searchRecord()}>
                          <img
                            src={config.BASE_URL + "/assets/images/search.png"}
                            alt="search-icon"
                            width={15}
                            height={15}
                          />
                        </div>
                          </div>
                        </div>

                        {/* <div className="input-group mb-3">
                          <span className="input-group-text">
                            <img
                              src={
                                config.BASE_URL + "/assets/images/search.png"
                              }
                              alt="search-icon"
                              width={15}
                              height={15}
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control searchTextEductaion"
                            placeholder={props.t("Search")}
                            onChange={(e) =>
                              handleKeywordActive(e.target.value)
                            }
                          />
                        </div> */}
                      </div>
                      <div className="col-md-3 pl-0 d-m-none">
                        <Select
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderRadius: "50px",
                            }),
                          }}
                          onChange={(e) => {
                            handleClickInstitute(e?.value);
                          }}
                          onMenuScrollToBottom={onScrollInstituesDataHandler}
                          onInputChange={onSearchInstitutesHandler}
                          onMenuOpen={onOpenInstituteMenuHandler}
                          defaultValue={selectedInstitute}
                          //onBlur={formik.handleBlur}
                          name="institute_id"
                          options={[...institutesData]}
                          placeholder={
                            <div>{props.t("Filter-By-Institution")}</div>
                          }
                        />
                        {/* <div className="dropdown filter-institution">
                          <button
                            type="button"
                            className="btn btn-default dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            {props.t("Filter-By-Institution")}
                            <img
                              src={
                                config.BASE_URL +
                                "/assets/images/angle-down.png"
                              }
                              alt=""
                              style={{ margin: "0px 6px" }}
                            />
                          </button>
                          <ul
                            className="dropdown-menu"
                            style={{ width: "320px" }}
                          >
                            <li>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id="radio1"
                                  name="optradio"
                                  defaultValue="option1"
                                  defaultChecked=""
                                  onClick={() => handleClickInstitute("All")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="radio1"
                                >
                                  {props.t("All")}
                                </label>
                              </div>
                            </li>

                            {activeInstitutesData?.map((institutes, i) => (
                              <li key={`education-activities-${i}`}>
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id="radio1"
                                    name="optradio"
                                    defaultValue="option1"
                                    defaultChecked=""
                                    onClick={() =>
                                      handleClickInstitute(institutes.id)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="radio1"
                                  >
                                    {institutes.institution_name}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {education_type !== "ARTICLE" && (
                <div className="col-md-12 mob-pr-0 specialiry mt-5 mob-mb-0 mob-mt-30 mob-pl-8 mob-mr-m-4">
                  <h5 className="title mb-4">{props.t("Specialities")}</h5>
                  <ul>
                <OwlCarousel
                  autoWidth={true}
                  {...options}
                  nav={true}
                  startPosition={interestPosition}
                  navText={[
                    '<span class="interest-arrow interest-prev">‹</span>',
                    '<span class="interest-arrow interest-next">›</span>',
                  ]}
                >
                  <li key={'allspecialties'}>
                    <a
                      href="javascript:void();"
                      onMouseUp={() => handleClickTabActive("All",0)}
                      className={`${interestActive == "All" ? "active" : ""}`}
                    >
                      {props.t("All")}
                    </a>
                  </li>
                  {interests?.map((interest, i) => (
                    <li key={`bhactiveInstitutesDatai_${i}`}>
                      <a 
                        key={`interest01_${i}`}
                        href="javascript:void();"
                        className={`${
                          interestActive == interest.id ? "active" : ""
                        }`}
                        onMouseUp={() => handleClickTabActive(interest.id,i)}
                      >
                        {interest.name_en}
                      </a>
                    </li>
                  ))}
                </OwlCarousel>
              </ul>
                </div>
              )}
            </div>
          </div>
        </section>
        {/* CPD Activities End */}
        {/* CME Events Start */}
        <section className="pb-5 pt-5 mob-mt-m-15">
          <div className="container p-0">
            <div className="row">
              <div className="col-md-12 px-30">
                <h3 className="title">{props.t("Activities")}</h3>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="subtitle">
                    {props.t("Come-See-What’s-Happening")}
                  </div>
                </div>
                <div className="collections" style={{ display: "block" }}>
                  {activities.length > 0 && (
                    <InfiniteScroll
                      dataLength={activities.length}
                      next={fetchMoreData}
                      loader={
                        <h4 className="w-50 h-40 text-[18px]">Loading...</h4>
                      }
                      hasMore={hasMore}
                      //height={500}
                      // endMessage={
                      //   <p className="text-[16px]" style={{ textAlign: "center", marginTop: '10px' }}>
                      //     <b>No more post</b>
                      //   </p>
                      // }
                    >
                      {activities.length > 0 ? (
                        activities?.map((activity, index) => {
                          return (
                            <Activity
                              activity={activity}
                              key={index}
                              forSection={
                                education_type === "ARTICLE"
                                  ? "articles"
                                  : pageTitle === "Multimedia"
                                  ? pageTitle
                                  : ""
                              }
                            />
                          );
                        })
                      ) : (
                        <div className="subtitle text-center">
                          {props.t("No-Activities-Found")}{" "}
                        </div>
                      )}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
              {/* <div className="col-md-12 px-30 mt-4 mob-mb-30">
                <div className="text-center">Loading...</div>
              </div> */}
            </div>
          </div>
        </section>
        {/* CME Events End */}
      </>

      <Footer />
    </div>
  );
}

export default withTranslation()(EducationActivities);
