import React, { useEffect, useState } from "react";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import Navbar from "../../components/Layout/Navbar";
import Footer from "../../components/Layout/Footer";
import upevent1 from "../../assets/images/right-arrow.png";
import { EducationApi } from "../../apis/EducationApi";
import { BookmarkApi } from "../../apis/BookmarkApi";
import config from "../../config";
import * as url from "../../helpers/common_helper";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import MobileHeaderArrowBanner from "../../components/Layout/Mobile/MobileHeaderArrowBanner";
import { BookingApi } from "apis/BookingApi";
import Advertise from "../Components/Advertise";
import { HomeApi } from "../../apis/HomeApi";
import constants from "../../constants";
import { getRoleID } from "../../Token";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";


function EducationActivityDetails(props) {
  const moment = require("moment");
  const navigate = useNavigate();
  const params = useParams();
  const activity_id = params.activity_id;

  const [activeData, setactiveData] = useState({ activity_id: activity_id });
  const [activity, setActivityData] = useState([]);
  const [image, setActivityImage] = useState("");
  const [files, setActivityFile] = useState([]);
  const [isBookmark, setBookmark] = useState(false);
  const [banners, setBanners] = useState([]);
  const [isActivityExpired, setIsActivityExpired] = useState(false);
  const [showJoinActivity, setShowJoinActivity] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false);
  const [isCustomActivity, setIsCustomActivity] = useState(false);
  const [activityTypeJson, setActivityTypeJson] = useState([]);
  const [quizQuestionCount, setQuizQuestionCount] = useState([]);

  useEffect(() => {
    getActivityDetails(activeData);
  }, []);

  const getActivityDetails = (data) => {
    EducationApi.getActivityDetails(data)
      .then((res) => {
        if (res.data.success) {
          let activity_date = new Date(res.data.data.activity_date)
            .toISOString()
            .replace(/.000Z/, "");
          activity_date = moment(activity_date).format("YYYY-MM-DD");
          if (activity_date < moment().format("YYYY-MM-DD")) {
            setIsActivityExpired(true);
          } else {
            setIsActivityExpired(false);
          }

          const activityJson = JSON.parse(
            res?.data?.data?.ActivityType?.form_content_json
          );
          setActivityTypeJson(activityJson);
          setActivityData(res.data.data);
          setQuizQuestionCount(res?.data?.checkQuizQuestionCount ? res?.data?.checkQuizQuestionCount : 0);
          setActivityImage(
            res.data.data.ActivityMediaImage[0]
              ? res.data.data.ActivityMediaImage[0].file_url
              : ""
          );
          setActivityFile(res.data.data.ActivityMediaFile);
          setBookmark(res.data.data.isBookmark ? true : false);
          setBanners(res.data.data.ActivityBanner);
          if (res.data.data.ActivityType) {
            const jsonData = JSON.parse(
              res.data.data.ActivityType.form_content_json
            );
            setShowQuiz(jsonData?.can_add_quiz);
            setShowJoinActivity(jsonData?.can_other_join_activity);
          }
          setIsCustomActivity(res.data.data?.is_custom_activity);
          window.scrollTo(0, 0);
        } else {
          setActivityData([]);
          setActivityImage("");
          setActivityFile([]);
          setBanners([]);
          setActivityTypeJson("");
          setQuizQuestionCount(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleBookmarkclick(actid, isBookmark) {
    //add bookmark
    // const addRemoveActivityBookmark = (data) => {
    //   BookmarkApi.postActivityBookmark(data)
    //     .then((res) => {
    //       setBookmark(true);
    //       //console.log(res.data)
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };

    const payload = {
      activity_id: actid,
      isBookmark: isBookmark,
    };

    BookmarkApi.postActivityBookmark(payload)
      .then((res) => {
        if (res.data.success) {
          if (isBookmark) {
            setBookmark(true);
          } else {
            setBookmark(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // addRemoveActivityBookmark(payload);
  }

  const onJoinActivityHandler = (activityID) => {
    const payload = {
      activity_id: activityID,
    };
    BookingApi.checkActivityBookedOrNotAPI(payload)
      .then((res) => {
        if (res.data.success) {
          window.$("#activityBookingExistAlready").modal("show");
        } else {
          navigate(`/education/review-booking/${btoa(activityID)}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // start time and end time
  let startTime = moment(activity.start_time, "HH:mm:ss a");
  let endTime = moment(activity.end_time, "HH:mm:ss a");
  // calculate total duration
  let duration = moment.duration(endTime.diff(startTime));
  //let hours = parseInt(duration.asHours());
  let hours = duration.asHours();
  let credits = Math.round(hours * 10) / 10;
  return (
    <div>
      <Navbar />
      <MobileBottomMenu />
      <>
        <MobileHeaderArrowBanner back_page_url="education" image={image} />
        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row">
              <div className="col-md-12 list-style-none">
                <ul className="breadcrumb mt-3">
                  <li>
                    <Link to="/education">{props.t("Activities")}</Link>
                  </li>
                  <li>/</li>
                  <li className="active">
                        {
                          i18n.language == 'en' && activity.title_en ? (
                          activity.title_en
                          ) : ( activity.title_th)
                        }
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Event / Activity Detail Start */}
        <section className="pt-3 pb-5 details top-curve">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="event-item d-flex justify-content-between">
                  <div className="position-relative main-img d-m-none">
                    {image == null || image == undefined || image == "" ? (
                      <img
                        className="img-fluid mx-auto brd-radius-10"
                        width="330"
                        src={
                          config.BASE_URL +
                          "/assets/images/default-activity.jpg"
                        }
                        alt=""
                      />
                    ) : (
                      <img
                        className="mx-auto brd-radius-10"
                        width={330}
                        height={330}
                        src={image}
                        style={{ objectFit: "cover" }}
                        alt=""
                      />
                    )}
                    {/* <img
                      className="img-fluid mx-auto brd-radius-10" width="330"
                      src={image}
                      //src="http://159.65.1.66:8002/storage/images/activity/image_url-1664171381391-maple-g887160dba_1920.jpg"
                      alt=""
                    /> */}
                  </div>
                  <div className="news-info">
                    <div>
                      {activity?.is_credit && activity?.education_type !== "ARTICLE" ? (
                        <span className="credits education-activity-credit">
                          {isNaN(credits) ? 0 : credits} {props.t("Credit")}
                        </span>
                      ): ''}
                      <h4 className="mt-3 mb-5">
                        {
                          i18n.language == 'en' && activity.title_en ? (
                          activity.title_en
                          ) : ( activity.title_th)
                        }
                      </h4>
                    </div>
                    <div className="list-style-none">
                      <ul className="event-list">
                        <li>
                          <div>
                            <img
                              className="img-fluid"
                              src={config.BASE_URL + "/assets/images/clock.png"}
                              alt=""
                              width={15}
                              height=""
                            />
                          </div>
                          <div>
                            {activity.activity_date &&
                              url.dateFormat(activity.activity_date) + " "}
                            {activity.start_time &&
                              url.TimeFormat(activity.start_time) + "-"}
                            {activity.end_time &&
                              url.TimeFormat(activity.end_time)}
                          </div> 
                        </li>
                        {(activity.type === constants.TYPE.Onsite || activity.type === constants.TYPE.Hybrid) && activity?.address && (
                          <li>
                            <div>
                              <img
                                className="img-fluid "
                                src={
                                  config.BASE_URL +
                                  "/assets/images/location-marker.png"
                                }
                                alt=""
                                width={15}
                                height=""
                              />
                            </div>
                            <div className="educationaddress">
                              {activity?.address}
                            </div>
                          </li>
                        )}
                        <li>
                          <div>
                            <img
                              className="img-fluid "
                              src={config.BASE_URL + "/assets/images/user.png"}
                              alt=""
                              width={15}
                              height=""
                            /> 
                          </div>
                          <div>{activity?.Institute?.institution_name}</div>
                        </li>
                      </ul>

                      <div className="row px-10">
                        <div className="col-md-12 mob-pr-0 specialiry mt-5 mob-mb-0 mob-mt-30 mob-pl-8  mob-mr-m-4">
                          <ul className="hidescroll">
                            {activity?.ActivityInterest?.map((interest, i) => (
                              <>
                                <li key={`actIntDet_${i}`}>
                                  <a href="javascript:void()" className="">
                                    {interest?.Interest?.name_en}
                                  </a>
                                </li>
                              </>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!isBookmark ? (
                    <>
                      <a
                        onClick={() => handleBookmarkclick(activity.id, true)}
                        href="#"
                        className="text-center"
                      >
                        <div className="bookmark bookmark-show">
                          <div>
                            <img
                              className="img-fluid"
                              src={
                                config.BASE_URL +
                                "/assets/images/bookmark-outline.png"
                              }
                              alt=""
                              width={25}
                              height=""
                            />
                          </div>
                        </div>
                        <span className="ed-activity-bookmark">
                          {props.t("Bookmark")}
                        </span>
                      </a>
                    </>
                  ) : (
                    <>
                      <a
                        onClick={() => handleBookmarkclick(activity.id, false)}
                        href="#"
                        className="text-center"
                      >
                        <div className="bookmark bookmark-show">
                          <div>
                            <img
                              className="img-fluid"
                              src={
                                config.BASE_URL + "/assets/images/bookmark.png"
                              }
                              alt=""
                              width={25}
                              height=""
                            />
                          </div>
                        </div>
                        <span className="ed-activity-bookmark">
                          {props.t("Bookmark")}
                        </span>
                      </a>
                    </>
                  )}

                  <div className="bookmark-hide bookmark">
                    <div>
                      <img
                        className="img-fluid"
                        src={config.BASE_URL + "/assets/images/bookmark.png"}
                        alt=""
                        width={25}
                        height=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 event-content my-4 plr-80">
                <br />
                {
                  i18n.language == 'en' && activity.description_en ? (
                    <div
                    dangerouslySetInnerHTML={{ __html: activity?.description_en }}
                    />
                  ) : ( <div
                    dangerouslySetInnerHTML={{ __html: activity?.description_th }}
                  />)
                }
                
                
              </div>
              <div className="library-list  mob-detail-list plr-80 list-style-none">
                <ul>
                  {files?.map((file, i) => (
                    <li key={`actFileDet${i}`}>
                      <a href={file.file_url} target="_blank">
                        <div className="d-flex align-items-center mb-3">
                          <div className="book">
                            <img
                              src={config.BASE_URL + "/assets/images/link.png"}
                              alt="link"
                              className=""
                              width={30}
                              height=""
                            />
                          </div>
                          <div className="link-block">
                            <span>{file.title_en}</span>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              {activity.ContactDetails && (
                <div className="contact mt-4 plr-80">
                  <div className="py-4 border-tb">
                    <h3 className="title mb-3">{props.t("Contact")}</h3>
                    <div className="row">
                      <div className="d-flex align-items-center col-md-6 contactItem">
                        <div className="icon">
                          <img
                            src={config.BASE_URL + "/assets/images/web.png"}
                            alt=""
                            className=""
                            width={20}
                            height=""
                          />
                        </div>
                        <div>{activity?.ContactDetails?.website}</div>
                      </div>
                      <div className="d-flex align-items-center col-md-6 contactItem">
                        <div className="icon">
                          <img
                            src={
                              config.BASE_URL + "/assets/images/Message-b.png"
                            }
                            alt=""
                            className=""
                            width={20}
                            height=""
                          />
                        </div>
                        <div>{activity?.ContactDetails?.email}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex align-items-center col-md-6 contactItem">
                        <div className="icon">
                          <img
                            src={config.BASE_URL + "/assets/images/Call-b.png"}
                            alt=""
                            className=""
                            width={20}
                            height=""
                          />
                        </div>
                        <div>{activity?.ContactDetails?.contact_number}</div>
                      </div>
                      <div className="d-flex align-items-center col-md-6 contactItem">
                        <div className="icon">
                          <img
                            src={
                              config.BASE_URL + "/assets/images/facebook.png"
                            }
                            alt=""
                            className=""
                            width={20}
                            height=""
                          />
                        </div>
                        <div>
                          <a href={activity?.ContactDetails?.facebook_link}>
                            {props.t("Contact")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="booking mt-4 plr-80 mob-mb-70">
                <h3 className="title mb-3">
                  <span className="d-m-none">{props.t("Booking")}</span>
                  <span className="d-none">{props.t("Booking")}</span>
                </h3>
                <div className="row booking mb-4">
                  <div className="col-md-6 coupon">
                    <div className="d-flex">
                      <div
                        className={`block1 fontBookingCard list-style-none ${
                          activity.type == "Online" ? "bg-green" : ""
                        }`}
                      >
                        {/* {activity?.type == "Onsite" && (
                          <img
                            src={config.BASE_URL + "/assets/images/qr-code.png"}
                            alt=""
                          />
                        )} */}
                        <span>{props.t("Date-And-Time")}</span>
                        <h4>{url.dateFormat(activity?.activity_date)} </h4>
                        { activity?.start_time && activity?.end_time && (
                          <h5>
                            {url.TimeFormat(activity?.start_time)} -{" "}
                            {url.TimeFormat(activity?.end_time)}{" "}
                          </h5>
                        )}
                        <ul>
                          {/* <li>
                            <span>Seal</span>
                            <strong>{activity?.join_number_of_users}</strong>
                          </li> */}
                          <li>
                            <span>{props.t("Price")}</span>
                            <strong>
                              {activity.fee_type == "FREE"
                                ? "Free"
                                : "฿ " + activity.fees}
                            </strong>
                          </li>
                        </ul>
                      </div>
                      <div className="block2">
                        {image == null || image == undefined || image == "" ? (
                          <img
                            src={"/assets/images/default-activity.jpg"}
                            alt=""
                          />
                        ) : (
                          <img src={image} alt="" />
                        )}
                        <h4>{
                          i18n.language == 'en' && activity.title_en ? (
                          activity.title_en
                          ) : ( activity.title_th)
                        }</h4>
                        <span className="text-center">
                        {activity?.type === constants.TYPE.Hybrid
                        ? 'Online/Onsite' 
                        : activity?.type
                        }
                        
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />

                {activity.can_join_activity &&
                // || activityTypeJson.can_other_join_activity
                constants.ROLE.COMPANY !== getRoleID() ? (
                  <div className="mob-bg-white">
                    <button
                      onClick={() => {
                        onJoinActivityHandler(activity.id);
                      }}
                      className="btn btn-join mt-5"
                      style={{ marginLeft: "10px" }}
                    >
                      {props.t("Join")} {props.t("Activity")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {isCustomActivity && showQuiz && quizQuestionCount > 0 ? (
                  <div className="mob-bg-white">
                    <Link
                      to={`/start-quiz/${activity.id}`}
                      className="btn btn-join mt-5"
                    >
                      {props.t("Start")} {props.t("Quiz")}
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
        {/* Event / Activity Detail Start */}
      </>

      {/* Mobile View Top End */}
      <div
        className="modal fade"
        id="activityBookingExistAlready"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered modal-xs">
          <div className="modal-content">
            <div className="modal-body">
              <div className="c-content">
                <div></div>
                <div className="p-l-20">
                  <img
                    src={require("../../assets/images/cancel.png")}
                    alt=""
                    width="30"
                    height="30"
                  />
                  <strong>{props.t("Alert")}</strong>
                  <p>
                    Sorry to say you can't join this activity again, because you
                    already join please check in your booking, thanks
                  </p>
                </div>
              </div>
              <div className="d-flex pt-4 cancel-btn-list">
                <button
                  type="button"
                  className="float-right btn btn-cancel"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    window.$("#activityBookingExistAlready").modal("hide");
                  }}
                >
                  {props.t("Okay")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Booking End --> */}

      <Advertise
        banner={banners}
        bannersCount={banners.length}
        name="ActivityDetailsBanner"
      />

      <Footer />
    </div>
  );
}

export default withTranslation()(EducationActivityDetails);
