import React from 'react'
import { NavLink } from 'react-router-dom'
import MobileBottomMenu from "../../../components/Layout/Mobile/MobileBottomMenu";
import MobileHeader from "../../../components/Layout/Mobile/MobileHeader";
import { withTranslation } from "react-i18next";

function MobileNavbar(props) {
    return (
        <>
            <MobileHeader title={props.t("Offer")} />
            <MobileBottomMenu/>
        </>
    )
}

export default withTranslation()(MobileNavbar)