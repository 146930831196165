import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ProductAPI } from "../../apis/ProductAPI";
import Footer from "../../components/Layout/Footer";
import MobileBottomMenu from "../../components/Layout/Mobile/MobileBottomMenu";
import MobileHeaderArrowBanner from "../../components/Layout/Mobile/MobileHeaderArrowBanner";
import Navbar from "../../components/Layout/Navbar";
import config from "../../config";

import { jsPDF } from "jspdf";

function CompanyProductDetail(props) {
  const params = useParams();
  const product_id = params.product_id;
  const [productDetail, setProductDetail] = useState({});
  const [productName, setProductName] = useState({});

  const getProductDetail = () => {
    const payload = {
      product_id: product_id,
    };
    ProductAPI.getProductDetail(payload)
      .then((res) => {
        if (res.data.success) {
          setProductDetail(res.data.data);
          setProductName(res.data.data?.name_en);
        } else {
          setProductDetail({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProductDetail();
  }, []);

  // const generatePdf = () => {
  //   const doc = new jsPDF();

  //   let pageHeight = doc.internal.pageSize.height;
  //   let y = 500;

  //   // Product Heading Name
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Product Name"), 20, 10);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   doc.text(productDetail.name_en, 20, 15);
  //   // Product Heading Name

  //   let para = doc.splitTextToSize(productDetail.description_en, 180); //Split to max length

  //   // About Product
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("About"), 20, 25);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   doc.text(para, 20, 32);
  //   // About Product

  //   // Licensee Name
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Licensee Name"), 20, 60);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   doc.text(productDetail.license_name, 20, 65);
  //   // Licensee Name

  //   // Distibutor Name
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Distributor"), 20, 73);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   doc.text(productDetail.distributor_name, 20, 78);
  //   // Distributor Name

  //   // Marketing Authorization Number
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Authorisation Number"), 20, 86);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   doc.text(productDetail.authorisation_number, 20, 91);
  //   // Marketing Authorization Number

  //   // Composition
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Composition"), 20, 101);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   let splitComposition = doc.splitTextToSize(productDetail.composition, 180);
  //   doc.text(splitComposition, 20, 107);
  //   // Composition

  //   // Pharmaceutical Dose Form
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Dose Form"), 20, 117);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   let splitDoseForm = doc.splitTextToSize(
  //     productDetail.pharmaceutical_dose_form,
  //     180
  //   );
  //   doc.text(splitDoseForm, 20, 123);
  //   // Pharmaceutical Dose Form

  //   // Indication
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Indication"), 20, 135);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   let splitIndication = doc.splitTextToSize(productDetail.indication, 180);
  //   doc.text(splitIndication, 20, 141);
  //   // Indication

  //   // Dose And Administration
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Dose And Administration"), 20, 175);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   let splitDoesAndAdmininstration = doc.splitTextToSize(
  //     productDetail.dose_and_administration,
  //     180
  //   );
  //   doc.text(splitDoesAndAdmininstration, 20, 182);
  //   // Dose And Administration

  //   // Contraindication
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Contraindication"), 20, 215);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   let splitContraindication = doc.splitTextToSize(
  //     productDetail.contraindications,
  //     180
  //   );
  //   doc.text(splitContraindication, 20, 222);
  //   // Contraindication

  //   // doc.addPage(); // New Page

  //   // Precautions
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Precautions"), 20, 235);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   let splitPrecaution = doc.splitTextToSize(productDetail.precautions, 180);
  //   doc.text(splitPrecaution, 20, 242);
  //   // Precautions

  //   doc.addPage();
  //   // Interaction
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Interaction"), 20, 10);

  //   doc.setFontSize(12);
  //   doc.setFont("", "normal", "");
  //   let splitInteraction = doc.splitTextToSize(productDetail.interaction, 180);
  //   doc.text(splitInteraction, 20, 17);
  //   // Interaction

  //   // Product Images Label
  //   doc.setFontSize(12);
  //   doc.setFont("", "bold", "");
  //   doc.text(props.t("Product Images"), 20, 55);

  //   // Product Images Label

  //   // doc.addPage();
  //   if (productDetail.ProductImages.length == 0) {
  //     doc.addImage(productDetail.image_url, 20, 60, 50, 50); //If image is not available
  //   } else {
  //     let x_axis = 20; // X axis
  //     let y_axis = 60; // Y axis
  //     for (let i = 0; i < productDetail.ProductImages.length; i++) {
  //       doc.addImage(
  //         productDetail.ProductImages[i]?.file_url,
  //         "JPG",
  //         x_axis,
  //         y_axis,
  //         50,
  //         50
  //       );
  //       y_axis = y_axis + 60; //Increment by 60
  //       if (y_axis > 180) {
  //         y_axis = 60;
  //         x_axis = 120;
  //       }
  //     }
  //   }
  //   doc.save(productName); //Name of the pdf
  // };

  const generatePdf  = async () => {
    var doc = new jsPDF('p', 'mm', [700, 810]);
    var pdfjs = document.querySelector('#export-product-details-pdf');
    // Convert HTML to PDF in JavaScript
    doc.html(pdfjs, {
      callback: function(doc) {
        doc.save(`${productName}.pdf`);
      },
      x: 10,
      y: 10
    });
  }

  return (
    <div>
      <Navbar />
      <MobileBottomMenu />
      <>
        <MobileHeaderArrowBanner
          back_page_url={
            `community/company/products/` + productDetail?.Company?.id
          }
          image={
            productDetail.image_url
              ? productDetail.image_url
              : config.BASE_URL + "/assets/images/default-activity.jpg"
          }
        />
        <section className="mt-5 d-m-none">
          <div className="container">
            <div className="row">
              <div className="col-md-12 list-style-none">
                <ul className="breadcrumb mt-3">
                  <li>
                    <Link to="/community">{props.t("Community")}</Link>
                  </li>
                  <li>/</li>
                  <li className="active">
                    <Link to="/community">{props.t("In-Focus")}</Link>
                  </li>
                  <li>/</li>
                  <li className="active">{productDetail?.Company?.name_en}</li>
                  <li>/</li>
                  <li className="active">{productDetail?.name_en}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-3 pb-5 details top-curve">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 event-item">
                <div className="row mb-4">
                  <div className="justify-content-between align-items-center">
                      <div className="float-right">
                        <button
                          type="button"
                          className="btn btn-join2"
                          onClick={generatePdf}
                        >
                          {props.t("exportPdf")}
                        </button>
                      </div>
                    </div>
                </div>

                <div id="export-product-details-pdf">
                <div className="row mb-4">
                  <div className="col-md-9 col-12">
                    <h6 style={{ color: "black" }}>{productDetail.name_en} </h6>
                  </div>
                </div>

                <div className="slide-gallery my-3 text-center">
                  {productDetail?.ProductImages ? (
                    productDetail?.ProductImages[0]?.file_url ? (
                      <img
                        src={productDetail?.ProductImages[0]?.file_url}
                        className="w-50 img-rouded-5"
                      />
                    ) : (
                      <>
                        <img
                          src={productDetail.image_url}
                          className="w-50 img-rounded-5"
                        />
                      </>
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div className="slide-gallery my-3">
                  <div className="d-flex column-list row-cols-3">
                    {productDetail?.ProductImages
                      ? productDetail?.ProductImages.map((comitem, comindx) => {
                          return comindx !== 0 ? (
                            comindx <= 3 ? (
                              <div
                                className="column "
                                key={comindx}
                                style={{
                                  width: "15%",
                                  height: "auto",
                                  marginRight: "15px",
                                }}
                              >
                                <Link
                                  to={`/community/product/images/${productDetail.id}`}
                                >
                                  <img
                                    className="cursor img-fluid rounded-5"
                                    src={comitem.file_url}
                                    alt=""
                                  />
                                </Link>
                              </div>
                            ) : comindx === 4 ? (
                              <div className="column ">
                                <Link
                                  to={`/community/product/images/${productDetail.id}`}
                                >
                                  <img
                                    className="cursor img-fluid "
                                    src={comitem?.file_url}
                                    alt=""
                                  />
                                  <div className="overlay">
                                    <span className="image-count">
                                      +{productDetail.ProductImages?.length - 3}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-md-12">
                    <p><strong> {props.t("About-Product")} </strong></p>
                    <p>{productDetail.description_en}</p>
                  </div> */}

                  <div className="col-md-12">
                    <p>
                      <strong> {props.t("Indication")} </strong>
                    </p>
                    <p>
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.indication,
                        }}
                      />
                    </p>
                  </div>

                  <div className="col-md-12">
                    <p>
                      <strong>
                        {" "}
                        {props.t("Qualitative_Quantitative_Composition")}{" "}
                      </strong>
                    </p>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.composition,
                        }}
                      />
                    </p>
                  </div>

                  <div className="col-md-12">
                    <p>
                      <strong> {props.t("Pharmaceutical_Dose_Form")} </strong>
                    </p>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.pharmaceutical_dose_form,
                        }}
                      />
                    </p>
                  </div>

                  <div className="col-md-12">
                    <p>
                      <strong> {props.t("Dose_And_Administration")} </strong>
                    </p>
                    <p> <div
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.dose_and_administration,
                        }}
                      /></p>
                  </div>

                  <div className="col-md-12">
                    <p>
                      <strong> {props.t("Contraindications")} </strong>
                    </p>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.contraindications,
                        }}
                      />
                    </p>
                  </div>

                  <div className="col-md-12">
                    <p>
                      <strong>
                        {" "}
                        {props.t("Special_Warnings_Precautions")}{" "}
                      </strong>
                    </p>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.precautions,
                        }}
                      />
                    </p>
                  </div>

                  <div className="col-md-12">
                    <p>
                      <strong> {props.t("Interaction")} </strong>
                    </p>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.interaction,
                        }}
                      />
                    </p>
                  </div>

                  <div className="col-md-12">
                    <p>
                      <strong> {props.t("License_Name")} </strong>
                    </p>
                    <p>{productDetail.license_name}</p>
                  </div>

                  <div className="col-md-12">
                    <p>
                      <strong> {props.t("Distributor_Name")} </strong>
                    </p>
                    <p>{productDetail.distributor_name}</p>
                  </div>

                  <div className="col-md-12">
                    <p>
                      <strong>
                        {" "}
                        {props.t("Marketing_Authorisation_Number")}{" "}
                      </strong>
                    </p>
                    <p>{productDetail.authorisation_number}</p>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Footer />
    </div>
  );
}

export default withTranslation()(CompanyProductDetail);
