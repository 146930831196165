import constants from "constants";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as url from "../../helpers/common_helper";

const UserTaskCard = (props) => {
  let task = props.task;
  let statusStyle = {};

  if (task.status === "Approved") {
    statusStyle.color = "#2A9C21";
  } else if (task.status === "Rejected") {
    statusStyle.color = "#D10E0E";
  } else if (task.status === "Pending") {
    statusStyle.color = "#FE951B";
  } else if (task.status === "Submited") {
    statusStyle.color = "#00B7C5";
  }
  return (
    <>
      <li style={{ float: "unset" }} key={`task_${task.id}`}>
        <Link to={`/taskDetail/${btoa(task.id)}`}>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div
              className="book"
              style={{ background: "none", marginRight: "10px" }}
            >
              <img
                src={
                  task?.Activity?.ActivityMediaImage[0]?.file_url
                    ? task?.Activity?.ActivityMediaImage[0]?.file_url
                    : require("assets/images/default-activity.jpg")
                }
                alt="book"
                height="40"
              />
            </div>
            <div className="block2 list-style-none" style={{ width: "100%" }}>
              <span className="d-block textwrap">
                {((task?.type !== "MSF" &&
                  task?.type !== "DIRECTOR") ||
                  (task?.task_type_id !==
                    constants.TASKTYPE.TASKREVIEWANDSUBMITEVALUATION &&
                    task?.task_type_id !== constants.TASKTYPE.CHECKRESULT)) &&
                  task?.Activity.title_en}
                {(task?.type === "MSF" ||
                  task?.type === "DIRECTOR") &&
                  task?.task_type_id ===
                    constants.TASKTYPE.TASKREVIEWANDSUBMITEVALUATION && (
                    <span>{props.t("fillEvaluationForm")}</span>
                  )}
                {task?.type === "TEACHER" &&
                  task?.task_type_id === constants.TASKTYPE.CHECKRESULT && (
                    <span>{props.t("checkResult")}</span>
                  )}
              </span>
              <ul className="ha-list" style={{ display: "block" }}>
                <li>
                  <img
                    className="img-fluid"
                    src="assets/images/clock.png"
                    alt=""
                    width="15"
                    height="15"
                  />
                  {url.dateFormat(task?.start_date_time)}
                </li>
                <li style={{ display: "block" }}>
                  <img
                    className="img-fluid "
                    src="assets/images/user.png"
                    alt=""
                    width="12"
                    height="12"
                  />
                  {task?.Activity.instituteName ? `${task?.Activity.instituteName}` : `${task.creatorName}`}
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <span className="" style={statusStyle}>
                  {task.status}{" "}
                </span>
              </div>
              <div className="col-sm-12">
                {/* <span className="" style={{ color: "#146de4" }}>
                  {task?.credit_score} {props.t("Credit")}
                </span> */}
                {task.type === "INTERN" && (
                  <p className="subtitle">
                    {task?.attendance_type === "CHECK-IN" &&
                      props.t("Check-In")}
                    {task?.attendance_type === "CHECK-OUT" &&
                      props.t("Check-Out")}
                  </p>
                )}
              </div>
            </div>
          </div>
        </Link>
      </li>
    </>
  );
};

export default withTranslation()(UserTaskCard);
