import { getEmail, getLicenseNumber, getName } from "Token";
import moment from "moment";
import { withTranslation } from "react-i18next";

const PdfArea = (props) => (
  <>
    <div className="main py-5 content-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 list-style-none" style={{ fontFamily: "Noto Sans Thai" }}>
            <h4>{getName()}</h4>
            <div className="brd-blue mb-3"></div>
            <ul>
              <li>License Number : {getLicenseNumber()}</li>
              <li>Email : {getEmail()}</li>
            </ul>
          </div>
          <div className="col-md-12 mt-4">
            <h5 className="mb-3">Total Score</h5>
            <div className="table-responsive score-table">
              <table className="table table-striped table-bordered export-pdf-table">
                <thead>
                  <tr>
                    <th colSpan="6">Topic</th>
                    <th colSpan="6" className="text-end">
                      Credit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="6">Yearly Score</td>
                    <td colSpan="6" className="text-end num">
                      {props?.yearlyScore?.length > 0
                        ? props?.yearlyScore[0]?.credit_score.toFixed(2)
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6">
                      5 Years Past ({props.summaryData?.last_year} -{" "}
                      {props.summaryData?.current_year})
                    </td>
                    <td colSpan="6" className="text-end num">
                      {props.summaryData
                        ? props.summaryData?.credit_score_year?.toFixed(2)
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6">Total</td>
                    <td colSpan="6" className="text-end num">
                        {props.summaryData?.credit_score_througout.toFixed(
                                      2
                                    )}
                      {/* {props.summaryData
                        ? (
                            parseInt(
                              props.summaryData?.credit_score_year?.toFixed(2)
                            ) + parseInt(props?.yearlyScore[0]?.credit_score)
                          )?.toFixed(2)
                        : 0} */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <h5 className="mb-3">Activity</h5>
            <div className="table-responsive activity-table">
              <table className="table table-striped table-bordered export-pdf-table">
                <thead>
                  <tr>
                    <th className="text-center">Activity</th>
                    <th className="text-center">Yearly Activity</th>
                    <th className="text-center">
                      5 Years Activity <br /> ({props.summaryData?.last_year} -{" "}
                      {props.summaryData?.current_year})
                    </th>
                    <th className="text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {props.scoregroupwise?.length > 0 &&
                    props.scoregroupwise.map((activityScore, index) => {
                      return (
                        <tr key={`activityScore_${index}`}>
                          <td>
                            {props.t("Activity Group")}{" "}
                            {activityScore.activity_group}
                          </td>
                          <td className="text-end num">
                            {" "}
                            {activityScore.totalYearlyActvity}
                          </td>
                          <td className="text-end num">
                            {activityScore.totalFiveYearlyActvity}
                          </td>
                          <td className="text-end num">
                            {activityScore.totalFiveYearlyActvity}
                            {/* {parseInt(activityScore.totalYearlyActvity) +
                              parseInt(activityScore.totalFiveYearlyActvity)} */}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <p>
              The score provided by Center for Continuing Medical Education
              (CCME) as of {moment().format("DD/MM/YYYY")}
            </p>
            <div className="link">
              <a
                href="https://www.medxcorporation.com"
                className="d-flex align-items-center"
              >
                <div className="me-3">
                  <img src={require("assets/images/m-icon.png")} width="50" />
                </div>
                <div>www.medxcorporation.com</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default withTranslation()(PdfArea);
