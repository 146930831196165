import moment from "moment";
import {getDistance, getPreciseDistance} from 'geolib';

export const dateFormat = (date) => {
  return moment(date).format("DD MMM YYYY");
};

export const dateTimeFormat = (datetime) => {
  return moment(datetime).format("DD MMMM YYYY hh:mm A");
};

export const dateMonthTimeFormat = (datetime) => {
  return moment(datetime).format("DD MMMM") +", "+ moment(datetime).format("hh:mm A");
};

export const TimeFormat = (datetime) => {
  return moment(datetime, "hh:mm").format("LT"); //moment(datetime).format("h:mm A UTC");
};

export const currentDateTH = (date) => {
  return moment().add(543, 'years').format("DD/MMM/YYYY");
};

export const currentYeareTH = (year) => {
  return moment(year).add(543, 'years').format("YYYY");
};

export const calculateDistance = (data) => {
  if(data.srcLatitude !== "" && data.srcLongitude !== "" && data.destLatitude !== "" && data.destLongitude !== ""){
    var dis = getDistance(
      {latitude: data.srcLatitude, longitude: data.srcLongitude},
      {latitude: data.destLatitude, longitude: data.destLongitude},
    );
    return `${dis}`;
    // return `Distance\n\n${dis} Meter\nOR\n${dis / 1000} KM`; 
  }
  return false;
};

export const calculatePreciseDistance = (data) => {
  if(data.srcLatitude !== "" && data.srcLongitude !== "" && data.destLatitude !== "" && data.destLongitude !== "") {
    var pdis = getPreciseDistance(
      {latitude: data.srcLatitude, longitude: data.srcLongitude},
      {latitude: data.destLatitude, longitude: data.destLongitude},
    );
    return `${pdis}`;
    // return `Distance\n\n${pdis} Meter\nOR\n${pdis / 1000} KM`;
    }
  return false;
};

export const subActivityTaskType = {
  1: "GIVEADVISE",
  2: "ADDQUIZ",
  3: "SIMPLE",
  4: "EVALUATIONFORMAPPROVAL",
  5: "EVALUATIONFORMREVIEW",
  6: "GIVEQUIZ",
  7: "REVIEWEXAM",
  8: "CREATIONTEACHINGMATERIAL",
  9: "INTERVIEWTEST",
  10: "MEQSHORTASSAY",
};
