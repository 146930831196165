import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { isLoggedIn } from "../../Token";
const PublicRoute = () => {


    const navigate = useNavigate();

    useEffect(() => {
        // if (isLoggedIn()) {
        //     navigate('/')
        // }
    }, [])


    return (
        <Outlet />
    )
}

export default PublicRoute