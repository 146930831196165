import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import { HomeApi } from "../apis/HomeApi";
import { EducationApi } from "../apis/EducationApi";
import config from "../config";
import axios from "axios";
import upevent1 from "../assets/images/right-arrow.png";
import Activity from "./Education/components/Activity";
import Advertise from "./Components/Advertise";
import _ from "lodash";
import validator from "validator";
import MobileBottomMenu from "../components/Layout/Mobile/MobileBottomMenu";
import MobileHeader from "../components/Layout/Mobile/MobileHeader";
import { withTranslation } from "react-i18next";
import { CommanApi } from "apis/CommanApi";

import Carousel from "react-bootstrap/Carousel";
import { getRoleID, isRestricted, isFda } from "../Token";
import constants from "../constants";
import Select from "react-select";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const options = {
  responsiveClass: true,
  dots: false,
  autoplay: false,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 6,
    },
    700: {
      items: 6,
    },
    1000: {
      items: 6,
    },
  },
};

const widthStyle = { width: "80px !important" };

function Home(props) {
  const [activityData, setActiveData] = useState({
    page: 1,
    limit: 10,
    keyword: "",
    institute_id: "All",
  });

  // const [newsData, setNewsData] = useState({
  //   page: 1,
  //   limit: 10,
  //   type: "NEWS",
  // });
  const [topBanner, setTopBanner] = useState({
    page_name: "Home",
    type: "Slider",
    position: "Top",
  });
  const [banner, setBanner] = useState([]);
  const [bannerCount, setBannerCount] = useState(0);
  const [upcomingAct, setUpcomingAct] = useState([]);
  const [liveActivities, setLiveActivities] = useState([]);
  const [activitiesAboutToClose, setActivitiesAboutToClose] = useState([]);
  const [newsget, setNewsget] = useState([]);
  const [interests, setInterests] = useState([]);
  const [userInterests, setUserInterest] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [userLineID, setUserLineID] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [specialistID, setSpecialistID] = useState("");
  const [specialistArr, setSpecialistArr] = useState([]);
  const [sliderInterval, setsliderInterval] = useState(10000);
  const [validateError, setValidateError] = React.useState({
    email: "",
    lineid: "",
  });

  const [banners, setBanners] = useState([]);
  const [bannersCount, setBannersCount] = useState(0);
  const [bottomBanner, setBottomBanner] = useState({
    page_name: "Home",
    type: "Banner",
    position: "Bottom",
  });
  const [selectedSpecialities, setSelectedSpecialities] = useState();
  const [userSpecialities, setUserSpecialities] = useState([]);
  const [userInt, setUserInt] = useState([]);
  const [termsAndCondtionData, setTermsAndCondtionData] = useState("");
  const [isProfileCompleted, setIsProfileCompleted] = useState(true);

  const getBanner = (data) => {
    HomeApi.getBanners(data)
      .then((res) => {
        if (res.data.success) {
          setBanner(res.data.data.banners);
          setsliderInterval(res.data.data.speed_time_interval);
          setBannerCount(res.data.data.banners.length);
        } else {
          setBanner([]);
          setBannerCount(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBanners = (data) => {
    HomeApi.getBanners(data)
      .then((res) => {
        if (res.data.success) {
          setBanners(res.data.data.banners);
          setBannersCount(res.data.data.banners.length);
          setsliderInterval(res.data.data.speed_time_interval);
        } else {
          setBanners([]);
          setBannersCount(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUpcomingActivities = (data) => {
    HomeApi.getUpcomingActivities(data)
      .then((res) => {
        if (res.data.success) {
          setUpcomingAct(res.data.data.rows);
        } else {
          setUpcomingAct([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getActivityAboutToClose = (data) => {
    HomeApi.getActivityAboutToCloseAPI(data)
      .then((res) => {
        if (res.data.success) {
          setActivitiesAboutToClose(res.data.data.rows);
        } else {
          setActivitiesAboutToClose([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLiveActivities = (data) => {
    HomeApi.getAllLiveActivities(data)
      .then((res) => {
        if (res.data.success) {
          setLiveActivities(res.data.data.rows);
        } else {
          setLiveActivities([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getNews = (data) => {
  //   HomeApi.getNews(data)
  //     .then((res) => {
  //       if (res.data.success) {
  //         setNewsget(res.data.data.rows);
  //       } else {
  //         setNewsget([]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleSelect = (data) => {
    let userSpecialitiesArr = [];
    data.map((item, index) => {
      userSpecialitiesArr.push(item.value);
    });
    setUserSpecialities(userSpecialitiesArr);
    setSelectedSpecialities(data);
  };

  useEffect(() => {
    if (constants.ROLE.COMPANY !== getRoleID()) {
      checkUserDetailsAndInterest();
    }
    getBanner(topBanner);
    getBanners(bottomBanner);
    getLiveActivities(activityData);
    getUpcomingActivities(activityData);
    getActivityAboutToClose(activityData);
    // getNews(newsData);
  }, []);

  const getTermsAndCondtion = async () => {
    CommanApi.cmsPageByTypeApi("TermsAndCondition")
      .then((res) => {
        if (res.data.success) {
          setTermsAndCondtionData(res.data.data);
          window.$("#terms").modal({ backdrop: "static", keyboard: false });
          window.$("#terms").modal("show");
        } else {
          toastr.error(res?.data?.message, "");
        }
      })
      .catch((err) => {
        toastr.error(err?.message, "");
      });
  };

  const checkUserDetailsAndInterest = () => {
    HomeApi.checkUserDetailsAndInterestAPI()
      .then((res) => {
        if (res.data.success) {
          setIsProfileCompleted(res.data.isFillUpDetailsAndInterest);
          if (!res.data.isFillUpDetailsAndInterest) {
            getInterest();
            getSpecialist();
            setUserEmail(res.data.user.email ? res.data.user.email : "");
            setUserLineID(res.data.user.line_id ? res.data.user.line_id : "");
            setUserMobile(res.data.user.mobile ? res.data.user.mobile : "");
            setUniqueId(res.data.user.unique_id ? res.data.user.unique_id : "");

            let userIntArr = [];
            res.data.userInt !== undefined &&
              res.data.userInt.forEach((element, i) => {
                userIntArr.push(element.interest_id);
              });
            setUserInterest(userIntArr);

            let userSpecialitiesArr = [];
            let specialtiesArr = [];
            res.data.userSpecialties !== undefined &&
              res.data.userSpecialties.forEach((element, i) => {
                userSpecialitiesArr.push({
                  label: element.Specialties.name_en,
                  value: element.Specialties.id,
                });
                specialtiesArr.push(element.Specialties.id);
              });
            setUserSpecialities(specialtiesArr);
            setSelectedSpecialities(userSpecialitiesArr);
            // setSpecialistID(
            //   res.data.user.Specialist ? res.data.user.Specialist.id : ""
            // );
          }
          if (!res.data.isAcceptTermsAndCondtion) {
            getTermsAndCondtion();
          } else {
            if (!res.data.isFillUpDetailsAndInterest) {
              window.$("#more-information").modal("show");
            }
          }
        } else {
          // setNewsget([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInterest = () => {
    EducationApi.getInterests()
      .then((res) => {
        if (res.data.success) {
          setInterests(res.data.data);
        } else {
          setInterests([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSpecialist = () => {
    CommanApi.getSpecialistAPI()
      .then((res) => {
        if (res.data.success) {
          setSpecialistArr(res.data.data);
        } else {
          setSpecialistArr([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onInterestHandler = async (e, interestID) => {
    const userInterestsCopy = _.cloneDeep(userInterests);
    if (e.target.checked) {
      const interestIndex = userInterestsCopy.indexOf(interestID);
      userInterestsCopy.splice(interestIndex, 1);
    } else {
      userInterestsCopy.push(interestID);
    }
    setUserInterest(userInterestsCopy);
  };

  const inputChange = (e) => {
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      var value = e.target.value;
      switch (e.target.name) {
        case "email":
          setUserEmail(value);
          break;
        case "lineid":
          setUserLineID(value);
          break;
        case "mobile":
          setUserMobile(value);
          break;
        case "unique_id":
          setUniqueId(value);
          break;
        case "specialist_id":
          setSpecialistID(value);
          break;
        default:
        // code block
      }
    }
  };

  const onSaveUserInformation = async () => {
    var errors = await userInformationValidation(true);
    var errorsSize = Object.keys(errors).length;
    if (errorsSize != 0) {
      return false;
    }

    const payload = {
      email: userEmail,
      lineID: userLineID,
      interests: userInterests,
      mobile: userMobile,
      unique_id: uniqueId,
      specialities: userSpecialities,
      // specialist_id: specialistID,
      // termsandcondition: true,
    };
    HomeApi.addUpdateUserInformationAPI(payload)
      .then((res) => {
        if (res.data.success) {
          window.$("#more-information").modal("hide");
          // window.$("#terms").modal("show");
          return true;
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userInformationValidation = async (check) => {
    if (check) {
      var ValidateError = {};
      if (userEmail === "") {
        ValidateError.email = props.t("Email-Required-Messages");
      }

      if (!validator.isEmail(userEmail) && userEmail !== "") {
        ValidateError.email = props.t("Valid-Email-Message");
      }

      if (userMobile === "") {
        ValidateError.userMobile = props.t("Phone-Number-Messages");
      }

      if (userSpecialities.length === 0) {
        ValidateError.specialistID = props.t("Specialist-Required-Messages");
      }

      if (uniqueId !== "" && uniqueId.length !== 13) {
        ValidateError.uniqueId = props.t("Digit-ID-Limit-Messages");
      }

      if (userInterests.length === 0) {
        ValidateError.interests = props.t("Min-Interest-Messages");
      }
      if (userInterests.length > 3) {
        ValidateError.interests = props.t("Mix-Interest-Messages");
      }
      setValidateError(ValidateError);
      return ValidateError;
    } else {
      return {};
    }
  };

  const onAcceptTermsAndCondition = async () => {
    const payload = {
      termsandcondition: true,
    };
    HomeApi.addUpdateUserInformationAPI(payload)
      .then((res) => {
        if (res.data.success) {
          window.$("#terms").modal("hide");
          if (!isProfileCompleted) {
            window.$("#more-information").modal("show");
          }
          return true;
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Navbar />
      <MobileHeader title={props.t("Title")} />
      <MobileBottomMenu />
      {/* Carousel Start  */}
      <section className="mt-5 top-curve">
        <div
          className={`container p-0 carousel-p restricted_${isRestricted()} fda_${isFda()}`}
        >
          <div className="row">
            <Carousel>
              {banner.map((item, i) => {
                return (
                  <Carousel.Item
                    interval={sliderInterval}
                    controls={false}
                    nextIcon=""
                    nextLabel=""
                  >
                    <a
                      href={
                        item.banner_link.includes("?")
                          ? `${item.banner_link}&&type=banner`
                          : `${item.banner_link}?type=banner`
                      }
                      title={item.title_en + " - " + item.banner_link}
                      target={isFda() ? "" : "_blank"}
                      onClick={() => {
                        window.parent.postMessage(
                          JSON.stringify({
                            type: "banner",
                            url: item.banner_link,
                          })
                        );
                      }}
                    >
                      <img
                        className="w-100 d-m-none"
                        src={item.image_url}
                        alt="Image"
                      />
                      <img
                        className="w-100 d-none"
                        src={item.image_url}
                        alt="Image"
                      />
                    </a>
                  </Carousel.Item>
                );
              })}
            </Carousel>
            {/* <div id="demo" className="carousel slide" data-bs-ride="carousel" data-interval="100">
              <div className="carousel-indicators">
                {[...Array(bannerCount)].map((item, i) => {
                  return (
                    <button
                      type="button"
                      data-bs-target="#demo"
                      data-bs-slide-to={i}
                      className={`${i === 0 ? "active" : ""}`}
                      key={i}
                    ></button>
                  );
                })}
              </div>

              <div className="carousel-inner">
                {banner.map((item, i) => {
                  return (
                    <div
                      className={`carousel-item ${i === 0 ? "active" : ""}`}
                      key={i}
                    >
                      <a href={`${item.banner_link}`} title={item.title_en + ' - ' + item.banner_link} target="_blank">
                        <img
                          className="w-100 d-m-none"
                          src={item.image_url}
                          alt="Image"
                        />
                        <img
                          className="w-100 d-none"
                          src={item.image_url}
                          alt="Image"
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* Carousel End  */}

      {/* My Score Start */}
      {/* <section className="d-none py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12 m-px-20">
              <div className="d-flex align-items-center justify-content-between myscore1 mb-3">
                <div>
                  <h4 className="m-0">My Score</h4>
                  <p>Total score (2017-2022)</p>
                  <div className="progress">
                    <div
                      className="progress-bar progress-primary"
                      role="progressbar"
                      style={{ width: "75%" }}
                      aria-valuenow="15"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div>
                  <button className="btn btn-credit bg-primary text-left">
                    <h5>200.50</h5>
                    <span>Credit</span>
                  </button>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between myscore1">
                <div>
                  <h4 className="m-0">My Token</h4>
                  <p>Total token (2017-2022)</p>
                </div>
                <div>
                  <button className="btn btn-credit bg-secondary text-left">
                    <h5>1,695</h5>
                    <span className="text-white">Token</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* My Score End */}

      {liveActivities.length > 0 && <section className={`section-m py-3 restricted_${isRestricted()}`}>
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12 px-30 d-m-flex">
              <h2 className="title">
                {props.t("live")}{" "}
                <img
                  src={require("../assets/images/live.gif")}
                  alt="icon"
                  width={18}
                  height={18}
                />
              </h2>
              <div className="d-flex justify-content-between align-items-center">
                <div className="subtitle">
                  {/* {props.t("Come-See-What-Happening")} */}
                </div>
                {/* {liveActivities.length > 6 && (
                  <div className="d-m-none">
                    <Link
                      to="upcoming-activities"
                      // to="education/education-activities/CME"
                      className="view-text"
                    >
                      <span className="view-m">{props.t("See-All")}</span>
                      <span className="see-m">{props.t("See-All")}</span>{" "}
                      <img
                        src={config.BASE_URL + "/assets/images/right-arrow.png"}
                        alt="icon"
                        className="ml-2"
                        width={18}
                        height={18}
                      />
                    </Link>
                  </div>
                )} */}
                {liveActivities.length > 2 && (
                  <div className="d-none">
                    <Link
                      to="upcoming-activities"
                      // to="education/education-activities/CME"
                      className="view-text"
                    >
                      <span className="view-m">{props.t("See-All")}</span>
                      <span className="see-m">{props.t("See-All")}</span>{" "}
                      <img
                        src={config.BASE_URL + "/assets/images/right-arrow.png"}
                        alt="icon"
                        className="ml-2"
                        width={18}
                        height={18}
                      />
                    </Link>
                  </div>
                )}
              </div>
            </div>

            {liveActivities.length > 0 ? (
              <OwlCarousel
                items={6}
                autoplay={false}
                className="owl-carousel events-carousel mt-4 px-20"
                {...options}
              >
                {liveActivities.map((item, i) => {
                  return (
                    <Activity
                      activity={item}
                      key={i}
                      forSection="live"
                    />
                  );
                })}
              </OwlCarousel>
            ) : (
              <div className="new-subtitle text-center">
                {props.t("No-Upcoming-Activities")}{" "}
              </div>
            )}
          </div>
        </div>
      </section>}

      <section className={`section-m py-3 restricted_${isRestricted()}`}>
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12 px-30 d-m-flex">
              <h2 className="title">{props.t("Upcoming-Activities")}</h2>
              <div className="d-flex justify-content-between align-items-center">
                <div className="subtitle">
                  {/* {props.t("Come-See-What-Happening")} */}
                </div>
                {upcomingAct.length > 6 && (
                  <div className="d-m-none">
                    <Link
                      to="upcoming-activities"
                      // to="education/education-activities/CME"
                      className="view-text"
                    >
                      <span className="view-m">{props.t("See-All")}</span>
                      <span className="see-m">{props.t("See-All")}</span>{" "}
                      <img
                        src={config.BASE_URL + "/assets/images/right-arrow.png"}
                        alt="icon"
                        className="ml-2"
                        width={18}
                        height={18}
                      />
                    </Link>
                  </div>
                )}
                {upcomingAct.length > 2 && (
                  <div className="d-none">
                    <Link
                      to="upcoming-activities"
                      // to="education/education-activities/CME"
                      className="view-text"
                    >
                      <span className="view-m">{props.t("See-All")}</span>
                      <span className="see-m">{props.t("See-All")}</span>{" "}
                      <img
                        src={config.BASE_URL + "/assets/images/right-arrow.png"}
                        alt="icon"
                        className="ml-2"
                        width={18}
                        height={18}
                      />
                    </Link>
                  </div>
                )}
              </div>
            </div>

            {upcomingAct.length > 0 ? (
              <OwlCarousel
                items={6}
                autoplay={false}
                className="owl-carousel events-carousel mt-4 px-20"
                {...options}
              >
                {upcomingAct.map((item, i) => {
                  return (
                    <Activity
                      activity={item}
                      key={i}
                      forSection={
                        item.education_type === "ARTICLE" ? "articles" : ""
                      }
                    />
                  );
                })}
              </OwlCarousel>
            ) : (
              <div className="new-subtitle text-center">
                {props.t("No-Upcoming-Activities")}{" "}
              </div>
            )}
          </div>
        </div>
      </section>

      <section className={`section-m py-3 restricted_${isRestricted()}`}>
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12 px-30 d-m-flex">
              <h2 className="title">
                {props.t("Activities-That-Are-About-To-Close")}
              </h2>
              <div className="d-flex justify-content-between align-items-center">
                <div className="subtitle">
                  {/* {props.t("Come-See-What-Happening")} */}
                </div>
                {activitiesAboutToClose.length > 6 && (
                  <div className="d-m-none">
                    <Link
                      to="activity-about-to-close"
                      // to="education/education-activities/CME"
                      className="view-text"
                    >
                      <span className="view-m">{props.t("See-All")}</span>
                      <span className="see-m">{props.t("See-All")}</span>{" "}
                      <img
                        src={config.BASE_URL + "/assets/images/right-arrow.png"}
                        alt="icon"
                        className="ml-2"
                        width={18}
                        height={18}
                      />
                    </Link>
                  </div>
                )}
                {activitiesAboutToClose.length > 2 && (
                  <div className="d-none">
                    <Link
                      to="upcoming-activities"
                      // to="education/education-activities/CME"
                      className="view-text"
                    >
                      <span className="view-m">{props.t("See-All")}</span>
                      <span className="see-m">{props.t("See-All")}</span>{" "}
                      <img
                        src={config.BASE_URL + "/assets/images/right-arrow.png"}
                        alt="icon"
                        className="ml-2"
                        width={18}
                        height={18}
                      />
                    </Link>
                  </div>
                )}
              </div>
            </div>

            {activitiesAboutToClose.length !== 0 ? (
              <OwlCarousel
                items={6}
                autoplay={false}
                className="owl-carousel events-carousel mt-4 px-20"
                {...options}
              >
                {activitiesAboutToClose.map((item, i) => {
                  return (
                    <Activity
                      activity={item}
                      key={`abouttoclose_${i}`}
                      forSection={
                        item.education_type === "ARTICLE" ? "articles" : ""
                      }
                    />
                  );
                })}
              </OwlCarousel>
            ) : (
              <div className="new-subtitle text-center">
                {props.t("No-Activities-About-To-Close")}
              </div>
            )}
          </div>
        </div>
      </section>

      <Advertise
        banner={banners}
        bannersCount={bannersCount}
        name="BottomBanner"
      />

      <Footer />

      {/* News Start */}
      {/* <section className="section-m py-3 mob-mb-30">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12 px-30 d-m-flex2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="title">News</h2>
                </div>
                <div>
                  <NavLink to="/" className="view-text">
                    <span className="view-m">View More</span>
                    <span className="see-m">See All</span>{" "}
                    <img
                      src="assets/images/right-arrow.png"
                      alt="icon"
                      width="18"
                      height="18"
                      className="ml-2"
                    />
                  </NavLink>
                </div>
              </div>
            </div>

            <OwlCarousel
              items={4}
              autoplay={false}
              className="owl-carousel news-carousel mt-4 px-20"
              {...newsoptions}
            >
              {newsget.length !== 0
                ? newsget.map((item, i) => {
                    return (
                      <div className="event-item text-center" key={i}>
                        <div className="position-relative main-img">
                          <NavLink to={"community/details/news/" + item.id}>
                            <img
                              style={widthStyle}
                              className="img-fluid mx-auto "
                              src={item?.CommunityPostMedia[0]?.image_url}
                              alt=""
                            />
                          </NavLink>
                        </div>
                        <div className="news-info mb-3">
                          <NavLink to={"community/details/news/" + item.id}>
                            {" "}
                            <h4>{item.name_en}</h4>
                          </NavLink>
                          <p></p>
                          <ul className="event-list">
                            <li className="">
                              <div>
                                <img
                                  className="img-fluid"
                                  src="assets/images/clock.png"
                                  alt=""
                                  width="12"
                                  height="12"
                                />
                              </div>
                              <div>{formatDate(item.created_at)}</div>
                            </li>
                           
                          </ul>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </OwlCarousel>
          </div>
        </div>
      </section> */}
      {/* News End */}
 
      {/* more information modal */}
      <div className="modal fade in" id="more-information">
        <div className="modal-dialog scrollDialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {props.t("Edit")} {props.t("Information")}
                {/* <a href="" className="close d-none" data-dismiss="modal">
                  <img
                    src={require("../assets/images/768/x-circle.png")}
                    className=""
                    alt=""
                    height=""
                    width=""
                  />
                </a> */}
              </h4>
            </div>

            <div className="modal-body scrollAndFixedHeight">
              <h5>
                {props.t("Contact")} {props.t("Information")}
              </h5>
              <form>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <label htmlFor="email" className="form-label">
                      {props.t("Email")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder={props.t("Email")}
                      name="email"
                      value={userEmail || ""}
                      onChange={inputChange}
                    />
                    {validateError.email && (
                      <span className="text-danger">{validateError.email}</span>
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="lineid" className="form-label">
                      {props.t("Line-ID")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lineid"
                      placeholder={props.t("Line-ID")}
                      name="lineid"
                      value={userLineID || ""}
                      onChange={inputChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <label htmlFor="lineid" className="form-label">
                      {props.t("phone_number")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      placeholder={props.t("phone_number")}
                      name="mobile"
                      value={userMobile || ""}
                      onChange={inputChange}
                    />
                    {validateError.userMobile && (
                      <span className="text-danger">
                        {validateError.userMobile}
                      </span>
                    )}
                  </div>
                  {/* <div className="col-md-6 mb-2">
                    <label htmlFor="lineid" className="form-label">
                      {props.t("unique_id")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="unique_id"
                      placeholder={props.t("unique_id")}
                      name="unique_id"
                      value={uniqueId || ""}
                      onChange={inputChange}
                    />
                    {validateError.uniqueId && (
                      <span className="text-danger">
                        {validateError.uniqueId}
                      </span>
                    )}
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <label htmlFor="" className="form-label">
                      {props.t("select_specialist")}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={specialistArr.map((value) => ({
                          value: `${value.id}`,
                          label: `${value.name_en}`,
                        }))}
                        placeholder={props.t("select_specialist")}
                        value={selectedSpecialities}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                      />
                    </div>
                    {validateError.specialistID && (
                      <span className="text-danger">
                        {validateError.specialistID}
                      </span>
                    )}
                  </div>
                </div>
              </form>
              <h5>{props.t("Interest")}</h5>
              {validateError.interests && (
                <span className="text-danger">{validateError.interests}</span>
              )}
              <div className="row">
                {interests.length > 0
                  ? interests.map((interest, i) => {
                      let isChecked = false;
                      userInterests.forEach((intrst, i) => {
                        if (intrst === interest.id) {
                          isChecked = true;
                        }
                      });
                      return (
                        <div
                          className="col-md-6 col-xs-12 col-12 userinterest-inner"
                          key={`interest_${i}`}
                        >
                          <input
                            type="checkbox"
                            onMouseUp={(e) => onInterestHandler(e, interest.id)}
                            id="intrest"
                            name="intrest"
                            checked={isChecked}
                          />{" "}
                          {interest.name_en}
                        </div>
                      );
                    })
                  : props.t("No-Post-Found")}
              </div>
            </div>

            <div className="modal-footer">
              {/* <button
                type="button"
                className="float-right btn"
                data-dismiss="modal"
                onClick={() => {
                  window.$("#more-information").modal("hide");
                }}
              >
                close
              </button> */}
              <div
                className="privacypolicylink"
                style={{ marginRight: "165px" }}
              >
                <p>
                  By clicking Save you accept Medx{" "}
                  <a href={`${config.BASE_URL}privacy-policy`} target="_blank">
                    {props.t("Privacy-Policy")}
                  </a>
                </p>
              </div>

              <button
                className="float-right btn btn-blue"
                onClick={onSaveUserInformation}
              >
                {props.t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* terms and condition modal */}
      <div className="modal fade in" id="terms">
        <div className="modal-dialog scrollDialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{termsAndCondtionData?.name_en}</h4>
            </div>

            <div className="modal-body scrollAndFixedHeight">
              <div
                dangerouslySetInnerHTML={{
                  __html: termsAndCondtionData?.content_en,
                }}
              />
            </div>

            <div className="modal-footer">
              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={onAcceptTermsAndCondition}
                  className="float-right btn btn-blue"
                >
                  {props.t("Accept")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Home);
