import React, { useEffect, useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import { BookmarkApi } from "../../apis/BookmarkApi";
import config from "../../config";
import moment from 'moment';

export default function Library() {
    const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
        if (!value) return value
        return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
    }

    const [librarybookmarkData, setLibrarybookmarkData] = useState({ education_type: "CME", page: 1, limit: config.LIMIT });

    const [librarybookmark, setlibrarybookmark] = useState([]);

    const getLibraryBookmark = (data) => {
        BookmarkApi.getLibraryBookmark(data)
            .then((res) => {
                //console.log(res.data)
                if (res.data.success) {
                    setlibrarybookmark(res.data.data.rows);
                } else {
                    setlibrarybookmark([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

   
    function handleRemoveLibraryclick(libid) {
        //add bookmark
        const deleteLibraryBookmark = (data) => {
            BookmarkApi.deleteLibraryBookmark(data)
                .then((res) => {
                    //console.log(res.data)
                    if (res.data.success) {
                        getLibraryBookmark(librarybookmarkData);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        const adddata = {
            bookmark_id: libid
        }

        deleteLibraryBookmark(adddata);
    }
    useEffect(() => {
        getLibraryBookmark(librarybookmarkData);
    }, []);

    return (
        <>
            <div className="library-list mt-4">
                <ul className="first">

                    {(librarybookmark) ? ((librarybookmark).map((item, i) => {
                        return <li key={i}>
                            <div>
                                <div className="d-flex align-items-center justify-content-between mb-3 position-relative">
                                    <div className="book">
                                        <img
                                            src={item.Library.image_url}
                                            alt="book"
                                            className=""
                                            width="30"
                                            height=""
                                        />

                                    </div>
                                    <div className="block2 list-style-none">
                                        <span className="d-block">
                                            {item.Library.title_en}
                                        </span>
                                        <ul className="ha-list">
                                            <li>
                                                <div>
                                                    <img
                                                        className="img-fluid"
                                                        src="assets/images/clock.png"
                                                        alt=""
                                                        width="15"
                                                        height="15"
                                                    />
                                                </div>
                                                <div>{item.created_at}</div>
                                            </li>
                                            <li>
                                                <div>
                                                    <img
                                                        className="img-fluid "
                                                        src="assets/images/user.png"
                                                        alt=""
                                                        width="12"
                                                        height="12"
                                                    />
                                                </div>
                                                <div>{item.name}</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="library-bookmark-custom-main">
                                        <div className="library-bookmark-custom-sub shadow">
                                            <Link onClick={() => handleRemoveLibraryclick(item.id)} to="">
                                                <img
                                                    className="img-fluid"
                                                    src="assets/images/bookmark.png"
                                                    alt=""
                                                    width="10"
                                                    height="10"
                                                    style={{ borderRadius: "0px" }}
                                                />
                                            </Link>
                                        </div>
                                        <div>
                                            <span className="credits library-custom-label">5 Credits</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    })) : ''}

                </ul>
            </div>
        </>
    );
}
