import moment from "moment";
import { React, useState } from "react";
import { Link } from "react-router-dom";
import * as url from "../../helpers/common_helper";
import { withTranslation } from "react-i18next";
import constants from "../../constants";
import config from '../../config'
import LeaveDetailModal from "pages/Components/Modal/LeaveDetailModal";
import { LeaveAPI } from "../../apis/LeaveAPI";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, Input, Label } from "reactstrap";
import Swal from "sweetalert2";

const LeaveCard = (props) => {
    const leave = props.leave;
    const [modalDelete, setModalDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const toggleDelete = () => setModalDelete(!modalDelete);
  
    let statusStyle = {};
    if (leave.status === "Approved") {
        statusStyle.color = "#fff";
        statusStyle.backgroundColor = "#2A9C21";
    } else if (leave.status === "Rejected") {
        statusStyle.color = "#fff";
        statusStyle.backgroundColor = "#D10E0E";
    } else if (leave.status === "Pending") {
        statusStyle.color = "#fff";
        statusStyle.backgroundColor = "#FE951B";
    } else if (leave.status === "Canceled") {
        statusStyle.color = "#fff";
        statusStyle.backgroundColor = "#00B7C5";
    }
    const onViewSubmittedDetailsHandler = (id) => {
        window.$(`#leaveDetailsmodal_${id}`).modal("show");
    };

    const cancelLeave = () => {
        LeaveAPI.cancelLeave(deleteId)
          .then((res) => {
            if (res.data.success) {
              toggleDelete();
              Swal.fire({
                text: res.data.message,
                icon: "success",
                imageAlt: "success image",
              }).then((result) => {
                window.location.href = "/my-leaves";
              });
            } else {
                Swal.fire({
                    text: res.data.message,
                    icon: "error",
                    imageAlt: "error image",
                }).then((result) => {
                    window.location.href = "/my-leaves";
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };

    return (
        <>
            <li style={{float:"unset"}}>
                {/* <a href="detail.html"> */}
                <div className="d-flex align-items-center mb-3" style={{width:"100%"}}>
                    <div className="block2 textwrap list-style-none" style={{paddingLeft: "10px"}}>
                        <span className="d-block textwrap">{leave?.subject}</span>
                        <ul className="ha-list">
                            <li>
                                <div>
                                    <img
                                        className="img-fluid"
                                        src="assets/images/clock.png"
                                        alt=""
                                        width="30"
                                        height="30"
                                    />
                                </div>
                                <div>
                                    {url.dateFormat(leave.from_date)} {" "}
                                    {props.t("To")} {" "}
                                    {url.dateFormat(leave.to_date)}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <a
                                href="#"
                                // className="nav-link dropdown-toggle"
                                data-bs-toggle="dropdown"
                                role="button"
                                style={{float:"right"}}
                            >
                                <svg className="" style={{ height: 30, width: 40 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" >
                                    <path fillRule="evenodd" d="M4.5 12a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm6 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" clipRule="evenodd" />
                                </svg>
                            </a>
                            <div className="dropdown-menu bg-gray m-0 p-2">
                                {/* {leave.status == constants.LEAVE_STATUS.Applied && ( */}
                                    <Link
                                        className="text-drak dropdown-item p-1"
                                        role="button"
                                        onClick={() => onViewSubmittedDetailsHandler(leave.id)}
                                    >
                                        <svg viewBox="0 0 24 24" id="magicoon-Regular" xmlns="http://www.w3.org/2000/svg" style={{ width: 20 }}>
                                            <path d="M21.229,10.116C19.914,7.925,16.966,4.25,12,4.25S4.086,7.925,2.771,10.116a3.656,3.656,0,0,0,0,3.768C4.086,16.075,7.035,19.75,12,19.75s7.914-3.675,9.229-5.866h0A3.652,3.652,0,0,0,21.229,10.116Zm-1.286,3C18.792,15.031,16.228,18.25,12,18.25s-6.791-3.219-7.943-5.138a2.16,2.16,0,0,1,0-2.224C5.209,8.969,7.773,5.75,12,5.75s6.792,3.219,7.943,5.138A2.155,2.155,0,0,1,19.943,13.112ZM12,7.75A4.25,4.25,0,1,0,16.25,12,4.255,4.255,0,0,0,12,7.75Zm0,7A2.75,2.75,0,1,1,14.75,12,2.752,2.752,0,0,1,12,14.75Z"/>
                                            <path d="M21.229,10.116C19.914,7.925,16.966,4.25,12,4.25S4.086,7.925,2.771,10.116a3.656,3.656,0,0,0,0,3.768C4.086,16.075,7.035,19.75,12,19.75s7.914-3.675,9.229-5.866h0A3.652,3.652,0,0,0,21.229,10.116Zm-1.286,3C18.792,15.031,16.228,18.25,12,18.25s-6.791-3.219-7.943-5.138a2.16,2.16,0,0,1,0-2.224C5.209,8.969,7.773,5.75,12,5.75s6.792,3.219,7.943,5.138A2.155,2.155,0,0,1,19.943,13.112ZM12,7.75A4.25,4.25,0,1,0,16.25,12,4.255,4.255,0,0,0,12,7.75Zm0,7A2.75,2.75,0,1,1,14.75,12,2.752,2.752,0,0,1,12,14.75Z"/>
                                        </svg>
                                        &ensp;
                                        {props.t("View")} {" "} {props.t("Leave")}
                                    </Link>
                                {/* )} */}
                                {/* {leave.status == constants.LEAVE_STATUS.Applied && (
                                    <Link
                                        className="text-drak dropdown-item p-1"
                                        role="button"
                                        to={`/edit-leave-form/${leave.id}`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style={{ width: 20 }}>
                                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                        </svg>
                                        &ensp;
                                        {props.t("Edit")} {" "} {props.t("Leave")}
                                    </Link>
                                )} */}
                                {leave.status === constants.LEAVE_STATUS.Pending && (
                                    <a
                                        className=" text-drak dropdown-item p-1"
                                        onClick={() => {
                                            setDeleteId(leave.id);
                                            toggleDelete();
                                        }}
                                        role="button"
                                    >
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        style={{ width: 20 }}
                                        >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                            clipRule="evenodd"
                                        />
                                        </svg>
                                        &ensp; {props.t("Cancel")} {" "} {props.t("Leave")}
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <span className="badges" style={statusStyle}>
                                {leave.status}{" "}
                            </span>
                        </div>
                    </div>
                    {leave && (
                        <LeaveDetailModal data={leave} />
                    )}
                    {/* <!-- Cancel Leave Modal --> */}
                    <Modal
                      style={{
                        background: "transparent",
                        borderRadius: 0,
                        borderWidth: 0,
                        boxShadow: "none",
                      }}
                      centered
                      isOpen={modalDelete}
                      toggle={toggleDelete}
                    >
                      <ModalBody>
                        <div className="d-flex collection-btn-list">
                          <img
                            src="/assets/images/cancel.png"
                            width="38"
                            height="38"
                            alt=""
                            className="m-3"
                          />

                          <div>
                            <h5>{props.t("Cancel")} {" "} {props.t("Leave")}</h5>
                            <p>
                              Are you sure want to cancel leave. This action cannot be undone
                            </p>
                          </div>
                        </div>
                        <div className="bg-gray d-flex justify-content-end">
                          <Button
                            className="float-right btn btn-cancel-d mx-2"
                            onClick={() => {
                              setDeleteId(null);
                              toggleDelete();
                            }}
                          >
                            {props.t("Cancel")}
                          </Button>
                          <Button
                            className="float-right btn-cancel"
                            onClick={() => cancelLeave()}
                          >
                            {props.t("Proceed")}
                          </Button>
                        </div>
                      </ModalBody>
                    </Modal>
                </div>
                {/* </a> */}
            </li>
        </>
    );
};

export default withTranslation()(LeaveCard);
